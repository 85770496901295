import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import CandidateReportComponent from 'modules/admin/reports/CandidateReportComponent';
import { candidateReportRequestAction } from 'redux/admin/candidateReport/action';

const CandidateReportcontainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { token } = useParams();

  const { reports, reportsLoading } = useSelector((state) => state.candidateReportReducer);

  useEffect(() => {
    dispatch(candidateReportRequestAction(token));
  }, [dispatch]);

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <CandidateReportComponent
      reportsAreLoading={reportsLoading}
      reportData={reports}
      handleGoBack={handleGoBack}
    />
  );
};

export default React.memo(CandidateReportcontainer);
