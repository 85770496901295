export const QUESTIONS = {
  QUESTIONS_REQUEST_ACTION: 'QUESTIONS_REQUEST_ACTION',
  QUESTIONS_SUCCESS_ACTION: 'QUESTIONS_SUCCESS_ACTION',
  QUESTIONS_FAILURE_ACTION: 'QUESTIONS_FAILURE_ACTION',
  CLEAR_QUESTIONS: 'CLEAR_QUESTIONS',
  SET_QUESTIONS_OFFSET: 'SET_QUESTIONS_OFFSET',
  SET_QUESTIONS_SEARCH_TEXT: 'SET_QUESTIONS_SEARCH_TEXT',
  SET_QUESTIONS_DIFFICULTY: 'SET_QUESTIONS_DIFFICULTY',
  SET_QUESTIONS_TAG: 'SET_QUESTIONS_TAG',
  RESET_QUESTIONS_FILTERS: 'RESET_QUESTIONS_FILTERS',
  RESET_QUESTION_SEARCH_TEXT: 'RESET_QUESTION_SEARCH_TEXT',
  SET_SEARCHED_QUESTION_FILTER_ACTION: 'SET_SEARCHED_QUESTION_FILTER_ACTION',
  RESET_SEARCHED_QUESTION_FILTER_ACTION: 'RESET_SEARCHED_QUESTION_FILTER_ACTION',
};

export const DELETE_QUESTION = {
  DELETE_QUESTION_REQUEST_ACTION: 'DELETE_QUESTION_REQUEST_ACTION',
  DELETE_QUESTION_SUCCESS_ACTION: 'DELETE_QUESTION_SUCCESS_ACTION',
  DELETE_QUESTION_FAILURE_ACTION: 'DELETE_QUESTION_FAILURE_ACTION',
};
