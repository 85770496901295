import produce from 'immer';
import { TAGS } from 'redux/admin/tags/actionContants';

const {
  TAGS_FAILURE_ACTION, TAGS_REQUEST_ACTION, TAGS_SUCCESS_ACTION, CLEAR_TAGS,
  ADD_NEW_DIFFICULTY, ADD_NEW_TAG } = TAGS;

export const initialState = {
  difficultyList: [],
  tagList: [],
  isTagsSuccess: false,
  isTagsLoading: false,
  isTagsError: false,
};

const tagsReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case TAGS_REQUEST_ACTION:
      state.isTagsLoading = true;
      break;
    case TAGS_SUCCESS_ACTION:
      state.isTagsSuccess = true;
      state.isTagsLoading = false;
      state.difficultyList = payload.difficulty_list;
      state.tagList = payload.tag_list;
      break;
    case TAGS_FAILURE_ACTION:
      state.isTagsError = true;
      state.isTagsLoading = false;
      break;
    case ADD_NEW_DIFFICULTY:
      state.difficultyList = [...state.difficultyList, payload];
      break;
    case ADD_NEW_TAG:
      state.tagList = [...state.tagList, payload];
      break;
    case CLEAR_TAGS:
      return { ...initialState };
    default:
      return state;
  }
});
export default tagsReducer;
