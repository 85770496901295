import produce from 'immer';

import { INPUT_OUTPUT } from 'constants/actionConstants';

export const initialState = {
  outputValue: '',
  inputValue: '',
  outputLoading: false,
  token: 'test_token',
};
const { SET_OUTPUT, SET_INPUT, CLEAR, SET_OUTPUT_REQUEST, SET_TOKEN } = INPUT_OUTPUT;

const ioReducer = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_OUTPUT:
      return { ...state, outputValue: payload };
    case SET_INPUT:
      return { ...state, inputValue: payload };
    case SET_OUTPUT_REQUEST:
      return { ...state, outputLoading: true };
    case SET_TOKEN:
      return { ...state, token: payload, outputLoading: true };
    case CLEAR:
      return { ...initialState };
    default: return state;
  }
});

export default ioReducer;
