import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import QuestionComponent from 'modules/admin/question/QuestionComponent';

import {
  questionsListRequest,
  questionsListClear,
  setQuestionsOffset,
  deleteQuestionRequestAction,
} from 'redux/admin/questions/action';

import { openNewWindow } from 'utils/window';

import { QUESTIONS_LIMIT } from 'constants/appConstants';
import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';

const QuestionContainer = () => {
  const dispatch = useDispatch();
  const observer = useRef();
  let { current: currentObserver } = observer;
  const history = useHistory();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [targetId, setTargetId] = useState();
  const [mcqDescription, setMcqDescription] = useState();

  const toggleConfirmation = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleDeleteClick = (id, description) => {
    setTargetId(id);
    setMcqDescription(description);
  };

  const {
    questions,
    totalQuestions,
    isQuestionsLoading,
    offset: currentPageQuestions,
    deletionCount,
    questionSearchText,
    questionDifficulty,
    questionTag,
  } = useSelector((item) => item.questionsListReducer);

  const { isRefetchQuestionList } = useSelector(
    (state) => state.mcqBulkUploadReducer,
  );

  const lastBookElementRef = useCallback((node) => {
    if (isQuestionsLoading) return;
    if (currentObserver) currentObserver.disconnect();

    currentObserver = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (questions.length >= QUESTIONS_LIMIT || deletionCount !== 0) {
          dispatch(setQuestionsOffset(currentPageQuestions + QUESTIONS_LIMIT - deletionCount));
        }
      }
    });

    if (node) currentObserver.observe(node);
  }, [isQuestionsLoading, deletionCount]);

  useEffect(() => {
    if (!isQuestionsLoading && currentPageQuestions <= totalQuestions) {
      dispatch(questionsListRequest({
        offset: currentPageQuestions,
        questions,
        searchedQuestionText: questionSearchText,
        searchedQuestionTag: questionTag,
        searchedQuestionDifficulty: questionDifficulty,
      }));
    }
  }, [currentPageQuestions, isRefetchQuestionList]);

  useEffect(() => {
    return () => {
      dispatch(questionsListClear());
    };
  }, []);

  useEffect(() => {
    if (isRefetchQuestionList) {
      dispatch(questionsListClear());
    }
  }, [isRefetchQuestionList]);

  const onClickQuestion = (question) => {
    openNewWindow(`/admin/question/${question.id}/edit`);
  };

  const handleAddQuestionClick = () => {
    history.push(ROUTES.ADMIN + ADMIN_ROUTES.CREATE_QUESTION);
  };

  const handleOnQuestionDelete = (id) => {
    dispatch(deleteQuestionRequestAction(id));
  };

  return (
    <QuestionComponent
      questions={questions}
      totalQuestions={totalQuestions}
      isQuestionsLoading={isQuestionsLoading}
      lastBookElementRef={lastBookElementRef}
      handleAddQuestionClick={handleAddQuestionClick}
      onClickQuestion={onClickQuestion}
      toggleConfirmation={toggleConfirmation}
      targetId={targetId}
      mcqDescription={mcqDescription}
      handleDeleteClick={handleDeleteClick}
      confirmationModal={confirmationModal}
      handleOnQuestionDelete={handleOnQuestionDelete}
    />
  );
};

export default React.memo(QuestionContainer);
