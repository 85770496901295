import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import LoginComponent from 'modules/admin/login/LoginComponent';

import { adminLoginRequestAction, adminLoginResetAction } from 'redux/admin/login/action';
import { reducer } from 'modules/admin/login/LoginContainer/reducer';
import { schema } from 'modules/admin/login/LoginContainer/schema';

import { getAccessToken, getUserRole } from 'utils/helpers/userRoleHelper';
import { capitalize } from 'utils/capitalize';

import { ADMIN_ROUTES, ROUTES, SUPER_ADMIN_ROUTES } from 'constants/routeConstants';

const LoginContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userRole = getUserRole();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const initialUserState = {
    email: '',
    password: '',
    emailError: '',
    passwordError: '',
  };

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [loginState, setLoginState] = useReducer(reducer, initialUserState);

  const { isAuth, isLoading, isError, errorMessage, role } = useSelector(
    (state) => state.adminLoginReducer,
  );

  useEffect(() => {
    if (urlParams.get('unauthorized') === 'true') {
      toast.error('Unauthorized User');
      history.push('/login');
    }
  }, [urlParams]);

  useEffect(() => {
    return dispatch(adminLoginResetAction());
  }, []);

  const handleEmailChange = useCallback(
    (event) => {
      const email = event.target.value.toLowerCase();
      setLoginState({
        type: 'email',
        payload: { event, email },
      });
      schema.validate({ email }).then(() => {
        setLoginState({
          type: 'emailError',
          payload: '',
        });
      });
    },
    [loginState],
  );

  const handlePasswordChange = useCallback(
    (event) => {
      const password = event.target.value;
      setLoginState({
        type: 'password',
        payload: { event, password },
      });
      schema
        .validate({
          password,
        })
        .then(() => {
          setLoginState({
            type: 'passwordError',
            payload: '',
          });
        });
    },
    [loginState],
  );

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    const { email, password } = loginState;
    schema
      .validate(
        {
          email,
          password,
        },
        { abortEarly: false },
      )
      .then((response) => {
        const data = {
          email,
          password,
        };
        dispatch(adminLoginRequestAction(data));
      })
      .catch((error) => {
        let isPasswordError = false;

        error.inner.forEach((e) => {
          switch (e.path) {
            case 'email':
              setLoginState({
                type: 'emailError',
                payload: capitalize(e.message),
              });
              break;
            case 'password':
              if (!isPasswordError) {
                setLoginState({
                  type: 'passwordError',
                  payload: capitalize(e.message),
                });
              }
              isPasswordError = true;
              break;
            default:
              break;
          }
        });
      });
  });

  if (isAuth) {
    if (role === 'SuperAdmin') {
      history.push(ROUTES.SUPER_ADMIN + SUPER_ADMIN_ROUTES.ORGANIZATIONS);
    } else if (role === 'Admin') {
      history.push(ROUTES.ADMIN + ADMIN_ROUTES.HOME);
    }
  }

  if (getAccessToken()) {
    if (userRole === 'Admin') {
      history.push(ROUTES.ADMIN + ADMIN_ROUTES.HOME);
    } else if (userRole === 'SuperAdmin') {
      history.push(ROUTES.SUPER_ADMIN + SUPER_ADMIN_ROUTES.ORGANIZATIONS);
    }
  }

  return (
    <LoginComponent
      handleEmailChange={handleEmailChange}
      handlePasswordChange={handlePasswordChange}
      handleSubmit={handleSubmit}
      emailError={loginState.emailError}
      passwordError={loginState.passwordError}
      email={loginState.email}
      password={loginState.password}
      isLoading={isLoading}
      isError={isError}
      errorMessage={errorMessage}
      passwordVisibility={passwordVisibility}
      setPasswordVisibility={setPasswordVisibility}
    />
  );
};

export default React.memo(LoginContainer);
