import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'core-components/index';
import './closeButton.scss';

const CloseButton = ({ onClick, className }) => {
  return (
    <>
      <Button
        onClick={onClick}
        className={`${className} text-decoration-none close-button d-flex align-items-center`}
        color='link'
      >
        <Icon name='close' />
      </Button>
    </>
  );
};

CloseButton.defaultProps = {
  className: '',
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default CloseButton;
