import produce from 'immer';

import { ASSESSMENT_LIST } from 'redux/admin/assessmentList/actionConstants';

export const initialState = {
  assessments: [],
  assessmentsLoading: false,
  totalAssessments: 0,
  assessmentsFailureMessage: '',
  deleteAssessmentFailureMessage: '',
  totalpages: 0,
  assessmentOffset: 0,
  assessmentDeletionCount: 0,
};
const { ASSESSMENT_LIST_REQUEST, ASSESSMENT_LIST_SUCCESS, ASSESSMENT_LIST_FAILURE,
  ASSESSMENT_DELETE_REQUEST, ASSESSMENT_DELETE_SUCCESS, ASSESSMENT_DELETE_FAILURE,
  CLEAR_ASSESSMENTS, ADD_ASSESSMENT_OFFSET } = ASSESSMENT_LIST;
const assessmentsListReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ASSESSMENT_LIST_REQUEST:
      return {
        ...state,
        assessmentsLoading: true,
        assessments: payload.assessments,
        assessmentOffset: payload.assessmentOffset,
        assessmentDeletionCount: 0,
      };
    case ASSESSMENT_LIST_SUCCESS:
      return {
        ...state,
        assessments: [...state.assessments, ...payload.assessments],
        totalAssessments: payload.total_assessments,
        assessmentsLoading: false,
      };
    case ASSESSMENT_LIST_FAILURE:
      return {
        ...state,
        assessmentsFailureMessage: payload.message,
        assessmentsLoading: false,
      };

    case ASSESSMENT_DELETE_REQUEST:
      return { ...state };
    case ASSESSMENT_DELETE_SUCCESS:
      state.assessments = state.assessments.filter((assessment) => assessment.id !== payload);
      state.assessmentDeletionCount += 1;
      state.totalAssessments -= 1;
      break;
    case ASSESSMENT_DELETE_FAILURE:
      return { ...state, deleteAssessmentFailureMessage: payload };

    case CLEAR_ASSESSMENTS:
      return { ...initialState };
    case ADD_ASSESSMENT_OFFSET:
      return { ...state, assessmentOffset: payload };
    default:
      return state;
  }
});

export default assessmentsListReducer;
