import { VERIFY_FORGOT_PASSWORD } from 'redux/admin/verifyForgotPassword/actionConstants';

export const verifyPasswordRequestAction = (payload) => ({
  type: VERIFY_FORGOT_PASSWORD.VERIFY_FORGOT_PASSWORD_REQUEST_ACTION,
  payload,
});

export const verifyPasswordSuccessAction = (payload) => ({
  type: VERIFY_FORGOT_PASSWORD.VERIFY_FORGOT_PASSWORD_SUCCESS_ACTION,
  payload,
});

export const verifyPasswordFailureAction = (payload) => ({
  type: VERIFY_FORGOT_PASSWORD.VERIFY_FORGOT_PASSWORD_FAILURE_ACTION,
  payload,
});
