import produce from 'immer';
import { FEEDBACKS } from 'redux/admin/feedbacks/actionContants';

export const initialState = {
  feedbacks: [],
  totalFeedbacks: 0,
  totalpages: 1,
  isFeedbacksLoading: false,
  offset: 0,
  feedbacksErrorMessage: '',
  isFeedbackDeleteLoading: false,
  feedbacksDeleteErrorMessage: '',
  deletionCount: 0,
};

const feedbackListReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FEEDBACKS.FEEDBACKS_REQUEST_ACTION:
      return {
        ...state,
        isFeedbacksLoading: true,
      };
    case FEEDBACKS.FEEDBACKS_SUCCESS_ACTION:
      return {
        ...state,
        feedbacks: [...state.feedbacks, ...payload.feedbacks],
        totalFeedbacks: payload.feedbacks_count,
        isFeedbacksLoading: false,
      };
    case FEEDBACKS.FEEDBACKS_FAILURE_ACTION:
      return {
        ...state,
        feedbacksErrorMessage: payload.message,
        isFeedbacksLoading: false,
      };
    case FEEDBACKS.CLEAR_FEEDBACKS:
      return { ...initialState };
    case FEEDBACKS.SET_FEEDBACKS_LIMIT:
      return { ...state, offset: payload };
    case FEEDBACKS.FEEDBACKS_DELETE_REQUEST_ACTION:
      return {
        ...state,
        isFeedbackDeleteLoading: true,
      };
    case FEEDBACKS.FEEDBACKS_DELETE_SUCCESS_ACTION:
      state.isFeedbackDeleteLoading = false;
      state.feedbacks = state.feedbacks.filter((feedback) => feedback.id !== payload);
      state.deletionCount += 1;
      state.totalFeedbacks -= 1;
      break;
    case FEEDBACKS.FEEDBACKS_DELETE_FAILURE_ACTION:
      return {
        ...state,
        feedbacksDeleteErrorMessage: payload.message,
        isFeedbackDeleteLoading: false,
      };
    default:
      return state;
  }
});
export default feedbackListReducer;
