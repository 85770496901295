import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Input,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Spinner,
  Icon,
} from 'core-components';

import './resetPasswordCard.scss';

const ResetPasswordCard = (props) => {
  const {
    handlePasswordConfirmationChange,
    handlePasswordChange,
    handleSubmit,
    handleCopy,
    handlePaste,
    resetPasswordState,
    isError,
    isLoading,
    errorMessage,
    successMessage,
    passwordVisibility,
    setPasswordVisibility,
    confirmPasswordVisibility,
    setConfirmPasswordVisibility,
  } = props;

  const {
    passwordError,
    passwordConfirmationError,
  } = resetPasswordState;

  return (
    <div className='login-card-box position-absolute'>
      <div className='d-flex justify-content-center mt-5'>
        <h2 className='font-weight-normal'>Reset Password</h2>
      </div>
      <Form className='login-form d-flex flex-column mt-5'>
        <FormGroup>
          <div className='reset-password'>
            <Input
              type={passwordVisibility ? 'text' : 'password'}
              className='shadow'
              id='examplePassword'
              placeholder='Password'
              onChange={handlePasswordChange}
              onCopy={handleCopy}
              onPaste={handlePaste}
              invalid={passwordError !== ''}
            />
            <span
              role='button'
              onClick={() => setPasswordVisibility(!passwordVisibility)}
              className={`${passwordError && 'reset-password__error'} reset-password__show`}
              onKeyDown={(event) => event.key === 'Enter' && setPasswordVisibility(!passwordVisibility)}
              tabIndex={0}
            >
              {passwordVisibility ? (
                <Icon
                  name='show-password'
                />
              ) : (
                <Icon
                  name='hide-password'
                />
              )}
            </span>
            <FormFeedback
              type='invalid'
              target='examplePassword'
            >
              {passwordError}
            </FormFeedback>
          </div>
        </FormGroup>
        <FormGroup>
          <div className='reset-password'>
            <Input
              type={confirmPasswordVisibility ? 'text' : 'password'}
              className='shadow mt-3'
              id='examplePassword'
              placeholder='Confirm Password'
              onChange={handlePasswordConfirmationChange}
              onCopy={handleCopy}
              onPaste={handlePaste}
              invalid={passwordConfirmationError !== ''}
            />
            <span
              role='button'
              onClick={() => setConfirmPasswordVisibility(!confirmPasswordVisibility)}
              className={`${passwordConfirmationError && 'reset-password__error'} reset-password__show`}
              onKeyDown={(event) => event.key === 'Enter' &&  setConfirmPasswordVisibility(!confirmPasswordVisibility)}
              tabIndex={0}
            >
              {confirmPasswordVisibility ? (
                <Icon
                  name='show-password'
                />
              ) : (
                <Icon
                  name='hide-password'
                />
              )}
            </span>
            <FormFeedback
              type='invalid'
              target='examplePassword'
            >
              {passwordConfirmationError}
            </FormFeedback>
          </div>
        </FormGroup>
        <FormGroup className='mt-5 d-flex justify-content-center'>
          <Button
            className='w-75 border-0'
            onClick={handleSubmit}
            color='primary'
          >
            {isLoading ? (
              <Spinner
                size='sm'
                color='light'
              />
            ) : <>Reset Password</>}
          </Button>
        </FormGroup>
        <div className='text-center text-warning '>
          {errorMessage}
        </div>
        <div className='text-center text-warning '>
          {successMessage}
        </div>
        <div className='mt-4 login-details-box d-flex justify-content-center'>
          <Link
            to='/'
            className='login-link text-center text-underline'
          >
            Back to Login
          </Link>
        </div>
      </Form>
    </div>
  );
};

ResetPasswordCard.propTypes = {
  handlePasswordChange: PropTypes.func.isRequired,
  handlePasswordConfirmationChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCopy: PropTypes.func.isRequired,
  handlePaste: PropTypes.func.isRequired,
  resetPasswordState: PropTypes.objectOf(PropTypes.any).isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  passwordVisibility: PropTypes.bool.isRequired,
  setPasswordVisibility: PropTypes.func.isRequired,
  confirmPasswordVisibility: PropTypes.bool.isRequired,
  setConfirmPasswordVisibility: PropTypes.func.isRequired,
};

export default React.memo(ResetPasswordCard);
