import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup } from 'reactstrap';
import { FieldArray } from 'formik';

import {
  FormGroup,
  Input,
  Button,
  Icon,
  Label,
} from 'core-components';
import { ImagePopUpModal } from 'shared-components';

import 'modules/admin/createQuestion/CreateQuestionComponent/createQuestionComponent.scss';
import './OptionComponent.scss';

const OptionComponent = ({
  handleDeleteClick,
  showImageModal,
  toggleImageModalVisibility,
  formik,
  isOptionsPresent,
  handleUploadImage,
  onImageClick,
  optionPopUpImage,
}) => {
  const scrollDiv = createRef();
  const { mcqOptions } = formik.values;

  const scrollSmoothHandler = () => {
    scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='options-section d-flex flex-column'>
      <div className='options-list-wrap scroll-y'>
        <ImagePopUpModal
          showImageModal={showImageModal}
          toggleImageModalVisibility={toggleImageModalVisibility}
          imageUrl={optionPopUpImage}
          alternateName='Option Image'
        />
        <div className='d-grid'>
          <FieldArray name='mcqOptions'>
            <>
              <div className='d-flex align-items-center mb-3'>
                <h2 className='options-section-head font-weight-bold mb-0'>Options</h2>
              </div>
              {/* TO DO
                  add new component for following  div */}
              {mcqOptions.map((mcqOption, index) => {
                const {
                  description,
                  isAnswer,
                  optionDiagram: savedOptionDiagram,
                  imagePreview,
                  optionImageError,
                  isDelete,
                } = mcqOption;

                const optionDiagram = imagePreview || savedOptionDiagram;

                return isDelete ? <></> : ((
                  <div
                    className='edit-option-box p-3 d-flex align-items-center mb-3'
                    ref={scrollDiv}
                  >
                    <span className='sequence-no mr-5'>
                      {String.fromCharCode(index + 65)}.
                    </span>
                    <FormGroup className='mb-0 d-flex flex-column mr-3'>
                      <Input
                        name={`mcqOptions[${index}].description`}
                        type='textarea'
                        invalid={
                          formik.touched.mcqOptions &&
                          formik.touched.mcqOptions[index]?.description &&
                          formik.touched.mcqOptions[index]?.optionDiagram &&
                          formik.errors.mcqOptions &&
                          formik.errors.mcqOptions[index]?.descriptionOrOptionsDiagram
                        }
                        value={description}
                        className='height100'
                        onChange={formik.handleChange}
                        placeholder='Type new option here'
                        onBlur={formik.handleBlur}
                      />
                    </FormGroup>
                    <div className='d-flex flex-1 flex-column mr-3'>
                      <div className='options-image-upload mb-3'>
                        <FormGroup className='mb-0'>
                          <Label for='exampleText' className=''>
                            Upload Diagram
                          </Label>
                          <Input
                            type='file'
                            name='text'
                            id='exampleFile'
                            accept='image/*'
                            optionDiagram
                            onInputCapture={(e) => { handleUploadImage(e, mcqOption, index); }}
                            onBlur={() => formik.setFieldTouched(`mcqOptions[${index}].optionDiagram`, true)}
                          />
                          {
                            formik.touched.mcqOptions &&
                            formik.touched.mcqOptions[index]?.description &&
                            formik.touched.mcqOptions[index]?.optionDiagram &&
                            formik.errors.mcqOptions &&
                            formik.errors.mcqOptions[index]?.descriptionOrOptionsDiagram &&
                            <div className='text-danger'>{formik.errors.mcqOptions[index]?.descriptionOrOptionsDiagram}</div>
                          }
                        </FormGroup>
                        {optionImageError && (
                          <div className='text-danger pt-2'>
                            {optionImageError}
                          </div>
                        )}
                      </div>
                      <div className='select-correct-option w-100'>
                        <span className='pr-4'>
                          Is this the right answer ? *
                        </span>
                        <ButtonGroup>
                          <Button
                            name={`mcqOptions[${index}].isAnswer`}
                            color='secondary'
                            outline
                            onClick={() => {
                              formik.setFieldValue(
                                `mcqOptions[${index}].isAnswer`,
                                true,
                              );
                            }}
                            active={isAnswer}
                          >
                            Yes
                          </Button>
                          <Button
                            color='secondary'
                            outline
                            onClick={() => {
                              formik.setFieldValue(
                                `mcqOptions[${index}].isAnswer`,
                                false,
                              );
                            }}
                            active={!isAnswer}
                          >
                            No
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                    {optionDiagram && (
                      <div
                        className=' option-diagram-wrapper'
                        onClick={() => onImageClick(mcqOption)}
                        role='button'
                        tabIndex={0}
                      >
                        <img
                          src={optionDiagram}
                          alt='Option Img'
                          className='image-wrapper'
                        />
                      </div>
                    )}
                    {isOptionsPresent(mcqOptions) && (
                      <Button
                        onClick={() => {
                          handleDeleteClick(index, description);
                        }}
                        color='link'
                        title='Remove Option'
                        className='p-0 mr-0 text-decoration-none'
                      >
                        <Icon name='close' />
                      </Button>
                    )}
                  </div>
                ));
              })}
            </>
          </FieldArray>
        </div>
      </div>
    </div>
  );
};

OptionComponent.propTypes = {
  handleDeleteClick: PropTypes.func.isRequired,
  showImageModal: PropTypes.bool.isRequired,
  toggleImageModalVisibility: PropTypes.func.isRequired,
  formik: PropTypes.shape().isRequired,
  isOptionsPresent: PropTypes.func.isRequired,
  handleUploadImage: PropTypes.func.isRequired,
  onImageClick: PropTypes.func.isRequired,
  optionPopUpImage: PropTypes.string.isRequired,
};

export default React.memo(OptionComponent);
