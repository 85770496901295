import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import { useSelector } from 'react-redux';

import SideNavContainer from 'modules/admin/sideNav/SideNavContainer';

const withLayout = (WrappedComponent, includeSidebar) => (
  props,
) => {
  const {
    isLoading,
  } = useSelector((item) => item.downloadMcqReducer);

  return (
    <>
      {isLoading && (
        <div className='loader-wrapper align-items-center justify-content-center'>
          <Spinner className='loader' color='primary' />
        </div>
      )}
      <main className='sp-main d-flex flex-1 scroll-y'>
        {includeSidebar && <SideNavContainer />}
        <section className='sp-section d-flex flex-column flex-1 scroll-y'>
          <WrappedComponent {...props} />
        </section>
      </main>

    </>
  );
};

withLayout.propTypes = {
  WrappedComponent: PropTypes.func.isRequired,
  includeSidebar: PropTypes.bool.isRequired,
};

export default withLayout;
