import React from 'react';
import PropTypes from 'prop-types';

import SideNavContainer from 'modules/superAdmin/sideNav/SideNavContainer';

const superAdminLayout = (WrappedComponent, includeSidebar) => (
  props,
) => {
  return (
    <>
      <main className='sp-main d-flex flex-1 scroll-y'>
        {includeSidebar && <SideNavContainer />}
        <section className='sp-section d-flex flex-column flex-1 scroll-y'>
          <WrappedComponent {...props} />
        </section>
      </main>

    </>
  );
};

superAdminLayout.propTypes = {
  WrappedComponent: PropTypes.func.isRequired,
  includeSidebar: PropTypes.bool.isRequired,
};

export default superAdminLayout;
