import produce from 'immer';

import {
  ASSESSMENT_CANDIDATES_LIST,
  ASSESSMENT_CANDIDATE,
  ASSESSMENT_CANDIDATE_REPORTS,
} from 'redux/admin/assessmentCandidates/actionConstants';

export const initialState = {
  candidates: [],
  isLoading: false,
  isReportsLoading: false,
  isAssessmentCandidateActive: false,
  resultTotalPages: 1,
  resultCurrentPage: 1,
  totalCandidatesPages: 1,
  assessmentName: '',
  totalCandidates: 0,
};

const assessmentCandidatesReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ASSESSMENT_CANDIDATES_LIST.ASSESSMENT_CANDIDATES_LIST_REQUEST:
      return { ...state, isLoading: true, candidates: [] };
    case ASSESSMENT_CANDIDATES_LIST.ASSESSMENT_CANDIDATES_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        candidates: [...payload.candidates],
        totalCandidatesPages: payload.pages,
        assessmentName: payload.name,
        totalCandidates: payload.total_candidates,
      };
    case ASSESSMENT_CANDIDATE.ASSESSMENT_CANDIDATE_ACTIVE_STATUS:
      return {
        ...state,
        isAssessmentCandidateActive: payload,
      };
    case ASSESSMENT_CANDIDATE_REPORTS.ASSESSMENT_CANDIDATE_REPORT_REQUEST:
      return {
        ...state,
        isReportsLoading: true,
        candidates: [],
      };
    case ASSESSMENT_CANDIDATE_REPORTS.ASSESSMENT_CANDIDATE_REPORT_SUCCESS:
      return {
        ...state,
        candidates: [...payload.result],
        isReportsLoading: false,
        resultTotalPages: payload.pages,
        resultCurrentPage: payload.page,
        assessmentName: payload.name,
      };
    default:
      return state;
  }
});

export default assessmentCandidatesReducer;
