import { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AssessmentsListComponent from 'modules/admin/assessmentsList/AssessmentsListComponent';

import {
  assessementDeleteRequest,
  assessementListRequest,
  clearAssessmentsAction,
  setAssessmentOffset,
} from 'redux/admin/assessmentList/action';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';
import { ASSESSMENT_LIMIT } from 'constants/appConstants';

const AssessmentsListContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const observer = useRef();

  const [searchText, setSearchText] = useState();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [targetId, setTargetId] = useState();
  const [assessmentName, setAssessmentName] = useState();
  const [isSearched, setIsSearched] = useState(false);

  const {
    assessments,
    assessmentsLoading,
    totalAssessments,
    assessmentDeletionCount,
    assessmentOffset,
  } = useSelector((item) => item.assessmentsListReducer);

  useEffect(() => {
    if (!assessmentsLoading && assessmentOffset <= totalAssessments) {
      dispatch(assessementListRequest({
        assessmentOffset,
        searchText,
        assessments,
      }));
    }
  }, [assessmentOffset]);

  useEffect(() => {
    return () => {
      dispatch(clearAssessmentsAction());
    };
  }, []);

  const toggleConfirmation = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleDeleteClick = (id, name) => {
    setTargetId(id);
    setAssessmentName(name);
  };

  const lastBookElementRef = useCallback((node) => {
    if (assessmentsLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (assessments.length >= ASSESSMENT_LIMIT || assessmentDeletionCount !== 0) {
          dispatch(
            setAssessmentOffset(
              assessmentOffset + ASSESSMENT_LIMIT - assessmentDeletionCount,
            ),
          );
        }
      }
    });
    if (node) observer.current.observe(node);
  }, [assessmentsLoading, assessmentDeletionCount]);

  const onClickEdit = (assessmentId) => {
    history.push(`/admin/assessment/${assessmentId}/edit`);
  };

  const onClickResult = (assessment) => {
    localStorage.setItem('showAssessmentUUID', assessment.uuid);
    history.push(`/admin/assessment/${assessment.id}/reports`);
  };

  const onClickCandidates = (assessment) => {
    localStorage.setItem('showAssessmentUUID', assessment.uuid);
    history.push(`/admin/assessment/${assessment.id}/candidates`);
  };

  const handleAddAssessmentClick = () => {
    history.push(ROUTES.ADMIN + ADMIN_ROUTES.CREATE_ASSESSMENT);
  };

  const handleOnChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, [searchText]);

  // TO DO
  const handleSearchClick = useCallback(async () => {
    if (searchText || isSearched) {
      if (!assessmentsLoading) {
        dispatch(assessementListRequest({
          assessmentOffset: 0,
          searchText: searchText.trim(),
          assessments: [],
        }));
        if (!searchText && isSearched) {
          setIsSearched(false);
        } else {
          setIsSearched(true);
        }
      }
    }
  }, [searchText]);

  const handleAssessmentSearchClearClick = () => {
    setSearchText('');
    if (isSearched) {
      dispatch(assessementListRequest({
        assessmentOffset: 0,
        searchText: '',
        assessments: [],
      }));
    }
    setIsSearched(false);
  };

  const onClickDelete = (problemId) => {
    dispatch(assessementDeleteRequest({ id: problemId }));
  };

  return (
    <AssessmentsListComponent
      assessments={assessments}
      assessmentsLoading={assessmentsLoading}
      handleAddAssessmentClick={handleAddAssessmentClick}
      onClickEdit={onClickEdit}
      onClickResult={onClickResult}
      onClickDelete={onClickDelete}
      handleOnChange={handleOnChange}
      handleSearchClick={handleSearchClick}
      handleAssessmentSearchClearClick={handleAssessmentSearchClearClick}
      totalAssessments={totalAssessments}
      toggleConfirmation={toggleConfirmation}
      targetId={targetId}
      assessmentName={assessmentName}
      handleDeleteClick={handleDeleteClick}
      confirmationModal={confirmationModal}
      onClickCandidates={onClickCandidates}
      lastBookElementRef={lastBookElementRef}
      searchText={searchText}
    />
  );
};

export default AssessmentsListContainer;
