import React from 'react';
import PropTypes from 'prop-types';
import './filterComponent.scss';

const FilterComponent = ({ count, text, onClickAddFilter }) => {
  return (
    <>
      <div className='filter-box d-flex px-3 align-items-center'>
        <p className='mb-0 mr-3 font-weight-bold'>{count} {text}</p>
        <div role='button' className='add-filter' tabIndex={0} onClick={onClickAddFilter} onKeyDown={() => {}}>
          <span>Add Filter</span>
        </div>
      </div>
    </>
  );
};

FilterComponent.defaultProps = {
  onClickAddFilter: () => {},
};

FilterComponent.propTypes = {
  count: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  onClickAddFilter: PropTypes.func,
};

export default FilterComponent;
