import { COMPANY_SIGN_UP, SIGN_UP_TOKEN_VALIDITY } from './actionConstants';

const {
  COMPANY_SIGN_UP_REQUEST,
  COMPANY_SIGN_UP_SUCCESS,
  COMPANY_SIGN_UP_FAILURE,
} = COMPANY_SIGN_UP;

const {
  SIGN_UP_TOKEN_VALIDITY_REQUEST,
  SIGN_UP_TOKEN_VALIDITY_SUCCESS,
  SIGN_UP_TOKEN_VALIDITY_FAILURE,
} = SIGN_UP_TOKEN_VALIDITY;

export const companySignUpRequest = (payload) => ({
  type: COMPANY_SIGN_UP_REQUEST,
  payload,
});

export const companySignUpFailure = () => ({
  type: COMPANY_SIGN_UP_FAILURE,
});

export const companySignUpSuccess = () => ({
  type: COMPANY_SIGN_UP_SUCCESS,
});

export const signUpTokenValidityRequest = (payload) => ({
  type: SIGN_UP_TOKEN_VALIDITY_REQUEST,
  payload,
});

export const signUpTokenValiditySuccess = () => ({
  type: SIGN_UP_TOKEN_VALIDITY_SUCCESS,
});

export const signUpTokenValidityFailure = () => ({
  type: SIGN_UP_TOKEN_VALIDITY_FAILURE,
});
