import { CREATE_QUESTION } from 'redux/admin/createQuestion/actionConstants';

export const createQuestionRequestAction = (payload) => ({
  type: CREATE_QUESTION.CREATE_QUESTION_REQUEST_ACTION,
  payload,
});

export const createQuestionSuccessAction = (payload) => ({
  type: CREATE_QUESTION.CREATE_QUESTION_SUCCESS_ACTION,
  payload,
});

export const createQuestionFailureAction = (payload) => ({
  type: CREATE_QUESTION.CREATE_QUESTION_FAILURE_ACTION,
  payload,
});

export const clearQuestionState = () => ({
  type: CREATE_QUESTION.CLEAR_STATE,
});
