import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import AcceptInvitationComponent from 'modules/admin/acceptInvitation/AcceptInvitationComponent';

import { reducer } from 'modules/admin/acceptInvitation/AcceptInvitationContainer/reducer';
import { schema } from 'modules/admin/acceptInvitation/AcceptInvitationContainer/schema';
import { validateData } from 'modules/admin/acceptInvitation/dataValidation';
import { companySignUpRequest, signUpTokenValidityRequest } from 'redux/admin/signUpCompany/actions';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';

const AcceptInvitationContainer = () => {
  const history = useHistory();
  const { token } = useParams();
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem('accessToken');

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false);

  const { isLoading, isError, errorMessage } = useSelector(
    (state) => state.adminLoginReducer,
  );

  const {
    isSignUpSuccess,
    isTokenInvalid,
  } = useSelector((state) => state.signUpUser);

  useEffect(() => {
    dispatch(signUpTokenValidityRequest({ invitation_token: token }));
  }, [token]);

  useEffect(() => {
    if (isTokenInvalid) {
      history.push(ROUTES.INVALID_TOKEN);
    }
  }, [isTokenInvalid]);

  useEffect(() => {
    if (isSignUpSuccess) {
      history.push(ROUTES.LOGIN);
    }
  }, [isSignUpSuccess]);

  useEffect(() => {
    if (accessToken) {
      history.push(ROUTES.ADMIN + ADMIN_ROUTES.HOME);
    }
  }, [accessToken]);

  const initialUserState = {
    firstName: '',
    lastName: '',
    mobileNumber: '',
    password: '',
    passwordConfirmation: '',
    firstNameError: '',
    lastNameError: '',
    mobileNumberError: '',
    passwordError: '',
    passwordConfirmationError: '',
    organizationName: '',
    organizationNameError: '',
  };
  const [signUpState, setSignUpState] = useReducer(reducer, initialUserState);

  useEffect(() => {
    if (isSignUpSuccess) {
      history.push(ROUTES.LOGIN);
    }
  }, [isSignUpSuccess]);

  const handleFirstNameChange = useCallback(
    (event) => {
      const firstName = event.target.value;
      setSignUpState({
        type: 'firstName',
        payload: firstName,
      });
    },
    [signUpState],
  );

  const handleLastNameChange = useCallback((event) => {
    const lastName = event.target.value;
    setSignUpState({
      type: 'lastName',
      payload: lastName,
    });
  }, []);

  const handleMobileNumberChange = useCallback((event) => {
    const mobileNumber = event.target.value;
    setSignUpState({
      type: 'mobileNumber',
      payload: mobileNumber,
    });
  }, []);

  const handlePasswordChange = useCallback((event) => {
    const password = event.target.value;
    setSignUpState({
      type: 'password',
      payload: password,
    });
  }, []);

  const handlePasswordConfirmationChange = useCallback((event) => {
    const passwordConfirmation = event.target.value;
    setSignUpState({
      type: 'passwordConfirmation',
      payload: passwordConfirmation,
    });
  }, []);

  const handleOrganizationNameChange = useCallback(
    (event) => {
      setSignUpState({
        type: 'organizationName',
        payload: event.target.value,
      });
    },
    [signUpState],
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const {
        firstName,
        lastName,
        mobileNumber,
        password,
        passwordConfirmation,
        organizationName,
      } = signUpState;
      const data = {
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        mobile_number: mobileNumber,
        password,
        password_confirmation: passwordConfirmation,
        invitation_token: token,
        show_name: organizationName.trim(),
      };

      schema.isValid(data).then(async (valid) => {
        if (!valid) {
          validateData(schema, data, setSignUpState);
        } else {
          dispatch(companySignUpRequest(data));
        }
      });
    },
    [signUpState],
  );

  return (
    <AcceptInvitationComponent
      handleFirstNameChange={handleFirstNameChange}
      handleLastNameChange={handleLastNameChange}
      handleMobileNumberChange={handleMobileNumberChange}
      handlePasswordChange={handlePasswordChange}
      handlePasswordConfirmationChange={handlePasswordConfirmationChange}
      handleSubmit={handleSubmit}
      signUpState={signUpState}
      isLoading={isLoading}
      isError={isError}
      errorMessage={errorMessage}
      passwordVisibility={passwordVisibility}
      setPasswordVisibility={setPasswordVisibility}
      confirmPasswordVisibility={confirmPasswordVisibility}
      setConfirmPasswordVisibility={setConfirmPasswordVisibility}
      handleOrganizationNameChange={handleOrganizationNameChange}
    />
  );
};

export default React.memo(AcceptInvitationContainer);
