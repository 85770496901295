import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import TestCaseComponent from 'modules/admin/testCase/TestCaseComponent';

import { deleteTestCaseApi, postTestCaseApi, updateTestCaseApi } from 'redux/admin/testCase/api';
import { validateData } from 'modules/admin/testCase/dataValidation';
import { testListRequest } from 'redux/admin/testCaseList/action';
import { schema } from './schema';

const problemSaved = (problemId) => {
  return problemId === '';
};

const TestCaseContainer = (props) => {
  const {
    scrollRef,
    isTestCaseCreate,
    testCasesList,
    setTestCasesList,
    handleSubmit,
    testCaseError,
  } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { message, isSuccess } = useSelector((state) => state.createProblemReducer);
  const testCaseState = useSelector((state) => state.testCaseReducer);
  const { problem_id: createProblemId } = useSelector((state) => state.createProblemReducer);
  const { problem_id: editProblemId } = useSelector((state) => state.editProblemReducer);

  const problemId = createProblemId || editProblemId;

  const handleInputChange = useCallback(
    (event, index) => {
      const input = event.target.value;
      const newArray = [...testCasesList];
      newArray[index].input = input;
      // set the input value in test case array
      setTestCasesList(newArray);
      // delete input error for that index
      const inputError = `testCasesList[${index}].input`;
      if (inputError in testCaseError) {
        delete testCaseError[inputError];
      }
    },
    [testCasesList],
  );

  const handleOutputChange = useCallback(
    (event, index) => {
      const output = event.target.value;
      const newArray = [...testCasesList];
      newArray[index].output = output;
      // set the output value in test case array
      setTestCasesList(newArray);
      // delete output error for that index
      const outputError = `testCasesList[${index}].output`;
      if (outputError in testCaseError) {
        delete testCaseError[outputError];
      }
    },
    [testCasesList],
  );

  const handleMarksChange = useCallback(
    (event, index) => {
      const marks = event.target.value;
      const newArray = [...testCasesList];
      newArray[index].marks = parseInt(marks, 10) || 0;
      // set the marks value in test case array
      setTestCasesList(newArray);
      // delete marks error for that index
      const marksError = `testCasesList[${index}].marks`;
      if (marksError in testCaseError) {
        delete testCaseError[marksError];
      }
    },
    [testCasesList, testCaseError],
  );

  const handleOnAdd = useCallback(
    () => {
      const newData = { input: '', output: '', marks: 0 };
      setTestCasesList([...testCasesList, newData]);
    },
  );

  const handleOnTestCaseEdit = useCallback(
    (tid) => {
      let testCase = {};
      const { testCases } = testCaseState;
      for (let i = 0; i < testCases.length; i += 1) {
        if (testCases[i].id === tid) {
          testCase = testCases[i];
          const { input, output, marks, id } = testCase;
          dispatch({
            type: 'editTestCase',
            payload: { input, output, marks, id },
          });
          break;
        }
      }
    }, [testCaseState.input, testCaseState.marks, testCaseState.testCases, testCaseState.id],
  );

  const handleOnTestCaseUpdate = useCallback(
    () => {
      const { input, output, marks, id, index } = testCaseState;
      const data = {
        id,
        input: input.trim(),
        output: output.trim(),
        marks,
      };
      schema.isValid(data).then(async (valid) => {
        if (!valid) {
          validateData(schema, data, dispatch);
        } else {
          testCasesList.forEach((testCase, testCaseIndex) => {
            if (testCase.id) {
              if (testCase.id === id) {
                testCasesList[testCaseIndex] = data;
              }
            } else if (index === testCaseIndex) {
              testCasesList[testCaseIndex] = data;
            }
          });

          setTestCasesList([...testCasesList]);
          dispatch({
            type: 'setdefault',
          });
        }
      });
    }, [testCaseState],
  );

  const handleOnTestCaseDelete = useCallback(
    async (id) => {
      const { testCases } = testCaseState;
      const data = {
        id,
        is_active: false,
      };
      try {
        // TODO
        // api without saga 37
        const result = await deleteTestCaseApi(data);
        let index;
        for (let i = 0; i < testCases.length; i += 1) {
          if (testCases[i].id === id) {
            index = i;
            break;
          }
        }
        dispatch({
          type: 'setAndDeleteTestCase',
          payload: {
            subType: 'deleteTestCase',
            data: index,
          },
        });
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }, [testCaseState.testCases],
  );

  const handleOnCancel = useCallback(
    (event) => {
      dispatch({
        type: 'setdefault',
      });
    },
    [testCaseState.input, testCaseState.output, testCaseState.marks],
  );

  const handleOnCancleClick = useCallback((index) => {
    const deletedTestCase = [...testCasesList];
    deletedTestCase.splice(index, 1);
    setTestCasesList([...deletedTestCase]);
    const objectError = Object.keys(testCaseError);
    const regExp = new RegExp(`${index}`);
    objectError.forEach((keys) => {
      if (regExp.test(keys)) {
        delete testCaseError[keys];
      }
    });
  }, [testCasesList, testCaseError]);

  return (
    <TestCaseComponent
      handleInputChange={handleInputChange}
      handleOutputChange={handleOutputChange}
      handleMarksChange={handleMarksChange}
      handleTestCaseOnAdd={handleOnAdd}
      handleOnTestCaseDelete={handleOnTestCaseDelete}
      handleOnTestCaseEdit={handleOnTestCaseEdit}
      handleOnTestCaseUpdate={handleOnTestCaseUpdate}
      handleOnCancel={handleOnCancel}
      message={message}
      testCasesList={testCasesList}
      input={testCaseState.input}
      output={testCaseState.output}
      marks={testCaseState.marks}
      inputErrTxt={testCaseState.inputErrTxt}
      outputErrTxt={testCaseState.outputErrTxt}
      marksErrTxt={testCaseState.marksErrTxt}
      isProblemSuccess={isSuccess}
      isTestCaseEdit={testCaseState.isTestCaseEdit}
      testCases={testCaseState.testCases}
      isLoading={isLoading}
      scrollRef={scrollRef}
      isTestCaseCreate={isTestCaseCreate}
      handleSubmit={handleSubmit}
      testCaseError={testCaseError}
      handleOnCancleClick={handleOnCancleClick}
    />
  );
};

TestCaseContainer.defaultProps = {
  isTestCaseCreate: false,
  scrollRef: {},
};

TestCaseContainer.propTypes = {
  scrollRef: PropTypes.object,
  isTestCaseCreate: PropTypes.bool,
  testCasesList: PropTypes.array.isRequired,
  setTestCasesList: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  testCaseError: PropTypes.object.isRequired,
};

export default React.memo(TestCaseContainer);
