/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';

import {
  FormGroup,
  Input,
  Button,
  Spinner,
  Icon,
} from 'core-components';
import { DeleteConfirmModal } from 'shared-components';

const RuleComponent = ({
  handleRuleChange,
  handleOnRuleAdd,
  handleOnRuleEdit,
  handleOnRuleDelete,
  handleOnRuleUpdate,
  rule,
  isRuleEdit,
  isLoading,
  rules,
  handleAddMoreClick,
  isAddMode,
  toggleConfirmation,
  handleDeleteClick,
  confirmationModal,
  targetId,
  ruleDescription,
}) => {
  const scrollDiv = createRef();
  const scrollSmoothHandler = () => {
    scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <div className='scroll-y mb-4'>
        <DeleteConfirmModal
          confirmationModal={confirmationModal}
          toggleConfirmation={toggleConfirmation}
          handleDelete={handleOnRuleDelete}
          targetId={targetId}
          type='rule'
          name={ruleDescription}
        />
        {
          rules.map((ruleIterator, key) => {
            return (
              <div className='rule-info-box d-flex pl-3 py-3 justify-content-between'>
                <span className='sequence-no mr-5'>{key + 1}.</span>
                <p className='mb-0'>
                  {ruleIterator.description}
                </p>
                <div className='ml-auto d-flex align-items-center'>
                  <Icon
                    name='pen'
                    title='Edit'
                    className='edit-icon mr-2'
                    onClick={() => { handleOnRuleEdit(ruleIterator.id); }}
                  />
                  <Icon
                    name='delete'
                    title='Delete'
                    onClick={() => {
                      toggleConfirmation();
                      handleDeleteClick(ruleIterator.id, ruleIterator.description);
                    }}
                  />
                </div>
              </div>
            );
          })
        }
      </div>
      <div className='rules-detail-form-group'>
        {isAddMode ? (
          <FormGroup className='mb-0'>
            <Input
              type='textarea'
              placeholder='Enter Rule Details'
              value={rule}
              onChange={handleRuleChange}
            />
            {
              isRuleEdit ?
                (
                  <Button
                    onClick={handleOnRuleUpdate}
                    className='text-uppercase save-btn ml-auto d-flex mt-3 px-2 py-1 text-decoration-none'
                  >
                    {isLoading ? (
                      <Spinner size='sm' color='light' />
                    ) : (
                      <>Update</>
                    )}
                  </Button>
                )
                :
                (
                  <Button
                    className='text-uppercase save-btn ml-auto d-flex mt-3 px-2 py-1 text-decoration-none'
                    onClick={handleOnRuleAdd}
                  >
                    save
                  </Button>
                )
            }
          </FormGroup>
        ) : null}
        <Button
          className='add-more-btn px-2 py-1 text-decoration-none mb-2'
          color='link'
          onClick={handleAddMoreClick}
        >
          <Icon name='plus' className='mr-2' />
          Add more
        </Button>
      </div>
    </>
  );
};

RuleComponent.propTypes = {
  handleRuleChange: PropTypes.func.isRequired,
  handleOnRuleAdd: PropTypes.func.isRequired,
  handleOnRuleEdit: PropTypes.func.isRequired,
  handleOnRuleDelete: PropTypes.func.isRequired,
  handleOnRuleUpdate: PropTypes.func.isRequired,
  rule: PropTypes.string.isRequired,
  rules: PropTypes.string.isRequired,
  isRuleEdit: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleAddMoreClick: PropTypes.func.isRequired,
  isAddMode: PropTypes.bool.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  targetId: PropTypes.number.isRequired,
  ruleDescription: PropTypes.string.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
};

export default React.memo(RuleComponent);
