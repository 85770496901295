import {
  Container,
  Row,
  FormGroup,
  Spinner,
  Button,
} from 'core-components';
import React from 'react';
import PropTypes, { shape } from 'prop-types';
import Gallery from 'react-grid-gallery';

const SnapshotsComponent = ({
  snapshotsData,
}) => {
  return (
    <Row className='px-3 position-relative'>
      <div style={{
        width: '100%',
        overflow: 'auto',
        textAlign: 'center',
        color: 'black',
      }}
      >
        {(snapshotsData.length === 0) &&
          <div className='text-danger'> Nothing to show </div>}
        <Gallery
          rowHeight={250}
          images={snapshotsData}
          enableImageSelection={false}
          enableLightbox={false}
        />
      </div>
    </Row>
  );
};
SnapshotsComponent.propTypes = {
  snapshotsData: PropTypes.arrayOf(shape()).isRequired,
};

export default React.memo(SnapshotsComponent);
