import produce from 'immer';

import { EDIT_ASSESSMENT } from 'redux/admin/editAssessment/actionConstants';

const { EDIT_ASSESSMENT_REQUEST_ACTION, EDIT_ASSESSMENT_SUCCESS_ACTION,
  EDIT_ASSESSMENT_FAILURE_ACTION, GET_ASSESSMENT_REQUEST_ACTION,
  GET_ASSESSMENT_SUCCESS_ACTION, GET_ASSESSMENT_FAILURE_ACTION, CLEAR_STATE } = EDIT_ASSESSMENT;

export const initialState = {
  assessment: {
    name: '',
    description: '',
    problem_count: '',
    is_param: false,
  },
  isLoading: false,
  isSuccess: false,
  isGetAssessmentSuccess: false,
  message: '',
  failureMessage: '',
};

const editAssessmentReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case EDIT_ASSESSMENT_REQUEST_ACTION:
      return { ...state, isLoading: true };
    case EDIT_ASSESSMENT_SUCCESS_ACTION:
      return {
        ...state,
        assessment: payload,
        isLoading: false,
        isSuccess: true,
        message: payload.message,
      };
    case EDIT_ASSESSMENT_FAILURE_ACTION:
      return {
        ...state,
        failureMessage: payload.message,
        isLoading: false,
      };
    case GET_ASSESSMENT_REQUEST_ACTION:
      return { ...state, isLoading: true };
    case GET_ASSESSMENT_SUCCESS_ACTION:
      return {
        ...state,
        assessment: payload.assessment,
        isLoading: false,
        isGetAssessmentSuccess: true,
      };
    case GET_ASSESSMENT_FAILURE_ACTION:
      return {
        ...state,
        failureMessage: payload.message,
        isLoading: false,
        isGetAssessmentSuccess: false,
      };
    case CLEAR_STATE:
      return { ...initialState };
    default:
      return state;
  }
});

export default editAssessmentReducer;
