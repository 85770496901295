import { EDIT_QUESTION } from 'redux/admin/editQuestion/actionConstants';

const { EDIT_QUESTION_REQUEST_ACTION, EDIT_QUESTION_SUCCESS_ACTION, EDIT_QUESTION_FAILURE_ACTION,
  EDIT_QUESTION_CLEAR_STATE, SET_QUESTION_REQUEST_ACTION, SET_QUESTION_SUCCESS_ACTION,
  SET_QUESTION_FAILURE_ACTION } = EDIT_QUESTION;

export const editQuestionRequestAction = (payload) => ({
  type: EDIT_QUESTION_REQUEST_ACTION,
  payload,
});

export const editQuestionSuccessAction = (payload) => ({
  type: EDIT_QUESTION_SUCCESS_ACTION,
  payload,
});

export const editQuestionFailureAction = (payload) => ({
  type: EDIT_QUESTION_FAILURE_ACTION,
  payload,
});

export const clearEditQuestionState = () => ({
  type: EDIT_QUESTION_CLEAR_STATE,
});

export const setQuestionRequestAction = (payload) => ({
  type: SET_QUESTION_REQUEST_ACTION,
  payload,
});

export const setQuestionSuccessAction = (payload) => ({
  type: SET_QUESTION_SUCCESS_ACTION,
  payload,
});

export const setQuestionFailuteAction = (payload) => ({
  type: SET_QUESTION_FAILURE_ACTION,
  payload,
});
