import { capitalize } from 'utils/capitalize';

export const validateData = (schema, validData, setEditDrive) => {
  schema.validate(validData, { abortEarly: false }).catch((err) => {
    err.inner.forEach((ele) => {
      if (ele.path === 'name') {
        setEditDrive({
          type: 'nameErrTxt',
          payload: capitalize(ele.message),
        });
      }
      if (ele.path === 'description') {
        setEditDrive({
          type: 'descriptionErrTxt',
          payload: capitalize(ele.message),
        });
      } if (ele.path === 'startTime') {
        setEditDrive({
          type: 'startTimeErrTxt',
          payload: capitalize(ele.message),
        });
      } if (ele.path === 'endTime') {
        setEditDrive({
          type: 'endTimeErrTxt',
          payload: capitalize(ele.message),
        });
      } if (ele.path === 'currentProblems') {
        setEditDrive({
          type: 'problemErrTxt',
          payload: capitalize(ele.message),
        });
      } if (ele.path === 'contacts') {
        setEditDrive({
          type: 'contactsErrTxt',
          payload: capitalize(ele.message),
        });
      } if (ele.path === 'problemsCount') {
        setEditDrive({
          type: 'problemErrTxt',
          payload: capitalize(ele.message),
        });
      }
    });
  });
};
