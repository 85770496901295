import React from 'react';
import PropTypes from 'prop-types';

import {
  Spinner,
  Icon,
} from 'core-components';
import { DeleteConfirmModal } from 'shared-components';

import './questionComponent.scss';

const QuestionComponent = ({
  isQuestionsLoading,
  questions,
  lastBookElementRef,
  onClickQuestion,
  confirmationModal,
  toggleConfirmation,
  targetId,
  mcqDescription,
  handleOnQuestionDelete,
  handleDeleteClick,
}) => {
  const questionsCount = questions.length;

  return (
    <div className='questions-section d-flex flex-column flex-1 scroll-y'>
      <DeleteConfirmModal
        confirmationModal={confirmationModal}
        toggleConfirmation={toggleConfirmation}
        handleDelete={handleOnQuestionDelete}
        targetId={targetId}
        type='MCQ'
        name={mcqDescription}
      />
      <div className='questions-list-wrap scroll-y'>
        {questionsCount ? null : 'MCQs Not Found'}
        <ul className='questions-list list-unstyled pl-0 mb-4 scroll-y'>
          {questions.map((questionIterator, key) => {
            return (
              <div
                ref={lastBookElementRef}
                className='questions-list-item'
                role='button'
                tabIndex={0}
                key={questionIterator.id}
              >
                <li className='questions-list-item d-flex p-3'>
                  <span className='sequence-no mr-5'>{key + 1}.</span>
                  <a
                    onClick={() => onClickQuestion(questionIterator)}
                    onKeyDown={() => onClickQuestion(questionIterator)}
                    className='mb-0 mr-5 mcq-description'
                    role='button'
                    tabIndex={0}
                  >
                    {questionIterator.description}
                  </a>
                  {/* Below code is commented to remove the delete icon for question temporarily */}
                  {/* <div className='rules-action-box ml-auto d-flex align-items-center'>
                    <Icon
                      name='delete'
                      title='Delete'
                      onClick={() => {
                        toggleConfirmation();
                        handleDeleteClick(questionIterator.id, questionIterator.description);
                      }}
                    />
                  </div> */}
                  <div className='ml-auto d-flex align-items-center'>
                    <Icon
                      name='pen'
                      title='Edit Question'
                      onClick={() => onClickQuestion(questionIterator)}
                    />
                  </div>
                </li>
              </div>
            );
          })}
          {isQuestionsLoading && <Spinner className='loader' />}
        </ul>
      </div>
    </div>
  );
};

QuestionComponent.defaultProps = {
  targetId: 0,
};

QuestionComponent.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  isQuestionsLoading: PropTypes.bool.isRequired,
  lastBookElementRef: PropTypes.func.isRequired,
  onClickQuestion: PropTypes.func.isRequired,
  handleOnQuestionDelete: PropTypes.func.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  targetId: PropTypes.number,
  mcqDescription: PropTypes.string.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
};

export default React.memo(QuestionComponent);
