import { DRIVE_LIST } from 'redux/admin/driveList/actionConstants';

// request
export const ongoingDriveListRequest = (payload) => ({
  type: DRIVE_LIST.ONGOING_DRIVE_LIST_REQUEST,
  payload,
});

export const upcomingDriveListRequest = (payload) => ({
  type: DRIVE_LIST.UPCOMING_DRIVE_LIST_REQUEST,
  payload,
});

export const completedDriveListRequest = (payload) => ({
  type: DRIVE_LIST.COMPLETED_DRIVE_LIST_REQUEST,
  payload,
});

export const driveDeleteRequest = (payload) => ({
  type: DRIVE_LIST.DRIVE_DELETE_REQUEST,
  payload,
});

export const ongoingDriveDeleteSuccess = (payload) => ({
  type: DRIVE_LIST.ONGOING_DRIVE_DELETE_SUCCESS,
  payload,
});

export const upcomingDriveDeleteSuccess = (payload) => ({
  type: DRIVE_LIST.UPCOMING_DRIVE_DELETE_SUCCESS,
  payload,
});

export const completedDriveDeleteSuccess = (payload) => ({
  type: DRIVE_LIST.COMPLETED_DRIVE_DELETE_SUCCESS,
  payload,
});

// success
export const ongoingDriveListSuccess = (payload) => ({
  type: DRIVE_LIST.ONGOING_DRIVE_LIST_SUCCESS,
  payload,
});

export const upcomingDriveListSuccess = (payload) => ({
  type: DRIVE_LIST.UPCOMING_DRIVE_LIST_SUCCESS,
  payload,
});

export const completedDriveListSuccess = (payload) => ({
  type: DRIVE_LIST.COMPLETED_DRIVE_LIST_SUCCESS,
  payload,
});

export const clearDrivesAction = () => ({
  type: DRIVE_LIST.CLEAR_DRIVES,
});

export const driveDeleteSuccess = (payload) => ({
  type: DRIVE_LIST.DRIVE_DELETE_SUCCESS,
  payload,
});

// failure
export const ongoingDriveFailure = (message) => ({
  type: DRIVE_LIST.ONGOING_DRIVE_LIST_FAILURE,
  payload: message,
});

export const upcomingDriveFailure = (message) => ({
  type: DRIVE_LIST.UPCOMING_DRIVE_LIST_FAILURE,
  payload: message,
});

export const completedDriveFailure = (message) => ({
  type: DRIVE_LIST.COMPLETED_DRIVE_LIST_FAILURE,
  payload: message,
});

export const driveDeleteFailure = (payload) => ({
  type: DRIVE_LIST.DRIVE_DELETE_FAILURE,
  payload,
});

export const setCompletedDriveOffset = (payload) => ({
  type: DRIVE_LIST.SET_COMPLETED_DRIVE_OFFSET,
  payload,
});

export const setOngoingDriveOffset = (payload) => ({
  type: DRIVE_LIST.SET_ONGOING_DRIVE_OFFSET,
  payload,
});

export const setUpcomingDriveOffset = (payload) => ({
  type: DRIVE_LIST.SET_UPCOMING_DRIVE_OFFSET,
  payload,
});
