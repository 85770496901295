import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ActionCableProvider } from 'react-actioncable-provider';

import { Modal, ModalBody } from 'core-components';
import EditorPadComponent from 'components/EditorPadComponent';
import { options } from 'components/EditorPadComponent/editorConstants';

import {
  CloseButton,
} from 'shared-components';
import {
  setCode,
  setFirstTime,
} from 'actions/languageAction';
import { WEB_SCOKET_CABLE_URL } from 'constants/appConstants';
import CustomIOReportComponent from './custumIOReportComponent';

const RunCodeModal = ({
  problemDetails,
  showModal,
  toggle,
}) => {
  const dispatch = useDispatch();
  const {
    answer: code,
    language,
    problem_id: problemId,
    lang_code: languageId,
  } = problemDetails;

  useEffect(() => {
    dispatch(setCode(code));
  }, [code]);

  const editorDidMount = useCallback((editor) => {
    editor.onKeyDown((event) => {
      const { keyCode, ctrlKey, metaKey } = event;
      // enabled ctrl+v keyCode = 52
      // Disabled ctrl+x keyCode = 54, ctrl+c = keyCode = 33
      if (
        (metaKey || ctrlKey) &&
        (keyCode === 54 || keyCode === 33)
      ) {
        event.preventDefault();
      }
    });
    editor.focus();
    editor.updateOptions({ contextmenu: false });
  }, []);

  const handleCode = useCallback(
    (value) => {
      dispatch(setCode(value));
      dispatch(setFirstTime(false));
    },
    [dispatch],
  );

  return (
    <Modal
      isOpen={showModal}
      toggle={toggle}
      modalClassName='left_modal search-modal'
    >
      <div className='modal-header-wrap d-flex align-items-center mb-2'>
        <CloseButton onClick={toggle} />
      </div>
      <ModalBody className='search-body-modal p-0 d-flex scroll-y flex-column'>
        <ActionCableProvider url={WEB_SCOKET_CABLE_URL}>
          <EditorPadComponent
            id='editor'
            lang={language}
            code={code}
            options={options}
            editorDidMount={editorDidMount}
            handleCode={handleCode}
          />
          <CustomIOReportComponent
            language={language}
            languageId={languageId}
          />
        </ActionCableProvider>
      </ModalBody>
    </Modal>
  );
};

RunCodeModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  problemDetails: PropTypes.object.isRequired,
};

export default React.memo(RunCodeModal);
