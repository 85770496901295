import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import DriveResultComponent from 'modules/admin/driveResult/DriveResultComponent';

import { Icon, Input } from 'core-components';
import {
  driveResultRequestAction,
  selectedCandidateRequestAction,
  selectedCandidateEmailRequestAction,
} from 'redux/admin/driveResult/action';
import {
  downloadResultRequestAction,
  absentCandidatesRequestAction,
  setDownloadedDriveNameAction,
} from 'redux/admin/downloadResult/action';
import { driveContactsListRequestAction } from 'redux/admin/getDriveContacts/action';
import { contactsListRequestActioon } from 'redux/admin/getContacts/action';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';
import { openNewWindow } from 'utils/window';
import { getValueIfTestNotAttended } from 'utils';

import 'modules/admin/userProfile/UserProfileComponent/userProfileComponent.scss';
import 'modules/admin/driveResult/DriveResultComponent/driveResultComponent.scss';

const DriveResultContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const pageNumber = 1;
  const { driveId } = useParams();

  const [searchQuery, setSearchQuery] = useState('');
  const [isSearched, setIsSearched] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [defaultEmail, setDefaultEmail] = useState([]);
  const [selectedEmailError, setSelectedEmailError] = useState('');

  const {
    data,
    errorMessage,
    isError, isLoading,
    driveResultMessage,
    isSelectedLoading,
    data: {
      drive_name: driveName,
      total_selected_candidates: totalSelectedCandidates,
      absent_candidates_count: absentCandidatesCount,
      drive_total_marks: driveTotalMarks,
      result,
    },
  } = useSelector((state) => state.driveResultReducer);
  const {
    contactList,
    isContactsLoading,
  } = useSelector((item) => item.getDriveContactsReducer);
  const {
    contactsList,
  } = useSelector((item) => item.getContactsReducer);

  const isDownloadResultDisable = result?.length ? !result.length : true;

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const toggleConfirmation = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    if (isSelectedLoading === false) {
      dispatch(driveResultRequestAction({ page: data.page, searchQuery, driveId }));
    }
  }, [dispatch, isSelectedLoading]);

  useEffect(() => {
    dispatch(setDownloadedDriveNameAction(driveName));
  }, [driveName]);

  useEffect(() => {
    if (contactList?.length !== 0) {
      const emailList = contactList?.map(({
        id,
        email,
      }) => ({
        value: id,
        label: email,
      }));
      setSelectedEmailError('');
      setDefaultEmail([...emailList]);
    }
  }, [contactList]);

  const handleDownloadResult = () => {
    dispatch(downloadResultRequestAction(driveId));
  };

  const handleDownloadAbsentCandidates = () => {
    dispatch(absentCandidatesRequestAction(driveId));
  };

  const handlePageClick = (event) => {
    dispatch(driveResultRequestAction({ page: event.selected + 1, searchQuery, driveId }));
  };

  const onClickCandidateReport = (e, index) => {
    const { token } = result[index];
    openNewWindow(`/admin/drive/${driveId}/candidate/${token}/report`);
  };

  const handleSelectedCandidateChange =
    (event, candidateToken) => {
      const isSelected = event.target.checked;
      const postData = {
        candidateToken,
        is_selected: isSelected,
        driveId,
      };
      dispatch(selectedCandidateRequestAction(postData));
    };

  const handleSendEmail = () => {
    if (defaultEmail.length === 0) {
      setSelectedEmailError('Please select atleast 1 Email-id.');
    } else {
      setShowModal(!showModal);
      const obj = {
        driveId,
        usersIds: defaultEmail.map((id) => id.value).toString(),
      };
      dispatch(selectedCandidateEmailRequestAction(obj));
    }
  };

  // TO DO
  // move this render table to new sub component
  const renderTableData = useMemo(() => {
    if (result.length === 0) {
      return (
        <tr>
          <td>No candidate(s) to view result!</td>
        </tr>
      );
    }

    if (result && result.length !== 0) {
      return result.map((val, index) => {
        const {
          candidate_id: candidateId,
          first_name: firstName,
          last_name: lastName,
          email,
          score,
          time_taken: timeTaken,
          is_selected: isSelected,
          token,
        } = val;

        return (
          <tr key={token}>
            <td>{candidateId}</td>
            <td>{firstName}</td>
            <td>{lastName}</td>
            <td>{email}</td>
            <td>{getValueIfTestNotAttended(score, timeTaken)}</td>
            <td>{timeTaken}</td>
            <td>
              <h2>
                {timeTaken !== 'Not Attended' ? (
                  <Icon
                    name='report'
                    onClick={(e) => onClickCandidateReport(e, index)}
                    className='report-icon'
                    title='Candidate Report'
                  />
                ) : '-'}
              </h2>
            </td>
            <td className='position-relative'>
              <Input
                type='checkbox'
                name='check'
                id='exampleCheck'
                className='mx-3'
                onChange={(e) => handleSelectedCandidateChange(e, token)}
                defaultChecked={isSelected}
                value={isSelected}
                disabled={score === null || timeTaken === 'Not Attended'}
              />
            </td>
          </tr>
        );
      });
    }
  }, [result]);

  const previousClick = () => {
    history.push(ROUTES.ADMIN + ADMIN_ROUTES.HOME);
  };

  const handleOnChange = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, [searchQuery]);

  const handleSearchClick = () => {
    if (searchQuery || isSearched) {
      dispatch(driveResultRequestAction({
        page: pageNumber,
        searchQuery: searchQuery.trim(),
        driveId,
      }));
      if (!searchQuery && isSearched) {
        setIsSearched(false);
      } else {
        setIsSearched(true);
      }
    }
  };

  const handleDriveResultSearchClearClick = () => {
    setSearchQuery('');
    if (isSearched) {
      dispatch(driveResultRequestAction({
        page: pageNumber,
        searchQuery: '',
        driveId,
      }));
    }
    setIsSearched(false);
  };

  const handleSelectedEmail = (event) => {
    const userEmails = event;
    setSelectedEmailError('');
    setDefaultEmail([...userEmails]);
  };

  // on Send Report button click call user list and drive contacts list request
  // to reduce extra api call
  const handleOnSendReportClick = () => {
    dispatch(contactsListRequestActioon({ contactList }));
    dispatch(driveContactsListRequestAction(driveId));
    toggleConfirmation();
  };

  const contactEmailOptions = useMemo(() => (contactsList?.map(({
    id,
    email,
  }) => ({
    value: id,
    label:email,
  }))
  ), [contactsList]);

  return (
    <DriveResultComponent
      renderTableData={renderTableData}
      errorMessage={errorMessage}
      isError={isError}
      isLoading={isLoading}
      handleDownloadResult={handleDownloadResult}
      handleSelectedCandidateChange={handleSelectedCandidateChange}
      driveResultMessage={driveResultMessage}
      handlePageClick={handlePageClick}
      pageCount={data.pages}
      previousClick={previousClick}
      driveName={driveName}
      totalSelectedCandidates={totalSelectedCandidates}
      absentCandidatesCount={absentCandidatesCount}
      driveTotalMarks={driveTotalMarks}
      defaultEmail={defaultEmail}
      handleOnChange={handleOnChange}
      handleSearchClick={handleSearchClick}
      handleDriveResultSearchClearClick={handleDriveResultSearchClearClick}
      handleDownloadAbsentCandidates={handleDownloadAbsentCandidates}
      dropdownOpen={dropdownOpen}
      toggleDropdown={toggleDropdown}
      toggleConfirmation={toggleConfirmation}
      showModal={showModal}
      handleSendEmail={handleSendEmail}
      emailData={contactList}
      isContactsLoading={isContactsLoading}
      searchQuery={searchQuery}
      isDownloadResultDisable={isDownloadResultDisable}
      contactEmailOptions={contactEmailOptions}
      handleSelectedEmail={handleSelectedEmail}
      emailErrorMessage={selectedEmailError}
      handleOnSendReportClick={handleOnSendReportClick}
    />
  );
};

export default React.memo(DriveResultContainer);
