import produce from 'immer';
import { QUESTIONS, DELETE_QUESTION } from 'redux/admin/questions/actionContants';

const {
  QUESTIONS_REQUEST_ACTION, QUESTIONS_FAILURE_ACTION, CLEAR_QUESTIONS,
  QUESTIONS_SUCCESS_ACTION, SET_QUESTIONS_OFFSET, SET_QUESTIONS_SEARCH_TEXT,
  SET_QUESTIONS_DIFFICULTY, SET_QUESTIONS_TAG, RESET_QUESTIONS_FILTERS,
  RESET_QUESTION_SEARCH_TEXT, SET_SEARCHED_QUESTION_FILTER_ACTION,
  RESET_SEARCHED_QUESTION_FILTER_ACTION,
} = QUESTIONS;

const { DELETE_QUESTION_FAILURE_ACTION, DELETE_QUESTION_REQUEST_ACTION,
  DELETE_QUESTION_SUCCESS_ACTION } = DELETE_QUESTION;

export const initialState = {
  questions: [],
  totalQuestions: 0,
  totalpages: 1,
  isQuestionsSuccess: false,
  isQuestionsLoading: false,
  isQuestionsError: false,
  offset: 0,
  deletionCount: 0,
  isQuestionDeleteLoading: false,
  questionSearchText: '',
  questionDifficulty: '',
  questionTag: [],
  searchedQuestionDifficulty: '',
  searchedQuestionTag: [],
};

const questionsListReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case QUESTIONS_REQUEST_ACTION:
      return {
        ...state,
        isQuestionsLoading: true,
        questions: payload.questions,
        totalpages: payload.pages,
        offset: payload.offset,
        deletionCount: 0,
      };
    case QUESTIONS_SUCCESS_ACTION:
      return {
        ...state,
        isQuestionsSuccess: true,
        isQuestionsLoading: false,
        questions: [...state.questions, ...payload.questions],
        totalQuestions: payload.total_questions,
        totalpages: payload.pages,
      };
    case QUESTIONS_FAILURE_ACTION:
      return {
        ...state,
        isQuestionsError: true,
        isQuestionsLoading: false,
      };
    case CLEAR_QUESTIONS:
      return { ...initialState };
    case SET_QUESTIONS_OFFSET:
      return { ...state, offset: payload };
    case SET_QUESTIONS_SEARCH_TEXT:
      return { ...state, questionSearchText: payload };
    case SET_QUESTIONS_DIFFICULTY:
      return { ...state, questionDifficulty: payload };
    case SET_QUESTIONS_TAG:
      return { ...state, questionTag: payload };
    case RESET_QUESTIONS_FILTERS:
      return {
        ...state,
        questionDifficulty: '',
        questionTag: [],
        searchedQuestionDifficulty: '',
        searchedQuestionTag: [],
      };
    case DELETE_QUESTION_REQUEST_ACTION:
      return {
        ...state,
        isQuestionDeleteLoading: true,
      };
    case DELETE_QUESTION_SUCCESS_ACTION: {
      return {
        ...state,
        isQuestionDeleteLoading: false,
        questions: state.questions.filter((option) => option.id !== payload.id),
        deletionCount: state.deletionCount + 1,
        totalQuestions: state.totalQuestions - 1,
      };
    }
    case DELETE_QUESTION_FAILURE_ACTION:
      return {
        ...state,
        isQuestionDeleteLoading: false,
        isOptionDeleteError: true,
      };
    case RESET_QUESTION_SEARCH_TEXT:
      return {
        ...state,
        questionSearchText: '',
      };
    case SET_SEARCHED_QUESTION_FILTER_ACTION:
      return {
        ...state,
        searchedQuestionDifficulty: payload.searchedQuestionDifficulty,
        searchedQuestionTag: payload.searchedQuestionTag,
      };
    case RESET_SEARCHED_QUESTION_FILTER_ACTION:
      return {
        ...state,
        searchedQuestionDifficulty: '',
        searchedQuestionTag: [],
      };
    default:
      return state;
  }
});

export default questionsListReducer;
