import { call, takeLatest, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { INPUT_OUTPUT } from 'constants/actionConstants';
import { customInputOutputPostApi } from 'apis/customIOAPI';
import { setOutput, setToken } from 'actions/ioAction';

export function* inputOutput(action) {
  try {
    const response = yield call(customInputOutputPostApi, action.payload);
    const { token } = response.data.data;
    yield put(setToken(token));
  } catch (error) {
    const { message } = error.response.data;

    yield put(setOutput(message));
    toast.error(message);
  }
}

export default function* inputOutputSaga() {
  yield takeLatest(INPUT_OUTPUT.SET_OUTPUT_REQUEST, inputOutput);
}
