import { FEEDBACKS } from 'redux/admin/feedbacks/actionContants';

export const feedbackRequestAction = (payload) => ({
  type: FEEDBACKS.FEEDBACKS_REQUEST_ACTION,
  payload,
});

export const feedbackSuccessAction = (payload) => ({
  type: FEEDBACKS.FEEDBACKS_SUCCESS_ACTION,
  payload,
});

export const feedbackFailureAction = (payload) => ({
  type: FEEDBACKS.FEEDBACKS_FAILURE_ACTION,
  payload,
});

export const feedbackClearAction = (payload) => ({
  type: FEEDBACKS.CLEAR_FEEDBACKS,
});

export const setFeedbacksOffset = (payload) => ({
  type: FEEDBACKS.SET_FEEDBACKS_LIMIT,
  payload,
});

export const feedbackDeleteRequestAction = (payload) => ({
  type: FEEDBACKS.FEEDBACKS_DELETE_REQUEST_ACTION,
  payload,
});

export const feedbackDeleteSuccessAction = (payload) => ({
  type: FEEDBACKS.FEEDBACKS_DELETE_SUCCESS_ACTION,
  payload,
});

export const feedbackDeleteFailureAction = (payload) => ({
  type: FEEDBACKS.FEEDBACKS_DELETE_FAILURE_ACTION,
  payload,
});
