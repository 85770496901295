import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import QuestionFilterComponent from 'modules/admin/question/QuestionComponent/QuestionFilterComponent';

import {
  questionsListRequest, setQuestionsDifficulty,
  setQuestionsTag, resetQuestionFilters,
  setSearchedQuestionFilterAction,
} from 'redux/admin/questions/action';
import { tagsRequestAction } from 'redux/admin/tags/action';

import arrayToKeyValueHash from 'utils/arrayToKeyValueHash';
import { capitalize } from 'utils/capitalize';

const QuestionFilterContainer = ({
  onClickQuestionsFilter,
  searchedQuestionText,
}) => {
  const dispatch = useDispatch();

  const [tagsOptions, setTagsOptions] = useState([]);
  const [difficultyOptions, setDifficultyOptions] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  const {
    isQuestionsLoading,
    questionSearchText,
    questionDifficulty,
    questionTag,
  } = useSelector((item) => item.questionsListReducer);
  const { difficultyList, tagList } = useSelector((state) => state.tagsReducer);

  useEffect(() => {
    dispatch(tagsRequestAction());
  }, []);

  useEffect(() => {
    setDifficultyOptions(arrayToKeyValueHash(difficultyList));
  }, [difficultyList]);

  useEffect(() => {
    setTagsOptions(arrayToKeyValueHash(tagList));
  }, [tagList]);

  const handleSelectedDifficultyChange = useCallback(
    (seletedOption) => {
      dispatch(setQuestionsDifficulty(seletedOption));
    }, [questionDifficulty, questionTag, questionSearchText],
  );

  const handleSelectedTagsChange = useCallback(
    (seletedOption) => {
      const tags = [];
      seletedOption.map((item) => tags.push(item));
      dispatch(setQuestionsTag(tags));
    }, [questionTag],
  );

  const handleFilterClick = () => {
    if (!isQuestionsLoading && (questionDifficulty || questionTag.length !== 0)) {
      const filters = {
        searchedQuestionDifficulty: questionDifficulty,
        searchedQuestionTag: questionTag,
      };
      dispatch(setSearchedQuestionFilterAction(filters));
      dispatch(questionsListRequest({
        offset: 0,
        searchedQuestionText,
        questions: [],
        searchedQuestionDifficulty: questionDifficulty,
        searchedQuestionTag: questionTag,
      }));
    }
    setIsFiltered(true);
  };

  const handleResetQuestionFilter = () => {
    if (questionDifficulty || questionTag.length !== 0) {
      dispatch(resetQuestionFilters());
      if (!isQuestionsLoading && isFiltered) {
        dispatch(questionsListRequest({
          offset: 0,
          searchedQuestionText,
          questions: [],
          searchedQuestionDifficulty: '',
          searchedQuestionTag: [],
        }));
        onClickQuestionsFilter();
        setIsFiltered(false);
      }
    } else {
      onClickQuestionsFilter();
      setIsFiltered(false);
    }
  };

  return (
    <QuestionFilterComponent
      tagsOptions={tagsOptions}
      difficultyOptions={difficultyOptions}
      selectedDifficultyKeyValue={questionDifficulty}
      selectedTagKeyValue={questionTag}
      handleSelectedDifficultyChange={handleSelectedDifficultyChange}
      handleSelectedTagsChange={handleSelectedTagsChange}
      handleFilterClick={handleFilterClick}
      handleResetQuestionFilter={handleResetQuestionFilter}
    />
  );
};

QuestionFilterContainer.propTypes = {
  onClickQuestionsFilter: PropTypes.func.isRequired,
  searchedQuestionText: PropTypes.string.isRequired,
};

export default QuestionFilterContainer;
