import produce from 'immer';

import date from 'utils/date';
import { DOWNLOAD_MCQ } from './actionConstants';

export const initialState = {
  isLoading: false,
};

const {
  DOWNLOAD_MCQ_REQUEST_ACTION,
  DOWNLOAD_MCQ_SUCCESS_ACTION,
  DOWNLOAD_MCQ_FAILURE_ACTION,
} = DOWNLOAD_MCQ;

const fileSaver = require('file-saver');

const downloadMcqReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case DOWNLOAD_MCQ_REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    case DOWNLOAD_MCQ_SUCCESS_ACTION:
      fileSaver.saveAs(new Blob([payload], { type: 'application/octet-stream' }),
        `mcq-list_${date.getTimeStampForFileName()}.csv`);
      return {
        ...state,
        isLoading: false,
      };
    case DOWNLOAD_MCQ_FAILURE_ACTION:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
});

export default downloadMcqReducer;
