// create and edit question utils

// schema utils
export const intToAlphaCapital = (number) => String.fromCharCode(65 + number);

// Container utils

// minimum 2 option validation for mcq
export const minTwoOptionValidation = (options) => options.filter(
  (option) => !option?.isDelete,
).length >= 2;

// checking validations of single and multiple correct options
export const correctOptionValidation = (type, options) => {
  // removing options with isDelete from options array
  options = options.filter((option) => !option?.isDelete);

  if (type.value === 'true') {
    // multiple correct optoins
    const notMultiCorrect = options.every((option) => {
      return option.isAnswer === false;
    });

    return notMultiCorrect ? 'Add at least one correct option' : '';

    // eslint-disable-next-line no-else-return
  } else if (type.value === 'false') {
    // single correct option
    let singleCorrectCount = 0;

    // options
    for (let i = 0; i < options.length; i += 1) {
      if (options[i].isAnswer === true) {
        singleCorrectCount += 1;
        // more than one correct
        if (singleCorrectCount > 1) {
          return 'Add only one correct option';
        }
      }
    }

    // zero correct
    if (!singleCorrectCount) {
      return 'Add at least one correct option';
    }
    // single correct
    return '';
  }
};

// -------------------------------------------------------------------------------------------

// Saga utils

// TO DO
// move data conversions payload to data in used in create and edit question saga here
