/* eslint-disable indent */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Tour from 'reactour';

import { Icon } from 'core-components';
import UserProfileContainer from 'modules/admin/userProfile/UserProfileContainer';
import SearchModalContainer from 'modules/admin/searchModal/SearchModalContainer';
import { setIsOpen } from 'redux/admin/modalStates/action';

import './sideNavComponent.scss';
import siteLogo from '../../../../assets/images/spark-code-logo.png';
import joshLogo from '../../../../assets/images/josh-logo@2x.png';

import './sideNavStyle.css';

const navLinkComponent = (
  navItems,
  handleClearState,
  handleSelectedNavItem,
  enabledSideNav,
  isHeighlightQuestion,
) => {
  if (!navItems.isChildren) {
    return (
      <li className='sp-aside-nav-item p-3' key={navItems.navPath}>
        <NavLink
          to={navItems.navPath}
          className='sp-aside-nav-link d-flex align-items-center text-decoration-none'
          onClick={(e) => handleClearState(navItems.navPath)}
        >
          <Icon id={navItems.navIcon} name={navItems.navIcon} />
          <span className='ml-4 font-weight-bold'>
            {navItems.navLabel}
          </span>
        </NavLink>
      </li>
    );
  }
  return (
    <div key={navItems}>
      <li className='sp-aside-nav-item p-3' key={navItems.navPath}>
        <div
          className='sp-aside-nav-link d-flex align-items-center text-decoration-none'
          onClick={handleSelectedNavItem}
          onKeyDown={() => { }}
          role='button'
          tabIndex={0}
        >
          <Icon id={navItems.navIcon} name={navItems.navIcon} className={`${isHeighlightQuestion && 'active-dropdown-nav'}`} />
          <span className='ml-4 font-weight-bold'>
            {navItems.navLabel}
          </span>
          {enabledSideNav ? (<Icon name='arrow-down arrow-wrapper' className='ml-4' />) :
            (<Icon name='arrow-right arrow-wrapper' className='ml-4' />)}
        </div>
      </li>
      {(enabledSideNav === navItems.navLabel && navItems.isChildren) && (
        navItems.children.map((item) => {
          return (
            <div key={item.navLabel} className='ml-4'>
              {navLinkComponent(
                item,
                handleClearState,
                handleSelectedNavItem,
                '',
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

const SideNavComponent = ({
  handleProfileClick,
  handleSearchClick,
  handleClearState,
  handleSelectedNavItem,
  enabledSideNav,
  isHeighlightQuestion,
  navLinks,
  tourSteps,
}) => {
  const color = '#5cb7b7';
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.modalStatusReducer);

  const startingStep = 0;
  return (
    <>
      <aside className='sp-sidebar px-2 d-flex flex-column position-relative scroll-y'>
        <div className='p-3'>
          <a
            href='/admin/home'
            className='sp-logo d-block'
          >
            <img
              src={siteLogo}
              alt='site logo'
            />
          </a>
        </div>
        <div className='sp-sidenav-action-box d-flex align-items-center justify-content-between sidenav-icon'>
          <Icon
            id='search'
            name='search'
            title='Search Drive'
            onClick={handleSearchClick}
          />
          <Icon
            id='profile'
            name='profile'
            title='Profile'
            onClick={handleProfileClick}
          />
        </div>
        <Tour
          steps={tourSteps}
          isOpen={isOpen}
          accentColor={color}
          onRequestClose={() => dispatch(setIsOpen(false))}
          nextButton={<div className='tour-style'>Next</div>}
          prevButton={<div className='tour-style'>Prev</div>}
          startAt={startingStep}
        />
        <div className='sp-aside-nav d-flex flex-1 flex-column scroll-y py-4'>
          <ul className='sp-aside-nav-list list-unstyled mb-0'>
            {navLinks.map((navItems, index) => (
              navLinkComponent(
                navItems,
                handleClearState,
                handleSelectedNavItem,
                enabledSideNav,
                isHeighlightQuestion,
              )
            ))}
          </ul>
        </div>
        <div className='bottom-link p-3'>
          <img
            src={joshLogo}
            alt='josh logo'
            className='h-100'
          />
        </div>
      </aside>
      <UserProfileContainer />
      <SearchModalContainer />
    </>
  );
};

SideNavComponent.propTypes = {
  handleProfileClick: PropTypes.func.isRequired,
  handleSearchClick: PropTypes.func.isRequired,
  handleClearState: PropTypes.func.isRequired,
  handleSelectedNavItem: PropTypes.func.isRequired,
  enabledSideNav: PropTypes.string.isRequired,
  isHeighlightQuestion: PropTypes.bool.isRequired,
  navLinks: PropTypes.arrayOf(PropTypes.object).isRequired,
  tourSteps: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default React.memo(SideNavComponent);
