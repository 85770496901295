import React from 'react';
import PropTypes from 'prop-types';

import {
  Input,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Spinner,
} from 'core-components/index';
import { Link } from 'react-router-dom';
import './forgotPasswordCard.scss';

const ForgotPasswordCard = (props) => {
  const {
    handleEmailChange,
    handleSubmit,
    emailError,
    email,
    isError,
    isLoading,
    errorMessage,
    successMessage,
  } = props;

  return (
    <div className='login-card-box position-absolute'>
      <h2 className='font-weight-normal d-flex justify-content-center mt-5'>Forgot Password</h2>
      <Form className='login-form d-flex flex-column h-100 scroll-y'>
        <Label className='mx-3 notification-message font-weight-normal'>Enter your email and we will send you a link to reset your password.</Label>
        <FormGroup>
          <Label className='mt-5 mx-3 font-weight-normal'>Email</Label>
          <Input
            id='exampleEmail'
            type='email'
            placeholder='example@gmail.com'
            value={email}
            onChange={handleEmailChange}
            invalid={emailError !== ''}
          />
          <FormFeedback type='invalid' target='exampleEmail'>
            {emailError}
          </FormFeedback>
        </FormGroup>
        <FormGroup className='d-flex mb-0 py-5'>
          <Button
            className='border-0 w-100'
            onClick={handleSubmit}
            color='primary'
          >
            {isLoading ? <Spinner size='sm' color='light' /> : <>Send Request</>}
          </Button>
        </FormGroup>
        <div className='text-center text-warning '>
          {errorMessage}
        </div>
        <div className='text-center text-warning '>
          {successMessage}
        </div>
        <div className='mt-3 login-details-box d-flex justify-content-center'>
          <Link to='/' className='login-link text-center text-underline'>
            Back to Login
          </Link>
        </div>
      </Form>
    </div>
  );
};

ForgotPasswordCard.propTypes = {
  handleEmailChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  emailError: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
};

export default React.memo(ForgotPasswordCard);
