import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  createQuestionFailureAction,
  createQuestionSuccessAction,
} from 'redux/admin/createQuestion/action';
import { createQuestionPostApi, getPresignedUrlApi, putImageS3 } from 'redux/admin/createQuestion/api';

import { CREATE_QUESTION } from 'redux/admin/createQuestion/actionConstants';

export function* createQuestionSaga(action) {
  const {
    type,
    description,
    marks,
    timeInMinutes,
    difficulty,
    tags,
    questionDiagram,
    mcqOptions,
  } = action.payload;

  let image_url;

  if (questionDiagram) {
    const presignedUrl = yield call(getPresignedUrlApi, { image_for: 'question' });

    const imageUrl = yield call(putImageS3, {
      url: presignedUrl.data.data.url,
      image: questionDiagram,
    });

    image_url = imageUrl.config.url.substr(0, imageUrl.config.url.indexOf('?'));
  }

  const urlsApiCall = [];
  const optionsImageIndex = [];
  let imageUrls;

  // checking if minimum 1 image present in options array
  const isOptionsDiagramPresent = mcqOptions.reduce(
    (prev, current) => (current.optionDiagram ? prev + 1 : prev),
    0,
  );

  if (isOptionsDiagramPresent) {
    for (let i = 0; i < mcqOptions.length; i += 1) {
      // checking if image present
      if (mcqOptions[i].optionDiagram) {
        // maintaining indices where images are present
        optionsImageIndex.push(i);
        // pushing presigned url in array for each image present
        urlsApiCall.push(getPresignedUrlApi({ image_for: 'option' }));
      }
    }

    // resolving all promises of presigned api calls
    const urls = yield Promise.all(urlsApiCall);

    const imageUrlsApiCall = [];

    // taking images from options at indices maintained in array
    for (let i = 0; i < optionsImageIndex.length; i += 1) {
      const { url } = urls[i].data.data;
      const image = mcqOptions[optionsImageIndex[i]].optionDiagram;

      // sending presigned url and image file to S3 to get image url
      // pushing all image url promises into array
      imageUrlsApiCall.push(putImageS3({ url, image }));
    }
    // resolving all image url promises
    imageUrls = yield Promise.all(imageUrlsApiCall);
  }

  // managing indices of image urls
  let imageUrlIndex = 0;

  const options = mcqOptions.map((option) => {
    const optionResponse = {
      description: option.description.trim(),
      is_answer: option.isAnswer,
    };

    if (option.optionDiagram) {
      // getting image url and setting it in optionResponse
      optionResponse.image_url = imageUrls[imageUrlIndex]?.config.url.substr(0, imageUrls[imageUrlIndex].config.url.indexOf('?'));
      imageUrlIndex += 1;
    } else {
      // if option don't have any image is present setting ''
      optionResponse.image_url = '';
    }

    return optionResponse;
  });

  const data = {
    is_multi_select: type.value,
    description: description.trim(),
    marks,
    time_in_minutes: timeInMinutes,
    difficulty_list: difficulty.value,
    tag_list: tags.map((tag) => (tag.value)),
    image_url,
    options_attributes: options,
  };
  try {
    const response = yield call(createQuestionPostApi, data);
    yield put(createQuestionSuccessAction(response.data.data));
    toast.success('Question created successfully');
  } catch (error) {
    toast.error(error.response.data.message);
    yield put(createQuestionFailureAction(error));
  }
}

export default function* createQuestionWatcherSaga() {
  yield takeLatest(CREATE_QUESTION.CREATE_QUESTION_REQUEST_ACTION, createQuestionSaga);
}
