import { QUESTION_TABS } from 'redux/admin/questionTabs/actionContants';

const { SET_ACTIVE_TAB, RESET_ACTIVE_TAB } = QUESTION_TABS;

export const setActiveTab = (payload) => ({
  type: SET_ACTIVE_TAB,
  payload,
});

export const resetActiveTab = () => ({
  type: RESET_ACTIVE_TAB,
});
