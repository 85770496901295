import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import ProtectedSuperAdminRoute from 'HOC/ProtectedSuperAdminRoute';
import OrganizationContainer from 'modules/superAdmin/organization/OrganizationContainer';
import OrganizationEditContainer from 'modules/superAdmin/organizationEdit/OrganizationEditContainer';

import { getUserDetails } from 'utils';

import { ROUTES, SUPER_ADMIN_ROUTES } from 'constants/routeConstants';

function SuperAdminRoutes() {
  const { path } = useRouteMatch();
  const userDetails = getUserDetails();
  // checking is user logged in or not
  if (userDetails?.role === 'SuperAdmin') {
    return (
      <Switch>
        <ProtectedSuperAdminRoute
          exact
          path={path + SUPER_ADMIN_ROUTES.ORGANIZATIONS}
          component={OrganizationContainer}
          includeSidebar
        />
        <ProtectedSuperAdminRoute
          exact
          path={path + SUPER_ADMIN_ROUTES.EDIT_ORGANIZATION}
          component={OrganizationEditContainer}
        />
        <Redirect to={path + SUPER_ADMIN_ROUTES.ORGANIZATIONS} />
      </Switch>
    );
  }
  return <Redirect to={ROUTES.HOME} />;
}

export default SuperAdminRoutes;
