import axios from 'axios';
import { SERVER_URL } from 'constants/appConstants';
import { get, post } from 'redux/admin/apiHelper';

export const createProblemPostApi = (data) => post(`${SERVER_URL}admin/problems`, data);

export const getPresignedUrlApi = () => get(`${SERVER_URL}admin/problems/presigned_url`);

export const putImageS3 = ({ url, image }) => {
  return axios.put(url, image, {
    headers: {
      'Content-Type': 'image/jpeg',
    },
  });
};
