import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Icon } from 'core-components/index';
import CountPill from 'shared-components/CountPill';

import './actionTile.scss';

const ActionTile = ({
  id,
  className,
  Children,
  onClickEdit,
  driveDetails,
  showCredits,
  credits,
  count,
  countType,
  countDetails,
  onClickCredits,
  problemDifficulty,
  totalDrives,
  totalDrivesCandidates,
  totalAssessment,
  totalAssessmentsCandidates,
  attendedAssessmentsCandidates,
  attendedDrivesCandidates,
  unattendedAssessmentsCandidates,
  unattendedDrivesCandidates,
  isOrganization,
  type,
  editTypeTitle,
}) => {
  const {
    appeared,
    invitation_sent: invitationSent,
    total_submissions: totalSubmissions,
    duration,
  } = driveDetails;

  const handleOnClickEdit = (e) => {
    onClickEdit(id, e);
  };

  return (
    <>
      <div
        className={`action-tile-wrap d-flex align-items-center justify-content-between ${className} gap-2`}
      >
        {countDetails && (
          <ul className='drive-count-details list-unstyled mb-0 pl-0 d-flex flex-1 gap-2'>
            <li>
              <CountPill
                count={appeared}
                countType='Appeared'
              />
            </li>
            <li>
              <CountPill
                count={invitationSent}
                countType='Invitation'
              />
            </li>
            <li>
              <CountPill
                count={totalSubmissions}
                countType='Submissions'
              />
            </li>
            <li>
              {driveDetails.duration && (
                <CountPill
                  count={duration}
                  countType='Duration'
                />
              )}
            </li>
          </ul>
        )}
        {isOrganization && (
          <ul className='drive-count-details list-unstyled mb-0 pl-0 d-flex flex-1 gap-2'>
            <li>
              <CountPill
                count={totalDrives}
                countType='Drives'
              />
            </li>
            <li
              title={`Attended: ${attendedDrivesCandidates}, Absent: ${unattendedDrivesCandidates}`}
            >
              <CountPill
                count={totalDrivesCandidates}
                countType='Drive Candidates'
              />
            </li>
            <li>
              <CountPill
                count={totalAssessment}
                countType='Assessment'
              />
            </li>
            <li
              title={`Attended: ${attendedAssessmentsCandidates}, Absent: ${unattendedAssessmentsCandidates}`}
            >
              <CountPill
                count={totalAssessmentsCandidates}
                countType='Assessment Candidates'
              />
            </li>
          </ul>
        )}
        <div className='difficulty-filter flex-1'>
          {problemDifficulty && (
            <span color='primary'>{problemDifficulty}</span>
          )}
        </div>
        <div className='action-box d-flex align-items-center gap-2'>
          {showCredits && (
            <div className='d-flex align-items-center flex-1 gap-1/2'>
              <div className='credits-wrapper'>
                <Icon
                  name='star'
                  className='text-warning'
                  onClick={onClickCredits}
                />
              </div>
              <span>{credits || 0}</span>
            </div>
          )}
          {driveDetails?.is_param ? (
            <div className='font-weight-bold param-heading text-capitalize'>
              <span className='mt-3 mr-3'>Param AI</span>
            </div>
          ) : null}
          {type === 'completedDrive' ||
            (type === 'search' && moment(driveDetails.end_time).isBefore()) ?
            null : (
              <Icon
                className='text-secondary font-weight-bold'
                name='pen'
                title={`Edit ${editTypeTitle}`}
                onClick={handleOnClickEdit}
              />
            )}
          {Children && <div className='mt-1'>{Children}</div>}
        </div>
      </div>
    </>
  );
};

ActionTile.defaultProps = {
  showCredits: false,
  credits: 0,
  onClickCredits: () => { },
  problemDifficulty: '',
  count: 0,
  countType: '',
  driveDetails: {},
  countDetails: false,
  totalDrives: 0,
  totalDrivesCandidates: 0,
  totalAssessment: 0,
  totalAssessmentsCandidates: 0,
  attendedAssessmentsCandidates: 0,
  attendedDrivesCandidates: 0,
  unattendedAssessmentsCandidates: 0,
  unattendedDrivesCandidates: 0,
  isOrganization: false,
  type: '',
  Children: null,
  editTypeTitle: '',
};

ActionTile.propTypes = {
  driveDetails: PropTypes.shape(),
  onClickEdit: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  Children: PropTypes.node,
  count: PropTypes.number,
  countType: PropTypes.string,
  countDetails: PropTypes.bool,
  showCredits: PropTypes.bool,
  credits: PropTypes.number,
  onClickCredits: PropTypes.func,
  problemDifficulty: PropTypes.string,
  totalDrives: PropTypes.number,
  totalDrivesCandidates: PropTypes.number,
  totalAssessment: PropTypes.number,
  totalAssessmentsCandidates: PropTypes.number,
  attendedAssessmentsCandidates: PropTypes.number,
  attendedDrivesCandidates: PropTypes.number,
  unattendedAssessmentsCandidates: PropTypes.number,
  unattendedDrivesCandidates: PropTypes.number,
  isOrganization: PropTypes.bool,
  type: PropTypes.string,
  editTypeTitle: PropTypes.string,
};
export default ActionTile;
