export const PROBLEMS_BULK_UPLOAD_ACTIONS = {
  PROBLEM_IMPORT_FILE_ACTION: 'PROBLEM_IMPORT_FILE_ACTION',
  PROBLEM_SET_FILE: 'PROBLEM_SET_FILE',
  PROBLEM_SET_FILE_NAME: 'PROBLEM_SET_FILE_NAME',
  PROBLEM_RESET_STATE: 'PROBLEM_RESET_STATE',
  PROBLEM_FILE_UPLOAD_SUCCESSFULLY: 'PROBLEM_FILE_UPLOAD_SUCCESSFULLY',
  PROBLEM_ACTION_FAILED: 'PROBLEM_ACTION_FAILED',
  PROBLEM_SET_FILE_PATH: 'PROBLEM_SET_FILE_PATH',
  PROBLEM_RESET_FILE_PATH: 'PROBLEM_RESET_FILE_PATH',
  PROBLEM_START_FILE_IMPORT: 'PROBLEM_START_FILE_IMPORT',
  PROBLEM_SET_FILE_NAME_ERROR: 'PROBLEM_SET_FILE_NAME_ERROR',
  PROBLEM_DOWNLOAD_FILE_SUCCESS_ACTION: 'PROBLEM_DOWNLOAD_FILE_SUCCESS_ACTION',
};
