import { LANGUAGE } from 'constants/actionConstants';
import local from 'utils/local';

const initialState = {
  languages: [],
  languageSelected: {},
  code: '# Write your code here (Accept input from stdin using gets and print output using puts)',
  problemId: parseInt(local.getItem('problemId'), 10) || '',
  languageCode: null,
  changed: false,
  first: false,
};

const languageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case LANGUAGE.RESET_ACTION:
      return {
        ...state,
        languages: payload.languages,
        languageSelected: payload.languages[payload.selectedLanguageIndex],
        code: payload.languages[payload.selectedLanguageIndex].code,
      };
    case LANGUAGE.SET_LANGUAGE_SELECTED:
      return { ...state, languageSelected: payload.languageSelected };
    case LANGUAGE.SET_CODE:
      return { ...state, code: payload.code ? payload.code : '' };
    case LANGUAGE.SET_CHANGED:
      return { ...state, changed: payload.changed };
    case LANGUAGE.SET_FIRST:
      return { ...state, first: payload.first };
    default:
      return state;
  }
};

export default languageReducer;
