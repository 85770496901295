import { SERVER_URL } from 'constants/appConstants';
import { get, post, put, destroy } from 'redux/admin/apiHelper';

export const postTemplateApi = (data) => post(`${SERVER_URL}admin/templates`, data);

export const getTemplatesApi = (data) => get(`${SERVER_URL}admin/templates?problem_id=${data}`);

export const updateTemplateApi = (data) => put(`${SERVER_URL}admin/templates/${data.id}`, data);

export const postDefaultTemplateApi = (data) => post(`${SERVER_URL}admin/default_templates`, data);

export const getDefaultTemplatesApi = () => get(`${SERVER_URL}admin/templates`);

export const updateDefaultTemplateApi = (data) => put(`${SERVER_URL}admin/default_templates/${data.id}`, data);

export const deleteTemplateApi = (data) => destroy(`${SERVER_URL}admin/templates/${data}`);
