import { call, takeLatest, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { csvValidationErrorMessage } from 'constants/messageConstants';
import { successfulFileImport, setFilePath, resetFilePath } from './action';
import { mcqBulkUploadApi } from './api';

import { MCQ_BULK_UPLOAD_ACTIONS } from './actionConstants';

const { IMPORT_FILE_ACTION } = MCQ_BULK_UPLOAD_ACTIONS;

function* mcqBulkUploadSaga(action) {
  try {
    const { data } = yield call(mcqBulkUploadApi, action.payload);
    const { message } = data;
    yield put(resetFilePath());
    toast.success(message);
    yield put(successfulFileImport());
  } catch (error) {
    const { response: { data, status } } = error;
    const { message } = data;
    if (status === 422) {
      if (message) {
        toast.error(message);
      } else {
        yield put(setFilePath(data));
        yield put(successfulFileImport());
        toast.error(csvValidationErrorMessage);
      }
    } else {
      yield put(resetFilePath());
      toast.error(message);
    }
  }
}

export function* mcqBulkUploadWatcherSaga() {
  yield takeLatest(IMPORT_FILE_ACTION, mcqBulkUploadSaga);
}
