import { PROBLEMS } from 'redux/admin/problems/actionContants';

export const problemsRequestAction = (payload) => ({
  type: PROBLEMS.PROBLEMS_REQUEST_ACTION,
  payload,
});

export const problemsSuccessAction = (payload) => ({
  type: PROBLEMS.PROBLEMS_SUCCESS_ACTION,
  payload,
});

export const problemsFailureAction = (payload) => ({
  type: PROBLEMS.PROBLEMS_FAILURE_ACTION,
  payload,
});

export const problemsClear = () => ({
  type: PROBLEMS.CLEAR_PROBLEMS_LIST,
});
