/* eslint-disable react/jsx-tag-spacing */
import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import {
  Table,
  Button,
  Spinner,
  Icon,
  Nav,
  NavItem,
  NavLink,
  Input,
} from 'core-components';
import { SearchBox } from 'shared-components';

import SendEmailInviteContainer from 'modules/admin/sendEmailInvite/SendEmailInviteContainer';
import RenderCandidateListTable from '../ShowCandidateListContainer/RenderCandidateListTable';

import './showCandidateListComponent.scss';

const ShowCandidateListComponent = (props) => {
  const {
    handleAddCandidateClick,
    pageCount,
    candidateIsLoading,
    handlePageClick,
    previousClick,
    addCandidateModal,
    driveName,
    handleOnChange,
    searchQuery,
    handleSearchClick,
    handleCandidateSearchClearClick,
    totalCandidates,
    allCandidates,
    handleTabChange,
    activeTab,
    handleSelectAll,
    isCheck,
    handleResendInvitation,
    isSelectAllChecked,
    handleClick,
    driveStatus,
  } = props;

  if (candidateIsLoading) {
    return <Spinner className='loader' />;
  }

  const previousArrow = (
    <>
      <Icon name='arrow-left' />
      <span className='text-uppercase'>PREVIOUS</span>
    </>
  );

  const nextArrow = (
    <>
      <span className='text-uppercase'>Next</span>
      <Icon name='arrow-right' />
    </>
  );

  return (
    <>
      <div className='candidate-section d-flex flex-column'>
        <div className='candidate-section-head d-flex justify-content-between mb-4'>
          <div className='d-flex align-items-center my-2 candidate-title-box'>
            <div className='d-flex align-items-center mr-3'>
              <Icon name='left' className='back-page-arrow font-weight-bold' onClick={previousClick} />
              <h2 className='section-heading font-weight-bold mb-0 ml-3'>
                Candidates ({totalCandidates})
              </h2>
            </div>
            <div className='drive-title px-3'>
              <span className='font-weight-bold text-capitalize'>
                {driveName}
              </span>
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-end candidate-filter-box'>
            <SearchBox
              placeholder='Search Candidate'
              handleOnChange={handleOnChange}
              handleSearchClick={handleSearchClick}
              handleCloseClick={handleCandidateSearchClearClick}
              value={searchQuery}
            />
            <Button
              onClick={handleAddCandidateClick}
              className='add-candidate-btn ml-4 border-0 text-decoration-none'
              disabled={driveStatus === 'completed'}
            >
              Add Candidates
            </Button>
            { (activeTab === 'absentCandidate' && (Object.values(isCheck).filter((val) => val === true)).length > 0) && (
              <div className='resend-invitation d-flex align-items-center my-2'>
                <Icon
                  name='send'
                  onClick={handleResendInvitation}
                  className='send-icon '
                  title='Resend Invitation'
                />
              </div>
            )}
          </div>
        </div>
        <div className='d-flex align-items-center candidate-filter-box'>
          <Nav className='navitem-style candidates-tab'>
            <NavItem>
              <NavLink
                active={activeTab === 'Default'}
                onClick={() => handleTabChange('Default')}
              >
                Default
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === 'absentCandidate'}
                onClick={() => handleTabChange('absentCandidate')}
              >
                Absent Candidates
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className='candidate-list scroll-y table-responsive'>
          <Table className='sp-table'>
            <thead>
              <tr>
                {/* commenting for now can be used later */}
                {/* <th>Id</th> */}
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Mobile Number</th>
                {activeTab === 'absentCandidate' &&
                  driveStatus === 'ongoing'
                  && (
                  <>
                    <th>
                      <Input
                        type='checkbox'
                        name='check'
                        id='select-all-checkbox'
                        className='select-all-checkbox'
                        checked={isSelectAllChecked}
                        onClick={handleSelectAll}
                        disabled={totalCandidates === 0}
                      />
                      Resend Invitation
                    </th>
                  </>
                  )}
              </tr>
            </thead>
            <tbody className='scroll-y'>
              <RenderCandidateListTable
                allCandidates={allCandidates}
                activeTab={activeTab}
                isCheck={isCheck}
                handleClick={handleClick}
                driveStatus={driveStatus}
              />
            </tbody>

          </Table>

          {pageCount > 1 && (
            <ReactPaginate
              previousLabel={previousArrow}
              nextLabel={nextArrow}
              breakLabel='...'
              breakClassName='break-me'
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName='sp-pagination'
              previousLinkClassName='sp-pagination__link'
              nextLinkClassName='sp-pagination__link'
              disabledClassName='sp-pagination__link--disabled'
              activeClassName='sp-pagination__link--active'
              pageLinkClassName='sp-page__link'
            />
          )}
        </div>
      </div>
      {addCandidateModal ? <SendEmailInviteContainer /> : null}
    </>
  );
};

ShowCandidateListComponent.defaultProps = {
  driveStatus: '',
};

ShowCandidateListComponent.propTypes = {
  handleAddCandidateClick: PropTypes.func.isRequired,
  handlePageClick: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  candidateIsLoading: PropTypes.bool.isRequired,
  previousClick: PropTypes.func.isRequired,
  addCandidateModal: PropTypes.bool.isRequired,
  driveName: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  searchQuery: PropTypes.string.isRequired,
  handleSearchClick: PropTypes.func.isRequired,
  handleCandidateSearchClearClick: PropTypes.func.isRequired,
  totalCandidates: PropTypes.number.isRequired,
  allCandidates: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      mobile_number: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  handleResendInvitation: PropTypes.func.isRequired,
  isCheck: PropTypes.object.isRequired,
  isSelectAllChecked: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  driveStatus: PropTypes.string,
};

export default React.memo(ShowCandidateListComponent);
