import React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import SideNavComponent from 'modules/admin/sideNav/SideNavComponent';

import {
  setIsCopyFromSearch,
  setProfileModal,
  setSearchModal,
} from 'redux/admin/modalStates/action';
import { problemsListClear } from 'redux/admin/problemsList/action';
import { clearSearchDrivesAction } from 'redux/admin/driveSearch/action';
import { questionsListClear } from 'redux/admin/questions/action';
import { setSideNavState } from 'redux/admin/sideNavState/action';
import { clearAssessmentsAction } from 'redux/admin/assessmentList/action';
import { feedbackClearAction } from 'redux/admin/feedbacks/action';
import {  isCopyDriveAction,
} from 'redux/admin/getEditDrive/action';
import { resetActiveTab } from 'redux/admin/questionTabs/action';
import { tourSteps } from 'utils/helpers/reactDemoWalkHelper';

import { getUserDetails } from 'utils';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';

const SideNavContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { profileModal, searchModal } = useSelector((state) => state.modalStatusReducer);
  const { enabledSideNav } = useSelector((state) => state.sideNavStateReducer);
  const isHeighlightQuestion = (location.pathname === '/admin/problems') || (location.pathname === '/admin/questions');
  const profileDetails = getUserDetails();
  const allowCoding = profileDetails.allow_coding;
  const allowMcq = profileDetails.allow_mcq;
  const isParamAiUser = profileDetails.is_param_ai_allowed;

  const handleProfileClick = (e) => {
    dispatch(setProfileModal(true));
  };
  const handleSearchClick = (e) => {
    dispatch(clearSearchDrivesAction());
    dispatch(isCopyDriveAction(false));
    dispatch(setSearchModal(true));
    dispatch(setIsCopyFromSearch(false));
  };

  const handleClearState = () => {
    dispatch(feedbackClearAction());
    dispatch(clearAssessmentsAction());
    dispatch(problemsListClear());
    dispatch(questionsListClear());
    dispatch(resetActiveTab());
  };

  const handleSelectedNavItem = () => {
    if (enabledSideNav) {
      dispatch(setSideNavState());
    } else {
      dispatch(setSideNavState('Questions'));
    }
  };

  const navLinks = [
    {
      navIcon: 'home',
      navLabel: 'Home',
      navPath: `${ROUTES.ADMIN}${ADMIN_ROUTES.HOME}`,
    },
    {
      navIcon: 'drive',
      navLabel: 'New Drive',
      navPath: `${ROUTES.ADMIN}${ADMIN_ROUTES.CREATE_DRIVE}`,
    },
    {
      navIcon: 'rules',
      navLabel: 'Rules',
      navPath: `${ROUTES.ADMIN}${ADMIN_ROUTES.RULES}`,
    },
    {
      navIcon: 'problems-bank',
      navLabel: 'Questions',
      navPath: `${ROUTES.ADMIN}${ADMIN_ROUTES.PROBLEMS}`,
    },
    {
      navIcon: 'users',
      navLabel: 'Users',
      navPath: `${ROUTES.ADMIN}${ADMIN_ROUTES.INVITE_USER}`,
    },
    {
      navIcon: 'assessment',
      navLabel: 'Assessments',
      navPath: `${ROUTES.ADMIN}${ADMIN_ROUTES.ASSESSMENTS}`,
    },
    {
      navIcon: 'feedback',
      navLabel: 'Feedbacks',
      navPath: `${ROUTES.ADMIN}${ADMIN_ROUTES.FEEDBACKS}`,
    },
  ];

  return (
    <SideNavComponent
      handleProfileClick={handleProfileClick}
      handleSearchClick={handleSearchClick}
      showProfileModal={profileModal}
      searchModal={searchModal}
      handleClearState={handleClearState}
      handleSelectedNavItem={handleSelectedNavItem}
      enabledSideNav={enabledSideNav}
      isHeighlightQuestion={isHeighlightQuestion}
      navLinks={navLinks}
      tourSteps={tourSteps}
    />
  );
};

export default React.memo(SideNavContainer);
