import { PROBLEMS_LIST, PROBLEM_DELETE } from 'redux/admin/problemsList/actionContants';

const {
  PROBLEMS_LIST_FAILURE_ACTION, PROBLEMS_LIST_REQUEST_ACTION,
  PROBLEMS_LIST_SUCCESS_ACTION, SET_PROBLEMS_DIFFICULTY, SET_PROBLEMS_LIMIT,
  SET_PROBLEMS_SEARCH_TEXT, SET_PROBLEMS_TAG, RESET_PROBLEMS_FILTERS, CLEAR_PROBLEMS,
  RESET_PROBLEMS_SEARCH_TEXT, SET_SEARCHED_PROBLEMS_FILTER_ACTION,
  RESET_SEARCHED_PROBLEMS_FILTER_ACTION,
} = PROBLEMS_LIST;

const { PROBLEM_DELETE_FAILURE_ACTION, PROBLEM_DELETE_REQUEST_ACTION,
  PROBLEM_DELETE_SUCCESS_ACTION } = PROBLEM_DELETE;

export const problemsListRequest = (payload) => ({
  type: PROBLEMS_LIST_REQUEST_ACTION,
  payload,
});

export const problemsListAction = (payload) => ({
  type: PROBLEMS_LIST_SUCCESS_ACTION,
  payload,
});

export const problemsListFailureAction = (payload) => ({
  type: PROBLEMS_LIST_FAILURE_ACTION,
  payload,
});

export const problemsListClear = () => ({
  type: CLEAR_PROBLEMS,
});

export const problemDeleteRequest = (payload) => ({
  type: PROBLEM_DELETE_REQUEST_ACTION,
  payload,
});

export const problemDeleteSuccessAction = (payload) => ({
  type: PROBLEM_DELETE_SUCCESS_ACTION,
  payload,
});

export const problemDeleteFailureAction = (payload) => ({
  type: PROBLEM_DELETE_FAILURE_ACTION,
  payload,
});

export const setProblemsOffset = (payload) => ({
  type: SET_PROBLEMS_LIMIT,
  payload,
});

export const setProblemsSearchText = (payload) => ({
  type: SET_PROBLEMS_SEARCH_TEXT,
  payload,
});

export const setProblemsDifficulty = (payload) => ({
  type: SET_PROBLEMS_DIFFICULTY,
  payload,
});

export const setProblemsTag = (payload) => ({
  type: SET_PROBLEMS_TAG,
  payload,
});

export const resetProblemsFilters = () => ({
  type: RESET_PROBLEMS_FILTERS,
});

export const resetProblemsSearchText = () => ({
  type: RESET_PROBLEMS_SEARCH_TEXT,
});

export const setSearchedProblemsFilterAction = (payload) => ({
  type: SET_SEARCHED_PROBLEMS_FILTER_ACTION,
  payload,
});

export const resetSearchedProblemsFilterAction = () => ({
  type: RESET_SEARCHED_PROBLEMS_FILTER_ACTION,
});
