export const DRIVE_LIST = {
  UPCOMING_DRIVE_LIST_REQUEST: 'UPCOMING_DRIVE_LIST_REQUEST',
  ONGOING_DRIVE_LIST_REQUEST: 'ONGOING_DRIVE_LIST_REQUEST',
  COMPLETED_DRIVE_LIST_REQUEST: 'COMPLETED_DRIVE_LIST_REQUEST',
  UPCOMING_DRIVE_LIST_SUCCESS: 'UPCOMING_DRIVE_LIST_SUCCESS',
  ONGOING_DRIVE_LIST_SUCCESS: 'ONGOING_DRIVE_LIST_SUCCESS',
  COMPLETED_DRIVE_LIST_SUCCESS: 'COMPLETED_DRIVE_LIST_SUCCESS',
  CLEAR_DRIVES: 'CLEAR_DRIVES',
  ONGOING_DRIVE_LIST_FAILURE: 'ONGOING_DRIVE_LIST_FAILURE',
  UPCOMING_DRIVE_LIST_FAILURE: 'UPCOMING_DRIVE_LIST_FAILURE',
  COMPLETED_DRIVE_LIST_FAILURE: 'COMPLETED_DRIVE_LIST_FAILURE',
  DRIVE_DELETE_REQUEST: 'DRIVE_DELETE_REQUEST',
  DRIVE_DELETE_SUCCESS: 'DRIVE_DELETE_SUCCESS',
  DRIVE_DELETE_FAILURE: 'DRIVE_DELETE_FAILURE',
  SET_COMPLETED_DRIVE_OFFSET: 'SET_COMPLETED_DRIVE_OFFSET',
  COMPLETED_DRIVE_DELETE_SUCCESS: 'COMPLETED_DRIVE_DELETE_SUCCESS',
  SET_ONGOING_DRIVE_OFFSET: 'SET_ONGOING_DRIVE_OFFSET',
  ONGOING_DRIVE_DELETE_SUCCESS: 'ONGOING_DRIVE_DELETE_SUCCESS',
  SET_UPCOMING_DRIVE_OFFSET: 'SET_UPCOMING_DRIVE_OFFSET',
  UPCOMING_DRIVE_DELETE_SUCCESS: 'UPCOMING_DRIVE_DELETE_SUCCESS',
};
