import { REMAINING_CREDITS } from 'redux/admin/remainingCredits/actionContants';

export const remainingCreditsRequest = (payload) => ({
  type: REMAINING_CREDITS.REMAINING_CREDITS_REQUEST_ACTION,
  payload,
});

export const remainingCreditsSuccessAction = (payload) => ({
  type: REMAINING_CREDITS.REMAINING_CREDITS_SUCCESS_ACTION,
  payload,
});

export const remainingCreditsFailureAction = (payload) => ({
  type: REMAINING_CREDITS.REMAINING_CREDITS_FAILURE_ACTION,
  payload,
});
