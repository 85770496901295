import { call, put, takeLatest } from 'redux-saga/effects';

import {
  driveSearchSuccess,
  driveSearchFailure,
} from 'redux/admin/driveSearch/action';

import { driveSearchGetApi } from 'redux/admin/driveSearch/api';
import { DRIVE_SEARCH } from 'redux/admin/driveSearch/actionConstants';

export function* driveSearchSaga(action) {
  try {
    const { driveSearchOffSet, searchText } = action.payload;
    const response = yield call(driveSearchGetApi, { offset: driveSearchOffSet, searchText });
    yield put(driveSearchSuccess(response.data.data));
  } catch (error) {
    yield put(driveSearchFailure('error while fetching ongoing drives'));
  }
}

export default function* driveSearchWatcherSaga() {
  yield takeLatest(DRIVE_SEARCH.DRIVE_SEARCH_REQUEST_ACTION, driveSearchSaga);
}
