import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { useHistory } from 'react-router-dom';
import { Buffer } from 'buffer';
import PropTypes from 'prop-types';

import CustomIOComponent from 'components/IdeComponent/CustomIOComponent';

import {
  setInput,
  setOutput,
  outputRequest,
  clearIO,
} from 'actions/ioAction';
import { changeSection } from 'actions/sectionStatusAction';
import { submitRequest } from 'actions/codeSubmissionActions';
import { setCode, setLanguageSelected } from 'actions/languageAction';
import {
  saveMCQAnswerRequest,
  mcqQuestionSubmission,
  clearSavedMessage,
} from 'actions/mcqQuestionsActions';

import { currentLangState } from 'utils/helpers/currentLanguageHelper';
import session from 'utils/session';
import local from 'utils/local';

const CustomIOContainer = ({
  handleSaveDraft,
  setOpenOutputScreen,
  openOutputScreen,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const codes = {}; // codes will set code according to question name
  const broadcastingRoom = `room_${localStorage.getItem('authToken')}`;

  const [finalOutputModal, setFinalOutputModal] = useState(false);
  const [limit, setlimit] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [isOpenCustomInput, setIsOpenCustomInput] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);

  let { code, languageSelected } = useSelector((state) => state.languageReducer);
  const { selectedSection } = useSelector((state) => state.sectionStatusReducer);
  const { languages, first } = useSelector((state) => state.languageReducer);
  const { submissionAllowed } = useSelector((state) => state.codeSubmissionReducer);
  const { candidateId } = useSelector((state) => state.userDriveReducer);

  const { isError, errorMessage, totalTestcases, testcasesPassed, isLoading, isSuccess } =
    useSelector((state) => state.codeSubmissionReducer);

  const {
    inputValue: storedInput,
    outputValue: storedOutput,
    token: latestToken,
  } = useSelector((state) => state.ioReducer);

  const {
    statement,
    activeIndex,
  } = useSelector((state) => state.problemStatementReducer);

  const {
    backupCode: {
      answer,
      problem_id: backupCodeProblemId,
      lang_code: backupLanguageId,
      submission_count_left: leftSubmissionCount,
    },
    errorMessage: backupCodeErrMsg,
    isError: backupCodeErr,
  } = useSelector((state) => state.codeBackupReducer);

  const {
    questions,
    selectedQuestion,
    selectedAnswers,
    saveddAnswers,
    saveSuccessMsg,
  } = useSelector((state) => state.mcqQuestionsReducer);

  const allowCoding = session.getItem('allowCoding');

  const isAllMcqAnswersSaved = useMemo(
    () => Object.keys(saveddAnswers).length === questions.length,
    [saveddAnswers, questions],
  );

  const inputOutuptValue = {
    inputValue: storedInput,
    outputValue: storedOutput,
  };

  useEffect(() => {
    if (saveSuccessMsg === 'Answer Saved Successfully') {
      setTimeout(() => {
        dispatch(clearSavedMessage());
      }, 5000);
    }
  }, [saveSuccessMsg]);

  useEffect(() => {
    setLoading(false);
    dispatch(clearIO());
  }, [selectedSection, code]);

  const questionId = questions[selectedQuestion - 1]?.id;

  const {
    id: problemId,
    test_case: testCase,
  } = statement[activeIndex - 1] || { problem_id: null };

  if (problemId) {
    const { currCode, currLanguageSelected } = currentLangState(
      code,
      answer,
      problemId,
      languages,
      backupLanguageId,
      languageSelected,
      backupCodeProblemId,
    );
    code = currCode;
    languageSelected = currLanguageSelected;
    codes[problemId] = {
      code: currCode,
      languageSelected: currLanguageSelected,
    };
  }

  const handleReceived = (data) => {
    const { token, stderr, stdout, compile_output } = data;
    if (token === latestToken) {
      let outputValue = '';

      if (stderr) {
        outputValue = stderr;
      } else if (!stdout) {
        outputValue = Buffer.from(compile_output, 'base64');
      } else {
        outputValue = stdout;
      }
      dispatch(setOutput(outputValue));
    }
    setLoading(false);
  };

  if (problemId) {
    const { currCode, currLanguageSelected } = currentLangState(
      code,
      answer,
      problemId,
      languages,
      backupLanguageId,
      languageSelected,
      backupCodeProblemId,
      submissionAllowed,
      leftSubmissionCount,
    );

    code = currCode;

    if (languageSelected.id !== currLanguageSelected.id) {
      dispatch(setLanguageSelected(currLanguageSelected));
    }
    languageSelected = currLanguageSelected;
  }

  const driveID = local.getItem('driveID');

  const languageId = languageSelected.id;

  const handleSubmit = async () => {
    if (submissionAllowed > 0) {
      if (code != null) {
        const obj = {
          code,
          languageId,
          problemId,
          submissionAllowed,
          candidateId,
          driveID,
        };
        if (first) {
          obj.code = answer;
        }
        setFinalOutputModal(true);
        handleSaveDraft();
        dispatch(submitRequest(obj));
      } else {
        // TODO handle error
      }
    } else {
      setlimit(!limit);
    }
  };

  const handleRunClick = async () => {
    dispatch(setOutput(''));
    const data = {
      language_id: codes[problemId]?.languageSelected.id,
      language_name: languageSelected.name,
      source_code: codes[problemId]?.code,
      stdin: inputOutuptValue.inputValue || testCase,
      room: 'broadcastingRoom',
    };
    dispatch(outputRequest(data));
    setLoading(true);
  };

  const toggleConfirmationSubmitModal = () => setConfirmationModal(!confirmationModal);
  const toggleFinalOutputModal = () => setFinalOutputModal(!finalOutputModal);

  const handleConfirmation = useCallback(() => {
    toggleConfirmationSubmitModal();
    handleSubmit();
  });

  const handleInputChange = useCallback(
    (event) => {
      dispatch(setInput(event.target.value));
    },
    [inputOutuptValue.inputValue],
  );

  const onSaveQuestion = () => {
    dispatch(saveMCQAnswerRequest({ [questionId]: selectedAnswers[questionId] }));
  };

  const currentQuestionIndex = questions.findIndex(
    (i) => i.id === questions[selectedQuestion - 1]?.id,
  );

  const onMCQSubmitClick = () => {
    dispatch(mcqQuestionSubmission());
    if (allowCoding) {
      dispatch(changeSection(2));
    }
    toggleConfirmationSubmitModal();
  };

  const isSelectedAnswer =
    (selectedAnswers[questionId]?.length) &&
    (selectedAnswers[questionId]?.length !== 0);

  if (submissionAllowed === 0 && !limit) {
    setlimit(true);
  } else if (submissionAllowed > 0 && limit) {
    setlimit(false);
  }

  const handleEvent = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <ActionCableConsumer
        channel={{ channel: 'TestChannel', room: 'broadcastingRoom' }}
        onReceived={handleReceived}
      />
      <CustomIOComponent
        toggleFinalOutputModal={toggleFinalOutputModal}
        outputValue={inputOutuptValue.outputValue}
        inputValue={inputOutuptValue.inputValue}
        loading={loading}
        isError={isError}
        isSuccess={isSuccess}
        submissionAllowed={submissionAllowed}
        isLoading={isLoading}
        limit={limit}
        totalTestcases={totalTestcases}
        testcasesPassed={testcasesPassed}
        toggleConfirmationSubmitModal={toggleConfirmationSubmitModal}
        errorMessage={errorMessage}
        modal={finalOutputModal}
        handleConfirmation={handleConfirmation}
        confirmationModal={confirmationModal}
        handleRunClick={handleRunClick}
        isOpenCustomInput={isOpenCustomInput}
        handleInputChange={handleInputChange}
        selectedSection={selectedSection}
        onSaveQuestion={onSaveQuestion}
        isSelectedAnswer={isSelectedAnswer}
        currentQuestionIndex={currentQuestionIndex}
        totalQuestion={questions?.length}
        onMCQSubmitClick={onMCQSubmitClick}
        saveSuccessMsg={saveSuccessMsg}
        handleEvent={handleEvent}
        handleSaveDraft={handleSaveDraft}
        isAllMcqAnswersSaved={isAllMcqAnswersSaved}
        setOpenOutputScreen={setOpenOutputScreen}
        openOutputScreen={openOutputScreen}
      />
    </>
  );
};

CustomIOContainer.propTypes = {
  handleSaveDraft: PropTypes.func.isRequired,
  setOpenOutputScreen: PropTypes.func.isRequired,
  openOutputScreen: PropTypes.bool.isRequired,
};

export default React.memo(CustomIOContainer);
