import produce from 'immer';

import { EDIT_PROBLEM } from 'redux/admin/editProblem/actionConstants';

export const initialState = {
  data: {
    title: '',
    description: '',
    submissionCount: null,
    timeInMinutes: '',
    difficulty: '',
  },
  message: '',
  problemId: '',
  isSuccess: false,
  isLoading: false,
  isError: false,
  errorMessage: '',
  problemImage: '',
};

const {
  EDIT_PROBLEM_IMAGE_ACTION,
  SET_UPLOAD_IMAGES_SUCCESS,
  EDIT_PROBLEM_SUCCESS_ACTION,
  EDIT_PROBLEM_FAILURE_ACTION,
  EDIT_PROBLEM_REQUEST_ACTION,
  SET_PROBLEM_ID,
  CLEAR_PROBLEM,
} = EDIT_PROBLEM;

const editProblemReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case EDIT_PROBLEM_SUCCESS_ACTION:
      state.message = payload.message;
      state.data = payload.data;
      state.problemId = payload.pid;
      state.isLoading = false;
      state.isSuccess = true;
      break;
    case EDIT_PROBLEM_FAILURE_ACTION:
      state.data = payload.data;
      state.message = payload.message;
      state.isError = true;
      state.errorMessage = payload.errorMessage;
      state.isLoading = false;
      break;
    case EDIT_PROBLEM_REQUEST_ACTION:
      state.isLoading = true;
      break;
    case SET_PROBLEM_ID:
      state.problemId = payload;
      break;
    case EDIT_PROBLEM_IMAGE_ACTION:
      state.problemImage = payload;
      break;
    case CLEAR_PROBLEM:
      return { ...initialState };
    default:
      return state;
  }
});

export default editProblemReducer;
