/* eslint-disable react/no-unknown-property */
/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-quotes */
/* eslint-disable react/jsx-boolean-value */
import PropTypes from 'prop-types';
import React from 'react';

import {
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Modal,
  Icon,
  ModalBody,
  Spinner,
  Button,
} from 'core-components';
import {
  InputWithButton,
} from 'shared-components';

import { insufficientCreditsMessage } from 'constants/messageConstants';

import siteLogo from 'assets/images/spark-code-logo.png';

import 'modules/admin/sendEmailInvite/SendEmailInviteComponent/styles.css';
import './sendEmailInviteComponent.scss';

const SendEmailInviteComponent = (props) => {
  const {
    emailsState,
    handleInvitationEmails,
    handleUploadedInvitationEmails,
    handleSendInvitationByEmail,
    handleSubmit,
    handleInvitationEmailsErrorMessage,
    isOpen = false,
    toggle,
    selectedFileName,
    credits,
    name,
    loading,
    handleOnDownloadFile,
    handleSendInvitationByCSV,
  } = props;
  const {
    emails,
    csvFileError,
    successMessage,
    errorMessage,
    csvSuccessMessage,
    csvErrorMessage,
    invalidEmailsFilePath,
  } = emailsState;

  return (
    <Modal isOpen={isOpen} modalClassName='add-candidate-modal'>
      {loading && (
        <div className='loader-wrapper align-items-center justify-content-center'>
          <Spinner className='loader' color='primary' />
        </div>
      )}
      <div className='candidate-modal-header d-flex justify-content-end'>
        <Icon name='close' onClick={toggle} />
      </div>
      <ModalBody className='candidate-modal-body d-flex flex-column'>
        <a href='/admin/home' className='site-logo-img d-block pb-5'>
          <img
            src={siteLogo}
            alt='Spark Code Logo'
          />
        </a>
        <div>
          {((errorMessage || csvErrorMessage) === insufficientCreditsMessage) && (
            <div className='text-warning mt-2'>
              You do not have sufficient credits to invite candidates.
              <a target='_blank' href='/admin/support' className='text-light'> Click here </a>  to purchase more credits
            </div>
          )}
        </div>
        <div className='credit-points-wrapper d-flex align-items-center justify-content-end mr-3'>
          {
            (credits > 0) ? (
              <div className='text-warning'>
                <Icon name='star' />
                {credits} Credits
              </div>
            ) :
              (
                <div className='text-danger'>
                  <Icon name='star' />
                  0 Credits
                </div>
              )
          }
        </div>
        <Form
          className='candidate-invitation-wrap d-flex align-items-center'
          onSubmit={handleSubmit}
          method='post'
        >
          <div className='invitation-col'>
            <label className='mb-5'>
              SparKode Invitation <h4 className='font-weight-bold text-capitalize name'> {name} </h4>
            </label>
            <InputWithButton
              className="p-3 gap-2"
              color='secondary'
              buttonText='Send Invitation'
              placeHolder='Enter Candidate Email'
              value={emails}
              onChange={handleInvitationEmails}
              onClickInput={handleInvitationEmailsErrorMessage}
              errorMessage={errorMessage}
              successMessage={successMessage}
              onClick={handleSendInvitationByEmail}
              disabled={loading}
            />
          </div>
          <div className='invitation-col -divider-wrap'>
            <div className='divider-line text-uppercase'>or</div>
          </div>
          <div className='invitation-col px-3'>
            <span className='mb-3 d-block'>
              Send Bulk Invitation by Uploading CSV file
              <a
                role='button'
                href={`${process.env.PUBLIC_URL}/sampleData.csv`}
                download='sampleData.csv'
                style={{ color: '#39FAFF' }}
              >
                &nbsp;(sample csv)
              </a>
            </span>
            <div className='upload-file-wrap d-flex align-items-center'>
              <FormGroup className='mb-0'>
                <div className='browse'>
                  <label
                    className='browse-label py-2 px-3 mb-0'
                    htmlFor='selectFile'
                  >
                    Select File
                  </label>
                  <Input
                    type='file'
                    className='browse-input'
                    name='Selectfile'
                    id='selectFile'
                    onInputCapture={handleUploadedInvitationEmails}
                    invalid={csvFileError !== ''}
                  />
                  <FormFeedback>{csvFileError}</FormFeedback>
                </div>
              </FormGroup>
              <p className='mb-0 ml-3'>{selectedFileName}</p>
              <Button className='send-invitation ml-2 bg-transparent border-0'>
                <Icon
                  name='send'
                  onClick={handleSendInvitationByCSV}
                  className='send-icon '
                  title='Send Invitation'
                />
              </Button>
              {invalidEmailsFilePath && (
                <Button
                  className='download-error-file bg-transparent border-0'
                  onClick={handleOnDownloadFile}
                >
                  <Icon
                    name='download'
                    className='download-icon text-danger'
                    title='Download Error File'
                  />
                </Button>
              )}
            </div>
            {csvErrorMessage && (
              <div className='text-danger mt-2'>
                {csvErrorMessage}
              </div>
            )}
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

SendEmailInviteComponent.propTypes = {
  handleInvitationEmails: PropTypes.func.isRequired,
  handleUploadedInvitationEmails: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleInvitationEmailsErrorMessage: PropTypes.func.isRequired,
  emailsState: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  selectedFileName: PropTypes.string.isRequired,
  credits: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  handleOnDownloadFile: PropTypes.func.isRequired,
  handleSendInvitationByEmail: PropTypes.func.isRequired,
  handleSendInvitationByCSV: PropTypes.func.isRequired,
};

export default SendEmailInviteComponent;
