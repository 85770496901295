import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import QuestionTabsComponent from 'modules/admin/questionTabs/QuestionTabsComponent';

import { setActiveTab } from 'redux/admin/questionTabs/action';
import {
  problemsListRequest, resetProblemsSearchText, setProblemsSearchText,
} from 'redux/admin/problemsList/action';
import {
  questionsListRequest, resetQuestionSearchText, setQuestionsSearchText,
} from 'redux/admin/questions/action';
import { downloadMcqRequestAction } from 'redux/admin/downloadMcq/action';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';

const QuestionTabsContainer = () => {
  const { CREATE_QUESTION, CREATE_PROBLEM } = ADMIN_ROUTES;
  const { ADMIN } = ROUTES;
  const history = useHistory();
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);
  const [showQuestionsFilter, setShowQuestionsFilter] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [searchedQuestionText, setSearchedQuestionText] = useState('');
  const [searchedProblemText, setSearchedProblemText] = useState('');

  const { activeTab } = useSelector((state) => state.getActiveTab);
  const {
    totalQuestions,
    isQuestionsLoading,
    questionSearchText,
    questionDifficulty,
    questionTag,
    searchedQuestionDifficulty,
    searchedQuestionTag,
  } = useSelector((item) => item.questionsListReducer);
  const {
    totalProblems,
    isProblemsLoading,
    problemSearchText,
    searchedProblemDifficulty,
    searchedProblemTag,
  } = useSelector((item) => item.problemsListReducer);

  const handleAddQuestionClick = () => {
    history.push(ADMIN + CREATE_QUESTION);
  };

  const handleAddProblemClick = () => {
    history.push(ADMIN + CREATE_PROBLEM);
  };

  const handleOnChange = useCallback((event) => {
    dispatch(setQuestionsSearchText(event.target.value));
  }, [questionSearchText]);

  const onProblemSearchChange = useCallback((event) => {
    dispatch(setProblemsSearchText(event.target.value));
  }, [problemSearchText]);

  const handleProblemSearchClick = useCallback(async () => {
    if (problemSearchText || isSearched) {
      if (!isProblemsLoading) {
        setSearchedProblemText(problemSearchText);
        dispatch(problemsListRequest({
          offset: 0,
          searchedProblemText: problemSearchText ? problemSearchText.trim() : problemSearchText,
          problems: [],
          searchedProblemDifficulty,
          searchedProblemTag,
        }));
        if (!problemSearchText && isSearched) {
          setIsSearched(false);
        } else {
          setIsSearched(true);
        }
      }
    }
  }, [problemSearchText, isSearched]);

  const handleProblemSearchClearClick = () => {
    dispatch(resetProblemsSearchText());
    if (isSearched) {
      setSearchedProblemText('');
      dispatch(problemsListRequest({
        offset: 0,
        searchedProblemText: '',
        problems: [],
        searchedProblemDifficulty,
        searchedProblemTag,
      }));
    }
    setIsSearched(false);
  };

  const handleQuestionSearchClick = useCallback(async () => {
    if (questionSearchText || isSearched) {
      if (!isQuestionsLoading) {
        setSearchedQuestionText(questionSearchText);
        dispatch(questionsListRequest({
          offset: 0,
          searchedQuestionText: questionSearchText ? questionSearchText.trim() : questionSearchText,
          questions: [],
          searchedQuestionDifficulty,
          searchedQuestionTag,
        }));
        if (!questionSearchText && isSearched) {
          setIsSearched(false);
        } else {
          setIsSearched(true);
        }
      }
    }
  }, [questionSearchText, questionDifficulty, questionTag, isSearched]);

  const handleQuestionSearchClearClick = () => {
    dispatch(resetQuestionSearchText());
    setSearchedQuestionText('');
    if (isSearched) {
      dispatch(questionsListRequest({
        offset: 0,
        questionSearchText: '',
        questions: [],
        searchedQuestionDifficulty,
        searchedQuestionTag,
      }));
    }
    setIsSearched(false);
  };

  const onClickAddFilter = () => {
    setShowFilter(!showFilter);
  };

  const onClickQuestionsFilter = () => {
    setShowQuestionsFilter(!showQuestionsFilter);
  };

  const setActiveTabMethod = (activeTabState) => {
    dispatch(setActiveTab(activeTabState));
    setIsSearched(false);
  };

  const handleDownloadMcq = () => {
    dispatch(downloadMcqRequestAction({
      questionSearchText,
      questionDifficulty,
      questionTag,
    }));
  };

  return (
    <QuestionTabsComponent
      activeTab={activeTab}
      setActiveTabMethod={setActiveTabMethod}
      totalQuestions={totalQuestions}
      handleAddQuestionClick={handleAddQuestionClick}
      handleAddProblemClick={handleAddProblemClick}
      handleOnChange={handleOnChange}
      handleProblemSearchClick={handleProblemSearchClick}
      handleProblemSearchClearClick={handleProblemSearchClearClick}
      totalProblems={totalProblems}
      onClickAddFilter={onClickAddFilter}
      showFilter={showFilter}
      handleQuestionSearchClick={handleQuestionSearchClick}
      handleQuestionSearchClearClick={handleQuestionSearchClearClick}
      questionSearchText={questionSearchText}
      onClickQuestionsFilter={onClickQuestionsFilter}
      searchedQuestionText={searchedQuestionText}
      searchedProblemText={searchedProblemText}
      showQuestionsFilter={showQuestionsFilter}
      onProblemSearchChange={onProblemSearchChange}
      problemSearchText={problemSearchText}
      handleDownloadMcq={handleDownloadMcq}
    />
  );
};

export default QuestionTabsContainer;
