import { call, put, takeEvery } from 'redux-saga/effects';
import {
  organizationListAction,
  organizationListFailureAction,
} from 'redux/superAdmin/organizationList/action';
import { getOrganization } from 'redux/superAdmin/organizationList/api';
import { ORGANIZATION_LIST } from 'redux/superAdmin/organizationList/actionContants';
import queryStringHelper from 'utils/queryStringHelper';
import { toast } from 'react-toastify';
import { ORGANIZATION_LIMIT } from 'constants/appConstants';

export function* getOrganizationList(action) {
  const { offset, searchText } = action.payload;
  const getQueryString = queryStringHelper({
    offset,
    limit: ORGANIZATION_LIMIT,
    search: searchText,
  });
  try {
    const response = yield call(getOrganization, getQueryString);
    yield put(organizationListAction(response.data.data));
  } catch (error) {
    yield put(organizationListFailureAction('Error while fetching organization list'));
  }
}

export default function* organizationListSaga() {
  yield takeEvery(ORGANIZATION_LIST.ORGANIZATION_LIST_REQUEST_ACTION, getOrganizationList);
}
