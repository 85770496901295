import React, { useState, useEffect, createRef } from 'react';
import PropTypes, { shape, string } from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Form, FormikProvider } from 'formik';
import { toast } from 'react-toastify';

import OptionContainer from 'modules/admin/option/OptionContainer';

import {
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Icon,
} from 'core-components';
import { DeleteConfirmModal, ImagePopUpModal } from 'shared-components';

import { imageFileToUrl, isImageFile } from 'utils';
import { capitalize } from 'utils/capitalize';

import { booleanOptions } from 'constants/optionsConstants';
import { CustomThemeSelect, CustomThemeMultiSelect } from 'constants/themesConstants';
import { customStyle } from 'constants/stylesConstants';
import { imageFormatErrorMessage } from 'constants/messageConstants';

import './createQuestionComponent.scss';

const CreateQuestionComponent = (props) => {
  const {
    isQuestionCreateLoading,
    isQuestionCreateSuccess,
    defaultTags,
    defaultType,
    pageTitle,
    isQuestionLoading,
    imageUrl,
    tagsOptions,
    difficultyOptions,
    showImageModal,
    toggleImageModalVisibility,
    handleAddNextQuestionClick,
    handleBackClick,
    isCreateQuestion,
    formik,
    handleOnOptionDelete,
    toggleConfirmation,
    handleDeleteClick,
    confirmationModal,
    targetId,
    mcqDescription,
  } = props;

  const scrollUp = createRef();
  const [selectedImage, setSelectedImage] = useState('');
  const [questionImage, setQuestionImage] = useState('');
  const [questionImageError, setQuestionImageError] = useState('');

  useEffect(() => {
    setQuestionImage(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    setQuestionImage(imageUrl);
  }, []);

  const imagePreview = (imageFile) => {
    setSelectedImage(imageFileToUrl(imageFile));
  };

  const handleUploadedImage = (e) => {
    const file = e.target.files[0];
    setQuestionImageError('');

    if (file) {
      if (isImageFile(file.name)) {
        formik.setFieldValue('questionDiagram', file);
        imagePreview(file);
      } else {
        setSelectedImage('');
        setQuestionImageError(imageFormatErrorMessage);
      }
    }
  };

  const setFieldTouched = (field) => {
    formik.setFieldTouched(field, true);
  };

  const addNewOption = () => {
    const { mcqOptions } = formik.values;
    const nonDeletedOptions = mcqOptions.filter((option) => !option?.isDelete);
    if (nonDeletedOptions.length >= 5) {
      toast.error('Maximum 5 options are allowed');
    } else {
      mcqOptions.push({ description: '', isAnswer: false, optionDiagram: null });
      formik.setFieldValue('mcqOptions', mcqOptions);
    }
  };

  // it will scroll up if form has some error or else call submit()
  const handleScrollOnSubmit = () => {
    scrollUp.current.scrollIntoView({ behavior: 'smooth' });
    formik.handleSubmit();
  };

  if (isQuestionLoading) {
    return (<Spinner className='loader' />);
  }

  return (
    <div className='add-question-section py-3 d-flex flex-column px-3'>
      <DeleteConfirmModal
        confirmationModal={confirmationModal}
        toggleConfirmation={toggleConfirmation}
        handleDelete={handleOnOptionDelete}
        targetId={targetId}
        type='Option'
        name={mcqDescription}
      />
      <div className='add-question-section-head mb-4 px-4 d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <Icon
            name='left'
            className='back-page-arrow font-weight-bold'
            onClick={handleBackClick}
          />
          <h2 className='section-heading font-weight-bold mb-0 ml-3'>
            {pageTitle}
          </h2>
        </div>
        {isQuestionCreateSuccess && isCreateQuestion && (
          <Button
            color='primary'
            className='mr-1 cursor-pointet'
            onClick={handleAddNextQuestionClick}
            title='Add Next Question'
          >
            <Icon id='plus' name='plus' />
          </Button>
        )}
      </div>
      <FormikProvider value={formik}>
        <div className='question-content h-100 scroll-y'>
          <div
            className='question-col-wrapper d-flex'
            ref={scrollUp}
          >
            <div className='question-col p-4 position-relative'>
              <Form onSubmit={formik.handleSubmit} className='add-question-form'>
                <FormGroup>
                  <Label for='exampleText' className='px-3'>
                    Question Description *
                  </Label>
                  <Input
                    type='textarea'
                    name='description'
                    id='description'
                    className='height100'
                    placeholder='Enter Question Description'
                    onChange={formik.handleChange}
                    required
                    invalid={formik.touched.description && formik.errors.description}
                    value={formik.values.description}
                    onBlur={formik.handleBlur}
                  />
                  { formik.touched.description && formik.errors.description && (
                  <div className='text-danger'>
                    {capitalize(formik.errors.description)}
                  </div>
                  )}
                </FormGroup>
                {questionImage && (
                  <div
                    className='question-diagram-wrapper'
                    onClick={toggleImageModalVisibility}
                    role='button'
                    tabIndex={0}
                  >
                    <img
                      className='image-wrapper'
                      src={questionImage}
                      alt='Upload Question Img'
                    />
                  </div>
                )}
                <ImagePopUpModal
                  showImageModal={showImageModal}
                  toggleImageModalVisibility={toggleImageModalVisibility}
                  imageUrl={questionImage}
                  alternateName='Question Image'
                />
                <FormGroup className='width-fit-content'>
                  <Label for='exampleText' className='px-3'>
                    Upload Diagram
                  </Label>
                  <Input
                    type='file'
                    name='text'
                    id='exampleFile'
                    accept='image/*'
                    onInputCapture={handleUploadedImage}
                    onBlur={formik.handleBlur}
                  />
                  {questionImageError && (
                    <div className='text-danger pb-3'>
                      {questionImageError}
                    </div>
                  )}
                </FormGroup>
                <div className='form-group-col d-flex align-items-start'>
                  <FormGroup className='w-50 mb-0'>
                    <Label className='text-truncate px-3'>
                      Difficulty *
                    </Label>
                    <CreatableSelect
                      className='mr-3 text-light react-select-container'
                      classNamePrefix='react-select'
                      id='role'
                      value={formik.values.difficulty}
                      onChange={(selected) => {
                        formik.setFieldValue('difficulty', selected);
                        formik.setFieldTouched('difficulty', true);
                      }}
                      options={difficultyOptions}
                      placeholder={formik.values.difficulty || 'Select Difficulty'}
                      theme={CustomThemeSelect}
                      styles={customStyle}
                      onBlur={() => setFieldTouched('difficulty')}
                    />
                    {formik.touched.difficulty && !formik.values.difficulty && (
                      <div className='text-danger'>
                        {capitalize(formik.errors.difficulty)}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup className='w-50 mb-0'>
                    <Label className='text-truncate px-3'>
                      Tags *
                    </Label>
                    <CreatableSelect
                      className='mr-3 text-light react-select-container'
                      classNamePrefix='react-select'
                      id='role'
                      value={formik.values.tags}
                      onChange={(selected) => formik.setFieldValue('tags', selected)}
                      options={tagsOptions}
                      placeholder='Select Tags'
                      isMulti
                      theme={CustomThemeMultiSelect}
                      defaultValue={defaultTags}
                      styles={customStyle}
                      onBlur={() => setFieldTouched('tags')}
                    />
                    {formik.touched.tags && formik.errors.tags && (
                      <div className='text-danger'>
                        {capitalize(formik.errors.tags)}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup className='w-50 mb-0'>
                    <Label className='text-truncate px-3'>
                      Type *
                    </Label>
                    <Select
                      name='type'
                      className='mr-3 text-light react-select-container'
                      classNamePrefix='react-select'
                      id='role'
                      value={formik.values.type}
                      onChange={(selected) => {
                        formik.setFieldValue('type', selected);
                        formik.setFieldTouched('type', true);
                      }}
                      options={booleanOptions}
                      placeholder={defaultType || 'Select Question Type'}
                      theme={CustomThemeSelect}
                      styles={customStyle}
                      onBlur={() => setFieldTouched('type')}
                    />
                    {formik.touched.type && !formik.values.type && (
                      <div className='text-danger'>
                        {capitalize(formik.errors.type)}
                      </div>
                    )}
                  </FormGroup>
                </div>
              </Form>
            </div>
            <div className='question-col p-4 position-relative w-100 add-question-form'>
              <div className='form-group-col d-flex align-items-start'>
                <FormGroup className='w-50 mb-0 mr-2'>
                  <Label className='text-truncate'>
                    Marks *
                  </Label>
                  <Input
                    name='marks'
                    type='number'
                    placeholder='Enter Marks'
                    onChange={formik.handleChange}
                    required
                    className='select-input-type'
                    min={0}
                    invalid={formik.touched.marks && formik.errors.marks}
                    value={formik.values.marks}
                    onBlur={formik.handleBlur}
                  />
                  { formik.touched.marks && formik.errors.marks && (
                    <div className='text-danger'>
                      {capitalize(formik.errors.marks)}
                    </div>
                  )}
                </FormGroup>
                <FormGroup className='w-50 mb-0 mr-2'>
                  <Label className='text-truncate'>
                    Time in minutes *
                  </Label>
                  <Input
                    name='timeInMinutes'
                    type='number'
                    placeholder='Enter Time'
                    onChange={formik.handleChange}
                    required
                    className='select-input-type'
                    min={0}
                    invalid={formik.touched.timeInMinutes && formik.errors.timeInMinutes}
                    value={formik.values.timeInMinutes}
                    onBlur={formik.handleBlur}
                  />
                  { formik.touched.timeInMinutes && formik.errors.timeInMinutes && (
                    <div className='text-danger'>
                      {capitalize(formik.errors.timeInMinutes)}
                    </div>
                  )}
                </FormGroup>
              </div>
            </div>
          </div>
          <OptionContainer
            formik={formik}
            handleDeleteClick={handleDeleteClick}
          />
          <div className='d-flex align-items-center justify-content-end mt-3 px-4'>
            {/* TO DO
                onClick add option if previous option description is empty then
                don't allow to add new option */}
            <Button
              onClick={addNewOption}
              color='primary'
              className='ml-auto mr-3'
            >
              Add Option
            </Button>
            {!isQuestionCreateSuccess && (
              <Button
                type='submit'
                color='primary'
                className='text-uppercase px-5 py-2 mr-2'
                onClick={handleScrollOnSubmit}
              >
                {isQuestionCreateLoading ? (
                  <Spinner size='sm' color='light' />
                ) : (
                  <>Submit</>
                )}
              </Button>
            )}
            {isQuestionCreateSuccess && (
              <Button
                type='submit'
                color='primary'
                className='text-uppercase px-5 py-2 mr-2'
                onClick={handleScrollOnSubmit}
              >
                {isQuestionCreateLoading ? (
                  <Spinner size='sm' color='light' />
                ) : (
                  <>Update</>
                )}
              </Button>
            )}
          </div>
        </div>
      </FormikProvider>
    </div>
  );
};

CreateQuestionComponent.defaultProps = {
  isCreateQuestion: false,
};

CreateQuestionComponent.propTypes = {
  isQuestionCreateSuccess: PropTypes.bool.isRequired,
  isQuestionCreateLoading: PropTypes.bool.isRequired,
  defaultTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultType: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  isQuestionLoading: PropTypes.bool.isRequired,
  imageUrl: PropTypes.string.isRequired,
  tagsOptions: PropTypes.arrayOf(string).isRequired,
  difficultyOptions: PropTypes.arrayOf(shape).isRequired,
  showImageModal: PropTypes.bool.isRequired,
  toggleImageModalVisibility: PropTypes.func.isRequired,
  handleAddNextQuestionClick: PropTypes.func.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  isCreateQuestion: PropTypes.bool,
  formik: PropTypes.shape().isRequired,
  handleOnOptionDelete: PropTypes.func.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
  targetId: PropTypes.number.isRequired,
  mcqDescription: PropTypes.string.isRequired,
};

export default React.memo(CreateQuestionComponent);
