import { call, put, takeEvery } from 'redux-saga/effects';
import { contactsListSuccessAction, contactsListFailureAction } from 'redux/admin/getContacts/action';
import { getContacts } from 'redux/admin/getContacts/api';
import { CONTACTS } from 'redux/admin/getContacts/actionContants';
import queryStringHelper from 'utils/queryStringHelper';
import { CONTACTS_LIMIT } from 'constants/appConstants';

export function* getContactsList(action) {
  try {
    const response = yield call(getContacts);
    yield put(contactsListSuccessAction(response.data.data));
  } catch (error) {
    yield put(contactsListFailureAction(error));
  }
}

export default function* getContactsSaga() {
  yield takeEvery(CONTACTS.CONTACTS_REQUEST_ACTION, getContactsList);
}
