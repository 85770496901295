import { call, put, takeLatest } from 'redux-saga/effects';

import {
  assessementCandidatesListSuccess,
  assessmentCandidateReportSuccess,
} from 'redux/admin/assessmentCandidates/action';

import { AssessmentCandidatesGetApi, AssessmentReportsGetApi } from 'redux/admin/assessmentCandidates/api';
import {
  ASSESSMENT_CANDIDATES_LIST,
  ASSESSMENT_CANDIDATE_REPORTS,
} from 'redux/admin/assessmentCandidates/actionConstants';
import { toast } from 'react-toastify';

// worker saga

export function* assessementCandidatsList(action) {
  try {
    const { id, page, searchQuery, absentCandidates } = action.payload;
    const response = yield call(AssessmentCandidatesGetApi, {
      id,
      page,
      searchQuery,
      absentCandidates,
    });
    yield put(assessementCandidatesListSuccess(response.data.data));
  } catch (error) {
    // yield put(assessementDeleteFailure('error while deleting drive'));
  }
}

export function* assessementReports(action) {
  try {
    const { id, page, searchQuery } = action.payload;
    const response = yield call(AssessmentReportsGetApi, { id, page, searchQuery });
    yield put(assessmentCandidateReportSuccess(response.data.data));
  } catch (error) {
    // yield put(assessementDeleteFailure('error while deleting drive'));
  }
}

// watcherSaga
export default function* assessmentsCandidatesSaga() {
  yield takeLatest(
    ASSESSMENT_CANDIDATES_LIST.ASSESSMENT_CANDIDATES_LIST_REQUEST,
    assessementCandidatsList,
  );
  yield takeLatest(
    ASSESSMENT_CANDIDATE_REPORTS.ASSESSMENT_CANDIDATE_REPORT_REQUEST,
    assessementReports,
  );
}
