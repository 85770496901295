import { QUESTIONS, DELETE_QUESTION } from 'redux/admin/questions/actionContants';

const {
  QUESTIONS_REQUEST_ACTION, QUESTIONS_FAILURE_ACTION, CLEAR_QUESTIONS,
  QUESTIONS_SUCCESS_ACTION, SET_QUESTIONS_OFFSET, SET_QUESTIONS_SEARCH_TEXT,
  SET_QUESTIONS_DIFFICULTY, SET_QUESTIONS_TAG, RESET_QUESTIONS_FILTERS,
  RESET_QUESTION_SEARCH_TEXT, SET_SEARCHED_QUESTION_FILTER_ACTION,
  RESET_SEARCHED_QUESTION_FILTER_ACTION,
} = QUESTIONS;

const { DELETE_QUESTION_FAILURE_ACTION, DELETE_QUESTION_REQUEST_ACTION,
  DELETE_QUESTION_SUCCESS_ACTION } = DELETE_QUESTION;

export const questionsListRequest = (payload) => ({
  type: QUESTIONS_REQUEST_ACTION,
  payload,
});

export const questionsListSuccessAction = (payload) => ({
  type: QUESTIONS_SUCCESS_ACTION,
  payload,
});

export const questionsListFailureAction = (payload) => ({
  type: QUESTIONS_FAILURE_ACTION,
  payload,
});

export const questionsListClear = (payload) => ({
  type: CLEAR_QUESTIONS,
});

export const setQuestionsOffset = (payload) => ({
  type: SET_QUESTIONS_OFFSET,
  payload,
});

export const setQuestionsSearchText = (payload) => ({
  type: SET_QUESTIONS_SEARCH_TEXT,
  payload,
});

export const setQuestionsDifficulty = (payload) => ({
  type: SET_QUESTIONS_DIFFICULTY,
  payload,
});

export const setQuestionsTag = (payload) => ({
  type: SET_QUESTIONS_TAG,
  payload,
});

export const resetQuestionFilters = () => ({
  type: RESET_QUESTIONS_FILTERS,
});

export const deleteQuestionRequestAction = (payload) => ({
  type: DELETE_QUESTION_REQUEST_ACTION,
  payload,
});

export const deleteQuestionSuccessAction = (payload) => ({
  type: DELETE_QUESTION_SUCCESS_ACTION,
  payload,
});

export const deleteQuestionFailureAction = (payload) => ({
  type: DELETE_QUESTION_FAILURE_ACTION,
  payload,
});

export const resetQuestionSearchText = () => ({
  type: RESET_QUESTION_SEARCH_TEXT,
});

export const setSearchedQuestionFilterAction = (payload) => ({
  type: SET_SEARCHED_QUESTION_FILTER_ACTION,
  payload,
});

export const resetSearchedQuestionFilterAction = () => ({
  type: RESET_SEARCHED_QUESTION_FILTER_ACTION,
});
