import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import './imagePopUpModal.scss';

const ImagePopUpModal = ({
  showImageModal,
  toggleImageModalVisibility,
  imageUrl,
  alternateName,
}) => {
  return (
    <Modal
      className='question-modal'
      isOpen={showImageModal}
      toggle={toggleImageModalVisibility}
      size='xl'
    >
      <ModalHeader
        className='modal-header'
        toggle={toggleImageModalVisibility}
      />
      <ModalBody>
        <img
          className='image-pop-up'
          src={imageUrl}
          alt={alternateName}
        />
      </ModalBody>
    </Modal>
  );
};

ImagePopUpModal.propTypes = {
  showImageModal: PropTypes.bool.isRequired,
  toggleImageModalVisibility: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  alternateName: PropTypes.string.isRequired,
};

export default React.memo(ImagePopUpModal);
