import { call, put, takeEvery } from 'redux-saga/effects';
import { driveContactsListSuccessAction, driveContactsListFailureAction } from 'redux/admin/getDriveContacts/action';
import { getDriveContactsApi } from 'redux/admin/getDriveContacts/api';
import { DRIVE_CONTACTS } from 'redux/admin/getDriveContacts/actionConstants';

const { DRIVE_CONTACTS_REQUEST_ACTION } = DRIVE_CONTACTS;

export function* getDriveContactsList(action) {
  try {
    const response = yield call(getDriveContactsApi, action.payload);
    yield put(driveContactsListSuccessAction(response.data.data));
  } catch (error) {
    yield put(driveContactsListFailureAction(error));
  }
}

export default function* getDriveContactsSaga() {
  yield takeEvery(DRIVE_CONTACTS_REQUEST_ACTION, getDriveContactsList);
}
