import { call, put, takeEvery } from 'redux-saga/effects';
import { remainingCreditsFailureAction, remainingCreditsSuccessAction } from 'redux/admin/remainingCredits/action';
import { getCredits } from 'redux/admin/remainingCredits/api';
import { REMAINING_CREDITS } from 'redux/admin/remainingCredits/actionContants';

export function* getRemainingCredits() {
  try {
    const response = yield call(getCredits);
    yield put(remainingCreditsSuccessAction(response.data.data));
  } catch (error) {
    yield put(remainingCreditsFailureAction(error));
  }
}

export default function* remainingCreditsSaga() {
  yield takeEvery(REMAINING_CREDITS.REMAINING_CREDITS_REQUEST_ACTION, getRemainingCredits);
}
