import React from 'react';
import PropTypes from 'prop-types';
import '../DeleteConfirmModal/deleteConfirmModal.scss';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'core-components';

const UpdateConfirmModal = ({
  handleStatusUpdate,
  confirmationModal,
  toggleConfirmation,
  targetId,
  isUserActive,
  activateDeactivateUserEmail,
}) => {
  return (
    <>
      <Modal
        className='confirmation-modal'
        isOpen={confirmationModal}
        toggle={toggleConfirmation}
      >
        <ModalHeader className='modal-header'>Confirm Update</ModalHeader>{' '}
        <ModalBody className=' modal-body'>
          <p>
            {' '}
            Are you sure you want to {isUserActive
              ? 'activate'
              : 'deactivate'}{' '}
            {activateDeactivateUserEmail}?
          </p>
        </ModalBody>
        <ModalFooter className='border-0 modal-footer'>
          <Button color='secondary' outline onClick={toggleConfirmation}>
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => {
              handleStatusUpdate(targetId, isUserActive);
              toggleConfirmation();
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

UpdateConfirmModal.defaultProps = {
  targetId: 0,
};

UpdateConfirmModal.propTypes = {
  handleStatusUpdate: PropTypes.func.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  targetId: PropTypes.number,
  isUserActive: PropTypes.bool.isRequired,
  activateDeactivateUserEmail: PropTypes.string.isRequired,
};
export default React.memo(UpdateConfirmModal);
