import { Redirect, Switch, useRouteMatch } from 'react-router-dom';

import ProtectedAdminRoute from 'HOC/ProtectedAdminRoute';
import DefaultRulesContainer from 'modules/admin/defaultRules/DefaultRulesContainer';
import SearchContainer from 'modules/admin/searchModal/SearchModalContainer';
import AdminHomeConatiner from 'modules/admin/adminHome/AdminHomeContainer';
import EditDriveContainer from 'modules/admin/editDrive/EditDriveCotainer';
import CreateDriveContainer from 'modules/admin/createDrive/CreateDriveContainer';
import ShowCandidateListContainer from 'modules/admin/showCandidateList/ShowCandidateListContainer';
import AssessmentCandidateListContainer from 'modules/admin/assessmentCandidateList/AssessmentCandidateListContainer';
import AssessmentResultsContainer from 'modules/admin/assessmentResults/AssessmentResultsContainer';
import DriveResultContainer from 'modules/admin/driveResult/DriveResultContainer';
import SendEmailInviteContainer from 'modules/admin/sendEmailInvite/SendEmailInviteContainer';
import ProblemDetailsContainer from 'modules/admin/problemDetails/ProblemDetailsContainer';
import CreateProblemContainer from 'modules/admin/createProblem/CreateProblemContainer';
import EditProblemContainer from 'modules/admin/editProblem/EditProblemContainer';
import UserProfileContainer from 'modules/admin/userProfile/UserProfileContainer';
import InviteUserContainer from 'modules/admin/inviteUser/InviteUserContainer';
import SnapshotsContainer from 'modules/admin/snapshots/SnapshotsContainer';
import SupportContainer from 'modules/admin/support/SupportContainer';
import CandidateReportContainer from 'modules/admin/reports/CandidateReportContainer';
import AssessmentsListContainer from 'modules/admin/assessmentsList/AssessmentsListContainer';
import CreateAssessmentContainer from 'modules/admin/createAssessment/CreateAssessmentCotainer';
import EditAssessmentCotainer from 'modules/admin/editAssessment/EditAssessmentCotainer';
import FeedbackContainer from 'modules/admin/feedbacks/FeedbackContainer';
import QuestionContainer from 'modules/admin/question/QuestionContainer';
import CreateQuestionContainer from 'modules/admin/createQuestion/CreateQuestionContainer';
import EditQuestionContainer from 'modules/admin/editQuestion/editQuestionContainer';
import QuestionTabsContainer from 'modules/admin/questionTabs/QuestionTabsContainer';

import local from 'utils/local';
import { getUserDetails } from 'utils';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';

function AdminRoutes() {
  const { path } = useRouteMatch();
  const userDetails = getUserDetails();
  if (userDetails?.role === 'Admin') {
    return (
      <Switch>
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.HOME}
          component={AdminHomeConatiner}
          includeSidebar
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.PROBLEMS}
          component={QuestionTabsContainer}
          includeSidebar
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.RULES}
          component={DefaultRulesContainer}
          includeSidebar
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.EDIT_DRIVE}
          component={EditDriveContainer}
          includeSidebar
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.CREATE_DRIVE}
          component={CreateDriveContainer}
          includeSidebar
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.SHOW_CANDIDATES}
          component={ShowCandidateListContainer}
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.DRIVE_RESULT}
          component={DriveResultContainer}
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.INVITE_CANDIDATES}
          component={SendEmailInviteContainer}
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.PROBLEM_DETAILS}
          component={ProblemDetailsContainer}
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.EDIT_PROBLEM}
          component={EditProblemContainer}
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.CREATE_PROBLEM}
          component={CreateProblemContainer}
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.USER_PROFILE}
          component={UserProfileContainer}
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.SEARCH}
          component={SearchContainer}
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.INVITE_USER}
          component={InviteUserContainer}
          includeSidebar
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.SNAPSHOTS}
          component={SnapshotsContainer}
          includeSidebar
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.CANDIDATE_REPORT}
          component={CandidateReportContainer}
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.SUPPORT_PAGE}
          component={SupportContainer}
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.ASSESSMENTS}
          component={AssessmentsListContainer}
          includeSidebar
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.CREATE_ASSESSMENT}
          component={CreateAssessmentContainer}
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.EDIT_ASSESSMENT}
          component={EditAssessmentCotainer}
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.ASSESSMENT_CANDIDATES}
          component={AssessmentCandidateListContainer}
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.ASSESSMENT_REPORTS}
          component={AssessmentResultsContainer}
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.FEEDBACKS}
          component={FeedbackContainer}
          includeSidebar
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.QUESTIONS}
          component={QuestionContainer}
          includeSidebar
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.CREATE_QUESTION}
          component={CreateQuestionContainer}
        />
        <ProtectedAdminRoute
          exact
          path={path + ADMIN_ROUTES.EDIT_QUESTION}
          component={EditQuestionContainer}
        />
        <Redirect to={path + ADMIN_ROUTES.HOME} />
      </Switch>
    );
  }
  return (
    <Switch>
      <ProtectedAdminRoute
        exact
        path={path + ADMIN_ROUTES.CANDIDATE_REPORT}
        component={CandidateReportContainer}
      />
      <ProtectedAdminRoute
        exact
        path={path + ADMIN_ROUTES.ASSESSMENT_REPORTS}
        component={AssessmentResultsContainer}
      />
      <Redirect to={ROUTES.HOME} />
    </Switch>
  );
}

export default AdminRoutes;
