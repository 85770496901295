export const options = {
  selectOnLineNumbers: true,
  wordWrap: 'on',
  fontSize: 16,
  minimap: {
    enabled: false,
  },
  folding: true,
  autoIndent: true,
  renderLineHighlight: true,
};

export const keyValueC = 67;
export const keyValueV = 86;
