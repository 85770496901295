import { DRIVE_RESULT, SELECTED_CANDIDATE, SELECTED_CANDIDATE_EMAIL } from 'redux/admin/driveResult/actionConstants';

const { SELECTED_CANDIDATE_EMAIL_REQUEST_ACTION, SELECTED_CANDIDATE_EMAIL_SUCCESS_ACTION,
  SELECTED_CANDIDATE_EMAIL_FAILURE_ACTION } = SELECTED_CANDIDATE_EMAIL;

const { DRIVE_RESULT_REQUEST_ACTION, DRIVE_RESULT_SUCCESS_ACTION,
  DRIVE_RESULT_FAILURE_ACTION } = DRIVE_RESULT;

const { SELECTED_CANDIDATE_SUCCESS_ACTION, SELECTED_CANDIDATE_REQUEST_ACTION,
  SELECTED_CANDIDATE_FAILURE_ACTION } = SELECTED_CANDIDATE;

export const driveResultRequestAction = (payload) => ({
  type: DRIVE_RESULT_REQUEST_ACTION,
  payload,
});

export const driveResultSuccessAction = (payload) => ({
  type: DRIVE_RESULT_SUCCESS_ACTION,
  payload,
});

export const driveResultFailureAction = (payload) => ({
  type: DRIVE_RESULT_FAILURE_ACTION,
  payload,
});

export const selectedCandidateSuccessAction = (payload) => ({
  type: SELECTED_CANDIDATE_SUCCESS_ACTION,
  payload,
});

export const selectedCandidateRequestAction = (payload) => ({
  type: SELECTED_CANDIDATE_REQUEST_ACTION,
  payload,
});

export const selecteCandidateFailureAction = (payload) => ({
  type: SELECTED_CANDIDATE_FAILURE_ACTION,
  payload,
});

export const selectedCandidateEmailSuccessAction = (payload) => ({
  type: SELECTED_CANDIDATE_EMAIL_SUCCESS_ACTION,
  payload,
});

export const selectedCandidateEmailFailureAction = (payload) => ({
  type: SELECTED_CANDIDATE_EMAIL_FAILURE_ACTION,
  payload,
});

export const selectedCandidateEmailRequestAction = (payload) => ({
  type: SELECTED_CANDIDATE_EMAIL_REQUEST_ACTION,
  payload,
});
