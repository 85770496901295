import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import LandingPageComponent from 'components/LandingPageComponent';

import { driveDetailRequest } from 'actions/userDriveActions';
import { driveTimerRequest, updateDriveTimer } from 'actions/driveTimerActions';
import { getCurrentTime } from 'utils/helpers/HeaderIdeHelper';

import { ROUTES, CANDIDATE_ROUTES } from 'constants/routeConstants';

function LandingPageContainer() {
  const { id: tokenId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const userDriveState = useSelector((state) => state.userDriveReducer);
  const { isFinished } = useSelector((state) => state.userDriveReducer);
  const result = useSelector((state) => state.DriveTimerReducer);

  const { data: driveDetails } = userDriveState;

  const data = {
    data: Math.max(0, result.counter - 1),
    is_live: result.isLive,
  };

  const driveTime = getCurrentTime(result.counter);

  if (isFinished) {
    history.replace(ROUTES.CANDIDATE + CANDIDATE_ROUTES.ENDPAGE);
  }

  useEffect(() => {
    dispatch(driveTimerRequest(tokenId));
  }, []);

  useEffect(() => {
    dispatch(driveDetailRequest(tokenId));
  }, [dispatch, tokenId]);

  useEffect(() => {
    setTimeout(() => {
      if (result.counter >= 0 && result.isLive === true) {
        dispatch(updateDriveTimer(data));
      }
    }, 1000);
  }, [result.counter, result.isLive]);

  const handleClick = useCallback(() => {
    history.replace(ROUTES.CANDIDATE + CANDIDATE_ROUTES.RULES_AND_PROFILE);
  }, [history]);

  return (
    <LandingPageComponent
      startTime={driveDetails.startTime}
      endTime={driveDetails.endTime}
      name={driveDetails.name}
      isError={userDriveState.isError}
      errorMessage={userDriveState.errorMessage}
      isLoading={userDriveState.isLoading}
      handleClick={handleClick}
      counter={result.counter}
      isLive={result.isLive}
      driveTime={driveTime}
    />
  );
}

export default React.memo(LandingPageContainer);
