import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import queryStringHelper from 'utils/queryStringHelper';

import {
  downloadMcqSuccessAction,
  downloadMcqFailureAction,
} from './action';
import { downloadMcqApi } from './api';

import { DOWNLOAD_MCQ } from './actionConstants';

const {
  DOWNLOAD_MCQ_REQUEST_ACTION,
} = DOWNLOAD_MCQ;

// worker saga
export function* downloadMcqCsvSaga(action) {
  const { questionSearchText, questionDifficulty, questionTag } = action.payload;
  const getQueryString = queryStringHelper({
    search: questionSearchText,
    difficulty_list: questionDifficulty
      ? questionDifficulty.value : '',
    tag_list: questionTag
      ? questionTag.map((tag) => (tag.value))?.toString() : '',
  });

  try {
    const response = yield call(downloadMcqApi, getQueryString);
    yield put(downloadMcqSuccessAction(response.data));
  } catch (error) {
    toast.error(error.response.data.message);
    yield put(downloadMcqFailureAction(error.response.data.message));
  }
}

// watcherSaga
export default function* downloadMcqSaga() {
  yield takeLatest(
    DOWNLOAD_MCQ_REQUEST_ACTION,
    downloadMcqCsvSaga,
  );
}
