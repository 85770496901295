import React from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
} from 'core-components';
import './editorNavStyle.css';
import './editorNavComponent.scss';
import technologyIcon from '../../assets/images/ruby-icon.png';

function EditorNavComponent({
  isDropDownOpen,
  handleToggle,
  languageSelected,
  languages,
  handleClick,
}) {
  return (
    <>
      <Dropdown
        isOpen={isDropDownOpen}
        toggle={handleToggle}
        className='technology-dropdown'
      >
        <DropdownToggle
          className='dropdown-toggle border-0 text-decoration-none p-0 d-flex align-items-center justify-content-center'
          color='link'
        >
          {/* <img
            src={technologyIcon}
            alt='icon'
            className='mr-1 technology-icon'
          /> */}
          <Icon name={languageSelected.id} className='mr-1 technology-icon' />
          <span className='text-truncate'>{languageSelected.name}</span>
          <Icon name='arrow-down' />
        </DropdownToggle>
        <DropdownMenu className='dropdown-menu m-0 p-0' right>
          {languages.map(({ id, name, code }) => (
            <DropdownItem
              id={id}
              code={code}
              key={id}
              onClick={handleClick}
              className='p-2'
            >
              <Icon name={id} className='mr-2 technology-icon' />
              {name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
}

EditorNavComponent.propTypes = {
  isDropDownOpen: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  languageSelected: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
  }).isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default React.memo(EditorNavComponent);
