import React from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Icon,
  Col,
  Row,
} from 'core-components';

import { stringValidation } from 'utils';

import 'modules/admin/createDrive/CreateDriveComponent/createDriveComponent.scss';

const OrganizationEditComponent = (props) => {
  const {
    handleOrganizationNameChange,
    handleOrganizationDescriptionChange,
    onEditOrganizationSubmit,
    isSuccess,
    nameErrTxt,
    descriptionErrTxt,
    action,
    handleContactsChange,
    finishOrganizationEdit,
    contactsErrTxt,
    isLoading,
    handleCreditsChange,
    creditsErrorMsg,
    allowCoding,
    allowMcq,
    isParam,
    handleIsParamChange,
    handleAllowCoding,
    handleAllowMcq,
    defaultOrganization,
    ownerName,
    email,
    contactNumber,
  } = props;

  if (isLoading) {
    return <Spinner className='loader' />;
  }
  return (
    <>
      <div className='d-flex'>
        <div className='new-drive-section d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <Icon
              name='left'
              className='back-page-arrow font-weight-bold'
              onClick={finishOrganizationEdit}
            />
            <h2 className='section-heading font-weight-bold mb-0 ml-3'>
              {action} Organization
            </h2>
          </div>
          <Form className='new-drive-form d-flex flex-row overflow-hidden w-100'>
            <div className='new-drive-wrapper d-flex overflow-hidden w-50'>
              <div className='new-drive-col scroll-y d-flex flex-column'>
                <div className='px-3'>
                  <FormGroup>
                    <Row>
                      <Col>
                        <Label className='px-3'>Owner Name</Label>
                        <Col>
                          <div>{stringValidation(ownerName)}</div>
                        </Col>
                      </Col>
                      <Col>
                        <Label className='px-3'>Email</Label>
                        <Col>
                          <div>{stringValidation(email)}</div>
                        </Col>
                      </Col>
                      <Col>
                        <Label className='px-3'>Contact Number</Label>
                        <Col>
                          <div>{contactNumber || 'N/A'}</div>
                        </Col>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Label className='px-3'>Organization Name</Label>
                    <Input
                      type='text'
                      placeholder='Enter Organization name'
                      onChange={handleOrganizationNameChange}
                      value={defaultOrganization.data.Organization.name}
                      invalid={nameErrTxt !== ''}
                    />
                    <FormFeedback>{nameErrTxt}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label className='px-3'>Organization Description</Label>
                    <Input
                      type='textarea'
                      placeholder='Enter Organization description'
                      onChange={handleOrganizationDescriptionChange}
                      value={defaultOrganization.data.Organization.description}
                      invalid={descriptionErrTxt !== ''}
                    />
                    <FormFeedback>{descriptionErrTxt}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label className='px-3'>Contacts</Label>
                    <Input
                      type='textfield'
                      placeholder='Enter contact number'
                      onChange={handleContactsChange}
                      value={defaultOrganization.data.Organization.contacts}
                      invalid={contactsErrTxt !== ''}
                    />
                    <FormFeedback>{contactsErrTxt}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label className='px-3'>Credits</Label>
                    <Input
                      type='number'
                      placeholder='Enter Credits'
                      value={defaultOrganization.data.Organization.credits}
                      onChange={handleCreditsChange}
                      invalid={creditsErrorMsg !== ''}
                      required
                      defaultValue={0}
                      className='select-input-type w-50 mt-3'
                    />
                    <FormFeedback>{creditsErrorMsg}</FormFeedback>
                  </FormGroup>
                  <Button
                    color='primary'
                    onClick={onEditOrganizationSubmit}
                    className='py-2 px-4 ml-auto'
                  >
                    {action} Organization
                  </Button>
                </div>
              </div>
            </div>
            <div className='new-drive-wrapper d-flex overflow-hidden flex-column w-50'>
              <FormGroup>
                <table className='table edit-table'>
                  <tbody>
                    <tr>
                      <td>
                        <Label className='px-3 d-column'>Allow Coding</Label>
                      </td>
                      <td>
                        <div className='flex-column w-25 mr-3'>
                          <Input
                            type='checkbox'
                            name='check'
                            id='exampleCheck'
                            checked={allowCoding}
                            className='ml-3'
                            onChange={handleAllowCoding}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Label className='px-3 d-column'>Allow MCQ</Label>
                      </td>
                      <td>
                        <div className='flex-column w-25 mr-3'>
                          <Input
                            type='checkbox'
                            name='check'
                            id='exampleCheck'
                            checked={allowMcq}
                            className='ml-3'
                            onChange={handleAllowMcq}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Label className='px-3 d-column mb-0'>
                          Param Assessment
                        </Label>
                      </td>
                      <td>
                        <div className='position-relative flex-column w-25 mr-3'>
                          <Input
                            type='checkbox'
                            name='check'
                            id='exampleCheck'
                            checked={isParam}
                            className='ml-3'
                            onChange={handleIsParamChange}
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </FormGroup>
            </div>
            {isSuccess && finishOrganizationEdit}
          </Form>
        </div>
      </div>
    </>
  );
};

OrganizationEditComponent.defaultProps = {
  isLoading: false,
  allowCoding: false,
  allowMcq: false,
};

OrganizationEditComponent.propTypes = {
  handleOrganizationNameChange: PropTypes.func.isRequired,
  handleOrganizationDescriptionChange: PropTypes.func.isRequired,
  handleContactsChange: PropTypes.func.isRequired,
  nameErrTxt: PropTypes.string.isRequired,
  descriptionErrTxt: PropTypes.string.isRequired,
  contactsErrTxt: PropTypes.string.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  allowCoding: PropTypes.bool,
  allowMcq: PropTypes.bool,
  isParam: PropTypes.bool.isRequired,
  handleIsParamChange: PropTypes.func.isRequired,
  handleAllowCoding: PropTypes.func.isRequired,
  handleAllowMcq: PropTypes.func.isRequired,
  onEditOrganizationSubmit: PropTypes.func.isRequired,
  finishOrganizationEdit: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  handleCreditsChange: PropTypes.func.isRequired,
  creditsErrorMsg: PropTypes.string.isRequired,
  ownerName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  contactNumber: PropTypes.number.isRequired,
  defaultOrganization: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ])).isRequired,
};
export default React.memo(OrganizationEditComponent);
