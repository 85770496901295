import produce from 'immer';
import { initialState } from 'reducers/userDriveReducer';

export const reducer = produce((state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'name':
      state.data.Organization.name = payload;
      state.nameErrTxt = '';
      break;
    case 'description':
      state.data.Organization.description = payload;
      state.descriptionErrTxt = '';
      break;
    case 'contacts':
      state.data.Organization.contacts = payload;
      state.contactsErrTxt = '';
      break;
    case 'credits':
      state.data.Organization.credits = payload;
      state.creditsErrorMsg = '';
      break;
    case 'resetOrganizationData':
      state.data.Organization.name = '';
      state.data.Organization.description = '';
      state.data.Organization.contacts = '';
      state.data.Organization.credits = 0;
      state.nameErrTxt = '';
      state.descriptionErrTxt = '';
      state.contactsErrTxt = '';
      state.creditsErrorMsg = '';
      break;
    case 'Organization':
      state.data.Organization.name = payload.show_name;
      state.data.Organization.description = payload.description;
      state.data.Organization.contacts = payload.contact_number;
      state.data.Organization.credits = payload.credits;
      state.data.Organization.isParamAssessment = payload.is_param_ai_allowed;
      state.data.Organization.isAllowCoding = payload.allow_coding;
      state.data.Organization.isallowMcq = payload.allow_mcq;
      break;
    case 'nameErrTxt':
      state.nameErrTxt = payload;
      break;
    case 'descriptionErrTxt':
      state.descriptionErrTxt = payload;
      break;
    case 'contactsErrTxt':
      state.contactsErrTxt = payload;
      break;
    case 'creditsErrorMsg':
      state.creditsErrorMsg = payload;
      break;
    case 'clear':
      return { ...initialState };
    default:
      return state;
  }
  return state;
});
