import * as yup from 'yup';

export const schema = yup.object().shape({
  input: yup.string().required(),
  output: yup.string().required(),
  marks: yup
    .number()
    .typeError('Marks is required field')
    .required()
    .min(1, 'Marks must be greater than 0')
    .integer(),
});
