import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import CreateDriveComponent from 'modules/admin/createDrive/CreateDriveComponent';

import {
  questionsRequestAction, questionsClear,
} from 'redux/admin/questionsList/action';
import {
  problemsClear, problemsRequestAction,
} from 'redux/admin/problems/action';
import {
  contactsListRequestActioon, contactsListClear,
} from 'redux/admin/getContacts/action';
import {
  createDriveRequestAction,
  createDriveClearState,
  ruleDriveCopyIdAction,
} from 'redux/admin/createDrive/action';
import { tagsRequestAction } from 'redux/admin/tags/action';
import { isCopyDriveAction } from 'redux/admin/getEditDrive/action';
import { reducer } from 'modules/admin/createDrive/CreateDriveContainer/reducer';
import { validateData } from 'modules/admin/createDrive/dataValidation';

import arrayToKeyValueHash from 'utils/arrayToKeyValueHash';
import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';
import { getFullNameWithContact, getUserDetails } from 'utils';
import { getDateFormat } from 'utils/date';
import { problemMCQErrorMessage } from 'constants/messageConstants';
import { defaultDriveStartDate, defaultDriveEndDate, defaultCopyDriveEndDate, defaultCopyDriveStartDate } from './utils';

import { DRIVE_SECTION_CONSTANTS } from './constants';

const { DRIVE_DETAILS_SECTION, ADD_QUESTIONS_SECTION, ADD_RULES_SECTION } =
  DRIVE_SECTION_CONSTANTS;

// TODO: Initial value should be same for both edit and update drive.
export const initialState = {
  data: {
    drive: {
      id: '',
      name: '',
      description: '',
      start_time: defaultDriveStartDate(),
      end_time: defaultDriveEndDate(),
      created_by_id: '',
      updated_by_id: '',
      organization_id: '',
      is_assessment: false,
    },
  },
  nameErrTxt: '',
  descriptionErrTxt: '',
  startTimeErrTxt: '',
  endTimeErrTxt: '',
  problemErrTxt: '',
  message: '',
  currentProblems: [],
  contacts: [],
  questionErrTxt: '',
  currentQuestions: [],
  problemLoading: true,
};

const CreateDriveContainer = () => {
  const { data, message, isSuccess } = useSelector(
    (state) => state.createDriveReducer,
  );

  const { editDriveData, isCopyDrive } = useSelector(
    (state) => state.getEditDriveReducer,
  );
  const { isCopyFromSearch } = useSelector((state) => state.modalStatusReducer);
  const [createDrive, setCreateDrive] = useReducer(reducer, initialState);
  const [selectedProblems, setSelectedProblems] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedQuestionsDetails, setSelectedQuestionsDetails] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filterTags, setFilterTags] = useState([]);
  const [filterDifficulty, setFilterDifficulty] = useState('');
  const [showQuestionFilters, setShowQuestionFilters] = useState(false);
  const handleFilterQuestionBox = () => {
    setShowQuestionFilters(!showQuestionFilters);
  };
  const [selectedProbDetails, setSelectedProbDetails] = useState([]);
  const [searchProbText, setSearchProbText] = useState('');
  const [filterProbTags, setFilterProbTags] = useState([]);
  const [filterProbDifficulty, setFilterProbDifficulty] = useState('');
  const [showProbFilters, setShowProbFilters] = useState(false);
  const handleFilterProbBox = () => {
    setShowProbFilters(!showProbFilters);
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const { questions, isQuestionsLoading } = useSelector(
    (item) => item.questionsReducer,
  );
  const {
    problems,
    isProblemsLoading,
    offset: currentPageProblems,
  } = useSelector((item) => item.getProblemsReducer);
  const {
    contactsList,
    isContactsLoading,
  } = useSelector((item) => item.getContactsReducer);
  const profileDetails = getUserDetails();
  const allowCoding = profileDetails.allow_coding;
  const allowMcq = profileDetails.allow_mcq;
  const { difficultyList, tagList } = useSelector((state) => state.tagsReducer);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [difficultyOptions, setDifficultyOptions] = useState([]);
  const [defaultContacts, setDefaultContacts] = useState([]);

  const [section, setSection] = useState(DRIVE_DETAILS_SECTION);
  const [startTimeValue, setstartTimeValue] = useState(() => defaultDriveStartDate());
  const [endTimeValue, setEndTimeValue] = useState(() => defaultDriveEndDate());

  const schemaDriveDetails = yup.object().shape({
    name: yup
      .string()
      .min(5, 'Title must be at least 5 characters')
      .max(49, 'Title must be at most 49 characters')
      .required('Title is a required field'),
    description: yup.string().min(10).max(349).required(),
    startTime: yup.date().required('Start date is required field').min(
      new Date(), 'Start date cannot be the past date and time',
    ),
    endTime: yup.date().required('End date is required field').min(
      yup.ref('startTime'),
      'End date cannot be before start date and time',
    ),
    drivesContactsAttributes: yup
      .array()
      .min(1, 'Please select/add contact(s)'),
  });

  const schemaAddQuestions = yup.object().shape({
    problemsCount: yup
      .number()
      .min(1, problemMCQErrorMessage),
  });

  useEffect(() => {
    if ((isCopyDrive || isCopyFromSearch) && editDriveData) {
      const {
        id: driveId,
        name,
        description,
        drives_problems: drivesProblems,
        drives_contacts: drivesContacts,
        drives_questions: drivesQuestions,
        admin_users: adminUsers,
      } = editDriveData?.drive;

      // set drive Id for copy rules
      dispatch(ruleDriveCopyIdAction(driveId));

      // set drive Problems
      const currDriveProblems = drivesProblems.map((problem) => problem.problem_id);
      setSelectedProblems(currDriveProblems);

      const currDrivesContacts = [];
      // get all active user and set into currDrivesContacts
      const activeUser = adminUsers.filter((user) => user.is_active);
      // set drive contacts
      if (activeUser.length !== 0) {
        activeUser.map((user) => currDrivesContacts.push(user.id));
      }
      // set default contacts for drive
      const contacts = activeUser?.map(({
        id,
        first_name: firstName,
        last_name: lastName,
        mobile_number: mobileNumber,
        is_active: isActive,
      }) => ({
        value: id,
        label: getFullNameWithContact(firstName, lastName, mobileNumber),
        status: isActive,
      }));
      setDefaultContacts([...contacts]);

      // set drive Questions
      const currDrivesQuestions = [];
      const drivesQuestionsDetails = [];
      drivesQuestions.forEach((element) => {
        currDrivesQuestions.push(element.question_id);
        const question = questions.find((q) => q.id === element.question_id);
        if (question) {
          drivesQuestionsDetails.push(question);
        }
      });
      setSelectedQuestions(currDrivesQuestions);
      setSelectedQuestionsDetails(drivesQuestionsDetails);

      const startTime = defaultCopyDriveStartDate();
      const endTime = defaultCopyDriveEndDate();

      setstartTimeValue(startTime);
      setEndTimeValue(endTime);

      setCreateDrive({
        type: 'drive',
        payload: {
          name: `${name} (copy)`,
          description,
          start_time: startTime,
          end_time: endTime,
          problem: currDriveProblems,
          contacts: currDrivesContacts,
          questions: currDrivesQuestions,
        } });
    }
  }, [isCopyDrive, editDriveData, questions]);

  useEffect(() => {
    if (!isContactsLoading) {
      dispatch(
        contactsListRequestActioon({
          contactsList,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (!isProblemsLoading && allowCoding) {
      dispatch(
        problemsRequestAction({
          offset: currentPageProblems,
          problems,
        }),
      );
    }
    dispatch(tagsRequestAction());
  }, []);

  useEffect(() => {
    setDifficultyOptions(arrayToKeyValueHash(difficultyList));
  }, [difficultyList]);

  useEffect(() => {
    setTagsOptions(arrayToKeyValueHash(tagList));
  }, [tagList]);

  useEffect(() => {
    if (!isQuestionsLoading && allowMcq) {
      dispatch(questionsRequestAction({}));
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setSection(ADD_RULES_SECTION);
    } else {
      setSection(DRIVE_DETAILS_SECTION);
    }
  }, [isSuccess]);

  useEffect(() => {
    return () => {
      dispatch(createDriveClearState());
      setCreateDrive({ type: 'clear' });
      dispatch(questionsClear());
      dispatch(problemsClear());
      dispatch(contactsListClear());
      dispatch(isCopyDriveAction(false));
    };
  }, []);

  const searchButtonClick = () => {
    const tags = [];
    for (let i = 0; i < filterTags.length; i += 1) {
      tags.push(filterTags[i].value);
    }
    if (!isQuestionsLoading && (searchText || tags.length !== 0 || filterDifficulty)) {
      dispatch(
        questionsRequestAction({
          searchText: searchText.trim(),
          filterDifficulty,
          filterTags: tags,
        }),
      );
    }
  };

  const resetButtonClick = () => {
    if (searchText || filterTags.length !== 0 || filterDifficulty) {
      setSearchText('');
      setFilterTags([]);
      setFilterDifficulty('');
      if (!isQuestionsLoading) {
        dispatch(questionsRequestAction({}));
      }
    } else {
      handleFilterQuestionBox();
    }
  };

  const searchProbButtonClick = () => {
    const tags = [];
    for (let i = 0; i < filterProbTags.length; i += 1) {
      tags.push(filterProbTags[i].value);
    }
    if (!isProblemsLoading  && (searchProbText || tags.length !== 0 || filterProbDifficulty)) {
      dispatch(
        problemsRequestAction({
          searchProbText,
          filterProbDifficulty,
          filterProbTags: tags,
        }),
      );
    }
  };

  const resetProbButtonClick = () => {
    if (searchProbText || filterProbTags.length !== 0 || filterProbDifficulty) {
      setSearchProbText('');
      setFilterProbTags([]);
      setFilterProbDifficulty('');
      if (!isProblemsLoading) {
        dispatch(problemsRequestAction({}));
      }
    } else {
      handleFilterProbBox();
    }
  };

  const handleSelectedProblemChange = useCallback(
    (event) => {
      if (event.target.checked) {
        setCreateDrive({
          type: 'problemErrTxt',
          payload: '',
        });
        setCreateDrive({
          type: 'problem',
          payload: [
            ...createDrive.currentProblems,
            ...[parseInt(event.target.value, 10)],
          ],
        });
        setSelectedProblems([
          ...selectedProblems,
          ...[parseInt(event.target.value, 10)],
        ]);
      } else {
        const refactoredArray = createDrive.currentProblems.filter(
          (item) => item !== parseInt(event.target.value, 10),
        );
        setCreateDrive({
          type: 'problem',
          payload: [...refactoredArray],
        });
        setSelectedProblems(refactoredArray);
      }
    },
    [createDrive.currentProblems],
  );

  const handleDriveNameChange = useCallback(
    (event) => {
      const name = event.target.value;
      setCreateDrive({
        type: 'nameErrTxt',
        payload: '',
      });
      setCreateDrive({
        type: 'name',
        payload: name,
      });
    },
    [createDrive.data.drive.name],
  );

  const handleDriveDescriptionChange = useCallback(
    (event) => {
      const description = event.target.value;
      setCreateDrive({
        type: 'descriptionErrTxt',
        payload: '',
      });
      setCreateDrive({
        type: 'description',
        payload: description,
      });
    },
    [createDrive.data.drive.description],
  );

  const handleDriveStartChange = useCallback(
    (startDateTime) => {
      if (!startDateTime) {
        setstartTimeValue(defaultDriveStartDate());
        setCreateDrive({
          type: 'start_time',
          payload: defaultDriveStartDate().toString(),
        });
      } else {
        setstartTimeValue(startDateTime);
        setCreateDrive({
          type: 'start_time',
          payload: startDateTime.toString(),
        });
      }
      setCreateDrive({
        type: 'startTimeErrTxt',
        payload: '',
      });
      setCreateDrive({
        type: 'endTimeErrTxt',
        payload: '',
      });
    },
    [createDrive.data.drive.start_time],
  );

  const handleDriveEndChange = useCallback(
    (endDateTime) => {
      if (!endDateTime) {
        setEndTimeValue(defaultDriveEndDate());
        setCreateDrive({
          type: 'end_time',
          payload: defaultDriveEndDate().toString(),
        });
      } else {
        setEndTimeValue(endDateTime);
        setCreateDrive({
          type: 'end_time',
          payload: endDateTime.toString(),
        });
      }
      setCreateDrive({
        type: 'endTimeErrTxt',
        payload: '',
      });
      setCreateDrive({
        type: 'startTimeErrTxt',
        payload: '',
      });
    },
    [createDrive.data.drive.end_time],
  );

  const handleIsAssessmentChange = useCallback(
    (assessmentStatus) => {
      const isAssessment = assessmentStatus;
      setCreateDrive({
        type: 'is_assessment',
        payload: isAssessment,
      });
    },
    [createDrive.data.drive.is_assessment],
  );

  const handleSelectedContactsChange = useCallback(
    (event) => {
      setCreateDrive({
        type: 'contactsErrTxt',
        payload: '',
      });
      setCreateDrive({
        type: 'resetCurrentContacts',
      });
      for (let i = 0; i < event.length; i += 1) {
        setCreateDrive({
          type: 'contacts',
          payload: [...createDrive.contacts, ...[parseInt(event[i].value, 10)]],
        });
      }
      setDefaultContacts(event);
    },
    [createDrive.contacts],
  );

  useEffect(() => {
    const currQuestions = [];
    selectedQuestionsDetails.forEach((q) => {
      if (selectedQuestions.includes(q.id)) {
        currQuestions.push(q);
      }
    });
    selectedQuestions.forEach((q) => {
      if (!selectedQuestionsDetails.some((sq) => sq.id === q)) {
        const question = questions.find((e) => e.id === q);
        if (question) {
          currQuestions.push(question);
        }
      }
    });
    setSelectedQuestionsDetails(currQuestions);
  }, [selectedQuestions]);

  const handleSelectedQuestionChange = useCallback(
    (event) => {
      if (event.target.checked) {
        setCreateDrive({
          type: 'problemErrTxt',
          payload: '',
        });
        setCreateDrive({
          type: 'question',
          payload: [
            ...createDrive.currentQuestions,
            ...[parseInt(event.target.value, 10)],
          ],
        });
        setSelectedQuestions([
          ...selectedQuestions,
          ...[parseInt(event.target.value, 10)],
        ]);
      } else {
        const refactoredArray = createDrive.currentQuestions.filter(
          (item) => item !== parseInt(event.target.value, 10),
        );
        setCreateDrive({
          type: 'question',
          payload: [...refactoredArray],
        });
        setSelectedQuestions(refactoredArray);
      }
    },
    [createDrive.currentQuestions],
  );

  const handleSearchInputChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);
  const handleFilterTagsChange = useCallback((event) => {
    setFilterTags(event);
  }, []);
  const handleFilterDifficultyChange = useCallback((event) => {
    setFilterDifficulty(event.value);
  }, []);

  useEffect(() => {
    const currProblems = [];
    selectedProbDetails.forEach((q) => {
      if (selectedProblems.includes(q.id)) {
        currProblems.push(q);
      }
    });
    selectedProblems.forEach((q) => {
      if (!selectedProbDetails.some((sq) => sq.id === q)) {
        const problem = problems.find((e) => e.id === q);
        if (problem) {
          currProblems.push(problem);
        }
      }
    });
    setSelectedProbDetails(currProblems);
  }, [selectedProblems, problems]);

  const handleSearchProbInputChange = useCallback((event) => {
    setSearchProbText(event.target.value);
  }, []);
  const handleFilterProbTagsChange = useCallback((event) => {
    setFilterProbTags(event);
  }, []);
  const handleFilterProbDifficultyChange = useCallback((event) => {
    setFilterProbDifficulty(event.value);
  }, []);

  const onDriveDetailsNext = () => {
    const {
      data: {
        drive: { name, description, start_time: startTime, end_time: endTime,
          is_assessment: isAssessment },
      },
      contacts,
    } = createDrive;

    const drivesContactsAttributes = [];
    for (let i = 0; i < contacts.length; i += 1) {
      drivesContactsAttributes.push({
        user_id: contacts[i],
        _destroy: false,
      });
    }

    const validatePostData = {
      name : name.trim(),
      description : description.trim(),
      startTime,
      endTime,
      isAssessment,
      drivesContactsAttributes,
    };

    schemaDriveDetails.isValid(validatePostData).then(async (valid) => {
      if (!valid) {
        validateData(schemaDriveDetails, validatePostData, setCreateDrive);
      } else if (getDateFormat(startTime) === getDateFormat(endTime)) {
        setCreateDrive({
          type: 'endTimeErrTxt',
          payload: 'End date cannot be equal to start date and time',
        });
      } else {
        setSection(ADD_QUESTIONS_SECTION);
      }
    });
  };

  const onAddQuestionsBack = () => {
    setSection(DRIVE_DETAILS_SECTION);
  };

  const onCreateDriveSubmit = () => {
    const {
      data: {
        drive: { id, name, description, start_time, end_time, is_assessment },
      },
      contacts,
      currentProblems,
      currentQuestions,
    } = createDrive;

    const drivesContactsAttributes = [];
    for (let i = 0; i < contacts.length; i += 1) {
      drivesContactsAttributes.push({
        user_id: contacts[i],
        _destroy: false,
      });
    }

    const drivesProblemsAttributes = [];
    for (let i = 0; i < currentProblems.length; i += 1) {
      drivesProblemsAttributes.push({
        problem_id: currentProblems[i],
        _destroy: false,
      });
    }

    const drivesQuestionsAttributes = [];
    for (let i = 0; i < currentQuestions.length; i += 1) {
      drivesQuestionsAttributes.push({
        question_id: currentQuestions[i],
        _destroy: false,
      });
    }

    const validatePostData = {
      problemsCount:
      drivesProblemsAttributes?.length +
      drivesQuestionsAttributes?.length,
    };

    const postData = {
      name: name.trim(),
      description: description.trim(),
      start_time,
      end_time,
      is_assessment,
      drives_contacts_attributes: drivesContactsAttributes,
      drives_problems_attributes: drivesProblemsAttributes,
      drives_questions_attributes: drivesQuestionsAttributes,
    };

    schemaAddQuestions.isValid(validatePostData).then(async (valid) => {
      if (!valid) {
        validateData(schemaAddQuestions, validatePostData, setCreateDrive);
      } else {
        dispatch(createDriveRequestAction({ postData }));
      }
    });
  };

  const onAddNewRulesBack = () => {
    if (data.drive.id) {
      history.push(`/admin/drive/${data.drive.id}/edit`);
    }
    setSection(ADD_QUESTIONS_SECTION);
  };

  const finishDriveCreation = useCallback(() => {
    history.push(ROUTES.ADMIN + ADMIN_ROUTES.HOME);
  });

  return (
    <CreateDriveComponent
      handleDriveDescriptionChange={handleDriveDescriptionChange}
      handleDriveEndChange={handleDriveEndChange}
      handleDriveNameChange={handleDriveNameChange}
      handleDriveStartChange={handleDriveStartChange}
      problemIsLoading={isProblemsLoading}
      handleSelectedProblemChange={handleSelectedProblemChange}
      data={problems}
      onDriveDetailsNext={onDriveDetailsNext}
      onAddQuestionsBack={onAddQuestionsBack}
      onCreateDriveSubmit={onCreateDriveSubmit}
      onAddNewRulesBack={onAddNewRulesBack}
      message={message}
      isSuccess={isSuccess}
      nameErrTxt={createDrive.nameErrTxt}
      descriptionErrTxt={createDrive.descriptionErrTxt}
      startTimeErrTxt={createDrive.startTimeErrTxt}
      endTimeErrTxt={createDrive.endTimeErrTxt}
      problemErrTxt={createDrive.problemErrTxt}
      createDrive={createDrive}
      handleIsAssessmentChange={handleIsAssessmentChange}
      finishDriveCreation={finishDriveCreation}
      selectedProblems={selectedProblems}
      contactsData={contactsList}
      handleSelectedContactsChange={handleSelectedContactsChange}
      contactsErrTxt={createDrive.contactsErrTxt}
      questions={questions}
      isQuestionsLoading={isQuestionsLoading}
      handleSelectedQuestionChange={handleSelectedQuestionChange}
      selectedQuestions={selectedQuestions}
      isContactsLoading={isContactsLoading}
      handleSearchInputChange={handleSearchInputChange}
      handleFilterTagsChange={handleFilterTagsChange}
      handleFilterDifficultyChange={handleFilterDifficultyChange}
      searchButtonClick={searchButtonClick}
      resetButtonClick={resetButtonClick}
      searchText={searchText}
      filterTags={filterTags}
      filterDifficulty={filterDifficulty}
      handleFilterQuestionBox={handleFilterQuestionBox}
      showQuestionFilters={showQuestionFilters}
      selectedQuestionsDetails={selectedQuestionsDetails}
      handleSearchProbInputChange={handleSearchProbInputChange}
      handleFilterProbTagsChange={handleFilterProbTagsChange}
      handleFilterProbDifficultyChange={handleFilterProbDifficultyChange}
      searchProbButtonClick={searchProbButtonClick}
      resetProbButtonClick={resetProbButtonClick}
      searchProbText={searchProbText}
      filterProbTags={filterProbTags}
      filterProbDifficulty={filterProbDifficulty}
      handleFilterProbBox={handleFilterProbBox}
      showProbFilters={showProbFilters}
      selectedProbDetails={selectedProbDetails}
      allowCoding={allowCoding}
      allowMcq={allowMcq}
      tagsOptions={tagsOptions}
      difficultyOptions={difficultyOptions}
      section={section}
      startTimeValue={startTimeValue}
      endTimeValue={endTimeValue}
      defaultContacts={defaultContacts}
      isCopyDrive={isCopyDrive}
    />
  );
};

export default React.memo(CreateDriveContainer);
