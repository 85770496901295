import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Button, Alert, Container } from 'core-components';
import { DATE_TIME_FORMAT } from 'constants/appConstants';
import siteLogo from 'assets/images/spark-code-logo.png';
import './landingPage.scss';

function LandingPageComponent(props) {
  const {
    startTime,
    endTime,
    isError,
    errorMessage,
    isLoading,
    handleClick,
    counter,
    isLive,
    driveTime,
  } = props;
  const expired = 'Expired';
  if (isLoading) {
    return (
      <div className='overview-block text-center text-white'>
        <Alert color='success'> Loading</Alert>
      </div>
    );
  }

  return (
    <Container fluid className='dark-l'>
      <div className='custom-padding text-center text-white'>
        <h3>Welcome to</h3>
        <img
          className='p-2'
          src={siteLogo}
          alt='Spark Code Logo'
        />
        {!isError && (isLive ? (
          <div>
            <h4 className='my-5'>
              {`Your test starts on ${moment(startTime).format(DATE_TIME_FORMAT)}`}
            </h4>
            <h3 className='text-success'>
              {driveTime === expired ? null : driveTime}
            </h3>
            {driveTime === expired && (
              <Button
                className='px-5 custom-color btn-style'
                size='lg'
                onClick={handleClick}
              >
                Continue
              </Button>
            )}
          </div>
        ) : (
          <h1 className='my-5'> Test Over </h1>
        ))}
        {isError && (
          <h1 className='my-5'> {errorMessage} ! </h1>
        )}
      </div>
    </Container>
  );
}

LandingPageComponent.defaultProps = {
  isLive: false,
};

LandingPageComponent.propTypes = {
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLive: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  counter: PropTypes.number.isRequired,
  driveTime: PropTypes.string.isRequired,
};

export default React.memo(LandingPageComponent);
