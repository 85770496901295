/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes, { shape, string } from 'prop-types';
import Select from 'react-select';

import {
  Input,
  Button,
} from 'core-components';

import { customStyle } from 'constants/stylesConstants';
import { CustomThemeMultiSelect, CustomThemeSelect } from 'constants/themesConstants';

import './createDriveComponent.scss';

const FilterComponent = (props) => {
  const {
    handleSearchInputChange,
    handleFilterTagsChange,
    handleFilterDifficultyChange,
    searchButtonClick,
    resetButtonClick,
    searchText,
    filterTags,
    filterDifficulty,
    tagsOptions,
    difficultyOptions,
    searchPlaceholder,
  } = props;

  return (
    <div className='mb-1 filter-box-style px-2 py-2'>
      <Input
        type='text'
        placeholder={`Search ${searchPlaceholder}`}
        onChange={handleSearchInputChange}
        value={searchText}
      />
      <Select
        className='text-light mt-1 react-select-container'
        classNamePrefix='react-select'
        id='tags'
        value={filterTags}
        onChange={handleFilterTagsChange}
        options={tagsOptions}
        placeholder='Select tags'
        theme={CustomThemeMultiSelect}
        isMulti
        styles={customStyle}
      />
      <Select
        className='text-light mt-1 react-select-container'
        classNamePrefix='react-select'
        id='difficulty'
        value={filterDifficulty ? { value: filterDifficulty, label: filterDifficulty } : ''}
        onChange={handleFilterDifficultyChange}
        options={difficultyOptions}
        placeholder='Select Difficulty'
        theme={CustomThemeSelect}
        styles={customStyle}
      />
      <div className='d-flex mt-1'>
        <div className='ml-auto'>
          <Button
            className='mr-1'
            color='secondary'
            outline
            onClick={searchButtonClick}
          >
            Filter
          </Button>
          <Button
            onClick={resetButtonClick}
            color='primary'
            outline
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
};

FilterComponent.defaultProps = {
  searchPlaceholder: '',
};

FilterComponent.propTypes = {
  handleSearchInputChange: PropTypes.func.isRequired,
  handleFilterTagsChange: PropTypes.func.isRequired,
  handleFilterDifficultyChange: PropTypes.func.isRequired,
  searchButtonClick: PropTypes.func.isRequired,
  resetButtonClick: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  filterTags: PropTypes.objectOf(PropTypes.object).isRequired,
  filterDifficulty: PropTypes.string.isRequired,
  tagsOptions: PropTypes.arrayOf(string).isRequired,
  difficultyOptions: PropTypes.arrayOf(shape).isRequired,
  searchPlaceholder: PropTypes.string,
};

export default React.memo(FilterComponent);
