import { MCQ_BULK_UPLOAD_ACTIONS } from './actionConstants';

export const initialState = {
  isLoading: false,
  fileName: '',
  fileChoosen: {},
  isRefetchQuestionList: false,
  filePath: '',
  showError: false,
  isImporting: false,
  fileNameError: '',
};
const fileSaver = require('file-saver');

const {
  SET_FILE_NAME,
  SET_FILE,
  RESET_STATE,
  FILE_UPLOAD_SUCCESSFULLY,
  ACTION_FAILED,
  SET_FILE_PATH,
  RESET_FILE_PATH,
  START_FILE_IMPORT,
  SET_FILE_NAME_ERROR,
  DOWNLOAD_FILE_SUCCESS_ACTION,
} = MCQ_BULK_UPLOAD_ACTIONS;

const mcqBulkUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FILE_NAME:
      return { ...state, fileName: action.payload };
    case SET_FILE:
      return { ...state, fileChoosen: action.payload, isPreviewLoading: true };
    case RESET_STATE:
      return initialState;
    case FILE_UPLOAD_SUCCESSFULLY:
      return {
        ...state,
        isRefetchQuestionList: true,
        isImporting: false,
      };
    case ACTION_FAILED:
      return { ...state, isLoading: false };
    case SET_FILE_PATH:
      return {
        ...state,
        filePath: action.payload,
        showError: true,
        isRefetchQuestionList: false,
      };
    case RESET_FILE_PATH:
      return {
        ...state,
        filePath: '',
        showError: false,
        isImporting: false,
        isRefetchQuestionList: false,
      };
    case START_FILE_IMPORT:
      return {
        ...state,
        isImporting: true,
        isRefetchQuestionList: false,
      };
    case SET_FILE_NAME_ERROR:
      return { ...state,  fileNameError: action.payload };
    case DOWNLOAD_FILE_SUCCESS_ACTION: {
      fileSaver.saveAs(new Blob([action.payload], { type: 'application/octet-stream' }), 'question-error.csv');
      return { ...state };
    }
    default:
      return state;
  }
};

export default mcqBulkUploadReducer;
