import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AdminProfileComponent from 'modules/superAdmin/adminProfile/AdminProfileComponent';
import { setProfileModal, setIsLogout } from 'redux/admin/modalStates/action';
import { adminLoginResetAction, adminLogoutRequestAction } from 'redux/admin/login/action';

import { getUserDetails } from 'utils';

const AdminProfileContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const profileDetails = getUserDetails();

  const { profileModal, isLogout } = useSelector((state) => state.modalStatusReducer);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const toggleConfirmation = () => {
    setConfirmationModal(!confirmationModal);
  };
  const toggle = () => {
    dispatch(setProfileModal(false));
  };

  useEffect(() => {
    if (isLogout) {
      localStorage.clear();
      dispatch(setProfileModal(false));
      dispatch(adminLoginResetAction());
      dispatch(setIsLogout(false));
      history.push('/login');
    }
  }, [isLogout]);

  const handleLogout = () => {
    dispatch(adminLogoutRequestAction());
  };

  return (
    <AdminProfileComponent
      profileDetails={profileDetails}
      toggle={toggle}
      showModal={profileModal}
      handleLogout={handleLogout}
      toggleConfirmation={toggleConfirmation}
      confirmationModal={confirmationModal}
    />
  );
};

export default React.memo(AdminProfileContainer);
