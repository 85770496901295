import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  editQuestionFailureAction,
  editQuestionSuccessAction,
  setQuestionFailuteAction,
  setQuestionSuccessAction,
} from 'redux/admin/editQuestion/action';
import { editQuestionPostApi, getQuestionApi } from 'redux/admin/editQuestion/api';
import { getPresignedUrlApi, putImageS3 } from 'redux/admin/createQuestion/api';

import { EDIT_QUESTION } from 'redux/admin/editQuestion/actionConstants';

const { EDIT_QUESTION_REQUEST_ACTION, SET_QUESTION_REQUEST_ACTION } = EDIT_QUESTION;

export function* editQuestionSaga(action) {
  const {
    id,
    type,
    description,
    marks,
    timeInMinutes,
    difficulty,
    tags,
    questionDiagram,
    mcqOptions,
  } = action.payload;

  try {
    let image_url;

    if (questionDiagram?.size) {
      const presignedUrl = yield call(getPresignedUrlApi, { image_for: 'question' });

      const imageUrl = yield call(putImageS3, {
        url: presignedUrl.data.data.url,
        image: questionDiagram,
      });

      image_url = imageUrl.config.url.substr(0, imageUrl.config.url.indexOf('?'));
    }

    const urlsApiCall = [];
    const optionsImageIndex = [];
    let imageUrls;

    const isOptionsDiagramPresent = mcqOptions.reduce(
      (prev, current) => (current.optionDiagram ? prev + 1 : prev),
      0,
    );

    if (isOptionsDiagramPresent) {
      for (let i = 0; i < mcqOptions.length; i += 1) {
        if (mcqOptions[i].optionDiagram?.size) {
          optionsImageIndex.push(i);
          urlsApiCall.push(getPresignedUrlApi({ image_for: 'option' }));
        }
      }

      const urls = yield Promise.all(urlsApiCall);
      const imageUrlsApiCall = [];

      for (let i = 0; i < optionsImageIndex.length; i += 1) {
        const { url } = urls[i].data.data;
        const image = mcqOptions[optionsImageIndex[i]].optionDiagram;

        imageUrlsApiCall.push(putImageS3({ url, image }));
      }
      imageUrls = yield Promise.all(imageUrlsApiCall);
    }

    let i = 0;
    const options = mcqOptions.map((option) => {
      const optionResponse = {
        id: option.id,
        description: option.description.trim(),
        is_answer: option.isAnswer,
      };

      // if option is deleted then sending _destroy field for that option only
      if (option.isDelete) {
        // eslint-disable-next-line dot-notation
        optionResponse['_destroy'] = 1;
      }

      if (option.optionDiagram && option.optionDiagram.size) {
        optionResponse.image_url = imageUrls[i]?.config.url.substr(0, imageUrls[i].config.url.indexOf('?'));
        i += 1;
      } else if (option.optionDiagram && option.optionDiagram.length) {
        optionResponse.image_url = option.optionDiagram;
      } else {
        optionResponse.image_url = '';
      }

      return optionResponse;
    });

    const data = {
      id,
      is_multi_select: type.value,
      description: description.trim(),
      marks,
      time_in_minutes: timeInMinutes,
      difficulty_list: difficulty.value,
      tag_list: tags.map((tag) => (tag.value)),
      image_url,
      options_attributes: options,
    };

    const response = yield call(editQuestionPostApi, data);
    yield put(editQuestionSuccessAction(response.data.data));
    toast.success(response.data.message);
  } catch (error) {
    toast.error(error.response.data.message);
    yield put(editQuestionFailureAction(error));
  }
}

export function* getQuestionSaga(action) {
  try {
    const response = yield call(getQuestionApi, action.payload);
    yield put(setQuestionSuccessAction(response.data.data));
  } catch (error) {
    yield put(setQuestionFailuteAction(error));
  }
}

export default function* editQuestionWatcherSaga() {
  yield takeLatest(EDIT_QUESTION_REQUEST_ACTION, editQuestionSaga);
  yield takeLatest(SET_QUESTION_REQUEST_ACTION, getQuestionSaga);
}
