export const FEEDBACKS = {
  FEEDBACKS_REQUEST_ACTION: 'FEEDBACKS_REQUEST_ACTION',
  FEEDBACKS_SUCCESS_ACTION: 'FEEDBACKS_SUCCESS_ACTION',
  FEEDBACKS_FAILURE_ACTION: 'FEEDBACKS_FAILURE_ACTION',
  CLEAR_FEEDBACKS: 'CLEAR_FEEDBACKS',
  SET_FEEDBACKS_LIMIT: 'SET_FEEDBACKS_LIMIT',
  FEEDBACKS_DELETE_REQUEST_ACTION: 'FEEDBACKS_DELETE_REQUEST_ACTION',
  FEEDBACKS_DELETE_SUCCESS_ACTION: 'FEEDBACKS_DELETE_SUCCESS_ACTION',
  FEEDBACKS_DELETE_FAILURE_ACTION: 'FEEDBACKS_DELETE_FAILURE_ACTION',
};
