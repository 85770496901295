import { MODAL_STATUS } from 'redux/admin/modalStates/actionConstants';

const {
  CANDIDATE_INVITATION,
  PROFILE_MODAL,
  SEARCH_MODAL,
  SET_IS_OPEN,
  RUN_CODE_MODAL,
  SET_IS_LOGOUT,
  SET_IS_COPY_FROM_SERACH_DRIVE,
} = MODAL_STATUS;

export const setAddCandidateModal = (payload) => ({
  type: CANDIDATE_INVITATION,
  payload,
});

export const setProfileModal = (payload) => ({
  type: PROFILE_MODAL,
  payload,
});

export const setRunCodeModal = (payload) => ({
  type: RUN_CODE_MODAL,
  payload,
});

export const setSearchModal = (payload) => ({
  type: SEARCH_MODAL,
  payload,
});

export const  setIsCopyFromSearch = (payload) => ({
  type: SET_IS_COPY_FROM_SERACH_DRIVE,
  payload,
});

export const setIsOpen = (payload) => ({
  type: SET_IS_OPEN,
  payload,
});

export const setIsLogout = (payload) => ({
  type: SET_IS_LOGOUT,
  payload,
});
