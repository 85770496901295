import axios from 'axios';
import {
  ADMIN_LOGIN_LINK,
  ADMIN_LOGOUT_LINK,
} from 'redux/admin/login/constants';
import { destroy } from '../apiHelper';

export const adminLoginPostApi = (data) => {
  return axios.post(ADMIN_LOGIN_LINK, data);
};

export const adminLogoutApi = () => {
  return destroy(ADMIN_LOGOUT_LINK);
};
