import React from 'react';
import PropTypes from 'prop-types';

import { SiteLogo } from 'shared-components';
import LoginCard from './LoginCard/loginCard';
import './loginLayout.scss';

const LoginComponent = (props) => {
  const {
    handleEmailChange,
    handlePasswordChange,
    handleSubmit,
    emailError,
    passwordError,
    email,
    password,
    isError,
    isLoading,
    errorMessage,
    passwordVisibility,
    setPasswordVisibility,
  } = props;

  return (
    <section className='login-section'>
      <div className='login-wrapper position-relative h-100'>
        <div className='spark-code-header py-4'>
          <div className='container'>
            <SiteLogo />
          </div>
        </div>
        <LoginCard
          handleEmailChange={handleEmailChange}
          handlePasswordChange={handlePasswordChange}
          handleSubmit={handleSubmit}
          emailError={emailError}
          passwordError={passwordError}
          email={email}
          password={password}
          isLoading={isLoading}
          isError={isError}
          errorMessage={errorMessage}
          passwordVisibility={passwordVisibility}
          setPasswordVisibility={setPasswordVisibility}
        />
      </div>
    </section>
  );
};

LoginComponent.propTypes = {
  handleEmailChange: PropTypes.func.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  emailError: PropTypes.string.isRequired,
  passwordError: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  passwordVisibility: PropTypes.bool.isRequired,
  setPasswordVisibility: PropTypes.func.isRequired,
};

export default React.memo(LoginComponent);
