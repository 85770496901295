import { call, put, takeEvery } from 'redux-saga/effects';
import {
  templateFailureAction, templateSuccessAction,
} from 'redux/admin/template/action';
import { getCandidateTemplateListApi } from 'redux/admin/template/api';
import { TEMPLATE } from 'redux/admin/template/actionContants';

const { CANDIDATE_TEMPLATE_LIST_REQUEST_ACTION } = TEMPLATE;

export function* getCandidateTemplates(action) {
  try {
    const response = yield call(getCandidateTemplateListApi, action.payload);
    yield put(templateSuccessAction(response.data.data.templates));
  } catch (error) {
    yield put(templateFailureAction(error));
  }
}

export default function* getCandidateTemplateSaga() {
  yield takeEvery(CANDIDATE_TEMPLATE_LIST_REQUEST_ACTION, getCandidateTemplates);
}
