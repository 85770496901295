import React from 'react';
import PropTypes from 'prop-types';

import {
  ModalBody,
  Modal,
  ModalHeader,
  Button,
  ModalFooter,
} from 'core-components';

const CustomModal = ({
  handleOnConfirm,
  onModalClose,
  actionMessage,
  actionName,
  confirmationModal,
}) => {
  return (
    <Modal
      isOpen={confirmationModal}
      toggle={onModalClose}
      modalClassName='modal-form'
      className='confirmation-modal'
    >
      <ModalHeader
        className='modal-header'
      >
        Confirm {actionName}
      </ModalHeader>
      <ModalBody className='d-flex flex-column scroll-y'>
        {actionMessage}
      </ModalBody>
      <ModalFooter className='border-0 modal-footer'>
        <Button
          color='secondary'
          outline
          onClick={onModalClose}
        >
          Cancel
        </Button>
        <Button
          color='primary'
          onClick={handleOnConfirm}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

CustomModal.propTypes = {
  handleOnConfirm: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  actionName: PropTypes.string.isRequired,
  actionMessage: PropTypes.string.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
};
export default CustomModal;
