import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCableProvider } from 'react-actioncable-provider';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import ChatwootWidget from 'components/IdeComponent/Chatwoot';
import IdeComponent from 'components/IdeComponent';
import { setCode, setLanguageSelected } from 'actions/languageAction';
import { saveCode } from 'actions/codeBackupAction';
import { changeSection } from 'actions/sectionStatusAction';
import { mcqQuestionsRequest } from 'actions/mcqQuestionsActions';
import { statementRequest } from 'actions/problemStatementActions';

import { currentLangState } from 'utils/helpers/currentLanguageHelper';
import local from 'utils/local';
import session from 'utils/session';

import { CANDIDATE_ROUTES, ROUTES } from 'constants/routeConstants';
import { WEB_SCOKET_CABLE_URL } from 'constants/appConstants';

const IdeContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [first, setFirst] = useState(false);

  const { languages } = useSelector((state) => state.languageReducer);
  const {
    candidateId,
    data: { id },
    isFinished,
  } = useSelector((state) => state.userDriveReducer);

  const allowCoding = session.getItem('allowCoding');
  const allowMcq = session.getItem('allowMcq');

  let { code, languageSelected } = useSelector(
    (state) => state.languageReducer,
  );
  const { statement, activeIndex } = useSelector(
    (state) => state.problemStatementReducer,
  );
  const { submissionAllowed } = useSelector(
    (state) => state.codeSubmissionReducer,
  );
  const {
    backupCode: {
      answer,
      problem_id: backupCodeProblemId,
      lang_code: backupLanguageId,
      submission_count_left: leftSubmissionCount,
    },
  } = useSelector((state) => state.codeBackupReducer);

  const { id: problemId } = statement[activeIndex - 1] || { problem_id: null };

  const driveID = local.getItem('driveID');
  const languageId = languageSelected.id;

  const tokenId = local.getItem('authToken');

  const unloadCallback = (event) => {
    event.preventDefault();
    event.returnValue = 'Are you sure u want to leave?';
    return true;
  };

  // add event listener on close tab
  useEffect(() => {
    // TODO: After hard reload event listener get removed form the DOM,
    // so need to do respective changes (add event listner on hard reload).
    window.addEventListener('beforeunload', unloadCallback);
    return () => {
      window.removeEventListener('beforeunload', unloadCallback);
    };
  }, []);

  useEffect(() => {
    if (allowCoding) {
      dispatch(statementRequest(id));
      // if case of only coding drive changing section to 2 so it'll show coding problems section
      // in IDE (if MCQ questiobs are there in drive section is setting to 1 in mcqQuestionSaga)
      if (!allowMcq) {
        dispatch(changeSection(2));
      }
    }
    if (allowMcq) {
      dispatch(mcqQuestionsRequest());
    }
    if (allowCoding === undefined && allowMcq === undefined) {
      toast.error('Drive does not contain both mcq and coding problems');
    }
  }, [dispatch, allowCoding, allowMcq]);

  if (problemId) {
    const { currCode, currLanguageSelected } = currentLangState(
      code,
      answer,
      problemId,
      languages,
      backupLanguageId,
      languageSelected,
      backupCodeProblemId,
      submissionAllowed,
      leftSubmissionCount,
      first,
    );
    code = currCode;

    if (languageSelected.id !== currLanguageSelected.id) {
      dispatch(setLanguageSelected(currLanguageSelected));
    }
    languageSelected = currLanguageSelected;
  }

  if (isFinished) {
    history.replace(ROUTES.CANDIDATE + CANDIDATE_ROUTES.ENDPAGE);
  }

  const codeBackupInterval = () => {
    if (
      code != null &&
      languageId != null &&
      problemId != null &&
      candidateId != null &&
      driveID != null
    ) {
      const obj = {
        code,
        languageId,
        problemId,
        candidateId,
        driveID,
      };
      dispatch(saveCode(obj));
    }
  };

  const handleSaveDraft = useCallback(() => {
    codeBackupInterval();
    dispatch(setCode(code));
  }, [code, languageId, problemId, candidateId, driveID]);

  return (
    <ActionCableProvider url={WEB_SCOKET_CABLE_URL}>
      <IdeComponent
        code={code}
        languageSelected={languageSelected}
        setFirst={setFirst}
        handleSaveDraft={handleSaveDraft}
      />
      <ChatwootWidget />
    </ActionCableProvider>
  );
};

export default IdeContainer;
