export const validateData = (schema, data, setSignUpState) => {
  schema.validate(data, { abortEarly: false }).catch((err) => {
    err.inner.forEach((e) => {
      switch (e.path) {
        case 'email':
          setSignUpState({
            type: 'emailError',
            payload: e.message,
          });
          break;
        default:
          break;
      }
    });
  });
};
