import React from 'react';
import PropTypes from 'prop-types';

import {
  Input,
  FormGroup,
  ModalBody,
  Modal,
  Button,
  Form,
  Label,
  Icon,
  ModalFooter,
  Spinner,
} from 'core-components';

const CSVUploadComponent = (props) => {
  const {
    chooseFile,
    fileSubmit,
    showError,
    onModalClose,
    isImporting,
    fileNameError,
    onDownload,
    modal,
    toggle,
    label,
    sampleCSVFile,
  } = props;

  const handleToggle = () => {
    toggle();
    if (modal) onModalClose();
  };

  return (
    <>
      <Button
        onClick={onModalClose}
        className='btn-sm'
      >
        Upload
      </Button>
      <Modal
        isOpen={modal}
        toggle={handleToggle}
        modalClassName='modal-form'
        className='confirmation-modal'
      >
        <ModalBody className='d-flex flex-column scroll-y'>
          <div className='form-wrapper scroll-y'>
            <div className='form-title-wrapper d-flex'>
              <Button
                className='close ml-auto'
                onClick={onModalClose}
              >
                x
              </Button>
            </div>
            <Form className='form-tpl scroll-y'>
              <div className='form-body scroll-y'>
                <FormGroup>
                  <Label for='fileName'>{label}</Label>
                  <a
                    role='button'
                    href={`${process.env.PUBLIC_URL}/${sampleCSVFile}`}
                    download={sampleCSVFile}
                    className='text-secondary'
                  >
                    &nbsp;(sample csv)
                  </a>
                  {isImporting && <Spinner />}
                  <div className='form-file mr-auto'>
                    <Input id='fileName' type='file' onChange={chooseFile} />
                  </div>
                </FormGroup>
              </div>
            </Form>
          </div>
          <div className='text-danger'>{fileNameError}</div>
          <ModalFooter className='border-0 modal-footer'>
            <Button
              color='secondary'
              outline
              onClick={onModalClose}
            >
              Cancel
            </Button>
            <Button
              color='primary'
              onClick={fileSubmit}
            >
              Upload
            </Button>
          </ModalFooter>
        </ModalBody>
      </Modal>
      {showError && (
      <>
        <Button
          className='download-icon-box p-0'
          onClick={onDownload}
        >
          <Icon
            name='download'
            className='download-icon text-danger'
            title='Download Error File'
          />
        </Button>
      </>
      )}
    </>
  );
};

CSVUploadComponent.propTypes = {
  chooseFile: PropTypes.func.isRequired,
  fileSubmit: PropTypes.func.isRequired,
  showError: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  isImporting: PropTypes.bool.isRequired,
  fileNameError: PropTypes.string.isRequired,
  onDownload: PropTypes.func.isRequired,
  modal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  sampleCSVFile: PropTypes.string.isRequired,
};

export default CSVUploadComponent;
