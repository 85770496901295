import produce from 'immer';

import { DRIVE_RESULT, SELECTED_CANDIDATE, SELECTED_CANDIDATE_EMAIL } from 'redux/admin/driveResult/actionConstants';

export const initialState = {
  data: {
    result: [],
    page: 1,
    pages: 1,
    driveName: '',
    total_selected_candidates: 0,
    drive_total_marks: 0,
    absent_candidates_count: 0,
  },
  isSuccess: false,
  isLoading: false,
  isError: false,
  errorMessage: '',
  isSelectedLoading: false,
  driveResultErrorMessage: '',
};

const { DRIVE_RESULT_REQUEST_ACTION, DRIVE_RESULT_SUCCESS_ACTION,
  DRIVE_RESULT_FAILURE_ACTION } = DRIVE_RESULT;

const { SELECTED_CANDIDATE_SUCCESS_ACTION, SELECTED_CANDIDATE_REQUEST_ACTION,
  SELECTED_CANDIDATE_FAILURE_ACTION } = SELECTED_CANDIDATE;

const { SELECTED_CANDIDATE_EMAIL_SUCCESS_ACTION,
  SELECTED_CANDIDATE_EMAIL_FAILURE_ACTION } = SELECTED_CANDIDATE_EMAIL;

const driveResultReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  const { total_selected_candidates } = state.data;
  switch (type) {
    case DRIVE_RESULT_SUCCESS_ACTION:
      return {
        ...state,
        data: payload,
        driveName: payload,
        total_selected_candidates: true,
        isLoading: false,
        isSuccess: true,
      };
    case DRIVE_RESULT_FAILURE_ACTION:
      return {
        ...state,
        isError : true,
        isLoading : false,
        errorMessage: payload.message,
      };
    case DRIVE_RESULT_REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
        result: [],
      };
    case SELECTED_CANDIDATE_SUCCESS_ACTION:
      return {
        ...state,
        isSelectedLoading: false,
        isLoading: true,
        data: {
          ...state.data,
          total_selected_candidates:
            payload.is_selected ? total_selected_candidates + 1 :
              total_selected_candidates - 1,
        },
      };
    case SELECTED_CANDIDATE_REQUEST_ACTION:
      return {
        ...state,
        isSelectedLoading: true,
        isLoading: false,
      };
    case SELECTED_CANDIDATE_FAILURE_ACTION:
      return {
        ...state,
        isFailure: true,
        errorMessage: payload.message,
        isLoading: false,
      };
    case SELECTED_CANDIDATE_EMAIL_SUCCESS_ACTION:
      return {
        ...state,
        isSelectedLoading: false,
        isLoading: false,
      };
    case SELECTED_CANDIDATE_EMAIL_FAILURE_ACTION:
      return {
        ...state,
        isFailure: true,
        isLoading: false,
        errorMessage: false,
      };
    default:
      return state;
  }
});

export default driveResultReducer;
