import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { MdCheckCircle, MdMoreHoriz } from 'react-icons/md';

import {
  Row,
  Form,
  FormGroup,
  Input,
  FormFeedback,
  Table,
  Icon,
} from 'core-components';
import { SearchBox, UpdateConfirmModal } from 'shared-components';

import { CustomThemeSelect } from 'constants/themesConstants';
import { customStyle } from 'constants/stylesConstants';

import './inviteUserComponent.scss';
import 'modules/admin/inviteUser/InviteUserComponent/styles.css';

const InviteUserComponent = (props) => {
  const {
    handleInvitationEmails,
    handleSendInvitation,
    handleSubmit,
    usersData,
    handleSelectedRoleChange,
    handleOnUserStatusUpdate,
    toggleConfirmation,
    confirmationModal,
    handleUpdateClick,
    targetId,
    handleOnChange,
    handleUserSearchClick,
    handleUserSearchClearClick,
    searchText,
  } = props;

  const [isUserActive, setisUserActive] = useState(true);
  const [activateDeactivateUserEmail, setActivateDeactivateUserEmail] = useState('');

  const {
    email,
    emailsError,
    successMessage,
    users,
    failureMessage,
    roles,
    totalUsers,
    role,
    roleErr,
  } = usersData;
  const options =
    roles &&
    roles.map((roleData) => {
      return { value: roleData.id, label: roleData.name };
    });

  const checkLoggedInUser = (userEmail) => {
    return userEmail !== localStorage.getItem('uid');
  };

  return (
    <div className='rules-section d-flex flex-column scroll-y'>
      <UpdateConfirmModal
        confirmationModal={confirmationModal}
        toggleConfirmation={toggleConfirmation}
        handleStatusUpdate={handleOnUserStatusUpdate}
        targetId={targetId}
        isUserActive={isUserActive}
        activateDeactivateUserEmail={activateDeactivateUserEmail}
      />
      <div className='rules-section-head d-flex align-items-center justify-content-between px-3'>
        <h2 className='section-heading font-weight-bold mb-0'>
          Users ({totalUsers})
        </h2>
        <SearchBox
          placeholder='Search User'
          handleOnChange={handleOnChange}
          handleSearchClick={handleUserSearchClick}
          handleCloseClick={handleUserSearchClearClick}
          value={searchText}
          className='mr-3'
        />
      </div>
      <div className='rules-list-wrap'>
        <div className='table-responsive'>
          <Table className='sp-table'>
            <thead>
              <tr>
                <th>#</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Mobile Number</th>
                <th>Role</th>
                <th>Invitation Status</th>
                <th>Active User</th>
              </tr>
            </thead>
            <tbody className='rules-list'>
              {users.map((userIterator, key) => {
                const {
                  id,
                  first_name,
                  last_name,
                  email: userEmail,
                  mobile_number,
                  role: userRole,
                  is_active,
                  invitation_accepted,
                } = userIterator;
                return (
                  <tr key={id}>
                    <td> {key + 1} </td>
                    <td> {first_name} </td>
                    <td> {last_name} </td>
                    <td> {userEmail} </td>
                    <td> {mobile_number} </td>
                    <td> {userRole} </td>
                    <td>
                      {' '}
                      {invitation_accepted ? (
                        <MdCheckCircle
                          className='text-success'
                          title='Accepted'
                        />
                      ) : (
                        <MdMoreHoriz className='text-warning' title='Pending' />
                      )}{' '}
                    </td>
                    <td>
                      {!checkLoggedInUser(userEmail) ? (
                        <>
                          <FormGroup check inline>
                            <Input
                              type='checkbox'
                              className='cursor-not-allowed'
                              checked={is_active}
                              disabled
                            />
                          </FormGroup>
                        </>
                      ) : (
                        <>
                          <FormGroup check inline>
                            <Input
                              type='checkbox'
                              checked={is_active}
                              onClick={() => {
                                toggleConfirmation();
                                handleUpdateClick(id);
                                setisUserActive(!is_active);
                                setActivateDeactivateUserEmail(userEmail);
                              }}
                            />
                          </FormGroup>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
      <br />
      <div className='rules-section-head d-flex align-items-center justify-content-between px-3'>
        <h2 className='section-heading font-weight-bold mb-0'>Invite User</h2>
      </div>
      <div className='rules-list-wrap'>
        <Form className='w-100 mb-5' onSubmit={handleSubmit} method='post'>
          <Row>
            <FormGroup className='px-3 w-50'>
              <Input
                type='text'
                placeholder='Enter User Email'
                value={email}
                onChange={handleInvitationEmails}
                invalid={emailsError !== ''}
                className='px-3 mr-3'
              />
              <FormFeedback>{emailsError}</FormFeedback>
              <div className='text-danger'>{failureMessage}</div>
              <div className='text-success'>{successMessage}</div>
            </FormGroup>

            <FormGroup className='px-3 w-25 mt-1'>
              <Select
                className='mr-3 text-light react-select-container'
                classNamePrefix='react-select'
                id='role'
                value={role}
                onChange={handleSelectedRoleChange}
                options={options}
                placeholder='Select Role'
                theme={CustomThemeSelect}
                styles={customStyle}
              />
              <div className='text-danger'>{roleErr}</div>
            </FormGroup>
            <FormGroup className='send-invitation'>
              <Icon
                name='send'
                onClick={handleSendInvitation}
                className='send-icon '
                title='Send Invitation'
              />
            </FormGroup>
          </Row>
        </Form>
      </div>
    </div>
  );
};

InviteUserComponent.defaultProps = {
  targetId: 0,
  successMessage: '',
  emailsError: '',
  email: '',
};

InviteUserComponent.propTypes = {
  email: PropTypes.string,
  handleInvitationEmails: PropTypes.func.isRequired,
  handleSendInvitation: PropTypes.func.isRequired,
  emailsError: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
  usersData: PropTypes.objectOf(PropTypes.any).isRequired,
  handleSelectedRoleChange: PropTypes.func.isRequired,
  handleOnUserStatusUpdate: PropTypes.func.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
  handleUpdateClick: PropTypes.func.isRequired,
  targetId: PropTypes.number,
  handleOnChange: PropTypes.func.isRequired,
  handleUserSearchClick: PropTypes.func.isRequired,
  handleUserSearchClearClick: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
};

export default InviteUserComponent;
