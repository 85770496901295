import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { codeBackupPostApi, codeBackupGetApi } from 'apis/codeBackupAPI';
import {
  backupCodeAction,
  backupCodeFailed,
} from 'actions/codeBackupAction';
import { setSubmissionAllowed } from 'actions/codeSubmissionActions';

import { CODE_BACKUP } from 'constants/actionConstants';
import local from 'utils/local';

export function* fetchCodeBackup(action) {
  try {
    if (action.payload) {
      const token = local.getItem('authToken');
      const response = yield call(codeBackupGetApi, token, action.payload);
      const { data, message } = response.data;

      // code backup present
      let isCodeBackup = true;
      if (message === 'Record Not Found') {
        isCodeBackup = false;
      }

      yield put(backupCodeAction({ data, isCodeBackup }));
      if (response.data.data.code && response.data.data.code.submission_count_left !== undefined) {
        yield put(setSubmissionAllowed(response.data.data.code.submission_count_left));
      }
    }
  } catch (error) {
    const { data } = error.response.data;

    yield put(backupCodeFailed(data));
    toast.error(data.message);
  }
}

export function* saveCodeBackup(action) {
  const { code, languageId, problemId } = action.payload;

  const data = {
    answer: code,
    language_id: languageId,
    problem_id: problemId,
    token: local.getItem('authToken'),
  };

  try {
    yield call(codeBackupPostApi, data);
    toast.success('Code saved successfully');
  } catch (error) {
    const { message } = error.response.data;

    yield put(backupCodeFailed(message));
    toast.error(message);
  }
}

export default function* saveCodeSaga() {
  yield takeLatest(CODE_BACKUP.SET_BACKUP_CODE, saveCodeBackup);
  yield takeLatest(CODE_BACKUP.FETCH_CODE_REQUEST, fetchCodeBackup);
}
