// This is common utils file. Add common utils/helper functions here

// converting images to url to display preview as you upload them
export const imageFileToUrl = (image) => URL.createObjectURL(image);

export const stringValidation = (value) => {
  if (value) {
    return value?.trim().length === 0 ? 'N/A' : value?.trim();
  }
  return 'N/A';
};

// checking if given file is image or not
export const isImageFile = (fileName) => {
  const imageFileExtentions = ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG'];

  if (fileName) {
    return imageFileExtentions.includes(fileName.split('.').pop());
  }
  // default returning false if file does not exist
  return false;
};

export const getFullNameWithContact = (first, last, contactNumber) => `${first} ${last} (${contactNumber})`;

export const getValueIfTestNotAttended = (value, timeTaken) => ((value || value === 0) && timeTaken !== 'Not Attended' ? value : <h2>-</h2>);

export const getUserDetails = () => JSON.parse(localStorage.getItem('userDetails'));
