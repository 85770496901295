import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Icon, Button } from 'core-components/index';
import { ActionTile } from 'shared-components';
import DriveDeleteCopyComponent from 'shared-components/DriveDeleteCopyComponent';

import { DATE_TIME_FORMAT } from 'constants/appConstants';

import './driveTile.scss';

const DriveTile = ({
  driveDetails,
  onClickCandidates,
  onClickResult,
  onClickEdit,
  toggleConfirmation,
  handleDeleteClick,
  type,
  isDrive,
  editTypeTitle,
  toggleSearchModal,
}) => {
  return (
    <>
      <div className='drive-content flex-1'>
        <ActionTile
          countDetails
          driveDetails={driveDetails}
          id={driveDetails?.id}
          count={driveDetails?.count}
          Children={(
            <DriveDeleteCopyComponent
              driveDetails={driveDetails}
              toggleConfirmation={toggleConfirmation}
              handleDeleteClick={handleDeleteClick}
              type={type}
              isDrive={isDrive}
              toggleSearchModal={toggleSearchModal}
            />
          )}
          className='mb-3'
          onClickEdit={onClickEdit}
          type={type}
          editTypeTitle={editTypeTitle}
        />
        <h6 className='mb-2 font-weight-bold card-heading text-capitalize'>
          {driveDetails?.name}
        </h6>
        {driveDetails?.start_time && driveDetails?.end_time && (
          <div className='drive-date-details d-flex align-items-center justify-content-between'>
            <Icon name='calendar' />
            <span className='text-truncate' title='Start Time'>
              {driveDetails
                ? moment(driveDetails.start_time).format(DATE_TIME_FORMAT)
                : ''}
            </span>
            <Icon name='arrow-right' />
            <span className='text-truncate' title='End Time'>
              {driveDetails
                ? moment(driveDetails.end_time).format(DATE_TIME_FORMAT)
                : ''}
            </span>
          </div>
        )}
      </div>
      <div className='tile-button-wrapper d-flex align-items-center'>
        <Button
          className='tile-btn -candidates-btn'
          onClick={(e) => onClickCandidates(driveDetails)}
        >
          Candidates
        </Button>
        <Button
          className='tile-btn -result-btn '
          onClick={(e) => onClickResult(driveDetails)}
        >
          Result
        </Button>
      </div>
    </>
  );
};

DriveTile.defaultProps = {
  type: '',
  isDrive: false,
  editTypeTitle: 'Drive',
  toggleSearchModal: () => {},
};

DriveTile.propTypes = {
  driveDetails: PropTypes.shape().isRequired,
  onClickCandidates: PropTypes.func.isRequired,
  onClickResult: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  isDrive: PropTypes.bool,
  editTypeTitle: PropTypes.string,
  toggleSearchModal: PropTypes.func,
};

export default DriveTile;
