export const PROBLEMS_LIST = {
  PROBLEMS_LIST_REQUEST_ACTION: 'PROBLEMS_LIST_REQUEST_ACTION',
  PROBLEMS_LIST_SUCCESS_ACTION: 'PROBLEMS_LIST_SUCCESS_ACTION',
  PROBLEMS_LIST_FAILURE_ACTION: 'PROBLEMS_LIST_FAILURE_ACTION',
  CLEAR_PROBLEMS: 'CLEAR_PROBLEMS',
  SET_PROBLEMS_LIMIT: 'SET_PROBLEMS_LIMIT',
  SET_PROBLEMS_SEARCH_TEXT: 'SET_PROBLEMS_SEARCH_TEXT',
  SET_PROBLEMS_DIFFICULTY: 'SET_PROBLEMS_DIFFICULTY',
  SET_PROBLEMS_TAG: 'SET_PROBLEMS_TAG',
  RESET_PROBLEMS_FILTERS: 'RESET_PROBLEMS_FILTERS',
  RESET_PROBLEMS_SEARCH_TEXT: 'RESET_PROBLEMS_SEARCH_TEXT',
  SET_SEARCHED_PROBLEMS_FILTER_ACTION: 'SET_SEARCHED_PROBLEMS_FILTER_ACTION',
  RESET_SEARCHED_PROBLEMS_FILTER_ACTION: 'RESET_SEARCHED_PROBLEMS_FILTER_ACTION',
};

export const PROBLEM_DELETE = {
  PROBLEM_DELETE_REQUEST_ACTION: 'PROBLEM_DELETE_REQUEST_ACTION',
  PROBLEM_DELETE_SUCCESS_ACTION: 'PROBLEM_DELETE_SUCCESS_ACTION',
  PROBLEM_DELETE_FAILURE_ACTION: 'PROBLEM_DELETE_FAILURE_ACTION',
};
