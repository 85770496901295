export const COMPANY_SIGN_UP = {
  COMPANY_SIGN_UP_REQUEST: 'COMPANY_SIGN_UP_REQUEST',
  COMPANY_SIGN_UP_SUCCESS: 'COMPANY_SIGN_UP_SUCCESS',
  COMPANY_SIGN_UP_FAILURE: 'COMPANY_SIGN_UP_FAILURE',
};

export const SIGN_UP_TOKEN_VALIDITY = {
  SIGN_UP_TOKEN_VALIDITY_REQUEST: 'SIGN_UP_TOKEN_VALIDITY_REQUEST',
  SIGN_UP_TOKEN_VALIDITY_SUCCESS: 'SIGN_UP_TOKEN_VALIDITY_SUCCESS',
  SIGN_UP_TOKEN_VALIDITY_FAILURE: 'SIGN_UP_TOKEN_VALIDITY_FAILURE',
};
