import { ASSESSMENT_LIST } from 'redux/admin/assessmentList/actionConstants';

const { ASSESSMENT_LIST_REQUEST, ASSESSMENT_LIST_SUCCESS, ASSESSMENT_LIST_FAILURE,
  ASSESSMENT_DELETE_REQUEST, ASSESSMENT_DELETE_SUCCESS, ASSESSMENT_DELETE_FAILURE,
  CLEAR_ASSESSMENTS, ADD_ASSESSMENT_OFFSET } = ASSESSMENT_LIST;

export const assessementListRequest = (payload) => ({
  type: ASSESSMENT_LIST_REQUEST,
  payload,
});
export const assessementListSuccess = (payload) => ({
  type: ASSESSMENT_LIST_SUCCESS,
  payload,
});
export const assessementListFailure = (message) => ({
  type: ASSESSMENT_LIST_FAILURE,
  payload: message,
});

export const clearAssessmentsAction = () => ({
  type: CLEAR_ASSESSMENTS,
});

export const assessementDeleteRequest = (payload) => ({
  type: ASSESSMENT_DELETE_REQUEST,
  payload,
});
export const assessementDeleteSuccess = (payload) => ({
  type: ASSESSMENT_DELETE_SUCCESS,
  payload,
});
export const assessementDeleteFailure = (payload) => ({
  type: ASSESSMENT_DELETE_FAILURE,
  payload,
});
export const setAssessmentOffset = (payload) => ({
  type: ADD_ASSESSMENT_OFFSET,
  payload,
});
