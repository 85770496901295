import produce from 'immer';
import { TESTCASE_LIST } from 'redux/admin/testCaseList/actionContants';

export const initialState = {
  testCases: [],
  isTestCaseSuccess: false,
  isTestCaseLoading: false,
  isTestCaseError: false,
};

const testCaseListReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case TESTCASE_LIST.TESTCASE_LIST_REQUEST_ACTION:
      state.isTestCaseLoading = true;
      break;
    case TESTCASE_LIST.TESTCASE_LIST_SUCCESS_ACTION:
      state.isTestCaseSuccess = true;
      state.isTestCaseLoading = false;
      state.testCases = payload;
      break;
    case TESTCASE_LIST.TESTCASE_LIST_FAILURE_ACTION:
      state.isTestCaseError = true;
      state.isTestCaseLoading = false;
      break;
    case TESTCASE_LIST.TESTCASE_LIST_RESET:
      return { ...initialState };
    default:
      return state;
  }
});
export default testCaseListReducer;
