import { useRef, useEffect, useState, useCallback } from 'react';

import FeedbackComponent from 'modules/admin/feedbacks/FeedbackComponent';
import {
  feedbackRequestAction,
  feedbackClearAction,
  setFeedbacksOffset,
  feedbackDeleteRequestAction,
} from 'redux/admin/feedbacks/action';
import { useDispatch, useSelector } from 'react-redux';
import { FEEDBACKS_LIMIT } from 'constants/appConstants';

const FeedbackContainer = () => {
  const dispatch = useDispatch();
  const observer = useRef();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [targetId, setTargetId] = useState();
  const toggleConfirmation = () => {
    setConfirmationModal(!confirmationModal);
  };

  const {
    feedbacks,
    totalFeedbacks,
    isFeedbacksLoading,
    offset: currentPageFeedbacks,
    deletionCount,
  } = useSelector((item) => item.feedbackListReducer);

  const [localCurrentFeedBacks, setLocalCurrentFeedBacks] = useState(0);

  const lastBookElementRef = useCallback((node) => {
    if (isFeedbacksLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (feedbacks.length >= FEEDBACKS_LIMIT) {
          setLocalCurrentFeedBacks(currentPageFeedbacks + FEEDBACKS_LIMIT - deletionCount);
          dispatch(setFeedbacksOffset(currentPageFeedbacks + FEEDBACKS_LIMIT - deletionCount));
        }
      }
    });
    if (node) observer.current.observe(node);
  }, [isFeedbacksLoading]);

  useEffect(() => {
    return () => {
      dispatch(feedbackClearAction());
    };
  }, []);

  useEffect(() => {
    if (!isFeedbacksLoading && localCurrentFeedBacks <= totalFeedbacks) {
      dispatch(feedbackRequestAction({
        offset: localCurrentFeedBacks,
        feedbacks,
      }));
    }
  }, [currentPageFeedbacks]);

  const handleDeleteClick = (id) => {
    dispatch(feedbackDeleteRequestAction({ id }));
  };

  const onClickAddFilter = () => { };

  return (
    <FeedbackComponent
      feedbacks={feedbacks}
      isFeedbacksLoading={isFeedbacksLoading}
      lastBookElementRef={lastBookElementRef}
      totalFeedbacks={totalFeedbacks}
      toggleConfirmation={toggleConfirmation}
      targetId={targetId}
      handleDeleteClick={handleDeleteClick}
      confirmationModal={confirmationModal}
      onClickAddFilter={onClickAddFilter}
    />
  );
};

export default FeedbackContainer;
