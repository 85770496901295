import React, {
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { validateData } from 'modules/admin/createAssessment/dataValidation';
import CreateAssessmentComponent from 'modules/admin/createAssessment/CreateAssessmentComponent';
import { reducer } from 'modules/admin/createAssessment/CreateAssessmentCotainer/reducer';
import { createAssessmentRequestAction, createAssessmentClearState } from 'redux/admin/createAssessment/action';
import { contactsListRequestActioon, contactsListClear } from 'redux/admin/getContacts/action';
import { getMcqCountRequestAction, clearMcqCount } from 'redux/admin/getMcqCount/action';
import { tagsRequestAction } from 'redux/admin/tags/action';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';
import arrayToKeyValueHash from 'utils/arrayToKeyValueHash';
import { getUserDetails } from 'utils';

const CreateAssessmentContainer = () => {
  const initialState =
  {
    data: {
      Assessment: {
        id: '',
        name: '',
        description: '',
        organization_id: '',
        easy_problem_count: 0,
        medium_problem_count: 0,
        hard_problem_count: 0,
        easy_mcq_count: 0,
        medium_mcq_count: 0,
        hard_mcq_count: 0,
        contacts: [],
        mcq_tags: [],
      },
    },
    nameErrTxt: '',
    descriptionErrTxt: '',
    problemCountErrTxt: '',
    contactsErrTxt: '',
    message: '',
    easyProblemErrTxt: '',
    mediumProblemErrTxt: '',
    hardProblemErrTxt: '',
    easyMcqErrTxt: '',
    mediumMcqErrTxt: '',
    hardMcqErrTxt: '',
  };

  const { message, isSuccess }
    = useSelector((state) => state.createAssessmentReducer);

  const { mcqCountData, isMcqCountLoading }
    = useSelector((state) => state.getMcqCountReducer);

  const [createAssessment, setCreateAssessment] = useReducer(reducer, initialState);
  const history = useHistory();
  const dispatch = useDispatch();
  const { tagList } = useSelector((state) => state.tagsReducer);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [userContacts, setUserContacts] = useState([]);

  const {
    contactsList,
    isContactsLoading,
  } = useSelector((item) => item.getContactsReducer);
  const profileDetails = getUserDetails();
  const allowCoding = profileDetails.allow_coding;
  const allowMcq = profileDetails.allow_mcq;
  const [isParam, setIsParam] = useState(false);

  useEffect(() => {
    if (!isContactsLoading) {
      dispatch(contactsListRequestActioon({
        contactsList,
      }));
    }
    if (!isMcqCountLoading) {
      dispatch(getMcqCountRequestAction(createAssessment.data.Assessment.mcq_tags));
    }
    dispatch(tagsRequestAction());
  }, []);

  useEffect(() => {
    setTagsOptions(arrayToKeyValueHash(tagList));
  }, [tagList]);

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(5, 'Title must be atleast 5 characters')
      .required('Title is a required field'),
    description: yup
      .string()
      .min(10)
      .required(),
    problem_count: yup
      .number()
      .integer()
      .min(1, 'Please add atleast 1 count'),
    contacts: yup
      .array()
      .min(1, 'Please select contact(s)'),
    easy_problem_count: yup
      .number()
      .integer()
      .typeError('')
      .max(mcqCountData.easy_problem_count, 'Count should be less than total'),
    medium_problem_count: yup
      .number()
      .integer()
      .typeError('')
      .max(mcqCountData.medium_problem_count, 'Count should be less than total'),
    hard_problem_count: yup
      .number()
      .integer()
      .typeError('')
      .max(mcqCountData.hard_problem_count, 'Count should be less than total'),
    easy_mcq_count: yup
      .number()
      .integer()
      .typeError('')
      .max(mcqCountData.easy_mcq_count, 'Count should be less than total'),
    medium_mcq_count: yup
      .number()
      .integer()
      .typeError('')
      .max(mcqCountData.medium_mcq_count, 'Count should be less than total'),
    hard_mcq_count: yup
      .number()
      .integer()
      .typeError('')
      .max(mcqCountData.hard_mcq_count, 'Count should be less than total'),
  });

  useEffect(() => {
    return () => {
      dispatch(createAssessmentClearState());
      setCreateAssessment({ type: 'clear' });
      dispatch(contactsListClear());
      dispatch(clearMcqCount());
    };
  }, []);

  const handleAssessmentNameChange = useCallback(
    (event) => {
      const name = event.target.value;
      setCreateAssessment({
        type: 'nameErrTxt',
        payload: '',
      });
      setCreateAssessment({
        type: 'name',
        payload: name,
      });
    },
    [createAssessment.data.Assessment.name],
  );

  const handleAssessmentDescriptionChange = useCallback(
    (event) => {
      const description = event.target.value;
      setCreateAssessment({
        type: 'descriptionErrTxt',
        payload: '',
      });
      setCreateAssessment({
        type: 'description',
        payload: description,
      });
    },
    [createAssessment.data.Assessment.description],
  );

  const handleEasyProblemCountChange = useCallback(
    (event) => {
      const count = event.target.value;
      setCreateAssessment({
        type: 'easy_problem_count',
        payload: count,
      });
    },
    [createAssessment.data.Assessment.easy_problem_count],
  );

  const handleMediumProblemCountChange = useCallback(
    (event) => {
      const count = event.target.value;
      setCreateAssessment({
        type: 'medium_problem_count',
        payload: count,
      });
    },
    [createAssessment.data.Assessment.medium_problem_count],
  );

  const handleHardProblemCountChange = useCallback(
    (event) => {
      const count = event.target.value;
      setCreateAssessment({
        type: 'hard_problem_count',
        payload: count,
      });
    },
    [createAssessment.data.Assessment.hard_problem_count],
  );

  const handleEasyMcqCountChange = useCallback(
    (event) => {
      const count = event.target.value;
      setCreateAssessment({
        type: 'easy_mcq_count',
        payload: count,
      });
    },
    [createAssessment.data.Assessment.easy_mcq_count],
  );

  const handleMediumMcqCountChange = useCallback(
    (event) => {
      const count = event.target.value;
      setCreateAssessment({
        type: 'medium_mcq_count',
        payload: count,
      });
    },
    [createAssessment.data.Assessment.medium_mcq_count],
  );

  const handleHardMcqCountChange = useCallback(
    (event) => {
      const count = event.target.value;
      setCreateAssessment({
        type: 'hard_mcq_count',
        payload: count,
      });
    },
    [createAssessment.data.Assessment.hard_mcq_count],
  );

  const handleSelectedTagsChange = useCallback(
    (event) => {
      const tags = [];
      event.map((tag) => tags.push(tag.value));
      setCreateAssessment({
        type: 'mcq_tags',
        payload: tags,
      });
      dispatch(getMcqCountRequestAction(tags));
    }, [createAssessment.data.Assessment.mcq_tags],
  );

  const onCreateAssessmentSubmit = () => {
    const {
      data: {
        Assessment: { name, description, contacts,
          easy_problem_count, medium_problem_count, hard_problem_count,
          easy_mcq_count, medium_mcq_count, hard_mcq_count, mcq_tags },
      },
    } = createAssessment;

    const assessments_contacts_attributes = [];
    for (let i = 0; i < contacts.length; i += 1) {
      assessments_contacts_attributes.push(
        {
          user_id: contacts[i],
          _destroy: false,
        },
      );
    }

    const problem_cnt = allowCoding ? (easy_problem_count || 0) + (medium_problem_count || 0) +
      (hard_problem_count || 0) : 0;
    const mcq_cnt = allowMcq ? (easy_mcq_count || 0) + (medium_mcq_count || 0) +
      (hard_mcq_count || 0) : 0;
    const problem_count = problem_cnt + mcq_cnt;

    setCreateAssessment({
      type: 'resetCountErrs',
    });

    const validData = {
      name: name.trim(),
      description: description.trim(),
      problem_count,
      contacts,
      easy_problem_count,
      medium_problem_count,
      hard_problem_count,
      easy_mcq_count,
      medium_mcq_count,
      hard_mcq_count,
    };

    const postData = {
      name: name.trim(),
      description: description.trim(),
      problem_count_hash: {
        easy: easy_problem_count,
        medium: medium_problem_count,
        hard: hard_problem_count,
      },
      mcq_count_hash: {
        easy: easy_mcq_count,
        medium: medium_mcq_count,
        hard: hard_mcq_count,
      },
      assessments_contacts_attributes,
      tag_list: mcq_tags,
      is_param: isParam,
    };

    schema.isValid(validData).then(async (valid) => {
      if (!valid) {
        validateData(schema, validData, setCreateAssessment);
      } else {
        dispatch(createAssessmentRequestAction({ postData }));
      }
    });
  };

  const handleSelectedContactsChange = useCallback(
    (event) => {
      const contacts = event;
      setCreateAssessment({
        type: 'contactsErrTxt',
        payload: '',
      });
      setCreateAssessment({
        type: 'resetCurrentContacts',
      });
      const selected = [];
      for (let i = 0; i < contacts.length; i += 1) {
        selected.push(parseInt(contacts[i].value, 10));
      }
      setCreateAssessment({
        type: 'contacts',
        payload: selected,
      });
      setUserContacts(contacts);
    },
    [createAssessment.data.Assessment.contacts],
  );

  const finishAssessmentCreation = useCallback(() => {
    history.push(ROUTES.ADMIN + ADMIN_ROUTES.ASSESSMENTS);
  });

  const contactOptions = [];
  if (contactsList.length > 0) {
    contactsList.map((e) => contactOptions.push({ value: e.id, label: `${e.first_name} ${e.last_name} ( ${e.mobile_number} )` }));
  }

  const handleIsParamChange = (e) => {
    setIsParam(!isParam);
  };

  return (
    <CreateAssessmentComponent
      handleAssessmentNameChange={handleAssessmentNameChange}
      handleAssessmentDescriptionChange={handleAssessmentDescriptionChange}
      handleEasyProblemCountChange={handleEasyProblemCountChange}
      handleMediumProblemCountChange={handleMediumProblemCountChange}
      handleHardProblemCountChange={handleHardProblemCountChange}
      handleEasyMcqCountChange={handleEasyMcqCountChange}
      handleMediumMcqCountChange={handleMediumMcqCountChange}
      handleHardMcqCountChange={handleHardMcqCountChange}
      onCreateAssessmentSubmit={onCreateAssessmentSubmit}
      message={message}
      isSuccess={isSuccess}
      nameErrTxt={createAssessment.nameErrTxt}
      descriptionErrTxt={createAssessment.descriptionErrTxt}
      problemCountErrTxt={createAssessment.problemCountErrTxt}
      finishAssessmentCreation={finishAssessmentCreation}
      defaultAssessment={createAssessment}
      action='Create'
      selectedContacts={userContacts}
      contactOptions={contactOptions}
      handleSelectedContactsChange={handleSelectedContactsChange}
      contactsErrTxt={createAssessment.contactsErrTxt}
      handleSelectedTagsChange={handleSelectedTagsChange}
      mcqCountData={mcqCountData}
      allowCoding={allowCoding}
      allowMcq={allowMcq}
      isParam={isParam}
      handleIsParamChange={handleIsParamChange}
      tagsOptions={tagsOptions}
    />
  );
};

export default React.memo(CreateAssessmentContainer);
