import React, { useCallback, useEffect, useState, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import EditProfileComponent from 'modules/admin/editProfile/EditProfileComponent';
import { editProfileReducer } from 'modules/admin/editProfile/editProfileReducer';
import { validateProfileData } from 'modules/admin/editProfile/profileDataValidation';

import { getUserDetails } from 'utils';
import { putEditProfileApi } from './putEditProfileApi';

const EditProfileContainer = (props) => {
  const profileDetails = getUserDetails();
  const initialProfileState = {
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    firstNameErrTxt: '',
    lastNameErrTxt: '',
    emailErrTxt: '',
    mobileNumberErrTxt: '',
  };
  const history = useHistory();
  const [editProfileData, setEditProfileData] = useReducer(editProfileReducer, initialProfileState);
  const {
    editProfileVisible,
    setEditProfileVisible,
  } = props;

  const schemaEditProfile = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    email: yup.string().email().required('Email is requires'),
    mobile_number: yup.string().length(10).matches(/[1-9]{1}[0-9]{9}/).required('Mobile number is required'),
  });

  useEffect(() => {
    setEditProfileData({
      type: 'setProfileData',
      payload: {
        firstName: profileDetails.first_name,
        lastName: profileDetails.last_name,
        email: profileDetails.email,
        mobileNumber: profileDetails.mobile_number,
      },
    });
  }, []);

  const logout = () => {
    localStorage.clear();
    history.push('/');
  };

  const handleFirstNameChange = useCallback(
    (event) => {
      const firstName = event.target.value;
      setEditProfileData({
        type: 'firstNameErrTxt',
        payload: '',
      });
      setEditProfileData({
        type: 'firstName',
        payload: firstName,
      });
    },
    [editProfileData.firstName],
  );

  const handleLastNameChange = useCallback(
    (event) => {
      const lastName = event.target.value;
      setEditProfileData({
        type: 'lastNameErrTxt',
        payload: '',
      });
      setEditProfileData({
        type: 'lastName',
        payload: lastName,
      });
    },
    [editProfileData.lastName],
  );

  const handleEmailChange = useCallback(
    (event) => {
      const email = event.target.value.toLowerCase();
      setEditProfileData({
        type: 'emailErrTxt',
        payload: '',
      });
      setEditProfileData({
        type: 'email',
        payload: email,
      });
    },
    [editProfileData.email],
  );

  const handleMobileNumberChange = useCallback(
    (event) => {
      const mobileNumber = event.target.value;
      setEditProfileData({
        type: 'mobileNumberErrTxt',
        payload: '',
      });
      setEditProfileData({
        type: 'mobileNumber',
        payload: mobileNumber,
      });
    },
    [editProfileData.mobileNumber],
  );

  const handleEditCancelClick = useCallback(
    (event) => {
      setEditProfileData({
        type: 'setProfileData',
        payload: {
          firstName: profileDetails.first_name,
          lastName: profileDetails.last_name,
          email: profileDetails.email,
          mobileNumber: profileDetails.mobile_number,
        },
      });
      setEditProfileVisible(false);
    },
    [],
  );

  const handleOnClickEdit = useCallback(
    (event) => {
      const { firstName, lastName, email, mobileNumber } = editProfileData;
      const data = {
        id: profileDetails.id,
        first_name: firstName.trim(),
        last_name: lastName.trim(),
        mobile_number: mobileNumber,
        email,
      };
      schemaEditProfile.isValid(data).then(async (valid) => {
        if (!valid) {
          validateProfileData(schemaEditProfile, data, setEditProfileData);
        } else {
          const result = await putEditProfileApi(data);
          const { data: responseData, status } = result.result;
          if (status === 200) {
            const userDetails = responseData.data.user;
            const {
              first_name: userFirstName,
              last_name: userLastName,
              email: userEmail,
              mobile_number: userMobileNumber,
            } = userDetails;
            localStorage.setItem('userDetails', JSON.stringify(userDetails));
            setEditProfileData({
              type: 'setProfileData',
              payload: {
                firstName: userFirstName,
                lastName: userLastName,
                email: userEmail,
                mobileNumber: userMobileNumber,
              },
            });
            setEditProfileVisible(false);
            if (localStorage.getItem('uid') !== userEmail) {
              setTimeout(logout, 5000);
              return toast.success('Update Successful, Email updated, redirecting to login page');
            }
            return toast.success(responseData.message);
          }
        }
      });
    }, [editProfileData.firstName, editProfileData.lastName, editProfileData.email,
      editProfileData.mobileNumber],
  );

  return (
    editProfileVisible &&
    (
      <EditProfileComponent
        profileDetails={editProfileData}
        handleOnClickEdit={handleOnClickEdit}
        firstNameErrTxt={editProfileData.firstNameErrTxt}
        lastNameErrTxt={editProfileData.lastNameErrTxt}
        emailErrTxt={editProfileData.emailErrTxt}
        mobileNumberErrTxt={editProfileData.mobileNumberErrTxt}
        handleFirstNameChange={handleFirstNameChange}
        handleLastNameChange={handleLastNameChange}
        handleEmailChange={handleEmailChange}
        handleMobileNumberChange={handleMobileNumberChange}
        handleEditCancelClick={handleEditCancelClick}
      />
    )
  );
};
EditProfileContainer.propTypes = {
  editProfileVisible: PropTypes.bool.isRequired,
  setEditProfileVisible: PropTypes.func.isRequired,
};

export default React.memo(EditProfileContainer);
