import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Button } from 'core-components';
import './feedbackTile.scss';

const FeedbackTile = ({
  feedbackDetails,
}) => {
  return (
    <>
      <div className='feedback-content mt-3'>
        <div className='feedback-date-details d-flex'>
          <div className=''>
            {feedbackDetails.rating === 5 && <Icon name='sad' title='sad' className='feedback-icon icon-sad' />}
            {feedbackDetails.rating === 4 && <Icon name='sceptic' title='sceptic' className='feedback-icon icon-sceptic' />}
            {feedbackDetails.rating === 3 && <Icon name='smile' title='smile' className='feedback-icon icon-smile' />}
            {feedbackDetails.rating === 2 && <Icon name='happiness' title='happiness' className='feedback-icon icon-happiness' />}
            {feedbackDetails.rating === 1 && <Icon name='happy' title='happy' className='feedback-icon icon-happy' />}
          </div>
          <h6 className='my-2 mb-0 ml-1 card-feedback card-height scroll-y'>
            {feedbackDetails?.answer}
          </h6>
        </div>
        <div className='mt-1 feedback-date-details d-flex align-items-center justify-content-between font-weight-bold text-capitalize'>
          <span className='text-truncate name-width' title='Candidate Name'>{feedbackDetails.candidate_name}</span>
          <span className='text-truncate name-width' title='Drive Name'>{feedbackDetails.drive_name}</span>
        </div>
      </div>
    </>
  );
};

FeedbackTile.propTypes = {
  feedbackDetails: PropTypes.shape().isRequired,
};
export default FeedbackTile;
