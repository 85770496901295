import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import 'react-datepicker/dist/react-datepicker.css';
import './customDatePicker.scss';

const CustomDatePicker = ({
  value,
  onChange,
  minDate,
  errorMessage,
  isDisabled,
}) => {
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  return (
    <>
      <DatePicker
        selected={value}
        onChange={onChange}
        showTimeSelect
        timeFormat='HH:mm'
        timeIntervals={15}
        popperClassName='datepicker-popper-box'
        className={` ${errorMessage && 'is-invalid'} form-control `}
        minDate={minDate}
        wrapperClassName={`
        ${isDisabled && 'disable-date-picker'} 
        ${errorMessage && 'date-picker-error-wrap'} 
        datepicker-box position-relative  d-block`}
        filterTime={filterPassedTime}
        dateFormat='dd/MM/yyyy, HH:mm'
        disabled={isDisabled}
      />
      {errorMessage && <div className='text-danger errFontSize'>{errorMessage}</div>}
    </>
  );
};

CustomDatePicker.defaultProps = {
  minDate: new Date(),
  errorMessage: '',
  isDisabled: false,
};

CustomDatePicker.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.func,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default CustomDatePicker;
