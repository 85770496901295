import axios from 'axios';
import { SERVER_URL } from 'constants/appConstants';
import { post, get } from '../apiHelper';

export const createQuestionPostApi = (data) => {
  return post(`${SERVER_URL}admin/questions`, data);
};

export const getPresignedUrlApi = (imageFor) => {
  return get(`${SERVER_URL}admin/questions/presigned_url`, imageFor);
};

export const putImageS3 = ({ url, image }) => {
  return axios.put(url, image, {
    headers: {
      'Content-Type': 'image/jpeg',
    },
  });
};
