import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import CandidateFeedbackComponent from 'components/CandidateFeedbackComponent';
import { candidateFeedbackRequestAction } from 'actions/candidateFeedbackActions';
import { resetToken } from 'actions/candidateFormActions';

import local from 'utils/local';

import { ROUTES, CANDIDATE_ROUTES } from 'constants/routeConstants';
import { feedbackList } from 'constants/feedbackIconConstant';

function CandidateFeedbackContainer() {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  const token = local.getItem('authToken');

  const handleRatingClick = (num) => setRating(num);

  const handleCommentChange = useCallback((e) => {
    setComment(e.target.value);
  }, [comment]);

  const handleSubmit = () => {
    if (rating || comment) {
      dispatch(candidateFeedbackRequestAction({ rating, comment, token }));
    }
    dispatch(resetToken());
    history.replace(ROUTES.CANDIDATE + CANDIDATE_ROUTES.ENDPAGE);

    // exit full screen on feedback submit
    document.exitFullscreen();
  };

  return (
    <CandidateFeedbackComponent
      feedbackList={feedbackList}
      rating={rating}
      handleRatingClick={handleRatingClick}
      handleCommentChange={handleCommentChange}
      handleSubmit={handleSubmit}
    />
  );
}

export default React.memo(CandidateFeedbackContainer);
