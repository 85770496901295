import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import SideNavComponent from 'modules/superAdmin/sideNav/SideNavComponent';
import { setProfileModal } from 'redux/superAdmin/modalStates/action';
import { organizationListClear } from 'redux/superAdmin/organizationList/action';

const SideNavContainer = () => {
  const dispatch = useDispatch();
  const { profileModal } = useSelector((state) => state.superAdminModalStatusReducer);
  const handleProfileClick = (e) => {
    dispatch(setProfileModal(true));
  };

  const handleClearState = () => {
    dispatch(organizationListClear());
  };

  return (
    <SideNavComponent
      handleProfileClick={handleProfileClick}
      showProfileModal={profileModal}
      handleClearState={handleClearState}
    />
  );
};

export default React.memo(SideNavContainer);
