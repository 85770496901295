import React from 'react';
import { TfiFaceSad } from 'react-icons/tfi';
import { Link } from 'react-router-dom';

import './errorPage404.scss';

const ErrorPage404 = () => (
  <div className='page-not-found'>
    <span className='error'>404</span>
    <p className='error-subTitle'>Oops, page not found</p>
    <p className='error-messsage'>Sorry, but the requested page is not found, Please click on back.</p>
    <Link
      to='/admin/home'
      className='link'
    >
      Back
    </Link>
  </div>
);

export default ErrorPage404;
