import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './deleteConfirmModal.scss';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'core-components';

const DeleteConfirmModal = ({
  handleDelete,
  confirmationModal,
  toggleConfirmation,
  targetId,
  type,
  name,
}) => {
  const [showReadMore, setShowReadMore] = useState(false);

  const toggleReadMore = () => {
    setShowReadMore(!showReadMore);
  };

  useEffect(() => {
    if (confirmationModal) {
      if ((name.length + type.length) > 135) {
        setShowReadMore(true);
      }
    }
  }, [name, confirmationModal]);

  return (
    <>
      <Modal
        className='confirmation-modal'
        isOpen={confirmationModal}
        toggle={toggleConfirmation}
      >
        <ModalHeader
          className='modal-header'
        >
          Confirm Delete
        </ModalHeader>
        <ModalBody className='modal-body'>
          {showReadMore ? (
            <>
              <p className='mb-0'>
                Are you sure you wish to delete {type} &apos;{name.substring(0, 135)}...
              </p>
              <Button
                className='text-secondary px-0'
                color='link'
                onClick={toggleReadMore}
              >
                Read more
              </Button>
            </>
          ) : (
            <p>
              Are you sure you wish to delete {type} {name}?
            </p>
          )}
        </ModalBody>
        <ModalFooter className='border-0 modal-footer'>
          <Button
            color='secondary'
            outline
            onClick={toggleConfirmation}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => {
              handleDelete(targetId);
              toggleConfirmation();
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

DeleteConfirmModal.defaultProps = {
  targetId: 0,
  // incase of using normal delete modal
  // message will be shown as 'Are you sure you wish to delete this item?'
  type: '',
  name: 'this item',
};

DeleteConfirmModal.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  targetId: PropTypes.number,
  type: PropTypes.string,
  name: PropTypes.string,
};

export default React.memo(DeleteConfirmModal);
