import { INPUT_OUTPUT } from 'constants/actionConstants';

const { SET_OUTPUT, SET_INPUT, CLEAR, SET_OUTPUT_REQUEST, SET_TOKEN } = INPUT_OUTPUT;
export const setOutput = (output) => ({
  type: SET_OUTPUT,
  payload: output,
});

export const setInput = (input) => ({
  type: SET_INPUT,
  payload: input,
});

export const clearIO = () => ({
  type: CLEAR,
});

export const outputRequest = (data) => ({
  type: SET_OUTPUT_REQUEST,
  payload: data,
});

export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: token,
});
