/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FiClipboard, FiCheck } from 'react-icons/fi';

import {
  Spinner,
  Row,
  Button,
  Modal,
  ModalBody,
  Icon,
  ModalFooter,
  ModalHeader,
} from 'core-components';
import EditProfileContainer from 'modules/admin/editProfile/EditProfileContainer';
import { CloseButton } from 'shared-components';
import { setIsOpen } from 'redux/admin/modalStates/action';

import userImage from 'assets/images/user.svg';

import './userProfileComponent.scss';

const UserProfileComponent = (props) => {
  const {
    profileDetails,
    setChangePassVisible,
    editProfileVisible,
    setEditProfileVisible,
    toggle,
    showModal,
    handleLogout,
    confirmationModal,
    toggleConfirmation,
    remainingCredits,
  } = props;
  const { first_name: firstName, last_name: lastName, email, mobile_number: mobileNumber, auth_token: authToken, is_param_ai_allowed: isParamAiAllowed } = profileDetails;

  const [isCopied, setIsCopied] = useState(false);
  const dispatch = useDispatch();

  if (typeof email === 'undefined') {
    return <Spinner />;
  }
  async function copyTextToClipboard(text) {
    return ('clipboard' in navigator) ? navigator.clipboard.writeText(text) : document.execCommand('copy', true, text);
  }
  const handleCopyClick = () => {
    copyTextToClipboard(authToken)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 600);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <Modal
        className='confirmation-modal'
        isOpen={confirmationModal}
        toggle={toggleConfirmation}
      >
        <ModalHeader
          className='modal-header'
        >
          Logout
        </ModalHeader>
        <ModalBody className=' modal-body'>
          <p>Do you want to logout?</p>
        </ModalBody>
        <ModalFooter className='border-0 modal-footer'>
          <Button
            color='secondary'
            outline
            onClick={toggleConfirmation}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => {
              handleLogout();
              toggleConfirmation();
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        className='profile-modal'
        modalClassName='left_modal search-modal'
      >
        <div className='modal-header-wrap'>
          <Row>
            <h3 className='modal-heading ml-4'>Profile</h3>
            <CloseButton onClick={toggle} />
          </Row>
        </div>
        <ModalBody className='px-5 pb-5 pt-0 profile-modal-body scroll-y'>
          <div className='d-flex profile-div'>
            <img src={userImage} className='profile-icon ml-2' alt='Admin profile' />
            <div className='credit-points-wrapper d-flex align-items-center justify-content-end mr-3'>
              {
                (remainingCredits > 0) ? (
                  <div className='text-warning'>
                    <Icon name='star' />
                    {remainingCredits} Credits
                  </div>
                ) :
                  (
                    <div className='text-danger'>
                      <Icon name='star' />
                      0 Credits
                    </div>
                  )
              }
            </div>
          </div>
          <a target='_blank' href='/admin/support' className='d-flex justify-content-end text-light mr-3 mb-5'>Need credits? Click here</a>
          {!editProfileVisible ?
            (
              <ul className='profile-detail-list-wrap list-unstyled mb-0 pl-0'>
                <li className='profile-detail-list p-3 d-flex align-items-center justify-content-between'>
                  <label className='mb-0 candidate-name font-weight-bold'>
                    {`${firstName || ''} ${lastName || ''}`}
                  </label>
                  <Icon
                    name='pen'
                    className='ml-1 edit-pen'
                    onClick={() => {
                      setEditProfileVisible(true);
                      setChangePassVisible(false);
                    }}
                  />
                </li>
                <li className='profile-detail-list p-3 d-flex align-items-center justify-content-between'>
                  <label className='mb-0'>Email</label>
                  <span>{email}</span>
                </li>

                <li className='profile-detail-list p-3 d-flex align-items-center justify-content-between'>
                  <label className='mb-0'>Mobile Number</label>
                  <span>{mobileNumber}</span>
                </li>

                {isParamAiAllowed && (
                <li className='profile-detail-list p-3 d-flex align-items-center justify-content-between'>
                  <label className='mb-0'>ParamAI-Key</label>
                  <span className='ml-4 mr-4 scroll-x'>{authToken}</span>
                  <Button onClick={handleCopyClick}>{isCopied ? <FiCheck /> : <FiClipboard />}</Button>
                </li>
                )}

                <li className='profile-detail-list p-3 d-flex align-items-center justify-content-between'>
                  <label className='mb-0'>Tour</label>
                  <Button onClick={() => { dispatch(setIsOpen(true)); toggle(); }}>Start Tour</Button>
                </li>
              </ul>
            )
            :
            (
              <EditProfileContainer
                editProfileVisible={editProfileVisible}
                setEditProfileVisible={setEditProfileVisible}
              />
            )}
        </ModalBody>
        <ModalFooter className='justify-content-between pb-5 px-5 border-0'>
          <Button
            color='link'
            className='logout-btn p-3 m-0 text-decoration-none primary'
            onClick={() => { toggleConfirmation(); }}
          >
            Logout
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
UserProfileComponent.propTypes = {
  profileDetails: PropTypes.shape().isRequired,
  setChangePassVisible: PropTypes.func.isRequired,
  editProfileVisible: PropTypes.bool.isRequired,
  setEditProfileVisible: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  remainingCredits: PropTypes.number.isRequired,
};

export default React.memo(UserProfileComponent);
