import {
  ASSESSMENT_CANDIDATES_LIST,
  ASSESSMENT_CANDIDATE,
  ASSESSMENT_CANDIDATE_REPORTS,
} from 'redux/admin/assessmentCandidates/actionConstants';

export const assessementCandidatesListRequest = (payload) => ({
  type: ASSESSMENT_CANDIDATES_LIST.ASSESSMENT_CANDIDATES_LIST_REQUEST,
  payload,
});

export const assessementCandidatesListSuccess = (payload) => ({
  type: ASSESSMENT_CANDIDATES_LIST.ASSESSMENT_CANDIDATES_LIST_SUCCESS,
  payload,
});

export const assessmentCandidateReportRequest = (payload) => ({
  type: ASSESSMENT_CANDIDATE_REPORTS.ASSESSMENT_CANDIDATE_REPORT_REQUEST,
  payload,
});

export const assessmentCandidateReportSuccess = (payload) => ({
  type: ASSESSMENT_CANDIDATE_REPORTS.ASSESSMENT_CANDIDATE_REPORT_SUCCESS,
  payload,
});

export const candidateStatus = (payload) => ({
  type: ASSESSMENT_CANDIDATE.ASSESSMENT_CANDIDATE_ACTIVE_STATUS,
  payload,
});
