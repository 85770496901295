/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable indent */

import React, { useMemo } from 'react';
import Select from 'react-select';
import PropTypes, { shape, string } from 'prop-types';

import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Icon,
} from 'core-components';

import { getFullNameWithContact } from 'utils';

import CustomDatePicker from 'core-components/CustomDatePicker';
import RuleContainer from 'modules/admin/rule/RuleContainer';
import AddQuestionComponent from 'modules/admin/createDrive/CreateDriveComponent/addQuestionCmponent';
import AddProblemComponent from 'modules/admin/createDrive/CreateDriveComponent/addProblemComponent';

import { customStyleForContacts } from 'constants/stylesConstants';
import { CustomThemeMultiSelect } from 'constants/themesConstants';
import { DRIVE_SECTION_CONSTANTS } from 'modules/admin/createDrive/CreateDriveContainer/constants';

import 'modules/admin/createDrive/CreateDriveComponent/createDriveComponent.scss';

const {
  DRIVE_DETAILS_SECTION,
  ADD_QUESTIONS_SECTION,
  ADD_RULES_SECTION,
} = DRIVE_SECTION_CONSTANTS;

const EditDriveComponent = (props) => {
  const {
    handleDriveNameChange,
    handleDriveDescriptionChange,
    handleDriveStartChange,
    handleDriveEndChange,
    handleSelectedProblemChange,
    data,
    problemIsLoading,
    onDriveDetailsNext,
    onAddQuestionsBack,
    onAddQuestionsNext,
    onEditDriveSubmit,
    onAddNewRulesBack,
    message,
    editDrive,
    driveDetails,
    nameErrTxt,
    descriptionErrTxt,
    handleIsAssessmentChange,
    defaultProblems,
    finishDriveEdit,
    selectedProblems,
    contactsData,
    handleSelectedContactsChange,
    defaultContacts,
    contactsErrTxt,
    problemErrTxt,
    selectedQuestions,
    questions,
    isQuestionsLoading,
    handleSelectedQuestionChange,
    questionErrTxt,
    isContactsLoading,
    handleSearchInputChange,
    handleFilterTagsChange,
    handleFilterDifficultyChange,
    searchButtonClick,
    resetButtonClick,
    searchText,
    filterTags,
    filterDifficulty,
    handleFilterQuestionBox,
    showQuestionFilters,
    selectedQuestionsDetails,
    handleSearchProbInputChange,
    handleFilterProbTagsChange,
    handleFilterProbDifficultyChange,
    searchProbButtonClick,
    resetProbButtonClick,
    searchProbText,
    filterProbTags,
    filterProbDifficulty,
    handleFilterProbBox,
    showProbFilters,
    selectedProbDetails,
    allowMcq,
    allowCoding,
    tagsOptions,
    difficultyOptions,
    section,
    endTimeErrTxt,
    startTimeErrTxt,
    driveType,
    isLoading,
  } = props;

  if (isContactsLoading) {
    return <Spinner />;
  }

  const contactOptions = useMemo(() => (contactsData?.map(({
    id,
    first_name: firstName,
    last_name: lastName,
    mobile_number: mobileNumber,
    is_active: isActive,
  }) => ({
        value: id,
        label: getFullNameWithContact(firstName, lastName, mobileNumber),
        status: isActive,
      }))
  ), [contactsData]);

  const {
    name,
    description,
    start_time: startTime,
    end_time: endTime,
  } = editDrive.data.drive;

  return (
    <>
      <div className='d-flex'>
        <div className='new-drive-section d-flex flex-column scroll-y'>
          <div className='d-flex align-items-center mt-3 mb-5'>
            <div className='d-flex align-items-center mr-3'>
              <h2 className='section-heading font-weight-bold mb-0'>
                Update Drive
              </h2>
            </div>
            <div className='drive-title px-3'>
              <span className='font-weight-bold text-capitalize'>
                {name}
              </span>
            </div>
          </div>
          <Form className='new-drive-form d-flex flex-column scroll-y'>
            {section === DRIVE_DETAILS_SECTION && (
              <>
                <h5 className='section-sub-heading mb-4'>Drive Details</h5>
                <div>
                  <div className='drive-details-wrap d-flex justify-content-between'>
                    <div className='drive-details'>
                      <FormGroup>
                        <Label> Title </Label>
                        <Input
                          type='text'
                          defaultValue={name}
                          onChange={handleDriveNameChange}
                          invalid={nameErrTxt !== ''}
                        />
                        <FormFeedback>{nameErrTxt}</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label> Description </Label>
                        <Input
                          type='textarea'
                          rows='5'
                          defaultValue={description}
                          onChange={handleDriveDescriptionChange}
                          invalid={descriptionErrTxt !== ''}
                        />
                        <FormFeedback>{descriptionErrTxt}</FormFeedback>
                      </FormGroup>
                    </div>
                    <div className='drive-details'>
                      <FormGroup>
                        <Label>Start Date</Label>
                        <CustomDatePicker
                          value={startTime}
                          onChange={handleDriveStartChange}
                          errorMessage={startTimeErrTxt}
                          isDisabled={driveType === 'ongoing'}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label> End Date </Label>
                        <CustomDatePicker
                          value={endTime}
                          onChange={handleDriveEndChange}
                          errorMessage={endTimeErrTxt}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className=''>Contacts</Label>
                        <Select
                          className='text-light'
                          id='role'
                          onChange={handleSelectedContactsChange}
                          options={contactOptions}
                          value={defaultContacts}
                          theme={CustomThemeMultiSelect}
                          styles={customStyleForContacts}
                          isMulti
                          maxMenuHeight={200}
                        />
                        {contactsErrTxt && (
                          <div className='text-danger errFontSize'>{contactsErrTxt}</div>
                        )}
                        <div className='d-flex justify-content-end'>
                          <Button
                            color='primary'
                            onClick={() => {
                              onDriveDetailsNext();
                            }}
                            className='py-2 px-4 my-5'
                          >
                            Next
                          </Button>
                        </div>
                      </FormGroup>
                    </div>
                  </div>
                </div>
              </>
            )}
            {section === ADD_QUESTIONS_SECTION && (
              <>
                <h5 className='section-sub-heading mb-4'>
                  Add Questions
                </h5>
                {problemErrTxt && (
                  <div className='text-danger mb-1 font-weight-bold'>{problemErrTxt}</div>
                )}
                <div className='drive-details-wrap d-flex flex-1 scroll-y'>
                  <div className='drive-details d-flex flex-column scroll-y'>
                    {allowCoding && (
                      <AddProblemComponent
                        handleSelectedProblemChange={
                          handleSelectedProblemChange
                        }
                        data={data}
                        problemIsLoading={problemIsLoading}
                        handleSearchProbInputChange={
                          handleSearchProbInputChange
                        }
                        handleFilterProbTagsChange={handleFilterProbTagsChange}
                        handleFilterProbDifficultyChange={
                          handleFilterProbDifficultyChange
                        }
                        searchProbButtonClick={searchProbButtonClick}
                        resetProbButtonClick={resetProbButtonClick}
                        searchProbText={searchProbText}
                        filterProbTags={filterProbTags}
                        filterProbDifficulty={filterProbDifficulty}
                        handleFilterProbBox={handleFilterProbBox}
                        showProbFilters={showProbFilters}
                        selectedProbDetails={selectedProbDetails}
                        tagsOptions={tagsOptions}
                        difficultyOptions={difficultyOptions}
                      />
                    )}
                  </div>
                  <div className='drive-details d-flex flex-column scroll-y'>
                    {allowMcq && (
                      <AddQuestionComponent
                        questions={questions}
                        isQuestionsLoading={isQuestionsLoading}
                        handleSelectedQuestionChange={
                          handleSelectedQuestionChange
                        }
                        handleSearchInputChange={handleSearchInputChange}
                        handleFilterTagsChange={handleFilterTagsChange}
                        handleFilterDifficultyChange={
                          handleFilterDifficultyChange
                        }
                        searchButtonClick={searchButtonClick}
                        resetButtonClick={resetButtonClick}
                        searchText={searchText}
                        filterTags={filterTags}
                        filterDifficulty={filterDifficulty}
                        handleFilterQuestionBox={handleFilterQuestionBox}
                        showQuestionFilters={showQuestionFilters}
                        selectedQuestionsDetails={selectedQuestionsDetails}
                        tagsOptions={tagsOptions}
                        difficultyOptions={difficultyOptions}
                      />
                    )}
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-between'>
                  <Button
                    onClick={() => {
                      onAddQuestionsBack();
                    }}
                    color='secondary'
                    outline
                    className='back-button py-2 px-4'
                  >
                    <Icon
                      name='left'
                      className='back-page-arrow font-weight-bold align-middle'
                    />
                    Back
                  </Button>
                  <Button
                    color='primary'
                    onClick={() => {
                      onAddQuestionsNext();
                    }}
                    className='next-button py-2 px-4'
                  >
                    Next
                  </Button>
                </div>
              </>
            )}
            {section === ADD_RULES_SECTION && (
              <>
                <div className='new-drive-col scroll-y d-flex flex-column'>
                  <div className='new-drive-content-head'>
                    <label className='mb-3'>
                      Add New Rules to Drive
                    </label>
                  </div>
                  <RuleContainer driveId={driveDetails.drive.id} />
                </div>
                {driveDetails.isSuccess ? (
                  <div className='d-flex'>
                    <h6 className='text-success'>{message}</h6>
                    <Button
                      color='primary'
                      disabled={!driveDetails.isSuccess}
                      onClick={finishDriveEdit}
                      className='py-2 px-4 ml-auto'
                    >
                      Finish
                    </Button>
                  </div>
                ) : (
                  <>
                    <div className='d-flex'>
                      <Button
                        onClick={() => {
                          onAddNewRulesBack();
                        }}
                        color='secondary'
                        outline
                        className='back-button py-2 px-4 mr-3'
                      >
                        <Icon
                          name='left'
                          className='back-page-arrow font-weight-bold align-middle'
                        />
                        Back
                      </Button>
                      <Button
                        color='primary'
                        disabled={isLoading}
                        onClick={onEditDriveSubmit}
                        className='py-2 px-4 ml-auto'
                      >
                        Update Drive
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </Form>
        </div>
      </div>
    </>
  );
};

EditDriveComponent.propTypes = {
  driveType: PropTypes.func.isRequired,
  handleDriveNameChange: PropTypes.func.isRequired,
  handleDriveDescriptionChange: PropTypes.func.isRequired,
  handleDriveStartChange: PropTypes.func.isRequired,
  handleDriveEndChange: PropTypes.func.isRequired,
  handleSelectedProblemChange: PropTypes.func.isRequired,
  data: PropTypes.string.isRequired,
  editDrive: PropTypes.objectOf(PropTypes.object).isRequired,
  driveDetails: PropTypes.objectOf(PropTypes.object).isRequired,
  problemIsLoading: PropTypes.bool.isRequired,
  onDriveDetailsNext: PropTypes.func.isRequired,
  onAddQuestionsBack: PropTypes.func.isRequired,
  onAddQuestionsNext: PropTypes.func.isRequired,
  onEditDriveSubmit: PropTypes.func.isRequired,
  onAddNewRulesBack: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  nameErrTxt: PropTypes.string.isRequired,
  descriptionErrTxt: PropTypes.string.isRequired,
  handleIsAssessmentChange: PropTypes.func.isRequired,
  defaultProblems: PropTypes.objectOf(PropTypes.object).isRequired,
  finishDriveEdit: PropTypes.func.isRequired,
  selectedProblems: PropTypes.objectOf(PropTypes.object).isRequired,
  contactsData: PropTypes.objectOf(PropTypes.object).isRequired,
  handleSelectedContactsChange: PropTypes.func.isRequired,
  defaultContacts: PropTypes.objectOf(PropTypes.object).isRequired,
  contactsErrTxt: PropTypes.string.isRequired,
  problemErrTxt: PropTypes.string.isRequired,
  selectedQuestions: PropTypes.objectOf(PropTypes.object).isRequired,
  questions: PropTypes.objectOf(PropTypes.object).isRequired,
  isQuestionsLoading: PropTypes.bool.isRequired,
  handleSelectedQuestionChange: PropTypes.func.isRequired,
  questionErrTxt: PropTypes.string.isRequired,
  isContactsLoading: PropTypes.bool.isRequired,
  handleSearchInputChange: PropTypes.func.isRequired,
  handleFilterTagsChange: PropTypes.func.isRequired,
  handleFilterDifficultyChange: PropTypes.func.isRequired,
  searchButtonClick: PropTypes.func.isRequired,
  resetButtonClick: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  filterTags: PropTypes.objectOf(PropTypes.object).isRequired,
  filterDifficulty: PropTypes.string.isRequired,
  handleFilterQuestionBox: PropTypes.func.isRequired,
  showQuestionFilters: PropTypes.bool.isRequired,
  selectedQuestionsDetails: PropTypes.objectOf(PropTypes.object).isRequired,
  handleSearchProbInputChange: PropTypes.func.isRequired,
  handleFilterProbTagsChange: PropTypes.func.isRequired,
  handleFilterProbDifficultyChange: PropTypes.func.isRequired,
  searchProbButtonClick: PropTypes.func.isRequired,
  resetProbButtonClick: PropTypes.func.isRequired,
  searchProbText: PropTypes.string.isRequired,
  filterProbTags: PropTypes.objectOf(PropTypes.object).isRequired,
  filterProbDifficulty: PropTypes.string.isRequired,
  handleFilterProbBox: PropTypes.func.isRequired,
  showProbFilters: PropTypes.bool.isRequired,
  selectedProbDetails: PropTypes.objectOf(PropTypes.object).isRequired,
  allowCoding: PropTypes.bool.isRequired,
  allowMcq: PropTypes.bool.isRequired,
  tagsOptions: PropTypes.arrayOf(string).isRequired,
  difficultyOptions: PropTypes.arrayOf(shape).isRequired,
  section: PropTypes.number.isRequired,
  endTimeErrTxt: PropTypes.string.isRequired,
  startTimeErrTxt: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default React.memo(EditDriveComponent);
