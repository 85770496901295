/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes, { shape, string } from 'prop-types';

import {
  FormGroup,
  Label,
  Input,
  Spinner,
  Icon,
} from 'core-components';
import FilterComponent from 'modules/admin/createDrive/CreateDriveComponent/filterComponent';

import './createDriveComponent.scss';

const AddQuestionComponent = (props) => {
  const {
    questions,
    isQuestionsLoading,
    handleSelectedQuestionChange,
    handleSearchInputChange,
    handleFilterTagsChange,
    handleFilterDifficultyChange,
    searchButtonClick,
    resetButtonClick,
    searchText,
    filterTags,
    filterDifficulty,
    handleFilterQuestionBox,
    showQuestionFilters,
    selectedQuestionsDetails,
    tagsOptions,
    difficultyOptions,
  } = props;

  if (isQuestionsLoading) {
    return (
      <Spinner />
    );
  }
  const selectedQuestionsCount = selectedQuestionsDetails.length;
  const questionOptions = [];
  questions.map((e) => {
    const selectedQuestion = (selectedQuestionsDetails.find((x) => x.id === e.id));
    return (!selectedQuestion && questionOptions.push(e));
  });

  return (
    <div className='new-drive-col scroll-y d-flex flex-column flex-1'>
      <div className='new-drive-content-head d-flex align-items-center'>
        <label className='mb-0'>Add MCQs to Drive</label>
        <div className='d-flex ml-auto align-items-center'>
          <Icon name='search' onClick={handleFilterQuestionBox} className='font-weight-bold search-icon' />
          <span className='count-box px-3 py-2 ml-3'>
            {selectedQuestionsCount} Selected
          </span>
        </div>
      </div>
      <div className='new-drive-content-body d-flex my-3 scroll-y'>
        <div className='scroll-y d-flex flex-column flex-1'>
          {showQuestionFilters && (
            <FilterComponent
              handleSearchInputChange={handleSearchInputChange}
              handleFilterTagsChange={handleFilterTagsChange}
              handleFilterDifficultyChange={handleFilterDifficultyChange}
              searchButtonClick={searchButtonClick}
              resetButtonClick={resetButtonClick}
              searchText={searchText}
              filterTags={filterTags}
              filterDifficulty={filterDifficulty}
              tagsOptions={tagsOptions}
              difficultyOptions={difficultyOptions}
              searchPlaceholder='Questions'
            />
          )}
          <ol className='list-wrap list-unstyled mb-0 pl-0 d-flex flex-column'>
            {selectedQuestionsDetails.map((item) => {
              return (
                <li className='list-item p-3'>
                  <FormGroup check>
                    <div className='font-weight-normal d-flex align-items-center'>
                      <Input
                        type='checkbox'
                        name='check'
                        id='exampleCheck'
                        className='mt-0'
                        checked
                        value={item.id}
                        onChange={(e) => handleSelectedQuestionChange(e)}
                      />
                      <span>{item.description}</span>
                      <div className='difficulty-level mr-5 text-center'>
                        <span>{item.difficulty}</span>
                      </div>
                    </div>
                  </FormGroup>
                </li>
              );
            })}
          </ol>
          <ol className='list-wrap list-unstyled mb-0 pl-0 d-flex flex-column mt-1'>
            {questionOptions.map((item) => {
              return (
                <li className='list-item p-3'>
                  <FormGroup check>
                    <div className='font-weight-normal d-flex align-items-center'>
                      <Input
                        type='checkbox'
                        name='check'
                        id='exampleCheck'
                        checked={false}
                        className='mt-0'
                        value={item.id}
                        onChange={(e) => handleSelectedQuestionChange(e)}
                      />
                      <span className='overflow-auto'>{item.description}</span>
                      <div className='difficulty-level mr-5 flex-shrink-0 text-center'>
                        <span className='px-2'>{item.difficulty}</span>
                      </div>
                    </div>
                  </FormGroup>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </div>
  );
};

AddQuestionComponent.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  isQuestionsLoading: PropTypes.bool.isRequired,
  handleSelectedQuestionChange: PropTypes.func.isRequired,
  handleSearchInputChange: PropTypes.func.isRequired,
  handleFilterTagsChange: PropTypes.func.isRequired,
  handleFilterDifficultyChange: PropTypes.func.isRequired,
  searchButtonClick: PropTypes.func.isRequired,
  resetButtonClick: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  filterTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterDifficulty: PropTypes.string.isRequired,
  handleFilterQuestionBox: PropTypes.func.isRequired,
  showQuestionFilters: PropTypes.bool.isRequired,
  selectedQuestionsDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
  tagsOptions: PropTypes.arrayOf(string).isRequired,
  difficultyOptions: PropTypes.arrayOf(shape).isRequired,
};

export default React.memo(AddQuestionComponent);
