import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import QuestionFilterComponent from 'modules/admin/question/QuestionComponent/QuestionFilterComponent';

import {
  problemsListRequest, setProblemsDifficulty,
  setProblemsTag, resetProblemsFilters,
  setSearchedProblemsFilterAction,
} from 'redux/admin/problemsList/action';
import { tagsRequestAction } from 'redux/admin/tags/action';

import arrayToKeyValueHash from 'utils/arrayToKeyValueHash';
import { capitalize } from 'utils/capitalize';

const ProblemsFilterContainer = ({ onClickAddFilter, searchedProblemText }) => {
  const dispatch = useDispatch();

  const [tagsOptions, setTagsOptions] = useState([]);
  const [difficultyOptions, setDifficultyOptions] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  const { difficultyList, tagList } = useSelector((state) => state.tagsReducer);
  const {
    isProblemsLoading,
    problemSearchText,
    problemDifficulty,
    problemTag,
  } = useSelector((item) => item.problemsListReducer);

  useEffect(() => {
    dispatch(tagsRequestAction());
  }, []);

  useEffect(() => {
    setDifficultyOptions(arrayToKeyValueHash(difficultyList));
  }, [difficultyList]);

  useEffect(() => {
    setTagsOptions(arrayToKeyValueHash(tagList));
  }, [tagList]);

  const handleProblemsDifficultyChange = useCallback(
    (seletedOption) => {
      dispatch(setProblemsDifficulty(seletedOption));
    }, [problemDifficulty, problemTag, problemSearchText],
  );

  const handleProblemsTagsChange = useCallback(
    (seletedOption) => {
      dispatch(setProblemsTag(seletedOption));
    }, [problemTag],
  );

  const handleProblemsFilterClick = () => {
    if (!isProblemsLoading  && (problemDifficulty || problemTag.length !== 0)) {
      const filters = {
        searchedProblemDifficulty: problemDifficulty,
        searchedProblemTag: problemTag,
      };
      dispatch(setSearchedProblemsFilterAction(filters));
      dispatch(problemsListRequest({
        offset: 0,
        searchedProblemText,
        problems: [],
        searchedProblemDifficulty: problemDifficulty,
        searchedProblemTag: problemTag,
      }));
    }
    setIsFiltered(true);
  };

  const handleResetProblemsFilter = () => {
    if (problemDifficulty || problemTag.length !== 0) {
      dispatch(resetProblemsFilters());
      if (!isProblemsLoading && isFiltered) {
        dispatch(problemsListRequest({
          offset: 0,
          searchedProblemText,
          problems: [],
          searchedProblemDifficulty: '',
          searchedProblemTag: [],
        }));
        onClickAddFilter();
        setIsFiltered(false);
      }
    } else {
      onClickAddFilter();
      setIsFiltered(false);
    }
  };

  return (
    <QuestionFilterComponent
      tagsOptions={tagsOptions}
      difficultyOptions={difficultyOptions}
      selectedDifficultyKeyValue={problemDifficulty}
      selectedTagKeyValue={problemTag}
      handleSelectedDifficultyChange={handleProblemsDifficultyChange}
      handleSelectedTagsChange={handleProblemsTagsChange}
      handleFilterClick={handleProblemsFilterClick}
      handleResetQuestionFilter={handleResetProblemsFilter}
    />
  );
};

ProblemsFilterContainer.propTypes = {
  onClickAddFilter: PropTypes.func.isRequired,
  searchedProblemText: PropTypes.string.isRequired,
};

export default ProblemsFilterContainer;
