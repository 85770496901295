import { CONTACTS } from 'redux/admin/getContacts/actionContants';

export const contactsListRequestActioon = (payload) => ({
  type: CONTACTS.CONTACTS_REQUEST_ACTION,
  payload,
});

export const contactsListSuccessAction = (payload) => ({
  type: CONTACTS.CONTACTS_SUCCESS_ACTION,
  payload,
});

export const contactsListFailureAction = (payload) => ({
  type: CONTACTS.CONTACTS_FAILURE_ACTION,
  payload,
});

export const contactsListClear = (payload) => ({
  type: CONTACTS.CLEAR_CONTACTS,
});

export const setContactsOffset = (payload) => ({
  type: CONTACTS.SET_CONTACTS_LIMIT,
  payload,
});
