import React from 'react';
import PropTypes, { shape } from 'prop-types';
import Select from 'react-select';

import {
  Button,
  FormGroup,
} from 'core-components';

import { customStyle } from 'constants/stylesConstants';
import { CustomThemeMultiSelect, CustomThemeSelect } from 'constants/themesConstants';

import './questionComponent.scss';

const QuestionFilterComponent = ({
  tagsOptions,
  difficultyOptions,
  selectedDifficultyKeyValue,
  selectedTagKeyValue,
  handleSelectedDifficultyChange,
  handleSelectedTagsChange,
  handleFilterClick,
  handleResetQuestionFilter,
}) => {
  return (
    <div className='p-2 mb-3'>
      <div className='mr-3 d-flex flex-row'>
        <FormGroup className='w-50 mb-0'>
          <Select
            className='mr-3 text-light react-select-container'
            classNamePrefix='react-select'
            id='role'
            onChange={handleSelectedDifficultyChange}
            placeholder='Select Difficulty'
            options={difficultyOptions}
            theme={CustomThemeSelect}
            styles={customStyle}
            value={selectedDifficultyKeyValue}
          />
        </FormGroup>
        <FormGroup className='w-50 mb-0'>
          <Select
            className='mr-3 text-light react-select-container'
            classNamePrefix='react-select'
            id='role'
            onChange={handleSelectedTagsChange}
            options={tagsOptions}
            isMulti
            placeholder='Select Tags'
            theme={CustomThemeMultiSelect}
            styles={customStyle}
            value={selectedTagKeyValue}
          />
        </FormGroup>
        <div className='ml-auto d-flex flex-row'>
          <Button
            className='mr-1'
            color='secondary'
            outline
            onClick={handleFilterClick}
          >
            Filter
          </Button>
          <Button
            onClick={handleResetQuestionFilter}
            color='primary'
            outline
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
};

QuestionFilterComponent.propTypes = {
  tagsOptions: PropTypes.arrayOf(shape).isRequired,
  difficultyOptions: PropTypes.arrayOf(shape).isRequired,
  selectedDifficultyKeyValue: PropTypes.string.isRequired,
  selectedTagKeyValue: PropTypes.arrayOf(shape).isRequired,
  handleSelectedDifficultyChange: PropTypes.func.isRequired,
  handleSelectedTagsChange: PropTypes.func.isRequired,
  handleFilterClick: PropTypes.func.isRequired,
  handleResetQuestionFilter: PropTypes.func.isRequired,
};

export default React.memo(QuestionFilterComponent);
