import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Input,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Spinner,
  Toast,
  Icon,
} from 'core-components';
import Loading from 'shared-components/Loading';

import { capitalize } from 'utils/capitalize';

import './signupCard.scss';

const LoginCard = (props) => {
  const {
    handleFirstNameChange,
    handleLastNameChange,
    handleMobileNumberChange,
    handlePasswordConfirmationChange,
    handlePasswordChange,
    handleSubmit,
    signUpState,
    isError,
    isLoading,
    passwordVisibility,
    setPasswordVisibility,
    confirmPasswordVisibility,
    setConfirmPasswordVisibility,
    handleOrganizationNameChange,
  } = props;

  const { firstNameError, lastNameError, mobileNumberError, passwordError,
    passwordConfirmationError, organizationNameError } = signUpState;

  const { isLoading: signUpUserIsLoading } = useSelector((state) => state.signUpUser);

  const invalidUser = () => {
    if (isError) {
      return (
        <Toast className='bg-transparent p-2 border-0 shadow-0 text-center'>
          <p>Invalid Credentials</p>
        </Toast>
      );
    }
  };

  if (signUpUserIsLoading || signUpUserIsLoading === undefined) {
    return (
      <Loading />
    );
  }

  return (
    <div className='create-account-card-box position-absolute'>
      <h2 className='font-weight-normal'>Create Account</h2>
      <Form className='create-account-form d-flex flex-column h-100 scroll-y'>
        <FormGroup className=''>
          <Input
            className='shadow'
            id='exampleFirstName'
            type='text'
            placeholder='First Name'
            onChange={handleFirstNameChange}
            invalid={firstNameError !== ''}
          />
          <FormFeedback type='invalid' target='exampleFirstName'>
            {firstNameError}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Input
            className='shadow'
            id='exampleLastName'
            type='text'
            placeholder='Last Name'
            onChange={handleLastNameChange}
            invalid={lastNameError !== ''}
          />
          <FormFeedback type='invalid' target='exampleLastName'>
            {lastNameError}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Input
            className='shadow'
            id='exampleMobileNumber'
            type='number'
            placeholder='Mobile Number'
            onChange={handleMobileNumberChange}
            invalid={mobileNumberError !== ''}
          />
          <FormFeedback type='invalid' target='exampleMobileNumber'>
            {mobileNumberError}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <div className='password'>
            <Input
              type={passwordVisibility ? 'text' : 'password'}
              className='shadow'
              placeholder='Password'
              onChange={handlePasswordChange}
              invalid={passwordError !== ''}
              autoComplete='new-password'
              onPaste={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
            />
            <Button
              color='link'
              onClick={() => setPasswordVisibility(!passwordVisibility)}
              className={`password__show text-secondary text-decoration-none' + ${passwordError ? 'mr-3' : ''}`}
            >
              {passwordVisibility ? <Icon name='show-password' /> : <Icon name='hide-password' />}
            </Button>
          </div>
          {passwordError && (
            <span className='text-danger'>
              {capitalize(passwordError)}
            </span>
          )}
        </FormGroup>
        <FormGroup>
          <div className='password'>
            <Input
              type={confirmPasswordVisibility ? 'text' : 'password'}
              className='shadow'
              placeholder='Confirm Password'
              onChange={handlePasswordConfirmationChange}
              invalid={passwordConfirmationError !== ''}
              onPaste={(e) => e.preventDefault()}
              onCopy={(e) => e.preventDefault()}
              onCut={(e) => e.preventDefault()}
            />
            <Button
              color='link'
              onClick={() => setConfirmPasswordVisibility(!confirmPasswordVisibility)}
              className={`password__show text-secondary text-decoration-none' + ${passwordConfirmationError ? 'mr-3' : ''}`}
            >
              {confirmPasswordVisibility ? <Icon name='show-password' /> : <Icon name='hide-password' />}
            </Button>
          </div>
          {passwordConfirmationError && (
            <span className='text-danger'>
              {passwordConfirmationError}
            </span>
          )}
        </FormGroup>
        <FormGroup className=''>
          <Input
            className='shadow'
            id='exampleOrgName'
            type='text'
            placeholder='Organization Name'
            onChange={handleOrganizationNameChange}
            invalid={organizationNameError !== ''}
          />
          <FormFeedback type='invalid' target='exampleOrgName'>
            {organizationNameError}
          </FormFeedback>
        </FormGroup>
        <FormGroup className='mt-3 d-flex justify-content-center'>
          <Button
            className='w-75 border-0'
            onClick={handleSubmit}
            color='primary'
          >
            {isLoading ? <Spinner size='sm' color='light' /> : <>Signup</>}
          </Button>
        </FormGroup>
        {invalidUser()}
      </Form>
    </div>
  );
};

LoginCard.propTypes = {
  handleFirstNameChange: PropTypes.func.isRequired,
  handleLastNameChange: PropTypes.func.isRequired,
  handleMobileNumberChange: PropTypes.func.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  handlePasswordConfirmationChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  signUpState: PropTypes.objectOf(PropTypes.any).isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  passwordVisibility: PropTypes.bool.isRequired,
  setPasswordVisibility: PropTypes.func.isRequired,
  confirmPasswordVisibility: PropTypes.bool.isRequired,
  setConfirmPasswordVisibility: PropTypes.func.isRequired,
  handleOrganizationNameChange: PropTypes.func.isRequired,
};

export default React.memo(LoginCard);
