import produce from 'immer';

import { CANDIDATE_LIST } from './actionConstants';

const {
  CANDIDATE_LIST_REQUEST,
  CANDIDATE_LIST_SUCCESS,
  CANDIDATE_LIST_FAILURE,
  DRIVE_STATUS,
} = CANDIDATE_LIST;

export const initialState = {
  candidateIsLoading: false,
  driveId: 0,
  driveName: '',
  allCandidates: [],
  pageCount: 0,
  totalCandidates: 0,
  currentPageNumber: 0,
  candidateListFailureMessage: '',
  driveStatus: '',
};

const candidateListReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CANDIDATE_LIST_REQUEST:

      return {
        ...state,
        candidateIsLoading: true,
      };
    case CANDIDATE_LIST_SUCCESS:

      return {
        ...state,
        candidateIsLoading: false,
        driveName: payload.drive_name,
        allCandidates: [...payload.candidates],
        pageCount: payload.pages,
        totalCandidates: payload.total_candidates,
      };
    case CANDIDATE_LIST_FAILURE:
      return {
        ...state,
        candidateIsLoading: false,
        candidateListFailureMessage: payload,
      };
    case DRIVE_STATUS:
      return {
        ...state,
        driveStatus: payload,
      };
    default:
      return state;
  }
});

export default candidateListReducer;
