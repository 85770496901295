import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  problemsListAction,
  problemsListFailureAction,
  problemDeleteFailureAction,
  problemDeleteSuccessAction,
} from 'redux/admin/problemsList/action';
import { getProblems, deleteProblem } from 'redux/admin/problemsList/api';
import { PROBLEMS_LIST, PROBLEM_DELETE } from 'redux/admin/problemsList/actionContants';

import queryStringHelper from 'utils/queryStringHelper';

import { PROBLEMS_LIMIT } from 'constants/appConstants';

export function* getProblemsList(action) {
  const {
    offset,
    searchedProblemText,
    searchedProblemDifficulty,
    searchedProblemTag,
  } = action.payload;

  const getQueryString = queryStringHelper({
    offset,
    search: searchedProblemText,
    limit: PROBLEMS_LIMIT,
    difficulty_list: searchedProblemDifficulty ? searchedProblemDifficulty.value : '',
    tag_list: searchedProblemTag ? searchedProblemTag.map((item) => (item.value))?.toString() : '',
  });

  try {
    const response = yield call(getProblems, getQueryString);
    yield put(problemsListAction(response.data.data));
  } catch (error) {
    const { data } = error.response;
    yield put(problemsListFailureAction(data));
    toast.error(data.message);
  }
}

export function* deleteProblemFunction(action) {
  const { id } = action.payload;
  try {
    const response = yield call(deleteProblem, id);
    if (response.data.message === 'Problem deleted successfully.') {
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
    yield put(problemDeleteSuccessAction(id));
  } catch (error) {
    const { data } = error.response;
    yield put(problemDeleteFailureAction(data));
    toast.error(data.message);
  }
}

export default function* problemsListSaga() {
  yield takeEvery(PROBLEMS_LIST.PROBLEMS_LIST_REQUEST_ACTION, getProblemsList);
  yield takeEvery(PROBLEM_DELETE.PROBLEM_DELETE_REQUEST_ACTION, deleteProblemFunction);
}
