import { DOWNLOAD_MCQ } from './actionConstants';

const {
  DOWNLOAD_MCQ_REQUEST_ACTION,
  DOWNLOAD_MCQ_SUCCESS_ACTION,
  DOWNLOAD_MCQ_FAILURE_ACTION,
} = DOWNLOAD_MCQ;

export const downloadMcqRequestAction = (payload) => ({
  type: DOWNLOAD_MCQ_REQUEST_ACTION,
  payload,
});

export const downloadMcqSuccessAction = (payload) => ({
  type: DOWNLOAD_MCQ_SUCCESS_ACTION,
  payload,
});

export const downloadMcqFailureAction = (payload) => ({
  type: DOWNLOAD_MCQ_FAILURE_ACTION,
  payload,
});
