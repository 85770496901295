import produce from 'immer';

import { PROBLEM_STATEMENT } from 'constants/actionConstants';

const {
  SET_SELECTED_PROBLEMS,
  UPDATE_SUBMISSION_COUNT,
  SET_ERROR_MESSAGE,
  DETAILS_REQUEST,
  SET_ACTIVE_INDEX,
} = PROBLEM_STATEMENT;

export const initialState = {
  statement: [],
  activeIndex: 1,
  errorMessage: '',
  isError: false,
  isLoading: false,
  timeInMinutes: '',
  testCase: '',
};

const problemStatementReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_SELECTED_PROBLEMS:
      state.statement = payload.sort(() => Math.random() - 0.5);
      state.isLoading = false;
      break;
    case UPDATE_SUBMISSION_COUNT:
      state.statement = {
        submissionCount: payload,
      };
      state.isLoading = false;
      break;
    case SET_ERROR_MESSAGE:
      state.errorMessage = payload;
      state.isError = true;
      state.isLoading = false;
      break;
    case DETAILS_REQUEST:
      state.isLoading = true;
      break;
    case SET_ACTIVE_INDEX:
      state.activeIndex = payload;
      break;
    default:
      return state;
  }
});

export default problemStatementReducer;
