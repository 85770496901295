import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import EditorNavComponent from 'components/EditorNavComponent';
import EditorPadComponent from 'components/EditorPadComponent';
import InputOutputContainer from 'containers/InputOutputContainer';
import MCQComponent from 'components/MCQComponent';
import { Button, Icon, Modal, ModalBody, ModalFooter } from 'core-components';
import {
  fetchLanguages,
  setLanguageSelected,
  setCode,
  setFirstTime,
} from 'actions/languageAction';
import { submitRequest } from 'actions/codeSubmissionActions';
import RemainingTimeContainer from 'containers/RemainingTimeContainer';
import { resetToken } from 'actions/candidateFormActions';
import { backupCodeRequest } from 'actions/codeBackupAction';
import { selectMCQAnswerRequest } from 'actions/mcqQuestionsActions';
import { candidateTemplateRequestAction } from 'redux/admin/template/action';
import { finishTestRequest } from 'actions/finishTestActions';
import { timerRequest } from 'actions/timerActions';

import { options } from 'components/EditorPadComponent/editorConstants';
import { ROUTES, CANDIDATE_ROUTES } from 'constants/routeConstants';

import isEmpty from 'utils/isEmpty';
import local from 'utils/local';
import session from 'utils/session';
import updateSessionObject from 'utils/updateSessionObject';

import './editorContainer.scss';

function EditorContainer({
  code,
  languageSelected,
  setFirst,
  handleSaveDraft,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openOutputScreen, setOpenOutputScreen] = useState(false);

  const [isDropDownOpen, setDropDownOpen] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [alternateName, setAlternateName] = useState('');

  const { languages } = useSelector((state) => state.languageReducer);
  const { selectedSection } = useSelector(
    (state) => state.sectionStatusReducer,
  );
  const { questions, isLoading, selectedQuestion, selectedAnswers } =
    useSelector((state) => state.mcqQuestionsReducer);

  const { changed } = useSelector((state) => state.languageReducer);
  const { templateList } = useSelector((state) => state.templateReducer);

  const { submissionAllowed } = useSelector(
    (state) => state.codeSubmissionReducer,
  );
  const { statement, activeIndex } = useSelector(
    (state) => state.problemStatementReducer,
  );

  const { id: problemId } = statement[activeIndex - 1] || { problem_id: null };

  const { candidateId } = useSelector((state) => state.userDriveReducer);
  const {
    isCodeBackup,
    isSuccess: isCodeBackupSuccess,
    backupCode: { answer },
  } = useSelector((state) => state.codeBackupReducer);

  const driveID = local.getItem('driveID');

  const languageId = languageSelected.id;
  const tokenId = local.getItem('authToken');

  // (1) setting selected image url i.e. question or option
  // (2) setting alternate name for question and option image to be showed in pop up modal
  // (3) setting flag to show image in pop up modal
  const onImageClick = (selectedImageUrl, selectedAlternateName) => {
    setImageUrl(selectedImageUrl);
    setAlternateName(selectedAlternateName);
    setShowImageModal(!showImageModal);
  };

  const allowCoding = session.getItem('allowCoding');

  useEffect(() => {
    if (allowCoding) {
      const problemLanguages = session.getItem('problemLanguages');
      const probId = statement[0]?.id;
      if (problemLanguages) {
        const langId = problemLanguages[probId];
        dispatch(fetchLanguages(langId));
      } else {
        dispatch(fetchLanguages());
      }
    }
  }, [dispatch, statement]);

  const handleToggle = useCallback(
    () => setDropDownOpen(!isDropDownOpen),
    [isDropDownOpen],
  );

  const handleCode = useCallback(
    (value) => {
      dispatch(setCode(value));
      setFirst(false);
      dispatch(setFirstTime(false));
    },
    [dispatch],
  );

  const templateCode = () => {
    if (templateList.length) {
      const currentTemplate = templateList.find(
        (template) => template.language_id === languageId,
      );
      dispatch(setCode(currentTemplate?.code));
    }
  };

  useEffect(() => {
    if (!answer && templateList) {
      templateCode();
    }
  }, [answer, templateList]);

  useEffect(() => {
    setFirst(true);
    dispatch(setFirstTime(true));
    if (allowCoding && problemId && languageId) {
      dispatch(backupCodeRequest({ problemId, languageId }));
    }
  }, [problemId, languageId]);

  useEffect(() => {
    if (!isCodeBackup) {
      templateCode();
    }
  }, [problemId, languageId, isCodeBackup]);

  useEffect(() => {
    handleSaveDraft();
  }, [changed]);

  useEffect(() => {
    if (isCodeBackupSuccess && (answer || answer === '')) {
      dispatch(setCode(answer));
    }
  }, [isCodeBackupSuccess]);

  useEffect(() => {
    setOpenOutputScreen(false);
  }, [selectedSection, activeIndex]);

  useEffect(() => {
    if (problemId) {
      dispatch(candidateTemplateRequestAction({ problemId, driveID }));
    }
  }, [problemId]);

  const [limit, setlimit] = useState(false);
  const [modal, setModal] = useState(false);
  const [finishModal, setFinishModal] = useState(false);
  const toggleFinish = () => setFinishModal(!finishModal);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const toggleConfirmation = () => setConfirmationModal(!confirmationModal);

  // Condition - on language change save current languageId with it's problemId in
  // problemLanguages object and save it back to session storage
  const setLanguageForProblem = (langId) => {
    const problemLanguages = session.getItem('problemLanguages') || {};
    updateSessionObject(problemLanguages, problemId, langId);
  };

  useEffect(() => {
    dispatch(timerRequest());
  }, []);

  const handleClick = useCallback(
    (e) => {
      const langId = parseInt(e.currentTarget.getAttribute('id'), 10);
      const langObj = {
        id: langId,
        name: e.currentTarget.textContent,
        code: e.currentTarget.getAttribute('code'),
      };
      dispatch(setLanguageSelected(langObj));
      setLanguageForProblem(langId);
    },
    [dispatch, problemId, languageId],
  );

  let lang = useMemo(() => {
    if (isEmpty(languageSelected)) {
      return '';
    }
    return languageSelected.name.split(' ')[0]?.toLowerCase();
  }, [languageSelected]);

  if (lang === 'c++') {
    lang = 'cpp';
  }

  const editorDidMount = useCallback((editor) => {
    editor.onKeyDown((event) => {
      const { keyCode, ctrlKey, metaKey } = event;
      // enabled ctrl+v keyCode = 52
      // Disabled ctrl+x keyCode = 54, ctrl+c = keyCode = 33
      if (
        (metaKey || ctrlKey) &&
        (keyCode === 54 || keyCode === 33 || keyCode === 52)
      ) {
        event.preventDefault();
      }
    });
    editor.focus();
    editor.updateOptions({ contextmenu: false });
  }, []);

  const handleSubmit = useCallback(() => {
    if (submissionAllowed > 0) {
      if (code != null) {
        const obj = {
          code,
          languageId,
          problemId,
          submissionAllowed,
          candidateId,
          driveID,
        };
        setModal(true);
        handleSaveDraft();
        dispatch(submitRequest(obj));
      } else {
        // TODO handle error
      }
    } else {
      setlimit(!limit);
    }
  }, [code, languageId, problemId, submissionAllowed, candidateId]);

  const handleFinish = useCallback(() => {
    dispatch(resetToken());
    dispatch(finishTestRequest());
    history.replace(ROUTES.CANDIDATE + CANDIDATE_ROUTES.FEEDBACK);
  });

  if (local.getItem('isFinishedTest') === 'true') {
    history.replace(ROUTES.CANDIDATE + CANDIDATE_ROUTES.ENDPAGE);
  }

  const handleConfirmation = useCallback(() => {
    toggleConfirmation();
    handleSubmit();
  });

  if (submissionAllowed <= 0 && limit === false) {
    setlimit(true);
  } else if (submissionAllowed > 0 && limit === true) {
    setlimit(false);
  }

  const handleSelectedProblemChange = (e, questionId, isMultiSelect) => {
    let selectedMcq = [];
    const answerId = parseInt(e.target.value, 10);
    if (isMultiSelect) {
      if (selectedAnswers[questionId]) {
        if (e.target.checked) {
          selectedMcq = [...[answerId], ...selectedAnswers[questionId]];
        } else {
          const checkedArr = selectedAnswers[questionId].filter(
            (item) => item !== answerId,
          );
          selectedMcq = [...checkedArr];
        }
      } else {
        selectedMcq = [answerId];
      }
    } else {
      selectedMcq = [answerId];
    }
    dispatch(selectMCQAnswerRequest({ [questionId]: selectedMcq }));
  };

  const handleOnRunClick = () => {
    setOpenOutputScreen(!openOutputScreen);
  };

  return (
    <>
      <div className='test-section d-flex flex-column position-relative'>
        <div className='test-section-head d-flex justify-content-between align-items-center p-2'>
          <div className='dropdown-wrapper'>
            {selectedSection === 2 && (
              <EditorNavComponent
                isDropDownOpen={isDropDownOpen}
                handleToggle={handleToggle}
                languageSelected={languageSelected}
                languages={languages}
                handleClick={(e) => {
                  handleSaveDraft();
                  handleClick(e);
                }}
              />
            )}
          </div>
          <div
            className={`test-timing-wrap ${
              selectedSection === 2 ? 'ml-5' : 'mr-5'
            }`}
          >
            <RemainingTimeContainer
              handleSaveDraft={handleSaveDraft}
            />
          </div>
          <div className='finish-button-wrap'>
            <Button
              color='primary'
              className='py-2 px-4 finish-button text-uppercase'
              onClick={toggleFinish}
            >
              Finish
            </Button>
            <Modal
              modalClassName='finish-test-modal'
              isOpen={finishModal}
              toggle={toggleFinish}
            >
              <ModalBody className='finish-test-modal-body'>
                <p>Do you want to Finish the test?</p>
                <br />
                <p>
                  Make sure you have clicked on SUBMIT button for each question
                  atleast once.
                </p>
                <hr />
                <p>
                  Once you click on Finish you won&apos;t have access for the
                  test. You will be logout from the test.
                </p>
              </ModalBody>
              <ModalFooter className='finish-test-modal-footer'>
                <Button
                  color='secondary'
                  outline
                  className='px-2 py-2 text-decoration-none'
                  onClick={toggleFinish}
                >
                  <Icon name='close' className='mr-2' />
                  Cancel
                </Button>
                <Button
                  color='primary'
                  className='px-3 py-2'
                  onClick={handleFinish}
                >
                  Finish
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
        {selectedSection === 2 ? (
          !openOutputScreen && (
          <div className='code-wrapper d-flex flex-column flex-1 position-relative'>
            <EditorPadComponent
              id='editor'
              lang={lang}
              code={code}
              handleCode={handleCode}
              options={options}
              editorDidMount={editorDidMount}
            />

          </div>
          )
        ) : (
          <>
            {!isLoading && questions.length && (
              <MCQComponent
                handleSelectedProblemChange={handleSelectedProblemChange}
                checkedAns={selectedAnswers}
                questions={questions}
                selectedQuestion={selectedQuestion}
                showImageModal={showImageModal}
                onImageClick={onImageClick}
                imageUrl={imageUrl}
                alternateName={alternateName}
              />
            )}
          </>
        )}
        <InputOutputContainer
          handleSaveDraft={handleSaveDraft}
          setOpenOutputScreen={handleOnRunClick}
          openOutputScreen={openOutputScreen}
        />
      </div>
    </>
  );
}

EditorContainer.propTypes = {
  code: PropTypes.string.isRequired,
  languageSelected: PropTypes.objectOf.isRequired,
  setFirst: PropTypes.bool.isRequired,
  handleSaveDraft: PropTypes.func.isRequired,
};

export default React.memo(EditorContainer);
