// this is function which need will pass - if isPassed is null
export const getPassedValue = (isPassed) => {
  if (isPassed === null) {
    return '-';
  }
  return isPassed ? 'Passed' : 'Failed';
};

export const getScoreOrPercentage = (value) => {
  if (value === null || value === undefined) {
    return '-';
  }
  return value;
};

export const getSubmissionCount = (submissionCount) => {
  if (submissionCount === null || submissionCount === undefined) {
    return '-';
  }
  return submissionCount;
};
