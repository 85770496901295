import React, { useCallback, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ForgotPasswordComponent from 'modules/admin/forgotPassword/ForgotPasswordComponent';

import { reducer } from 'modules/admin/forgotPassword/ForgotPasswordContainer/reducer';
import { schema } from 'modules/admin/forgotPassword/ForgotPasswordContainer/schema';
import {
  adminForgotPasswordRequestAction,
  adminForgotPasswordResetAction,
} from 'redux/admin/forgotPassword/action';

const ForgotPasswordContainer = () => {
  const dispatch = useDispatch();
  const { isLoading, isError, errorMessage, successMessage }
    = useSelector((state) => state.forgotPasswordReducer);

  const initialUserState = {
    email: '',
    emailError: '',
  };
  const [userState, setUserState] = useReducer(reducer, initialUserState);

  useEffect(() => {
    return dispatch(adminForgotPasswordResetAction());
  }, []);

  const handleEmailChange = useCallback(
    (event) => {
      const email = event.target.value.toLowerCase();
      setUserState({
        type: 'email',
        payload: email,
      });
    }, [],
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const { email } = userState;
      schema.validate(
        {
          email,
        },
        { abortEarly: false },
      )
        .then((response) => {
          const data = {
            email,
          };
          dispatch(adminForgotPasswordRequestAction(data));
        })
        .catch((error) => {
          error.inner.forEach((e) => {
            switch (e.path) {
              case 'email':
                setUserState({
                  type: 'emailError',
                  payload: e.message,
                });
                break;
              default:
                break;
            }
          });
        });
    }, [userState],
  );

  return (
    <ForgotPasswordComponent
      handleSubmit={handleSubmit}
      handleEmailChange={handleEmailChange}
      isLoading={isLoading}
      email={userState.email}
      emailError={userState.emailError}
      errorMessage={errorMessage}
      successMessage={successMessage}
      isError={isError}
    />
  );
};

export default React.memo(ForgotPasswordContainer);
