import produce from 'immer';

import { ADMIN_SIGNUP } from 'redux/admin/signup/actionConstants';

export const initialState = {
  errorMessage: '',
  successMessage: '',
  isError: false,
  isLoading: false,
};

const adminSignupReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_SIGNUP.ADMIN_SIGNUP_SUCCESS_ACTION:
      return {
        ...state,
        isError: false,
        isLoading: false,
        successMessage: payload.message,
      };
    case ADMIN_SIGNUP.ADMIN_SIGNUP_FAILURE_ACTION:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: payload.message,
      };
    case ADMIN_SIGNUP.ADMIN_SIGNUP_REQUEST_ACTION:
      return {
        ...initialState,
        isLoading: true,
      };
    case ADMIN_SIGNUP.ADMIN_SIGNUP_RESET_ACTION:
      return { ...initialState };
    default:
      return state;
  }
});

export default adminSignupReducer;
