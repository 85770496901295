export const ROUTES = {
  HOME: '/',
  CANDIDATE: '/candidate',
  ADMIN: '/admin',
  REVIEWER: '/reviewer',
  ACCEPT_INVITATION: '/accept/invite/:token',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot_password',
  VERIFY_FORGOT_PASSWORD: '/verify_forgot_password/:token',
  SUPER_ADMIN: '/administrant',
  ERROR_PAGE_404: '/page_not_found',
  INVALID_TOKEN: '/invalid_token',
};

export const CANDIDATE_ROUTES = {
  OVERVIEW: '/overview/:id',
  CANDIDATE_DETAILS: '/:id/details',
  IDE: '/ide',
  RULES_AND_PROFILE: '/profile/create',
  ENDPAGE: '/endpage',
  FEEDBACK: '/feedback',
};

export const ADMIN_ROUTES = {
  HOME: '/home',
  PROBLEMS: '/problems',
  RULES: '/rules',
  EDIT_DRIVE: '/drive/:driveId/edit',
  CREATE_DRIVE: '/drive/create',
  SHOW_CANDIDATES: '/drive/:driveId/candidates',
  DRIVE_RESULT: '/drive/:driveId/result',
  INVITE_CANDIDATES: '/drive/:driveId/candidates/invite',
  PROBLEM_DETAILS: '/problem/:problemId/details',
  CREATE_PROBLEM: '/problem/create',
  EDIT_PROBLEM: '/problem/:problemId/edit',
  USER_PROFILE: '/profile',
  SNAPSHOTS: '/drive/:driveId/candidate/:candidateId/snapshots',
  CANDIDATE_REPORT: '/drive/:driveId/candidate/:token/report',
  INVITE_USER: '/users',
  SEARCH: '/search',
  SUPPORT_PAGE: '/support',
  ASSESSMENTS: '/assessments',
  CREATE_ASSESSMENT: '/assessment/create',
  EDIT_ASSESSMENT: '/assessment/:assessmentId/edit',
  ASSESSMENT_CANDIDATES: '/assessment/:assessmentId/candidates',
  ASSESSMENT_REPORTS: '/assessment/:assessmentId/reports',
  FEEDBACKS: '/feedbacks',
  QUESTIONS: '/questions',
  CREATE_QUESTION: '/question/create',
  EDIT_QUESTION: '/question/:questionId/edit',
};

export const SUPER_ADMIN_ROUTES = {
  ORGANIZATIONS: '/organizations',
  EDIT_ORGANIZATION: '/organization/:organizationId/edit',
};
