import { TEMPLATE } from 'redux/admin/template/actionContants';

const {
  TEMPLATE_FAILURE_ACTION, TEMPLATE_LIST_REQUEST_ACTION,
  TEMPLATE_SUCCESS_ACTION, ADD_NEW_TEMPLATE, RESET_TEMPLATE,
  CANDIDATE_TEMPLATE_LIST_REQUEST_ACTION } = TEMPLATE;

export const templateRequestAction = (payload) => ({
  type: TEMPLATE_LIST_REQUEST_ACTION,
  payload,
});

export const templateSuccessAction = (payload) => ({
  type: TEMPLATE_SUCCESS_ACTION,
  payload,
});

export const templateFailureAction = (payload) => ({
  type: TEMPLATE_FAILURE_ACTION,
  payload,
});

export const addNewTemplate = (payload) => ({
  type: ADD_NEW_TEMPLATE,
  payload,
});

export const resetTemplate = () => ({
  type: RESET_TEMPLATE,
});

export const candidateTemplateRequestAction = (payload) => ({
  type: CANDIDATE_TEMPLATE_LIST_REQUEST_ACTION,
  payload,
});
