import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CSVUploadComponent from 'components/CSVUploadComponent';

import {
  importFileAction,
  setFileName,
  setFile,
  resetFilePath,
  startFileImport,
  setFileNameError,
  downloadFileSuccessAction,
} from 'redux/admin/problemUpload/action';
import { schema } from './schema';

const ProblemBulkUploadContainer = () => {
  const dispatch = useDispatch();
  const { fileName, fileChoosen, filePath, isImporting,
    showError, fileNameError } = useSelector(
    (state) => state.problemBulkUploadReducer,
  );
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const onFileChoose = (e) => {
    if (e.target.files[0]) {
      dispatch(setFileNameError(''));
      dispatch(setFile(e.target.files[0]));
      dispatch(setFileName(e.target.files[0].name));
    }
  };

  const onModalClose = () => {
    dispatch(setFile({}));
    dispatch(setFileName(''));
    dispatch(setFileNameError(''));
    dispatch(resetFilePath());
    toggle();
  };

  const onFormSubmit = () => {
    schema
      .validate(
        {
          fileName,
        },
        { abortEarly: false },
      )
      .then(() => {
        const data = {
          fileName,
        };
        dispatch(resetFilePath());
        dispatch(
          importFileAction({ file: fileChoosen }),
        );
        dispatch(startFileImport());
        if (!isImporting) toggle();
      })
      .catch((error) => {
        error.inner.forEach((innerError) => {
          dispatch(setFileNameError(innerError.message));
        });
      });
  };

  const onDownload = () => {
    dispatch(downloadFileSuccessAction(filePath));
  };

  return (
    <CSVUploadComponent
      chooseFile={onFileChoose}
      fileSubmit={onFormSubmit}
      showError={showError}
      onModalClose={onModalClose}
      isImporting={isImporting}
      fileNameError={fileNameError}
      toggle={toggle}
      modal={modal}
      onDownload={onDownload}
      label='Import Problem'
      sampleCSVFile='sampleProblems.csv'
    />
  );
};

export default ProblemBulkUploadContainer;
