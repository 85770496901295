import produce from 'immer';

import { CREATE_DRIVE } from 'redux/admin/createDrive/actionConstants';

export const initialState = {
  data: {
    drive: {
      id: '',
      name: '',
      description: '',
      start_time: '',
      end_time: '',
      created_by_id: '',
      updated_by_id: '',
      organization_id: '',
      problems: '',
    },
  },
  ruleDriveCopyId: '',
  message: '',
  isSuccess: false,
};

const createDriveReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_DRIVE.CREATE_DRIVE_SUCCESS_ACTION:
      state.data = payload.data;
      state.drive_id = state.data.drive.id;
      state.message = payload.message;
      state.isLoading = false;
      state.isSuccess = true;
      break;
    case CREATE_DRIVE.CREATE_DRIVE_FAILURE_ACTION:
      state.data = payload.data;
      state.message = payload.message;
      state.isSuccess = false;
      break;
    case CREATE_DRIVE.RULE_DRIVE_COPY_ID_ACTION:
      return {
        ...state,
        ruleDriveCopyId: payload,
      };
    // TODO
    // case CREATE_DRIVE.CREATE_DRIVE_REQUEST_ACTION:
    //   need to add isLoading = true for loader
    //   break;
    case CREATE_DRIVE.CLEAR_STATE:
      return { ...initialState };
    default:
      return state;
  }
});

export default createDriveReducer;
