import { CANDIDATE_SECTION_CHANGE_ACTION } from 'constants/actionConstants';

export const initialState = {
  selectedSection: 0,
};

const sectionStatusReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CANDIDATE_SECTION_CHANGE_ACTION.CANDIDATE_SECTION_CHANGE_ACTION:
      return { ...state, selectedSection: payload };
    default:
      return state;
  }
};

export default sectionStatusReducer;
