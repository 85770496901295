import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import OrganizationEditComponent from 'modules/superAdmin/organizationEdit/OrganizationEditComponent';

import { reducer } from 'modules/superAdmin/organizationEdit/OrganizationEditContainer/reducer';
import { validateData } from 'modules/superAdmin/organizationEdit/dataValidation';
import {
  editOrganizationRequestAction,
  editOrganizationClearAction,
  getOrganizationRequestAction,
} from 'redux/superAdmin/editOrganization/action';
import { schema } from 'modules/superAdmin/organizationEdit/OrganizationEditContainer/schema';

import { ROUTES, SUPER_ADMIN_ROUTES } from 'constants/routeConstants';

const { SUPER_ADMIN } = ROUTES;
const { ORGANIZATIONS } = SUPER_ADMIN_ROUTES;

const OrganizationEditContainer = () => {
  const initialState = {
    data: {
      Organization: {
        name: '',
        description: '',
        credit: 0,
        contacts: '',
        isParamAssessment: false,
        isAllowCoding: false,
        isAllowMcq: false,
      },
    },
    nameErrTxt: '',
    descriptionErrTxt: '',
    contactsErrTxt: '',
    creditsErrorMsg:'',
    message: '',
  };
  const [editOrganization, setEditOrganization] = useReducer(
    reducer,
    initialState,
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const { organizationId } = useParams();

  const [isParam, setIsParam] = useState(false);
  const [allowCoding, setAllowCoding] = useState(false);
  const [allowMcq, setAllowMcq] = useState(false);
  const [addedCredits, setAddedCredits] = useState({
    credits: 0,
    error: '',
    isValid: true,
  });

  const { message, isSuccess, isLoading, organization } = useSelector(
    (state) => state.editOrganizationReducer,
  );

  const {
    user_name: ownerName,
    user_email: ownerEmail,
    user_contact: ownerContact,
  } = organization;

  useEffect(() => {
    if (organizationId && !isLoading) {
      dispatch(getOrganizationRequestAction(organizationId));
    }
  }, [organizationId]);

  useEffect(() => {
    setEditOrganization({
      type: 'Organization',
      payload: organization,
    });
    setIsParam(organization.is_param_ai_allowed);
    setAllowCoding(organization.allow_coding);
    setAllowMcq(organization.allow_mcq);
  }, [organization]);

  useEffect(() => {
    if (isSuccess) {
      history.push(SUPER_ADMIN + ORGANIZATIONS);
    }
  }, [isSuccess]);

  useEffect(() => {
    return () => {
      dispatch(editOrganizationClearAction());
      setEditOrganization({ type: 'clear' });
    };
  }, []);

  const handleOrganizationNameChange = useCallback(
    (event) => {
      const name = event.target.value;
      setEditOrganization({
        type: 'name',
        payload: name,
      });
    },
    [editOrganization.data.Organization.name],
  );

  const handleOrganizationDescriptionChange = useCallback(
    (event) => {
      const description = event.target.value;
      setEditOrganization({
        type: 'description',
        payload: description,
      });
    },
    [editOrganization.data.Organization.description],
  );

  const handleContactsChange = useCallback(
    (event) => {
      const contacts = event.target.value;
      setEditOrganization({
        type: 'contacts',
        payload: contacts,
      });
    },
    [editOrganization.data.Organization.contacts],
  );

  const handleCreditsChange = useCallback(
    (event) => {
      const count = event.target.value;
      setEditOrganization({
        type: 'credits',
        payload: count,
      });
    },
    [addedCredits.credits],
  );

  const onEditOrganizationSubmit = () => {
    const {
      data: {
        Organization: { name, description, contacts, credits },
      },
    } = editOrganization;

    setEditOrganization({
      type: 'resetCountErrs',
    });

    const validData = {
      name,
      description,
      contacts,
      credits,
    };

    schema.isValid(validData).then(async (valid) => {
      if (!valid) {
        validateData(schema, validData, setEditOrganization);
      } else {
        const putData = {
          show_name: name,
          description,
          credits,
          contact_number: contacts,
          is_param_ai_allowed: isParam,
          allow_coding: allowCoding,
          allow_mcq: allowMcq,
        };
        dispatch(editOrganizationRequestAction({ putData, organizationId }));
      }
    });
  };

  const finishOrganizationEdit = useCallback(() => {
    history.push(SUPER_ADMIN + ORGANIZATIONS);
  });

  const handleIsParamChange = () => {
    setIsParam(!isParam);
  };
  const handleAllowCoding = () => {
    setAllowCoding(!allowCoding);
  };
  const handleAllowMcq = () => {
    setAllowMcq(!allowMcq);
  };

  return (
    <OrganizationEditComponent
      handleOrganizationNameChange={handleOrganizationNameChange}
      handleOrganizationDescriptionChange={handleOrganizationDescriptionChange}
      onEditOrganizationSubmit={onEditOrganizationSubmit}
      message={message}
      isSuccess={isSuccess}
      nameErrTxt={editOrganization.nameErrTxt}
      descriptionErrTxt={editOrganization.descriptionErrTxt}
      finishOrganizationEdit={finishOrganizationEdit}
      action='Update'
      handleContactsChange={handleContactsChange}
      isLoading={isLoading}
      contactsErrTxt={editOrganization.contactsErrTxt}
      handleCreditsChange={handleCreditsChange}
      creditsErrorMsg={editOrganization.creditsErrorMsg}
      isValidCredits={addedCredits.isValid}
      allowCoding={allowCoding}
      allowMcq={allowMcq}
      isParam={isParam}
      handleIsParamChange={handleIsParamChange}
      handleAllowMcq={handleAllowMcq}
      handleAllowCoding={handleAllowCoding}
      defaultOrganization={editOrganization}
      ownerName={ownerName}
      email={ownerEmail}
      contactNumber={ownerContact}
    />
  );
};

export default React.memo(OrganizationEditContainer);
