import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getStatement } from 'apis/problemStatementApi';
import {
  setSelectedProblems,
  statementActionFailed,
} from 'actions/problemStatementActions';
import { setSubmissionAllowed } from 'actions/codeSubmissionActions';

import local from 'utils/local';

import { PROBLEM_STATEMENT } from 'constants/actionConstants';

// Problem statememt Saga
export function* statementSaga(action) {
  try {
    const response = yield call(getStatement, action.payload.driveId);
    yield put(setSelectedProblems(response.data.data));
    local.setItem('problemId', response.data.data[0].id);
    local.setItem('subCount', response.data.data[0].submission_count);
    yield put(setSubmissionAllowed(response.data.data[0].submission_count));
  } catch (error) {
    const { message } = error.response.data;

    yield put(statementActionFailed(message));
    toast.error(message);
  }
}

// watcherSaga
export default function* statementRequestSaga() {
  yield takeLatest(PROBLEM_STATEMENT.DETAILS_REQUEST, statementSaga);
}
