import produce from 'immer';
import { initialState } from './index';

export const reducer = produce((state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'name':
      state.data.drive.name = payload;
      break;
    case 'description':
      state.data.drive.description = payload;
      break;
    case 'start_time':
      state.data.drive.start_time = payload;
      break;
    case 'end_time':
      state.data.drive.end_time = payload;
      break;
    case 'is_assessment':
      state.data.drive.is_assessment = payload;
      break;
    case 'problem':
      state.currentProblems = payload;
      break;
    case 'question':
      state.currentQuestions = payload;
      break;
    case 'contacts':
      state.contacts = payload;
      break;
    case 'nameErrTxt':
      state.nameErrTxt = payload;
      break;
    case 'descriptionErrTxt':
      state.descriptionErrTxt = payload;
      break;
    case 'startTimeErrTxt':
      state.startTimeErrTxt = payload;
      break;
    case 'endTimeErrTxt':
      state.endTimeErrTxt = payload;
      break;
    case 'problemErrTxt':
      state.problemErrTxt = payload;
      break;
    case 'questionErrTxt':
      state.questionErrTxt = payload;
      break;
    case 'contactsErrTxt':
      state.contactsErrTxt = payload;
      break;
    case 'resetCurrentProblems':
      state.currentProblems = [];
      break;
    case 'resetCurrentContacts':
      state.contacts = [];
      break;
    case 'resetDriveData':
      state.problemErrTxt = '';
      state.data.drive.name = '';
      state.data.drive.description = '';
      state.data.drive.start_time = '';
      state.data.drive.end_time = '';
      state.currentProblems = '';
      state.nameErrTxt = '';
      state.descriptionErrTxt = '';
      state.startTimeErrTxt = '';
      state.endTimeErrTxt = '';
      state.contacts = [];
      state.questionErrTxt = '';
      state.currentQuestions = [];
      break;
    case 'drive':
      state.data.drive.name = payload.name;
      state.data.drive.description = payload.description;
      state.data.drive.start_time = payload.start_time;
      state.data.drive.end_time = payload.end_time;
      state.currentProblems = payload.problem;
      state.contacts = payload.contacts;
      state.currentQuestions = payload.questions;
      break;
    case 'clear':
      return { ...initialState };
    default:
      return state;
  }
  return state;
});
