import produce from 'immer';

export const initialState = {
  email: '',
  users: [],
  successMessage: '',
  failureMessage: '',
  emailsError: '',
  role: '',
  roleErr: '',
  roles: [],
  totalUsers: 0,
};

const reducer = produce((state = initialState, action = {}) => {
  let changedUser;
  const { type, payload } = action;
  switch (type) {
    case 'EMAILS_SENT_SUCCESS': {
      state.email = '';
      state.emailsError = '';
      state.role = '';
      state.roleErr = '';
      state.successMessage = payload.message;
      state.failureMessage = '';
      break;
    }
    case 'EMAILS_SENT_FAILURE':
      state.failureMessage = payload;
      break;
    case 'USER_EXIST_FAILURE':
      state.successMessage = '';
      state.failureMessage = payload;
      break;
    case 'INVALID_EMAIL':
      state.successMessage = '';
      state.failureMessage = '';
      state.emailsError = payload;
      break;
    case 'VALID_ROLE':
      state.role = payload;
      state.roleErr = '';
      break;
    case 'VALID_EMAIL':
      state.email = payload;
      state.emailsError = '';
      break;
    case 'SET_EMAIL_ERROR':
      state.emailsError = payload;
      break;
    case 'SET_USERS':
      state.users = payload;
      break;
    case 'SET_USER': {
      const userList = [...state.users];
      userList.splice(0, 0, payload.userData);
      return {
        ...state,
        users: [...userList],
      };
    }
    case 'SET_USERS_COUNT':
      state.totalUsers = payload;
      break;
    case 'UPDATE_USER_STATUS': {
      const updatedArray = [...state.users];
      // it will return index of the user that need to update
      const index = state.users.findIndex((value) => {
        if (value.id === payload.updatedUsers.id) return value;
        return 0;
      });
      // this will remove updated user from array
      updatedArray.splice(index, 1);
      // this will add updated user at the first inactive user index position
      updatedArray.splice(
        payload.status ? 0 : payload.inactiveUser - 1,
        0,
        payload.updatedUsers,
      );
      return {
        ...state,
        users: [...updatedArray],
      };
    }
    case 'SET_ROLE':
      state.role = payload;
      break;
    case 'SET_ROLE_ERROR':
      state.roleErr = payload;
      break;
    case 'SET_ROLES':
      state.roles = payload;
      break;
    default:
      return state;
  }
});

export default reducer;
