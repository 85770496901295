import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Icon,
} from 'core-components/index';
import './toggledropdown.scss';

const ToggleDropdown = ({ iconName, className, children, text, direction }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <>
      {' '}
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className={`${iconName ? 'menu-dropdown' : 'filter-menu'} ${className}`}
        direction={direction}
        onClick={(e) => e.stopPropagation()}
      >
        <DropdownToggle
          className={`border-0 text-decoration-none d-flex ${!iconName && 'filter-dropdown px-5 py-1 '}`}
          // color='link'
        >
          { iconName ? <Icon name={iconName} /> : (<span color='filter-text'> {text} </span>) }
        </DropdownToggle>
        <DropdownMenu
          className={`m-0 ${iconName ? 'toggle-dropdown-menu' : 'filter-toggle-dropdown-menu'}`}
          right
          container='body'
        >
          {children}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

ToggleDropdown.defaultProps = {
  text: '',
  direction:'left',
};

ToggleDropdown.propTypes = {
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  text: PropTypes.string,
  direction: PropTypes.string,
};

export default ToggleDropdown;
