import { SERVER_URL } from 'constants/appConstants';
import { get, assessmentGet } from 'redux/admin/apiHelper';

export const downloadResultApi = (driveId) => {
  return get(`${SERVER_URL}admin/drives/${driveId}/results/csv_result.csv`);
};

export const downloadAssessmentResultApi = (assessmentId) => {
  return assessmentGet(`${SERVER_URL}admin/assessments/${assessmentId}/results`);
};

export const downloadAbsentCandidatesApi = (driveId) => {
  return get(`${SERVER_URL}admin/drives/${driveId}/results/absent_candidates.csv`);
};
