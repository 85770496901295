import React from 'react';
import PropTypes from 'prop-types';
import Editor from '@monaco-editor/react';

import './editorPadComponent.scss';

function EditorPadComponent({
  lang,
  code,
  handleCode,
  options,
  editorDidMount,
  height,
}) {
  return (
    <div className='code-editor-wrapper flex-1 px-2'>
      <Editor
        id='editor'
        className='custom-style'
        language={lang}
        height={height}
        value={code}
        onChange={handleCode}
        options={options}
        onMount={editorDidMount}
        theme='vs-dark'
      />
    </div>
  );
}
EditorPadComponent.defaultProps = {
  height: '100%',
};
EditorPadComponent.propTypes = {
  lang: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  handleCode: PropTypes.func.isRequired,
  options: PropTypes.shape().isRequired,
  editorDidMount: PropTypes.func.isRequired,
  height: PropTypes.string,
};

export default React.memo(EditorPadComponent);
