import { ORGANIZATION_LIST, ADD_CREDITS } from 'redux/superAdmin/organizationList/actionContants';

export const organizationListRequest = (payload) => ({
  type: ORGANIZATION_LIST.ORGANIZATION_LIST_REQUEST_ACTION,
  payload,
});

export const organizationListAction = (payload) => ({
  type: ORGANIZATION_LIST.ORGANIZATION_LIST_SUCCESS_ACTION,
  payload,
});

export const organizationListFailureAction = (payload) => ({
  type: ORGANIZATION_LIST.ORGANIZATION_LIST_FAILURE_ACTION,
  payload,
});

export const organizationListClear = (payload) => ({
  type: ORGANIZATION_LIST.CLEAR_ORGANIZATION,
});

export const setOrganizationOffset = (payload) => ({
  type: ORGANIZATION_LIST.SET_ORGANIZATION_OFFSET,
  payload,
});
