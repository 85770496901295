import { call, put, takeEvery } from 'redux-saga/effects';
import {
  feedbackFailureAction, feedbackSuccessAction,
  feedbackDeleteSuccessAction, feedbackDeleteFailureAction,
} from 'redux/admin/feedbacks/action';
import { getFeedbacksGetApi, destroyFeedbacksDeleteApi } from 'redux/admin/feedbacks/api';
import { FEEDBACKS } from 'redux/admin/feedbacks/actionContants';
import queryStringHelper from 'utils/queryStringHelper';
import { FEEDBACKS_LIMIT } from 'constants/appConstants';
import { toast } from 'react-toastify';

export function* getFeedbacksList(action) {
  const { offset } = action.payload;
  const getQueryString = queryStringHelper({
    offset,
    limit: FEEDBACKS_LIMIT,
  });
  try {
    const response = yield call(getFeedbacksGetApi, getQueryString);
    yield put(feedbackSuccessAction(response.data.data));
  } catch (error) {
    yield put(feedbackFailureAction(error));
  }
}

export function* deleteFeedbackFunction(action) {
  const { id } = action.payload;
  try {
    const response = yield call(destroyFeedbacksDeleteApi, id);
    if (response.data.message === 'Feedback deleted successfully.') {
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
    yield put(feedbackDeleteSuccessAction(id));
  } catch (error) {
    const { data } = error.response;
    yield put(feedbackDeleteFailureAction(data));
    toast.error(data.message);
  }
}

export default function* feedbacksListSaga() {
  yield takeEvery(FEEDBACKS.FEEDBACKS_REQUEST_ACTION, getFeedbacksList);
  yield takeEvery(FEEDBACKS.FEEDBACKS_DELETE_REQUEST_ACTION, deleteFeedbackFunction);
}
