export const customStyle = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
  }),
  input: (base, state) => ({
    ...base,
    color: '#ffffff',
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 180,
  }),
};

export const customStyleForContacts = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: 'none',
  }),
  input: (base, state) => ({
    ...base,
    color: '#ffffff',
  }),
  multiValueLabel: (base, { data }) => ({
    ...base,
    backgroundColor: !data.status ? 'grey' : 'hsl(0, 0%, 90%)',
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 180,
  }),
};
