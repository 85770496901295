import { call, put, takeEvery } from 'redux-saga/effects';
import {
  templateFailureAction, templateSuccessAction,
} from 'redux/admin/template/action';
import { getTemplateListApi } from 'redux/admin/template/api';
import { TEMPLATE } from 'redux/admin/template/actionContants';

const { TEMPLATE_LIST_REQUEST_ACTION } = TEMPLATE;

export function* getTemplates(action) {
  try {
    const response = yield call(getTemplateListApi, action.payload);
    yield put(templateSuccessAction(response.data.data.templates));
  } catch (error) {
    yield put(templateFailureAction(error));
  }
}

export default function* getTemplateSaga() {
  yield takeEvery(TEMPLATE_LIST_REQUEST_ACTION, getTemplates);
}
