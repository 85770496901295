/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React from 'react';

import {
  Button,
  Spinner,
} from 'core-components';
import {
  SearchBox,
  DeleteConfirmModal,
  CardComponent,
  DriveTile,
} from 'shared-components';
import Icon from 'core-components/Icon';

import 'modules/admin/problemsList/ProblemsListComponent/problemListComponent.scss';

const AssessmentsListComponent = ({
  assessmentsLoading,
  handleAddAssessmentClick,
  assessments,
  onClickEdit,
  onClickResult,
  handleOnChange,
  handleSearchClick,
  handleAssessmentSearchClearClick,
  totalAssessments,
  confirmationModal,
  toggleConfirmation,
  targetId,
  assessmentName,
  handleDeleteClick,
  onClickDelete,
  onClickCandidates,
  lastBookElementRef,
  searchText,
}) => {
  return (
    <>
      <DeleteConfirmModal
        confirmationModal={confirmationModal}
        toggleConfirmation={toggleConfirmation}
        handleDelete={onClickDelete}
        targetId={targetId}
        type='assessment'
        name={assessmentName}
      />
      <div className='problem-bank-section d-flex flex-column flex-1 scroll-y'>
        <div className='problem-bank-section-head d-flex align-items-center px-3 mb-5'>
          <h2 className='section-heading font-weight-bold mb-0'>
            Assessments ({totalAssessments})
          </h2>
          <div className='d-flex align-items-center ml-auto'>
            <SearchBox
              placeholder='Search Assessment'
              handleOnChange={handleOnChange}
              handleSearchClick={handleSearchClick}
              handleCloseClick={handleAssessmentSearchClearClick}
              value={searchText}
              className='mr-3'
            />
            <Button
              color='primary'
              className='ml-3'
              onClick={handleAddAssessmentClick}
              title='Add Assessment'
            >
              <Icon id='plus' name='plus' title='Add Assessment' />
            </Button>
          </div>
        </div>
        {assessments.length === 0 ? 'Assessments Not Found' : null}
        <div className='problem-list-wrap py-3 scroll-y'>
          <ul className='problem-list list-unstyled mb-0 px-3'>
            {assessments.map((item) => {
              return (
                <li ref={lastBookElementRef} key={item.id}>
                  <CardComponent>
                    <DriveTile
                      driveDetails={item}
                      onClickCandidates={onClickCandidates}
                      onClickResult={onClickResult}
                      onClickEdit={onClickEdit}
                      confirmationModal={confirmationModal}
                      toggleConfirmation={toggleConfirmation}
                      targetId={targetId}
                      handleDeleteClick={handleDeleteClick}
                      editTypeTitle='Assessment'
                    />
                  </CardComponent>
                </li>
              );
            })}
            {assessmentsLoading && <Spinner className='loader' />}
          </ul>
        </div>
      </div>
    </>
  );
};

AssessmentsListComponent.defaultProps = {
  targetId: 0,
};

AssessmentsListComponent.propTypes = {
  assessmentsLoading: PropTypes.bool.isRequired,
  handleAddAssessmentClick: PropTypes.func.isRequired,
  assessments: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickResult: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleSearchClick: PropTypes.func.isRequired,
  handleAssessmentSearchClearClick: PropTypes.func.isRequired,
  totalAssessments: PropTypes.number.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  targetId: PropTypes.number,
  assessmentName: PropTypes.string.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickCandidates: PropTypes.func.isRequired,
  lastBookElementRef: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
};

export default React.memo(AssessmentsListComponent);
