import produce from 'immer';
import { CONTACTS } from 'redux/admin/getContacts/actionContants';

export const initialState = {
  contactsList: [],
  isContactsSuccess: false,
  isContactsLoading: false,
  isContactsError: false,
};

const getContactsReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CONTACTS.CONTACTS_REQUEST_ACTION:
      state.isContactsLoading = true;
      state.contactsList = payload.contactsList;
      break;
    case CONTACTS.CONTACTS_SUCCESS_ACTION:
      state.isContactsSuccess = true;
      state.isContactsLoading = false;
      state.contactsList = [...payload.contacts];
      break;
    case CONTACTS.CONTACTS_FAILURE_ACTION:
      state.isContactsError = true;
      state.isContactsLoading = false;
      break;
    case CONTACTS.CLEAR_CONTACTS:
      return { ...initialState };
    case CONTACTS.SET_CONTACTS_LIMIT:
      return { ...state, offset: payload };
    default:
      return state;
  }
});
export default getContactsReducer;
