import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { SiteLogo } from 'shared-components';
import ResetPasswordCard from './ResetPassword/resetPasswordCard';
import 'modules/admin/login/LoginComponent/loginLayout.scss';

const VerifyForgotPasswordComponent = (props) => {
  const {
    handlePasswordConfirmationChange,
    handlePasswordChange,
    handleSubmit,
    handleCopy,
    handlePaste,
    resetPasswordState,
    isError,
    isLoading,
    errorMessage,
    successMessage,
  } = props;
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false);

  return (
    <section className='login-section'>
      <div className='login-wrapper position-relative h-100'>
        <div className='spark-code-header py-4'>
          <div className='container'>
            <SiteLogo />
          </div>
        </div>
        <ResetPasswordCard
          handlePasswordConfirmationChange={handlePasswordConfirmationChange}
          handlePasswordChange={handlePasswordChange}
          handleSubmit={handleSubmit}
          handleCopy={handleCopy}
          handlePaste={handlePaste}
          resetPasswordState={resetPasswordState}
          isError={isError}
          isLoading={isLoading}
          errorMessage={errorMessage}
          successMessage={successMessage}
          passwordVisibility={passwordVisibility}
          setPasswordVisibility={setPasswordVisibility}
          confirmPasswordVisibility={confirmPasswordVisibility}
          setConfirmPasswordVisibility={setConfirmPasswordVisibility}
        />
      </div>
    </section>
  );
};

VerifyForgotPasswordComponent.propTypes = {
  handlePasswordChange: PropTypes.func.isRequired,
  handlePasswordConfirmationChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCopy: PropTypes.func.isRequired,
  handlePaste: PropTypes.func.isRequired,
  resetPasswordState: PropTypes.objectOf(PropTypes.any).isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
};

export default React.memo(VerifyForgotPasswordComponent);
