import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ActionCableConsumer } from 'react-actioncable-provider';

import {
  Input,
  FormGroup,
  Button,
  Icon,
} from 'core-components';

import { setInput, setOutput, outputRequest } from 'actions/ioAction';

const CustomIOReportComponent = ({
  language,
  languageId,
}) => {
  const dispatch = useDispatch();
  const { code } = useSelector(
    (state) => state.languageReducer,
  );
  const [loading, setLoading] = useState(false);
  const [isOpenCustomInput, setCustomInput] = useState(false);
  const [showOutput, setshowOutput] = useState(false);

  const {
    inputValue: storedInput,
    outputValue: storedOutput,
    token: latestToken,
  } = useSelector((state) => state.ioReducer);

  const inputOutuptValue = {
    inputValue: storedInput,
    outputValue: storedOutput,
  };

  const handleReceived = (data) => {
    const { token, stderr, stdout, compile_output } = data;
    if (token === latestToken) {
      let outputValue = '';

      if (stderr) {
        outputValue = stderr;
      } else if (!stdout) {
        outputValue = Buffer.from(compile_output, 'base64');
      } else {
        outputValue = stdout;
      }
      dispatch(setOutput(outputValue));
    }
    setLoading(false);
  };

  const handleRunClick = async () => {
    dispatch(setOutput(''));
    const data = {
      language_id: languageId,
      language_name: language,
      source_code: code,
      room: 'broadcastingRoom',
    };
    if (inputOutuptValue.inputValue) {
      data.stdin = inputOutuptValue.inputValue;
    }
    dispatch(outputRequest(data));
    setLoading(true);
  };

  const handleInputChange = useCallback(
    (event) => {
      dispatch(setInput(event.target.value));
    },
    [inputOutuptValue.inputValue],
  );

  const handleEvent = (event) => {
    event.preventDefault();
  };

  const toggleOutput = () => setshowOutput(!showOutput);
  const toggleCustomIO = () => setCustomInput(!isOpenCustomInput);

  const handleOnInputClick = () => {
    toggleOutput();
    toggleCustomIO();
  };

  return (
    <>
      <ActionCableConsumer
        channel={{ channel: 'TestChannel', room: 'broadcastingRoom' }}
        onReceived={handleReceived}
      />
      <Button
        color='warning'
        className='py-2 px-4 position-relative output-run-btn mr-2'
        onClick={handleOnInputClick}
      >
        Run
      </Button>
      <div
        className={`output-btn-wrapper position-absolute ${isOpenCustomInput && 'toggle-wrapper'}`}
      >
        {showOutput && (
          <div className='custom-output-section p-4 position-relative'>
            <div
              color='warning'
              className='d-flex output-header justify-content-between'
            >
              <span className='ml-3 output-title'>Output</span>
              <Icon className='icon-click' name='arrow-down' onClick={handleOnInputClick} />
            </div>
            <FormGroup className='d-flex mb-0'>
              <Input
                type='textarea'
                value={loading ? '' : inputOutuptValue.outputValue}
                rows={15}
                className='border-0 show-output-wrapper'
                placeholder={loading ? 'Loading...' : 'Run code to view output here'}
                readOnly
              />
            </FormGroup>
          </div>
        )}
      </div>
      <div
        className={`custom-button-wrapper position-absolute ${isOpenCustomInput && 'toggle-wrapper'}`}
      >
        {isOpenCustomInput && (
          <div
            color='link'
            className='custom-input-wrapper border-0 text-decoration-none p-0 text-center'
          >
            <span className='mt-3 d-inline-block'>Add Custom Input</span>
          </div>
        )}
        <div className='custom-input-section p-4 position-relative'>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <FormGroup className='d-flex mb-0'>
              <Input
                placeholder='Enter Input'
                className='border-0'
                value={inputOutuptValue.inputValue}
                onChange={handleInputChange}
                onCopy={handleEvent}
                onCut={handleEvent}
                onPaste={handleEvent}
              />
            </FormGroup>
            <div>
              <Button
                color='secondary'
                className='text-uppercase text-decoration-none position-absolute run-btn px-3 py-2'
                onClick={handleRunClick}
              >
                Run code
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CustomIOReportComponent.propTypes = {
  language: PropTypes.string.isRequired,
  languageId: PropTypes.number.isRequired,
};

export default React.memo(CustomIOReportComponent);
