import React from 'react';
import PropTypes from 'prop-types';

import QuestionFilterContainer from 'modules/admin/question/QuestionContainer/QuestionFilterContainer';
import Icon from 'core-components/Icon';
import { FilterComponent, SearchBox } from 'shared-components';
import { Button, DropdownToggle } from 'core-components';
import McqBulkUploadContainer from '../QuestionContainer/McqBulkUploadContainer';

import 'modules/admin/problemsList/ProblemsListComponent/problemListComponent.scss';

const QuestionHeaderComponent = ({
  totalQuestions,
  handleAddQuestionClick,
  handleOnChange,
  handleQuestionSearchClick,
  handleQuestionSearchClearClick,
  questionSearchText,
  onClickQuestionsFilter,
  searchedQuestionText,
  showQuestionsFilter,
  handleDownloadMcq,
}) => {
  return (
    <div>
      <div
        className={`problem-bank-section-head d-flex align-items-center gap-2 px-3 ${showQuestionsFilter ? 'mb-0' : 'mb-2'}`}
      >
        <div className='d-flex align-items-center gap-2'>
          <SearchBox
            placeholder='Search Questions'
            handleSearchClick={handleQuestionSearchClick}
            handleCloseClick={handleQuestionSearchClearClick}
            handleOnChange={handleOnChange}
            value={questionSearchText}
          />
          <FilterComponent count={totalQuestions} onClickAddFilter={onClickQuestionsFilter} text='Questions' />
          <DropdownToggle
            className='download-icon-box'
          >
            <Icon
              name='download'
              className='download-icon'
              title='Download MCQ List'
              onClick={handleDownloadMcq}
            />
          </DropdownToggle>
          <Button
            color='primary'
            className='btn-sm'
            onClick={handleAddQuestionClick}
            title='Add Question'
          >
            <Icon id='plus' name='plus' title='Add Question' />
          </Button>
        </div>
        <McqBulkUploadContainer />
      </div>
      {showQuestionsFilter && (
        <QuestionFilterContainer
          onClickQuestionsFilter={onClickQuestionsFilter}
          searchedQuestionText={searchedQuestionText}
        />
      )}
    </div>
  );
};

QuestionHeaderComponent.propTypes = {
  totalQuestions: PropTypes.number.isRequired,
  handleAddQuestionClick: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleQuestionSearchClick: PropTypes.func.isRequired,
  handleQuestionSearchClearClick: PropTypes.func.isRequired,
  questionSearchText: PropTypes.string.isRequired,
  onClickQuestionsFilter: PropTypes.func.isRequired,
  searchedQuestionText: PropTypes.string.isRequired,
  showQuestionsFilter: PropTypes.bool.isRequired,
  handleDownloadMcq: PropTypes.func.isRequired,
};

export default React.memo(QuestionHeaderComponent);
