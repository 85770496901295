import React from 'react';
import PropTypes, { shape } from 'prop-types';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';

import {
  Table,
  Alert,
  Icon,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container,
  Spinner,
} from 'core-components';
import { SearchBox } from 'shared-components';

import { customStyle } from 'constants/stylesConstants';
import { CustomThemeMultiSelect } from 'constants/themesConstants';

import 'modules/admin/showCandidateList/ShowCandidateListComponent/showCandidateListComponent.scss';
import 'modules/admin/driveResult/DriveResultComponent/driveResultComponent.scss';

const DriveResultComponent = ({
  renderTableData,
  errorMessage,
  isError,
  handleDownloadResult,
  driveResultMessage,
  pageCount,
  handlePageClick,
  previousClick,
  driveName,
  defaultEmail,
  totalSelectedCandidates,
  absentCandidatesCount,
  driveTotalMarks,
  handleOnChange,
  handleSearchClick,
  handleDriveResultSearchClearClick,
  handleDownloadAbsentCandidates,
  dropdownOpen,
  toggleDropdown,
  isAssessmentResult,
  toggleConfirmation,
  showModal,
  handleSendEmail,
  emailData,
  isContactsLoading,
  searchQuery,
  isDownloadResultDisable,
  contactEmailOptions,
  handleSelectedEmail,
  emailErrorMessage,
  handleOnSendReportClick,
}) => {
  if (isContactsLoading) {
    return (
      <Container className='d-flex justify-content-center align-content'>
        <Spinner />
      </Container>
    );
  }

  const error = () => {
    if (isError) {
      return <h6 className='text-danger font-weight-light'>{errorMessage}</h6>;
    }
  };

  if (typeof driveResultMessage !== 'undefined') {
    return <Alert>{driveResultMessage}</Alert>;
  }

  const previousArrow = (
    <>
      <Icon name='arrow-left' />
      <span className='text-uppercase'>PREVIOUS</span>
    </>
  );

  const nextArrow = (
    <>
      <span className='text-uppercase'>Next</span>
      <Icon name='arrow-right' />
    </>
  );

  const emailList = [];
  if (emailData.length) {
    emailData.map((e) => {
      return emailList.push(`${e.email}`);
    });
  }

  return (
    <>
      <Modal
        className='confirmation-modal'
        isOpen={showModal}
        toggle={toggleConfirmation}
      >
        <ModalHeader className='modal-header'>
          Send email
        </ModalHeader>
        <ModalBody className=' modal-body'>
          <p>Are you sure you want to send these candidates result on email?</p>
          <p>Email will be sent to:</p>
          <Select
            className='email-react-select'
            value={defaultEmail}
            onChange={handleSelectedEmail}
            options={contactEmailOptions}
            placeholder='Select Contacts to Send in Email'
            theme={CustomThemeMultiSelect}
            isMulti
            styles={customStyle}
          />
          {emailErrorMessage && <div className='text-danger'>{emailErrorMessage}</div>}
        </ModalBody>
        <ModalFooter className='border-0 modal-footer'>
          <Button
            color='secondary'
            outline
            onClick={toggleConfirmation}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={handleSendEmail}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
      <div className='candidate-section d-flex flex-column'>
        <div className='candidate-section-head d-flex justify-content-between mb-4'>
          <div className='d-flex align-items-center my-2 candidate-title-box'>
            <div className='d-flex align-items-center mr-3'>
              <Icon name='left' className='back-page-arrow font-weight-bold' onClick={previousClick} />
              <h2 className='section-heading font-weight-bold mb-0 ml-3'>
                Result
              </h2>
            </div>
            <div className='drive-title px-3'>
              <span className='font-weight-bold text-capitalize'>
                {driveName}
              </span>
            </div>
          </div>
          <div className='d-flex align-items-center'>
            <div className='d-flex align-items-center mr-3'>
              <h2 className='section-heading font-weight-bold mb-0 mx-3'>
                Total Marks
              </h2>
              <div className='drive-title px-3'>
                <span className='font-weight-bold text-capitalize'>
                  {driveTotalMarks}
                </span>
              </div>
            </div>
            {!isAssessmentResult && (
              <div className='d-flex align-items-center mr-3'>
                <h2 className='section-heading font-weight-bold mb-0 mx-3'>
                  Shortlisted
                </h2>
                <div className='drive-title px-3'>
                  <span className='font-weight-bold text-capitalize'>
                    {totalSelectedCandidates}
                  </span>
                </div>
              </div>
            )}
            <div className='d-flex align-items-center justify-content-center '>
              <SearchBox
                placeholder='Search Candidate'
                handleOnChange={handleOnChange}
                handleSearchClick={handleSearchClick}
                handleCloseClick={handleDriveResultSearchClearClick}
                value={searchQuery}
              />
              {isAssessmentResult ? (
                <Button
                  className='download-icon-box'
                  disabled={isDownloadResultDisable}
                  onClick={handleDownloadResult}
                >
                  <Icon
                    className='download-icon'
                    name='download'
                    title='Download Result'
                  />
                </Button>
              ) : (
                <Dropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDropdown}
                  onClick={(e) => e.stopPropagation()}
                >
                  <DropdownToggle
                    className='download-icon-box'
                    disabled={isDownloadResultDisable}
                  >
                    <Icon
                      className='download-icon'
                      name='download'
                      title='Download Result'
                    />
                  </DropdownToggle>
                  <DropdownMenu className='dropdown-menu'>
                    <DropdownItem className='dropdown-item' onClick={handleDownloadResult}>
                      Report
                    </DropdownItem>
                    <DropdownItem
                      className='dropdown-item'
                      onClick={handleDownloadAbsentCandidates}
                      disabled={absentCandidatesCount === 0}
                    >
                      Absent candidates
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              )}
              {!isAssessmentResult && (
                <Button
                  disabled={!totalSelectedCandidates}
                  color='primary'
                  className='py-2 px-4 result'
                  onClick={handleOnSendReportClick}
                >
                  Send Report
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className='candidate-list scroll-y table-responsive'>
          <Table className='sp-table'>
            <thead>
              <tr>
                <th>Candidate Id</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Score</th>
                <th>Time</th>
                <th>Result</th>
                {!isAssessmentResult && (<th>Shortlisted</th>)}

              </tr>
            </thead>
            <tbody className='scroll-y'>{renderTableData}</tbody>
          </Table>

          {pageCount > 1 && (
            <ReactPaginate
              previousLabel={previousArrow}
              nextLabel={nextArrow}
              breakLabel='...'
              breakClassName='break-me'
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName='sp-pagination'
              previousLinkClassName='sp-pagination__link'
              nextLinkClassName='sp-pagination__link'
              disabledClassName='sp-pagination__link--disabled'
              activeClassName='sp-pagination__link--active'
              pageLinkClassName='sp-page__link'
            />
          )}
        </div>
      </div>
    </>
  );
};

DriveResultComponent.defaultProps = {
  totalSelectedCandidates: 0,
  absentCandidatesCount: 0,
  driveResultMessage: undefined,
  isAssessmentResult: false,
  emailData: [],
  emailErrorMessage: '',
};

DriveResultComponent.propTypes = {
  renderTableData: PropTypes.arrayOf(shape()).isRequired,
  errorMessage: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  handleDownloadResult: PropTypes.func.isRequired,
  driveResultMessage: PropTypes.string,
  handlePageClick: PropTypes.func.isRequired,
  pageCount: PropTypes.number.isRequired,
  previousClick: PropTypes.func.isRequired,
  driveName: PropTypes.string.isRequired,
  totalSelectedCandidates: PropTypes.number,
  absentCandidatesCount: PropTypes.number,
  driveTotalMarks: PropTypes.number.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleSearchClick: PropTypes.func.isRequired,
  handleDriveResultSearchClearClick: PropTypes.func.isRequired,
  handleDownloadAbsentCandidates: PropTypes.func.isRequired,
  dropdownOpen: PropTypes.bool.isRequired,
  toggleDropdown: PropTypes.func.isRequired,
  isAssessmentResult: PropTypes.bool,
  toggleConfirmation: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleSendEmail: PropTypes.func.isRequired,
  emailData: PropTypes.arrayOf(PropTypes.object),
  isContactsLoading: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string.isRequired,
  contactEmailOptions: PropTypes.array.isRequired,
  isDownloadResultDisable: PropTypes.number.isRequired,
  defaultEmail: PropTypes.number.isRequired,
  handleSelectedEmail: PropTypes.func.isRequired,
  emailErrorMessage: PropTypes.string,
  handleOnSendReportClick: PropTypes.func.isRequired,
};

export default React.memo(DriveResultComponent);
