export const ASSESSMENT_LIST = {
  ASSESSMENT_LIST_REQUEST: 'ASSESSMENT_LIST_REQUEST',
  ASSESSMENT_LIST_SUCCESS: 'ASSESSMENT_LIST_SUCCESS',
  ASSESSMENT_LIST_FAILURE: 'ASSESSMENT_LIST_FAILURE',
  CLEAR_ASSESSMENTS: 'CLEAR_ASSESSMENTS',
  ASSESSMENT_DELETE_REQUEST: 'ASSESSMENT_DELETE_REQUEST',
  ASSESSMENT_DELETE_SUCCESS: 'ASSESSMENT_DELETE_SUCCESS',
  ASSESSMENT_DELETE_FAILURE: 'ASSESSMENT_DELETE_FAILURE',
  ADD_ASSESSMENT_OFFSET: 'ADD_ASSESSMENT_OFFSET',
};
