/* eslint-disable max-len */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'utils/capitalize';

import {
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
  Spinner,
  Icon,
} from 'core-components';
import { Row, Col } from 'reactstrap';

import { DeleteConfirmModal } from 'shared-components';

import './testCase.css';
import './testCaseComponent.scss';

const TestCasesCard = ({
  testCase,
  index,
  handleInputChange,
  handleOutputChange,
  handleMarksChange,
  testCaseError,
  handleOnCancleClick,
  testCasesList,
}) => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const { input, output, marks } = testCase;
  const {
    [`testCasesList[${index}].input`]: inputError,
    [`testCasesList[${index}].output`]: outputError,
    [`testCasesList[${index}].marks`]: marksError,
  } = testCaseError;

  const toggleDeleteConfirmation = () => {
    setShowDeleteConfirmationModal(!showDeleteConfirmationModal);
  };

  return (
    <div className='test-case-container'>
      <DeleteConfirmModal
        confirmationModal={showDeleteConfirmationModal}
        toggleConfirmation={toggleDeleteConfirmation}
        handleDelete={() => handleOnCancleClick(index)}
        targetId={index}
        name='this test case'
      />
      <Row>
        <Col md={11}>
          <Row>
            <Col md={5}>
              <FormGroup>
                <Label className='px-3'>Input</Label>
                <Input
                  type='textarea'
                  invalid={inputError}
                  value={input}
                  onChange={(event) => handleInputChange(event, index)}
                  placeholder='Input'
                />
                {inputError && (
                  <FormFeedback>
                    {capitalize(inputError)}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Label className='px-3'>Output</Label>
                <Input
                  type='textarea'
                  invalid={outputError}
                  value={output}
                  onChange={(event) => handleOutputChange(event, index)}
                  placeholder='Output'
                />
                {outputError && (
                  <FormFeedback>
                    {capitalize(outputError)}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label className='px-3'>Marks</Label>
                <Input
                  type='number'
                  invalid={marksError}
                  onChange={(event) => handleMarksChange(event, index)}
                  placeholder='Marks'
                  required
                  value={marks || ''}
                  defaultValue={0}
                />
                {marksError && (
                  <FormFeedback>
                    {capitalize(marksError)}
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col
          md={1}
          className='d-flex align-items-center justify-content-center'
        >
          {testCasesList.length !== 1 && (
            <Button
              onClick={toggleDeleteConfirmation}
              color='link'
              title='Remove test Case'
              className='close-button p-0 mr-0 text-decoration-none'
            >
              <Icon name='delete' />
            </Button>
        )}
        </Col>
      </Row>
    </div>
  );
};
const CreateProblemComponent = ({
  testCasesList,
  handleInputChange,
  handleOutputChange,
  handleMarksChange,
  handleTestCaseOnAdd,
  isLoading,
  scrollRef,
  handleSubmit,
  testCaseError,
  handleOnCancleClick,
  isTestCaseCreate,
}) => {
  const scrollOnUpdate = () => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div
      className='test-case-section-wrap'
    >
      {isTestCaseCreate && <h6 className='font-weight-bold ml-4'>Add New Test Case</h6>}
      {testCasesList.map((testCase, index) => (
        <TestCasesCard
          testCase={testCase}
          index={index}
          handleInputChange={handleInputChange}
          handleOutputChange={handleOutputChange}
          handleMarksChange={handleMarksChange}
          testCaseError={testCaseError}
          handleOnCancleClick={handleOnCancleClick}
          testCasesList={testCasesList}
        />
      ))}
      <div className='d-flex px-5 justify-content-end'>
        <Button
          onClick={handleTestCaseOnAdd}
          color='primary'
          className='px-5 py-2'
        >
          {isLoading ? <Spinner size='sm' color='light' /> : <>Add Test Case</>}
        </Button>

        <Button
          onClick={() => {
            scrollOnUpdate();
            handleSubmit();
          }}
          color='primary'
          className='px-4 py-2 text-decoration-none ml-2'
        >
          {isTestCaseCreate ? 'Submit' : 'Update'}
        </Button>
      </div>
    </div>
  );
};

TestCasesCard.propTypes = {
  testCase: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleOutputChange: PropTypes.func.isRequired,
  handleMarksChange: PropTypes.func.isRequired,
  testCaseError: PropTypes.object.isRequired,
  handleOnCancleClick: PropTypes.func.isRequired,
  testCasesList: PropTypes.array.isRequired,
};

CreateProblemComponent.defaultProps = {
  isTestCaseCreate: false,
};

CreateProblemComponent.propTypes = {
  testCasesList: PropTypes.array.isRequired,
  isTestCaseCreate: PropTypes.bool,
  handleInputChange: PropTypes.func.isRequired,
  handleOutputChange: PropTypes.func.isRequired,
  handleMarksChange: PropTypes.func.isRequired,
  handleTestCaseOnAdd: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  scrollRef: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  testCaseError: PropTypes.object.isRequired,
  handleOnCancleClick: PropTypes.func.isRequired,
};

export default React.memo(CreateProblemComponent);
