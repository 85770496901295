import { LANGUAGE } from 'constants/actionConstants';

export const fetchLanguages = (payload) => ({
  type: LANGUAGE.FETCH_ACTION,
  payload,
});

export const setLanguages = (payload) => ({
  type: LANGUAGE.RESET_ACTION,
  payload,
});

export const setLanguageSelected = (languageSelected) => ({
  type: LANGUAGE.SET_LANGUAGE_SELECTED,
  payload: {
    languageSelected,
  },
});

export const setCode = (code) => ({
  type: LANGUAGE.SET_CODE,
  payload: { code },
});

export const setChanged = (changed) => ({
  type: LANGUAGE.SET_CHANGED,
  payload: { changed },
});

export const setFirstTime = (first) => ({
  type: LANGUAGE.SET_FIRST,
  payload: { first },
});
