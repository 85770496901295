import { CANDIDATE_LIST } from './actionConstants';

const {
  CANDIDATE_LIST_REQUEST,
  CANDIDATE_LIST_SUCCESS,
  CANDIDATE_LIST_FAILURE,
  DRIVE_STATUS,
} = CANDIDATE_LIST;

export const candidateListRequest = (payload) => ({
  type: CANDIDATE_LIST_REQUEST,
  payload,
});

export const candidateListSuccess = (payload) => ({
  type: CANDIDATE_LIST_SUCCESS,
  payload,
});

export const candidateListFailure = (message) => ({
  type: CANDIDATE_LIST_FAILURE,
  payload: message,
});

export const setDriveStatusAction = (payload) => ({
  type: DRIVE_STATUS,
  payload,
});
