import { SERVER_URL } from 'constants/appConstants';
import { put, get } from '../apiHelper';

export const editQuestionPostApi = (data) => {
  return put(`${SERVER_URL}admin/questions/${data.id}`, data);
};

export const getQuestionApi = (questionId) => {
  return get(`${SERVER_URL}admin/questions/${questionId}`);
};
