import produce from 'immer';

export const initialState = {
  // invitation send by button click
  emails: '',
  successMessage: '',
  errorMessage: '',
  // invitation send by csv file
  csvEmails: '',
  csvFileError: '',
  csvSuccessMessage: '',
  csvErrorMessage: '',
  invalidEmailsFilePath: '',
  isModalOpen: true,
};

const reducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case 'CSV_EMAILS_SENT_SUCCESS':
      state.csvEmails = '';
      state.csvSuccessMessage = payload;
      state.csvErrorMessage = '';
      state.csvFileError = '';
      break;
    case 'EMIALS_SENT_SUCCESS':
      state.emails = '';
      state.successMessage = payload;
      state.errorMessage = '';
      break;
    case 'CSV_EMAILS_SENT_FAILURE':
      state.csvEmails = '';
      state.csvSuccessMessage = '';
      state.csvErrorMessage = payload;
      state.csvFileError = '';
      state.invalidEmailsFilePath = '';
      break;
    case 'EMIALS_SENT_FAILURE':
      state.emails = '';
      state.successMessage = '';
      state.errorMessage = payload;
      break;
    case 'CSV_EMAILS_SENT_FAILURE_FILE':
      state.csvSuccessMessage = '';
      state.invalidEmailsFilePath = payload;
      state.csvErrorMessage = 'Download error file to check the invalid emails.';
      break;
    case 'INVALID_EMAIL':
      state.successMessage = '';
      state.errorMessage = payload;
      break;
    case 'INVALID_CSV_FILETYPE':
      state.csvEmails = '';
      state.csvFileError = payload;
      state.csvSuccessMessage = '';
      state.csvErrorMessage = '';
      break;
    case 'VALID_EMAIL':
      state.emails = payload;
      state.errorMessage = '';
      break;
    case 'VALID_CSV_FILETYPE':
      state.csvEmails = payload;
      state.csvFileError = '';
      break;
    case 'MODAL_TOGGLE':
      state.isModalOpen = payload;
      break;
    case 'RESET_SEND_EMAIL_INVITE':
      return initialState;
    default:
      return state;
  }
});

export default reducer;
