/* eslint-disable no-underscore-dangle */
import React, { useReducer, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Spinner } from 'core-components';
import EditProblemComponent from 'modules/admin/editProblem/EditProblemComponent';

import {
  editProblemRequestAction,
  setProblemId,
  clearProblem,
  setProblemImageAction,
} from 'redux/admin/editProblem/action';
import { tagsRequestAction, addNewDifficulty, addNewTag } from 'redux/admin/tags/action';
import { reducer } from 'modules/admin/editProblem/EditProblemContainer/reducer';
import { getProblem } from 'modules/admin/editProblem/EditProblemContainer/getProblemDetails';
import { schema } from 'modules/admin/createProblem/CreateProblemContainer/schema';
import arrayToKeyValueHash from 'utils/arrayToKeyValueHash';
import { imageFileToUrl, isImageFile } from 'utils';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';
import { imageFormatErrorMessage } from 'constants/messageConstants';

const EditProblemContainer = () => {
  const history = useHistory();
  const initialUserState = {
    title: '',
    titleError: '',
    validTitle: true,
    description: '',
    validDescription: true,
    descriptionError: '',
    submissionCount: null,
    validSubmissionCount: true,
    submissionCountError: '',
    timeInMinutes: '',
    validTime: true,
    timeInMinutesError: '',
    difficulty: '',
    difficultyErr: '',
    difficultyError: '',
    tags: [],
    tagsError: '',
    problemImage: '',
  };
  const dispatch = useDispatch();
  const [problemDetails, setProblemDetails] = useState();
  const [editProblem, setEditProblem] = useReducer(reducer, initialUserState);
  const [problemIsLoading, setProblemIsLoading] = useState(true);
  const {
    message,
    isSuccess,
    isLoading,
    problemId: editProblemId,
    problemImage,
  } = useSelector((state) => state.editProblemReducer);
  const [defTags, setDefTags] = useState([]);
  const { difficultyList, tagList } = useSelector((state) => state.tagsReducer);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [difficultyOptions, setDifficultyOptions] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);
  const [defaultDifficulty, setDefaultDifficulty] = useState({});

  const [problemImageError, setProblemImageError] = useState('');
  // problemTestCase is use to set already existing test case of problem
  const [problemTestCase, setProblemTestCase] = useState([]);
  // testCasesList is use to set the test case while deleting and updating test case
  const [testCasesList, setTestCasesList] = useState([]);
  const [testCaseError, setTestCaseError] = useState({});
  const [problemDiagram, setProblemDiagram] = useState(null);

  const { problemId } = useParams();
  useEffect(async () => {
    const problemData = await getProblem(problemId);
    const { problems, problemLoading } = problemData;
    if (!problemLoading) {
      setProblemDetails(problems);
      dispatch(setProblemId(problems.id));
      setProblemIsLoading(problemLoading);
    }
    const {
      title,
      description,
      submission_count,
      time_in_minutes,
      difficulty,
      tags,
      image_url: imageURL,
      test_cases: testCases,
    } = problemData.problems;
    setTestCasesList([...testCases]);
    setProblemTestCase([...testCases]);

    dispatch(setProblemImageAction(imageURL));
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
    const defaultTags = [];
    for (let i = 0; i < tags.length; i += 1) {
      defaultTags.push({ value: tags[i], label: capitalizeFirstLetter(tags[i]) });
    }
    setDefTags(defaultTags);
    setEditProblem({
      type: 'problem',
      payload: {
        title,
        description,
        submissionCount: submission_count,
        timeInMinutes: time_in_minutes,
        difficulty,
        tags,
        problemImage: imageURL,
      },
    });
    dispatch(tagsRequestAction());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      history.push(ROUTES.ADMIN + ADMIN_ROUTES.PROBLEMS);
    }
  }, [isSuccess]);

  useEffect(() => {
    setDifficultyOptions(arrayToKeyValueHash(difficultyList));
  }, [difficultyList]);

  useEffect(() => {
    const difficultyArray = difficultyOptions.find(
      (level) => level.value === problemDetails.difficulty,
    );
    setDefaultDifficulty({ ...difficultyArray });
  }, [difficultyOptions, problemDetails]);

  useEffect(() => {
    setTagsOptions(arrayToKeyValueHash(tagList));
  }, [tagList]);

  useEffect(() => {
    return () => {
      dispatch(clearProblem());
    };
  }, []);

  const handleTitleChange = useCallback(
    (event) => {
      const title = event.target.value;
      setEditProblem({
        type: 'title',
        payload: title,
      });
    },
    [editProblem.title],
  );

  const handleDescriptionChange = useCallback(
    (event) => {
      const description = event.target.value;
      setEditProblem({
        type: 'description',
        payload: description,
      });
    },
    [editProblem.description],
  );

  const handleCountChange = useCallback(
    (event) => {
      const submissionCount = event.target.value;
      setEditProblem({
        type: 'submissionCount',
        payload: Number(submissionCount),
      });
    },
    [editProblem.submissionCount],
  );

  const handleTimeChange = useCallback(
    (event) => {
      const timeInMinutes = event.target.value;
      setEditProblem({
        type: 'timeInMinutes',
        payload: Number(timeInMinutes),
      });
    },
    [editProblem.timeInMinutes],
  );
  const handleSelectedDifficultyChange = useCallback(
    (selectedDifficulty) => {
      const difficulty = selectedDifficulty.value;
      setEditProblem({
        type: 'difficulty',
        payload: difficulty,
      });
      setDefaultDifficulty({ ...selectedDifficulty });
      if (selectedDifficulty.__isNew__) {
        selectedDifficulty.__isNew__ = false; dispatch(addNewDifficulty(selectedDifficulty.value));
      }
    }, [editProblem.difficulty],
  );

  const handleSelectedTagsChange = useCallback(
    (event) => {
      const tags = [];
      setDefTags(event);
      event.map((item) => {
        if (item.__isNew__) { item.__isNew__ = false; dispatch(addNewTag(item.value)); }
        return tags.push(item.value);
      });
      setEditProblem({
        type: 'tags',
        payload: tags,
      });
    }, [editProblem.tags],
  );

  const handleSubmit = useCallback(() => {
    const {
      title,
      description,
      submissionCount,
      timeInMinutes,
      difficulty,
      tags,
    } = editProblem;

    schema.validate(
      {
        problemTitle: title.trim(),
        problemDescription: description.trim(),
        submissionCount,
        timeInMinute: timeInMinutes,
        difficulty,
        tags,
        testCasesList,
      },
      { abortEarly: false },
    ).then(() => {
      const trimTestCases = testCasesList.map((testCase) => {
        const { input, output } = testCase;
        testCase.input = input.trim();
        testCase.output = output.trim();
        return testCase;
      });
      let testCasesAttributes = [...trimTestCases];
      // get the deleted testcases
      const deletedTestCases =  problemTestCase.filter((problem) => {
        return !testCasesList.find((testCase) => {
          return testCase.id === problem.id;
        });
      });

      // set destroy flag for that deleted test case
      const array = deletedTestCases.map((deleteTestCase) => {
        deleteTestCase._destroy = true;
        return deleteTestCase;
      });

      // add into the test cases array
      if (deletedTestCases.length !== 0) {
        testCasesAttributes = testCasesAttributes.concat(array);
      }
      const data = {
        title: title.trim(),
        description: description.trim(),
        submissionCount,
        timeInMinutes,
        difficulty,
        tags,
        problemId,
        problemDiagram,
        testCasesAttributes,
      };
      dispatch(editProblemRequestAction(data));
    }).catch((error) => {
      const testCaseErrorObj = {};
      error.inner.forEach((e) => {
        if (/testCasesList/.test(e.path)) {
          testCaseErrorObj[e.path] = e.message;
          setTestCaseError({ ...testCaseErrorObj });
        }
        switch (e.path) {
          case 'problemTitle':
            setEditProblem({
              type: 'invalidTitle',
              payload: e.message,
            });
            break;
          case 'submissionCount':
            setEditProblem({
              type: 'invalidSubmissionCount',
              payload: e.message,
            });
            break;
          case 'timeInMinute':
            setEditProblem({
              type: 'invalidTime',
              payload: e.message,
            });
            break;
          case 'problemDescription':
            setEditProblem({
              type: 'invalidDescription',
              payload: e.message,
            });
            break;
          case 'difficulty':
            setEditProblem({
              type: 'invalidDifficulty',
            });
            break;
          case 'tags':
            setEditProblem({
              type: 'invalidTags',
            });
            break;
          case 'problemDiagram':
            setEditProblem({
              type: 'invalidDiagram',
            });
            break;
          default:
            break;
        }
      });
    });
  });

  const finishProblemEdit = useCallback(() => {
    history.push(ROUTES.ADMIN + ADMIN_ROUTES.PROBLEMS);
  });

  const imagePreview = (imageFile) => {
    dispatch(setProblemImageAction(imageFileToUrl(imageFile)));
  };

  const handleCaptureProblem = useCallback(
    (e) => {
      const file = e.target.files[0];
      setProblemImageError('');

      if (file) {
        if (isImageFile(file.name)) {
          setEditProblem({
            type: 'problemDiagram',
            payload: file,
          });
          setProblemDiagram(file);
          imagePreview(file);
        } else {
          dispatch(setProblemImageAction(''));
          setProblemImageError(imageFormatErrorMessage);
        }
      }
    }, [editProblem.questionDiagram],
  );

  const toggleImageModalVisibility = () => {
    setShowImageModal(!showImageModal);
  };

  if (problemIsLoading) {
    return <Spinner />;
  }

  return (
    <EditProblemComponent
      handleTitleChange={handleTitleChange}
      handleDescriptionChange={handleDescriptionChange}
      handleCountChange={handleCountChange}
      handleTimeChange={handleTimeChange}
      handleSubmit={handleSubmit}
      message={message}
      isSuccess={isSuccess}
      isLoading={isLoading}
      finishProblemEdit={finishProblemEdit}
      problemDetails={problemDetails}
      problemIsLoading={problemIsLoading}
      editProblem={editProblem}
      handleSelectedDifficultyChange={handleSelectedDifficultyChange}
      handleSelectedTagsChange={handleSelectedTagsChange}
      defTags={defTags}
      problemId={editProblemId}
      tagsOptions={tagsOptions}
      difficultyOptions={difficultyOptions}
      handleCaptureProblem={handleCaptureProblem}
      problemImage={problemImage}
      showImageModal={showImageModal}
      toggleImageModalVisibility={toggleImageModalVisibility}
      defaultDifficulty={defaultDifficulty}
      problemImageError={problemImageError}
      testCasesList={testCasesList}
      setTestCasesList={setTestCasesList}
      testCaseError={testCaseError}
      setTestCaseError={setTestCaseError}
    />
  );
};
export default React.memo(EditProblemContainer);
