import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import { Spinner } from 'core-components';
import EditDriveComponent from 'modules/admin/editDrive/EditDriveComponent';

import { editDriveClearAction, editDriveRequestAction } from 'redux/admin/editDrive/action';
import {
  questionsRequestAction, questionsClear,
} from 'redux/admin/questionsList/action';
import {
  problemsClear, problemsRequestAction,
} from 'redux/admin/problems/action';
import {
  contactsListRequestActioon, contactsListClear,
} from 'redux/admin/getContacts/action';
import {
  getEditDriveRequestAction, getEditDriveClear,
} from 'redux/admin/getEditDrive/action';
import { reducer } from 'modules/admin/createDrive/CreateDriveContainer/reducer';
import { tagsRequestAction } from 'redux/admin/tags/action';
import { validateData } from 'modules/admin/editDrive/dataValidation';
import arrayToKeyValueHash from 'utils/arrayToKeyValueHash';
import { getDateFormat } from 'utils/date';
import { getFullNameWithContact, getUserDetails } from 'utils';

import { DRIVE_SECTION_CONSTANTS } from 'modules/admin/createDrive/CreateDriveContainer/constants';
import { problemMCQErrorMessage } from 'constants/messageConstants';
import { ROUTES, ADMIN_ROUTES } from 'constants/routeConstants';
import { defaultDriveStartDate, defaultDriveEndDate } from 'modules/admin/createDrive/CreateDriveContainer/utils';
import { getDriveType, isStartTimeIsPast } from './utils';

const { DRIVE_DETAILS_SECTION, ADD_QUESTIONS_SECTION, ADD_RULES_SECTION } =
  DRIVE_SECTION_CONSTANTS;

const EditDriveContainer = () => {
  const initialState =
  {
    data: {
      drive: {
        id: '',
        name: '',
        description: '',
        start_time: defaultDriveStartDate(),
        end_time: defaultDriveEndDate(),
        created_by_id: '',
        updated_by_id: '',
        organization_id: '',
        is_assessment: false,
      },
    },
    nameErrTxt: '',
    descriptionErrTxt: '',
    startTimeErrTxt: '',
    endTimeErrTxt: '',
    problemErrTxt: '',
    contactsErrTxt: '',
    message: '',
    currentProblems: [],
    problemLoading: true,
    contacts: [],
    adminUsers:[],
    currentQuestions: [],
    questionErrTxt: '',
  };
  const { driveId } = useParams();
  const history = useHistory();
  const [driveDetails, setDriveDetails] = useState();
  const [editDrive, setEditDrive] = useReducer(reducer, initialState);
  const [problemIsLoading, setProblemIsLoading] = useState(true);
  const [selectedProblems, setSelectedProblems] = useState([]);
  const { message } = editDrive;
  const dispatch = useDispatch();
  const [selectedQuestionsDetails, setSelectedQuestionsDetails] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filterTags, setFilterTags] = useState([]);
  const [filterDifficulty, setFilterDifficulty] = useState('');
  const [showQuestionFilters, setShowQuestionFilters] = useState(false);
  const handleFilterQuestionBox = () => {
    setShowQuestionFilters(!showQuestionFilters);
  };
  const [selectedProbDetails, setSelectedProbDetails] = useState([]);
  const [searchProbText, setSearchProbText] = useState('');
  const [filterProbTags, setFilterProbTags] = useState([]);
  const [filterProbDifficulty, setFilterProbDifficulty] = useState('');
  const [showProbFilters, setShowProbFilters] = useState(false);
  const handleFilterProbBox = () => {
    setShowProbFilters(!showProbFilters);
  };

  useEffect(() => {
    setProblemIsLoading(true);
  }, [driveId]);

  const {
    questions,
    totalQuestions,
    isQuestionsLoading,
  } = useSelector((item) => item.questionsReducer);
  const {
    problems,
    isProblemsLoading,
    totalProblems,
  } = useSelector((item) => item.getProblemsReducer);
  const {
    contactsList,
    isContactsLoading,
  } = useSelector((item) => item.getContactsReducer);
  const { editDriveData } = useSelector(
    (item) => item.getEditDriveReducer,
  );

  const { isSuccess, isLoading } = useSelector((item) => item.editDriveReducer);
  const profileDetails = getUserDetails();
  const allowCoding = profileDetails.allow_coding;
  const allowMcq = profileDetails.allow_mcq;
  const { difficultyList, tagList } = useSelector((state) => state.tagsReducer);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [difficultyOptions, setDifficultyOptions] = useState([]);

  const [section, setSection] = useState(DRIVE_DETAILS_SECTION);
  const [defaultContacts, setDefaultContacts] = useState([]);
  const [driveType, setDriveType] = useState('');

  const schemaDriveDetails = yup.object().shape({
    name: yup
      .string()
      .min(5, 'Title must be at least 5 characters')
      .required('Title is a required field'),
    description: yup.string().min(10).required(),
    contacts: yup.array().min(1, 'Please select/add contact(s)'),
    startTime: yup.date().required(),
    endTime: yup.date().required('End date is required field').min(
      yup.ref('startTime'),
      'End date cannot be before start date and time',
    ),
  });
  const schemaAddQuestions = yup.object().shape({
    problemsCount: yup
      .number()
      .min(1, problemMCQErrorMessage),
  });
  useEffect(() => {
    if (!isQuestionsLoading && allowMcq) {
      dispatch(questionsRequestAction({}));
    }
    dispatch(tagsRequestAction());
  }, []);

  useEffect(() => {
    setDifficultyOptions(arrayToKeyValueHash(difficultyList));
  }, [difficultyList]);

  useEffect(() => {
    setTagsOptions(arrayToKeyValueHash(tagList));
  }, [tagList]);

  useEffect(() => {
    if (!isSuccess) {
      setSection(DRIVE_DETAILS_SECTION);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isContactsLoading) {
      dispatch(
        contactsListRequestActioon({
          contactsList,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (!isProblemsLoading && allowCoding) {
      dispatch(problemsRequestAction({}));
    }
    dispatch(getEditDriveRequestAction(driveId));
  }, []);

  useEffect(() => {
    setProblemIsLoading(true);
  }, [editDriveData]);

  useEffect(() => {
    if (isSuccess) {
      history.push(ROUTES.ADMIN + ADMIN_ROUTES.HOME);
    }
  }, [isSuccess]);

  if (
    editDriveData !== '' &&
    problemIsLoading &&
    !isQuestionsLoading &&
    !isProblemsLoading
  ) {
    setDriveDetails(editDriveData);
    const {
      name,
      description,
      start_time: startTime,
      end_time: endTime,
      drives_problems: drivesProblems,
      drives_contacts: drivesContacts,
      drives_questions: drivesQuestions,
      admin_users: adminUsers,
    } = editDriveData.drive;

    const currDriveProblems = [];
    for (let i = 0; i < drivesProblems.length; i += 1) {
      currDriveProblems.push(drivesProblems[i].problem_id);
    }
    setSelectedProblems(currDriveProblems);
    const currDrivesContacts = [];
    for (let i = 0; i < drivesContacts.length; i += 1) {
      currDrivesContacts.push(drivesContacts[i].user_id);
    }

    const currDrivesQuestions = [];
    const drivesQuestionsDetails = [];
    for (let i = 0; i < drivesQuestions.length; i += 1) {
      currDrivesQuestions.push(drivesQuestions[i].question_id);
      const question = questions.find(
        (q) => q.id === drivesQuestions[i].question_id,
      );
      if (question) {
        drivesQuestionsDetails.push(question);
      }
    }

    // set drive type
    setDriveType(getDriveType(startTime));
    setSelectedQuestionsDetails(drivesQuestionsDetails);
    setProblemIsLoading(false);
    setEditDrive({
      type: 'drive',
      payload: {
        name,
        description,
        start_time: new Date(startTime),
        end_time: new Date(endTime),
        problem: currDriveProblems,
        contacts: currDrivesContacts,
        questions: currDrivesQuestions,
        adminUsers,
      },
    });
  }

  useEffect(() => {
    return () => {
      dispatch(questionsClear());
      dispatch(problemsClear());
      dispatch(contactsListClear());
      dispatch(getEditDriveClear());
      dispatch(editDriveClearAction());
    };
  }, []);

  const searchButtonClick = () => {
    const tags = [];
    for (let i = 0; i < filterTags.length; i += 1) {
      tags.push(filterTags[i].value);
    }
    if (!isQuestionsLoading && (searchText || tags.length !== 0 || filterDifficulty)) {
      dispatch(
        questionsRequestAction({
          searchText: searchText.trim(),
          filterDifficulty,
          filterTags: tags,
        }),
      );
    }
  };
  const resetButtonClick = () => {
    if (searchText || filterTags.length !== 0 || filterDifficulty) {
      setSearchText('');
      setFilterTags([]);
      setFilterDifficulty('');
      if (!isQuestionsLoading) {
        dispatch(questionsRequestAction({}));
      }
    } else {
      handleFilterQuestionBox();
    }
  };
  const searchProbButtonClick = () => {
    const tags = [];
    for (let i = 0; i < filterProbTags.length; i += 1) {
      tags.push(filterProbTags[i].value);
    }
    if (!isProblemsLoading && (searchProbText || tags.length !== 0 || filterProbDifficulty)) {
      dispatch(
        problemsRequestAction({
          searchProbText,
          filterProbDifficulty,
          filterProbTags: tags,
        }),
      );
    }
  };
  const resetProbButtonClick = () => {
    if (searchProbText || filterProbTags.length !== 0 || filterProbDifficulty) {
      setSearchProbText('');
      setFilterProbTags([]);
      setFilterProbDifficulty('');
      if (!isProblemsLoading) {
        dispatch(problemsRequestAction({}));
      }
    } else {
      handleFilterProbBox();
    }
  };

  const handleSearchProbInputChange = useCallback((event) => {
    setSearchProbText(event.target.value);
  }, []);
  const handleFilterProbTagsChange = useCallback((event) => {
    setFilterProbTags(event);
  }, []);
  const handleFilterProbDifficultyChange = useCallback((event) => {
    setFilterProbDifficulty(event.value);
  }, []);

  const handleSelectedProblemChange = useCallback(
    (event) => {
      if (event.target.checked) {
        setEditDrive({
          type: 'problemErrTxt',
          payload: '',
        });
        setEditDrive({
          type: 'problem',
          payload: [
            ...editDrive.currentProblems,
            ...[parseInt(event.target.value, 10)],
          ],
        });
        setSelectedProblems([
          ...selectedProblems,
          ...[parseInt(event.target.value, 10)],
        ]);
      } else {
        const refactoredArray = editDrive.currentProblems.filter(
          (item) => item !== parseInt(event.target.value, 10),
        );
        setEditDrive({
          type: 'problem',
          payload: [...refactoredArray],
        });
        setSelectedProblems(refactoredArray);
      }
    },
    [editDrive.currentProblems],
  );

  const handleDriveNameChange = useCallback(
    (event) => {
      const name = event.target.value;
      setEditDrive({
        type: 'nameErrTxt',
        payload: '',
      });
      setEditDrive({
        type: 'name',
        payload: name,
      });
    },
    [editDrive.data.drive.name],
  );

  const handleDriveDescriptionChange = useCallback(
    (event) => {
      const description = event.target.value;
      setEditDrive({
        type: 'descriptionErrTxt',
        payload: '',
      });
      setEditDrive({
        type: 'description',
        payload: description,
      });
    },
    [editDrive.data.drive.description],
  );

  const handleDriveStartChange = useCallback(
    (startDateTime) => {
      if (startDateTime) {
        setEditDrive({
          type: 'start_time',
          payload: startDateTime,
        });
      }
      setEditDrive({
        type: 'endTimeErrTxt',
        payload: '',
      });
      setEditDrive({
        type: 'startTimeErrTxt',
        payload: '',
      });
    },
    [editDrive.data.drive.start_time],
  );

  const handleDriveEndChange = useCallback(
    (endDateTime) => {
      if (endDateTime) {
        setEditDrive({
          type: 'end_time',
          payload: endDateTime,
        });
      }
      setEditDrive({
        type: 'endTimeErrTxt',
        payload: '',
      });
      setEditDrive({
        type: 'startTimeErrTxt',
        payload: '',
      });
    },
    [editDrive.data.drive.end_time],
  );

  const handleIsAssessmentChange = useCallback(
    (assessmentStatus) => {
      const isAssessment = assessmentStatus;
      setEditDrive({
        type: 'is_assessment',
        payload: isAssessment,
      });
    },
    [editDrive.data.drive.is_assessment],
  );

  const handleSelectedContactsChange = useCallback(
    (event) => {
      setEditDrive({
        type: 'contactsErrTxt',
        payload: '',
      });
      setEditDrive({
        type: 'resetCurrentContacts',
      });
      const newContacts = [];
      for (let i = 0; i < event.length; i += 1) {
        newContacts.push(event[i].value);
      }
      setEditDrive({
        type: 'contacts',
        payload: newContacts,
      });
      setDefaultContacts([...event]);
    },
    [editDrive.contacts],
  );

  useEffect(() => {
    if (editDriveData) {
      const adminUser = editDriveData?.drive?.admin_users;
      const contactsArray = adminUser?.map(({
        id,
        first_name: firstName,
        last_name: lastName,
        mobile_number: mobileNumber,
        is_active: isActive,
      }) => ({
        value: id,
        label: getFullNameWithContact(firstName, lastName, mobileNumber),
        status: isActive,
      }));
      const filterArray = contactsArray?.filter((v) => !v.status)
        .concat(contactsArray?.filter((v) => v.status));
      setDefaultContacts([...filterArray]);
    }
  }, [editDriveData]);

  useEffect(() => {
    const currQuestions = [];
    selectedQuestionsDetails.forEach((q) => {
      if (editDrive.currentQuestions.includes(q.id)) {
        currQuestions.push(q);
      }
    });
    editDrive.currentQuestions.forEach((q) => {
      if (!selectedQuestionsDetails.some((sq) => sq.id === q)) {
        const question = questions.find((e) => e.id === q);
        if (question) {
          currQuestions.push(question);
        }
      }
    });
    setSelectedQuestionsDetails(currQuestions);
  }, [editDrive.currentQuestions]);
  useEffect(() => {
    const currProblems = [];
    selectedProbDetails.forEach((q) => {
      if (selectedProblems.includes(q.id)) {
        currProblems.push(q);
      }
    });
    selectedProblems.forEach((q) => {
      if (!selectedProbDetails.some((sq) => sq.id === q)) {
        const problem = problems.find((e) => e.id === q);
        if (problem) {
          currProblems.push(problem);
        }
      }
    });
    setSelectedProbDetails(currProblems);
  }, [selectedProblems]);

  const handleSearchInputChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);
  const handleFilterTagsChange = useCallback((event) => {
    setFilterTags(event);
  }, []);
  const handleFilterDifficultyChange = useCallback((event) => {
    setFilterDifficulty(event.value);
  }, []);

  const onDriveDetailsNext = () => {
    const {
      data: {
        drive: { id, name, description, start_time: startTime, end_time: endTime,
          is_assessment: isAssessment },
      },
      contacts,
    } = editDrive;

    const newContacts = [];
    let drivesContactsAttributes = [];

    for (let i = 0; i < contacts.length; i += 1) {
      let flg = 1;
      for (let j = 0; j < driveDetails.drive.drives_contacts.length; j += 1) {
        if (driveDetails.drive.drives_contacts[j].user_id === contacts[i]) {
          flg = 0;
          break;
        }
      }
      if (flg) newContacts.push(contacts[i]);
    }

    const oldcontacts = driveDetails.drive.drives_contacts.filter(
      (x) => !contacts.includes(x.user_id),
    );

    for (let i = 0; i < oldcontacts.length; i += 1) {
      drivesContactsAttributes.push({
        id: oldcontacts[i].id,
        user_id: oldcontacts[i].user_id,
        _destroy: true,
      });
    }

    for (let i = 0; i < newContacts.length; i += 1) {
      drivesContactsAttributes.push({
        user_id: newContacts[i],
        _destroy: false,
      });
    }

    const validData = {
      name : name.trim(),
      description : description.trim(),
      contacts,
      startTime,
      endTime,
    };

    const activeUsers = defaultContacts.filter((contact) => contact.status);
    schemaDriveDetails.isValid(validData).then(async (valid) => {
      if (!valid) {
        validateData(schemaDriveDetails, validData, setEditDrive);
      } else if (activeUsers.length === 0) {
        setEditDrive({
          type: 'contactsErrTxt',
          payload: 'Please select atleast 1 active user',
        });
      } else if (driveType === 'ongoing' && !isStartTimeIsPast(endTime)) {
        // set endDateTime error if endTime is past time
        setEditDrive({
          type: 'endTimeErrTxt',
          payload: 'End date cannot be the past date and time',
        });
      } else if (driveType === 'upcoming' && !isStartTimeIsPast(startTime)) {
        // set startDateTime error if startTime is past time
        setEditDrive({
          type: 'startTimeErrTxt',
          payload: 'Start date cannot be the past date and time',
        });
      } else if (getDateFormat(startTime) === getDateFormat(endTime)) {
        // set endDateTime error if startDateTime is equal to endDateTime
        setEditDrive({
          type: 'endTimeErrTxt',
          payload: 'End date cannot be equal to start date and time',
        });
      } else {
        drivesContactsAttributes = [];
        setSection(ADD_QUESTIONS_SECTION);
      }
    });
  };

  const onAddQuestionsBack = () => {
    setSection(DRIVE_DETAILS_SECTION);
  };

  const onAddQuestionsNext = () => {
    const { currentProblems, currentQuestions } = editDrive;

    const newProblems = [];

    for (let i = 0; i < currentProblems.length; i += 1) {
      let flg = 1;
      for (let j = 0; j < driveDetails.drive.drives_problems.length; j += 1) {
        if (
          driveDetails.drive.drives_problems[j].problem_id ===
          currentProblems[i]
        ) {
          flg = 0;
          break;
        }
      }
      if (flg) newProblems.push(currentProblems[i]);
    }

    const oldProblems = driveDetails.drive.drives_problems.filter(
      (x) => !currentProblems.includes(x.problem_id),
    );

    let drivesProblemsAttributes = [];
    for (let i = 0; i < oldProblems.length; i += 1) {
      drivesProblemsAttributes.push({
        id: oldProblems[i].id,
        problem_id: oldProblems[i].problem_id,
        _destroy: true,
      });
    }

    for (let i = 0; i < newProblems.length; i += 1) {
      drivesProblemsAttributes.push({
        problem_id: newProblems[i],
        _destroy: false,
      });
    }

    const newQuestions = [];
    for (let i = 0; i < currentQuestions.length; i += 1) {
      let flg = 1;
      for (let j = 0; j < driveDetails.drive.drives_questions.length; j += 1) {
        if (
          driveDetails.drive.drives_questions[j].question_id ===
          currentQuestions[i]
        ) {
          flg = 0;
          break;
        }
      }
      if (flg) newQuestions.push(currentQuestions[i]);
    }

    const oldQuestions = driveDetails.drive.drives_questions.filter(
      (x) => !currentQuestions.includes(x.question_id),
    );
    let drivesQuestionsAttributes = [];

    for (let i = 0; i < oldQuestions.length; i += 1) {
      drivesQuestionsAttributes.push({
        id: oldQuestions[i].id,
        question_id: oldQuestions[i].question_id,
        _destroy: true,
      });
    }

    for (let i = 0; i < newQuestions.length; i += 1) {
      drivesQuestionsAttributes.push({
        question_id: newQuestions[i],
        _destroy: false,
      });
    }

    const validData = {
      problemsCount: currentProblems?.length + currentQuestions?.length,
    };

    schemaAddQuestions.isValid(validData).then(async (valid) => {
      if (!valid) {
        validateData(schemaAddQuestions, validData, setEditDrive);
      } else {
        drivesProblemsAttributes = [];
        drivesQuestionsAttributes = [];
        setSection(ADD_RULES_SECTION);
      }
    });
  };

  const onAddNewRulesBack = () => {
    setSection(ADD_QUESTIONS_SECTION);
  };

  const onEditDriveSubmit = () => {
    const {
      data: {
        drive: { id, name, description, start_time, end_time, is_assessment },
      },
      contacts,
      currentProblems,
      currentQuestions,
    } = editDrive;

    const newContacts = [];
    let drivesContactsAttributes = [];

    for (let i = 0; i < contacts.length; i += 1) {
      let flg = 1;
      for (let j = 0; j < driveDetails.drive.drives_contacts.length; j += 1) {
        if (driveDetails.drive.drives_contacts[j].user_id === contacts[i]) {
          flg = 0;
          break;
        }
      }
      if (flg) newContacts.push(contacts[i]);
    }

    const oldcontacts = driveDetails.drive.drives_contacts.filter(
      (x) => !contacts.includes(x.user_id),
    );

    for (let i = 0; i < oldcontacts.length; i += 1) {
      drivesContactsAttributes.push({
        id: oldcontacts[i].id,
        user_id: oldcontacts[i].user_id,
        _destroy: true,
      });
    }

    for (let i = 0; i < newContacts.length; i += 1) {
      drivesContactsAttributes.push({
        user_id: newContacts[i],
        _destroy: false,
      });
    }

    const newProblems = [];

    for (let i = 0; i < currentProblems.length; i += 1) {
      let flg = 1;
      for (let j = 0; j < driveDetails.drive.drives_problems.length; j += 1) {
        if (
          driveDetails.drive.drives_problems[j].problem_id ===
          currentProblems[i]
        ) {
          flg = 0;
          break;
        }
      }
      if (flg) newProblems.push(currentProblems[i]);
    }

    const oldProblems = driveDetails.drive.drives_problems.filter(
      (x) => !currentProblems.includes(x.problem_id),
    );

    let drivesProblemsAttributes = [];
    for (let i = 0; i < oldProblems.length; i += 1) {
      drivesProblemsAttributes.push({
        id: oldProblems[i].id,
        problem_id: oldProblems[i].problem_id,
        _destroy: true,
      });
    }

    for (let i = 0; i < newProblems.length; i += 1) {
      drivesProblemsAttributes.push({
        problem_id: newProblems[i],
        _destroy: false,
      });
    }

    const newQuestions = [];
    for (let i = 0; i < currentQuestions.length; i += 1) {
      let flg = 1;
      for (let j = 0; j < driveDetails.drive.drives_questions.length; j += 1) {
        if (
          driveDetails.drive.drives_questions[j].question_id ===
          currentQuestions[i]
        ) {
          flg = 0;
          break;
        }
      }
      if (flg) newQuestions.push(currentQuestions[i]);
    }

    const oldQuestions = driveDetails.drive.drives_questions.filter(
      (x) => !currentQuestions.includes(x.question_id),
    );

    let drivesQuestionsAttributes = [];
    for (let i = 0; i < oldQuestions.length; i += 1) {
      drivesQuestionsAttributes.push({
        id: oldQuestions[i].id,
        question_id: oldQuestions[i].question_id,
        _destroy: true,
      });
    }

    for (let i = 0; i < newQuestions.length; i += 1) {
      drivesQuestionsAttributes.push({
        question_id: newQuestions[i],
        _destroy: false,
      });
    }

    const putData = {
      name,
      description,
      start_time,
      end_time,
      is_assessment,
      drives_contacts_attributes: drivesContactsAttributes,
      drives_problems_attributes: drivesProblemsAttributes,
      drives_questions_attributes: drivesQuestionsAttributes,
    };

    dispatch(editDriveRequestAction({ putData, driveId }));
    drivesProblemsAttributes = [];
    drivesContactsAttributes = [];
    drivesQuestionsAttributes = [];
  };

  const handleSelectedQuestionChange = useCallback(
    (event) => {
      if (event.target.checked) {
        setEditDrive({
          type: 'problemErrTxt',
          payload: '',
        });
        setEditDrive({
          type: 'question',
          payload: [
            ...editDrive.currentQuestions,
            ...[parseInt(event.target.value, 10)],
          ],
        });
      } else {
        const refactoredArray = editDrive.currentQuestions.filter(
          (item) => item !== parseInt(event.target.value, 10),
        );
        setEditDrive({
          type: 'question',
          payload: [...refactoredArray],
        });
      }
    },
    [editDrive.currentQuestions],
  );

  if (problemIsLoading) {
    return <Spinner />;
  }

  return (
    <EditDriveComponent
      handleDriveDescriptionChange={handleDriveDescriptionChange}
      handleDriveEndChange={handleDriveEndChange}
      handleDriveNameChange={handleDriveNameChange}
      handleDriveStartChange={handleDriveStartChange}
      problemIsLoading={isProblemsLoading}
      handleSelectedProblemChange={handleSelectedProblemChange}
      data={problems}
      editDrive={editDrive}
      driveDetails={driveDetails}
      onDriveDetailsNext={onDriveDetailsNext}
      onAddQuestionsBack={onAddQuestionsBack}
      onAddQuestionsNext={onAddQuestionsNext}
      onAddNewRulesBack={onAddNewRulesBack}
      onEditDriveSubmit={onEditDriveSubmit}
      message={message}
      nameErrTxt={editDrive.nameErrTxt}
      descriptionErrTxt={editDrive.descriptionErrTxt}
      handleIsAssessmentChange={handleIsAssessmentChange}
      problemErrTxt={editDrive.problemErrTxt}
      selectedProblems={selectedProblems}
      handleSelectedContactsChange={handleSelectedContactsChange}
      defaultContacts={defaultContacts}
      contactsData={contactsList}
      contactsErrTxt={editDrive.contactsErrTxt}
      endTimeErrTxt={editDrive.endTimeErrTxt}
      startTimeErrTxt={editDrive.startTimeErrTxt}
      selectedQuestions={editDrive.currentQuestions}
      questions={questions}
      isQuestionsLoading={isQuestionsLoading}
      handleSelectedQuestionChange={handleSelectedQuestionChange}
      questionErrTxt={editDrive.questionErrTxt}
      isContactsLoading={isContactsLoading}
      handleSearchInputChange={handleSearchInputChange}
      handleFilterTagsChange={handleFilterTagsChange}
      handleFilterDifficultyChange={handleFilterDifficultyChange}
      searchButtonClick={searchButtonClick}
      resetButtonClick={resetButtonClick}
      searchText={searchText}
      filterTags={filterTags}
      filterDifficulty={filterDifficulty}
      handleFilterQuestionBox={handleFilterQuestionBox}
      showQuestionFilters={showQuestionFilters}
      selectedQuestionsDetails={selectedQuestionsDetails}
      handleSearchProbInputChange={handleSearchProbInputChange}
      handleFilterProbTagsChange={handleFilterProbTagsChange}
      handleFilterProbDifficultyChange={handleFilterProbDifficultyChange}
      searchProbButtonClick={searchProbButtonClick}
      resetProbButtonClick={resetProbButtonClick}
      searchProbText={searchProbText}
      filterProbTags={filterProbTags}
      filterProbDifficulty={filterProbDifficulty}
      handleFilterProbBox={handleFilterProbBox}
      showProbFilters={showProbFilters}
      selectedProbDetails={selectedProbDetails}
      allowCoding={allowCoding}
      allowMcq={allowMcq}
      tagsOptions={tagsOptions}
      difficultyOptions={difficultyOptions}
      section={section}
      driveType={driveType}
      isLoading={isLoading}
    />
  );
};

export default EditDriveContainer;
