import React from 'react';
import PropTypes from 'prop-types';

import { Toast } from 'core-components';
import { SiteLogo } from 'shared-components';
import SignupCard from './SignupCard/signupCard';

import './adminLoginStyle.css';
import 'modules/admin/login/LoginComponent/loginLayout.scss';

const AcceptInvitationComponent = (props) => {
  const {
    handleFirstNameChange,
    handleLastNameChange,
    handleMobileNumberChange,
    handlePasswordConfirmationChange,
    handlePasswordChange,
    handleSubmit,
    signUpState,
    isError,
    isLoading,
    errorMessage,
    passwordVisibility,
    setPasswordVisibility,
    confirmPasswordVisibility,
    setConfirmPasswordVisibility,
    handleOrganizationNameChange,
  } = props;

  const invalidUser = () => {
    if (isError) {
      return (
        <Toast className='bg-transparent p-2 border-0 shadow-0 text-center'>
          <p>{errorMessage}</p>
          <p>Unauthorized User</p>
        </Toast>
      );
    }
  };

  return (
    <section className='login-section'>
      <div className='login-wrapper position-relative h-100'>
        <div className='spark-code-header py-4'>
          <div className='container'>
            <SiteLogo />
          </div>
        </div>
        <SignupCard
          handleFirstNameChange={handleFirstNameChange}
          handleLastNameChange={handleLastNameChange}
          handleMobileNumberChange={handleMobileNumberChange}
          handlePasswordConfirmationChange={handlePasswordConfirmationChange}
          handlePasswordChange={handlePasswordChange}
          handleSubmit={handleSubmit}
          signUpState={signUpState}
          isError={isError}
          isLoading={isLoading}
          errorMessage={errorMessage}
          passwordVisibility={passwordVisibility}
          setPasswordVisibility={setPasswordVisibility}
          confirmPasswordVisibility={confirmPasswordVisibility}
          setConfirmPasswordVisibility={setConfirmPasswordVisibility}
          handleOrganizationNameChange={handleOrganizationNameChange}
        />
      </div>
    </section>
  );
};

AcceptInvitationComponent.propTypes = {
  handleFirstNameChange: PropTypes.func.isRequired,
  handleLastNameChange: PropTypes.func.isRequired,
  handleMobileNumberChange: PropTypes.func.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  handlePasswordConfirmationChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  signUpState: PropTypes.objectOf(PropTypes.any).isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  passwordVisibility: PropTypes.bool.isRequired,
  setPasswordVisibility: PropTypes.func.isRequired,
  confirmPasswordVisibility: PropTypes.bool.isRequired,
  setConfirmPasswordVisibility: PropTypes.func.isRequired,
  handleOrganizationNameChange: PropTypes.func.isRequired,
};

export default React.memo(AcceptInvitationComponent);
