import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { COMPANY_SIGN_UP, SIGN_UP_TOKEN_VALIDITY } from './actionConstants';
import {
  companySignUpSuccess,
  companySignUpFailure,
  signUpTokenValidityFailure,
  signUpTokenValiditySuccess,
} from './actions';
import { companySignUpRequest, signUpTokenValidityCheckApi } from './api';

export function* signUpCompany(action) {
  const data = action.payload;
  try {
    const response = yield call(companySignUpRequest, data);
    toast.success('Your account has been created successfully. Please login');
    yield put(companySignUpSuccess());
  } catch (error) {
    toast.error(error.response.data.message);
    yield put(companySignUpFailure());
  }
}

export function* signUpTokenValidityCheckSaga(action) {
  const data = action.payload;
  try {
    yield call(signUpTokenValidityCheckApi, data);
    yield put(signUpTokenValiditySuccess());
  } catch (error) {
    yield put(signUpTokenValidityFailure());
  }
}

export default function* signUpCompanySaga() {
  yield takeLatest(COMPANY_SIGN_UP.COMPANY_SIGN_UP_REQUEST, signUpCompany);
  yield takeLatest(
    SIGN_UP_TOKEN_VALIDITY.SIGN_UP_TOKEN_VALIDITY_REQUEST, signUpTokenValidityCheckSaga,
  );
}
