import { MCQ_QUESTION } from 'constants/actionConstants';

export const mcqQuestionsRequest = () => ({
  type: MCQ_QUESTION.MCQ_QUESTION_REQUEST_ACTION,
});

export const mcqQuestionsSuccess = (problems) => ({
  type: MCQ_QUESTION.MCQ_QUESTION_SUCCESS_ACTION,
  payload: problems,
});

export const mcqQuestionSelection = (id) => ({
  type: MCQ_QUESTION.SELECT_MCQ,
  payload: id,
});

export const saveMCQAnswerRequest = (mcqQuestion) => ({
  type: MCQ_QUESTION.SAVE_QUESTION_REQUEST_ACTION,
  payload: mcqQuestion,
});

export const saveMCQAnswerSuccess = (mcqAnswer) => ({
  type: MCQ_QUESTION.SAVE_QUESTION_SUCCESS_ACTION,
  payload: mcqAnswer,
});

export const selectMCQAnswerRequest = (mcqQuestions) => ({
  type: MCQ_QUESTION.SELECT_ANSWER_REQUEST_ACTION,
  payload: mcqQuestions,
});

export const mcqQuestionSubmission = () => ({
  type: MCQ_QUESTION.FINAL_MCQ_SUBMISSION,
});

export const clearSavedMessage = () => ({
  type: MCQ_QUESTION.CLEAR_SAVE_MESSAGE,
});
