import { EDIT_PROBLEM } from 'redux/admin/editProblem/actionConstants';

const {
  EDIT_PROBLEM_SUCCESS_ACTION,
  EDIT_PROBLEM_FAILURE_ACTION,
  EDIT_PROBLEM_REQUEST_ACTION,
  SET_PROBLEM_ID,
  CLEAR_PROBLEM,
  EDIT_PROBLEM_IMAGE_ACTION,
  SET_UPLOAD_IMAGES_SUCCESS,
} = EDIT_PROBLEM;

// request
export const editProblemRequestAction = (payload) => ({
  type: EDIT_PROBLEM_REQUEST_ACTION,
  payload,
});

// success
export const editProblemSuccessAction = (payload) => ({
  type: EDIT_PROBLEM_SUCCESS_ACTION,
  payload,
});

// failure
export const editProblemFailureAction = (payload) => ({
  type: EDIT_PROBLEM_FAILURE_ACTION,
  payload,
});

export const setProblemId = (payload) => ({
  type: SET_PROBLEM_ID,
  payload,
});

export const clearProblem = () => ({
  type: CLEAR_PROBLEM,
});

export const setProblemImageAction = (payload) => ({
  type: EDIT_PROBLEM_IMAGE_ACTION,
  payload,
});
