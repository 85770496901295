import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { resendAssessmentEmails } from 'modules/admin/sendEmailInvite/SendEmailInviteContainer/sendEmails';
import ShowCandidateListComponent from 'modules/admin/showCandidateList/ShowCandidateListComponent';
import SendEmailInviteContainer from 'modules/admin/sendEmailInvite/SendEmailInviteContainer';
import { setAddCandidateModal } from 'redux/admin/modalStates/action';
import { remainingCreditsRequest } from 'redux/admin/remainingCredits/action';
import { assessementCandidatesListRequest, candidateStatus } from 'redux/admin/assessmentCandidates/action';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';

const AssessmentCandidateListContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { assessmentId } = useParams();
  const id = assessmentId;

  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('Default');
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [updateCandidateList, setUpdateCandidateList] = useState(false);
  const [sendInvite, setSendInvite] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const { addCandidateModal } = useSelector((state) => state.modalStatusReducer);
  const {
    candidates,
    totalCandidatesPages,
    assessmentName,
    totalCandidates,
  } = useSelector((state) => state.assessmentCandidatesReducer);
  useEffect(() => {
    dispatch(assessementCandidatesListRequest({ id: assessmentId, searchQuery, activeTab }));
  }, [dispatch, updateCandidateList]);

  // Adding candidate id in isCheckDefaultValue array for furthur use.
  useEffect(() => {
    const isCheckDefaultValue = {};
    candidates.forEach((candidate) => {
      isCheckDefaultValue[`${candidate.id}`] =  false;
    });
    setIsCheck(isCheckDefaultValue);
  }, [candidates]);

  useEffect(() => {
    const objectValues = Object.values(isCheck);
    const value = objectValues.length !== 0 && !objectValues.includes(false);
    setIsSelectAllChecked(value);
  }, [isCheck]);

  const handleClick = (e, candidateId) => {
    const { checked } = e.target;
    setIsCheck({ ...isCheck, [`${candidateId}`]: checked });
  };

  const handleSelectAll = () => {
    setIsSelectAllChecked(!isSelectAllChecked);
    let newObj = {};
    Object.keys(isCheck).forEach((candidateId) => {
      newObj = { ...newObj, [`${candidateId}`]: !isSelectAllChecked };
    });
    setIsCheck(newObj);
  };

  const handleResendInvitation = async () => {
    const selectedCandidates = Object.keys(isCheck).filter((k) => isCheck[`${k}`] === true);
    const data = {
      candidate_ids: selectedCandidates,
      id: assessmentId,
    };
    const responseData = await resendAssessmentEmails(data);
    let newObj = {};
    Object.keys(selectedCandidates).forEach((candidateId) => {
      newObj = { ...isCheck, ...newObj, [`${selectedCandidates[candidateId]}`]: !isCheck[`${selectedCandidates[candidateId]}`] };
    });
    setIsCheck(newObj);
    toast.success(responseData.data.message);
  };

  const handleTabChange = (tabName) => {
    const params = {
      id: assessmentId,
      searchQuery: '',
      absentCandidates: tabName === 'absentCandidate',
    };
    setActiveTab(tabName);
    dispatch(assessementCandidatesListRequest(params));
  };

  const handleAddCandidateClick = () => {
    dispatch(setAddCandidateModal(true));
    dispatch(candidateStatus(true));
    dispatch(remainingCreditsRequest());
  };

  const handlePageClick = (data) => {
    dispatch(assessementCandidatesListRequest({ id, page: data.selected + 1, searchQuery }));
  };

  const previousClick = () => {
    history.push(ROUTES.ADMIN + ADMIN_ROUTES.ASSESSMENTS);
  };

  const handleOnChange = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, [searchQuery]);

  const handleSearchClick = () => {
    if (searchQuery || isSearched) {
      dispatch(assessementCandidatesListRequest({ id, searchQuery: searchQuery.trim() }));
      if (!searchQuery && isSearched) {
        setIsSearched(false);
      } else {
        setIsSearched(true);
      }
    }
  };

  const handleCandidateSearchClearClick = () => {
    setSearchQuery('');
    if (isSearched) {
      dispatch(assessementCandidatesListRequest({ id, searchQuery: '' }));
    }
    setIsSearched(false);
  };

  useEffect(() => {
    dispatch(assessementCandidatesListRequest({ id, searchQuery }));
  }, [dispatch, updateCandidateList]);

  return (
    <>
      <ShowCandidateListComponent
        handleAddCandidateClick={handleAddCandidateClick}
        handlePageClick={handlePageClick}
        pageCount={totalCandidatesPages}
        candidateIsLoading={false}
        previousClick={previousClick}
        addCandidateModal={addCandidateModal}
        driveName={assessmentName}
        totalCandidates={totalCandidates}
        handleOnChange={handleOnChange}
        handleSearchClick={handleSearchClick}
        searchQuery={searchQuery}
        handleCandidateSearchClearClick={handleCandidateSearchClearClick}
        allCandidates={candidates}
        handleTabChange={handleTabChange}
        activeTab={activeTab}
        handleSelectAll={handleSelectAll}
        handleClick={handleClick}
        handleResendInvitation={handleResendInvitation}
        isCheck={isCheck}
        isSelectAllChecked={isSelectAllChecked}
      />
      <SendEmailInviteContainer
        updateCandidateList={updateCandidateList}
        setUpdateCandidateList={setUpdateCandidateList}
        setSendInvite={setSendInvite}
        sendInvite={sendInvite}
        name={assessmentName}
      />
    </>
  );
};

export default AssessmentCandidateListContainer;
