import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { csvFormatErrorMessage } from 'constants/messageConstants';

import {
  importFileAction,
  setFileName,
  setFile,
  resetFilePath,
  startFileImport,
  setFileNameError,
  downloadFileSuccessAction,
} from 'redux/admin/mcqUpload/action';

import CSVUploadComponent from 'components/CSVUploadComponent';

const supportedformats = ['csv'];

const McqBulkUploadContainer = () => {
  const dispatch = useDispatch();
  const { fileName, fileChoosen, filePath, isImporting,
    showError, fileNameError } = useSelector(
    (state) => state.mcqBulkUploadReducer,
  );
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const schema = yup.object().shape({
    fileName: yup
      .string()
      .test('required', 'Please choose a File', (value) => {
        if (value) {
          return true;
        }
        return false;
      })
      .test('extension', csvFormatErrorMessage, (value) => {
        if (value) {
          const ext = value.substr(value.lastIndexOf('.') + 1);
          return supportedformats.includes(ext);
        }
        return false;
      }),
  });

  const onFileChoose = (e) => {
    if (e.target.files[0]) {
      dispatch(setFileNameError(''));
      dispatch(setFile(e.target.files[0]));
      dispatch(setFileName(e.target.files[0].name));
    }
  };
  const onModalClose = () => {
    dispatch(setFile({}));
    dispatch(setFileName(''));
    dispatch(setFileNameError(''));
    dispatch(resetFilePath());
    toggle();
  };
  const onFormSubmit = () => {
    schema
      .validate(
        {
          fileName,
        },
        { abortEarly: false },
      )
      .then(() => {
        const data = {
          fileName,
        };
        dispatch(resetFilePath());
        dispatch(
          importFileAction({ file: fileChoosen }),
        );
        dispatch(startFileImport());
        if (!isImporting) toggle();
      })
      .catch((error) => {
        error.inner.forEach((e) => {
          dispatch(setFileNameError(e.message));
        });
      });
  };
  const onDownload = () => {
    dispatch(downloadFileSuccessAction(filePath));
  };

  return (
    <CSVUploadComponent
      chooseFile={onFileChoose}
      fileSubmit={onFormSubmit}
      showError={showError}
      onModalClose={onModalClose}
      isImporting={isImporting}
      fileNameError={fileNameError}
      toggle={toggle}
      modal={modal}
      onDownload={onDownload}
      label='Import MCQ'
      sampleCSVFile='sampleQuestions.csv'
    />
  );
};

export default McqBulkUploadContainer;
