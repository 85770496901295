/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes, { shape, string } from 'prop-types';

import {
  Container,
  FormGroup,
  Label,
  Input,
  Spinner,
  Icon,
} from 'core-components';
import FilterComponent from 'modules/admin/createDrive/CreateDriveComponent/filterComponent';

import './createDriveComponent.scss';

const AddProblemComponent = (props) => {
  const {
    handleSelectedProblemChange,
    data,
    problemIsLoading,
    handleSearchProbInputChange,
    handleFilterProbTagsChange,
    handleFilterProbDifficultyChange,
    searchProbButtonClick,
    resetProbButtonClick,
    searchProbText,
    filterProbTags,
    filterProbDifficulty,
    handleFilterProbBox,
    showProbFilters,
    selectedProbDetails,
    tagsOptions,
    difficultyOptions,
  } = props;

  if (problemIsLoading) {
    return (
      <Container className='d-flex justify-content-center align-content'>
        <Spinner />
      </Container>
    );
  }
  const selectedQuestionsCount = selectedProbDetails.length;
  const options = [];
  data.map((e) => {
    const flg = (selectedProbDetails.find((x) => x.id === e.id));
    return (!flg && options.push({ value: e.id, label: e.title, difficulty: e.difficulty }));
  });

  return (
    <div className='new-drive-col scroll-y d-flex flex-column flex-1'>
      <div className='new-drive-content-head d-flex align-items-center'>
        <label className='mb-0'>Add Coding Problem to Drive</label>
        <div className='d-flex ml-auto align-items-center'>
          <Icon name='search' onClick={handleFilterProbBox} className='font-weight-bold search-icon' />
          <span className='count-box px-3 py-2 ml-3'>
            {selectedQuestionsCount} Selected
          </span>
        </div>
      </div>
      <div className='new-drive-content-body d-flex my-3 scroll-y'>
        <div className='scroll-y d-flex flex-column flex-1'>
          {showProbFilters && (
            <FilterComponent
              handleSearchInputChange={handleSearchProbInputChange}
              handleFilterTagsChange={handleFilterProbTagsChange}
              handleFilterDifficultyChange={handleFilterProbDifficultyChange}
              searchButtonClick={searchProbButtonClick}
              resetButtonClick={resetProbButtonClick}
              searchText={searchProbText}
              searchPlaceholder='Problems'
              filterTags={filterProbTags}
              filterDifficulty={filterProbDifficulty}
              tagsOptions={tagsOptions}
              difficultyOptions={difficultyOptions}
            />
          )}
          <ol className='list-wrap list-unstyled mb-0 pl-0 d-flex flex-column'>
            {selectedProbDetails.map((item) => {
              return (
                <li className='list-item p-3'>
                  <FormGroup check>
                    <div className='font-weight-normal d-flex align-items-center'>
                      <Input
                        type='checkbox'
                        name='check'
                        id='exampleCheck'
                        className='mt-0'
                        checked
                        value={item.id}
                        onChange={(e) => handleSelectedProblemChange(e)}
                      />
                      <span className='overflow-auto'>{item.title}</span>
                      <div className='difficulty-level mr-5 flex-shrink-0 text-center'>
                        <span className='px-2'>{item.difficulty}</span>
                      </div>
                    </div>
                  </FormGroup>
                </li>
              );
            })}
          </ol>
          <ol className='list-wrap list-unstyled mb-0 pl-0 d-flex flex-column mt-1'>
            {options.map((item) => {
              return (
                <li className='list-item p-3'>
                  <FormGroup check>
                    <div className='font-weight-normal d-flex align-items-center'>
                      <Input
                        type='checkbox'
                        name='check'
                        id='exampleCheck'
                        checked={false}
                        className='mt-0'
                        value={item.value}
                        onChange={(e) => handleSelectedProblemChange(e)}
                      />
                      <span className='overflow-auto'>{item.label}</span>
                      <div className='difficulty-level mr-5 flex-shrink-0 text-center'>
                        <span className='px-2'>{item.difficulty}</span>
                      </div>
                    </div>
                  </FormGroup>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </div>
  );
};

AddProblemComponent.propTypes = {
  handleSelectedProblemChange: PropTypes.func.isRequired,
  data: PropTypes.string.isRequired,
  problemIsLoading: PropTypes.bool.isRequired,
  handleSearchProbInputChange: PropTypes.func.isRequired,
  handleFilterProbTagsChange: PropTypes.func.isRequired,
  handleFilterProbDifficultyChange: PropTypes.func.isRequired,
  searchProbButtonClick: PropTypes.func.isRequired,
  resetProbButtonClick: PropTypes.func.isRequired,
  searchProbText: PropTypes.string.isRequired,
  filterProbTags: PropTypes.objectOf(PropTypes.object).isRequired,
  filterProbDifficulty: PropTypes.string.isRequired,
  handleFilterProbBox: PropTypes.func.isRequired,
  showProbFilters: PropTypes.bool.isRequired,
  selectedProbDetails: PropTypes.objectOf(PropTypes.object).isRequired,
  tagsOptions: PropTypes.arrayOf(string).isRequired,
  difficultyOptions: PropTypes.arrayOf(shape).isRequired,
};

export default React.memo(AddProblemComponent);
