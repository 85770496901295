import produce from 'immer';
import { REMAINING_CREDITS } from 'redux/admin/remainingCredits/actionContants';

export const initialState = {
  credits: 0,
  errorMessage: '',
  isCreditsLoading: false,
};

const remainingCreditsReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case REMAINING_CREDITS.REMAINING_CREDITS_REQUEST_ACTION:
      return {
        ...state,
        isCreditsLoading: true,
      };
    case REMAINING_CREDITS.REMAINING_CREDITS_SUCCESS_ACTION:
      return {
        ...state,
        credits: payload.credits,
        isCreditsLoading: false,
      };
    case REMAINING_CREDITS.REMAINING_CREDITS_FAILURE_ACTION:
      return {
        ...state,
        errorMessage: payload,
        isCreditsLoading: false,
      };
    default:
      return state;
  }
});
export default remainingCreditsReducer;
