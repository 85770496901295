import { PROBLEMS_BULK_UPLOAD_ACTIONS } from './actionConstants';

export const initialState = {
  isLoading: false,
  fileName: '',
  fileChoosen: {},
  isRefetchProblemList: false,
  filePath: '',
  showError: false,
  isImporting: false,
  fileNameError: '',
};
const fileSaver = require('file-saver');

const {
  PROBLEM_SET_FILE_NAME,
  PROBLEM_SET_FILE,
  PROBLEM_RESET_STATE,
  PROBLEM_FILE_UPLOAD_SUCCESSFULLY,
  PROBLEM_ACTION_FAILED,
  PROBLEM_SET_FILE_PATH,
  PROBLEM_RESET_FILE_PATH,
  PROBLEM_START_FILE_IMPORT,
  PROBLEM_SET_FILE_NAME_ERROR,
  PROBLEM_DOWNLOAD_FILE_SUCCESS_ACTION,
} = PROBLEMS_BULK_UPLOAD_ACTIONS;

const problemBulkUploadReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case PROBLEM_SET_FILE_NAME:
      return {
        ...state,
        fileName: payload,
      };
    case PROBLEM_SET_FILE:
      return {
        ...state,
        fileChoosen: payload,
        isPreviewLoading: true,
      };
    case PROBLEM_RESET_STATE:
      return initialState;
    case PROBLEM_FILE_UPLOAD_SUCCESSFULLY:
      return {
        ...state,
        isRefetchProblemList: true,
        isImporting: false,
      };
    case PROBLEM_ACTION_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case PROBLEM_SET_FILE_PATH:
      return {
        ...state,
        filePath: payload,
        showError: true,
        isRefetchProblemList: false,
      };
    case PROBLEM_RESET_FILE_PATH:
      return {
        ...state,
        filePath: '',
        showError: false,
        isImporting: false,
        isRefetchProblemList: false,
      };
    case PROBLEM_START_FILE_IMPORT:
      return {
        ...state,
        isImporting: true,
        isRefetchProblemList: false,
      };
    case PROBLEM_SET_FILE_NAME_ERROR:
      return {
        ...state,
        fileNameError: payload,
      };
    case PROBLEM_DOWNLOAD_FILE_SUCCESS_ACTION:
      fileSaver.saveAs(new Blob([payload], { type: 'application/octet-stream' }), 'problem-error.csv');
      return { ...state };
    default:
      return state;
  }
};

export default problemBulkUploadReducer;
