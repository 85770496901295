import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { testListAction } from 'redux/admin/testCaseList/action';
import { getTestCasesApi } from 'redux/admin/testCaseList/api';
import { TESTCASE_LIST } from 'redux/admin/testCaseList/actionContants';

export function* getTestCase(action) {
  try {
    const response = yield call(getTestCasesApi, action?.payload);
    yield put(testListAction(response.data.data.test_cases));
  } catch (error) {
    const { message } = error.response.data;
    toast.error(message);
  }
}

export default function* testCaseWatcherSaga() {
  yield takeEvery(TESTCASE_LIST.TESTCASE_LIST_REQUEST_ACTION, getTestCase);
}
