const setItem = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

const getItem = (key) => {
  // can't parse undefined so sending it directly
  if (sessionStorage.getItem(key) === undefined) {
    return undefined;
  }
  // if not undefined then parsing it back from string form
  return JSON.parse(sessionStorage.getItem(key));
};

export default {
  setItem,
  getItem,
};
