import { combineReducers } from 'redux';

import userDriveReducer from 'reducers/userDriveReducer';
import rulesReducer from 'reducers/rulesReducer';
import candidateFormReducer from 'reducers/candidateFormReducer';
import TimerReducer from 'reducers/timerReducer';
import languageReducer from 'reducers/languageReducer';
import problemStatementReducer from 'reducers/problemStatementReducer';
import DriveTimerReducer from 'reducers/driverTimerReducer';
import codeSubmissionReducer from 'reducers/codeSubmissionReducer';
import codeBackupReducer from 'reducers/codeBackupReducer';
import ioReducer from 'reducers/ioReducer';
import sectionStatusReducer from 'reducers/sectionStatusReducer';
import mcqQuestionsReducer from 'reducers/mcqQuestionsReducer';
import adminLoginReducer from 'redux/admin/login/reducer';
import adminHomeComponentReducer from 'modules/admin/home/HomeContainer/adminHomeComponentReducer';
import createDriveReducer from 'redux/admin/createDrive/reducer';
import editDriveReducer from 'redux/admin/editDrive/reducer';
import finishTestReducer from 'reducers/finishTestReducer';
import createProblemReducer from 'redux/admin/createProblem/reducer';
import editProblemReducer from 'redux/admin/editProblem/reducer';
import testReducer from 'redux/admin/testCase/reducer';
import driveResultReducer from 'redux/admin/driveResult/reducer';
import downloadResultReducer from 'redux/admin/downloadResult/reducer';
import testCaseListReducer from 'redux/admin/testCaseList/reducer';
import problemsListReducer from 'redux/admin/problemsList/reducer';
import testCaseReducer from 'redux/admin/testCase/testCaseReducer';
import modalStatusReducer from 'redux/admin/modalStates/reducer';
import candidateReportReducer from 'redux/admin/candidateReport/reducer';
import driveListReducer from 'redux/admin/driveList/reducer';
import assessmentsListReducer from 'redux/admin/assessmentList/reducer';
import createAssessmentReducer from 'redux/admin/createAssessment/reducer';
import editAssessmentReducer from 'redux/admin/editAssessment/reducer';
import assessmentCandidatesReducer from 'redux/admin/assessmentCandidates/reducer';
import adminSignupReducer from 'redux/admin/signup/reducer';
import forgotPasswordReducer from 'redux/admin/forgotPassword/reducer';
import verifyPasswordReducer from 'redux/admin/verifyForgotPassword/reducer';
import organizationListReducer from 'redux/superAdmin/organizationList/reducer';
import superAdminModalStatusReducer from 'redux/superAdmin/modalStates/reducer';
import remainingCreditsReducer from 'redux/admin/remainingCredits/reducer';
import driveSearchReducer from 'redux/admin/driveSearch/reducer';
import feedbackListReducer from 'redux/admin/feedbacks/reducer';
import questionsListReducer from 'redux/admin/questions/reducer';
import createQuestionReducer from 'redux/admin/createQuestion/reducer';
import editQuestionReducer from 'redux/admin/editQuestion/reducer';
import sideNavStateReducer from 'redux/admin/sideNavState/reducer';
import getProblemsReducer from 'redux/admin/problems/reducer';
import getContactsReducer from 'redux/admin/getContacts/reducer';
import getEditDriveReducer from 'redux/admin/getEditDrive/reducer';
import questionsReducer from 'redux/admin/questionsList/reducer';
import getMcqCountReducer from 'redux/admin/getMcqCount/reducer';
import getActiveTab from 'redux/admin/questionTabs/reducer';
import editOrganizationReducer from 'redux/superAdmin/editOrganization/reducer';
import tagsReducer from 'redux/admin/tags/reducer';
import getDriveContactsReducer from 'redux/admin/getDriveContacts/reducer';
import mcqBulkUploadReducer from 'redux/admin/mcqUpload/reducer';
import templateReducer from 'redux/admin/template/reducer';
import candidateListReducer from 'redux/admin/candidateList/reducer';
import downloadMcqReducer from 'redux/admin/downloadMcq/reducer';
import problemBulkUploadReducer from 'redux/admin/problemUpload/reducer';
import { signUpUser } from 'redux/admin/signUpCompany/reducer';

const rootReducer = combineReducers({
  rulesReducer,
  candidateFormReducer,
  userDriveReducer,
  TimerReducer,
  signUpUser,
  languageReducer,
  problemStatementReducer,
  DriveTimerReducer,
  codeSubmissionReducer,
  adminLoginReducer,
  adminHomeComponentReducer,
  createDriveReducer,
  editDriveReducer,
  finishTestReducer,
  createProblemReducer,
  editProblemReducer,
  testReducer,
  driveResultReducer,
  downloadResultReducer,
  codeBackupReducer,
  ioReducer,
  testCaseListReducer,
  testCaseReducer,
  modalStatusReducer,
  problemsListReducer,
  candidateReportReducer,
  driveListReducer,
  assessmentsListReducer,
  createAssessmentReducer,
  editAssessmentReducer,
  assessmentCandidatesReducer,
  adminSignupReducer,
  forgotPasswordReducer,
  verifyPasswordReducer,
  organizationListReducer,
  superAdminModalStatusReducer,
  remainingCreditsReducer,
  driveSearchReducer,
  feedbackListReducer,
  questionsListReducer,
  createQuestionReducer,
  editQuestionReducer,
  getProblemsReducer,
  getContactsReducer,
  getEditDriveReducer,
  sectionStatusReducer,
  mcqQuestionsReducer,
  sideNavStateReducer,
  questionsReducer,
  getMcqCountReducer,
  getActiveTab,
  editOrganizationReducer,
  tagsReducer,
  getDriveContactsReducer,
  mcqBulkUploadReducer,
  templateReducer,
  candidateListReducer,
  downloadMcqReducer,
  problemBulkUploadReducer,
});

export default rootReducer;
