import produce from 'immer';
import { GET_MCQ_COUNT } from 'redux/admin/getMcqCount/actionContants';

export const initialState = {
  mcqCountData: {},
  isMcqCountLoading: false,
  isMcqCountError: false,
};

const { GET_MCQ_COUNT_REQUEST_ACTION, GET_MCQ_COUNT_SUCCESS_ACTION,
  GET_MCQ_COUNT_FAILURE_ACTION, CLEAR_MCQ_COUNT } = GET_MCQ_COUNT;

const getMcqCountReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_MCQ_COUNT_REQUEST_ACTION:
      state.isMcqCountLoading = true;
      break;
    case GET_MCQ_COUNT_SUCCESS_ACTION:
      state.isMcqCountLoading = false;
      state.mcqCountData = payload;
      break;
    case GET_MCQ_COUNT_FAILURE_ACTION:
      state.isMcqCountError = true;
      state.isMcqCountLoading = false;
      break;
    case CLEAR_MCQ_COUNT:
      return { ...initialState };
    default:
      return state;
  }
});
export default getMcqCountReducer;
