import { DOWNLOAD_RESULT, ABSENT_CANDIDATES } from 'redux/admin/downloadResult/actionConstants';

const {
  DOWNLOAD_ASSESSMENT_RESULT_FAILURE_ACTION,
  DOWNLOAD_ASSESSMENT_RESULT_REQUEST_ACTION,
  DOWNLOAD_ASSESSMENT_RESULT_SUCCESS_ACTION,
  DOWNLOAD_RESULT_FAILURE_ACTION,
  DOWNLOAD_RESULT_REQUEST_ACTION,
  DOWNLOAD_RESULT_SUCCESS_ACTION,
  SET_DOWNLOAD_DRIVE_NAME_ACTION,
} = DOWNLOAD_RESULT;

const {
  ABSENT_CANDIDATES_FAILURE_ACTION,
  ABSENT_CANDIDATES_REQUEST_ACTION,
  ABSENT_CANDIDATES_SUCCESS_ACTION,
  SET_DOWNLOAD_ASSESSMENT_NAME_ACTION,
} = ABSENT_CANDIDATES;

export const downloadResultRequestAction = (payload) => ({
  type: DOWNLOAD_RESULT_REQUEST_ACTION,
  payload,
});

export const downloadResultSuccessAction = (payload) => ({
  type: DOWNLOAD_RESULT_SUCCESS_ACTION,
  payload,
});

export const setDownloadedDriveNameAction = (payload) => ({
  type: SET_DOWNLOAD_DRIVE_NAME_ACTION,
  payload,
});

export const downloadResultFailureAction = (payload) => ({
  type: DOWNLOAD_RESULT_FAILURE_ACTION,
  payload,
});

export const downloadAssessmentResultRequestAction = (payload) => ({
  type: DOWNLOAD_ASSESSMENT_RESULT_REQUEST_ACTION,
  payload,
});

export const downloadAssessmentResultSuccessAction = (payload) => ({
  type: DOWNLOAD_ASSESSMENT_RESULT_SUCCESS_ACTION,
  payload,
});

export const downloadAssessmentResultFailureAction = (payload) => ({
  type: DOWNLOAD_ASSESSMENT_RESULT_FAILURE_ACTION,
  payload,
});

export const absentCandidatesRequestAction = (payload) => ({
  type: ABSENT_CANDIDATES_REQUEST_ACTION,
  payload,
});

export const absentCandidatesSuccessAction = (payload) => ({
  type: ABSENT_CANDIDATES_SUCCESS_ACTION,
  payload,
});

export const absentCandidatesFailureAction = (payload) => ({
  type: ABSENT_CANDIDATES_FAILURE_ACTION,
  payload,
});

export const setDownloadedAssessmentNameAction = (payload) => ({
  type: SET_DOWNLOAD_ASSESSMENT_NAME_ACTION,
  payload,
});
