/* eslint-disable indent */
/* eslint-disable max-len */
import React, { useState, createRef } from 'react';
import PropTypes, { shape, string } from 'prop-types';
import CreatableSelect from 'react-select/creatable';

import {
  Form,
  FormGroup,
  Label,
  Input,
  Icon,
  Card,
} from 'core-components';
import TestCaseContainer from 'modules/admin/testCase/TestCaseContainer';

import { capitalize } from 'utils/capitalize';

import { CustomThemeSelect, CustomThemeMultiSelect } from 'constants/themesConstants';
import { customStyle } from 'constants/stylesConstants';

import './createProblemComponent.scss';
import { ImagePopUpModal } from 'shared-components';

const CreateProblemComponent = (props) => {
  const {
    handleTitleChange,
    handleDescriptionChange,
    handleCountChange,
    handleTimeChange,
    handleSubmit,
    isSuccess,
    isLoading,
    userState,
    handleSelectedDifficultyChange,
    handleSelectedTagsChange,
    problemId,
    tagsOptions,
    difficultyOptions,
    handleAddNextProblemClick,
    handleBackClick,
    handleCaptureProblem,
    selectedImage,
    showImageModal,
    toggleImageModalVisibility,
    problemImageError,
    testCasesList,
    setTestCasesList,
    testCaseError,
    setTestCaseError,
  } = props;
  const scrollRef = createRef();

  const {
    validTitle,
    titleError,
    validSubmissionCount,
    submissionCountError,
    validTime,
    timeInMinutesError,
    difficultyError,
    tagsError,
    validDescription,
    descriptionError,
  } = userState;

  return (
    <div className='add-problem-section py-3 d-flex flex-column'>
      <div className='add-problem-section-head mb-4 px-4 d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <Icon
            name='left'
            className='back-page-arrow font-weight-bold'
            onClick={handleBackClick}
          />
          <h2 className='section-heading font-weight-bold mb-0 ml-3'>
            Add New Problem
          </h2>
        </div>
      </div>
      <div className='problem-content scroll-y'>
        <div className='problem-col-wrapper' ref={scrollRef}>
          <div className='problem-col p-4 position-relative'>
            <Form className='add-problem-form'>
              <div className='d-flex form-group-col-wrapper align-items-start'>
                <div className='form-group-col d-flex'>
                  <FormGroup className='w-100 mb-0'>
                    <Label className='text-truncate px-3'>Problem Title</Label>
                    <Input
                      type='text'
                      placeholder='Enter Problem Title'
                      onChange={handleTitleChange}
                      required
                      invalid={!validTitle}
                    />
                    { titleError && !validTitle && (
                      <div className='text-danger'>
                        {capitalize(titleError)}
                      </div>
                    )}
                  </FormGroup>
                </div>
                <div className='form-group-col d-flex align-items-start'>
                  <FormGroup className='w-50 mb-0'>
                    <Label className='text-truncate px-3'>
                      Submission Count
                    </Label>
                    <Input
                      type='number'
                      placeholder='Enter Submission Count'
                      title='Enter number of Submissions allowed'
                      onChange={handleCountChange}
                      required
                      className='select-input-type'
                      min={0}
                      invalid={!validSubmissionCount}
                    />
                    { submissionCountError && !validSubmissionCount && (
                      <div className='text-danger'>
                        {capitalize(submissionCountError)}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup className='w-50 mb-0'>
                    <Label className='text-truncate px-3'>
                      Time in minutes
                    </Label>
                    <Input
                      type='number'
                      placeholder='Enter Time (minutes)'
                      title='Enter Time allowed in minutes'
                      onChange={handleTimeChange}
                      required
                      className='select-input-type'
                      invalid={!validTime}
                    />
                    { timeInMinutesError && !validTime && (
                      <div className='text-danger'>
                        {capitalize(timeInMinutesError)}
                      </div>
                    )}
                  </FormGroup>
                </div>
              </div>
              {selectedImage && (
                <div
                  className='question-diagram-wrapper'
                  onClick={toggleImageModalVisibility}
                  role='button'
                  tabIndex={0}
                >
                  <img
                    className='image-wrapper'
                    src={selectedImage}
                    alt='Upload Question Img'
                  />
                </div>
              )}
              <ImagePopUpModal
                showImageModal={showImageModal}
                toggleImageModalVisibility={toggleImageModalVisibility}
                imageUrl={selectedImage}
                alternateName='Question Image'
              />
              <FormGroup className='width-fit-content'>
                <Label for='exampleText' className='px-3'>
                  Upload Diagram
                </Label>
                <Input
                  type='file'
                  name='text'
                  id='exampleFile'
                  accept='image/*'
                  onInputCapture={handleCaptureProblem}
                />
              </FormGroup>
              { problemImageError && (
                <div className='text-danger pb-3'>
                  {problemImageError}
                </div>
              )}
              <div className='form-group-col d-flex align-items-start'>
                <FormGroup className='w-50 mb-0'>
                  <Label className='text-truncate px-3'>
                    Difficulty
                  </Label>
                  <CreatableSelect
                    className='mr-3 text-light react-select-container'
                    classNamePrefix='react-select'
                    id='role'
                    value={difficultyOptions.id}
                    onChange={handleSelectedDifficultyChange}
                    options={difficultyOptions}
                    placeholder='Select Difficulty'
                    theme={CustomThemeSelect}
                    styles={customStyle}
                  />
                  {difficultyError && (
                    <div className='text-danger'>
                      {difficultyError}
                    </div>
                  )}
                </FormGroup>
                <FormGroup className='w-50 mb-0'>
                  <Label className='text-truncate px-3'>
                    Tags
                  </Label>
                  <CreatableSelect
                    className='mr-3 text-light react-select-container'
                    classNamePrefix='react-select'
                    id='role'
                    value={tagsOptions.id}
                    onChange={handleSelectedTagsChange}
                    options={tagsOptions}
                    placeholder='Select Tags'
                    isMulti
                    theme={CustomThemeMultiSelect}
                    styles={customStyle}
                  />
                  {tagsError && (
                    <div className='text-danger'>
                      {tagsError}
                    </div>
                  )}
                </FormGroup>
              </div>
              <FormGroup className='mt-3'>
                <Label for='exampleText' className='px-3'>
                  Problem Description
                </Label>
                <Input
                  type='textarea'
                  name='text'
                  id='exampleText'
                  style={{ height: 400 }}
                  placeholder='Enter Problem Description'
                  onChange={handleDescriptionChange}
                  required
                  invalid={!validDescription}
                />
                {descriptionError && !validDescription && (
                  <div className='text-danger'>
                    {capitalize(descriptionError)}
                  </div>
                )}
              </FormGroup>
            </Form>
          </div>
        </div>
        <Card className='bg-card'>
          <div>
            <TestCaseContainer
              isTestCaseCreate
              testCasesList={testCasesList}
              setTestCasesList={setTestCasesList}
              scrollRef={scrollRef}
              handleSubmit={handleSubmit}
              testCaseError={testCaseError}
              setTestCaseError={setTestCaseError}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

CreateProblemComponent.propTypes = {
  handleTitleChange: PropTypes.func.isRequired,
  handleDescriptionChange: PropTypes.func.isRequired,
  handleCountChange: PropTypes.func.isRequired,
  handleTimeChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  userState: PropTypes.shape().isRequired,
  handleSelectedDifficultyChange: PropTypes.func.isRequired,
  handleSelectedTagsChange: PropTypes.func.isRequired,
  problemId: PropTypes.number.isRequired,
  tagsOptions: PropTypes.arrayOf(string).isRequired,
  difficultyOptions: PropTypes.arrayOf(shape).isRequired,
  handleAddNextProblemClick: PropTypes.func.isRequired,
  handleBackClick: PropTypes.func.isRequired,
  handleCaptureProblem: PropTypes.func.isRequired,
  selectedImage: PropTypes.string.isRequired,
  showImageModal: PropTypes.bool.isRequired,
  toggleImageModalVisibility: PropTypes.func.isRequired,
  problemImageError: PropTypes.string.isRequired,
  testCasesList: PropTypes.array.isRequired,
  setTestCasesList: PropTypes.func.isRequired,
  setTestCaseError: PropTypes.object.isRequired,
  testCaseError: PropTypes.object.isRequired,
};

export default React.memo(CreateProblemComponent);
