import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { editAssessmentPutApi, getAssessmentApi } from 'redux/admin/editAssessment/api';
import { EDIT_ASSESSMENT } from 'redux/admin/editAssessment/actionConstants';
import {
  editAssessmentFailureAction,
  editAssessmentSuccessAction,
  getAssessmentFailureAction,
  getAssessmentSuccessAction,
} from 'redux/admin/editAssessment/action';

const { EDIT_ASSESSMENT_REQUEST_ACTION, GET_ASSESSMENT_REQUEST_ACTION } = EDIT_ASSESSMENT;

export function* editAssessmentSaga(action) {
  try {
    const response = yield call(editAssessmentPutApi, action.payload);
    toast.success(response.data.message);
    yield put(editAssessmentSuccessAction(response.data));
  } catch (error) {
    toast.error(error.response.data.message);
    yield put(editAssessmentFailureAction(error.response.data));
  }
}

export function* getAssessmentSaga(action) {
  try {
    const response = yield call(getAssessmentApi, action.payload);
    yield put(getAssessmentSuccessAction(response.data.data));
  } catch (error) {
    yield put(getAssessmentFailureAction(error.response.data));
  }
}

export default function* editAssessmentWatcherSaga() {
  yield takeLatest(EDIT_ASSESSMENT_REQUEST_ACTION, editAssessmentSaga);
  yield takeLatest(GET_ASSESSMENT_REQUEST_ACTION, getAssessmentSaga);
}
