import { get, destroy } from 'redux/admin/apiHelper';
import { SERVER_URL, DRIVES_LIMIT } from 'constants/appConstants';
import queryStringHelper from 'utils/queryStringHelper';

// TODO
// repplace driveResultGetApi by getDriveApi
export const driveResultGetApi = ({ driveType, offset = 1, searchText }) => {
  const getQueryString = queryStringHelper({ offset, search: searchText, limit: DRIVES_LIMIT });
  return get(`${SERVER_URL}admin/drives/${driveType}_drives?${getQueryString}`);
};

export const driveDeleteApi = ({ id }) => {
  return destroy(`${SERVER_URL}admin/drives/${id}`);
};
