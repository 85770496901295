import { call, put, takeEvery } from 'redux-saga/effects';
import { getMcqCountSuccessAction, getMcqCountFailureAction } from 'redux/admin/getMcqCount/action';
import { getMcqCountApi } from 'redux/admin/getMcqCount/api';
import { GET_MCQ_COUNT } from 'redux/admin/getMcqCount/actionContants';

export function* getMcqCount(mcq_tags) {
  try {
    const response = yield call(getMcqCountApi, mcq_tags.payload);
    yield put(getMcqCountSuccessAction(response.data.data));
  } catch (error) {
    yield put(getMcqCountFailureAction(error));
  }
}

export default function* getMcqCountSaga() {
  yield takeEvery(GET_MCQ_COUNT.GET_MCQ_COUNT_REQUEST_ACTION, getMcqCount);
}
