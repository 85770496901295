import { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import SendEmailInviteComponent from 'modules/admin/sendEmailInvite/SendEmailInviteComponent';

import reducer, { initialState } from 'modules/admin/sendEmailInvite/SendEmailInviteContainer/reducer';
import { setAddCandidateModal } from 'redux/admin/modalStates/action';
import { remainingCreditsRequest } from 'redux/admin/remainingCredits/action';
import { candidateStatus } from 'redux/admin/assessmentCandidates/action';
import {
  sendEmails,
  sendAssessmentEmail,
} from 'modules/admin/sendEmailInvite/SendEmailInviteContainer/sendEmails';

import local from 'utils/local';
import { getUserDetails } from 'utils';

import { EMAIL_REGEX } from 'constants/emailRegexConstant';
import { csvFormatErrorMessage, insufficientCreditsMessage } from 'constants/messageConstants';

const fileSaver = require('file-saver');

const SendEmailInviteContainer = ({
  updateCandidateList,
  setUpdateCandidateList,
  sendInvite,
  setSendInvite,
  name,
  isInvitationSendFromDrive,
}) => {
  const dispatchAction = useDispatch();

  const [emailsState, dispatch] = useReducer(reducer, initialState);
  const { addCandidateModal } = useSelector((state) => state.modalStatusReducer);
  const [loading, setLoading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState('No file selected');
  const { driveId } = useParams();
  const { credits, isCreditsLoading } = useSelector((state) => state.remainingCreditsReducer);
  const [creditCount, setCreditCount] = useState(0);

  useEffect(() => {
    setCreditCount(credits);
  }, [credits]);

  useEffect(() => {
    return () => {
      dispatch(candidateStatus(false));
    };
  }, []);

  const handleInvitationEmails = (event) => {
    dispatch({
      type: 'VALID_EMAIL',
      payload: event.target.value,
    });
  };

  const toggle = () => {
    dispatchAction(setAddCandidateModal(false));
    dispatch({ type: 'RESET_SEND_EMAIL_INVITE' });
    setSelectedFileName('No file selected');
    if (sendInvite) {
      setUpdateCandidateList(!updateCandidateList);
    }
  };

  const handleUploadedInvitationEmails = async (e) => {
    dispatch({
      type: 'CSV_EMAILS_SENT_FAILURE',
      payload: '',
    });
    if (e.target.files[0] && e.target.files[0].type === 'text/csv') {
      setSelectedFileName(e.target.files[0].name);
      let csvEmailText = await e.target.files[0].text();
      csvEmailText = csvEmailText.replace(/(\n)/gm, ',');
      csvEmailText = csvEmailText.replace(/\s/gm, '');
      const finalCsvEmailText = csvEmailText.replace(/,\s*$/, '');
      dispatch({
        type: 'VALID_CSV_FILETYPE',
        payload: finalCsvEmailText,
      });
    } else {
      setSelectedFileName('No file selected');
      dispatch({
        type: 'INVALID_CSV_FILETYPE',
        payload: csvFormatErrorMessage,
      });
    }
  };

  // This function will perform functionality of error response
  const handleInvitationErrorResponse = (error, isCSVFileUpload) => {
    const errorResponse = error.response;
    if (errorResponse) {
      const { data, status } = errorResponse;
      if (isCSVFileUpload) {
        // api will return 422 if ccsv file as validation error
        if (status === 422) {
          dispatchAction(remainingCreditsRequest());

          if (data.message) {
            toast.error(data.message);
          } else {
            dispatch({
              type: 'CSV_EMAILS_SENT_FAILURE_FILE',
              payload: data,
            });
          }
        } else {
          dispatch({
            type: 'CSV_EMAILS_SENT_FAILURE',
            payload: data.message,
          });
        }
      } else {
        dispatch({
          type: 'EMIALS_SENT_FAILURE',
          payload: data.message,
        });
      }
    }
    setLoading(false);
  };

  // This function will perform functionality of success response
  const handleInvitationSuccessResponse = (responseData, isCSVFileUpload, invalidEmails) => {
    const {
      data: {
        data: {
          credits: updatedCredits,
        },
        message,
      },
    } = responseData;

    // it will set the insufficientCreditsError Message seperately
    // for csv upload / invitation upload
    if (message === insufficientCreditsMessage) {
      if (isCSVFileUpload) {
        dispatch({
          type: 'CSV_EMAILS_SENT_FAILURE',
          payload: message,
        });
      } else {
        dispatch({
          type: 'EMIALS_SENT_FAILURE',
          payload: message,
        });
      }
      setLoading(false);
    } else {
      // set success message for seperately bulk upload/ button click upload
      if (isCSVFileUpload) {
        dispatch({
          type: 'CSV_EMAILS_SENT_SUCCESS',
          payload: message,
        });
      } else {
        // set success message for invitation by button click
        dispatch({
          type: 'EMIALS_SENT_SUCCESS',
          payload: message,
        });
        // set error message for invalid email
        if (invalidEmails) {
          dispatch({
            type: 'INVALID_EMAIL',
            payload: `Invalid Emails ${invalidEmails}`,
          });
        }
      }
      toast.success(message);
      setLoading(false);
      const profileDetails = JSON.parse(localStorage.getItem('userDetails'));
      profileDetails.credits = updatedCredits || 0;
      localStorage.setItem('userDetails', JSON.stringify(profileDetails));
      setCreditCount(updatedCredits);
      setSelectedFileName('No file selected');
    }
  };

  // This is a function to call send invitation api
  const handleSendInvitation = async (values, isCSVFileUpload, invalidEmails) => {
    setSendInvite(true);
    const allEmails = values.replace(
      /^,|,$/g,
      '',
    );
    const checkEmails = allEmails.replace(/\s/g, '').split(',');
    setLoading(true);
    let responseData;
    try {
      if (!isInvitationSendFromDrive) {
        try {
          const data = {
            email: checkEmails.join(','),
            assessment_id: local.getItem('showAssessmentUUID'),
            organization_id: local.getItem('organization_id'),
            is_sparkode_request: true,
          };
          // TODO
          // api without saga 24
          responseData = await sendAssessmentEmail(data);
          handleInvitationSuccessResponse(responseData, isCSVFileUpload, invalidEmails);
        } catch (error) {
          handleInvitationErrorResponse(error, isCSVFileUpload);
        }
      } else {
        try {
          const data = {
            emails: checkEmails.join(','),
            drife_id: driveId,
          };
          // TODO
          // api without saga 25
          responseData = await sendEmails(data);
          handleInvitationSuccessResponse(responseData, isCSVFileUpload, invalidEmails);
        } catch (error) {
          handleInvitationErrorResponse(error, isCSVFileUpload);
        }
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  // this is function if initation is been send by uploading CSV file upload
  const handleSendInvitationByCSV = () => {
    // to check if file is selected
    if (selectedFileName === 'No file selected') {
      dispatch({
        type: 'INVALID_CSV_FILETYPE',
        payload: csvFormatErrorMessage,
      });
    } else {
      // or send Invitation by call api
      handleSendInvitation(emailsState.csvEmails, true);
    }
  };

  // this function is use to validate Emails passed in input fields
  const validateEmails = (checkEmails) => {
    const pattern = EMAIL_REGEX;
    const validationResponse = {
      validation: false,
      invalidEmails: [],
      validEmails: [],
    };

    checkEmails.forEach((email) => {
      if (!pattern.test(email)) {
        validationResponse.invalidEmails.push(email);
        validationResponse.validation = false;
      } else {
        validationResponse.validEmails.push(email);
      }
    });
    validationResponse.validation = validationResponse.invalidEmails.length === 0;
    return validationResponse;
  };

  // this is function to send invitation by input field to send Invitation on button click
  const handleSendInvitationByEmail = () => {
    const email = emailsState.emails;
    const allEmails = email.replace(
      /^,|,$/g,
      '',
    );
    const emailList = allEmails.replace(/\s/g, '').split(',');
    // to check if email added is greater then 10.
    if (emailList.length > 10) {
      dispatch({
        type: 'INVALID_EMAIL',
        payload: 'Please add maximum 10 email-ids.',
      });
    } else if (emailList.length === 1 && email === '') {
      // to check if atleast 1 email is added
      dispatch({
        type: 'INVALID_EMAIL',
        payload: 'Please add atleast 1 email-id.',
      });
    } else {
      const ifEmailIsValid = validateEmails(emailList);
      // to check if all emails are valid emails
      if (!ifEmailIsValid.validation) {
        const invalidEmailsMessage = ifEmailIsValid.invalidEmails.join(', ');
        if (ifEmailIsValid.validEmails.length !== 0) {
          const validEmailsString = ifEmailIsValid.validEmails.toString();
          handleSendInvitation(`${validEmailsString}`, false, invalidEmailsMessage);
        }
        dispatch({
          type: 'INVALID_EMAIL',
          payload: `Invalid Emails ${invalidEmailsMessage}`,
        });
      } else {
        // call api email is validate
        handleSendInvitation(email, false);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleCancel = () => {
    dispatch({
      type: 'SHOW_CANDIDATES',
      payload: { currentScreen: 'SHOW_CANDIDATES', id: driveId },
    });
  };

  const handleCsvRemove = () => {
    dispatch({
      type: 'VALID_CSV_FILETYPE',
      payload: '',
    });
  };

  const handleInvitationEmailsErrorMessage = (event) => {
    dispatch({
      type: 'VALID_EMAIL',
      payload: event.target.value,
    });
  };

  const handleOnDownloadErrorFile = () => {
    fileSaver.saveAs(
      new Blob(
        [emailsState.invalidEmailsFilePath],
        { type: 'application/octet-stream' },
      ), 'invalid-emails.csv',
    );
  };

  return (
    <>
      <SendEmailInviteComponent
        emailsState={emailsState}
        handleInvitationEmails={handleInvitationEmails}
        handleUploadedInvitationEmails={handleUploadedInvitationEmails}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        handleCsvRemove={handleCsvRemove}
        handleInvitationEmailsErrorMessage={handleInvitationEmailsErrorMessage}
        loading={loading}
        driveId={driveId}
        isOpen={addCandidateModal}
        toggle={toggle}
        selectedFileName={selectedFileName}
        credits={creditCount}
        name={name}
        handleOnDownloadFile={handleOnDownloadErrorFile}
        handleSendInvitationByEmail={handleSendInvitationByEmail}
        handleSendInvitationByCSV={handleSendInvitationByCSV}
      />
    </>
  );
};

SendEmailInviteContainer.defaultProps = {
  isInvitationSendFromDrive: false,
};

SendEmailInviteContainer.propTypes = {
  updateCandidateList: PropTypes.bool.isRequired,
  setUpdateCandidateList: PropTypes.func.isRequired,
  sendInvite: PropTypes.bool.isRequired,
  setSendInvite: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  isInvitationSendFromDrive: PropTypes.bool,
};

export default SendEmailInviteContainer;
