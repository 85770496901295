import { TAGS } from 'redux/admin/tags/actionContants';

const {
  TAGS_FAILURE_ACTION, TAGS_REQUEST_ACTION, TAGS_SUCCESS_ACTION, CLEAR_TAGS,
  ADD_NEW_DIFFICULTY, ADD_NEW_TAG } = TAGS;

export const tagsRequestAction = (payload) => ({
  type: TAGS_REQUEST_ACTION,
  payload,
});

export const tagsSuccessAction = (payload) => ({
  type: TAGS_SUCCESS_ACTION,
  payload,
});

export const tagsFailureAction = (payload) => ({
  type: TAGS_FAILURE_ACTION,
  payload,
});

export const addNewDifficulty = (payload) => ({
  type: ADD_NEW_DIFFICULTY,
  payload,
});

export const addNewTag = (payload) => ({
  type: ADD_NEW_TAG,
  payload,
});

export const tagsClear = () => ({
  type: CLEAR_TAGS,
});
