import { call, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { feedbackPostApi } from 'apis/candidateFeebackApi';
import { CANDIDATE_FEEFBACK_ACTIONS } from 'constants/actionConstants';

export function* saveFeedback(action) {
  try {
    if (action.payload) {
      const data = {
        answer: action.payload.comment,
        rating: action.payload.rating,
        token: action.payload.token,
      };
      yield call(feedbackPostApi, data);
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
}

export default function* saveCandidateFeedback() {
  yield takeLatest(CANDIDATE_FEEFBACK_ACTIONS.REQUEST_ACTION, saveFeedback);
}
