import produce from 'immer';

import { COMPANY_SIGN_UP, SIGN_UP_TOKEN_VALIDITY } from './actionConstants';

const { COMPANY_SIGN_UP_SUCCESS, COMPANY_SIGN_UP_FAILURE } = COMPANY_SIGN_UP;

const {
  SIGN_UP_TOKEN_VALIDITY_REQUEST,
  SIGN_UP_TOKEN_VALIDITY_SUCCESS,
  SIGN_UP_TOKEN_VALIDITY_FAILURE,
} = SIGN_UP_TOKEN_VALIDITY;

export const initialState = {
  isSignUpSuccess: false,
  isValidToken: false,
};

export const signUpUser = produce((state = initialState, action = {}) => {
  const { type } = action;
  switch (type) {
    case COMPANY_SIGN_UP_SUCCESS:
      return { ...state, isSignUpSuccess: true };
    case COMPANY_SIGN_UP_FAILURE:
      return { ...state, isSignUpSuccess: false };
    case SIGN_UP_TOKEN_VALIDITY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SIGN_UP_TOKEN_VALIDITY_FAILURE:
      return {
        ...state,
        isTokenInvalid: true,
        isLoading: false,
      };
    case SIGN_UP_TOKEN_VALIDITY_SUCCESS:
      return { ...state, isLoading: false };
    default:
      return state;
  }
});
