import produce from 'immer';

import { CANDIDATE_REPORT } from 'redux/admin/candidateReport/actionConstants';

export const initialState = {
  reportsLoading: false,
  reports: {
    candidate: {
    },
    drive_name: '2021',
    time: {
    },
    problems_details: [],
    score: {
    },
    snapshots: [],
    mcqs_details: [],
  },
  candidateReportErrorMessage: '',
};

const candidateReportReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case CANDIDATE_REPORT.CANDIDATE_REPORT_REQUEST_ACTION:
      state.reportsLoading = true;
      break;
    case CANDIDATE_REPORT.CANDIDATE_REPORT_SUCCESS_ACTION:
      state.reportsLoading = false;
      state.reports = payload;
      break;
    case CANDIDATE_REPORT.CANDIDATE_REPORT_FAILURE_ACTION:
      state.candidateReportErrorMessage = payload.message;
      state.reportsLoading = false;
      state.reports = {};
      break;
    default:
      return state;
  }
});

export default candidateReportReducer;
