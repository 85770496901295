import { MCQ_BULK_UPLOAD_ACTIONS } from './actionConstants';

const {
  IMPORT_FILE_ACTION,
  SET_FILE_NAME,
  SET_FILE,
  RESET_STATE,
  FILE_UPLOAD_SUCCESSFULLY,
  ACTION_FAILED,
  SET_FILE_PATH,
  RESET_FILE_PATH,
  START_FILE_IMPORT,
  SET_FILE_NAME_ERROR,
  DOWNLOAD_FILE_SUCCESS_ACTION,
} = MCQ_BULK_UPLOAD_ACTIONS;

export const importFileAction = (params) => ({
  type: IMPORT_FILE_ACTION,
  payload: params,
});

export const setFileName = (params) => ({
  type: SET_FILE_NAME,
  payload: params,
});

export const setFile = (params) => ({
  type: SET_FILE,
  payload: params,
});

export const resetAction = () => ({
  type: RESET_STATE,
});

export const successfulFileImport = (params) => ({
  type: FILE_UPLOAD_SUCCESSFULLY,
  payload: params,
});

export const actionFailed = () => ({
  type: ACTION_FAILED,
});

export const setFilePath = (params) => ({
  type: SET_FILE_PATH,
  payload: params,
});

export const resetFilePath = () => ({
  type: RESET_FILE_PATH,
});

export const startFileImport = () => ({
  type: START_FILE_IMPORT,
});

export const setFileNameError = (params) => ({
  type: SET_FILE_NAME_ERROR,
  payload: params,
});

export const downloadFileSuccessAction = (params) => ({
  type: DOWNLOAD_FILE_SUCCESS_ACTION,
  payload: params,
});
