import produce from 'immer';
import { MODAL_STATUS } from 'redux/admin/modalStates/actionConstants';

export const initialState = {
  addCandidateModal: false,
  profileModal: false,
  searchModal: false,
  isOpen: false,
  runCodeModal: false,
  isLogout: false,
  isCopyFromSearch: false,
};

const {
  CANDIDATE_INVITATION,
  PROFILE_MODAL,
  SEARCH_MODAL,
  SET_IS_OPEN,
  RUN_CODE_MODAL,
  SET_IS_LOGOUT,
  SET_IS_COPY_FROM_SERACH_DRIVE,
} = MODAL_STATUS;

const modalStatusReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CANDIDATE_INVITATION:
      state.addCandidateModal = payload;
      break;
    case PROFILE_MODAL:
      state.profileModal = payload;
      break;
    case SEARCH_MODAL:
      state.searchModal = payload;
      break;
    case SET_IS_COPY_FROM_SERACH_DRIVE:
      state.isCopyFromSearch = payload;
      break;
    case SET_IS_OPEN:
      state.isOpen = payload;
      break;
    case RUN_CODE_MODAL:
      state.runCodeModal = payload;
      break;
    case SET_IS_LOGOUT:
      state.isLogout = payload;
      break;
    default:
      return state;
  }
});

export default modalStatusReducer;
