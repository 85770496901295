import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  downloadResultSuccessAction,
  downloadResultFailureAction,
  downloadAssessmentResultFailureAction,
  downloadAssessmentResultSuccessAction,
  absentCandidatesFailureAction,
  absentCandidatesSuccessAction,
} from 'redux/admin/downloadResult/action';
import { downloadResultApi, downloadAssessmentResultApi, downloadAbsentCandidatesApi } from 'redux/admin/downloadResult/api';
import { DOWNLOAD_RESULT, ABSENT_CANDIDATES } from 'redux/admin/downloadResult/actionConstants';

const {
  DOWNLOAD_ASSESSMENT_RESULT_REQUEST_ACTION,
  DOWNLOAD_RESULT_REQUEST_ACTION,
} = DOWNLOAD_RESULT;

const { ABSENT_CANDIDATES_REQUEST_ACTION } = ABSENT_CANDIDATES;

export function* downloadResultCsvSaga(action) {
  try {
    const response = yield call(downloadResultApi, action.payload);
    yield put(downloadResultSuccessAction(response.data));
  } catch (error) {
    const { data } = error.response;
    yield put(downloadResultFailureAction(data));
    toast.error(data.message);
  }
}

export function* downloadAssessmentResultCsvSaga(action) {
  try {
    const response = yield call(downloadAssessmentResultApi, action.payload);
    yield put(downloadAssessmentResultSuccessAction(response.data));
  } catch (error) {
    const { data } = error.response;
    yield put(downloadAssessmentResultFailureAction(data));
    toast.error(data.message);
  }
}

export function* downloadAbsentCandidatesSaga(action) {
  try {
    const response = yield call(downloadAbsentCandidatesApi, action.payload);
    yield put(absentCandidatesSuccessAction(response.data));
  } catch (error) {
    const { data } = error.response;
    yield put(absentCandidatesFailureAction(data));
    toast.error(data.message);
  }
}

// watcherSaga
export default function* downloadResultSaga() {
  yield takeLatest(
    DOWNLOAD_RESULT_REQUEST_ACTION,
    downloadResultCsvSaga,
  );
  yield takeLatest(
    DOWNLOAD_ASSESSMENT_RESULT_REQUEST_ACTION,
    downloadAssessmentResultCsvSaga,
  );
  yield takeLatest(
    ABSENT_CANDIDATES_REQUEST_ACTION,
    downloadAbsentCandidatesSaga,
  );
}
