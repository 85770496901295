/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React from 'react';

import {
  Icon,
} from 'core-components';
import {
  ActionTile,
  CardComponent,
} from 'shared-components';

import './problemListComponent.scss';

const ProblemsComponent = ({
  onClickEdit,
  onClickDetails,
  problem,
  toggleConfirmation,
  handleDeleteClick,
}) => {
  const {
    id,
    title,
    description,
  } = problem;

  return (
    <>
      <CardComponent>
        <div
          className='problems-content p-3 d-flex flex-column flex-1 overflow-hidden'
        >
          <ActionTile
            id={id}
            className='mb-3'
            /* Below code is commented to remove the delete icon for problem temporarily. */
            /* Children={
              (
                <Icon
                  name='delete'
                  title='Delete'
                  onClick={() => {
                    toggleConfirmation();
                    handleDeleteClick(problem.id, problem.title);
                  }}
                />
              )
            } */
            onClickEdit={onClickEdit}
            onClickDetails={onClickDetails}
            problemDifficulty={problem.difficulty}
            editTypeTitle='Problem'
          />
          <div
            onClick={() => onClickDetails(id)}
          >
            <h6 className='mb-2 font-weight-bold card-heading text-capitalize prob-title'>
              {title}
            </h6>
          </div>

          <p className='problem-description text-truncate-three-line overflow-hidden mb-0'>
            {description}
          </p>
        </div>
        {/* TO DO:- (Future Scope)Need to add the how many time problem used and correct ratio */}
        {/* <div className='problem-info d-flex'>
          <div className='problem-info-tile d-flex'>
            <div className='problem-tile-box d-flex px-2 py-3 align-items-center'>
              <label className='mb-0 mr-4'>Used</label>
              <p className='mb-0'>60 Times</p>
            </div>
            <div className='problem-tile-box d-flex px-2 py-3 align-items-center'>
              <label className='mb-0 mr-4'>Correct Ratio</label>
              <p className='mb-0'>80%</p>
            </div>
          </div>
        </div> */}
      </CardComponent>
    </>
  );
};

ProblemsComponent.propTypes = {
  onClickEdit: PropTypes.func.isRequired,
  onClickDetails: PropTypes.func.isRequired,
  problem: PropTypes.shape().isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
};

export default React.memo(ProblemsComponent);
