import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import SendEmailInviteContainer from 'modules/admin/sendEmailInvite/SendEmailInviteContainer';
import ShowCandidateListComponent from 'modules/admin/showCandidateList/ShowCandidateListComponent';
import { resendEmails } from 'modules/admin/sendEmailInvite/SendEmailInviteContainer/sendEmails';

import { setAddCandidateModal } from 'redux/admin/modalStates/action';
import { remainingCreditsRequest } from 'redux/admin/remainingCredits/action';
import { candidateListRequest } from 'redux/admin/candidateList/action';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';

const ShowCandidateListContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { driveId } = useParams();

  const firstPage = 1;
  const Id = driveId;
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearched, setIsSearched] = useState(false);
  const [updateCandidateList, setUpdateCandidateList] = useState(false);
  const [activeTab, setActiveTab] = useState('Default');
  const [sendInvite, setSendInvite] = useState(false);
  const [isSelectAllChecked, setSelectAllChecked] = useState(false);
  const [isCheck, setIsCheck] = useState({});
  const { addCandidateModal } = useSelector((state) => state.modalStatusReducer);
  const {
    driveName,
    allCandidates,
    pageCount,
    totalCandidates,
    driveStatus,
  } = useSelector((item) => item.candidateListReducer);

  useEffect(() => {
    const params = { currentPageNumber, driveId, searchQuery, activeTab };
    dispatch(candidateListRequest(params));
  }, [currentPageNumber, updateCandidateList]);

  // Adding candidate ids to isCheckDefaultValue for furthur use
  useEffect(() => {
    const isCheckDefaultValue = {};
    allCandidates.forEach((candidate) => {
      isCheckDefaultValue[`${candidate.id}`] =  false;
    });
    setIsCheck(isCheckDefaultValue);
  }, [allCandidates]);

  useEffect(() => {
    const objectValues = Object.values(isCheck);
    const value = objectValues.length !== 0 && !objectValues.includes(false);
    setSelectAllChecked(value);
  }, [isCheck]);

  const handleClick = (e, id) => {
    const { checked } = e.target;
    setIsCheck({ ...isCheck, [`${id}`]: checked });
  };

  const handleSelectAll = (e) => {
    setSelectAllChecked(!isSelectAllChecked);
    let newObj = {};
    Object.keys(isCheck).forEach((id) => {
      newObj = { ...newObj, [`${id}`]: !isSelectAllChecked };
    });
    setIsCheck(newObj);
  };

  const handleResendInvitation = async (e) => {
    const selectedCandidates = Object.keys(isCheck).filter((k) => isCheck[`${k}`] === true);
    const data = {
      candidate_ids: selectedCandidates,
      drife_id: driveId,
    };
    try {
      const responseData = await resendEmails(data);
      let newObj = {};
      Object.keys(selectedCandidates).forEach((id) => {
        newObj = { ...isCheck, ...newObj, [`${selectedCandidates[id]}`]: !isCheck[`${selectedCandidates[id]}`] };
      });
      setIsCheck(newObj);
      toast.success(responseData.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const handleAddCandidateClick = () => {
    dispatch(remainingCreditsRequest());
    dispatch(setAddCandidateModal(true));
  };

  const handleTabChange = (tabName) => {
    const data = {
      currentPageNumber: firstPage,
      driveId,
      searchQuery: '',
      absentCandidates: tabName === 'absentCandidate',
    };
    setActiveTab(tabName);
    dispatch(candidateListRequest(data));
  };

  const handlePageClick = (data) => {
    setCurrentPageNumber(data.selected + 1);
  };

  const previousClick = () => {
    history.push(ROUTES.ADMIN + ADMIN_ROUTES.HOME);
  };

  const handleOnChange = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, [searchQuery]);

  const handleSearchClick = () => {
    if (searchQuery || isSearched) {
      const params = {
        currentPageNumber: firstPage,
        driveId,
        searchQuery: searchQuery.trim(),
        activeTab,
      };
      dispatch(candidateListRequest(params));
      if (!searchQuery && isSearched) {
        setIsSearched(false);
      } else {
        setIsSearched(true);
      }
    }
  };

  const handleCandidateSearchClearClick = () => {
    setSearchQuery('');
    if (isSearched) {
      const params = {
        currentPageNumber: firstPage,
        driveId,
        searchQuery: '',
        activeTab,
      };
      dispatch(candidateListRequest(params));
    }
    setIsSearched(false);
  };

  return (
    <>
      <ShowCandidateListComponent
        handleAddCandidateClick={handleAddCandidateClick}
        handlePageClick={handlePageClick}
        pageCount={pageCount}
        candidateIsLoading={false}
        previousClick={previousClick}
        addCandidateModal={addCandidateModal}
        driveName={driveName}
        totalCandidates={totalCandidates}
        handleOnChange={handleOnChange}
        searchQuery={searchQuery}
        handleSearchClick={handleSearchClick}
        handleCandidateSearchClearClick={handleCandidateSearchClearClick}
        allCandidates={allCandidates}
        handleTabChange={handleTabChange}
        activeTab={activeTab}
        handleSelectAll={handleSelectAll}
        handleClick={handleClick}
        handleResendInvitation={handleResendInvitation}
        isCheck={isCheck}
        isSelectAllChecked={isSelectAllChecked}
        driveStatus={driveStatus}
      />
      <SendEmailInviteContainer
        updateCandidateList={updateCandidateList}
        setUpdateCandidateList={setUpdateCandidateList}
        setSendInvite={setSendInvite}
        sendInvite={sendInvite}
        name={driveName}
        isInvitationSendFromDrive
      />
    </>
  );
};

export default ShowCandidateListContainer;
