import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';

const ChatwootWidget = () => {
  useEffect(() => {
    const cookies = new Cookies();
    cookies.remove('cw_conversation', { path: '/' });

    window.chatwootSettings = {
      hideMessageBubble: false,
      type: 'expanded_bubble',  // [standard, expanded_bubble]
      launcherTitle: 'Support',
      position: 'right', // This can be left or right
      locale: 'en', // Language to be set
    };
  }, []);
  const token = localStorage.getItem('authToken');

  // Paste the script from inbox settings except the < script > tag
  (function (d, t) {
    const BASE_URL = process.env.REACT_APP_CHATWOOT_WEBSITE;
    const g = d.createElement(t);
    const s = d.getElementsByTagName(t)[0];
    g.src = `${BASE_URL}/packs/js/sdk.js`;
    g.defer = true;
    g.async = true;
    s.parentNode.insertBefore(g, s);
    g.onload = function () {
      window.chatwootSDK.run({
        websiteToken: process.env.REACT_APP_CHATWOOT_TOKEN,
        baseUrl: BASE_URL,
      });
    };
  }(document, 'script'));

  return null;
};

export default ChatwootWidget;
