import { ADMIN_FORGOT_PASSWORD } from 'redux/admin/forgotPassword/actionConstants';

export const adminForgotPasswordRequestAction = (payload) => ({
  type: ADMIN_FORGOT_PASSWORD.ADMIN_FORGOT_PASSWORD_REQUEST_ACTION,
  payload,
});

export const adminForgotPasswordSuccessAction = (payload) => ({
  type: ADMIN_FORGOT_PASSWORD.ADMIN_FORGOT_PASSWORD_SUCCESS_ACTION,
  payload,
});

export const adminForgotPasswordFailureAction = (payload) => ({
  type: ADMIN_FORGOT_PASSWORD.ADMIN_FORGOT_PASSWORD_FAILURE_ACTION,
  payload,
});

export const adminForgotPasswordResetAction = () => ({
  type: ADMIN_FORGOT_PASSWORD.ADMIN_FORGOT_PASSWORD_RESET_ACTION,
});
