import { EXPORT_FILE_NAME_DATE_FORMAT, DATE_TIME_FORMAT } from 'constants/appConstants';
import moment from 'moment';

const getTimeStampForFileName = () => moment(Date()).format(EXPORT_FILE_NAME_DATE_FORMAT);

export const getDateFormat = (date) => moment(date).format(DATE_TIME_FORMAT);

export const isValidateDate = (startDate, endDate) => moment(startDate).isAfter(endDate);

export default {
  getTimeStampForFileName,
  getDateFormat,
  isValidateDate,
};
