import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  editDriveFailureAction,
  editDriveSuccessAction,
} from 'redux/admin/editDrive/action';

import { editDrivePutApi } from 'redux/admin/editDrive/api';
import { EDIT_DRIVE } from 'redux/admin/editDrive/actionConstants';

const { EDIT_DRIVE_REQUEST_ACTION } = EDIT_DRIVE;

export function* editDriveSaga(action) {
  try {
    const response = yield call(editDrivePutApi, action.payload);
    toast.success('Drive updated successfully');
    yield put(editDriveSuccessAction(response.data));
  } catch (error) {
    toast.error(error.response.data.message);
    yield put(editDriveFailureAction(error.message));
  }
}

export default function* editDriveWatcherSaga() {
  yield takeLatest(EDIT_DRIVE_REQUEST_ACTION, editDriveSaga);
}
