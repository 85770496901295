import produce from 'immer';
import { GET_EDIT_DRIVE } from 'redux/admin/getEditDrive/actionContants';

const {
  GET_EDIT_DRIVE_REQUEST_ACTION,
  GET_EDIT_DRIVE_SUCCESS_ACTION,
  GET_EDIT_DRIVE_FAILURE_ACTION,
  CLEAR_GET_EDIT_DRIVE,
  IS_COPY_DRIVE_ACTION,
} = GET_EDIT_DRIVE;

export const initialState = {
  editDriveData: '',
  isEditDriveSuccess: false,
  isEditDriveLoading: false,
  isEditDriveError: false,
  isCopyDrive: false,
};

const getEditDriveReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case GET_EDIT_DRIVE_REQUEST_ACTION:
      return {
        ...state,
        isEditDriveLoading: true,
      };
    case IS_COPY_DRIVE_ACTION:
      return {
        ...state,
        isCopyDrive: payload,
      };
    case GET_EDIT_DRIVE_SUCCESS_ACTION:
      return {
        ...state,
        isEditDriveSuccess: true,
        isEditDriveLoading: false,
        editDriveData: payload,
      };
    case GET_EDIT_DRIVE_FAILURE_ACTION:
      return {
        ...state,
        isEditDriveError: true,
        isEditDriveLoading: false,
      };
    case CLEAR_GET_EDIT_DRIVE:
      return { ...initialState };
    default:
      return state;
  }
});
export default getEditDriveReducer;
