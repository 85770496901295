import { GET_EDIT_DRIVE } from 'redux/admin/getEditDrive/actionContants';

const {
  GET_EDIT_DRIVE_REQUEST_ACTION,
  GET_EDIT_DRIVE_SUCCESS_ACTION,
  GET_EDIT_DRIVE_FAILURE_ACTION,
  CLEAR_GET_EDIT_DRIVE,
  IS_COPY_DRIVE_ACTION,
} = GET_EDIT_DRIVE;

export const getEditDriveRequestAction = (payload) => ({
  type: GET_EDIT_DRIVE_REQUEST_ACTION,
  payload,
});

export const getEditDriveSuccessAction = (payload) => ({
  type: GET_EDIT_DRIVE_SUCCESS_ACTION,
  payload,
});

export const getEditDriveFailureAction = (payload) => ({
  type: GET_EDIT_DRIVE_FAILURE_ACTION,
  payload,
});

export const isCopyDriveAction = (payload) => ({
  type: IS_COPY_DRIVE_ACTION,
  payload,
});

export const getEditDriveClear = () => ({
  type: CLEAR_GET_EDIT_DRIVE,
});
