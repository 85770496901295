import React, { useCallback, useEffect, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SignupComponent from 'modules/admin/signup/SignupComponent';

import { adminSignupRequestAction, adminSignupResetAction } from 'redux/admin/signup/action';
import { reducer } from 'modules/admin/signup/SignupContainer/reducer';
import { schema } from 'modules/admin/signup/SignupContainer/schema';
import { validateData } from 'modules/admin/signup/dataValidation';

const SignupContainer = () => {
  const dispatch = useDispatch();
  const { isLoading, isError, errorMessage, successMessage }
    = useSelector((state) => state.adminSignupReducer);

  const initialUserState = {
    email: '',
    emailError: '',
  };
  const [signUpState, setSignUpState] = useReducer(reducer, initialUserState);
  const signUpUrl = window.location.href;

  const validateParamAiUser = () => {
    let isParamAIUser = false;
    const signUpParameter = signUpUrl.split('?');
    if (signUpParameter[1]) {
      const paramsUser = signUpParameter[1].split('=');
      if (paramsUser[0] === 'ref' && paramsUser[1] === 'paramAI') {
        isParamAIUser = true;
      }
    }
    return isParamAIUser;
  };

  useEffect(() => {
    return () => {
      dispatch(adminSignupResetAction());
    };
  }, []);

  const handleEmailChange = useCallback(
    (event) => {
      const email = event.target.value.toLowerCase();
      setSignUpState({
        type: 'email',
        payload: email,
      });
    }, [],
  );

  const handleSubmit = useCallback(
    (event) => {
      const paramAllowed = validateParamAiUser();
      event.preventDefault();
      const { email } = signUpState;
      const data = { email };

      schema.isValid(data).then(async (valid) => {
        if (!valid) {
          validateData(schema, data, setSignUpState);
        } else {
          dispatch(adminSignupRequestAction({ ...data, is_param_ai_allowed: paramAllowed }));
        }
      });
    }, [signUpState],
  );

  return (
    <SignupComponent
      handleSubmit={handleSubmit}
      signUpState={signUpState}
      isLoading={isLoading}
      isError={isError}
      errorMessage={errorMessage}
      successMessage={successMessage}
      handleEmailChange={handleEmailChange}
    />
  );
};

export default React.memo(SignupContainer);
