import React, { useState, createRef } from 'react';
import PropTypes, { shape, string } from 'prop-types';
import CreatableSelect from 'react-select/creatable';

import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Icon,
  Card, CardBody, CardHeader,
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'core-components';
import { ImagePopUpModal } from 'shared-components';

import TestCaseContainer from 'modules/admin/testCase/TestCaseContainer';
import TestCaseListContainer from 'modules/admin/testCaseList/testCaseListContainer';
import TemplateContainer from 'modules/admin/template/TemplateContainer';

import { capitalize } from 'utils/capitalize';

import { CustomThemeSelect, CustomThemeMultiSelect } from 'constants/themesConstants';
import { customStyle } from 'constants/stylesConstants';

import './editProblemComponent.scss';

const EditProblemComponent = (props) => {
  const {
    handleTitleChange,
    handleDescriptionChange,
    handleCountChange,
    handleTimeChange,
    handleSubmit,
    problemDetails,
    problemIsLoading,
    finishProblemEdit,
    editProblem,
    handleSelectedDifficultyChange,
    handleSelectedTagsChange, defTags,
    problemId,
    tagsOptions,
    difficultyOptions,
    handleCaptureProblem,
    problemImage,
    showImageModal,
    toggleImageModalVisibility,
    defaultDifficulty,
    problemImageError,
    testCasesList,
    setTestCasesList,
    testCaseError,
    setTestCaseError,
  } = props;
  const scrollRef = createRef();

  const {
    description,
    descriptionError,
    difficulty,
    difficultyError,
    submissionCount,
    submissionCountError,
    tagsError,
    timeInMinutes,
    timeInMinutesError,
    title,
    titleError,
    validDescription,
    validSubmissionCount,
    validTime,
    validTitle,
  } = editProblem;
  if (problemIsLoading) {
    return <Spinner />;
  }
  const [activeTab, setActiveTab] = useState('test');

  return (
    <div className='add-problem-section py-3 d-flex flex-column'>
      <div className='add-problem-section-head mb-4 px-4 d-flex align-items-center justify-content-between'>
        <div className='d-flex align-items-center'>
          <Icon name='left' className='back-page-arrow font-weight-bold' onClick={finishProblemEdit} />
          <h2 className='section-heading font-weight-bold mb-0 ml-3'>
            Update Problem
          </h2>
        </div>
      </div>
      <div className='problem-content scroll-y'>
        <div className='problem-col-wrapper' ref={scrollRef}>
          <div className='problem-col p-4 position-relative'>
            <Form className='add-problem-form' onSubmit={handleSubmit}>
              <div className='d-flex form-group-col-wrapper align-items-start'>
                <div className='form-group-col d-flex'>
                  <FormGroup className='w-100 mb-0'>
                    <Label className='text-truncate px-3'>Problem Title</Label>
                    <Input
                      type='text'
                      placeholder='Enter Problem Title'
                      defaultValue={problemDetails.title}
                      onChange={handleTitleChange}
                      invalid={!validTitle}
                      required
                    />
                    { titleError && !validTitle && (
                      <div className='text-danger'>
                        {capitalize(titleError)}
                      </div>
                    )}
                  </FormGroup>
                </div>
                <div className='form-group-col d-flex align-items-start'>
                  <FormGroup className='w-50 mb-0'>
                    <Label className='text-truncate px-3'>
                      Submission Count
                    </Label>
                    <Input
                      type='number'
                      placeholder='Enter Submission Count'
                      defaultValue={problemDetails.submission_count}
                      onChange={handleCountChange}
                      invalid={!validSubmissionCount}
                      required
                      className='select-input-type'
                    />
                    { submissionCountError && !validSubmissionCount && (
                      <div className='text-danger'>
                        {capitalize(submissionCountError)}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup className='w-50 mb-0'>
                    <Label className='text-truncate px-3'>
                      Time in minutes
                    </Label>
                    <Input
                      type='number'
                      placeholder='Enter Time allowed in minutes'
                      onChange={handleTimeChange}
                      defaultValue={problemDetails.time_in_minutes}
                      invalid={!validTime}
                      required
                      className='select-input-type'
                    />
                    { timeInMinutesError && !validTime && (
                      <div className='text-danger'>
                        {capitalize(timeInMinutesError)}
                      </div>
                    )}
                  </FormGroup>
                </div>
              </div>
              {problemImage && (
                <div
                  className='question-diagram-wrapper'
                  onClick={toggleImageModalVisibility}
                  role='button'
                  tabIndex={0}
                >
                  <img
                    className='image-wrapper'
                    src={problemImage}
                    alt='Upload Question Img'
                  />
                </div>
              )}
              <ImagePopUpModal
                showImageModal={showImageModal}
                toggleImageModalVisibility={toggleImageModalVisibility}
                imageUrl={problemImage}
                alternateName='Question Image'
              />
              <FormGroup className='width-fit-content'>
                <Label for='exampleText' className='px-3'>
                  Upload Diagram
                </Label>
                <Input
                  type='file'
                  name='text'
                  id='exampleFile'
                  accept='image/*'
                  onInputCapture={handleCaptureProblem}
                />
              </FormGroup>
              {problemImageError && (
                <div className='text-danger pb-3'>
                  {problemImageError}
                </div>
              )}
              <div className='form-group-col d-flex align-items-start'>
                <FormGroup className='w-50 mb-0'>
                  <Label className='text-truncate px-3'>
                    Difficulty
                  </Label>
                  <CreatableSelect
                    className='mr-3 text-light dropdown'
                    id='role'
                    value={defaultDifficulty}
                    onChange={handleSelectedDifficultyChange}
                    options={difficultyOptions}
                    theme={CustomThemeSelect}
                    styles={customStyle}
                  />
                  <div className='text-danger'>
                    {difficultyError}
                  </div>
                </FormGroup>
                <FormGroup className='w-50 mb-0'>
                  <Label className='text-truncate px-3'>
                    Tags
                  </Label>
                  <CreatableSelect
                    className='mr-3 text-light dropdown'
                    id='role'
                    value={defTags}
                    onChange={handleSelectedTagsChange}
                    options={tagsOptions}
                    defaultValue={defTags}
                    placeholder='hi'
                    isMulti
                    theme={CustomThemeMultiSelect}
                    styles={customStyle}
                  />
                  <div className='text-danger'>
                    {tagsError}
                  </div>
                </FormGroup>
              </div>
              <FormGroup className='mt-3'>
                <Label for='exampleText' className='px-3'>
                  Problem Description
                </Label>
                <Input
                  type='textarea'
                  name='text'
                  id='exampleText'
                  style={{ height: 400 }}
                  placeholder='Enter Problem Description'
                  onChange={handleDescriptionChange}
                  defaultValue={problemDetails.description}
                  invalid={!validDescription}
                  required
                />
                { descriptionError && !validDescription && (
                  <div className='text-danger'>
                    {capitalize(descriptionError)}
                  </div>
                )}
              </FormGroup>
            </Form>
          </div>
        </div>
        <Card className='bg-card'>
          <CardHeader tag='h6' className='d-flex'>
            <Nav className='navitem-style'>
              <NavItem>
                <NavLink active={activeTab === 'test'} onClick={() => setActiveTab('test')}>
                  Test Cases
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink active={activeTab === 'template'} onClick={() => setActiveTab('template')}>
                  Templates
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody className='d-flex' style={{ overflow: 'unset' }}>
            <TabContent activeTab={activeTab} className='d-flex flex-column flex-1 tabContent'>
              <TabPane tabId='test'>
                {activeTab === 'test' && (
                  <TestCaseContainer
                    testCasesList={testCasesList}
                    setTestCasesList={setTestCasesList}
                    scrollRef={scrollRef}
                    handleSubmit={handleSubmit}
                    testCaseError={testCaseError}
                    setTestCaseError={setTestCaseError}
                  />
                )}
              </TabPane>
              <TabPane tabId='template' active={activeTab === 'template'} className='flex-1 tabPane'>
                {activeTab === 'template' && <TemplateContainer problemId={problemId} showTemplateHeader={false} className='d-flex flex-column flex-1' />}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

EditProblemComponent.propTypes = {
  handleTitleChange: PropTypes.func.isRequired,
  handleDescriptionChange: PropTypes.func.isRequired,
  handleCountChange: PropTypes.func.isRequired,
  handleTimeChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  problemIsLoading: PropTypes.bool.isRequired,
  finishProblemEdit: PropTypes.func.isRequired,
  problemDetails: PropTypes.objectOf(PropTypes.object).isRequired,
  editProblem: PropTypes.shape().isRequired,
  handleSelectedDifficultyChange: PropTypes.func.isRequired,
  handleSelectedTagsChange: PropTypes.func.isRequired,
  defTags: PropTypes.objectOf(PropTypes.object).isRequired,
  problemId: PropTypes.number.isRequired,
  tagsOptions: PropTypes.arrayOf(string).isRequired,
  difficultyOptions: PropTypes.arrayOf(shape).isRequired,
  handleCaptureProblem: PropTypes.func.isRequired,
  problemImage: PropTypes.string.isRequired,
  showImageModal: PropTypes.bool.isRequired,
  toggleImageModalVisibility: PropTypes.func.isRequired,
  defaultDifficulty: PropTypes.object.isRequired,
  problemImageError: PropTypes.string.isRequired,
  testCasesList: PropTypes.array.isRequired,
  setTestCasesList: PropTypes.func.isRequired,
  testCaseError: PropTypes.object.isRequired,
  setTestCaseError: PropTypes.func.isRequired,
};

export default React.memo(EditProblemComponent);
