/* eslint-disable no-underscore-dangle */
import React, { useReducer, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import CreateProblemComponent from 'modules/admin/createProblem/CreateProblemComponent';

import { schema } from 'modules/admin/createProblem/CreateProblemContainer/schema';
import { reducer } from 'modules/admin/createProblem/CreateProblemContainer/reducer';
import { createProblemRequestAction, clearProblemState } from 'redux/admin/createProblem/action';
import { tagsRequestAction, addNewDifficulty, addNewTag } from 'redux/admin/tags/action';

import arrayToKeyValueHash from 'utils/arrayToKeyValueHash';
import { imageFileToUrl, isImageFile } from 'utils';
import { capitalize } from 'utils/capitalize';

import { imageFormatErrorMessage } from 'constants/messageConstants';
import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';

const CreateProblemContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { message, isSuccess, isLoading,
    problemId } = useSelector((state) => state.createProblemReducer);
  const { difficultyList, tagList } = useSelector((state) => state.tagsReducer);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [difficultyOptions, setDifficultyOptions] = useState([]);
  const [selectedImage, setSelectedImage] = useState('');
  const [problemImageError, setProblemImageError] = useState('');
  const [testCaseError, setTestCaseError] = useState({});
  const [testCasesList, setTestCasesList] = useState([{
    input: '',
    output: '',
    marks: 0,
  }]);

  const {
    testCases: { length: testCasesCount },
  } = useSelector((state) => state.testCaseListReducer);

  const initialUserState = {
    title: '',
    validTitle: true,
    titleError: '',
    description: '',
    validDescription: true,
    descriptionError: '',
    submissionCount: 0,
    validSubmissionCount: true,
    submissionCountError: '',
    timeInMinutes: 0,
    validTime: true,
    timeInMinutesError: '',
    difficulty: '',
    difficultyError: '',
    tags: [],
    tagsError: '',
    problemDiagram: '',
  };

  useEffect(() => {
    if (isSuccess) {
      history.push(ROUTES.ADMIN + ADMIN_ROUTES.PROBLEMS);
    }
  }, [isSuccess]);

  useEffect(() => {
    dispatch(tagsRequestAction());
  }, []);

  useEffect(() => {
    setDifficultyOptions(arrayToKeyValueHash(difficultyList));
  }, [difficultyList]);

  useEffect(() => {
    setTagsOptions(arrayToKeyValueHash(tagList));
  }, [tagList]);

  const [userState, setUserState] = useReducer(reducer, initialUserState);
  const handleTitleChange = useCallback(
    (event) => {
      const title = event.target.value;
      setUserState({
        type: 'title',
        payload: title,
      });
    },
    [userState.title],
  );

  const handleDescriptionChange = useCallback(
    (event) => {
      const description = event.target.value;
      setUserState({
        type: 'description',
        payload: description,
      });
    },
    [userState.description],
  );

  useEffect(() => {
    return () => {
      setUserState(initialUserState);
      dispatch(clearProblemState());
    };
  }, []);

  const handleCountChange = useCallback(
    (event) => {
      const submissionCount = event.target.value;
      setUserState({
        type: 'submissionCount',
        payload: Number(submissionCount),
      });
    },
    [userState.submissionCount],
  );

  const handleTimeChange = useCallback(
    (event) => {
      const timeInMinutes = event.target.value;
      setUserState({
        type: 'timeInMinutes',
        payload: Number(timeInMinutes),
      });
    },
    [userState.timeInMinutes],
  );

  const handleSelectedDifficultyChange = useCallback(
    (event) => {
      const difficulty = event.value;
      setUserState({
        type: 'difficulty',
        payload: difficulty,
      });
      if (event.__isNew__) { event.__isNew__ = false; dispatch(addNewDifficulty(event.value)); }
    }, [userState.difficulty],
  );

  const handleSelectedTagsChange = useCallback(
    (event) => {
      const tags = [];
      event.map((item) => {
        if (item.__isNew__) { item.__isNew__ = false; dispatch(addNewTag(item.value)); }
        return tags.push(item.value);
      });

      setUserState({
        type: 'tags',
        payload: tags,
      });
    }, [userState.tags],
  );

  const handleSubmit = useCallback(() => {
    const {
      title,
      description,
      submissionCount,
      timeInMinutes,
      difficulty,
      tags,
      problemDiagram,
    } = userState;

    const trimTestCases = testCasesList.map((testCase) => {
      const { input, output } = testCase;
      testCase.input = input.trim();
      testCase.output = output.trim();
      return testCase;
    });
    schema.validate(
      {
        problemTitle: title.trim(),
        problemDescription: description.trim(),
        submissionCount,
        timeInMinute: timeInMinutes,
        difficulty,
        tags,
        testCasesList,
      },
      { abortEarly: false },
    ).then(() => {
      const data = {
        title: title.trim(),
        description: description.trim(),
        submissionCount,
        timeInMinutes,
        difficulty,
        tags,
        problemDiagram,
        testCasesAttributes: trimTestCases,
      };
      dispatch(createProblemRequestAction(data));
    }).catch((errors) => {
      const testCaseErrorObj = {};
      errors.inner.forEach((error) => {
        if (/testCasesList/.test(error.path)) {
          testCaseErrorObj[error.path] = error.message;
          setTestCaseError({ ...testCaseErrorObj });
        }
        switch (error.path) {
          case 'problemTitle':
            setUserState({
              type: 'invalidTitle',
              payload: error.message,
            });
            break;
          case 'submissionCount':
            setUserState({
              type: 'invalidSubmissionCount',
              payload: error.message,
            });
            break;
          case 'timeInMinute':
            setUserState({
              type: 'invalidTime',
              payload: error.message,
            });
            break;
          case 'problemDescription':
            setUserState({
              type: 'invalidDescription',
              payload: error.message,
            });
            break;
          case 'difficulty':
            setUserState({
              type: 'invalidDifficulty',
              payload: capitalize(error.message),
            });
            break;
          case 'tags':
            setUserState({
              type: 'invalidTags',
              payload: capitalize(error.message),
            });
            break;
          case 'problemDiagram':
            setUserState({
              type: 'invalidDiagram',
            });
            break;
          default:
            break;
        }
      });
    });
  });

  const handleAddNextProblemClick = (() => {
    if (testCasesCount >= 2) {
      history.push(ROUTES.ADMIN + ADMIN_ROUTES.CREATE_PROBLEM);
    } else toast.error('Add minimum 2 test cases');
  });

  const finishProblemCreation = useCallback(() => {
    history.push(ROUTES.ADMIN + ADMIN_ROUTES.PROBLEMS);
  });

  // TO DO on 2 times clicking diabled back click pop up modal asking for discarding written problem
  const handleBackClick = (() => {
    // this condition controls back button click.
    // (1) before successfully saving created coding problem back button should be accessible.
    // (2) it should not be accessible if coding problem is created but you haven't added minimum of
    //     2 test cases
    // (3) it is accessible after user have added 2 test cases
    if (!isSuccess || (isSuccess && testCasesCount >= 2)) finishProblemCreation();
    else toast.error('Add minimum 2 test cases');
  });

  const imagePreview = (imageFile) => {
    setSelectedImage(imageFileToUrl(imageFile));
  };

  const handleCaptureProblem = useCallback(
    (e) => {
      const file = e.target.files[0];
      setProblemImageError('');

      if (file) {
        if (isImageFile(file.name)) {
          setUserState({
            type: 'problemDiagram',
            payload: file,
          });
          imagePreview(file);
        } else {
          setSelectedImage('');
          setProblemImageError(imageFormatErrorMessage);
        }
      }
    }, [userState.questionDiagram],
  );

  const [showImageModal, setShowImageModal] = useState(false);

  const toggleImageModalVisibility = () => {
    setShowImageModal(!showImageModal);
  };

  return (
    <CreateProblemComponent
      handleTitleChange={handleTitleChange}
      handleDescriptionChange={handleDescriptionChange}
      handleCountChange={handleCountChange}
      handleTimeChange={handleTimeChange}
      handleSubmit={handleSubmit}
      message={message}
      isSuccess={isSuccess}
      isLoading={isLoading}
      finishProblemCreation={finishProblemCreation}
      userState={userState}
      handleSelectedDifficultyChange={handleSelectedDifficultyChange}
      handleSelectedTagsChange={handleSelectedTagsChange}
      problemId={problemId}
      tagsOptions={tagsOptions}
      difficultyOptions={difficultyOptions}
      handleAddNextProblemClick={handleAddNextProblemClick}
      handleBackClick={handleBackClick}
      handleCaptureProblem={handleCaptureProblem}
      selectedImage={selectedImage}
      showImageModal={showImageModal}
      toggleImageModalVisibility={toggleImageModalVisibility}
      problemImageError={problemImageError}
      testCasesList={testCasesList}
      setTestCasesList={setTestCasesList}
      testCaseError={testCaseError}
      setTestCaseError={setTestCaseError}
    />
  );
};

export default React.memo(CreateProblemContainer);
