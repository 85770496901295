import axios from 'axios';

import { getUserDetails } from 'utils';
import local from 'utils/local';

// api header functions

// api headers for all api's other than assessment related api's
const getApiRequestHeader = () => ({
  'access-token': local.getItem('accessToken'),
  'token-type': local.getItem('token-type'),
  client: local.getItem('client'),
  expiry: local.getItem('expiry'),
  uid: local.getItem('uid'),
});

// api headers for assessment related api's
const getAssessmentApiRequestHeader = () => {
  const userDetails = getUserDetails();
  const { auth_token } = userDetails;
  return {
    AUTHORIZATION: auth_token,
    ...getApiRequestHeader(),
  };
};

// axios wrapper functions

// for api's other than assessment
export const get = (url, params = {}) => {
  return axios.get(url, {
    params,
    headers: { ...getApiRequestHeader() },
  });
};

export const post = (url, data = '', headers = {}) => {
  return axios.post(url, data, {
    headers: { ...getApiRequestHeader() },
  });
};

export const patch = (url, data = '') => {
  return axios.patch(url, data, {
    headers: { ...getApiRequestHeader() },
  });
};

export const put = (url, data = '') => {
  return axios.put(url, data, {
    headers: { ...getApiRequestHeader() },
  });
};

export const destroy = (url) => {
  return axios.delete(url, {
    headers: { ...getApiRequestHeader() },
  });
};

// for assessment api's
export const assessmentGet = (url) => {
  return axios.get(url, {
    headers: { ...getAssessmentApiRequestHeader() },
  });
};

export const assessmentPut = (url, data = '') => {
  return axios.put(url, data, {
    headers: { ...getAssessmentApiRequestHeader() },
  });
};

export const assessmentPost = (url, data = '') => {
  return axios.post(url, data, {
    headers: { ...getAssessmentApiRequestHeader() },
  });
};

export const assessmentDelete = (url) => {
  return axios.delete(url, {
    headers: { ...getAssessmentApiRequestHeader() },
  });
};
