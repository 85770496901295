import produce from 'immer';

import { SIDENAV_STATUS } from 'redux/admin/sideNavState/actionConstants';

export const initialState = {
  enabledSideNav: '',
};

const sideNavStateReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SIDENAV_STATUS.SIDENAV_SET:
      state.enabledSideNav = payload;
      break;
    default:
      return state;
  }
});

export default sideNavStateReducer;
