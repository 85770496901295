import { TESTCASE_LIST } from 'redux/admin/testCaseList/actionContants';

// create test cases actions
export const testListRequest = (payload) => ({
  type: TESTCASE_LIST.TESTCASE_LIST_REQUEST_ACTION,
  payload,
});

export const testListAction = (payload) => ({
  type: TESTCASE_LIST.TESTCASE_LIST_SUCCESS_ACTION,
  payload,
});

export const testListFailureAction = (payload) => ({
  type: TESTCASE_LIST.TESTCASE_LIST_FAILURE_ACTION,
  payload,
});

export const testListReset = () => ({
  type: TESTCASE_LIST.TESTCASE_LIST_RESET,
});
