import React from 'react';
import PropTypes from 'prop-types';

import OrganizationComponent from 'modules/superAdmin/organization/OrganizationComponent/OrganizationComponent';

import {
  SearchBox,
} from 'shared-components';
import {
  Spinner,
} from 'core-components';

import './organizationComponent.scss';

const OrganizationsComponent = ({
  organization,
  onClickEdit,
  lastOrgElementRef,
  totalOrganization,
  isOrgListLoading,
  orgListErrorMsg,
  handleOnChange,
  handleSearchClick,
  handleOrganizationSearchClearClick,
  searchText,
}) => {
  return (
    <>
      <div className='organization-list-section d-flex flex-column'>
        <div className='organization-list-section-head d-flex align-items-center px-3 mb-5'>
          <h2 className='organization-heading font-weight-bold mb-0'>
            Organizations ({totalOrganization})
          </h2>
          <div className='d-flex align-items-center ml-auto'>
            <SearchBox
              placeholder='Search Organization'
              handleOnChange={handleOnChange}
              handleSearchClick={handleSearchClick}
              handleCloseClick={handleOrganizationSearchClearClick}
              value={searchText}
              className='mr-3'
            />
          </div>
        </div>
        {orgListErrorMsg === '' && (
          organization.length === 0 ? 'Organization Not Found' : orgListErrorMsg
        )}
        <div className='organization-list-wrap scroll-auto'>
          <ul className='organization-list list-unstyled mb-0 pl-0 mx-3'>
            {organization.map((item, index) => {
              if (organization.length === index + 1) {
                return (
                  <div ref={lastOrgElementRef}>
                    <OrganizationComponent
                      organization={item}
                      onClickEdit={onClickEdit}
                    />
                  </div>
                );
              }
              return (
                <div>
                  <OrganizationComponent
                    organization={item}
                    onClickEdit={onClickEdit}
                  />
                </div>
              );
            })}
            {isOrgListLoading && <Spinner />}
          </ul>
        </div>
      </div>
    </>
  );
};

OrganizationsComponent.propTypes = {
  organization: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  lastOrgElementRef: PropTypes.func.isRequired,
  totalOrganization: PropTypes.number.isRequired,
  isOrgListLoading: PropTypes.bool.isRequired,
  orgListErrorMsg: PropTypes.string.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleSearchClick: PropTypes.func.isRequired,
  handleOrganizationSearchClearClick: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
};

export default React.memo(OrganizationsComponent);
