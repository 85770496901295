/* eslint-disable max-len */
/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Icon,
  FormGroup,
} from 'core-components';
import Loading from 'shared-components/Loading';
import { CloseButton } from 'shared-components';

import './customIOStyle.scss';

const ExecuteScreen = ({
  outputValue,
  loading,
  handleInputChange,
  handleEvent,
  inputValue,
  handleRunClick,
  setOpenOutputScreen,
}) => {
  return (
    <>
      <div>
        <div
          className='output-btn-wrapper
        position-absolute
        toggle-wrapper'
        >
          <div
            className='custom-output-section
          p-4 position-relative
          candidate-output-section'
          >
            <div
              color='warning'
              className='d-flex output-header justify-content-between'
            >
              <span className='ml-3 output-title'>Output</span>
              <CloseButton onClick={setOpenOutputScreen} />
            </div>
            <FormGroup className='d-flex mb-0'>
              <Input
                type='textarea'
                value={loading ? '' : outputValue}
                rows={15}
                className='border-0 show-output-wrapper'
                placeholder={loading ? 'Loading...' : 'Run code to view output here'}
                readOnly
              />
            </FormGroup>
          </div>
        </div>
        <div
          className='custom-button-wrapper position-absolute toggle-wrapper'
          style={{ top: '50%' }}
        >
          <div
            color='link'
            className='custom-input-wrapper border-0 text-decoration-none p-0 text-center'
          >
            <span className='mt-3 d-inline-block'>Add Custom Input</span>
          </div>
          <div
            className='custom-input-section p-4 position-relative'
            style={{ height: '18rem' }}
          >
            <div className='d-flex justify-content-between align-items-center mb-3'>
              <FormGroup className='d-flex mb-0'>
                <Input
                  placeholder='Enter Input'
                  className='border-0'
                  value={inputValue}
                  onChange={handleInputChange}
                  onCopy={handleEvent}
                  onCut={handleEvent}
                  onPaste={handleEvent}
                />
              </FormGroup>
              <div>
                <Button
                  color='secondary'
                  className='text-uppercase text-decoration-none position-absolute run-btn px-3 py-2'
                  onClick={handleRunClick}
                >
                  Run code
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const CustomIOComponent = ({
  toggleFinalOutputModal,
  outputValue,
  isError,
  isSuccess,
  isAllMcqAnswersSaved,
  submissionAllowed,
  isLoading,
  limit,
  totalTestcases,
  testcasesPassed,
  errorMessage,
  toggleConfirmationSubmitModal,
  modal,
  handleConfirmation,
  confirmationModal,
  loading,
  handleInputChange,
  handleEvent,
  inputValue,
  handleRunClick,
  selectedSection,
  onSaveQuestion,
  isSelectedAnswer,
  currentQuestionIndex,
  totalQuestion,
  onMCQSubmitClick,
  saveSuccessMsg,
  handleSaveDraft,
  setOpenOutputScreen,
  openOutputScreen,
}) => {
  const loadingSubmission = () => {
    if (isLoading) {
      return <Loading />;
    }
  };

  const submissionStatue = (total, passed) => {
    const error = 'Code is submitted successfully. But all test cases are not passed.';
    const accepted = 'Code is submitted successfully. All test cases are passed.';

    return (
      <div>
        {passed === total && <div className='text-success font-weight-bold'>{accepted}</div>}
        {passed === 0 && <div className='text-danger font-weight-bold'>{error}</div>}
        {passed > 0 && passed < total && <div className='text-warning font-weight-bold'>Partially Accepted</div>}
      </div>
    );
  };

  const getModalBody = () => {
    if (!isError && !isLoading) {
      return (
        <>
          <p>Submission Left: {submissionAllowed}</p>
          <p>Total Test Cases: {totalTestcases}</p>
          <p>Passed Test Cases: {testcasesPassed}</p>
          {submissionStatue(totalTestcases, testcasesPassed)}
        </>
      );
    }
    return <p className='text-danger'>{errorMessage}</p>;
  };

  const onSubmitClick = () => {
    if (!limit) {
      toggleConfirmationSubmitModal();
    }
  };

  return (
    <>
      {openOutputScreen && selectedSection === 2 ? (
        <ExecuteScreen
          setOpenOutputScreen={setOpenOutputScreen}
          outputValue={outputValue}
          loading={loading}
          handleInputChange={handleInputChange}
          handleEvent={handleEvent}
          inputValue={inputValue}
          handleRunClick={handleRunClick}
        />
      ) : (
        <div className='output-section d-flex justify-content-end p-2'>
          {selectedSection === 2 ? (
            <>
              <div className='d-flex align-items-center'>
                {isError || limit ? (
                  <span className='mr-5'>Submission limit Exceeded</span>
              ) : (
                <span className='mr-5 position-relative'>
                  {submissionAllowed} Submissions Left
                </span>
              )}
              </div>
              <div className='d-flex justify-content-between'>
                <Button
                  onClick={setOpenOutputScreen}
                  className='py-2 px-4 position-relative custom-input-btn mr-2'
                >
                  Add Custom Input
                </Button>
                <Button
                  color='secondary'
                  className='px-4 py-1 mr-2 position-relative'
                  onClick={handleSaveDraft}
                >
                  SAVE
                </Button>
                <Button
                  color='warning'
                  className='py-2 px-4 position-relative output-run-btn mr-2'
                  onClick={setOpenOutputScreen}
                >
                  Run
                </Button>
                <Button
                  color='primary'
                  onClick={onSubmitClick}
                  disabled={limit}
                  className='py-2 px-4 mr-3 position-relative output-run-btn'
                >
                  Submit
                </Button>
              </div>
            </>
        ) : (
          <>
            <div className='d-flex align-items-center mr-5'>
              <span>{saveSuccessMsg}</span>
            </div>
            <Button
              title={!isSelectedAnswer ? 'Please select an option' : ''}
              color='primary'
              onClick={onSaveQuestion}
              disabled={!isSelectedAnswer}
              className='py-2 px-4 mr-3 position-relative output-run-btn'
            >
              {currentQuestionIndex + 1 === totalQuestion
                ? 'Save'
                : 'Save & next'}
            </Button>
            {currentQuestionIndex + 1 === totalQuestion && (
              <Button
                color='primary'
                onClick={onSubmitClick}
                className='py-2 px-4 mr-3 position-relative output-run-btn'
                title={!isAllMcqAnswersSaved ? 'Please save to submit' : ''}
              >
                Submit
              </Button>
            )}
          </>
        )}
          <Modal
            modalClassName='testcase-result-modal'
            isOpen={modal}
            toggle={toggleFinalOutputModal}
            backdrop='static'
          >
            <div className='testcase-result-modal-header d-flex justify-content-between align-items-center'>
              <span className='fs-6'>Final Output</span>
              {isSuccess && (
              <Button
                className='p-2 text-decoration-none bg-transparent border-0'
                onClick={toggleFinalOutputModal}
              >
                <Icon name='close' className='text-secondary ' />
              </Button>
            )}
            </div>
            <ModalBody className='testcase-result-modal-body'>
              {loadingSubmission()}
              {getModalBody()}
            </ModalBody>
          </Modal>
          <Modal
            modalClassName='submit-test-modal'
            isOpen={confirmationModal}
            toggle={toggleConfirmationSubmitModal}
          >
            <ModalHeader className='submission-modal-header'>
              Confirm Submit
            </ModalHeader>
            <ModalBody className='submission-modal-body'>
              {selectedSection === 1 && !isAllMcqAnswersSaved && (
              <p className='text-danger'>
                All answers are not saved.
              </p>
            )}
              <p>
                Do you want to confirm the submisson for this{' '}
                {selectedSection === 2 ? 'problem' : 'MCQ Test'}?
              </p>
            </ModalBody>
            <ModalFooter className='submission-modal-footer'>
              <Button
                color='secondary'
                outline
                className='px-3 py-2 text-decoration-none'
                onClick={toggleConfirmationSubmitModal}
              >
                <Icon name='close' className='mr-2' />
                Cancel
              </Button>
              {selectedSection === 2 ? (
                <Button
                  color='primary'
                  className='px-4 py-2'
                  onClick={handleConfirmation}
                >
                  Confirm
                </Button>
            ) : (
              <Button
                color='primary'
                className='px-4 py-2'
                onClick={onMCQSubmitClick}
              >
                Confirm
              </Button>
            )}
            </ModalFooter>
          </Modal>
        </div>
    )}
    </>
  );
};

ExecuteScreen.propTypes = {
  setOpenOutputScreen: PropTypes.func.isRequired,
  outputValue: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleEvent: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  handleRunClick: PropTypes.func.isRequired,
};

CustomIOComponent.defaultProps = {
  isSelectedAnswer: false,
};

CustomIOComponent.propTypes = {
  toggleFinalOutputModal: PropTypes.func.isRequired,
  outputValue: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  submissionAllowed: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  limit: PropTypes.bool.isRequired,
  totalTestcases: PropTypes.number.isRequired,
  testcasesPassed: PropTypes.number.isRequired,
  errorMessage: PropTypes.string.isRequired,
  toggleConfirmationSubmitModal: PropTypes.func.isRequired,
  isAllMcqAnswersSaved: PropTypes.bool.isRequired,
  modal: PropTypes.bool.isRequired,
  handleConfirmation: PropTypes.func.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
  handleRunClick: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleEvent: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  selectedSection: PropTypes.number.isRequired,
  onSaveQuestion: PropTypes.func.isRequired,
  isSelectedAnswer: PropTypes.bool,
  currentQuestionIndex: PropTypes.number.isRequired,
  totalQuestion: PropTypes.number.isRequired,
  onMCQSubmitClick: PropTypes.func.isRequired,
  saveSuccessMsg: PropTypes.string.isRequired,
  handleSaveDraft: PropTypes.func.isRequired,
  setOpenOutputScreen: PropTypes.func.isRequired,
  openOutputScreen:  PropTypes.bool.isRequired,
};

export default React.memo(CustomIOComponent);
