import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { getEditDriveSuccessAction } from 'redux/admin/getEditDrive/action';
import { getEditDriveApi } from 'redux/admin/getEditDrive/api';
import { GET_EDIT_DRIVE } from 'redux/admin/getEditDrive/actionContants';

const { GET_EDIT_DRIVE_REQUEST_ACTION } = GET_EDIT_DRIVE;
export function* getEditDrive(action) {
  try {
    const response = yield call(getEditDriveApi, action.payload);
    yield put(getEditDriveSuccessAction(response.data.data));
  } catch (error) {
    toast.error(error.response.data.message);
  }
}

export default function* getEditDriveSaga() {
  yield takeEvery(GET_EDIT_DRIVE_REQUEST_ACTION, getEditDrive);
}
