import produce from 'immer';
import { DRIVE_CONTACTS } from 'redux/admin/getDriveContacts/actionConstants';

export const initialState = {
  contactList: [],
  isContactsSuccess: false,
  isContactsLoading: false,
  isContactsError: false,
};

const getDriveContactsReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  const { DRIVE_CONTACTS_REQUEST_ACTION, DRIVE_CONTACTS_SUCCESS_ACTION,
    DRIVE_CONTACTS_FAILURE_ACTION, DRIVE_CLEAR_CONTACTS } = DRIVE_CONTACTS;
  switch (type) {
    case DRIVE_CONTACTS_REQUEST_ACTION:
      state.isContactsLoading = true;
      break;
    case DRIVE_CONTACTS_SUCCESS_ACTION:
      state.isContactsSuccess = true;
      state.isContactsLoading = false;
      state.contactList = payload.drive_contacts;
      break;
    case DRIVE_CONTACTS_FAILURE_ACTION:
      state.isContactsError = true;
      state.isContactsLoading = false;
      break;
    case DRIVE_CLEAR_CONTACTS:
      return { ...initialState };
    default:
      return state;
  }
});
export default getDriveContactsReducer;
