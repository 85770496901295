import { call, put, takeLatest } from 'redux-saga/effects';

import {
  adminForgotPasswordSuccessAction,
  adminForgotPasswordFailureAction,
} from 'redux/admin/forgotPassword/action';
import { forgotPasswordRequest } from 'redux/admin/forgotPassword/api';
import { ADMIN_FORGOT_PASSWORD } from 'redux/admin/forgotPassword/actionConstants';

// worker saga
export function* forgotPasswordInvitationSaga(action) {
  try {
    const response = yield call(forgotPasswordRequest, action.payload);
    yield put(adminForgotPasswordSuccessAction(response.data));
  } catch (error) {
    yield put(adminForgotPasswordFailureAction(error.response.data));
  }
}

// watcherSaga
export default function* forgotPasswordSaga() {
  yield takeLatest(
    ADMIN_FORGOT_PASSWORD.ADMIN_FORGOT_PASSWORD_REQUEST_ACTION,
    forgotPasswordInvitationSaga,
  );
}
