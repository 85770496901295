import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import {
  Input,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Spinner,
  Toast,
  Icon,
} from 'core-components/index';

import './loginCard.scss';

const LoginCard = (props) => {
  const {
    handleEmailChange,
    handlePasswordChange,
    handleSubmit,
    emailError,
    passwordError,
    email,
    password,
    isError,
    isLoading,
    errorMessage,
    passwordVisibility,
    setPasswordVisibility,
  } = props;

  const invalidUser = () => {
    if (isError) {
      return (
        <Toast className='bg-transparent border-0 shadow-0 text-center pt-3 text-danger font-weight-bold'>
          <p>{errorMessage}</p>
        </Toast>
      );
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  return (
    <div className='login-card-box position-absolute'>
      <h2 className='font-weight-normal mt-5'>Welcome to SparKode</h2>
      <Form className='login-form d-flex flex-column'>
        <FormGroup>
          <Label className='font-weight-normal'>Email</Label>
          <Input
            id='exampleEmail'
            type='email'
            placeholder='example@gmail.com'
            value={email}
            onChange={handleEmailChange}
            invalid={emailError !== ''}
          />
          <FormFeedback type='invalid' target='exampleEmail'>
            {emailError}
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className='font-weight-normal'>Password</Label>
          <div className='password'>
            <Input
              type={passwordVisibility ? 'text' : 'password'}
              id='examplePassword'
              placeholder='******'
              value={password}
              onChange={handlePasswordChange}
              invalid={passwordError !== ''}
              className='password__input'
            />
            <FormFeedback type='invalid' target='examplePassword'>
              {passwordError}
            </FormFeedback>
          </div>
          <FormGroup check>
            <Label
              for='exampleCheck'
              check
              className='font-weight-normal d-flex align-items-center mt-2'
              id='exampleCheckFormGroup'
            >
              <Input
                type='checkbox'
                name='check'
                id='exampleCheck'
                className='form-check-input-sm mt-0'
                onClick={togglePasswordVisibility}
              />
              <span className='ml-2'>Show Password</span>
            </Label>
          </FormGroup>
        </FormGroup>
        <div className='login-details-box d-flex justify-content-between align-items-center pt-0 pb-1'>
          <Link to='/forgot_password' className='forgot-password-link'>
            Forgot Password
          </Link>
          <FormGroup check>
            <Label
              for='exampleCheck'
              check
              className='font-weight-normal d-flex align-items-center'
              id='exampleCheckFormGroup'
            >
              <Input
                type='checkbox'
                name='check'
                id='exampleCheck'
                className='form-check-input-sm mt-0'
              />
              <span className='ml-2'>Remember Me</span>
            </Label>
          </FormGroup>
        </div>
        <div>{invalidUser()}</div>
        <FormGroup className='d-flex mb-0 py-4'>
          <Button
            className='border-0 w-100'
            onClick={handleSubmit}
            color='primary'
          >
            {isLoading ? <Spinner size='sm' color='light' /> : <>Login</>}
          </Button>
        </FormGroup>
        <div className='login-details-box d-flex'>
          Don&apos;t have an account?&nbsp;&nbsp;&nbsp;
          <Link
            to='/signup'
            className='forgot-password-link text-center text-underline'
          >
            Sign Up
          </Link>
        </div>
      </Form>
    </div>
  );
};

LoginCard.propTypes = {
  handleEmailChange: PropTypes.func.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  emailError: PropTypes.string.isRequired,
  passwordError: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  passwordVisibility: PropTypes.bool.isRequired,
  setPasswordVisibility: PropTypes.func.isRequired,
};

export default React.memo(LoginCard);
