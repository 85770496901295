import { booleanOptions } from 'constants/optionsConstants';

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const stringToKeyValue = (value) => ({ value, label: capitalize(value) });

const arrayToKeyValueHash = (array) => {
  return array.map((tag) => stringToKeyValue(tag));
};

// converting boolean 'type' into key-value pair from booleanOptions
export const getType = (value) => {
  const typeObject = booleanOptions.find((type) => type.value === value.toString());
  return typeObject;
};

export default arrayToKeyValueHash;
