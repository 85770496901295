import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  editProblemSuccessAction,
  editProblemFailureAction,
} from 'redux/admin/editProblem/action';
import { EDIT_PROBLEM } from 'redux/admin/editProblem/actionConstants';
import { editProblemPutApi } from 'redux/admin/editProblem/api';
import { getPresignedUrlApi, putImageS3 } from 'redux/admin/createProblem/api';

// worker saga
export function* editProblemSaga(action) {
  const {
    title,
    description,
    submissionCount,
    timeInMinutes,
    difficulty,
    tags,
    problemId,
    problemDiagram,
    testCasesAttributes,
  } = action.payload;

  let image_url;

  // if Problem image is updated
  if (problemDiagram) {
    const presignedUrl = yield call(getPresignedUrlApi);

    const imageUrl = yield call(putImageS3, {
      url: presignedUrl.data.data.url,
      image: problemDiagram,
    });

    image_url = imageUrl.config.url.substr(0, imageUrl.config.url.indexOf('?'));
  }

  const data = {
    title,
    description,
    submission_count: submissionCount,
    time_in_minutes: timeInMinutes,
    difficulty_list: difficulty,
    tag_list: tags,
    id: problemId,
    image_url,
    test_cases_attributes: testCasesAttributes,
  };

  try {
    const response = yield call(editProblemPutApi, data);
    toast.success('Problem updated successfully');
    yield put(editProblemSuccessAction({
      message: response.data.message,
      pid: response.data.data.problem.id,
    }));
  } catch (error) {
    const { data: responseData } = error.response;

    if (responseData) {
      const { message } = responseData;

      toast.error(message);
      yield put(editProblemFailureAction(message));
    }
  }
}

// watcherSaga
export default function* editProblemWatcherSaga() {
  yield takeLatest(EDIT_PROBLEM.EDIT_PROBLEM_REQUEST_ACTION, editProblemSaga);
}
