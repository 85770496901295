import React, { useCallback, useEffect, useReducer, useState, useRef, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import TemplateComponent from 'modules/admin/template/TemplateComponent';

import { reducer } from 'modules/admin/template/reducer';
import { setActiveTab } from 'redux/admin/questionTabs/action';
import {
  fetchLanguages,
  setLanguageSelected,
} from 'actions/languageAction';

import { getUserDetails } from 'utils';

import { validateData } from '../dataValidation';
import {
  deleteTemplateApi,
  postTemplateApi,
  updateTemplateApi,
  updateDefaultTemplateApi,
  getDefaultTemplatesApi,
  getTemplatesApi,
  postDefaultTemplateApi,
} from './api';

const ProblemSaved = (problemId) => {
  return problemId === '';
};

const TemplateContainer = (props) => {
  const dispatch = useDispatch();
  const scrollTemplateRef = createRef();
  const { problemId, showTemplateHeader } = props;
  const { message, isSuccess } = useSelector((state) => state.createDriveReducer);

  const { languages, languageSelected } = useSelector((state) => state.languageReducer);
  const userDetails = getUserDetails();
  const initialUserState = {
    template: '',
    templateError: '',
    isTemplateEdit: false,
    templates: [],
    id: -1,
    isTemplateLoaded: false,
  };

  const [templateState, setTemplateState] = useReducer(reducer, initialUserState);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddTemplate, setIsAddTemplate] = useState(false);
  const [isEditTemplate, setIsEditTemplate] = useState(false);

  const setActiveTabMethod = (activeTabState) => {
    dispatch(setActiveTab(activeTabState));
  };

  const schema = yup.object().shape({
    code: yup.string().required('Code can\'t be blank'),
  });

  // TODO
  // very very very very BAD CODE - Need to change everything in below function
  const loadTemplates = useCallback(
    async () => {
      if (problemId) {
        useEffect(async () => {
          // TODO
          // api without saga 28
          const result = await getTemplatesApi(problemId);
          const templatesList = result.data !== undefined ? [...result.data.data.templates] : [];
          setTemplateState({
            type: 'setAndDeleteTemplate',
            payload: {
              subType: 'setTemplates',
              data: templatesList,
            },
          });
        }, []);
      } else {
        useEffect(async () => {
          // TODO
          // api without saga 29
          const result = await getDefaultTemplatesApi();
          const templatesList = result.data !== undefined ? [...result.data.data.templates] : [];
          setTemplateState({
            type: 'setAndDeleteTemplate',
            payload: {
              subType: 'setTemplates',
              data: templatesList,
            },
          });
        }, []);
      }
    }, [],
  );

  loadTemplates();

  const handleTemplateChange = useCallback(
    (event) => {
      setTemplateState({
        type: 'setError',
        payload: {
          subType: 'templateError',
          data: '',
        },
      });
      const template = event.trim();
      setTemplateState({
        type: 'set template/id',
        payload: {
          data: template,
          subType: 'template',
        },
      });
    },
    [templateState.template],
  );

  const handleOnAdd = useCallback(
    () => {
      const { template } = templateState;
      const isDefault = problemId === ('' || undefined);

      const data = {
        code: template,
        is_default: isDefault,
        language_id: languageSelected.id,
      };
      const problemData = {
        code: template,
        problem_id: problemId,
        is_default: isDefault,
        language_id: languageSelected.id,
      };

      // TODO
      // api without saga 30 - 31
      schema.isValid(data).then(async (valid) => {
        if (!valid) {
          validateData(schema, data, setTemplateState);
        } else {
          if (ProblemSaved(problemId)) {
            toast.error('Please create problem first');
            setTemplateState({ type: 'default' });
            return;
          }
          setIsLoading(true);
          const apiCall = isDefault ? postDefaultTemplateApi(data) : postTemplateApi(problemData);

          try {
            const result = await apiCall;
            setIsLoading(false);
            data.id = result.data.data.template.id;
            setTemplateState({
              type: 'addTemplate',
              payload: { ...data, language: languageSelected.name },
            });
            toast.success(result.data.message);
          } catch (error) {
            setTemplateState({
              type: 'setError',
              payload: {
                subType: 'templateError',
                data: '',
              },
            });
            setIsAddTemplate(false);
            setIsEditTemplate(false);
            setIsLoading(false);
            toast.error(error.response.data.message);
          }
        }
      });
    },
  );

  const handleOnTemplateEdit = useCallback(
    (tid) => {
      setTemplateState({
        type: 'setError',
        payload: {
          subType: 'templateError',
          data: '',
        },
      });
      setIsEditTemplate(true);
      setIsAddTemplate(false);
      let template = {};
      const { templates } = templateState;
      for (let i = 0; i < templates.length; i += 1) {
        if (templates[i].id === tid) {
          template = templates[i];
          const { code, id, language, language_id } = template;
          const langObj = {
            id: language_id,
            name: language,
          };
          dispatch(setLanguageSelected(langObj));
          setTemplateState({
            type: 'editTemplate',
            payload: { code, id },
          });
          break;
        }
      }
    }, [templateState.template, templateState.marks, templateState.templates, templateState.id],
  );

  const handleOnTemplateUpdate = useCallback(
    () => {
      setTemplateState({
        type: 'setError',
        payload: {
          subType: 'templateError',
          data: '',
        },
      });
      const { templates, template, id } = templateState;
      const isDefault = problemId === ('' || undefined);
      const data = {
        code: template,
        is_default: isDefault,
        language_id: languageSelected.id,
        id,
      };
      const problemData = {
        code: template,
        problem_id: problemId,
        is_default: isDefault,
        language_id: languageSelected.id,
        id,
      };

      // TODO
      // api without saga 32 - 33
      schema.isValid(data).then(async (valid) => {
        if (!valid) {
          validateData(schema, data, setTemplateState);
        } else {
          let index;
          const templateObj = { ...data, language: languageSelected.name };
          for (let i = 0; i < templates.length; i += 1) {
            if (templates[i].id === id) {
              index = i;
              break;
            }
          }
          const apiCall = isDefault ?
            updateDefaultTemplateApi(data) :
            updateTemplateApi(problemData);
          try {
            const result = await apiCall;
            setTemplateState({
              type: 'updateTemplate',
              payload: { index, templateObj },
            });
            const templateData = await getTemplatesApi(problemId);
            const templateList = templateData.data !== undefined ?
              [...templateData.data.data.templates] : [];
            setTemplateState({
              type: 'setAndDeleteTemplate',
              payload: {
                subType: 'setTemplates',
                data: templateList,
              },
            });
            toast.success(result.data.message);
          } catch (error) {
            toast.error(error.response.data.message);
            setTemplateState({ type: 'default' });
          }
          setIsAddTemplate(false);
          setIsEditTemplate(false);
          setTemplateState({
            type: 'setError',
            payload: {
              subType: 'templateError',
              data: '',
            },
          });
        }
      });
    }, [templateState.template, templateState.output, templateState.marks, templateState.templates,
      templateState.id],
  );

  const handleOnTemplateDelete = useCallback(
    async (id) => {
      // TODO
      // api without saga 34
      try {
        const { templates } = templateState;
        const result = await deleteTemplateApi(id);
        let index;
        for (let i = 0; i < templates.length; i += 1) {
          if (templates[i].id === id) {
            index = i;
            break;
          }
        }
        if (result.status === 200) {
          setTemplateState({
            type: 'setAndDeleteTemplate',
            payload: {
              subType: 'deleteTemplate',
              data: index,
            },
          });
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }, [templateState.templates],
  );

  const handleOnCancel = useCallback(
    (event) => {
      event.preventDefault();
      setTemplateState({
        type: 'setdefault',
      });
    },
    [templateState.template, templateState.output, templateState.marks],
  );

  const handleAddMoreClick = () => {
    setTemplateState({
      type: 'set template/id',
      payload: {
        data: '',
        subType: 'template',
      },
    });
    setTemplateState({
      type: 'setError',
      payload: {
        subType: 'templateError',
        data: '',
      },
    });
    setIsAddTemplate(true);
    setIsEditTemplate(false);
  };

  const handleLanguageClick = useCallback(
    (e) => {
      const langObj = {
        id: parseInt(e.currentTarget.getAttribute('id'), 10),
        name: e.currentTarget.textContent,
      };
      dispatch(setLanguageSelected(langObj));
      setTemplateState({
        type: 'setError',
        payload: {
          subType: 'templateError',
          data: '',
        },
      });
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(fetchLanguages());
  }, []);

  return (
    <TemplateComponent
      handleTemplateChange={handleTemplateChange}
      handleOnTemplateAdd={handleOnAdd}
      handleOnTemplateDelete={handleOnTemplateDelete}
      handleOnTemplateEdit={handleOnTemplateEdit}
      handleOnTemplateUpdate={handleOnTemplateUpdate}
      handleOnCancel={handleOnCancel}
      message={message}
      isProblemSuccess={isSuccess}
      isTemplateEdit={templateState.isTemplateEdit}
      templates={templateState.templates}
      isLoading={isLoading}
      template={templateState.template}
      templateError={templateState.templateError}
      handleAddMoreClick={handleAddMoreClick}
      languages={languages}
      handleLanguageClick={handleLanguageClick}
      languageSelected={languageSelected}
      setActiveTabMethod={setActiveTabMethod}
      showTemplateHeader={showTemplateHeader}
      scrollTemplateRef={scrollTemplateRef}
      isAddTemplate={isAddTemplate}
      isEditTemplate={isEditTemplate}
    />
  );
};

TemplateContainer.defaultProps = {
  showTemplateHeader:true,
};

TemplateContainer.propTypes = {
  problemId: PropTypes.number.isRequired,
  showTemplateHeader: PropTypes.bool,
};

export default React.memo(TemplateContainer);
