/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { Spinner } from 'reactstrap';
import { toast } from 'react-toastify';

import CreateQuestionComponent from 'modules/admin/createQuestion/CreateQuestionComponent';

import { schema } from 'modules/admin/createQuestion/CreateQuestionContainer/schema';
import { editQuestionRequestAction, clearEditQuestionState, setQuestionRequestAction } from 'redux/admin/editQuestion/action';
import { tagsRequestAction } from 'redux/admin/tags/action';
import { setActiveTab } from 'redux/admin/questionTabs/action';
import { initialValues } from 'modules/admin/createQuestion/CreateQuestionContainer';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';
import { MCQ_TAB } from 'constants/appConstants';

import { correctOptionValidation, minTwoOptionValidation } from 'modules/admin/createQuestion/CreateQuestionContainer/utils';
import arrayToKeyValueHash from 'utils/arrayToKeyValueHash';
import { convertResponseToInitialValues } from './utils';

const EditQuestionContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { questionId } = useParams();
  const { PROBLEMS } = ADMIN_ROUTES;
  const { ADMIN } = ROUTES;

  const [defaultType, setDefaultType] = useState();
  const [tagsOptions, setTagsOptions] = useState([]);
  const [difficultyOptions, setDifficultyOptions] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [targetId, setTargetId] = useState();
  const [mcqDescription, setMcqDescription] = useState();

  const {
    question,
    isQuestionLoading,
    isQuestionEditSuccess,
    isSuccess,
  } = useSelector((state) => state.editQuestionReducer);
  const { difficultyList, tagList } = useSelector((state) => state.tagsReducer);

  const handleUpdateClick = useCallback((values) => {
    const nonDeletedOptions = values.mcqOptions.filter((option) => !option?.isDelete);
    if (nonDeletedOptions.length > 5) {
      toast.error('Maximum 5 options are allowed');
    } else {
      dispatch(editQuestionRequestAction(values));
    }
  });

  const onSubmit = (values) => {
    if (!minTwoOptionValidation(values.mcqOptions)) {
      toast.error('Add at least two options');
    } else {
      const validationError = correctOptionValidation(values.type, values.mcqOptions);

      if (!validationError) {
        handleUpdateClick(values);
      } else {
        toast.error(validationError);
      }
    }
  };

  const formik = useFormik({
    initialValues: { ...initialValues, id: 0 },
    onSubmit,
    validationSchema: schema,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(setActiveTab(MCQ_TAB));
      history.push(ROUTES.ADMIN + ADMIN_ROUTES.PROBLEMS);
    }
  }, [isSuccess]);

  useEffect(() => {
    dispatch(setQuestionRequestAction(questionId));
  }, [isQuestionEditSuccess]);

  useEffect(() => {
    dispatch(tagsRequestAction());
  }, []);

  useEffect(() => {
    setDifficultyOptions(arrayToKeyValueHash(difficultyList));
  }, [difficultyList]);

  useEffect(() => {
    setTagsOptions(arrayToKeyValueHash(tagList));
  }, [tagList]);

  useEffect(() => {
    setDefaultType(question.is_multi_select ? 'Multiple Choice' : 'Single Choice');
  }, [question]);

  // storing question data from backend into formik states
  useEffect(() => {
    if (!isQuestionLoading) {
      const formikQuestionInitialValues = convertResponseToInitialValues(
        isQuestionLoading, question,
      );
      formik.setValues(formikQuestionInitialValues);
    }
  }, [isQuestionLoading]);

  useEffect(() => {
    return () => {
      dispatch(clearEditQuestionState());
    };
  }, []);

  const toggleImageModalVisibility = () => {
    setShowImageModal(!showImageModal);
  };

  const handleOnOptionDelete = (index) => {
    if (formik.values.id) {
      if (formik.values.mcqOptions[index].id) {
        formik.setFieldValue(
          `mcqOptions[${index}].isDelete`,
          true,
        );
      } else {
        const { mcqOptions } = formik.values;
        mcqOptions.splice(index, 1);
      }
    }
  };

  const toggleConfirmation = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleDeleteClick = (id, description) => {
    setTargetId(id);
    setMcqDescription(description);
    toggleConfirmation();
  };

  // on update page back click
  // TO DO
  // on editing anything in form give alert for saving changes
  const finishQuestionEdit = useCallback(() => {
    dispatch(setActiveTab(MCQ_TAB));
    history.push(ADMIN + PROBLEMS);
  });

  const defaultTags = [];
  if (question.tags) {
    question.tags.map((tag) => {
      return defaultTags.push({ value: tag, label: tag });
    });
  }

  const isQuestionPresent = !isQuestionLoading && formik?.values?.id;

  return (
    <>
      {isQuestionPresent ? (
        <CreateQuestionComponent
          isQuestionCreateSuccess
          handleBackClick={finishQuestionEdit}
          handleUpdateClick={handleUpdateClick}
          questionId={question.id}
          defaultTags={defaultTags}
          defaultType={defaultType}
          imageUrl={question.image_url}
          pageTitle='Update Question'
          message='After updating question, make sure to hit update button'
          isQuestionLoading={isQuestionLoading}
          tagsOptions={tagsOptions}
          difficultyOptions={difficultyOptions}
          showImageModal={showImageModal}
          toggleImageModalVisibility={toggleImageModalVisibility}
          formik={formik}
          handleDeleteClick={handleDeleteClick}
          targetId={targetId}
          mcqDescription={mcqDescription}
          confirmationModal={confirmationModal}
          handleOnOptionDelete={handleOnOptionDelete}
          toggleConfirmation={toggleConfirmation}
        />
      ) :
        (
          <div className='loader-wrapper align-items-center justify-content-center'>
            <Spinner className='loader' color='primary' />
          </div>
        )}
    </>
  );
};

export default React.memo(EditQuestionContainer);
