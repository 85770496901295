import { SERVER_URL } from 'constants/appConstants';
import { get, destroy } from '../apiHelper';

export const getProblems = (queryParams) => {
  return get(`${SERVER_URL}admin/problems?${queryParams}`);
};

export const deleteProblem = (id) => {
  return destroy(`${SERVER_URL}admin/problems/${id}`);
};
