import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import CandidateRoutes from 'root/CandidateRoutes';
import AdminRoutes from 'root/AdminRoutes';
import SuperAdminRoutes from 'root/SuperAdminRoutes';
import ReviewerRoutes from 'root/ReviewerRoutes';

import LoginContainer from 'modules/admin/login/LoginContainer';
import AcceptInvitationContainer from 'modules/admin/acceptInvitation/AcceptInvitationContainer';
import SignupContainer from 'modules/admin/signup/SignupContainer';
import ForgotPasswordContainer from 'modules/admin/forgotPassword/ForgotPasswordContainer';
import VerifyForgotPasswordContainer from 'modules/admin/verifyForgotPassword/VerifyForgotPasswordContainer';

import ErrorPage404 from 'shared-components/ErrorPage404';
import InvalidTokenComponent from 'shared-components/InvalidTokenComponent';

import { ROUTES } from 'constants/routeConstants';

function Routes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={LoginContainer} />

      <Route path={ROUTES.LOGIN} component={LoginContainer} />

      <Route exact path={ROUTES.HOME} component={AdminRoutes} />

      <Route path={ROUTES.ADMIN} component={AdminRoutes} />

      <Route path={ROUTES.SUPER_ADMIN} component={SuperAdminRoutes} />

      <Route path={ROUTES.REVIEWER} component={ReviewerRoutes} />

      <Route path={ROUTES.CANDIDATE} component={CandidateRoutes} />

      <Route path={ROUTES.ACCEPT_INVITATION} component={AcceptInvitationContainer} />

      <Route path={ROUTES.SIGNUP} component={SignupContainer} />

      <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordContainer} />

      <Route path={ROUTES.VERIFY_FORGOT_PASSWORD} component={VerifyForgotPasswordContainer} />

      <Route exact path={ROUTES.ERROR_PAGE_404} component={ErrorPage404} />

      <Route exact path={ROUTES.INVALID_TOKEN} component={InvalidTokenComponent} />

      <Redirect to={ROUTES.HOME} />

    </Switch>
  );
}

export default Routes;
