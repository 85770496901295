import { call, put, takeEvery } from 'redux-saga/effects';
import {
  tagsFailureAction, tagsSuccessAction,
} from 'redux/admin/tags/action';
import { getTagsApi } from 'redux/admin/tags/api';
import { TAGS } from 'redux/admin/tags/actionContants';

const { TAGS_REQUEST_ACTION } = TAGS;

export function* getTags() {
  try {
    const response = yield call(getTagsApi);
    yield put(tagsSuccessAction(response.data.data.tags));
  } catch (error) {
    yield put(tagsFailureAction(error));
  }
}

export default function* getTagsSaga() {
  yield takeEvery(TAGS_REQUEST_ACTION, getTags);
}
