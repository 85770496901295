import { EDIT_ORGANIZATION, ADD_CREDITS } from 'redux/superAdmin/editOrganization/actionConstants';

const { EDIT_ORGANIZATION_REQUEST_ACTION, EDIT_ORGANIZATION_SUCCESS_ACTION,
  EDIT_ORGANIZATION_FAILURE_ACTION, GET_ORGANIZATION_REQUEST_ACTION,
  GET_ORGANIZATION_SUCCESS_ACTION,
  GET_ORGANIZATION_FAILURE_ACTION, CLEAR_STATE } = EDIT_ORGANIZATION;

export const editOrganizationRequestAction = (payload) => ({
  type: EDIT_ORGANIZATION_REQUEST_ACTION,
  payload,
});
export const editOrganizationSuccessAction = (payload) => ({
  type: EDIT_ORGANIZATION_SUCCESS_ACTION,
  payload,
});
export const editOrganizationFailureAction = (payload) => ({
  type: EDIT_ORGANIZATION_FAILURE_ACTION,
  payload,
});

export const getOrganizationRequestAction = (payload) => ({
  type: GET_ORGANIZATION_REQUEST_ACTION,
  payload,
});
export const getOrganizationSuccessAction = (payload) => ({
  type: GET_ORGANIZATION_SUCCESS_ACTION,
  payload,
});
export const getOrganizationFailureAction = (payload) => ({
  type: GET_ORGANIZATION_FAILURE_ACTION,
  payload,
});

export const editOrganizationClearAction = () => ({
  type: CLEAR_STATE,
});
