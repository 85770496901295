import { SERVER_URL } from 'constants/appConstants';
import { post, assessmentPost } from 'redux/admin/apiHelper';

export const sendEmails = (data) => {
  return post(`${SERVER_URL}admin/invite`, data);
};

export const resendEmails = (data) => { return post(`${SERVER_URL}admin/resend_invite`, data); };

export const resendAssessmentEmails = (data) => {
  return assessmentPost(`${SERVER_URL}admin/assessments/resend_invite`, data);
};

export const sendAssessmentEmail = (data) => {
  return assessmentPost(`${SERVER_URL}admin/assessments`, data);
};
