import { call, takeLatest, put } from 'redux-saga/effects';

import driveDetail from 'apis/userDriveApis';
import {
  setUserDriveDetails,
  showErrorMessage,
} from 'actions/userDriveActions';

import { DRIVE } from 'constants/actionConstants';
import local from 'utils/local';

// worker saga
export function* driveDetails(action) {
  try {
    const response = yield call(driveDetail, action.payload.token);
    const {
      candidate_id: candidateId,
      drive_start_time: startTime,
      drive_end_time: endTime,
      is_finished: isFinished,
    } = response.data.data;
    const {
      id,
      name,
      allow_coding: allowCoding,
      allow_mcq: allowMcq,
    } = response.data.data.drive;
    const userDriveDetails = {
      data: {
        id,
        name,
        startTime,
        endTime,
        allowCoding,
        allowMcq,
      },
      candidateId,
      isFinished,
    };
    yield put(setUserDriveDetails(userDriveDetails));
    local.setItem('authToken', action.payload.token);
    local.setItem('driveID', id);
    local.setItem('name', name);
    local.setItem('candidateId', candidateId);
    local.setItem('isFinishedTest', isFinished);
  } catch (error) {
    yield put(showErrorMessage(error.response.data));
  }
}

// watcher saga
export default function* driveSagas() {
  yield takeLatest(DRIVE.DRIVE_DETAIL_REQUEST, driveDetails);
}
