import { MCQ_QUESTION } from 'constants/actionConstants';

export const initialState = {
  questions: [],
  isLoading: false,
  selectedQuestion: 0,
  selectedAnswers: {},
  isSaving: false,
  saveSuccessMsg: '',
  saveddAnswers: {},
  isPresentMCQ: false,
};

const mcqQuestionsReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case MCQ_QUESTION.MCQ_QUESTION_REQUEST_ACTION:
      return { ...state, isLoading: true, questions: [] };
    case MCQ_QUESTION.MCQ_QUESTION_SUCCESS_ACTION:
      return {
        ...state,
        isLoading: false,
        // this condition will sort question randomly
        questions: payload.sort(() => Math.random() - 0.5),
        isPresentMCQ: payload.length && true,
      };
    case MCQ_QUESTION.SELECT_MCQ:
      return { ...state, selectedQuestion: payload, selectedAnswers: { ...state.saveddAnswers } };
    case MCQ_QUESTION.SAVE_QUESTION_REQUEST_ACTION:
      return { ...state, isSaving: true, saveSuccessMsg: '' };
    case MCQ_QUESTION.SAVE_QUESTION_SUCCESS_ACTION:
      return {
        ...state,
        isSaving: false,
        saveddAnswers: { ...state.saveddAnswers, ...payload },
        selectedAnswers: { ...state.saveddAnswers, ...payload },
        selectedQuestion: state.selectedQuestion === state.questions.length ?
          state.selectedQuestion : state.selectedQuestion + 1,
        saveSuccessMsg: state.isSaving ? 'Answer Saved Successfully' : '',
      };
    case MCQ_QUESTION.SELECT_ANSWER_REQUEST_ACTION:
      return {
        ...state,
        selectedAnswers: { ...state.selectedAnswers, ...payload },
      };
    case MCQ_QUESTION.CLEAR_SAVE_MESSAGE:
      return {
        ...state, saveSuccessMsg: '',
      };
    default:
      return state;
  }
};

export default mcqQuestionsReducer;
