/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React from 'react';

import {
  ActionTile,
  CardComponent,
  FilterComponent,
  organizationTile,
} from 'shared-components';

import './organizationComponent.scss';

const OrganizationComponent = ({
  organization,
  onClickEdit,
}) => {
  const {
    id,
    credits,
    total_drives_candidates: totalDrivesCandidates,
    total_assessments_candidates: totalAssessmentsCandidates,
    total_drives: totalDrives,
    show_name: showName,
    name,
    description,
    total_assessments: totalAssessment,
    attended_assessments_candidates: attendedAssessmentsCandidates,
    attended_drives_candidates: attendedDrivesCandidates,
    unattended_assessments_candidates: unattendedAssessmentsCandidates,
    unattended_drives_candidates: unattendedDrivesCandidates,
  } = organization;
  return (
    <>
      <li className='organization-list-item h-100'>
        <CardComponent>
          <div
            className='organization-content p-3 d-flex flex-column overflow-hidden'
          >
            <ActionTile
              isOrganization
              id={id}
              className='mb-3'
              showCredits
              credits={credits}
              organization={organization}
              onClickEdit={onClickEdit}
              totalDrives={totalDrives}
              totalDrivesCandidates={totalDrivesCandidates}
              totalAssessment={totalAssessment}
              totalAssessmentsCandidates={totalAssessmentsCandidates}
              attendedAssessmentsCandidates={attendedAssessmentsCandidates}
              attendedDrivesCandidates={attendedDrivesCandidates}
              unattendedAssessmentsCandidates={unattendedAssessmentsCandidates}
              unattendedDrivesCandidates={unattendedDrivesCandidates}
              editTypeTitle='Organization'
            />
            <h6 className='mb-2 font-weight-bold card-heading text-capitalize'>
              {showName || name}
            </h6>

            <p className='organization-description text-truncate-three-line overflow-hidden mb-0'>
              {description ||
                `Organization Description will be provided here.
              Click on edit button to add description.`}
            </p>
          </div>
        </CardComponent>
      </li>
    </>
  );
};

OrganizationComponent.propTypes = {
  organization: PropTypes.shape().isRequired,
  onClickEdit: PropTypes.func.isRequired,
};

export default React.memo(OrganizationComponent);
