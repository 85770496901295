import produce from 'immer';
import { PROBLEMS_LIST, PROBLEM_DELETE } from 'redux/admin/problemsList/actionContants';

const {
  PROBLEMS_LIST_FAILURE_ACTION, PROBLEMS_LIST_REQUEST_ACTION,
  PROBLEMS_LIST_SUCCESS_ACTION, SET_PROBLEMS_DIFFICULTY, SET_PROBLEMS_LIMIT,
  SET_PROBLEMS_SEARCH_TEXT, SET_PROBLEMS_TAG, RESET_PROBLEMS_FILTERS, CLEAR_PROBLEMS,
  RESET_PROBLEMS_SEARCH_TEXT, SET_SEARCHED_PROBLEMS_FILTER_ACTION,
  RESET_SEARCHED_PROBLEMS_FILTER_ACTION,
} = PROBLEMS_LIST;

const {
  PROBLEM_DELETE_FAILURE_ACTION, PROBLEM_DELETE_REQUEST_ACTION,
  PROBLEM_DELETE_SUCCESS_ACTION,
} = PROBLEM_DELETE;

export const initialState = {
  problems: [],
  totalProblems: 0,
  totalpages: 1,
  isProblemsSuccess: false,
  isProblemsLoading: false,
  isProblemsError: false,
  isProblemDeleteSuccess: false,
  isProblemDeleteLoading: false,
  isProblemDeleteError: false,
  offset: 0,
  deletionCount: 0,
  problemSearchText: '',
  problemDifficulty: '',
  problemTag: [],
  searchedProblemDifficulty: '',
  searchedProblemTag: [],
  problemDeleteErrorMessage: '',
  getProblemsListErrorMessage: '',
};

const problemsListReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PROBLEMS_LIST_REQUEST_ACTION:
      return {
        ...state,
        isProblemsLoading: true,
        problems: payload.problems,
        offset: payload.offset,
        deletionCount: 0,
      };
    case PROBLEMS_LIST_SUCCESS_ACTION:
      return {
        ...state,
        isProblemsSuccess: true,
        isProblemsLoading: false,
        problems: [...state.problems, ...payload.problems],
        totalProblems: payload.total_problems,
        totalpages: payload.pages,
      };
    case PROBLEMS_LIST_FAILURE_ACTION:
      return {
        ...state,
        isProblemsError: true,
        isProblemsLoading: false,
        getProblemsListErrorMessage: payload.message,
      };
    case CLEAR_PROBLEMS:
      return { ...initialState };
    case PROBLEM_DELETE_REQUEST_ACTION:
      return {
        ...state,
        isProblemDeleteLoading: true,
      };
    case PROBLEM_DELETE_SUCCESS_ACTION:
      return {
        ...state,
        isProblemDeleteSuccess: true,
        isProblemDeleteLoading: false,
        problems: state.problems.filter((problem) => problem.id !== payload),
        deletionCount: state.deletionCount + 1,
        totalProblems: state.totalProblems - 1,
      };
    case PROBLEM_DELETE_FAILURE_ACTION:
      return {
        ...state,
        isProblemDeleteError: true,
        isProblemDeleteLoading: false,
        problemDeleteErrorMessage: payload.message,
      };
    case SET_PROBLEMS_LIMIT:
      return { ...state, offset: payload };
    case SET_PROBLEMS_SEARCH_TEXT:
      return { ...state, problemSearchText: payload };
    case SET_PROBLEMS_DIFFICULTY:
      return { ...state, problemDifficulty: payload };
    case SET_PROBLEMS_TAG:
      return { ...state, problemTag: payload };
    case RESET_PROBLEMS_FILTERS:
      return {
        ...state,
        problemTag: [],
        problemDifficulty: '',
        searchedProblemDifficulty: '',
        searchedProblemTag: [],
      };
    case RESET_PROBLEMS_SEARCH_TEXT:
      return {
        ...state,
        problemSearchText: '',
      };
    case SET_SEARCHED_PROBLEMS_FILTER_ACTION:
      return {
        ...state,
        searchedProblemDifficulty: payload.searchedProblemDifficulty,
        searchedProblemTag: payload.searchedProblemTag,
      };
    case RESET_SEARCHED_PROBLEMS_FILTER_ACTION:
      return {
        ...state,
        searchedProblemDifficulty: '',
        searchedProblemTag: [],
      };
    default:
      return state;
  }
});

export default problemsListReducer;
