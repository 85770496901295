import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Table,
  Button,
} from 'core-components';

import { ImagePopUpModal } from 'shared-components';

import {
  getPassedValue,
  getScoreOrPercentage,
  getSubmissionCount,
} from './util';
import RunCodeModal from './runCodeModal';
import './report.scss';

const ProblemDetails = ({
  problemDetails,
  index,
  key,
  problems,
  handleOnExecuteClick,
}) => {
  let testcaseNumbers;
  let testcaseResult;
  let testcaseResultDetails;

  const dispatch = useDispatch();
  const { runCodeModal } = useSelector((state) => state.modalStatusReducer);
  const [showImageModal, setShowImageModal] = useState(false);
  const [problemCode, setProblemCode] = useState('');

  const {
    is_attempted: isAttempted,
    problem_id: problemId,
    total_score: totalScore,
    obtained_score: obtainedScore,
    submission_time: submissionTime,
    image_url: imageUrl,
    test_case_result: testCaseResult,
    submissions_used: submissionUsed,
    total_submissions: totalSubmissions,
    answer,
    language,
    status,
    description,
    percentage,
    title,
    candidate,
  } = problemDetails;

  if (isAttempted) {
    testcaseNumbers = useMemo(() => {
      return testCaseResult.map((val, text_no) => {
        return (
          <td>{text_no + 1}</td>
        );
      });
    }, []);

    testcaseResult = useMemo(() => {
      return testCaseResult.map(({ is_passed: isPassed }) => {
        return (
          <td>{ getPassedValue(isPassed) }</td>
        );
      });
    }, []);

    testcaseResultDetails = useMemo(() => {
      return testCaseResult.map((val, text_no) => {
        const {
          input,
          expected_output: expectedOutput,
          actual_output: actualOutput,
          is_passed: isPassed,
        } = val;
        return (
          <tr>
            <td>{text_no + 1}</td>
            <td>{input}</td>
            <td>{expectedOutput}</td>
            <td>{actualOutput || '-'}</td>
            <td>{getPassedValue(isPassed)}</td>
          </tr>
        );
      });
    }, []);
  }
  const toggleImageModalVisibility = () => {
    setShowImageModal(!showImageModal);
  };

  return (
    <Container fluid className='px-5 report-card' key={problemId}>
      <Table className='table-bordered  text-white'>
        <thead>
          <tr>
            <th>Problem {index}</th>
            <th>Score</th>
            <th>Percentage</th>
            <th>Submission count</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{title}</td>
            <td>{getScoreOrPercentage(obtainedScore)} / {totalScore}</td>
            <td>{getScoreOrPercentage(percentage)}</td>
            <td>{getSubmissionCount(submissionUsed)} / {totalSubmissions}</td>
          </tr>
        </tbody>
      </Table>

      <div className='problemDescription'>
        <Row className='font-weight-bold'>Description</Row>
        <br />
        <Row>{description}</Row>
        <Row>
          {imageUrl && (
            <div
              className='problem-image-wrapper'
              onClick={toggleImageModalVisibility}
              role='button'
              tabIndex={0}
            >
              <img
                className='problem-image'
                src={imageUrl}
                alt='Upload Question Img'
              />
            </div>
          )}
          <ImagePopUpModal
            showImageModal={showImageModal}
            toggleImageModalVisibility={toggleImageModalVisibility}
            imageUrl={imageUrl}
            alternateName='Question Image'
          />
        </Row>
      </div>
      <br />
      {isAttempted && (
        <>
          <Table className='table-bordered text-white'>
            <tbody>
              <tr>
                <td>Test cases</td>
                {testcaseNumbers}
              </tr>
              <tr>
                <td>Problem. {index}</td>
                {testcaseResult}
              </tr>
            </tbody>
          </Table>
          <br />

          <Row>
            <Col className='col-3'><b>Submission time</b></Col>
            <Col>: {submissionTime || '-'}</Col>
          </Row>

          <Row>
            <Col className='col-3'><b>Result</b></Col>
            <Col>: {status || '-'}</Col>
          </Row>

          <Row>
            <Col className='col-3'><b>Programming language used</b></Col>
            <Col>: {language}</Col>
          </Row>
          <br />
          <Row className='py-1 px-3'>
            <h5><b>Test Cases:</b></h5>
          </Row>
          <Table className='table-bordered tableStyle text-white'>
            <thead>
              <tr>
                <th className='srNo'>Sr.No.</th>
                <th>Input Supplied</th>
                <th>Expected Output</th>
                <th>Program Output</th>
                <th className='result'>Result</th>
              </tr>
            </thead>
            <tbody>{testcaseResultDetails}</tbody>
          </Table>
          <div className='problemDescription text-white'>
            <Row className='d-flex'>
              <span className='font-weight-bold d-flex align-items-center'>
                Code  {index}
              </span>
              <Button
                onClick={() =>  handleOnExecuteClick(problemDetails)}
                className='btn btn-sm ml-5'
                color='secondary'
                outline
              >
                Execute
              </Button>
            </Row>
            <br />
            <Row key={index}>
              {answer}
            </Row>
          </div>
        </>
      )}
    </Container>
  );
};

ProblemDetails.propTypes = {
  problemDetails: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  key: PropTypes.number.isRequired,
  problems: PropTypes.array.isRequired,
  handleOnExecuteClick: PropTypes.func.isRequired,
};

export default React.memo(ProblemDetails);
