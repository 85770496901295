import React from 'react';
import PropTypes from 'prop-types';

import { SiteLogo } from 'shared-components';
import ForgotPasswordCard from 'modules/admin/forgotPassword/ForgotPasswordComponent/forgotPasswordCard/forgotPasswordCard';
import './forgotPasswordLayout.scss';

const ForgotPasswordComponent = (props) => {
  const {
    handleEmailChange,
    handleSubmit,
    emailError,
    email,
    isError,
    isLoading,
    errorMessage,
    successMessage,
  } = props;

  return (
    <section className='login-section'>
      <div className='login-wrapper position-relative h-100'>
        <div className='spark-code-header py-4'>
          <div className='container'>
            <SiteLogo />
          </div>
        </div>
        <ForgotPasswordCard
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          handleEmailChange={handleEmailChange}
          email={email}
          emailError={emailError}
          errorMessage={errorMessage}
          successMessage={successMessage}
          isError={isError}
        />
      </div>
    </section>
  );
};

ForgotPasswordComponent.propTypes = {
  handleEmailChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  emailError: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
};

export default React.memo(ForgotPasswordComponent);
