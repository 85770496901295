import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  adminLoginSuccessAction,
  adminLoginFailureAction,
} from 'redux/admin/login/action';
import {
  setIsLogout,
} from 'redux/admin/modalStates/action';
import {
  adminLoginPostApi,
  adminLogoutApi,
} from 'redux/admin/login/api';
import {
  ADMIN_LOGIN,
} from 'redux/admin/login/actionConstants';
import local from 'utils/local';
import { setIsOpen, setProfileModal } from '../modalStates/action';

const {
  ADMIN_REQUEST_ACTION,
  ADMIN_LOGOUT_REQUEST_ACTION,
} = ADMIN_LOGIN;

export function* adminLoginSaga(action) {
  try {
    const response = yield call(adminLoginPostApi, action.payload);
    yield put(adminLoginSuccessAction(response.data.data));
    const isFirstLogin = response.data.data.is_first_time_login || false;
    yield put(setIsOpen(isFirstLogin));
    local.setItem('client', response.headers.client);
    local.setItem('expiry', response.headers.expiry);
    local.setItem('uid', response.headers.uid);
    local.setItem('token-type', response.headers['token-type']);
    local.setItem('accessToken', response.headers['access-token']);
  } catch (error) {
    // join array elements with new line
    yield put(adminLoginFailureAction(error.response.data.errors.join('\r\n')));
  }
}

export function* adminLogoutSaga() {
  try {
    yield call(adminLogoutApi);
    yield put(setIsLogout(true));
  } catch (error) {
    toast.error(error.response.data.errors[0]);
  }
}

export default function* adminSaga() {
  yield takeLatest(ADMIN_REQUEST_ACTION, adminLoginSaga);
  yield takeLatest(ADMIN_LOGOUT_REQUEST_ACTION, adminLogoutSaga);
}
