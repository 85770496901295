import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BsImages } from 'react-icons/bs';

import { Icon } from 'core-components';
import ImagePopUpModal from 'shared-components/ImagePopUpModal';

import './iconImagePopUpModal.scss';

const IconImagePopUpModal = ({ imageUrlLink, index }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleImageModalVisibility = () => {
    setShowModal(!showModal);
  };
  return (
    <>
      <Icon className='icon' onClick={toggleImageModalVisibility}>
        <BsImages />
      </Icon>
      <ImagePopUpModal
        key={index}
        showImageModal={showModal}
        toggleImageModalVisibility={toggleImageModalVisibility}
        imageUrl={imageUrlLink}
        alternateName='MCQ Image'
      />
    </>
  );
};

IconImagePopUpModal.propTypes = {
  imageUrlLink: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default IconImagePopUpModal;
