import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import UserProfileComponent from 'modules/admin/userProfile/UserProfileComponent';

import { setIsLogout, setProfileModal } from 'redux/admin/modalStates/action';
import { adminLoginResetAction, adminLogoutRequestAction } from 'redux/admin/login/action';
import { remainingCreditsRequest } from 'redux/admin/remainingCredits/action';
import { getUserDetails } from 'utils';

const UserProfileContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const profileDetails = getUserDetails();
  const { credits } = useSelector((state) => state.remainingCreditsReducer);
  const { profileModal, isLogout } = useSelector((state) => state.modalStatusReducer);
  const [changePassVisible, setChangePassVisible] = useState(false);
  const [editProfileVisible, setEditProfileVisible] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const toggleConfirmation = () => {
    setConfirmationModal(!confirmationModal);
  };
  const toggle = () => {
    dispatch(setProfileModal(false));
  };
  useEffect(() => {
    if (profileModal) dispatch(remainingCreditsRequest());
  }, [profileModal]);

  useEffect(() => {
    if (isLogout) {
      localStorage.clear();
      dispatch(setProfileModal(false));
      dispatch(adminLoginResetAction());
      dispatch(setIsLogout(false));
      history.push('/login');
    }
  }, [isLogout]);

  const handleLogout = () => {
    dispatch(adminLogoutRequestAction());
  };

  return (
    <UserProfileComponent
      profileDetails={profileDetails}
      changePassVisible={changePassVisible}
      setChangePassVisible={setChangePassVisible}
      editProfileVisible={editProfileVisible}
      setEditProfileVisible={setEditProfileVisible}
      toggle={toggle}
      showModal={profileModal}
      handleLogout={handleLogout}
      toggleConfirmation={toggleConfirmation}
      confirmationModal={confirmationModal}
      remainingCredits={credits}
    />
  );
};

export default React.memo(UserProfileContainer);
