import { call, takeLatest, put } from 'redux-saga/effects';

import { LANGUAGE } from 'constants/actionConstants';
import fetchLanguages from 'apis/languagesApis';
import { showErrorMessage } from 'actions/userDriveActions';
import { setLanguages } from 'actions/languageAction';

// worker saga
export function* languageDetails(action) {
  try {
    const response = yield call(fetchLanguages);
    let selectedLanguageIndex = 0;

    if (action.payload) {
      for (let i = 0; i < response.data.length; i += 1) {
        if (response.data[i].id === action.payload) {
          selectedLanguageIndex = i;
          break;
        }
      }
    }

    const languageInformation = {
      languages: response.data,
      selectedLanguageIndex,
    };

    yield put(setLanguages(languageInformation));
  } catch (err) {
    yield put(showErrorMessage(err.message));
  }
}

// watcher saga
export default function* languageSagas() {
  yield takeLatest(LANGUAGE.FETCH_ACTION, languageDetails);
}
