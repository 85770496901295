import session from './session';

const updateSessionObject = (problemLanguages, problemId, languageId) => {
  // storing/updating current problemId and languageId in problemLanguages object
  problemLanguages[problemId] = languageId;
  // storing back problemLanguages object in session storage
  session.setItem('problemLanguages', problemLanguages);
};

export default updateSessionObject;
