import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'utils/capitalize';

import {
  FormGroup,
  FormFeedback,
  Input,
  Button,
  Spinner,
  Icon,
} from 'core-components';
import { DeleteConfirmModal } from 'shared-components';

import './rule.css';
import './defaultRulesComponent.scss';

const DefaultRulesComponent = ({
  handleRuleChange,
  handleOnRuleAdd,
  handleOnRuleEdit,
  handleOnRuleDelete,
  handleOnRuleUpdate,
  handleOnCancel,
  isLoading,
  ruleState,
  confirmationModal,
  toggleConfirmation,
  targetId,
  ruleDescription,
  handleDeleteClick,
}) => {
  const { rules, totalRules, ruleErr: ruleErrTxt, isRuleEdit, rule } = ruleState;
  const scrollDiv = createRef();

  const scrollSmoothHandler = () => {
    scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='rules-section d-flex flex-column'>
      <DeleteConfirmModal
        confirmationModal={confirmationModal}
        toggleConfirmation={toggleConfirmation}
        handleDelete={handleOnRuleDelete}
        targetId={targetId}
        type='rule'
        name={ruleDescription}
      />
      <div className='rules-section-head d-flex align-items-center justify-content-between px-3'>
        <h2 className='section-heading font-weight-bold mb-0'>Rules ({totalRules})</h2>
      </div>
      <div className='rules-list-wrap scroll-y'>
        <div
          className='edit-rule-box p-4 mb-4 d-flex align-items-center'
          ref={scrollDiv}
        >
          <FormGroup className='mb-0 d-flex flex-column'>
            <Input
              type='text'
              invalid={ruleErrTxt !== ''}
              value={rule}
              onChange={handleRuleChange}
              placeholder='Type New Rule Here'
            />{' '}
            <FormFeedback>{capitalize(ruleErrTxt)}</FormFeedback>
          </FormGroup>

          <Button
            onClick={handleOnCancel}
            color='link'
            className='p-0 mr-2 text-decoration-none'
          >
            <Icon name='close' />
          </Button>
          {isRuleEdit ? (
            <Button onClick={handleOnRuleUpdate} color='primary'>
              {isLoading ? (
                <Spinner size='sm' color='light' />
              ) : (
                <>Update</>
              )}
            </Button>
          ) : (
            <Button onClick={handleOnRuleAdd} color='primary'>
              {isLoading ? (
                <Spinner size='sm' color='light' />
              ) : (
                <>Add Rule</>
              )}
            </Button>
          )}
        </div>
        <ul className='rules-list list-unstyled pl-0 scroll-y'>
          {rules.map(({ id, description }, key) => {
            return (
              <li className='rules-list-item d-flex p-3' key={id}>
                <span className='sequence-no mr-5'>{key + 1}.</span>
                <p className='mb-0 mr-5'>{description} </p>
                <div className='rules-action-box ml-auto d-flex align-items-center'>
                  <Icon
                    name='pen'
                    title='Edit'
                    className='edit-icon'
                    onClick={() => {
                      scrollSmoothHandler();
                      handleOnRuleEdit(id);
                    }}
                  />
                  <Icon
                    name='delete'
                    title='Delete'
                    onClick={() => {
                      toggleConfirmation();
                      handleDeleteClick(id, description);
                    }}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

DefaultRulesComponent.defaultProps = {
  targetId: 0,
};

DefaultRulesComponent.propTypes = {
  handleRuleChange: PropTypes.func.isRequired,
  handleOnRuleAdd: PropTypes.func.isRequired,
  handleOnRuleEdit: PropTypes.func.isRequired,
  handleOnRuleDelete: PropTypes.func.isRequired,
  handleOnRuleUpdate: PropTypes.func.isRequired,
  handleOnCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  targetId: PropTypes.number,
  ruleDescription: PropTypes.string.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  ruleState: PropTypes.shape().isRequired,

};

export default React.memo(DefaultRulesComponent);
