export const validateData = (schema, postData, setCreateAssessment) => {
  schema.validate(postData, { abortEarly: false }).catch((errors) => {
    errors.inner.forEach((error) => {
      if (error.path === 'name') {
        setCreateAssessment({
          type: 'nameErrTxt',
          payload: error.message,
        });
      }
      if (error.path === 'description') {
        setCreateAssessment({
          type: 'descriptionErrTxt',
          payload: error.message,
        });
      } if (error.path === 'problem_count') {
        setCreateAssessment({
          type: 'problemCountErrTxt',
          payload: error.message,
        });
      } if (error.path === 'contacts') {
        setCreateAssessment({
          type: 'contactsErrTxt',
          payload: error.message,
        });
      } if (error.path === 'easy_problem_count') {
        setCreateAssessment({
          type: 'easyProblemErrTxt',
          payload: error.message,
        });
      } if (error.path === 'medium_problem_count') {
        setCreateAssessment({
          type: 'mediumProblemErrTxt',
          payload: error.message,
        });
      } if (error.path === 'hard_problem_count') {
        setCreateAssessment({
          type: 'hardProblemErrTxt',
          payload: error.message,
        });
      } if (error.path === 'easy_mcq_count') {
        setCreateAssessment({
          type: 'easyMcqErrTxt',
          payload: error.message,
        });
      } if (error.path === 'medium_mcq_count') {
        setCreateAssessment({
          type: 'mediumMcqErrTxt',
          payload: error.message,
        });
      } if (error.path === 'hard_mcq_count') {
        setCreateAssessment({
          type: 'hardMcqErrTxt',
          payload: error.message,
        });
      }
    });
  });
};
