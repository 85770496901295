/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import EditorPadComponent from 'components/EditorPadComponent';

import {
  options,
} from 'components/EditorPadComponent/editorConstants';
import isEmpty from 'utils/isEmpty';

import './template.scss';

import {
  FormGroup,
  Button,
  Spinner,
  Icon,
  DropdownItem,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Table,
} from 'core-components';

import { CODING_TAB } from 'constants/appConstants';

const TemplateComponent = ({
  handleTemplateChange,
  handleOnTemplateAdd,
  handleOnTemplateEdit,
  handleOnTemplateDelete,
  handleOnTemplateUpdate,
  template,
  isTemplateEdit,
  isLoading,
  templates,
  handleAddMoreClick,
  languages,
  handleLanguageClick,
  languageSelected,
  setActiveTabMethod,
  showTemplateHeader,
  templateError,
  scrollTemplateRef,
  isAddTemplate,
  isEditTemplate,
}) => {
  const [isDropDownOpen, setDropDownOpen] = useState(false);

  const handleToggle = useCallback(
    () => setDropDownOpen(!isDropDownOpen),
    [isDropDownOpen],
  );

  let lang = useMemo(() => {
    if (isEmpty(languageSelected)) {
      return '';
    }
    return languageSelected.name.split(' ')[0].toLowerCase();
  });

  if (lang === 'c++') {
    lang = 'cpp';
  }

  const editorDidMount = useCallback((editor) => {
    editor.onKeyDown((event) => {
      const { keyCode, ctrlKey, metaKey } = event;
      // Enable copy paste for first live drive
      if ((metaKey || ctrlKey) && keyCode === 52) {
        event.preventDefault();
      }
    });
    editor.focus();
  }, []);

  const scrollSmoothHandler = () => {
    scrollTemplateRef.current.scrollIntoView();
  };

  return (
    <div id='mytestDiv' className='px-3 d-flex flex-column flex-1'>
      {showTemplateHeader && (
        <div className='d-flex align-items-center justify-content-between'>
          <div
            className='scrollClass d-flex align-items-center'
            ref={showTemplateHeader ? scrollTemplateRef : {}}
          >
            <Icon
              name='left'
              className='back-page-arrow font-weight-bold'
              onClick={() => setActiveTabMethod(CODING_TAB)}
            />
            <h2 className='section-heading font-weight-bold mb-0 ml-3'>
              Default Templates
            </h2>
          </div>
          <Button
            color='primary'
            onClick={handleAddMoreClick}
          >
            Add Template
          </Button>
        </div>
      )}
      <div
        className='templates-detail-form-group d-flex flex-column flex-1'
        ref={!showTemplateHeader ? scrollTemplateRef : {}}
      >
        {templateError && (
        <div className='text-danger mb-1 font-weight-bold'>{templateError}</div>
        )}
        {isAddTemplate || isEditTemplate ? (
          <FormGroup className='py-3 mb-0 d-flex flex-column flex-1'>
            {isAddTemplate && (
              <Dropdown
                isOpen={isDropDownOpen}
                toggle={handleToggle}
                className='technology-dropdown mt-3'
              >
                <DropdownToggle
                  className='dropdown-toggle border-0 text-decoration-none p-0 d-flex align-items-center justify-content-center'
                  color='link'
                >
                  <Icon name={languageSelected.id} className='mr-1 technology-icon' />
                  <span className='text-truncate'>{languageSelected.name}</span>
                  <Icon name='arrow-down' />
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu m-0 p-0' right>
                  {languages.map(({ id, name, code }) => (
                    <DropdownItem
                      id={id}
                      code={code}
                      key={id}
                      onClick={handleLanguageClick}
                      className='p-2'
                    >
                      <Icon name={id} className='mr-2 technology-icon' />
                      {name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            )}
            {isEditTemplate && (
              <span className='span-style mt-2 text-truncate'>{languageSelected.name}</span>
            )}
            <EditorPadComponent
              id='editor'
              lang={lang}
              code={template}
              handleCode={handleTemplateChange}
              options={options}
              editorDidMount={editorDidMount}
              height='240px'
            />
            {
              isEditTemplate ?
                (
                  <Button
                    onClick={handleOnTemplateUpdate}
                    className='text-uppercase ml-auto d-flex mt-3 px-2 py-1 text-decoration-none'
                  >
                    {isLoading ? (
                      <Spinner size='sm' color='light' />
                    ) : (
                      <>Update</>
                    )}
                  </Button>
                )
                :
                (
                  <Button
                    className='text-uppercase ml-auto d-flex mt-3 px-2 py-1 text-decoration-none'
                    onClick={handleOnTemplateAdd}
                  >
                    save
                  </Button>
                )
            }
          </FormGroup>
        ) : null}
      </div>
      <div className='table-responsive mb-5'>
        <Table className='sp-table test-cases-table'>
          <thead>
            <tr>
              <th>Language</th>
              <th>Code</th>
            </tr>
          </thead>
          <tbody>
            {templates.map((templateIterator) => {
              return (
                <tr>
                  <td>
                    {templateIterator.language}
                  </td>
                  <td>
                    <div className='problemDescription text-white w-100'>
                      {templateIterator.code}
                    </div>
                  </td>
                  <td>
                    <Icon
                      name='pen'
                      title='Edit'
                      className='edit-icon'
                      onClick={() => {
                        scrollSmoothHandler();
                        handleOnTemplateEdit(templateIterator.id);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

TemplateComponent.defaultProps = {
  templateError: '',
};

TemplateComponent.propTypes = {
  handleTemplateChange: PropTypes.func.isRequired,
  handleOnTemplateAdd: PropTypes.func.isRequired,
  handleOnTemplateEdit: PropTypes.func.isRequired,
  handleOnTemplateDelete: PropTypes.func.isRequired,
  handleOnTemplateUpdate: PropTypes.func.isRequired,
  template: PropTypes.string.isRequired,
  templates: PropTypes.string.isRequired,
  isTemplateEdit: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleAddMoreClick: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  languageSelected: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
  }).isRequired,
  handleLanguageClick: PropTypes.func.isRequired,
  setActiveTabMethod: PropTypes.func.isRequired,
  showTemplateHeader: PropTypes.bool.isRequired,
  templateError: PropTypes.bool,
  scrollTemplateRef: PropTypes.object.isRequired,
  isEditTemplate: PropTypes.bool.isRequired,
  isAddTemplate: PropTypes.bool.isRequired,
};

export default React.memo(TemplateComponent);
