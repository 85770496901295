import * as yup from 'yup';

export const schema = yup.object().shape({
  problemTitle: yup
    .string()
    .nullable()
    .trim()
    .min(5, 'Problem Title must be at least 5 characters')
    .max(70, 'Problem Title must be at most 70 characters')
    .required('Problem Title is a required field'),

  submissionCount: yup
    .number()
    .nullable()
    .min(1, 'Submission Count must be greater than or equal to 1')
    .max(999, 'Submission Count must be less than or equal to 999')
    .required('Submission Count is required field'),

  timeInMinute: yup
    .number()
    .required('Time is a required field')
    .min(1, 'Time must be greater than or equal to 1')
    .nullable()
    .max(999, 'Time must be less than or equal to 999'),

  problemDescription: yup
    .string()
    .trim()
    .min(10, 'Problem Description must be at least 10 characters')
    .max(600, 'Problem Description must be at most 600 characters')
    .required('Problem Description is a required field')
    .nullable(),

  difficulty: yup
    .string()
    .nullable()
    .required(),

  tags: yup
    .array()
    .min(1)
    .required('Problem Tags is a Required Field'),

  testCasesList: yup.array().of(
    yup.object().shape({
      input: yup
        .string()
        .trim()
        .required('Input is a required field'),
      output: yup
        .string()
        .trim()
        .required('Output is a required field'),
      marks: yup
        .number()
        .required('Marks is required field')
        .min(1, 'Marks must be greater than 0')
        .max(999, 'Marks must be less than or equal to 999'),
    }).required(),
  ),
});
