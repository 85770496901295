const getCurrentTime = (remainingTime) => {
  let time;

  if (typeof remainingTime === 'string') {
    /*
      We are getting two type of values in remainingTime i.e. string and float.
      if it is string then assign its value to time and return it directly.
    */
    time = remainingTime;
    return time;
  } if (remainingTime === -1) {
    time = 'Expired';
    return time;
  }

  /*
    get remainingTimer as a float value from the container
    splitting remaining time in seconds to time in "hours:minutes:seconds" format
    */
  let seconds = parseInt(remainingTime, 10);
  const days = Math.floor(seconds / (3600 * 24)).toLocaleString('en-US', { minimumIntegerDigits: 2 });
  seconds -= days * 3600 * 24;
  const hours = Math.floor(seconds / 3600).toLocaleString('en-US', { minimumIntegerDigits: 2 });
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60).toLocaleString('en-US', { minimumIntegerDigits: 2 });
  seconds -= minutes * 60;
  seconds = Math.floor(seconds).toLocaleString('en-US', { minimumIntegerDigits: 2 });
  // if seconds < 0 timer gets Expired
  time = `${days >= 1 ? `${days}Day ${hours}:${minutes}:${seconds}` : `${hours}:${minutes}:${seconds}`}`;

  if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
    time = 'Expired';
  }
  return time;
};

const ifSufficientTime = (remainingTime) => {
  const hours = Math.floor((remainingTime / (60 * 60)) % 60);
  const minutes = Math.floor((remainingTime / (60)) % 60);
  if (hours === 0 && minutes < 10) {
    return false;
  }
  return true;
};

const getRemainingTime = (e) => {
  const total = Date.parse(e) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / 1000 / 60 / 60) % 24);
  return {
    total,
    hours,
    minutes,
    seconds,
  };
};

const pad = (num) => {
  return (`0${num}`).slice(-2);
};

const getRemainingTimeInFormat = (hr, min, sec) => {
  return `${pad(hr)} hr: ${pad(min)} min: ${pad(sec)} secs Remain`;
};

export { ifSufficientTime };
export { getCurrentTime };
export { getRemainingTime };
export { getRemainingTimeInFormat };
