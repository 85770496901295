import produce from 'immer';

import { VERIFY_FORGOT_PASSWORD } from 'redux/admin/verifyForgotPassword/actionConstants';

export const initialState = {
  errorMessage: null,
  successMessage: null,
  isError: false,
  isLoading: false,
};

const verifyPasswordReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case VERIFY_FORGOT_PASSWORD.VERIFY_FORGOT_PASSWORD_SUCCESS_ACTION:
      return {
        ...state,
        successMessage: 'Your Password has been changed please login again',
        isError: false,
        isLoading: false,
        errorMessage: false,
      };
    case VERIFY_FORGOT_PASSWORD.VERIFY_FORGOT_PASSWORD_FAILURE_ACTION:
      return {
        ...state,
        successMessage: '',
        isError: true,
        isLoading: false,
        errorMessage: payload.message,
      };
    case VERIFY_FORGOT_PASSWORD.VERIFY_FORGOT_PASSWORD_REQUEST_ACTION:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
});

export default verifyPasswordReducer;
