/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-alert */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Table,
} from 'core-components';
import { DeleteConfirmModal } from 'shared-components';

const TestCaseListComponent = ({
  testCases,
  handleOnTestCaseEdit,
  handleOnTestCaseDelete,
  confirmationModal,
  toggleConfirmation,
  targetId,
  handleDeleteClick,
  scrollRef,
  isTestCaseCreate,
}) => {
  const scrollSmoothHandler = () => {
    scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='table-responsive'>
      <DeleteConfirmModal
        confirmationModal={confirmationModal}
        toggleConfirmation={toggleConfirmation}
        handleDelete={handleOnTestCaseDelete}
        targetId={targetId}
      />
      <Table className='sp-table test-cases-table px-2 mb-0'>
        <thead>
          <tr>
            <th>#</th>
            <th>Input</th>
            <th>Output</th>
            <th>Marks</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {testCases.map((testCase, key) => {
            return (
              !testCase.input ? <></> : (
                <tr>
                  <td> {key + 1} </td>
                  <td className='input'> {testCase.input} </td>
                  <td className='output'> {testCase.output} </td>
                  <td> {testCase.marks} </td>
                  <td>
                    <Button
                      onClick={() => {
                        handleOnTestCaseEdit(testCase.id ? testCase.id : key);
                        scrollSmoothHandler();
                      }}
                      className='btn btn-sm btn-primary'
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => {
                        toggleConfirmation();
                        handleDeleteClick(key);
                      }}
                      className='btn btn-sm btn-danger button_margin'
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              )
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
TestCaseListComponent.defaultProps = {
  targetId: 0,
  isTestCaseCreate: false,
};
TestCaseListComponent.propTypes = {
  testCases: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleOnTestCaseEdit: PropTypes.func.isRequired,
  handleOnTestCaseDelete: PropTypes.func.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  targetId: PropTypes.number,
  handleDeleteClick: PropTypes.func.isRequired,
  scrollRef: PropTypes.object.isRequired,
  isTestCaseCreate: PropTypes.bool,
};

export default React.memo(TestCaseListComponent);
