import { SERVER_URL } from 'constants/appConstants';
import { get, destroy } from '../apiHelper';

export const getFeedbacksGetApi = (queryParams) => {
  return get(`${SERVER_URL}admin/feedbacks?${queryParams}`);
};

export const destroyFeedbacksDeleteApi = (id) => {
  return destroy(`${SERVER_URL}admin/feedbacks/${id}`);
};
