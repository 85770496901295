import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import superAdminLayout from 'HOC/superAdminLayout';

function ProtectedSuperAdminRoute({ component: Component, restricted, ...rest }) {
  const { accessToken } = useSelector((state) => state.adminLoginReducer);
  const WrapperComponent = superAdminLayout(Component, rest.includeSidebar);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (accessToken) {
          return <WrapperComponent {...props} />;
        }
        return () => {
          return <Redirect to='/login' />;
        };
      }}
    />
  );
}

ProtectedSuperAdminRoute.defaultProps = {
  restricted: '',
};

ProtectedSuperAdminRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  restricted: PropTypes.string,
};

export default ProtectedSuperAdminRoute;
