import { assessmentGet, assessmentDelete } from 'redux/admin/apiHelper';
import { SERVER_URL, ASSESSMENT_LIMIT } from 'constants/appConstants';
import queryStringHelper from 'utils/queryStringHelper';

export const AssessmentGetApi = ({ limit = ASSESSMENT_LIMIT, offset, search }) => {
  const quesryString = queryStringHelper({ limit, offset, search });
  return assessmentGet(`${SERVER_URL}admin/assessments/assessments_list?${quesryString}`);
};

export const assessmentDeleteApi = ({ id }) => {
  return assessmentDelete(`${SERVER_URL}admin/assessments/${id}`);
};
