/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import ProblemsFilterContainer from 'modules/admin/problemsList/ProblemsListContainer/ProblemsFilterContainer';
import Icon from 'core-components/Icon';
import { Button } from 'core-components';
import {
  FilterComponent,
  SearchBox,
} from 'shared-components';
import ProblemBulkUploadContainer from '../ProblemsListContainer/ProblemBulkUploadContainer';

import './problemListComponent.scss';

const ProblemHeaderComponent = ({
  handleAddProblemClick,
  handleProblemSearchClick,
  handleProblemSearchClearClick,
  totalProblems,
  onClickAddFilter,
  showFilter,
  onProblemSearchChange,
  problemSearchText,
  setActiveTabMethod,
  searchedProblemText,
}) => {
  return (
    <>
      <div
        className={`problem-bank-section-head d-flex align-items-center ${showFilter ? 'mb-0' : 'mb-2'}`}
      >
        <div className='d-flex align-items-center gap-2'>
          <SearchBox
            placeholder='Search Problems'
            handleOnChange={onProblemSearchChange}
            handleSearchClick={handleProblemSearchClick}
            handleCloseClick={handleProblemSearchClearClick}
            value={problemSearchText}
          />
          <FilterComponent onClickAddFilter={onClickAddFilter} count={totalProblems} text='Problems' />
          <Button
            color='primary'
            className='btn-sm'
            onClick={handleAddProblemClick}
            title='Add Problem'
          >
            <Icon
              id='plus'
              name='plus'
              title='Add Problem'
            />
          </Button>
          <ProblemBulkUploadContainer />
          <Button
            onClick={() => setActiveTabMethod('template')}
            size='sm'
          >
            Default Templates
          </Button>
        </div>
      </div>
      {showFilter && (
        <ProblemsFilterContainer
          onClickAddFilter={onClickAddFilter}
          searchedProblemText={searchedProblemText}
        />
      )}
    </>
  );
};

ProblemHeaderComponent.propTypes = {
  handleAddProblemClick: PropTypes.func.isRequired,
  handleProblemSearchClick: PropTypes.func.isRequired,
  handleProblemSearchClearClick: PropTypes.func.isRequired,
  totalProblems: PropTypes.number.isRequired,
  onClickAddFilter: PropTypes.func.isRequired,
  showFilter: PropTypes.bool.isRequired,
  onProblemSearchChange: PropTypes.func.isRequired,
  problemSearchText: PropTypes.string.isRequired,
  setActiveTabMethod: PropTypes.func.isRequired,
  searchedProblemText: PropTypes.string.isRequired,
};

export default React.memo(ProblemHeaderComponent);
