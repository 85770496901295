import PropTypes from 'prop-types';
import React from 'react';

import { CardComponent, DriveTile, DeleteConfirmModal } from 'shared-components';
import Loading from 'shared-components/Loading';

import './adminHomeComponent.scss';

const AdminHomeComponent = (props) => {
  const {
    onClickCandidates,
    onClickResult,
    onClickEdit,
    ongoingDrives,
    totalOngoingDrives,
    ongoingDrivesLoading,
    upcomingDrives,
    completedDrives,
    upcomingDrivesLoading,
    completedDrivesLoading,
    totalUpcomingDrives,
    totalCompletedDrives,
    completedDriveRef,
    ongoingDriveRef,
    upcomingDriveRef,
    ongoingDrivesFailureMessage,
    upcomingDriveFailureMessage,
    completedDriveFailureMessage,
    toggleConfirmation,
    targetId,
    driveName,
    handleDeleteClick,
    confirmationModal,
    onClickDelete,
  } = props;

  return (
    <div className='dashboard-section d-flex scroll-x'>
      <DeleteConfirmModal
        confirmationModal={confirmationModal}
        toggleConfirmation={toggleConfirmation}
        handleDelete={onClickDelete}
        targetId={targetId}
        name={driveName}
        type='drive'
      />
      <div className='sp-col d-flex flex-column scroll-y'>
        <div className='d-flex align-items-center justify-content-between sp-col-header px-4 my-4'>
          <h3 className='font-weight-bold'>Ongoing Drives</h3>
          <span className='py-1 px-3 font-weight-bold'>
            {totalOngoingDrives}
          </span>
        </div>
        <div className='sp-col-body scroll-y'>
          <div className='sp-card-list px-4'>
            {!ongoingDrivesLoading && ongoingDrives?.length === 0 ? 'No Ongoing Drives Scheduled' :
              ongoingDrives.map((e) => {
                return (
                  <div ref={ongoingDriveRef} className='mb-4' key={e.id}>
                    <CardComponent>
                      <DriveTile
                        driveDetails={e}
                        onClickCandidates={onClickCandidates}
                        onClickResult={onClickResult}
                        onClickEdit={onClickEdit}
                        confirmationModal={confirmationModal}
                        toggleConfirmation={toggleConfirmation}
                        handleDeleteClick={handleDeleteClick}
                        type='ongoingDrive'
                        isDrive
                      />
                    </CardComponent>
                  </div>
                );
              })}
            {ongoingDrivesLoading && <Loading />}
            {ongoingDrivesFailureMessage}
          </div>
        </div>
      </div>
      <div className='sp-col d-flex flex-column scroll-y'>
        <div className='d-flex align-items-center justify-content-between sp-col-header px-4 my-4'>
          <h3 className='font-weight-bold -upcoming'>Upcoming Drives</h3>
          <span className='py-1 px-3 font-weight-bold'>
            {totalUpcomingDrives}
          </span>
        </div>
        <div className='sp-col-body scroll-y'>
          <div className='sp-card-list px-4'>
            {!upcomingDrivesLoading && upcomingDrives?.length === 0 ? 'No Upcoming Drives Scheduled' :
              upcomingDrives?.map((e) => {
                return (
                  <div ref={upcomingDriveRef} className='mb-4' key={e.id}>
                    <CardComponent>
                      <DriveTile
                        onClickCandidates={onClickCandidates}
                        onClickEdit={onClickEdit}
                        onClickResult={onClickResult}
                        driveDetails={e}
                        confirmationModal={confirmationModal}
                        toggleConfirmation={toggleConfirmation}
                        handleDeleteClick={handleDeleteClick}
                        type='upcomingDrive'
                        isDrive
                      />
                    </CardComponent>
                  </div>
                );
              })}
            {upcomingDrivesLoading && <Loading />}
            {upcomingDriveFailureMessage}
          </div>
        </div>
      </div>
      <div className='sp-col d-flex flex-column scroll-y'>
        <div className='d-flex px-4 align-items-center justify-content-between sp-col-header px-4 my-4'>
          <h3 className='font-weight-bold -completed'>Completed Drives</h3>
          <span className='py-1 px-3 font-weight-bold'>
            {totalCompletedDrives}
          </span>
        </div>
        <div className='sp-col-body scroll-y'>
          <div className='sp-card-list px-4'>
            {!completedDrivesLoading && completedDrives?.length === 0 ? 'No Completed Drives' :
              completedDrives?.map((e) => {
                return (
                  <div ref={completedDriveRef} className='mb-4' key={e.id}>
                    <CardComponent>
                      <DriveTile
                        driveDetails={e}
                        onClickCandidates={onClickCandidates}
                        onClickResult={onClickResult}
                        confirmationModal={confirmationModal}
                        toggleConfirmation={toggleConfirmation}
                        handleDeleteClick={handleDeleteClick}
                        type='completedDrive'
                        isDrive
                      />
                    </CardComponent>
                  </div>
                );
              })}
            {completedDrivesLoading && <Loading />}
            {completedDriveFailureMessage}
          </div>
        </div>
      </div>
    </div>
  );
};

AdminHomeComponent.defaultProps = {
  targetId: 0,
};

AdminHomeComponent.propTypes = {
  onClickCandidates: PropTypes.func.isRequired,
  onClickResult: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  ongoingDrives: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  totalOngoingDrives: PropTypes.number.isRequired,
  ongoingDrivesLoading: PropTypes.bool.isRequired,
  upcomingDrives: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  completedDrives: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  upcomingDrivesLoading: PropTypes.bool.isRequired,
  completedDrivesLoading: PropTypes.bool.isRequired,
  totalUpcomingDrives: PropTypes.number.isRequired,
  totalCompletedDrives: PropTypes.number.isRequired,
  completedDriveRef: PropTypes.func.isRequired,
  ongoingDriveRef: PropTypes.func.isRequired,
  upcomingDriveRef: PropTypes.func.isRequired,
  ongoingDrivesFailureMessage: PropTypes.string.isRequired,
  upcomingDriveFailureMessage: PropTypes.string.isRequired,
  completedDriveFailureMessage: PropTypes.string.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  targetId: PropTypes.number,
  driveName: PropTypes.string.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};
export default React.memo(AdminHomeComponent);
