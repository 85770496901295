import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Icon } from 'core-components';
import CustomModal from 'shared-components/CustomModal';

import {
  getEditDriveRequestAction,
  isCopyDriveAction,
} from 'redux/admin/getEditDrive/action';
import { setIsCopyFromSearch } from 'redux/admin/modalStates/action';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';

const DriveDeleteCopyComponent = ({
  driveDetails,
  toggleConfirmation,
  handleDeleteClick,
  type,
  isDrive,
  toggleSearchModal,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { editDriveData, isCopyDrive } = useSelector((state) => state.getEditDriveReducer);
  const [onCopyOpenModal, setOnCopyOpenModal] = useState(false);

  useEffect(() => {
    if (editDriveData && isCopyDrive) {
      history.push(`/admin/drive/${editDriveData?.drive?.id}/edit`);
    }
  }, [editDriveData, isCopyDrive]);

  const toggleModal = () => {
    setOnCopyOpenModal(!onCopyOpenModal);
  };

  const onConfirmClick = useCallback(() => {
    setOnCopyOpenModal(!onCopyOpenModal);
    if (type === 'search') {
      toggleSearchModal();
      dispatch(setIsCopyFromSearch(true));
    } else {
      dispatch(isCopyDriveAction(true));
    }
    dispatch(getEditDriveRequestAction(driveDetails.id));
    history.push(ROUTES.ADMIN + ADMIN_ROUTES.CREATE_DRIVE);
  }, [driveDetails, type]);

  const onDeleteClick = useCallback(() => {
    const { id, name } = driveDetails;
    toggleConfirmation();
    handleDeleteClick(id, name, type);
  }, [driveDetails]);

  const { can_delete: canDelete } = driveDetails;

  return (
    <>
      {canDelete && (
        <Icon
          name='delete'
          title='Delete Drive'
          className='text-secondary ml-1'
          onClick={onDeleteClick}
        />
      )}
      {isDrive && (
        <Icon
          name='copy'
          title='Copy Drive'
          onClick={toggleModal}
          className='text-secondary ml-1'
        />
      )}
      <CustomModal
        handleOnConfirm={onConfirmClick}
        onModalClose={toggleModal}
        actionMessage={`Are you sure you want to copy '${driveDetails.name}' drive?`}
        actionName='Copy'
        confirmationModal={onCopyOpenModal}
      />
    </>
  );
};

DriveDeleteCopyComponent.defaultProps = {
  type: '',
  isDrive: false,
  toggleSearchModal: () => {},
};

DriveDeleteCopyComponent.propTypes = {
  driveDetails: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    name: PropTypes.string,
    can_delete: PropTypes.bool,
  }).isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  isDrive: PropTypes.bool,
  toggleSearchModal: PropTypes.func,
};

export default DriveDeleteCopyComponent;
