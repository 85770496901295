import { ADMIN_LOGIN } from 'redux/admin/login/actionConstants';

const {
  ADMIN_REQUEST_ACTION,
  ADMIN_SUCCESS_ACTION,
  ADMIN_FAILURE_ACTION,
  ADMIN_RESET_ACTION,
  ADMIN_LOGOUT_REQUEST_ACTION,
} = ADMIN_LOGIN;

// request
export const adminLoginRequestAction = (payload) => ({
  type: ADMIN_REQUEST_ACTION,
  payload,
});

// success
export const adminLoginSuccessAction = (payload) => ({
  type: ADMIN_SUCCESS_ACTION,
  payload,
});

// failure
export const adminLoginFailureAction = (payload) => ({
  type: ADMIN_FAILURE_ACTION,
  payload,
});

export const adminLoginResetAction = () => ({
  type: ADMIN_RESET_ACTION,
});

export const adminLogoutRequestAction = () => ({
  type: ADMIN_LOGOUT_REQUEST_ACTION,
});
