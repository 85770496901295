import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { finishTestApi } from 'apis/finishTestApi';
import {
  finishTestSuccessAction,
  finishTestRequestFailed,
} from 'actions/finishTestActions';

import local from 'utils/local';

import { FINISH_TEST } from 'constants/actionConstants';

export function* finishTestWorkerSaga() {
  try {
    const response = yield call(finishTestApi);
    yield put(finishTestSuccessAction(response.data));
    local.setItem('isFinishedTest', true);
  } catch (error) {
    const { message } = error.response.data;

    yield put(finishTestRequestFailed(message));
    toast.error(message);
  }
}

// watcher saga
export default function* finishTestSaga() {
  yield takeLatest(FINISH_TEST.FINISH_TEST_REQUEST, finishTestWorkerSaga);
}
