import { SERVER_URL } from 'constants/appConstants';
import { get, post, put, destroy } from 'redux/admin/apiHelper';

export const postRuleApi = (data) => {
  return post(`${SERVER_URL}admin/rules`, data);
};

export const getRulesApi = (data) => {
  return get(`${SERVER_URL}admin/rules?drive_id=${data.drive_id}`);
};

export const getDefaultRulesApi = () => {
  return get(`${SERVER_URL}admin/default_rules`);
};

export const updateRuleApi = (data) => {
  return put(`${SERVER_URL}admin/rules/${data.id}`, data);
};

export const deleteRuleApi = (data) => {
  return destroy(`${SERVER_URL}admin/rules/${data}`);
};
