import { DRIVE_SEARCH } from 'redux/admin/driveSearch/actionConstants';

const { DRIVE_SEARCH_REQUEST_ACTION, DRIVE_SEARCH_SUCCESS_ACTION, DRIVE_SEARCH_FAILURE_ACTION,
  SET_DRIVE_SEARCH_OFFSET, CLEAR_SEARCH_DRIVES } = DRIVE_SEARCH;

export const driveSearchRequest = (payload) => ({
  type: DRIVE_SEARCH_REQUEST_ACTION,
  payload,
});

export const driveSearchSuccess = (payload) => ({
  type: DRIVE_SEARCH_SUCCESS_ACTION,
  payload,
});

export const driveSearchFailure = (message) => ({
  type: DRIVE_SEARCH_FAILURE_ACTION,
  payload: message,
});

export const clearSearchDrivesAction = () => ({
  type: CLEAR_SEARCH_DRIVES,
});

export const setDriveSearchOffset = (payload) => ({
  type: SET_DRIVE_SEARCH_OFFSET,
  payload,
});
