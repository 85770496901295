/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable indent */
import React from 'react';
import PropTypes, { shape, string } from 'prop-types';
import Select from 'react-select';

import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Icon,
} from 'core-components';

import { capitalize } from 'utils/capitalize';

import { customStyle, customStyleForContacts } from 'constants/stylesConstants';
import { CustomThemeMultiSelect } from 'constants/themesConstants';

import 'modules/admin/createDrive/CreateDriveComponent/createDriveComponent.scss';

const CreateAssessmentComponent = (props) => {
  const {
    handleAssessmentNameChange,
    handleAssessmentDescriptionChange,
    handleEasyProblemCountChange,
    handleMediumProblemCountChange,
    handleHardProblemCountChange,
    handleEasyMcqCountChange,
    handleMediumMcqCountChange,
    handleHardMcqCountChange,
    onCreateAssessmentSubmit,
    isSuccess,
    nameErrTxt,
    descriptionErrTxt,
    problemCountErrTxt,
    finishAssessmentCreation,
    defaultAssessment,
    action,
    contactOptions,
    handleSelectedContactsChange,
    contactsErrTxt,
    selectedContacts,
    isLoading,
    handleSelectedTagsChange,
    defaultTags,
    mcqCountData,
    allowCoding,
    allowMcq,
    isParam,
    handleIsParamChange,
    tagsOptions,
    isEditAssessment,
  } = props;

  const {
    easy_mcq_count: easyMcqCount,
    easy_problem_count: easyProblemCount,
    hard_mcq_count: hardMcqCount,
    hard_problem_count: hardProblemCount,
    medium_mcq_count: mediumMcqCount,
    medium_problem_count: mediumProblemCount,
  } = mcqCountData;

  const {
    data: {
      Assessment: {
        name,
        description,
        easy_problem_count: easyDefaultProblemCount,
        medium_problem_count: mediumDefaultProblemCount,
        hard_problem_count: defaultHardProblemCount,
        easy_mcq_count: defaultEasyMcqCount,
        medium_mcq_count: defaultMediumMcqCout,
        hard_mcq_count: defaultHardMcqCount,
      },
    },
    easyProblemErrTxt,
    mediumProblemErrTxt,
    easyMcqErrTxt,
    hardProblemErrTxt,
    mediumMcqErrTxt,
    hardMcqErrTxt,
  } = defaultAssessment;

  if (isLoading) {
    return (<Spinner className='loader' />);
  }

  return (
    <>
      <div className='d-flex'>
        <div className='new-drive-section d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <Icon
              name='left'
              className='back-page-arrow font-weight-bold'
              onClick={finishAssessmentCreation}
            />
            <h2 className='section-heading font-weight-bold mb-0 ml-3'>
              {action} Assessment
            </h2>
          </div>
          <Form className='new-drive-form d-flex flex-row overflow-hidden w-100'>
            <div className='new-drive-wrapper d-flex overflow-hidden w-50'>
              <div className='new-drive-col scroll-y d-flex flex-column'>
                <div className='px-3'>
                  <FormGroup>
                    <Label
                      className='px-3'
                    >
                      Assessment Title
                    </Label>
                    <Input
                      type='text'
                      placeholder='Enter Assessment Title'
                      onChange={handleAssessmentNameChange}
                      value={name}
                      invalid={nameErrTxt !== ''}
                    />
                    <FormFeedback>{capitalize(nameErrTxt)}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className='px-3'
                    >
                      Assessment Description
                    </Label>
                    <Input
                      type='textarea'
                      placeholder='Enter Assessment Description'
                      onChange={handleAssessmentDescriptionChange}
                      value={description}
                      invalid={descriptionErrTxt !== ''}
                    />
                    <FormFeedback>{capitalize(descriptionErrTxt)}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label
                      className='px-3'
                    >
                      Contacts
                    </Label>
                    <Select
                      id='contact'
                      className='react-select-container text-light'
                      classNamePrefix='react-select'
                      onChange={handleSelectedContactsChange}
                      options={contactOptions}
                      placeholder='Select Contacts to Send in Email'
                      theme={CustomThemeMultiSelect}
                      isMulti
                      styles={isEditAssessment ? customStyleForContacts : customStyle}
                      menuPlacement='auto'
                      value={selectedContacts}
                    />
                    {contactsErrTxt && (
                      <div className='text-danger errFontSize'>
                        {contactsErrTxt}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup
                    className='position-relative'
                  >
                    <Label
                      className='px-3 mb-0 align-middle'
                    >
                      Param Assessment
                    </Label>
                    <Input
                      type='checkbox'
                      name='check'
                      id='exampleCheck'
                      checked={isParam}
                      className='ml-3'
                      onChange={handleIsParamChange}
                    />
                  </FormGroup>
                  <Button
                    color='primary'
                    disabled={isSuccess}
                    onClick={onCreateAssessmentSubmit}
                    className='py-2 px-4 ml-auto'
                  >
                    {action} Assessment
                  </Button>
                </div>
              </div>
            </div>
            <div className='new-drive-wrapper d-flex overflow-hidden flex-column w-50'>
              <div>
                <div className='d-flex align-items-end flex-1'>
                  <Label
                    className='px-3 mt-4 w-25'
                  >
                    Tags (optional)
                  </Label>
                  <div className='flex-1'>
                    <Select
                      className='text-light react-select-container'
                      classNamePrefix='react-select'
                      id='role'
                      value={tagsOptions.id}
                      onChange={handleSelectedTagsChange}
                      options={tagsOptions}
                      placeholder='Select Tags'
                      isMulti
                      theme={CustomThemeMultiSelect}
                      defaultValue={defaultTags}
                      styles={customStyle}
                    />
                  </div>
                </div>
                {allowCoding && (
                  <div className='d-flex mt-5'>
                    <Label
                      className='px-3 mt-4 w-25'
                    >
                      Problem Count
                    </Label>
                    <div className='d-flex flex-1 gap-2'>
                      <div className='flex-column flex-1'>
                        <Label
                          className='px-3'
                        >
                          Easy
                        </Label>
                        <div className='d-flex align-items-center'>
                          <Input
                            type='number'
                            min={0}
                            max={easyProblemCount}
                            placeholder='Easy'
                            onChange={handleEasyProblemCountChange}
                            value={
                              easyDefaultProblemCount
                            }
                          />
                          <div className='w-50'>
                            /{easyProblemCount}
                          </div>
                        </div>
                        <div className='text-danger'>
                          {easyProblemErrTxt}
                        </div>
                      </div>
                      <div className='flex-column flex-1'>
                        <Label
                          className='px-3'
                        >
                          Medium
                        </Label>
                        <div className='d-flex align-items-center'>
                          <Input
                            type='number'
                            min={0}
                            max={mediumProblemCount}
                            placeholder='Medium'
                            onChange={handleMediumProblemCountChange}
                            value={
                              mediumDefaultProblemCount
                            }
                          />
                          <div className='w-50'>
                            /{mediumProblemCount}
                          </div>
                        </div>
                        <div className='text-danger'>
                          {mediumProblemErrTxt}
                        </div>
                      </div>
                      <div className='flex-column flex-1'>
                        <Label
                          className='px-3'
                        >
                          Hard
                        </Label>
                        <div className='d-flex align-items-center'>
                          <Input
                            type='number'
                            min={0}
                            max={hardProblemCount}
                            placeholder='Hard'
                            onChange={handleHardProblemCountChange}
                            value={
                              defaultHardProblemCount
                            }
                          />
                          <div className='w-50'>
                            /{hardProblemCount}
                          </div>
                        </div>
                        <div className='text-danger'>
                          {hardProblemErrTxt}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {allowMcq && (
                  <>
                    <div className='d-flex mt-5'>
                      <Label
                        className='px-3 mt-4 w-25'
                      >
                        MCQ Count
                      </Label>
                      <div className='d-flex flex-1 gap-2'>
                        <div className='d-flex flex-column flex-1'>
                          <Label className='px-3'>Easy</Label>
                          <div className='d-flex align-items-center'>
                            <Input
                              type='number'
                              min={0}
                              max={easyMcqCount}
                              placeholder='Easy'
                              onChange={handleEasyMcqCountChange}
                              value={
                                defaultEasyMcqCount
                              }
                            />
                            <div className='w-50'>
                              /{easyMcqCount}
                            </div>
                          </div>
                          <div className='text-danger'>
                            {easyMcqErrTxt}
                          </div>
                        </div>
                        <div className='d-flex flex-column flex-1'>
                          <Label
                            className='px-3'
                          >
                            Medium
                          </Label>
                          <div className='d-flex align-items-center'>
                            <Input
                              type='number'
                              min={0}
                              max={mediumMcqCount}
                              placeholder='Medium'
                              onChange={handleMediumMcqCountChange}
                              value={
                                defaultMediumMcqCout
                              }
                            />
                            <div className='w-50'>
                              /{mediumMcqCount}
                            </div>
                          </div>
                          <div className='text-danger'>
                            {mediumMcqErrTxt}
                          </div>
                        </div>
                        <div className='d-flex flex-column flex-1'>
                          <Label
                            className='px-3'
                          >
                            Hard
                          </Label>
                          <div className='d-flex align-items-center'>
                            <Input
                              type='number'
                              min={0}
                              max={hardMcqCount}
                              placeholder='Hard'
                              onChange={handleHardMcqCountChange}
                              value={
                                defaultHardMcqCount
                              }
                            />
                            <div className='w-50'>
                              /{hardMcqCount}
                            </div>
                          </div>
                          <div className='text-danger'>
                            {hardMcqErrTxt}
                          </div>
                        </div>
                      </div>
                    </div>
                    {problemCountErrTxt && (
                      <div className='text-danger ml-3 errFontSize'>
                        {problemCountErrTxt}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {isSuccess && finishAssessmentCreation()}
          </Form>
        </div>
      </div>
    </>
  );
};

CreateAssessmentComponent.defaultProps = {
  defaultTags: [],
  isLoading: false,
  selectedContacts: [],
  isEditAssessment: false,
};

CreateAssessmentComponent.propTypes = {
  handleAssessmentNameChange: PropTypes.func.isRequired,
  handleAssessmentDescriptionChange: PropTypes.func.isRequired,
  handleEasyProblemCountChange: PropTypes.func.isRequired,
  handleMediumProblemCountChange: PropTypes.func.isRequired,
  handleHardProblemCountChange: PropTypes.func.isRequired,
  handleEasyMcqCountChange: PropTypes.func.isRequired,
  handleMediumMcqCountChange: PropTypes.func.isRequired,
  handleHardMcqCountChange: PropTypes.func.isRequired,
  onCreateAssessmentSubmit: PropTypes.func.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  nameErrTxt: PropTypes.string.isRequired,
  descriptionErrTxt: PropTypes.string.isRequired,
  problemCountErrTxt: PropTypes.string.isRequired,
  finishAssessmentCreation: PropTypes.func.isRequired,
  defaultAssessment: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ])).isRequired,
  action: PropTypes.string.isRequired,
  contactOptions: PropTypes.arrayOf(shape()).isRequired,
  handleSelectedContactsChange: PropTypes.func.isRequired,
  contactsErrTxt: PropTypes.string.isRequired,
  selectedContacts: PropTypes.arrayOf(shape()),
  isLoading: PropTypes.bool,
  handleSelectedTagsChange: PropTypes.func.isRequired,
  defaultTags: PropTypes.arrayOf(shape()),
  mcqCountData: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  allowCoding: PropTypes.bool.isRequired,
  allowMcq: PropTypes.bool.isRequired,
  isParam: PropTypes.bool.isRequired,
  handleIsParamChange: PropTypes.func.isRequired,
  tagsOptions: PropTypes.arrayOf(string).isRequired,
  isEditAssessment: PropTypes.bool,
};

export default React.memo(CreateAssessmentComponent);
