import React from 'react';
import PropTypes, { shape } from 'prop-types';

import Card from 'core-components/Card';
import './candidateFeedbackComponent.scss';
import { Icon, Input, Button } from 'core-components';

const CandidateFeedbackComponent = ({
  feedbackList,
  rating,
  handleRatingClick,
  handleCommentChange,
  handleSubmit,
}) => {
  return (
    <>
      <div className='h-100 d-flex justify-content-center'>
        <Card className='w-50 p-5 scroll-y card-wrapper text-center'>
          <span className='title-style'> Feedback </span>
          <span className='mt-5 sub-title-style'>Your opinion is important to us. This way we can keep improving our product</span>
          <div className='mt-5'>
            <span>How satisfied are you with SparKode?</span>
            <div className='mt-4 icon-list'>
              {feedbackList.map((item) => {
                return (
                  <Icon
                    key={item.id}
                    name={item.name}
                    className={`icon-wrapper ${(rating === item.id) && 'icon-selector'}`}
                    onClick={(e) => handleRatingClick(item.id)}
                  />
                );
              })}
            </div>
            <div className='mt-5  d-flex flex-column align-items-center'>
              <span>Please leave your comments below:</span>
              <Input
                type='textarea'
                rows={5}
                placeholder='Enter feedback'
                className='mt-5 w-50'
                onChange={handleCommentChange}
              />
              <Button className='mt-5' onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

CandidateFeedbackComponent.propTypes = {
  feedbackList: PropTypes.arrayOf(shape()).isRequired,
  rating: PropTypes.number.isRequired,
  handleRatingClick: PropTypes.func.isRequired,
  handleCommentChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default React.memo(CandidateFeedbackComponent);
