import { SERVER_URL } from 'constants/appConstants';
import { assessmentPut, assessmentGet } from 'redux/admin/apiHelper';

export const editAssessmentPutApi = (data) => {
  const { putData, assessmentId } = data;
  return assessmentPut(`${SERVER_URL}admin/assessments/${assessmentId}`, putData);
};

export const getAssessmentApi = (assessmentId) => {
  return assessmentGet(`${SERVER_URL}admin/assessments/${assessmentId}`);
};
