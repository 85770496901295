import { capitalize } from 'utils/capitalize';

export const validateData = (schema, postData, setEditOrganization) => {
  schema.validate(postData, { abortEarly: false }).catch((errors) => {
    errors.inner.forEach((error) => {
      if (error.path === 'name') {
        setEditOrganization({
          type: 'nameErrTxt',
          payload: capitalize(error.message),
        });
      } if (error.path === 'description') {
        setEditOrganization({
          type: 'descriptionErrTxt',
          payload: capitalize(error.message),
        });
      } if (error.path === 'contacts') {
        setEditOrganization({
          type: 'contactsErrTxt',
          payload: capitalize(error.message),
        });
      } if (error.path === 'credits') {
        setEditOrganization({
          type: 'creditsErrorMsg',
          payload: capitalize(error.message),
        });
      }
    });
  });
};
