import { EDIT_DRIVE } from 'redux/admin/editDrive/actionConstants';

const { EDIT_DRIVE_REQUEST_ACTION, EDIT_DRIVE_FAILURE_ACTION,
  EDIT_DRIVE_SUCCESS_ACTION, EDIT_DRIVE_CLEAR_ACTION } = EDIT_DRIVE;

export const editDriveRequestAction = (payload) => ({
  type: EDIT_DRIVE_REQUEST_ACTION,
  payload,
});

export const editDriveSuccessAction = (payload) => ({
  type: EDIT_DRIVE_SUCCESS_ACTION,
  payload,
});

export const editDriveFailureAction = (payload) => ({
  type: EDIT_DRIVE_FAILURE_ACTION,
  payload,
});

export const editDriveClearAction = () => ({
  type: EDIT_DRIVE_CLEAR_ACTION,
});
