/* eslint-disable max-len */
/* eslint-disable indent */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Icon,
} from 'core-components';
import siteLogo from 'assets/images/spark-code-logo.png';
import './HeaderIDE.css';
import './headerIDE.scss';
import ImagePopUpModal from 'shared-components/ImagePopUpModal';

const HeaderIDE = (props) => {
  const {
    currentProblem,
    totalProblems,
    nextProblemSwitch,
    prevProblemSwitch,
    title,
    description,
    problemImage,
    onClickSection,
    selectedSection,
    onClickQuestions,
    questions,
    selectedQuestion,
    isPresentMCQ,
    saveddAnswers,
    allowCoding,
    dispatchChange,
    showImageModal,
    toggleImageModalVisibility,
  } = props;

  return (
    <div className='test-description-section p-3'>
      <a
        className='site-logo d-inline-block text-decoration-none mb-2'
      >
        <img src={siteLogo} alt='site logo' />
      </a>
      <div className='problem-content d-flex flex-column h-100'>
        {(allowCoding && isPresentMCQ) ? (
          <div className='question-section d-flex mt-2 justify-content-between'>
            <Button color='none' className={`mr-1 ${(selectedSection === 1) && 'active-button'}`} onClick={(e) => onClickSection(1)}> <span> MCQs </span> </Button>
            <Button color='none' className={`ml-1 ${(selectedSection === 2) && 'active-button'}`} onClick={(e) => onClickSection(2)}> <span> Problems </span> </Button>
          </div>
        ) : null}
        <div className='problem-number-wrap d-flex align-items-center mt-3 justify-content-between'>
          {(selectedSection === 2) && (
            <Button
              color='link'
              className='ml-3'
              disabled={currentProblem <= 1}
              onClick={() => {
                dispatchChange();
                setTimeout(() => { prevProblemSwitch(); }, 50);
              }}
            >
              <Icon name='left' className='font-weight-bold' />
            </Button>
          )}
          {(selectedSection === 1) && (
            <Button
              color='link'
              className='ml-3'
              disabled={selectedQuestion <= 1}
              onClick={prevProblemSwitch}
            >
              <Icon name='left' className='font-weight-bold' />
            </Button>
          )}
          <div className='problem-number d-flex mx-3'>
            <span className='mb-0 ml-3 mr-2'>{(selectedSection === 2) && 'Problem'} {(selectedSection === 1) && 'Question'} </span>
            <span className='font-weight-bold'>{(selectedSection === 1) && `${selectedQuestion}/`} {(selectedSection === 2) && `${currentProblem}/`}</span>
            <span className='total-problem font-weight-bold'>
              {(selectedSection === 1) && questions.length}
              {(selectedSection === 2) && totalProblems}
            </span>
          </div>
          {(selectedSection === 2) && (
            <Button
              color='link'
              className='ml-3'
              disabled={currentProblem >= totalProblems}
              onClick={() => {
                dispatchChange();
                setTimeout(() => { nextProblemSwitch(); }, 50);
              }}
            >
              <Icon name='right' className='font-weight-bold' />
            </Button>
          )}
          {(selectedSection === 1) && (
            <Button
              color='link'
              className='ml-3'
              disabled={selectedQuestion >= questions.length}
              onClick={nextProblemSwitch}
            >
              <Icon name='right' className='font-weight-bold' />
            </Button>
          )}
        </div>
        <div className='problems-detail-wrapper scroll-y h-100'>
          {selectedSection === 2 ? (
            <>
              <h4 className='problem-title mb-3 text-uppercase'>{title}</h4>
              {problemImage && (
                <div
                  onClick={toggleImageModalVisibility}
                  role='button'
                  tabIndex={0}
                >
                  <img
                    className='w-100'
                    src={problemImage}
                    alt='Upload Question Img'
                  />
                </div>
              )}
              <ImagePopUpModal
                showImageModal={showImageModal}
                toggleImageModalVisibility={toggleImageModalVisibility}
                imageUrl={problemImage}
                alternateName='Question Image'
              />
              <p className='problem-description mb-0 mt-3'>
                {description}
              </p>
            </>
          ) : (
            <div className='d-flex paginate-wrapper mt-2 justify-content-start ml-4 mr-4'>
              {questions.map((index, item) => {
                const isSavedProblem = saveddAnswers[questions[item].id]?.length;
                const currentSelection = selectedQuestion === item + 1;
                return (
                  <Button key={Math.random()} className={`page-link ${isSavedProblem && 'answered-question'} ${currentSelection && 'selected-question'} ml-3 mr-3 mt-5 text-center`} onClick={(e) => onClickQuestions(item + 1)}>
                    <span>
                      {item + 1}
                    </span>
                  </Button>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

HeaderIDE.defaultProps = {
  description: '',
  title: '',
  problemImage: '',
};

HeaderIDE.propTypes = {
  currentProblem: PropTypes.number.isRequired,
  totalProblems: PropTypes.number.isRequired,
  nextProblemSwitch: PropTypes.func.isRequired,
  prevProblemSwitch: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  problemImage: PropTypes.string,
  onClickSection: PropTypes.func.isRequired,
  selectedSection: PropTypes.number.isRequired,
  onClickQuestions: PropTypes.func.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedQuestion: PropTypes.number.isRequired,
  isPresentMCQ: PropTypes.bool.isRequired,
  saveddAnswers: PropTypes.shape().isRequired,
  allowCoding: PropTypes.bool.isRequired,
  dispatchChange: PropTypes.func.isRequired,
  showImageModal: PropTypes.bool.isRequired,
  toggleImageModalVisibility: PropTypes.func.isRequired,
};

export default React.memo(HeaderIDE);
