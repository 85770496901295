import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'store';
import { toast } from 'react-toastify';

import Routes from 'root/Routes';

import 'assets/stylesheets/main.scss';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  toast.configure();
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
