import { PROBLEMS_BULK_UPLOAD_ACTIONS } from './actionConstants';

const {
  PROBLEM_IMPORT_FILE_ACTION,
  PROBLEM_SET_FILE_NAME,
  PROBLEM_SET_FILE,
  PROBLEM_RESET_STATE,
  PROBLEM_FILE_UPLOAD_SUCCESSFULLY,
  PROBLEM_ACTION_FAILED,
  PROBLEM_SET_FILE_PATH,
  PROBLEM_RESET_FILE_PATH,
  PROBLEM_START_FILE_IMPORT,
  PROBLEM_SET_FILE_NAME_ERROR,
  PROBLEM_DOWNLOAD_FILE_SUCCESS_ACTION,
} = PROBLEMS_BULK_UPLOAD_ACTIONS;

export const importFileAction = (payload) => ({
  type: PROBLEM_IMPORT_FILE_ACTION,
  payload,
});

export const setFileName = (payload) => ({
  type: PROBLEM_SET_FILE_NAME,
  payload,
});

export const setFile = (payload) => ({
  type: PROBLEM_SET_FILE,
  payload,
});

export const resetAction = () => ({
  type: PROBLEM_RESET_STATE,
});

export const successfulFileImport = () => ({
  type: PROBLEM_FILE_UPLOAD_SUCCESSFULLY,
});

export const actionFailed = () => ({
  type: PROBLEM_ACTION_FAILED,
});

export const setFilePath = (payload) => ({
  type: PROBLEM_SET_FILE_PATH,
  payload,
});

export const resetFilePath = () => ({
  type: PROBLEM_RESET_FILE_PATH,
});

export const startFileImport = () => ({
  type: PROBLEM_START_FILE_IMPORT,
});

export const setFileNameError = (payload) => ({
  type: PROBLEM_SET_FILE_NAME_ERROR,
  payload,
});

export const downloadFileSuccessAction = (payload) => ({
  type: PROBLEM_DOWNLOAD_FILE_SUCCESS_ACTION,
  payload,
});
