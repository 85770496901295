import { SERVER_URL } from 'constants/appConstants';
import { get, destroy } from '../apiHelper';

export const getQuestions = (queryParams) => {
  return get(`${SERVER_URL}admin/questions?${queryParams}`);
};

export const deleteQuestionApi = (id) => {
  return destroy(`${SERVER_URL}admin/questions/${id}`);
};
