import { call, put, takeLatest } from 'redux-saga/effects';

import {
  ongoingDriveListSuccess,
  upcomingDriveListSuccess,
  completedDriveListSuccess,
  ongoingDriveFailure,
  upcomingDriveFailure,
  completedDriveFailure,
  driveDeleteSuccess,
  driveDeleteFailure,
  completedDriveDeleteSuccess,
  ongoingDriveDeleteSuccess,
  upcomingDriveDeleteSuccess,
} from 'redux/admin/driveList/action';

// TODO
// repplace driveResultGetApi by getDriveApi
import { driveResultGetApi, driveDeleteApi } from 'redux/admin/driveList/api';
import { DRIVE_LIST } from 'redux/admin/driveList/actionConstants';
import { toast } from 'react-toastify';

// worker saga
export function* ongoingDriveSaga(action) {
  try {
    const { ongoingDriveOffSet } = action.payload;
    const response = yield call(driveResultGetApi, { driveType: 'ongoing', offset: ongoingDriveOffSet });
    yield put(ongoingDriveListSuccess(response.data.data));
  } catch (error) {
    yield put(ongoingDriveFailure('error while fetching ongoing drives'));
  }
}

export function* upcomingDriveSaga(action) {
  try {
    const { upcomingDriveOffSet } = action.payload;
    const response = yield call(driveResultGetApi, { driveType: 'upcoming', offset: upcomingDriveOffSet });
    yield put(upcomingDriveListSuccess(response.data.data));
  } catch (error) {
    yield put(upcomingDriveFailure('error while fetching upcoming drives'));
  }
}

export function* completedDriveSaga(action) {
  try {
    const { completedDriveOffSet } = action.payload;
    const response = yield call(driveResultGetApi, { driveType: 'completed', offset: completedDriveOffSet });
    yield put(completedDriveListSuccess(response.data.data));
  } catch (error) {
    yield put(completedDriveFailure('error while fetching completed drives'));
  }
}

export function* driveDeleteSaga(action) {
  try {
    const { id, type } = action.payload;
    const response = yield call(driveDeleteApi, { id });
    if (response.data.message === 'Drive deleted successfully.') {
      toast.success(response.data.message);
      switch (type) {
        case 'ongoingDrive':
          yield put(ongoingDriveDeleteSuccess({ id }));
          break;
        case 'upcomingDrive':
          yield put(upcomingDriveDeleteSuccess({ id }));
          break;
        case 'completedDrive':
          yield put(completedDriveDeleteSuccess({ id }));
          break;
        default:
          break;
      }
      // yield put(driveDeleteSuccess(id));
    } else {
      toast.error(response.data.message);
      yield put(driveDeleteFailure(response.data.message));
    }
  } catch (error) {
    yield put(driveDeleteFailure('error while deleting drive'));
  }
}

// watcherSaga
export default function* driveListSaga() {
  yield takeLatest(DRIVE_LIST.ONGOING_DRIVE_LIST_REQUEST, ongoingDriveSaga);
  yield takeLatest(DRIVE_LIST.UPCOMING_DRIVE_LIST_REQUEST, upcomingDriveSaga);
  yield takeLatest(DRIVE_LIST.COMPLETED_DRIVE_LIST_REQUEST, completedDriveSaga);
  yield takeLatest(DRIVE_LIST.DRIVE_DELETE_REQUEST, driveDeleteSaga);
}
