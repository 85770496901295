import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  createProblemSuccessAction,
  createProblemFailureAction,
} from 'redux/admin/createProblem/action';
import { createProblemPostApi, getPresignedUrlApi, putImageS3 } from 'redux/admin/createProblem/api';
import { CREATE_PROBLEM } from 'redux/admin/createProblem/actionConstants';

// worker saga
export function* createProblemSaga(action) {
  const {
    title,
    description,
    submissionCount,
    timeInMinutes,
    difficulty,
    tags,
    problemDiagram,
    testCasesAttributes,
  } = action.payload;

  let image_url;

  if (problemDiagram) {
    const presignedUrl = yield call(getPresignedUrlApi);

    const imageUrl = yield call(putImageS3, {
      url: presignedUrl.data.data.url,
      image: problemDiagram,
    });

    image_url = imageUrl.config.url.substr(0, imageUrl.config.url.indexOf('?'));
  }

  const data = {
    title,
    description,
    submission_count: submissionCount,
    time_in_minutes: timeInMinutes.toString(),
    difficulty_list: difficulty,
    tag_list: tags,
    image_url,
    test_cases_attributes: testCasesAttributes,
  };

  try {
    const response = yield call(createProblemPostApi, data);
    const { data: responseData, message } = response.data;
    yield put(createProblemSuccessAction({
      message,
      pid: responseData.problem.id,
    }));
    toast.success(message);
  } catch (error) {
    const { data: responseData } = error.response;
    toast.error(responseData.message);
    yield put(createProblemFailureAction(responseData));
  }
}

// watcherSaga
export default function* problemWatcherSaga() {
  yield takeLatest(CREATE_PROBLEM.CREATE_PROBLEM_REQUEST_ACTION, createProblemSaga);
}
