import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  setLanguageSelected,
  setChanged,
} from 'actions/languageAction';
import { changeSection } from 'actions/sectionStatusAction';
import { mcqQuestionSelection } from 'actions/mcqQuestionsActions';
import { setSubmissionAllowed } from 'actions/codeSubmissionActions';
import HeaderIDE from 'shared-components/Header/HeaderIDE';
import { statementActiveIndex } from 'actions/problemStatementActions';
import { saveCode } from 'actions/codeBackupAction';
import { clearIO } from 'actions/ioAction';
import WebcamCapture from 'shared-components/Header/Webcam/WebcamCapture';

import local from 'utils/local';
import session from 'utils/session';
import updateSessionObject from 'utils/updateSessionObject';

import './header.scss';

const HeaderIDEConatiner = () => {
  const {
    activeIndex,
    statement,
  } = useSelector((state) => state.problemStatementReducer);

  const allowCoding = session.getItem('allowCoding');

  const [showImageModal, setShowImageModal] = useState(false);

  const { selectedSection } = useSelector((state) => state.sectionStatusReducer);

  const { languages, code,
    languageSelected, changed } = useSelector((state) => state.languageReducer);

  const totalProblems = statement.length;
  const currentProblem = activeIndex;
  const languageId = languageSelected.id;
  const {
    id: problemId,
    title, description,
    image_url: problemImage,
  } = statement[activeIndex - 1] || { problem_id: null };

  const { candidateId } = useSelector((state) => state.userDriveReducer);
  const driveID = local.getItem('driveID');
  const drive = useSelector((state) => state.userDriveReducer);
  const { name } = drive.data;
  const organizationName = name;
  const {
    questions,
    selectedQuestion,
    saveddAnswers,
    isPresentMCQ,
  } = useSelector((state) => state.mcqQuestionsReducer);

  const dispatch = useDispatch();

  const {
    data: { id },
  } = useSelector((state) => state.userDriveReducer);

  const codeBackup = () => {
    if (
      code != null &&
      code &&
      languageId != null &&
      problemId != null &&
      candidateId != null &&
      driveID != null
    ) {
      const obj = {
        code,
        languageId,
        problemId,
        candidateId,
        driveID,
      };
      dispatch(saveCode(obj));
    }
  };

  const dispatchChange = () => {
    dispatch(setChanged(!changed));
  };

  const getSelectedLanguageObject = (problemLanguages, index) => {
    const selectedProblemId = statement[index].id;
    // getting language id of problem at index from problemLanguages object
    const selectedLanguageId = problemLanguages[selectedProblemId];
    // getting language object of problem with languageId for selected problem
    // as stored in problemLanguages object
    const selectedLanguageObject = languages.find((language) => language.id === selectedLanguageId);

    return selectedLanguageObject;
  };

  const nextProblemSwitch = () => {
    if (selectedSection === 2) {
      // if problemLanguages is available in session storage then get that object
      // else initializing with empty object
      const problemLanguages = session.getItem('problemLanguages') || {};
      updateSessionObject(problemLanguages, problemId, languageId);

      const { submission_count: submissionCount }
        = statement[currentProblem] || { problem_id: null };
      // codeBackup();

      let selectedLanguageObject = {};

      // checking if problemId and languageId of next problem is present in
      // problemLanguages object or not
      if (!problemLanguages[statement[currentProblem].id]) {
        // Condition - problem visiting first time
        // => setting same language to next problem
        // => getting language object of next problem with same language id as current
        selectedLanguageObject = languages.find((language) => language.id === languageId);
      } else {
        // Condition - problem already visited and it's problemId and languageId is stored

        // previous problem index => currentProblem
        selectedLanguageObject = getSelectedLanguageObject(problemLanguages, currentProblem);
      }

      dispatch(setLanguageSelected(selectedLanguageObject));
      dispatch(setSubmissionAllowed(submissionCount));
      dispatch(clearIO());
      dispatch(statementActiveIndex(currentProblem + 1));
    } else {
      dispatch(mcqQuestionSelection(selectedQuestion + 1));
    }
  };

  const prevProblemSwitch = () => {
    if (selectedSection === 2) {
      const problemLanguages = session.getItem('problemLanguages');
      updateSessionObject(problemLanguages, problemId, languageId);

      // previous problem index => currentProblem - 2
      const selectedLanguageObject = getSelectedLanguageObject(
        problemLanguages, currentProblem - 2,
      );

      const { submission_count: submissionCount }
        = statement[currentProblem] || { problem_id: null };
      // codeBackup();
      dispatch(setLanguageSelected(selectedLanguageObject));
      dispatch(setSubmissionAllowed(submissionCount));
      dispatch(clearIO());
      dispatch(statementActiveIndex(currentProblem - 1));
    } else {
      dispatch(mcqQuestionSelection(selectedQuestion - 1));
    }
  };

  const onClickSection = (sectionId) => {
    dispatch(changeSection(sectionId));
  };

  const onClickQuestions = (selectedId) => {
    dispatch(mcqQuestionSelection(selectedId));
  };

  const toggleImageModalVisibility = () => {
    setShowImageModal(!showImageModal);
  };

  return (
    <>
      <div className='webcam-wrapper position-absolute'>
        <WebcamCapture />
      </div>
      <HeaderIDE
        totalProblems={totalProblems}
        currentProblem={currentProblem}
        organizationName={organizationName}
        title={title}
        description={description}
        problemImage={problemImage}
        nextProblemSwitch={nextProblemSwitch}
        prevProblemSwitch={prevProblemSwitch}
        onClickSection={onClickSection}
        selectedSection={selectedSection}
        onClickQuestions={onClickQuestions}
        questions={questions}
        selectedQuestion={selectedQuestion}
        isPresentMCQ={isPresentMCQ}
        saveddAnswers={saveddAnswers}
        allowCoding={allowCoding}
        dispatchChange={dispatchChange}
        showImageModal={showImageModal}
        toggleImageModalVisibility={toggleImageModalVisibility}
      />
    </>
  );
};

export default React.memo(HeaderIDEConatiner);
