import produce from 'immer';

import { DRIVE_LIST } from 'redux/admin/driveList/actionConstants';

export const initialState = {
  ongoingDrives: [],
  upcomingDrives: [],
  completedDrives: [],
  ongoingDrivesLoading: false,
  upcomingDrivesLoading: false,
  completedDrivesLoading: false,
  totalOngoingDrives: 0,
  totalUpcomingDrives: 0,
  totalCompletedDrives: 0,
  ongoingDrivesFailureMessage: '',
  upcomingDriveFailureMessage: '',
  completedDriveFailureMessage: '',
  deleteDriveFailureMessage: '',
  completedDriveOffSet: 0,
  ongoingDriveOffSet: 0,
  upcomingDriveOffSet: 0,
  completedDrivesDeletionCount: 0,
  upcomingDrivesDeletionCount: 0,
  ongoingDrivesDeletionCount: 0,
};

const driveListReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case DRIVE_LIST.ONGOING_DRIVE_LIST_REQUEST:
      return {
        ...state,
        ongoingDrivesLoading: true,
        ongoingDriveOffSet: payload.ongoingDriveOffSet,
        ongoingDrivesDeletionCount: 0,
      };
    case DRIVE_LIST.UPCOMING_DRIVE_LIST_REQUEST:
      return {
        ...state,
        upcomingDrivesLoading: true,
        upcomingDriveOffSet: payload.upcomingDriveOffSet,
        upcomingDrivesDeletionCount: 0,
      };
    case DRIVE_LIST.COMPLETED_DRIVE_LIST_REQUEST:
      return {
        ...state,
        completedDrivesLoading: true,
        completedDriveOffSet: payload.completedDriveOffSet,
        completedDrivesDeletionCount: 0,
      };
    case DRIVE_LIST.DRIVE_DELETE_REQUEST:
      return { ...state };
    case DRIVE_LIST.UPCOMING_DRIVE_LIST_SUCCESS:
      return {
        ...state,
        upcomingDrives: [...state.upcomingDrives, ...payload.drives],
        totalUpcomingDrives: payload.total_drives,
        upcomingDrivesLoading: false,
        upcomingDriveFailureMessage: '',
      };
    case DRIVE_LIST.ONGOING_DRIVE_LIST_SUCCESS:
      return {
        ...state,
        ongoingDrives: [...state.ongoingDrives, ...payload.drives],
        totalOngoingDrives: payload.total_drives,
        ongoingDrivesLoading: false,
        ongoingDrivesFailureMessage: '',
      };
    case DRIVE_LIST.COMPLETED_DRIVE_LIST_SUCCESS:
      return {
        ...state,
        completedDrives: [...state.completedDrives, ...payload.drives],
        totalCompletedDrives: payload.total_drives,
        completedDrivesLoading: false,
        completedDriveFailureMessage: '',
      };
    case DRIVE_LIST.DRIVE_DELETE_SUCCESS:
      return {
        ...state,
        completedDrives: state.completedDrives.filter((drive) => drive.id !== payload),
        upcomingDrives: state.upcomingDrives.filter((drive) => drive.id !== payload),
        ongoingDrives: state.ongoingDrives.filter((drive) => drive.id !== payload),
      };
    case DRIVE_LIST.ONGOING_DRIVE_LIST_FAILURE:
      return { ...state, ongoingDrivesFailureMessage: payload, ongoingDrivesLoading: false };
    case DRIVE_LIST.UPCOMING_DRIVE_LIST_FAILURE:
      return { ...state, upcomingDriveFailureMessage: payload, upcomingDrivesLoading: false };
    case DRIVE_LIST.COMPLETED_DRIVE_LIST_FAILURE:
      return { ...state, completedDriveFailureMessage: payload, completedDrivesLoading: false };
    case DRIVE_LIST.DRIVE_DELETE_FAILURE:
      return { ...state, deleteDriveFailureMessage: payload };
    case DRIVE_LIST.CLEAR_DRIVES:
      return { ...initialState };
    case DRIVE_LIST.SET_COMPLETED_DRIVE_OFFSET:
      return { ...state, completedDriveOffSet: payload };
    case DRIVE_LIST.SET_ONGOING_DRIVE_OFFSET:
      return { ...state, ongoingDriveOffSet: payload };
    case DRIVE_LIST.SET_UPCOMING_DRIVE_OFFSET:
      return { ...state, upcomingDriveOffSet: payload };
    case DRIVE_LIST.COMPLETED_DRIVE_DELETE_SUCCESS:
      state.completedDrives = state.completedDrives.filter((drive) => drive.id !== payload.id);
      state.completedDrivesDeletionCount += 1;
      state.totalCompletedDrives -= 1;
      break;
    case DRIVE_LIST.UPCOMING_DRIVE_DELETE_SUCCESS:
      state.upcomingDrives = state.upcomingDrives.filter((drive) => drive.id !== payload.id);
      state.upcomingDrivesDeletionCount += 1;
      state.totalUpcomingDrives -= 1;
      break;
    case DRIVE_LIST.ONGOING_DRIVE_DELETE_SUCCESS:
      state.ongoingDrives = state.ongoingDrives.filter((drive) => drive.id !== payload.id);
      state.ongoingDrivesDeletionCount += 1;
      state.totalOngoingDrives -= 1;
      break;
    default:
      return state;
  }
});

export default driveListReducer;
