import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import AdminHomeComponent from 'modules/admin/adminHome/AdminHomeComponent';

import {
  ongoingDriveListRequest,
  upcomingDriveListRequest,
  completedDriveListRequest,
  clearDrivesAction,
  driveDeleteRequest,
  setCompletedDriveOffset,
  setOngoingDriveOffset,
  setUpcomingDriveOffset,
} from 'redux/admin/driveList/action';

import { DRIVES_LIMIT } from 'constants/appConstants';

const AdminHomeContainer = () => {
  const dispatch = useDispatch();
  const observer = useRef({});
  const history = useHistory();

  const {
    ongoingDrives,
    totalOngoingDrives,
    ongoingDrivesLoading,
    upcomingDrives,
    completedDrives,
    upcomingDrivesLoading,
    completedDrivesLoading,
    totalUpcomingDrives,
    totalCompletedDrives,
    ongoingDrivesFailureMessage,
    upcomingDriveFailureMessage,
    completedDriveFailureMessage,
    completedDriveOffSet,
    completedDrivesDeletionCount,
    ongoingDriveOffSet,
    upcomingDriveOffSet,
    upcomingDrivesDeletionCount,
    ongoingDrivesDeletionCount,
  } = useSelector((item) => item.driveListReducer);

  const [deletedDriveType, setDeletedDriveType] = useState('');
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [targetId, setTargetId] = useState();
  const [driveName, setDriveName] = useState();

  const toggleConfirmation = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleDeleteClick = (id, name, type) => {
    setTargetId(id);
    setDriveName(name);
    setDeletedDriveType(type);
  };

  const onClickEdit = (driveId) => {
    history.push(`/admin/drive/${driveId}/edit`);
  };

  const onClickCandidates = (drive) => {
    history.push(`/admin/drive/${drive.id}/candidates`);
  };

  const onClickResult = (drive) => {
    history.push(`/admin/drive/${drive.id}/result`);
  };

  const onClickDelete = (driveId) => {
    dispatch(driveDeleteRequest({ id: driveId, type: deletedDriveType }));
    setDeletedDriveType('');
  };

  // storing reference of last scrolled element
  const completedDriveRef = useCallback((node) => {
    if (completedDrivesLoading) return;
    if (observer.current && observer.current.completed) observer.current.completed.disconnect();
    observer.current.completed = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (completedDrives.length >= DRIVES_LIMIT || completedDrivesDeletionCount !== 0) {
          dispatch(
            setCompletedDriveOffset(
              completedDriveOffSet + DRIVES_LIMIT - completedDrivesDeletionCount,
            ),
          );
        }
      }
    });
    if (node) observer.current.completed.observe(node);
  }, [completedDrivesLoading, completedDrivesDeletionCount]);

  const upcomingDriveRef = useCallback((node) => {
    if (upcomingDrivesLoading) return;
    if (observer.current && observer.current.upcoming) observer.current.upcoming.disconnect();
    observer.current.upcoming = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (upcomingDrives.length >= DRIVES_LIMIT || upcomingDrivesDeletionCount !== 0) {
          dispatch(
            setUpcomingDriveOffset(
              upcomingDriveOffSet + DRIVES_LIMIT - upcomingDrivesDeletionCount,
            ),
          );
        }
      }
    });
    if (node) observer.current.upcoming.observe(node);
  }, [upcomingDrivesLoading, upcomingDrivesDeletionCount]);

  const ongoingDriveRef = useCallback((node) => {
    if (ongoingDrivesLoading) return;
    if (observer.current && observer.current.ongoing) observer.current.ongoing.disconnect();
    observer.current.ongoing = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (ongoingDrives.length >= DRIVES_LIMIT || ongoingDrivesDeletionCount !== 0) {
          dispatch(
            setOngoingDriveOffset(
              ongoingDriveOffSet + DRIVES_LIMIT - ongoingDrivesDeletionCount,
            ),
          );
        }
      }
    });
    if (node) observer.current.ongoing.observe(node);
  }, [ongoingDrivesLoading, ongoingDrivesDeletionCount]);

  // dispatching action after last scrolling
  useEffect(() => {
    if (!completedDrivesLoading && completedDriveOffSet <= totalCompletedDrives) {
      dispatch(completedDriveListRequest({ completedDriveOffSet }));
    }
  }, [completedDriveOffSet]);

  useEffect(() => {
    if (!ongoingDrivesLoading && ongoingDriveOffSet <= totalOngoingDrives) {
      dispatch(ongoingDriveListRequest({ ongoingDriveOffSet }));
    }
  }, [ongoingDriveOffSet]);

  useEffect(() => {
    if (!upcomingDrivesLoading && upcomingDriveOffSet <= totalUpcomingDrives) {
      dispatch(upcomingDriveListRequest({ upcomingDriveOffSet }));
    }
  }, [upcomingDriveOffSet]);

  useEffect(() => {
    return () => {
      dispatch(clearDrivesAction());
    };
  }, []);

  return (
    <AdminHomeComponent
      ongoingDrives={ongoingDrives}
      totalOngoingDrives={totalOngoingDrives}
      ongoingDrivesLoading={ongoingDrivesLoading}
      upcomingDrives={upcomingDrives}
      completedDrives={completedDrives}
      upcomingDrivesLoading={upcomingDrivesLoading}
      completedDrivesLoading={completedDrivesLoading}
      totalUpcomingDrives={totalUpcomingDrives}
      totalCompletedDrives={totalCompletedDrives}
      onClickEdit={onClickEdit}
      onClickCandidates={onClickCandidates}
      onClickResult={onClickResult}
      completedDriveRef={completedDriveRef}
      ongoingDriveRef={ongoingDriveRef}
      upcomingDriveRef={upcomingDriveRef}
      ongoingDrivesFailureMessage={ongoingDrivesFailureMessage}
      upcomingDriveFailureMessage={upcomingDriveFailureMessage}
      completedDriveFailureMessage={completedDriveFailureMessage}
      toggleConfirmation={toggleConfirmation}
      targetId={targetId}
      driveName={driveName}
      handleDeleteClick={handleDeleteClick}
      confirmationModal={confirmationModal}
      onClickDelete={onClickDelete}
    />
  );
};

export default AdminHomeContainer;
