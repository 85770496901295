import { Alert } from 'core-components';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  candidateListSuccess,
  candidateListFailure,
  setDriveStatusAction,
} from './action';
import { CANDIDATE_LIST } from './actionConstants';
import { getCandidates } from './api';

// worker saga
export function* candidateSaga(action) {
  try {
    const { currentPageNumber, driveId, searchQuery, absentCandidates } = action.payload;
    const response = yield call(getCandidates, {
      currentPageNumber,
      driveId,
      searchQuery,
      absentCandidates,
    });
    const { data: { data } } = response;
    const { drive_status: driveStatus } = data;
    yield put(candidateListSuccess(data));
    yield put(setDriveStatusAction(driveStatus));
  } catch (error) {
    <Alert className='danger'> {error} </Alert>;
    yield put(candidateListFailure(error));
  }
}

// watcher saga
export default function* candidateListSaga() {
  yield takeLatest(CANDIDATE_LIST.CANDIDATE_LIST_REQUEST, candidateSaga);
}
