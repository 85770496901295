import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import OrganizationsComponent from 'modules/superAdmin/organization/OrganizationComponent';

import {
  organizationListRequest,
  setOrganizationOffset,
  organizationListClear,
} from 'redux/superAdmin/organizationList/action';

import { ORGANIZATION_LIMIT } from 'constants/appConstants';

const OrganizationContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const observer = useRef();

  const [searchText, setSearchText] = useState();
  const [isSearched, setIsSearched] = useState(false);

  const {
    organization,
    isOrgListLoading,
    totalOrganization,
    offset: currentPageOrganization,
    addCreditsLoading,
    orgListErrorMsg,
  } = useSelector((state) => state.organizationListReducer);

  useEffect(() => {
    if (!isOrgListLoading && currentPageOrganization <= totalOrganization) {
      dispatch(organizationListRequest({
        offset: currentPageOrganization,
        organization,
        searchText,
      }));
    }
  }, [currentPageOrganization]);

  useEffect(() => {
    return () => {
      dispatch(organizationListClear());
    };
  }, []);

  const lastOrgElementRef = useCallback(
    (node) => {
      if (isOrgListLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (organization.length >= ORGANIZATION_LIMIT) {
            dispatch(setOrganizationOffset(currentPageOrganization + ORGANIZATION_LIMIT));
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [isOrgListLoading],
  );

  const handleOnChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, [searchText]);

  const handleSearchClick = useCallback(async () => {
    if (searchText || isSearched) {
      if (!isOrgListLoading) {
        dispatch(organizationListRequest({
          offset: 0,
          searchText: searchText.trim(),
          organization: [],
        }));
      }
      if (!searchText && isSearched) {
        setIsSearched(false);
      } else {
        setIsSearched(true);
      }
    }
  });

  const handleOrganizationSearchClearClick = () => {
    setSearchText('');
    if (isSearched) {
      dispatch(organizationListRequest({
        offset: 0,
        searchText: '',
        organization: [],
      }));
    }
    setIsSearched(false);
  };

  const onClickEdit = (organizationId, e) => {
    localStorage.setItem('editorganizationId', organizationId);
    history.push(`/administrant/organization/${organizationId}/edit`);
  };

  return (
    <OrganizationsComponent
      organization={organization}
      onClickEdit={onClickEdit}
      lastOrgElementRef={lastOrgElementRef}
      totalOrganization={totalOrganization}
      isOrgListLoading={isOrgListLoading}
      addCreditsLoading={addCreditsLoading}
      orgListErrorMsg={orgListErrorMsg}
      handleOnChange={handleOnChange}
      handleSearchClick={handleSearchClick}
      handleOrganizationSearchClearClick={handleOrganizationSearchClearClick}
      searchText={searchText}
    />
  );
};

export default React.memo(OrganizationContainer);
