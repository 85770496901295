import PropTypes from 'prop-types';
import React from 'react';

import { Modal, ModalBody } from 'core-components';
import {
  CloseButton,
  InputWithButton,
  CardComponent,
  DriveTile,
  DeleteConfirmModal,
} from 'shared-components';
import Loading from 'shared-components/Loading';

import './searchModalComponent.scss';

const SearchModalComponent = (props) => {
  const {
    toggle,
    showModal,
    drives,
    drivesLoading,
    driveSearchFailureMessage,
    onClickEdit,
    onClickCandidates,
    onClickResult,
    driveSearchRef,
    toggleConfirmation,
    targetId,
    handleDeleteClick,
    confirmationModal,
    handleOnClickSearch,
    handleOnChangeInput,
    searchText,
    driveName,
    showDeleteConfirmationModal,
    toggleDeleteConfirmation,
    onClickDelete,
    totalSearchedDrives,
    handleResetSearch,
  } = props;

  return (
    <Modal
      isOpen={showModal}
      toggle={toggle}
      modalClassName='left_modal search-modal'
    >
      <div className='modal-header-wrap d-flex align-items-center mb-2'>
        <h6 className='modal-heading mb-0'>Search ({totalSearchedDrives} Drives)</h6>
        <CloseButton onClick={toggle} />
      </div>
      <ModalBody className='search-body-modal p-0 d-flex scroll-y flex-column'>
        <InputWithButton
          color='primary'
          buttonText='Search'
          placeHolder='Type Here'
          emailsError=''
          onClick={handleOnClickSearch}
          onChange={handleOnChangeInput}
          value={searchText}
          isSearchModal
          handleResetSearch={handleResetSearch}
        />
        <DeleteConfirmModal
          confirmationModal={showDeleteConfirmationModal}
          toggleConfirmation={toggleDeleteConfirmation}
          handleDelete={onClickDelete}
          targetId={targetId}
          name={driveName}
          type='drive'
        />
        <div className='mt-3 sp-col scroll-y'>
          <div className='sp-col-body '>
            <div className='sp-card-list px-4'>
              {!drivesLoading && drives?.length === 0 ? 'No Drive Found' :
                drives.map((e) => {
                  return (
                    <div ref={driveSearchRef} className='mb-4' key={e.id}>
                      <CardComponent>
                        <DriveTile
                          driveDetails={e}
                          onClickCandidates={onClickCandidates}
                          onClickResult={onClickResult}
                          onClickEdit={onClickEdit}
                          confirmationModal={showDeleteConfirmationModal}
                          toggleConfirmation={toggleDeleteConfirmation}
                          targetId={targetId}
                          handleDeleteClick={handleDeleteClick}
                          type='search'
                          toggleSearchModal={toggle}
                          isDrive
                        />
                      </CardComponent>
                    </div>
                  );
                })}
              {drivesLoading && <Loading />}
              {driveSearchFailureMessage}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

SearchModalComponent.defaultProps = {
  targetId: 0,
};

SearchModalComponent.propTypes = {
  toggle: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  drives: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  drivesLoading: PropTypes.bool.isRequired,
  driveSearchFailureMessage: PropTypes.string.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickCandidates: PropTypes.func.isRequired,
  onClickResult: PropTypes.func.isRequired,
  driveSearchRef: PropTypes.func.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  targetId: PropTypes.number,
  onClickDelete: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
  handleOnClickSearch: PropTypes.func.isRequired,
  handleOnChangeInput: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  driveName: PropTypes.string.isRequired,
  showDeleteConfirmationModal: PropTypes.bool.isRequired,
  toggleDeleteConfirmation: PropTypes.func.isRequired,
  totalSearchedDrives: PropTypes.number.isRequired,
  handleResetSearch: PropTypes.func.isRequired,
};

export default SearchModalComponent;
