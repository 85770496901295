import produce from 'immer';

import { CREATE_ASSESSMENT } from 'redux/admin/createAssessment/actionConstants';

export const initialState = {
  isLoading: false,
  isSuccess: false,
  message: '',
  failureMessage: '',
};

const createAssessmentReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_ASSESSMENT.CREATE_ASSESSMENT_REQUEST_ACTION:
      return { ...state, isLoading: true };
    case CREATE_ASSESSMENT.CREATE_ASSESSMENT_SUCCESS_ACTION:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
        message: payload.message,
      };
    case CREATE_ASSESSMENT.CREATE_ASSESSMENT_FAILURE_ACTION:
      return {
        ...state,
        failureMessage: payload.message,
        isLoading: false,
      };

    case CREATE_ASSESSMENT.CLEAR_STATE:
      return { ...initialState };
    default:
      return state;
  }
});

export default createAssessmentReducer;
