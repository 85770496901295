import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Container, Table } from 'core-components';
import IconImagePopUpModal from 'shared-components/IconImagePopUpModal';

import './report.scss';

const McqResultComponent = ({ mcqResult }) => {
  return (
    <Container fluid className='px-5 report-card'>
      <Table className='table-bordered tableStyle text-white'>
        <thead>
          <tr>
            <th className='srNo'>Sr.No.</th>
            <th>Description</th>
            <th className='option'>Correct Answer(s)</th>
            <th className='option'>Selected Answer(s)</th>
            <th className='srNo'>Marks</th>
            <th className='marksObtained'>Marks Obtained</th>
          </tr>
        </thead>
        {mcqResult.map((mcq, index) => {
          const correctOption = [];
          const selectedOptions = [];
          const {
            image_url: imageUrl,
            obtained_marks: obtainedMarks,
            description,
            options,
            marks,
          } = mcq;

          options?.map((option) => {
            if (option.is_answer) {
              correctOption.push(option);
            }
            if (mcq.selected_options?.includes(option.id)) {
              selectedOptions.push(option);
            }
            return '';
          });
          return (
            <tbody>
              <tr>
                <td>{index + 1}</td>
                <td>
                  <div className='image-div'>
                    {description}
                    {imageUrl && (
                      <IconImagePopUpModal
                        imageUrlLink={imageUrl}
                        index={index}
                      />
                    )}
                  </div>
                </td>
                <td>
                  <ul>
                    {correctOption.map((option) => {
                      const {
                        id,
                        description: optionDescription,
                        image_url: optionImageUrl,
                      } = option;
                      return (
                        <div className='image-div'>
                          <li className='ml-2' key={id}>
                            {optionDescription}
                          </li>
                          {optionImageUrl && (
                            <IconImagePopUpModal
                              imageUrlLink={optionImageUrl}
                              index={id}
                            />
                          )}
                        </div>
                      );
                    })}
                  </ul>
                </td>
                <td>
                  <ul>
                    {selectedOptions.length !== 0 ? selectedOptions.map((option) => {
                      const {
                        id,
                        description: selectedDescription,
                        image_url: selectedImageUrl,
                      } = option;
                      return (
                        <div className='image-div'>
                          <li
                            className={
                              mcq.is_accepted
                                ? 'ml-2 text-success'
                                : 'ml-2 text-danger'
                            }
                            key={id}
                          >
                            {selectedDescription}
                          </li>
                          {selectedImageUrl && (
                            <IconImagePopUpModal
                              imageUrlLink={selectedImageUrl}
                              index={id}
                            />
                          )}
                        </div>
                      );
                    }) : '-'}
                  </ul>
                </td>
                <td>{marks || 0}</td>
                <td>{obtainedMarks || 0}</td>
              </tr>
            </tbody>
          );
        })}
      </Table>
    </Container>
  );
};

McqResultComponent.propTypes = {
  mcqResult: PropTypes.checkPropTypes.isRequired,
};

export default React.memo(McqResultComponent);
