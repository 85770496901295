import { call, put, takeEvery } from 'redux-saga/effects';
import { problemsSuccessAction, problemsFailureAction } from 'redux/admin/problems/action';
import { getProblemsApi } from 'redux/admin/problems/api';
import { PROBLEMS } from 'redux/admin/problems/actionContants';
import queryStringHelper from 'utils/queryStringHelper';

export function* getProblemsList(action) {
  const { searchProbText, filterProbDifficulty, filterProbTags } = action.payload;
  const getQueryString = queryStringHelper({
    search: searchProbText,
    difficulty_list: filterProbDifficulty,
    tag_list: filterProbTags?.toString(),
  });

  try {
    const response = yield call(getProblemsApi, getQueryString);
    yield put(problemsSuccessAction(response.data.data));
  } catch (error) {
    yield put(problemsFailureAction(error));
  }
}

export default function* getProblemsSaga() {
  yield takeEvery(PROBLEMS.PROBLEMS_REQUEST_ACTION, getProblemsList);
}
