import produce from 'immer';
import { PROBLEMS } from 'redux/admin/problems/actionContants';

export const initialState = {
  problems: [],
  totalProblems: 0,
  isProblemsSuccess: false,
  isProblemsLoading: false,
  isProblemsError: false,
};

const getProblemsReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case PROBLEMS.PROBLEMS_REQUEST_ACTION:
      state.isProblemsLoading = true;
      state.problems = payload.problems;
      state.deletionCount = 0;
      break;
    case PROBLEMS.PROBLEMS_SUCCESS_ACTION:
      state.isProblemsSuccess = true;
      state.isProblemsLoading = false;
      state.problems = payload.problems;
      state.totalProblems = payload.total_problems;
      break;
    case PROBLEMS.PROBLEMS_FAILURE_ACTION:
      state.isProblemsError = true;
      state.isProblemsLoading = false;
      break;
    case PROBLEMS.CLEAR_PROBLEMS_LIST:
      return { ...initialState };
    default:
      return state;
  }
});
export default getProblemsReducer;
