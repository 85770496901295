import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import EditorContainer from 'containers/EditorContainer';
import InputOutputContainer from 'containers/InputOutputContainer';

import { resetToken } from 'actions/candidateFormActions';
import { finishTestRequest } from 'actions/finishTestActions';

import Header from 'shared-components/Header';
import OpenFullScreenModal from 'shared-components/OpenFullScreenModal';

import local from 'utils/local';
import session from 'utils/session';

import { CANDIDATE_ROUTES, ROUTES } from 'constants/routeConstants';

import './ideComponent.scss';
// import ChatwootWidget from './Chatwoot';

function IdeComponent({
  code,
  languageSelected,
  setFirst,
  handleSaveDraft,
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  // how many attempts are remaining for entering in full screen before test ends
  const fullScreenAttempts = session.getItem('fullScreenAttempts');
  // eslint-disable-next-line no-restricted-globals
  console.log(`setstate isFullScreen ${window.innerHeight === screen.height}`);

  // eslint-disable-next-line no-restricted-globals
  const [isFullScreen, setIsFullScreen] = useState(window.innerHeight === screen.height);

  console.log(
    // eslint-disable-next-line no-restricted-globals
    `setstate setConfirmationModal ${window.innerHeight !== screen.height}`,
  );

  const [confirmationModal, setConfirmationModal] = useState(
    // eslint-disable-next-line no-restricted-globals
    window.innerHeight !== screen.height,
  );
  const [status, setStatus] = useState('');
  const [oldStatus, setOldStatus] = useState('');

  // preventing f11, f12, esc, ctrl keys
  function useCustomFullScreen(event) {
    if (event.keyCode === 116 || // f5
      event.keyCode === 122 ||   // f11
      event.keyCode === 123 ||   // f12
      event.key === 'Escape' ||
      event.ctrlKey) {
      event.preventDefault();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', (event) => useCustomFullScreen(event));
    return () => {
      window.removeEventListener('keydown', (event) => useCustomFullScreen(event));
    };
  }, []);

  // to check if user has network connection or not
  useEffect(() => {
    if (navigator.onLine) {
      setStatus('online');
    } else {
      setStatus('offline');
    }
  }, [status]);

  // changing status and exiting full screen on offline network connection
  useEffect(() => {
    setInterval(() => {
      if (navigator.onLine) {
        setStatus('online');
        if (oldStatus !== 'online') {
          setOldStatus('online');
        }
      } else {
        setStatus('offline');

        if (oldStatus !== 'offline') {
          setOldStatus('offline');
          document.exitFullscreen();
        }
      }
    }, 5000);
  }, [navigator.onLine, fullScreenAttempts]);

  // on exosting 2 attempts for entering into full screen exiting full screen and ending test
  useEffect(() => {
    if (!isFullScreen) {
      if (document.getElementById('test-ide')) {
        document.getElementById('test-ide').style.filter = 'blur(5px)';
        session.setItem('fullScreenAttempts', fullScreenAttempts - 1);
        console.log('setstate setConfirmationModal true');
        setConfirmationModal(true);
        if (fullScreenAttempts <= 1) {
          local.setItem('isFinishedTest', true);
          dispatch(resetToken());
          dispatch(finishTestRequest());
          document.exitFullscreen();
          history.replace(ROUTES.CANDIDATE + CANDIDATE_ROUTES.ENDPAGE);
        }
      }
    } else {
      document.getElementById('test-ide').style.filter = 'blur(0px)';
    }
  }, [isFullScreen]);

  // to check if browser window is in fullscreen or not
  useEffect(() => {
    window.addEventListener('resize', () => {
      // eslint-disable-next-line no-restricted-globals
      if (window.innerHeight === screen.height) {
        console.log('addEventListener in setIsFullScreen set true');
        setIsFullScreen(true);
      } else {
        console.log('addEventListener in setIsFullScreen set fasle');
        setIsFullScreen(false);
      }
    });
    return () => {
      window.removeEventListener('resize');
    };
  }, []);

  return (
    <div className='test-page-wrapper'>
      {!isFullScreen && (
        <OpenFullScreenModal
          confirmationModal={confirmationModal}
          setConfirmationModal={setConfirmationModal}
          setIsFullScreen={setIsFullScreen}
        />
      )}
      <div
        id='test-ide'
        className='test-page-section d-flex overflow-hidden'
      >
        <div className='test-col'>
          <Header />
        </div>
        <div className='test-col -code-col'>
          <EditorContainer
            code={code}
            languageSelected={languageSelected}
            setFirst={setFirst}
            handleSaveDraft={handleSaveDraft}
          />
          {/* <ChatwootWidget /> */}
        </div>
      </div>
    </div>
  );
}

IdeComponent.propTypes = {
  code: PropTypes.string.isRequired,
  languageSelected: PropTypes.objectOf.isRequired,
  setFirst: PropTypes.bool.isRequired,
  handleSaveDraft: PropTypes.func.isRequired,
};

export default React.memo(IdeComponent);
