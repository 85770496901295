import { call, put, takeLatest } from 'redux-saga/effects';

import {
  verifyPasswordSuccessAction,
  verifyPasswordFailureAction,
} from 'redux/admin/verifyForgotPassword/action';
import { resetPassword } from 'redux/admin/verifyForgotPassword/api';
import { VERIFY_FORGOT_PASSWORD } from 'redux/admin/verifyForgotPassword/actionConstants';

// worker saga
export function* changePasswordSaga(action) {
  try {
    const response = yield call(resetPassword, action.payload);
    if (response.status === 200 && response.data.message === 'Password reset successfully') {
      yield put(verifyPasswordSuccessAction());
    } else {
      yield put(verifyPasswordFailureAction(response.data));
    }
  } catch (error) {
    yield put(verifyPasswordFailureAction({ message: error.message }));
  }
}

// watcherSaga
export default function* verifyForgotPasswordSaga() {
  yield takeLatest(
    VERIFY_FORGOT_PASSWORD.VERIFY_FORGOT_PASSWORD_REQUEST_ACTION,
    changePasswordSaga,
  );
}
