import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import DriveResultComponent from 'modules/admin/driveResult/DriveResultComponent';

import {
  downloadAssessmentResultRequestAction,
  setDownloadedAssessmentNameAction,
} from 'redux/admin/downloadResult/action';
import { Icon } from 'core-components';

import local from 'utils/local';
import { getValueIfTestNotAttended } from 'utils';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';

import 'modules/admin/userProfile/UserProfileComponent/userProfileComponent.scss';
import 'modules/admin/driveResult/DriveResultComponent/driveResultComponent.scss';

import { assessmentCandidateReportRequest } from 'redux/admin/assessmentCandidates/action';

const AssessmentResultsContainer = () => {
  const { assessmentId } = useParams();
  const id = assessmentId;

  const [searchQuery, setSearchQuery] = useState('');
  const [isSearched, setIsSearched] = useState(false);

  const { data, errorMessage, isError } = useSelector(
    (state) => state.driveResultReducer,
  );
  const {
    candidates,
    isReportsLoading: isLoading,
    resultTotalPages,
    assessmentName,
  } = useSelector((state) => state.assessmentCandidatesReducer);
  const { driveResultMessage } = useSelector(
    (state) => state.driveResultReducer,
  );

  const isDownloadResultDisable = candidates?.length ? !candidates.length : true;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(assessmentCandidateReportRequest({ id, searchQuery }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setDownloadedAssessmentNameAction(assessmentName));
  }, [assessmentName]);

  // clean up effect (it will run on component unmount)
  useEffect(() => local.setItem('candidateReportToken', ''), []);

  const handleDownloadResult = () => {
    dispatch(downloadAssessmentResultRequestAction(assessmentId));
  };
  const handlePageClick = (event) => {
    dispatch(assessmentCandidateReportRequest({ id, page: event.selected + 1, searchQuery }));
  };

  const onClickCandidateReport = (e, index) => {
    const driveUUID = localStorage.getItem('showAssessmentUUID');
    const { token } = candidates[index];
    local.setItem('candidateReportToken', token);
    window.open(`/admin/drive/${driveUUID}/candidate/${token}/report`, '_blank');
  };

  // TO DO
  // need to move in sub component
  const renderTableData = useMemo(() => {
    if (candidates.length === 0) {
      return (
        <tr>
          <td>No candidate(s) to view result!</td>
        </tr>
      );
    }
    return candidates.map((val, index) => {
      if (val) {
        const {
          candidate_id: candidateId,
          first_name: firstName,
          last_name: lastName,
          email,
          score,
          time_taken: timeTaken,
          token,
        } = val;
        return (
          <tr key={token} id={candidateId}>
            <td>{candidateId}</td>
            <td>{firstName}</td>
            <td>{lastName}</td>
            <td>{email}</td>
            <td>{getValueIfTestNotAttended(score, timeTaken)}</td>
            <td>{timeTaken}</td>
            <td style={{ fontSize: '2em' }}>
              {timeTaken !== 'Not Attended' ? (
                <Icon
                  name='report'
                  onClick={(e) => onClickCandidateReport(e, index)}
                  className='report-icon'
                  title='Candidate Report'
                />
              ) : '-'}
            </td>
          </tr>
        );
      }
      return '';
    });
  }, [candidates]);

  const previousClick = () => {
    history.push(ROUTES.ADMIN + ADMIN_ROUTES.ASSESSMENTS);
  };

  const handleOnChange = useCallback((event) => {
    setSearchQuery(event.target.value);
  }, [searchQuery]);

  const handleSearchClick = () => {
    if (searchQuery || isSearched) {
      dispatch(assessmentCandidateReportRequest({ id, searchQuery: searchQuery.trim() }));
      if (!searchQuery && isSearched) {
        setIsSearched(false);
      } else {
        setIsSearched(true);
      }
    }
  };

  const handleDriveResultSearchClearClick = () => {
    setSearchQuery('');
    if (isSearched) {
      dispatch(assessmentCandidateReportRequest({ id, searchQuery: '' }));
    }
    setIsSearched(false);
  };

  // TO DO
  // this function is not used anywhere, check why it written
  const handleSelectedCandidateChange = () => { };

  return (
    <DriveResultComponent
      renderTableData={renderTableData}
      errorMessage={errorMessage}
      isError={isError}
      isLoading={isLoading}
      handleDownloadResult={handleDownloadResult}
      driveResultMessage={driveResultMessage}
      handlePageClick={handlePageClick}
      pageCount={resultTotalPages}
      previousClick={previousClick}
      driveName={assessmentName}
      handleOnChange={handleOnChange}
      handleSearchClick={handleSearchClick}
      searchQuery={searchQuery}
      handleDriveResultSearchClearClick={handleDriveResultSearchClearClick}
      handleSelectedCandidateChange={handleSelectedCandidateChange}
      isAssessmentResult
      isDownloadResultDisable={isDownloadResultDisable}
    />
  );
};

export default React.memo(AssessmentResultsContainer);
