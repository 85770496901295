import { DRIVE_CONTACTS } from 'redux/admin/getDriveContacts/actionConstants';

const { DRIVE_CONTACTS_REQUEST_ACTION, DRIVE_CONTACTS_SUCCESS_ACTION,
  DRIVE_CONTACTS_FAILURE_ACTION, DRIVE_CLEAR_CONTACTS } = DRIVE_CONTACTS;

export const driveContactsListRequestAction = (payload) => ({
  type: DRIVE_CONTACTS_REQUEST_ACTION,
  payload,
});

export const driveContactsListSuccessAction = (payload) => ({
  type: DRIVE_CONTACTS_SUCCESS_ACTION,
  payload,
});

export const driveContactsListFailureAction = (payload) => ({
  type: DRIVE_CONTACTS_FAILURE_ACTION,
  payload,
});

export const driveContactsListClear = () => ({
  type: DRIVE_CLEAR_CONTACTS,
});
