import PropTypes from 'prop-types';
import React from 'react';

import {
  Spinner,
  Label,
  Input,
  Button,
  Icon,
} from 'core-components';

const EditProfileComponent = (props) => {
  const {
    profileDetails,
    handleFirstNameChange,
    handleLastNameChange,
    handleEmailChange,
    handleOnClickEdit,
    handleMobileNumberChange,
    firstNameErrTxt,
    lastNameErrTxt,
    emailErrTxt,
    mobileNumberErrTxt,
    handleEditCancelClick,
  } = props;
  const { firstName, lastName, email, mobileNumber } = profileDetails;

  if (typeof email === 'undefined') {
    return <Spinner />;
  }

  return (
    <>
      <ul className='profile-detail-list-wrap list-unstyled mb-0 pl-0'>
        <li className='profile-detail-list p-3 d-flex align-items-center justify-content-between'>
          <div className='d-flex flex-column'>
            <Input
              type='text'
              invalid={firstNameErrTxt !== ''}
              onChange={handleFirstNameChange}
              value={firstName}
              className='w-100'
              placeholder='First name'
              required
            />
            {firstNameErrTxt && <div className='text-danger'>{firstNameErrTxt}</div>}
          </div>
          <div>
            <Input
              type='text'
              invalid={lastNameErrTxt !== ''}
              onChange={handleLastNameChange}
              value={lastName}
              className='w-100 ml-2'
              placeholder='Last name'
              required
            />
            {lastNameErrTxt && <div className='text-danger'>{lastNameErrTxt}</div>}
          </div>
        </li>
        <li className='profile-detail-list p-3 d-flex align-items-center justify-content-between'>
          <Label className='mb-0'>Email</Label>
          <Input
            type='text'
            invalid={emailErrTxt !== ''}
            onChange={handleEmailChange}
            value={email}
            placeholder='Email'
            className='w-50'
            required
          />
        </li>
        <li className='profile-detail-list p-3 d-flex align-items-center justify-content-between'>
          <Label className='mb-0'>Mobile</Label>
          <Input
            type='text'
            invalid={mobileNumberErrTxt !== ''}
            onChange={handleMobileNumberChange}
            value={mobileNumber}
            placeholder='Mobile number'
            className='w-50'
            required
          />
        </li>
        <li className='p-3 d-flex align-items-center justify-content-end'>
          <Button
            onClick={handleOnClickEdit}
            color='primary'
            className='px-4 py-2'
          >
            Update
          </Button>
          <Button
            onClick={handleEditCancelClick}
            color='secondary'
            className='px-4 py-2 text-decoration-none ml-2'
          >
            Cancel
          </Button>
        </li>
      </ul>
    </>
  );
};
EditProfileComponent.propTypes = {
  profileDetails: PropTypes.shape().isRequired,
  handleEmailChange: PropTypes.string.isRequired,
  handleFirstNameChange: PropTypes.string.isRequired,
  handleLastNameChange: PropTypes.string.isRequired,
  handleMobileNumberChange: PropTypes.string.isRequired,
  handleOnClickEdit: PropTypes.func.isRequired,
  firstNameErrTxt: PropTypes.string.isRequired,
  lastNameErrTxt: PropTypes.string.isRequired,
  emailErrTxt: PropTypes.string.isRequired,
  mobileNumberErrTxt: PropTypes.string.isRequired,
  handleEditCancelClick: PropTypes.func.isRequired,
};

export default React.memo(EditProfileComponent);
