const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

const getUserRole = () => {
  let userRole;

  if (localStorage.getItem('userDetails')) {
    userRole = JSON.parse(localStorage.getItem('userDetails')).role;
  } else {
    userRole = '';
  }
  return userRole;
};

export { getAccessToken };
export { getUserRole };
