import produce from 'immer';

import { SUPER_ADMIN_MODAL_STATUS } from 'redux/superAdmin/modalStates/actionConstants';

export const initialState = {
  profileModal: false,
};

const superAdminModalStatusReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SUPER_ADMIN_MODAL_STATUS.PROFILE_MODAL:
      state.profileModal = payload;
      break;
    default:
      return state;
  }
});

export default superAdminModalStatusReducer;
