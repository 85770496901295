import { call, put, takeLatest } from 'redux-saga/effects';

import {
  adminSignupFailureAction,
  adminSignupSuccessAction,
} from 'redux/admin/signup/action';
import { adminSignupPostApi } from 'redux/admin/signup/api';
import { ADMIN_SIGNUP } from 'redux/admin/signup/actionConstants';

// worker saga
export function* adminSignupSaga(action) {
  try {
    const response = yield call(adminSignupPostApi, action.payload);

    yield put(adminSignupSuccessAction(response.data));
  } catch (error) {
    yield put(adminSignupFailureAction(error.response?.data));
  }
}

// watcherSaga
export default function* adminSaga() {
  yield takeLatest(ADMIN_SIGNUP.ADMIN_SIGNUP_REQUEST_ACTION, adminSignupSaga);
}
