import moment from 'moment';

export const defaultDriveStartDate = () => {
  const currentDate = new Date();
  return moment(currentDate).add(15, 'm').set('seconds', 0).toDate();
};

export const defaultDriveEndDate = () => {
  const currentDate = new Date();
  return moment(currentDate).add(1, 'd').add(15, 'm').set('seconds', 0)
    .toDate();
};

export const defaultCopyDriveStartDate = () => {
  const currentDate = new Date();
  return moment(currentDate).add(1, 'd').set('seconds', 0).toDate();
};

export const defaultCopyDriveEndDate = () => {
  const currentDate = new Date();
  return moment(currentDate).add(2, 'd').set('seconds', 0)
    .toDate();
};
