import React from 'react';
import PropTypes from 'prop-types';

import {
  Input,
} from 'core-components';
import { ImagePopUpModal } from 'shared-components';

import './MCQComponent.scss';

function MCQComponent({
  handleSelectedProblemChange,
  checkedAns,
  questions,
  selectedQuestion: selectedQuestionIndex,
  showImageModal,
  onImageClick,
  imageUrl,
  alternateName,
}) {
  const selectedQuestion = questions[selectedQuestionIndex - 1];
  const questionImageUrl = selectedQuestion?.image_url;

  return (
    <div className='mcq-component-wrapper flex-1 px-2 py-4 scroll-y'>
      <div className='px-4'>
        <p className='question-wrapper mb-2'>Question</p>
        <div className='mb-0'>
          <pre>{selectedQuestion?.description}</pre>
        </div>
      </div>
      {questionImageUrl && (
        <div
          className='d-flex align-items-center justify-content-center mt-4 question-diagram-wrap'
          onClick={() => {
            onImageClick(questionImageUrl, 'Question Image');
          }}
          role='button'
          tabIndex={0}
        >
          <img
            className='image-wrapper p-2'
            src={questionImageUrl}
            alt='Question Img'
          />
        </div>
      )}
      <div className='question-text-wrap px-4 mt-4'>
        <p className='mb-0 position-relative'>Answer</p>
      </div>
      <div className='p-4 d-flex flex-column options-item-wrap'>
        {selectedQuestion?.options.map((item, index) => {
          const {
            image_url: optionImageUrl,
            id,
            is_multi_select: isMultiSelect,
            description,
          } = item;
          let isOptionChecked = checkedAns[selectedQuestion.id]?.includes(id);
          isOptionChecked = isOptionChecked === undefined ? false : isOptionChecked;
          const imageQues = optionImageUrl ? 'my-3' : 'd-flex align-items-center my-3';
          const activeStyle = optionImageUrl ? 'my-3 active-option d-flex flex-column' : 'my-3 active-option d-flex';
          const activeClassName = isOptionChecked ? activeStyle : imageQues;
          const isImageOption = optionImageUrl ? 'd-flex flex-option2 options-wrapper' : 'd-flex options-wrapper';

          return (
            <div className={isImageOption}>
              <div className={activeClassName}>
                <div className='d-flex align-items-center'>
                  {selectedQuestion.is_multi_select ? (
                    <Input
                      type='checkbox'
                      name='check'
                      id='exampleCheck'
                      checked={
                        isOptionChecked
                      }
                      className='input-checkbox mt-0 mr-3'
                      value={id}
                      onChange={
                        (e) => handleSelectedProblemChange(
                          e,
                          selectedQuestion?.id,
                          true, // as 'selectedQuestion.is_multi_select' is checked
                          // before this block sending true instead of
                          // 'selectedQuestion.is_multi_select'
                        )
                      }
                    />
                  ) : (
                    <>
                      <input
                        type='radio'
                        id='exampleCheck'
                        checked={
                          isOptionChecked || false
                        }
                        className='input-radio-button mt-0'
                        style={{ width: 'auto' }}
                        value={id}
                        onChange={
                          (e) => handleSelectedProblemChange(
                            e,
                            selectedQuestion?.id,
                            isMultiSelect,
                          )
                        }
                      />
                      <span className='ml-1'>
                        {String.fromCharCode(index + 65)}.
                      </span>
                    </>
                  )}
                </div>
                <div>
                  <span className='mx-2 ml-3'>
                    {description}
                  </span>
                  {optionImageUrl && (
                    <div
                      className='options-diagram-wrapper'
                      onClick={() => {
                        onImageClick(optionImageUrl, 'Option Image');
                      }}
                      role='button'
                      tabIndex={0}
                    >
                      <img
                        className='p-2 image-wrapper'
                        src={optionImageUrl}
                        alt='Option Img'
                      />
                    </div>
                  )}
                </div>
              </div>
              {showImageModal && (
                <ImagePopUpModal
                  showImageModal={showImageModal}
                  toggleImageModalVisibility={onImageClick}
                  imageUrl={imageUrl}
                  alternateName={alternateName}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

MCQComponent.propTypes = {
  handleSelectedProblemChange: PropTypes.func.isRequired,
  checkedAns: PropTypes.shape().isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedQuestion: PropTypes.number.isRequired,
  showImageModal: PropTypes.bool.isRequired,
  onImageClick: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  alternateName: PropTypes.string.isRequired,
};

export default React.memo(MCQComponent);
