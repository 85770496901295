import produce from 'immer';

import { ORGANIZATION_LIST } from 'redux/superAdmin/organizationList/actionContants';

export const initialState = {
  organization: [],
  totalOrganization: 0,
  totalpages: 1,
  isOrgListLoading: false,
  isOrganizationSuccess: false,
  isOrganizationError: false,
  addCreditsLoading: false,
  offset: 0,
  orgListErrorMsg: '',
};

const organizationListReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ORGANIZATION_LIST.ORGANIZATION_LIST_REQUEST_ACTION:
      state.isOrgListLoading = true;
      state.organization = payload.organization;
      state.offset = payload.offset;
      state.orgListErrorMsg = '';
      break;
    case ORGANIZATION_LIST.ORGANIZATION_LIST_SUCCESS_ACTION:
      state.isOrganizationSuccess = true;
      state.isOrgListLoading = false;
      state.organization = [...state.organization, ...payload.organizations];
      state.totalOrganization = payload.total_organizations;
      state.totalpages = payload.pages;
      break;
    case ORGANIZATION_LIST.ORGANIZATION_LIST_FAILURE_ACTION:
      state.isOrganizationError = true;
      state.isOrgListLoading = false;
      state.orgListErrorMsg = payload;
      break;
    case ORGANIZATION_LIST.CLEAR_ORGANIZATION:
      return { ...initialState };
    case ORGANIZATION_LIST.SET_ORGANIZATION_OFFSET:
      return { ...state, offset: payload };
    default:
      return state;
  }
});
export default organizationListReducer;
