import moment from 'moment';

export const isStartTimeIsPast = (date) => (!!(date && moment(date).isAfter()));

export const getDriveType = (date) => {
  if (date) {
    if (isStartTimeIsPast(date)) {
      return 'upcoming';
    }
    return 'ongoing';
  }
  return 'upcoming';
};
