import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import OptionComponent from 'modules/admin/option/OptionComponent';

import { imageFileToUrl, isImageFile } from 'utils';

import { imageFormatErrorMessage } from 'constants/messageConstants';

const OptionContainer = ({ formik, handleDeleteClick }) => {
  const { isQuestionCreateSuccess } = useSelector((state) => state.createQuestionReducer);

  const [showImageModal, setShowImageModal] = useState(false);
  const [optionPopUpImage, setOptionPopUpImage] = useState('');

  const toggleImageModalVisibility = () => {
    setShowImageModal(!showImageModal);
  };

  const [selectedImageOptionId, setSelectedImageOptionId] = useState();

  const handleUploadImage = (e, mcqOption, index) => {
    const file = e.target.files[0];
    formik.setFieldValue(`mcqOptions[${index}].optionImageError`, '');

    if (file) {
      if (isImageFile(file.name)) {
        formik.setFieldValue(`mcqOptions[${index}].optionDiagram`, file);
        setSelectedImageOptionId(mcqOption.id);

        // image preview for options
        formik.setFieldValue(`mcqOptions[${index}].imagePreview`,
          imageFileToUrl(file));
      } else {
        formik.setFieldValue(`mcqOptions[${index}].optionImageError`, imageFormatErrorMessage);
      }
    }
  };

  const onImageClick = (mcqOption) => {
    toggleImageModalVisibility();
    setOptionPopUpImage(mcqOption.imagePreview || mcqOption.optionDiagram);
  };

  const getOptions = (mcqOptions) => mcqOptions.filter((option) => {
    return !option.isDelete;
  });

  const isOptionsPresent = (mcqOptions) => {
    return mcqOptions.length > 1 && getOptions(mcqOptions).length > 1;
  };

  return (
    <OptionComponent
      handleDeleteClick={handleDeleteClick}
      showImageModal={showImageModal}
      toggleImageModalVisibility={toggleImageModalVisibility}
      formik={formik}
      isQuestionCreateSuccess={isQuestionCreateSuccess}
      selectedImageOptionId={selectedImageOptionId}
      isOptionsPresent={isOptionsPresent}
      handleUploadImage={handleUploadImage}
      onImageClick={onImageClick}
      optionPopUpImage={optionPopUpImage}
    />
  );
};

OptionContainer.propTypes = {
  formik: PropTypes.shape().isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
};

export default React.memo(OptionContainer);
