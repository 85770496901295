import * as yup from 'yup';

export const schema = yup.object().shape({
  name: yup.string()
    .nullable()
    .trim()
    .min(5)
    .max(50)
    .required('Name is a required field'),
  description: yup.string()
    .nullable()
    .trim()
    .min(10)
    .max(350)
    .required('Description is a required field'),
  contacts: yup.string()
    .nullable()
    .required()
    .matches(/^[0-9]+$/, 'Invalid Mobile Number')
    .min(10, 'Invalid Mobile Number')
    .max(10, 'Invalid Mobile Number'),
  credits: yup.number().typeError('Credits is a required field')
    .integer()
    .required()
    .min(0, 'Credits must be greater than or equal to 0')
    .max(10000, 'Credits must be less than or equal to 10000'),
});
