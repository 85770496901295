export const onlyCharacterAndNumber = (str) => {
  return str
    .split('')
    .filter((ch) => {
      const code = ch.charCodeAt(0);
      return (
        (code > 47 && code < 58) || // numeric (0-9)
        (code > 64 && code < 91) || // upper alpha (A-Z)
        (code > 96 && code < 123) // lower alpha (a-z)
      );
    })
    .join('');
};

export const checkDuplicate = (stringA, stringB) => {
  const processedStringA = onlyCharacterAndNumber(stringA).toLowerCase();
  const processedStringB = onlyCharacterAndNumber(stringB).toLowerCase();

  return processedStringA === processedStringB;
};
