import produce from 'immer';

import { QUESTION_TABS } from 'redux/admin/questionTabs/actionContants';

import { CODING_TAB } from 'constants/appConstants';

const { SET_ACTIVE_TAB, RESET_ACTIVE_TAB } = QUESTION_TABS;

export const initialState = {
  activeTab: CODING_TAB,
};

const getActiveTab = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ACTIVE_TAB:
      state.activeTab = payload;
      break;
    case RESET_ACTIVE_TAB:
      state.activeTab = CODING_TAB;
      break;
    default:
      return state;
  }
});
export default getActiveTab;
