import produce from 'immer';

import { CODE_BACKUP } from 'constants/actionConstants';

export const initialState = {
  backupCode: {},
  errorMessage: '',
  isError: false,
  isCodeBackup: false,
  isSuccess: false,
};

const codeBackupReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CODE_BACKUP.FETCH_CODE_ACTION:
      return {
        ...initialState,
        backupCode: { ...payload.data.code },
        isCodeBackup: payload.isCodeBackup,
        isSuccess: true,
      };
    case CODE_BACKUP.FETCH_CODE_REQUEST:
      state.isSuccess = false;
      break;
    case CODE_BACKUP.SET_ERROR_MESSAGE:
      return { ...state, isError: true, errorMessage: payload.message };
    default:
      return state;
  }
});

export default codeBackupReducer;
