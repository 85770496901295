/* eslint-disable prefer-template */
import React, { useEffect } from 'react';
import Webcam from 'react-webcam';

import {
  getPresignedUrlApi,
  putSaveScreenshotApi,
} from 'shared-components/Header/Webcam/WebcamCapture/apis';

import { webCamAlertMessage } from 'constants/messageConstants';

const WebcamCapture = () => {
  const videoConstraints = {
    width: 177,
    height: 70,
    facingMode: 'user',
  };
  const webcamRef = React.useRef(null);

  const saveScreenshot = async (imageSrc) => {
    // TODO
    // api without saga 38
    const presignedUrl = await getPresignedUrlApi();
    putSaveScreenshotApi(presignedUrl, imageSrc);
  };

  // this useEffect will take a snapshot after 3 sec when test start's
  useEffect(() => {
    setTimeout(() => {
      if (webcamRef.current != null) {
        const imageSrc = webcamRef.current.getScreenshot({
          width: 500,
          height: 500,
        });
        if (!imageSrc) {
          alert(webCamAlertMessage);
        } else {
          saveScreenshot(imageSrc);
        }
      }
    }, 3000);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (webcamRef.current != null) {
        const imageSrc = webcamRef.current.getScreenshot({
          width: 500,
          height: 500,
        });
        if (!imageSrc) {
          alert(webCamAlertMessage);
        } else {
          saveScreenshot(imageSrc);
        }
      }
    }, 1000 * 60 * 10);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Webcam
        audio={false}
        ref={webcamRef}
        height={100 + '%'}
        width={100 + '%'}
        screenshotFormat='image/jpeg'
        videoConstraints={videoConstraints}
      />
    </>
  );
};

export default WebcamCapture;
