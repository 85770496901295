import axios from 'axios';

import { SERVER_URL } from 'constants/appConstants';

export const candidateReportGetApi = (token) => {
  return axios({
    method: 'get',
    url: `${SERVER_URL}/report/${token}`,
  });
};
