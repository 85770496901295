/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import './countPill.scss';

const CountPill = ({ count, countType }) => {
  return (
    <>
      <div className='count-pill-box d-flex flex-column h-100 py-1'>
        <span>{count}</span>
        <label className='mb-0'>{countType}</label>
      </div>
    </>
  );
};

CountPill.propTypes = {
  count: PropTypes.number.isRequired,
  countType: PropTypes.string.isRequired,
};
export default CountPill;
