import produce from 'immer';
import { initialState } from 'reducers/userDriveReducer';

export const reducer = produce((state, action) => {
  const { type, payload } = action;
  const defaultCnt = 0;
  switch (type) {
    case 'name':
      state.data.Assessment.name = payload;
      break;
    case 'description':
      state.data.Assessment.description = payload;
      break;
    case 'easy_problem_count':
      state.problemCountErrTxt = '';
      state.easyProblemErrTxt = '';
      state.data.Assessment.easy_problem_count = payload;
      break;
    case 'medium_problem_count':
      state.problemCountErrTxt = '';
      state.mediumProblemErrTxt = '';
      state.data.Assessment.medium_problem_count = payload;
      break;
    case 'hard_problem_count':
      state.problemCountErrTxt = '';
      state.hardProblemErrTxt = '';
      state.data.Assessment.hard_problem_count = payload;
      break;
    case 'easy_mcq_count':
      state.problemCountErrTxt = '';
      state.easyMcqErrTxt = '';
      state.data.Assessment.easy_mcq_count = payload;
      break;
    case 'medium_mcq_count':
      state.problemCountErrTxt = '';
      state.mediumMcqErrTxt = '';
      state.data.Assessment.medium_mcq_count = payload;
      break;
    case 'hard_mcq_count':
      state.problemCountErrTxt = '';
      state.hardMcqErrTxt = '';
      state.data.Assessment.hard_mcq_count = payload;
      break;
    case 'contacts':
      state.data.Assessment.contacts = payload;
      break;
    case 'mcq_tags':
      state.data.Assessment.mcq_tags = payload;
      break;
    case 'nameErrTxt':
      state.nameErrTxt = payload;
      break;
    case 'descriptionErrTxt':
      state.descriptionErrTxt = payload;
      break;
    case 'problemCountErrTxt':
      state.problemCountErrTxt = payload;
      break;
    case 'contactsErrTxt':
      state.contactsErrTxt = payload;
      break;
    case 'easyProblemErrTxt':
      state.easyProblemErrTxt = payload;
      break;
    case 'mediumProblemErrTxt':
      state.mediumProblemErrTxt = payload;
      break;
    case 'hardProblemErrTxt':
      state.hardProblemErrTxt = payload;
      break;
    case 'easyMcqErrTxt':
      state.easyMcqErrTxt = payload;
      break;
    case 'mediumMcqErrTxt':
      state.mediumMcqErrTxt = payload;
      break;
    case 'hardMcqErrTxt':
      state.hardMcqErrTxt = payload;
      break;
    case 'resetCountErrs':
      state.easyProblemErrTxt = '';
      state.mediumProblemErrTxt = '';
      state.hardProblemErrTxt = '';
      state.easyMcqErrTxt = '';
      state.mediumMcqErrTxt = '';
      state.hardMcqErrTxt = '';
      break;
    case 'resetAssessmentData':
      state.data.Assessment.name = '';
      state.data.Assessment.description = '';
      state.data.Assessment.easy_problem_count = '';
      state.data.Assessment.medium_problem_count = '';
      state.data.Assessment.hard_problem_count = '';
      state.data.Assessment.easy_mcq_count = '';
      state.data.Assessment.medium_mcq_count = '';
      state.data.Assessment.hard_mcq_count = '';
      state.data.Assessment.contacts = [];
      state.data.Assessment.mcq_tags = [];
      state.nameErrTxt = '';
      state.descriptionErrTxt = '';
      state.problemCountErrTxt = '';
      state.contactsErrTxt = '';
      state.easyProblemErrTxt = '';
      state.mediumProblemErrTxt = '';
      state.hardProblemErrTxt = '';
      state.easyMcqErrTxt = '';
      state.mediumMcqErrTxt = '';
      state.hardMcqErrTxt = '';
      break;
    case 'Assessment':
      state.data.Assessment.name = payload.name;
      state.data.Assessment.description = payload.description;
      state.data.Assessment.easy_problem_count = payload.problem_count_hash?.easy || defaultCnt;
      state.data.Assessment.medium_problem_count = payload.problem_count_hash?.medium || defaultCnt;
      state.data.Assessment.hard_problem_count = payload.problem_count_hash?.hard || defaultCnt;
      state.data.Assessment.easy_mcq_count = payload.mcq_count_hash?.easy || defaultCnt;
      state.data.Assessment.medium_mcq_count = payload.mcq_count_hash?.medium || defaultCnt;
      state.data.Assessment.hard_mcq_count = payload.mcq_count_hash?.hard || defaultCnt;
      state.data.Assessment.mcq_tags = payload.problem_tag_list;
      state.data.Assessment.isParamAssessment = payload.is_param;
      break;
    case 'defaultContacts':
      state.data.Assessment.contacts = payload;
      break;
    case 'resetCurrentContacts':
      state.data.Assessment.contacts = [];
      break;
    case 'clear':
      return { ...initialState };
    default:
      return state;
  }
  return state;
});
