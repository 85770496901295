export const MCQ_BULK_UPLOAD_ACTIONS = {
  IMPORT_FILE_ACTION: 'IMPORT_FILE_ACTION',
  SET_FILE_NAME: 'SET_FILE_NAME',
  SET_FILE: 'SET_FILE',
  RESET_STATE: 'RESET_STATE',
  FILE_UPLOAD_SUCCESSFULLY: 'FILE_UPLOAD_SUCCESSFULLY',
  ACTION_FAILED: 'ACTION_FAILED',
  SET_FILE_PATH: 'SET_FILE_PATH',
  RESET_FILE_PATH: 'RESET_FILE_PATH',
  START_FILE_IMPORT: 'START_FILE_IMPORT',
  SET_FILE_NAME_ERROR: 'SET_FILE_NAME_ERROR',
  DOWNLOAD_FILE_SUCCESS_ACTION: 'DOWNLOAD_FILE_SUCCESS_ACTION',
};
