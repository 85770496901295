import produce from 'immer';
import { QUESTIONS_LIST } from 'redux/admin/questionsList/actionContants';

const {
  QUESTIONS_LIST_REQUEST_ACTION,
  QUESTIONS_LIST_SUCCESS_ACTION,
  QUESTIONS_LIST_FAILURE_ACTION,
  CLEAR_QUESTIONS_LIST,
} = QUESTIONS_LIST;

export const initialState = {
  questions: [],
  totalQuestions: 0,
  isQuestionsSuccess: false,
  isQuestionsLoading: false,
  isQuestionsError: false,
};

const questionsReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case QUESTIONS_LIST_REQUEST_ACTION:
      state.isQuestionsLoading = true;
      break;
    case QUESTIONS_LIST_SUCCESS_ACTION:
      state.isQuestionsSuccess = true;
      state.isQuestionsLoading = false;
      state.questions = payload.questions;
      state.totalQuestions = payload.total_questions;
      break;
    case QUESTIONS_LIST_FAILURE_ACTION:
      state.isQuestionsError = true;
      state.isQuestionsLoading = false;
      break;
    case CLEAR_QUESTIONS_LIST:
      return { ...initialState };
    default:
      return state;
  }
});
export default questionsReducer;
