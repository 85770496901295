import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { postRuleApi } from 'redux/admin/driveRules/api';

import { DRIVE_RULES } from 'redux/admin/driveRules/actionConstants';

export function* createRuleDriveSaga(action) {
  try {
    const response = yield call(postRuleApi, action.payload);
  } catch (error) {
    toast.error(error.response.data.message);
  }
}

export default function* ruleDriveWatcherSaga() {
  yield takeLatest(DRIVE_RULES.CREATE_RULE_REQUEST_ACTION, createRuleDriveSaga);
}
