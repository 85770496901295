import { ADMIN_SIGNUP } from 'redux/admin/signup/actionConstants';

export const adminSignupRequestAction = (payload) => ({
  type: ADMIN_SIGNUP.ADMIN_SIGNUP_REQUEST_ACTION,
  payload,
});

export const adminSignupSuccessAction = (payload) => ({
  type: ADMIN_SIGNUP.ADMIN_SIGNUP_SUCCESS_ACTION,
  payload,
});

export const adminSignupFailureAction = (payload) => ({
  type: ADMIN_SIGNUP.ADMIN_SIGNUP_FAILURE_ACTION,
  payload,
});

export const adminSignupResetAction = () => ({
  type: ADMIN_SIGNUP.ADMIN_SIGNUP_RESET_ACTION,
});
