export const DOWNLOAD_RESULT = {
  DOWNLOAD_RESULT_REQUEST_ACTION: 'DOWNLOAD_RESULT_REQUEST_ACTION',
  DOWNLOAD_RESULT_SUCCESS_ACTION: 'DOWNLOAD_RESULT_SUCCESS_ACTION',
  DOWNLOAD_RESULT_FAILURE_ACTION: 'DOWNLOAD_RESULT_FAILURE_ACTION',
  DOWNLOAD_ASSESSMENT_RESULT_REQUEST_ACTION: 'DOWNLOAD_ASSESSMENT_RESULT_REQUEST_ACTION',
  DOWNLOAD_ASSESSMENT_RESULT_SUCCESS_ACTION: 'DOWNLOAD_ASSESSMENT_RESULT_SUCCESS_ACTION',
  DOWNLOAD_ASSESSMENT_RESULT_FAILURE_ACTION: 'DOWNLOAD_ASSESSMENT_RESULT_FAILURE_ACTION',
  SET_DOWNLOAD_DRIVE_NAME_ACTION: 'SET_DOWNLOAD_DRIVE_NAME_ACTION',
};

export const ABSENT_CANDIDATES = {
  ABSENT_CANDIDATES_REQUEST_ACTION: 'ABSENT_CANDIDATES_REQUEST_ACTION',
  ABSENT_CANDIDATES_SUCCESS_ACTION: 'ABSENT_CANDIDATES_SUCCESS_ACTION',
  ABSENT_CANDIDATES_FAILURE_ACTION: 'ABSENT_CANDIDATES_FAILURE_ACTION',
  SET_DOWNLOAD_ASSESSMENT_NAME_ACTION: 'SET_DOWNLOAD_ASSESSMENT_NAME_ACTION',
};
