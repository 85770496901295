export const CustomThemeSelect = (theme) => ({
  ...theme,
  borderRadius: 5,
  colors: {
    ...theme.colors,
    primary25: '#666978',
    neutral80: 'white',
    neutral0: '#252836',
  },
});
export const CustomThemeMultiSelect = (theme) => ({
  ...theme,
  borderRadius: 5,
  colors: {
    ...theme.colors,
    primary25: '#666978',
    neutral80: 'black',
    neutral0: '#252836',
  },
});
