import { SERVER_URL } from 'constants/appConstants';
import { get } from '../apiHelper';

export const getTemplateListApi = (problemId) => {
  return get(`${SERVER_URL}admin/templates?problem_id=${problemId}`);
};

export const getCandidateTemplateListApi = ({ problemId, driveID }) => {
  return get(`${SERVER_URL}drives/${driveID}/templates?problem_id=${problemId}`);
};
