import produce from 'immer';

export const reducer = produce((state, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case 'email':
      return ({
        ...state,
        email: payload,
        emailError: '',
      });
    case 'emailError':
      return ({ ...state, emailError: payload });
    case 'resetSignUp':
      return ({
        ...state,
        email: '',
        emailError: '',
      });
    default:
      return state;
  }
});
