import React, { useMemo, createRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import {
  Row,
  Col,
  Spinner,
  Button,
} from 'core-components';
import SnapshotsComponent from 'modules/admin/snapshots/SnapshotsComponent';

import { getTestEndDate, getTestEndTime, getTestStartDate, getTestStartDateString, getTestStartTime } from 'utils/testDateTime';
import { getCaption } from 'utils/snapshotDataTime';
import ProblemDetails from './problemDetails';
import McqResultComponent from './mcqResultComponent';
import RunCodeModal from './runCodeModal';

import 'modules/admin/showCandidateList/ShowCandidateListComponent/showCandidateListComponent.scss';
import './report.scss';

const CandidateReportComponent = ({
  reportsAreLoading,
  reportData,
}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const toggle = () => {
    setShowModal(!showModal);
  };
  const {
    candidate,
    drive_name: driveName,
    time,
    score,
    problems_details: problemsDetails,
    mcqs_details: mcqsDetails,
    snapshots,
  } = reportData;
  const { first_name: firstName, last_name: lastName, email } = candidate;
  const {
    test_started: testStarted, test_finished: testFinished, alloted_time: allotedTime,
  } = time;
  const {
    percentage,
    obtained_score: obtainedScore,
    total_score: totalScore,
  } = score;

  const scrollDiv = createRef();
  const scrollDiv2 = createRef();
  const testStartDate = getTestStartDate(testStarted);
  const testEndDate = getTestEndDate(testFinished);
  const testStartDateString = getTestStartDateString(testStartDate);
  const testStartTime = getTestStartTime(testStartDate);
  const testEndTime = getTestEndTime(testEndDate);
  const snapshotsData = [];
  const [selectedProblem, setSelectedProblem] = useState({});

  const progressInstance = (
    <div className='progress_bar'>
      <Progress
        style={{
          background: '#B94FC2',
          width: `${percentage || 0}%`,
        }}
      >
        {`${percentage || 0}%`}
      </Progress>
    </div>
  );

  const scrollSmoothHandler = (e) => {
    e.current.scrollIntoView({ behavior: 'smooth' });
  };

  const renderProblemDetails = useMemo(() => {
    return problemsDetails.map((val, index) => {
      return (
        <div key={val.problem_id}>
          <Row className='px-3'>
            <ProblemDetails
              problemDetails={val}
              index={index + 1}
              key={val.problem_id}
              problems={problemsDetails}
            />
          </Row>
          <br />
        </div>
      );
    });
  }, []);

  snapshots.map((val) => {
    const { image_url: imageUrl, created_at: createdAt } = val;
    const caption = getCaption(createdAt);

    snapshotsData.push({
      src: imageUrl,
      thumbnail: imageUrl,
      thumbnailWidth: 250,
      thumbnailHeight: 250,
      caption,
      thumbnailCaption: caption,
      alt: firstName,
    });

    return (
      snapshotsData
    );
  });

  const handleOnExecuteClick = (problemDetails) => {
    setSelectedProblem({ ...problemDetails });
    toggle();
  };

  return (
    <>
      {reportsAreLoading ?
        <Spinner className='loader' /> : (
          <div className='candidate-section d-flex flex-column scroll-y'>
            <div className='candidate-section-head d-flex justify-content-between mb-4'>
              <div className='d-flex align-items-center my-2 candidate-title-box'>
                <div className='d-flex align-items-center mr-3'>
                  <h2 className='section-heading font-weight-bold mb-0 ml-3'>
                    Candidate Performance Report
                  </h2>
                </div>
                <div className='drive-title px-3'>
                  <span className='font-weight-bold'>
                    {firstName} {lastName}
                  </span>
                </div>
              </div>
            </div>
            <div className='candidate-list scroll-y table-responsive'>
              <div className='px-5' ref={scrollDiv2}>
                <Row className='py-1 px-3 headers'>
                  <Col><h5><b>Profile</b></h5></Col>
                  <Col className='col-auto'>
                    <Button
                      color='secondary'
                      outline
                      onClick={() => { scrollSmoothHandler(scrollDiv); }}
                    > Go to snapshots
                    </Button>
                  </Col>
                </Row>
                <div className='report-card'>
                  <Row>
                    <Col className='col-3'><b>Name</b></Col>
                    <Col>: {firstName} {lastName}</Col>
                  </Row>
                  <Row>
                    <Col className='col-3'><b>Email</b></Col>
                    <Col>: {email}</Col>
                  </Row>
                  <Row>
                    <Col className='col-3'><b>Test Name</b></Col>
                    <Col>: {driveName}</Col>
                  </Row>
                  <Row>
                    <Col className='col-3'><b>Test Taken on</b></Col>
                    <Col>: {testStartDateString}</Col>
                  </Row>
                  <Row>
                    <Col className='col-3'><b>Test Started On</b></Col>
                    <Col>: {testStartTime}</Col>
                  </Row>
                  <Row>
                    <Col className='col-3'><b>Test Completed On</b></Col>
                    <Col>: {testEndTime}</Col>
                  </Row>
                  <Row>
                    <Col className='col-3'><b>Alloted Time(in minutes)</b></Col>
                    <Col>: {allotedTime}</Col>
                  </Row>
                </div>
                <br />
                <div className='h4 report-card'>
                  <Row className='py-1 px-1'>
                    <Col className='col-3'><b>Raw Score</b></Col>
                    <Col>: {obtainedScore} / {totalScore}</Col>
                    <Col className='col-3'><b>Percentage Score</b></Col>
                    <Col>: {percentage || 0}%</Col>
                  </Row>
                </div>
                <br />
                <div className='report-card'>
                  <Row className='py-1 px-3'>
                    <h5><b>Overall Performance</b></h5>
                  </Row>
                  <Row className='py-1 px-3'>
                    {progressInstance}
                  </Row>
                </div>
              </div>
              <br />
              {problemsDetails.length > 0 && (
              <div className='px-5'>
                <Row className='py-1 px-3 headers'>
                  <h5><b>Summary</b></h5>
                </Row>
                <br />
                {problemsDetails.map((val, index) => (
                  <>
                    <Row className='px-3' key={val.problem_id}>
                      <ProblemDetails
                        problemDetails={val}
                        key={val.problem_id}
                        problems={problemsDetails}
                        handleOnExecuteClick={handleOnExecuteClick}
                      />
                    </Row>
                    <br />
                  </>
                ))}
              </div>
              )}
              {mcqsDetails.length > 0 && (
              <div className='px-5'>
                <Row className='py-1 px-3 headers'>
                  <h5><b>MCQ Result</b></h5>
                </Row>
                <br />
                <McqResultComponent
                  mcqResult={mcqsDetails}
                />
              </div>
              )}
              <div className='px-5' ref={scrollDiv}>
                <Row className='py-1 px-3 headers'>
                  <Col><h5><b>Snapshots</b></h5></Col>
                  <Col className='col-auto'>
                    <Button
                      color='secondary'
                      outline
                      onClick={() => { scrollSmoothHandler(scrollDiv2); }}
                    >Go back to top
                    </Button>
                  </Col>
                </Row>
                <br />
                <SnapshotsComponent
                  snapshotsData={snapshotsData}
                />
                <RunCodeModal
                  showModal={showModal}
                  toggle={toggle}
                  problemDetails={selectedProblem}
                />
              </div>
            </div>
          </div>
        )}
    </>
  );
};

CandidateReportComponent.propTypes = {
  reportData: PropTypes.shape().isRequired,
  reportsAreLoading: PropTypes.bool.isRequired,
};

export default React.memo(CandidateReportComponent);
