import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { createTestCaseSuccessAction, createTestCaseFailureAction } from 'redux/admin/testCase/action';
import { postTestCaseApi } from 'redux/admin/testCase/api';
import { CREATE_TESTCASE } from 'redux/admin/testCase/actionContants';

// TODO - remove this saga
// non useful
export function* postTestCase(action) {
  const { input, output, marks, problem_id } = action.payload;
  const data = {
    input,
    output,
    marks,
    problem_id,
  };
  try {
    const response = yield call(postTestCaseApi);
    yield put(createTestCaseSuccessAction({ message: response.message }));
  } catch (error) {
    const { data: responseData } = error.response;
    yield put(createTestCaseFailureAction(responseData));
    toast.error(responseData.message);
  }
}

export default function* testCaseWatcherSaga() {
  yield takeEvery(CREATE_TESTCASE.CREATE_TESTCASE_REQUEST_ACTION, postTestCase);
}
