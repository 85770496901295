import { useEffect, useReducer, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import InviteUserComponent from 'modules/admin/inviteUser/InviteUserComponent';

import reducer, {
  initialState,
} from 'modules/admin/inviteUser/InviteUserContainer/reducer';
import {
  sendEmailsApi,
  getUsersApi,
  getUserRole,
  putUserStatusApi,
} from 'modules/admin/inviteUser/InviteUserContainer/apis';

import queryStringHelper from 'utils/queryStringHelper';
import { getUserDetails } from 'utils';

const InviteUserContainer = () => {
  const userDetails = getUserDetails();
  const [loading, setLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [targetId, setTargetId] = useState();
  const [searchText, setSearchText] = useState('');
  const [lastSearchedText, setLastSearchedText] = useState('');
  const [usersData, setUsersData] = useReducer(reducer, initialState);

  const getUsers = async () => {
    const getQueryString = queryStringHelper({
      organization_id: userDetails.organization_id,
      search: searchText.trim(),
    });

    // TODO
    // api without saga 13
    const response = await getUsersApi(getQueryString);
    const { users, total_users: totalUsers } = response.data.data;
    if (users) {
      setUsersData({
        type: 'SET_USERS',
        payload: users,
      });
      setUsersData({
        type: 'SET_USERS_COUNT',
        payload: totalUsers,
      });
    }
  };

  useEffect(() => {
    if (searchText === '' && lastSearchedText !== '') {
      getUsers();
      setLastSearchedText('');
    }
  }, [searchText]);

  useEffect(async () => {
    getUsers();

    // TODO
    // api without saga 14
    const roleResponse = await getUserRole(userDetails.organization_id);
    const { roles } = roleResponse.data.data;
    if (roles) {
      setUsersData({
        type: 'SET_ROLES',
        payload: roles,
      });
    }
  }, []);

  const toggleConfirmation = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleUpdateClick = async (id) => {
    setTargetId(id);
  };

  const handleInvitationEmails = (event) => {
    setUsersData({
      type: 'USER_EXIST_FAILURE',
      payload: '',
    });
    setUsersData({ type: 'VALID_EMAIL', payload: event.target.value });
  };

  const validateEmail = (email) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!pattern.test(email)) {
      return false;
    }
    return true;
  };

  const handleSelectedRoleChange = useCallback(
    (event) => {
      const role = event;

      if (role !== '') {
        setUsersData({
          type: 'SET_ROLE_ERROR',
          payload: '',
        });
        setUsersData({
          type: 'SET_ROLE',
          payload: role,
        });
      } else {
        setUsersData({
          type: 'SET_ROLE_ERROR',
          payload: 'role empty',
        });
      }
    }, [],
  );

  const handleSendInvitation = async () => {
    const { email, role } = usersData;
    const roleId = role.value;

    if (role === '') {
      setUsersData({ type: 'SET_ROLE_ERROR', payload: 'Select role' });
    } else {
      setUsersData({ type: 'SET_ROLE', payload: role });
    }
    if (validateEmail(email)) {
      if (roleId !== undefined) {
        const data = {
          email,
          role: roleId,
        };
        setLoading(true);
        try {
          // TODO
          // api without saga 15
          const responseData = await sendEmailsApi(data);
          if (responseData.status === 200) {
            const { user } = responseData.data.data;
            setUsersData({
              type: 'EMAILS_SENT_SUCCESS',
              payload: responseData.data,
            });
            setLoading(false);
            setUsersData({
              type: 'SET_USER',
              payload: { userData: user },
            });
          }
        } catch (error) {
          setUsersData({
            type: 'EMAILS_SENT_FAILURE',
            payload: error.response.data.message,
          });
          setLoading(false);
        }
      } else {
        setUsersData({ type: 'SET_ROLE_ERROR', payload: 'Select role' });
      }
    } else if (email === '') {
      setUsersData({ type: 'SET_EMAIL_ERROR', payload: 'Enter email' });
    } else {
      setUsersData({ type: 'INVALID_EMAIL', payload: 'Invalid email' });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleOnUserStatusUpdate = useCallback(
    async (id, isUserActive) => {
      try {
        const { users } = usersData;
        const data = {
          id,
          is_active: isUserActive,
        };

        // TODO
        // api without saga 16
        const result = await putUserStatusApi(data);
        const updatedUsers = result.data.data.user;
        // this will return first inactive user index.
        const firstInactiveUserIndex = users.findIndex((x) => !x.is_active);

        if (result.status === 200) {
          toast.success(result.data.message);
          // if all the user are active then send -1,so it will add the inctive at the end.
          setUsersData({
            type: 'UPDATE_USER_STATUS',
            payload: {
              inactiveUser:
                firstInactiveUserIndex === -1
                  ? users.length
                  : firstInactiveUserIndex,
              updatedUsers,
              status: isUserActive,
            },
          });
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }, [usersData.users],
  );

  const handleOnChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, [searchText]);

  const handleUserSearchClick = useCallback(() => {
    if (searchText && searchText !== lastSearchedText) {
      getUsers();
      setLastSearchedText(searchText.trim());
    }
  }, [searchText, lastSearchedText, getUsers]);

  const handleUserSearchClearClick = () => {
    setSearchText('');
  };

  return (
    <>
      <InviteUserComponent
        handleInvitationEmails={handleInvitationEmails}
        handleSendInvitation={handleSendInvitation}
        loading={loading}
        usersData={usersData}
        handleSelectedRoleChange={handleSelectedRoleChange}
        handleSubmit={handleSubmit}
        handleOnUserStatusUpdate={handleOnUserStatusUpdate}
        toggleConfirmation={toggleConfirmation}
        confirmationModal={confirmationModal}
        handleUpdateClick={handleUpdateClick}
        targetId={targetId}
        handleOnChange={handleOnChange}
        handleUserSearchClick={handleUserSearchClick}
        handleUserSearchClearClick={handleUserSearchClearClick}
        searchText={searchText}
      />
    </>
  );
};

export default InviteUserContainer;
