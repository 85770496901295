import { call, takeLatest, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { fetchMcqs, mcqSubmissionPostApi, savedQuestions, finalSubmissionMCQTest } from 'apis/mcqsApi';
import { changeSection } from 'actions/sectionStatusAction';
import { mcqQuestionsSuccess, saveMCQAnswerSuccess } from 'actions/mcqQuestionsActions';

import { MCQ_QUESTION } from 'constants/actionConstants';
import local from 'utils/local';

// worker saga
export function* getQuestions(action) {
  try {
    const response = yield call(fetchMcqs);
    const savedQuestionsList = yield call(savedQuestions);
    yield put(mcqQuestionsSuccess(response.data.data));
    yield put(saveMCQAnswerSuccess(savedQuestionsList.data.data.questions_submissions));
    yield put(changeSection(1));
  } catch (error) {
    toast.error(error.response.data.message);
  }
}

export function* saveQuestion(action) {
  try {
    const data = {
      token: local.getItem('authToken'),
      question_id: Object.keys(action.payload)[0],
      selected_options: Object.values(action.payload)[0],
    };
    yield call(mcqSubmissionPostApi, data);
    yield put(saveMCQAnswerSuccess(action.payload));
  } catch (error) {
    toast.error(error.response.data.message);
  }
}

export function* finalSubmission() {
  try {
    const data = {
      token: local.getItem('authToken'),
    };
    yield call(finalSubmissionMCQTest, data);
  } catch (error) {
    toast.error(error.response?.data.message);
  }
}

// watcher saga
export default function* mcqQuestionSaga() {
  yield takeLatest(MCQ_QUESTION.MCQ_QUESTION_REQUEST_ACTION, getQuestions);
  yield takeLatest(MCQ_QUESTION.SAVE_QUESTION_REQUEST_ACTION, saveQuestion);
  yield takeLatest(MCQ_QUESTION.FINAL_MCQ_SUBMISSION, finalSubmission);
}
