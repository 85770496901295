import React from 'react';
import PropTypes from 'prop-types';

import {
  Input,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Spinner,
  Toast,
} from 'core-components';
import { Link } from 'react-router-dom';
import './signupCard.scss';

const SignupCard = (props) => {
  const {
    handleSubmit,
    signUpState,
    isError,
    isLoading,
    errorMessage,
    handleEmailChange,
    successMessage,
  } = props;

  const { email, emailError } = signUpState;

  return (
    <div className='login-card-box position-absolute'>
      <h2 className='font-weight-normal'>Welcome to SparKode</h2>
      <Form className='login-form d-flex flex-column h-100 scroll-y'>
        <div className='border-dark border-bottom'>
          <h2 className='text-center'>
            Sign up
          </h2>
        </div>
        <FormGroup className='mt-5'>
          <Label className=' font-weight-normal'>Enter Work Email</Label>
          <Input
            id='exampleEmail'
            type='email'
            placeholder='example@company.com'
            value={email}
            onChange={handleEmailChange}
            invalid={emailError !== ''}
          />
          <FormFeedback type='invalid' target='exampleEmail'>
            {emailError}
          </FormFeedback>
        </FormGroup>
        <FormGroup className='mt-2 d-flex justify-content-center'>
          <Button
            className='w-75 border-0'
            onClick={handleSubmit}
            color='primary'
          >
            {isLoading ? <Spinner size='sm' color='light' /> : <>Get Signup Link</>}
          </Button>
        </FormGroup>
        <div className='text-center text-warning '>
          {errorMessage}
        </div>
        <div className='text-center text-warning '>
          {successMessage}
        </div>
        <div className='mt-5 login-details-box d-flex'>
          Already have an account?&nbsp;&nbsp;&nbsp;
          <Link to='/' className='forgot-password-link text-center text-underline'>
            Login
          </Link>
        </div>
      </Form>
    </div>
  );
};

SignupCard.defaultProps = {
  successMessage: '',
  errorMessage: '',
};

SignupCard.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  signUpState: PropTypes.objectOf(PropTypes.any).isRequired,
  isError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  handleEmailChange: PropTypes.func.isRequired,
  successMessage: PropTypes.string,
};

export default React.memo(SignupCard);
