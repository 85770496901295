/* eslint-disable react/jsx-tag-spacing */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ProblemDetailsComponent from 'modules/admin/problemDetails/ProblemDetailsComponent';
import {
  Alert,
  Spinner,
  Table,
  Icon,
} from 'core-components';
import { get } from 'redux/admin/apiHelper';

import { SERVER_URL } from 'constants/appConstants';
import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';

import 'modules/admin/testCase/TestCaseComponent/testCase.css';
import './style.css';
import './problemDetailsContainer.scss';
import { ImagePopUpModal } from 'shared-components';

const ProblemDetailsContainer = () => {
  const history = useHistory();
  const [problemDetails, setProblemDetails] = useState();
  const [problemIsLoading, setProblemIsLoading] = useState(true);

  const [testCaseLoading, setTestCaseLoading] = useState(true);
  const [showImageModal, setShowImageModal] = useState(false);

  const [templateDetails, setTemplateDetails] = useState([]);
  const [templatesLoading, setTemplatesLoading] = useState(true);
  const { problemId } = useParams();

  useEffect(() => {
    async function getProblemDetails() {
      try {
        let candidateLoading = false;
        // TODO
        // api without saga 17
        await get(`${SERVER_URL}admin/problems/${problemId}`)
          .then((response) => {
            setProblemDetails(response.data.data);
          })
          .catch((error) => {
            candidateLoading = true;
            setProblemIsLoading(candidateLoading);
            return <Alert className='danger'> {error} </Alert>;
          });
      } catch (e) {
        <Alert color='API Call Failed '> {e}</Alert>;
      }
    }
    getProblemDetails();

    async function getTemplates() {
      try {
        let templateListLoading = false;
        // TODO
        // api without saga 19
        await get((`${SERVER_URL}admin/templates?problem_id=${problemId}`))
          .then((response) => {
            setTemplateDetails(response.data.data.templates);
          })
          .catch((error) => {
            templateListLoading = true;
            setTemplatesLoading(templateListLoading);
            return <Alert className='danger'> {error} </Alert>;
          });
      } catch (e) {
        <Alert color='API Call Failed '> {e}</Alert>;
      }
    }
    getTemplates();
  }, [problemIsLoading, testCaseLoading, templatesLoading]);

  const finishProblemDetails = useCallback(() => {
    history.push(ROUTES.ADMIN + ADMIN_ROUTES.PROBLEMS);
  });

  const renderTestCases = useCallback(() => {
    const { test_cases: testCases } = problemDetails.problem;
    return testCases.map((val) => {
      const { id, input, output, marks } = val;
      return (
        <tr key={id}>
          <td>{id}</td>
          <td className='input'>{input}</td>
          <td className='output'>{output}</td>
          <td>{marks}</td>
        </tr>
      );
    });
  }, [problemDetails]);

  const renderTemplates = useCallback(() => {
    return templateDetails.map((val) => {
      const { code, language, id } = val;
      return (
        <div key={id} className='d-flex'>
          <div className='w-100 template-info-box d-flex pl-3 py-3'>
            <div className='d-flex problemDescription text-white w-100'>
              {language}
            </div>
            <div className='d-flex problemDescription text-white w-100'>
              {code}
            </div>
          </div>
        </div>
      );
    });
  });

  const onClickEditProblem = () => {
    history.push(`/admin/problem/${problemId}/edit`);
  };

  const { title, description, image_url: problemImage } = problemDetails?.problem || {};

  const toggleImageModalVisibility = () => {
    setShowImageModal(!showImageModal);
  };

  const renderProblemDetails = useCallback(() => {
    if (typeof problemDetails === 'undefined') {
      return <Spinner />;
    }

    return (
      <div className='problem-details-section d-flex h-100'>
        <div className='problem-description-wrap pl-4 pr-1 py-3'>
          <div className='problem-details-section-head'>
            <div className='d-flex align-items-center'>
              <Icon
                name='left'
                className='back-page-arrow font-weight-bold'
                onClick={finishProblemDetails}
              />
              <h2 className='section-heading font-weight-bold mb-0 ml-3'>
                Problem Details
              </h2>
            </div>
            <div className='description-wrapper d-flex flex-column'>
              <h5 className='font-weight-bold'>
                {title}
              </h5>
              <span className='description-label mb-3 d-block'>
                Problem Description
              </span>
              {problemImage && (
                <div
                  onClick={toggleImageModalVisibility}
                  role='button'
                  tabIndex={0}
                >
                  <img
                    className='w-100'
                    src={problemImage}
                    alt='Upload Question Img'
                  />
                </div>
              )}
              <ImagePopUpModal
                showImageModal={showImageModal}
                toggleImageModalVisibility={toggleImageModalVisibility}
                imageUrl={problemImage}
                alternateName='Question Image'
              />
              <div className='scroll-y'>
                <p className='mt-3'>{description}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='problem-test-case-wrap scroll-y'>
          <div className='d-flex justify-content-end align-items-center mb-2'>
            <div className='edit-icon-box'>
              <Icon
                name='pen'
                onClick={onClickEditProblem}
                className='pen-icon'
                title='Edit Problem'
              />
            </div>
          </div>
          <div className='test-case-wrapper'>
            <h6 className='font-weight-bold px-3'>Test Cases</h6>
            <div className='table-responsive'>
              <Table className='sp-table test-cases-table'>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Input</th>
                    <th>Output</th>
                    <th>Marks</th>
                  </tr>
                </thead>
                <tbody>{renderTestCases()}</tbody>
              </Table>
            </div>
          </div>
          <div className='test-case-wrapper'>
            <h6 className='font-weight-bold px-3'>Templates</h6>
            <div className='table-responsive'>
              {renderTemplates()}
            </div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <ProblemDetailsComponent renderProblemDetails={renderProblemDetails} />
  );
};

export default React.memo(ProblemDetailsContainer);
