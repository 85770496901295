import produce from 'immer';

import { ADMIN_FORGOT_PASSWORD } from 'redux/admin/forgotPassword/actionConstants';

export const initialState = {
  errorMessage: '',
  successMessage: '',
  isError: false,
  isLoading: false,
};

const forgotPasswordReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_FORGOT_PASSWORD.ADMIN_FORGOT_PASSWORD_SUCCESS_ACTION:
      return {
        ...state,
        isError: false,
        isLoading: false,
        successMessage: payload.message,
      };
    case ADMIN_FORGOT_PASSWORD.ADMIN_FORGOT_PASSWORD_FAILURE_ACTION:
      return {
        ...state,
        isError: true,
        isLoading: false,
        errorMessage: payload.message,
      };
    case ADMIN_FORGOT_PASSWORD.ADMIN_FORGOT_PASSWORD_REQUEST_ACTION:
      return {
        ...initialState,
        isLoading: true,
      };
    case ADMIN_FORGOT_PASSWORD.ADMIN_FORGOT_PASSWORD_RESET_ACTION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
});

export default forgotPasswordReducer;
