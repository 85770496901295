/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Input,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Card,
  CardBody,
  Row,
  Col,
  Toast,
  ToastHeader,
  Label,
  Spinner,
  Container,
} from 'core-components/index';
import RulesContainer from 'containers/RulesContainer';

import './infoComponent.css';
import Loading from 'shared-components/Loading';
import { RulesList, SiteLogo } from 'shared-components';
import './userProfileComponent.scss';

const UserProfileComponent = (props) => {
  const {
    handleFirstNameChange,
    handleLastNameChange,
    handleMobileChange,
    handleSubmit,
    firstNameIsValid,
    lastNameIsValid,
    mobileIsValid,
    result,
    rulesChecked,
    setRulesChecked,
  } = props;

  return (
    <div className='container'>
      <Form className='candidate-login-form-content'>
        <SiteLogo className='mb-4' />
        <div className='candidate-login-form-wrap d-flex'>
          <div className='candidate-login-form-col py-4 pl-5'>
            <h6 className='mb-3'>Please fill below details</h6>
            <div className='form-fields'>
              <div className='d-flex form-group-col'>
                <FormGroup>
                  <Label>First Name</Label>
                  <Input
                    className='shadow'
                    onChange={handleFirstNameChange}
                    invalid={!firstNameIsValid.valid}
                    placeholder=''
                  />

                  <FormFeedback className='text-center'>
                    {firstNameIsValid.message}
                  </FormFeedback>
                </FormGroup>

                <FormGroup>
                  <Label>Last Name</Label>
                  <Input
                    className='shadow'
                    onChange={handleLastNameChange}
                    invalid={!lastNameIsValid.valid}
                    placeholder=''
                  />

                  <FormFeedback className='text-center'>
                    {lastNameIsValid.message}
                  </FormFeedback>
                </FormGroup>
              </div>
              <FormGroup>
                <Label>Mobile</Label>
                <Input
                  className='shadow'
                  onChange={handleMobileChange}
                  invalid={!mobileIsValid.valid}
                  placeholder=''
                />

                <FormFeedback className='text-center'>
                  {mobileIsValid.message}
                </FormFeedback>
              </FormGroup>
            </div>
          </div>
          <div className='candidate-login-rules-col'>
            <div className='rules-list-wrapper position-absolute d-flex flex-column ml-5 mt-4'>
              <h4 className='font-weight-bold text-capitalize name mt-5 ml-3'>Instructions </h4>
              <RulesContainer
                rulesChecked={rulesChecked}
                setRulesChecked={setRulesChecked}
              />
              <Button
                color='primary'
                type='submit'
                onClick={handleSubmit}
                className='position-absolute'
              >
                {result.state.loading ? (
                  <Loading size='sm' color='light' />
                ) : (
                  <>Continue</>
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

UserProfileComponent.propTypes = {
  handleFirstNameChange: PropTypes.func.isRequired,
  handleLastNameChange: PropTypes.func.isRequired,
  handleMobileChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  firstNameIsValid: PropTypes.shape().isRequired,
  lastNameIsValid: PropTypes.shape().isRequired,
  mobileIsValid: PropTypes.shape().isRequired,

  result: PropTypes.shape().isRequired,

  rulesChecked: PropTypes.bool.isRequired,
  setRulesChecked: PropTypes.func.isRequired,
};

export default React.memo(UserProfileComponent);
