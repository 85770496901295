export const ASSESSMENT_CANDIDATES_LIST = {
  ASSESSMENT_CANDIDATES_LIST_REQUEST: 'ASSESSMENT_CANDIDATES_LIST_REQUEST',
  ASSESSMENT_CANDIDATES_LIST_SUCCESS: 'ASSESSMENT_CANDIDATES_LIST_SUCCESS',
};

export const ASSESSMENT_CANDIDATE = {
  ASSESSMENT_CANDIDATE_ACTIVE_STATUS: 'ASSESSMENT_CANDIDATE_ACTIVE_STATUS',
};

export const ASSESSMENT_CANDIDATE_REPORTS = {
  ASSESSMENT_CANDIDATE_REPORT_REQUEST: 'ASSESSMENT_CANDIDATE_REPORT_REQUEST',
  ASSESSMENT_CANDIDATE_REPORT_SUCCESS: 'ASSESSMENT_CANDIDATE_REPORT_SUCCESS',
};
