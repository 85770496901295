import React from 'react';
import PropTypes from 'prop-types';
import siteLogo from 'assets/images/spark-code-logo.png';
import './SiteLogo.scss';

const SiteLogo = ({ className }) => {
  return (
    <>
      <a className={`site-logo-wrap d-inline-block ${className}`}>
        <img className='site-logo' src={siteLogo} alt='Spark Code Logo' />
      </a>
    </>
  );
};

SiteLogo.defaultProps = {
  className: '',
};

SiteLogo.propTypes = {
  className: PropTypes.string,
};

export default SiteLogo;
