import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { createAssessmentPostApi } from 'redux/admin/createAssessment/api';
import { CREATE_ASSESSMENT } from 'redux/admin/createAssessment/actionConstants';
import {
  createAssessmentFailureAction,
  createAssessmentSuccessAction,
} from 'redux/admin/createAssessment/action';

export function* createAssessmentSaga(action) {
  const { postData } = action.payload;
  try {
    const response = yield call(createAssessmentPostApi, postData);
    toast.success(response.data.message);
    yield put(createAssessmentSuccessAction(response.data));
  } catch (error) {
    toast.error(error.response.data.message);
    yield put(createAssessmentFailureAction(error.response.data));
  }
}

export default function* createAssessmentWatcherSaga() {
  yield takeLatest(CREATE_ASSESSMENT.CREATE_ASSESSMENT_REQUEST_ACTION, createAssessmentSaga);
}
