import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { createRuleRequestAction } from 'redux/admin/driveRules/action';
import RuleComponent from 'modules/admin/rule/RuleComponent';
import { reducer } from 'modules/admin/rule/reducer';
import { validateData } from 'modules/admin/rule/dataValidation';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';
import { deleteRuleApi, getRulesApi, postRuleApi, updateRuleApi } from './api';

const driveSaved = (drive_id) => {
  return drive_id === '';
};

const RuleContainer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { driveId, isCopyDrive } = props;
  const { message, isSuccess, ruleDriveCopyId } = useSelector((state) => state.createDriveReducer);
  let { drive_id } = useSelector((state) => state.createDriveReducer);
  if (typeof driveId !== 'undefined') {
    drive_id = driveId;
  }

  const initialUserState = {
    rule: '',
    ruleErr: '',
    isRuleEdit: false,
    rules: [],
    id: -1,
    isRuleLoaded: false,
  };
  const [userState, setUserState] = useReducer(reducer, initialUserState);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddMode, setIsAddMore] = useState(false);
  const [confirmationModal, setConfirmationmodal] = useState(false);
  const [targetId, setTargetId] = useState();
  const [ruleDescription, setRuleDescription] = useState();

  const toggleConfirmation = () => {
    setConfirmationmodal(!confirmationModal);
  };

  const handleDeleteClick = (id, description) => {
    setTargetId(id);
    setRuleDescription(description);
  };

  const schema = yup.object().shape({
    description: yup.string().required(),
  });

  const loadRules = useCallback(
    () => {
      let data = { drive_id };
      if (isCopyDrive) {
        data = { drive_id: ruleDriveCopyId };
      }
      useEffect(async () => {
        // TODO
        // api without saga 20
        const response = await getRulesApi(data);
        const responseForCurrentDrive = await getRulesApi({ drive_id });
        const currentRulesList = responseForCurrentDrive?.data?.data?.rules ?
          [...responseForCurrentDrive.data.data.rules]
          : [];
        const copyedRulesList = response.data !== undefined ? [...response.data.data.rules] : [];
        // get the rules with is not present in the new created drive
        const filterRules =  copyedRulesList.filter(
          (value) => !currentRulesList.find((rule) => rule.description === value.description),
        );
        setUserState({
          type: 'setAndDeleteRule',
          payload: {
            subType: 'setRules',
            data: copyedRulesList,
          },
        });
        filterRules.map((ele) => dispatch(createRuleRequestAction({ description: ele.description,
          drive_id })));
      }, []);
    }, [userState.rules, ruleDriveCopyId, isCopyDrive],
  );

  if (typeof drive_id !== 'undefined') {
    loadRules();
  }

  const handleRuleChange = useCallback(
    (event) => {
      const rule = event.target.value;
      setUserState({
        type: 'set rule/id',
        payload: {
          data: rule,
          subType: 'rule',
        },
      });
    },
    [userState.rule],
  );

  const handleOnAdd = useCallback(
    (event) => {
      const { rule } = userState;
      const data = {
        description: rule,
        drive_id,
      };
      schema.isValid(data).then(async (valid) => {
        if (!valid) {
          validateData(schema, data, setUserState);
        } else {
          if (driveSaved(drive_id)) {
            toast.error('Please create problem first');
            setUserState({ type: 'default' });
            return;
          }
          setIsLoading(true);
          try {
            // TODO
            // api without saga 21
            const response = await postRuleApi(data);
            setIsLoading(false);
            data.id = response.data.data.rule.id;

            setUserState({
              type: 'addRule',
              payload: data,
            });
            toast.success(response.data.message);
          } catch (error) {
            setIsLoading(false);
            toast.error(error.response.data.message);
          }
        }
      });
      setIsAddMore(!isAddMode);
    },
  );

  const handleOnRuleEdit = useCallback(
    (tid) => {
      let rule = {};
      const { rules } = userState;
      for (let i = 0; i < rules.length; i += 1) {
        if (rules[i].id === tid) {
          rule = rules[i];
          const { description, id } = rule;
          setUserState({
            type: 'editRule',
            payload: { description, id },
          });
          break;
        }
      }
      setIsAddMore(true);
    }, [userState.rule, userState.marks, userState.rules, userState.id],
  );

  const handleOnRuleUpdate = useCallback(
    (event) => {
      const { rules, rule, id } = userState;
      const data = {
        description: rule,
        drive_id,
        id,
      };
      schema.isValid(data).then(async (valid) => {
        if (!valid) {
          validateData(schema, data, setUserState);
        } else {
          let index;
          for (let i = 0; i < rules.length; i += 1) {
            if (rules[i].id === id) {
              index = i;
              break;
            }
          }
          try {
            // TODO
            // api without saga 22
            const response = await updateRuleApi(data);
            setUserState({
              type: 'updateRule',
              payload: { index, rule },
            });
            toast.success(response.data.message);
          } catch (error) {
            setUserState({ type: 'default' });
            toast.error(error.response.data.message);
          }
        }
      });
    }, [userState.rule, userState.output, userState.marks, userState.rules,
      userState.id],
  );

  const handleOnRuleDelete = useCallback(
    async (id) => {
      const { rules } = userState;
      const data = {
        id,
      };
      try {
        // TODO
        // api without saga 23
        const response = await deleteRuleApi(id);
        let index;
        for (let i = 0; i < rules.length; i += 1) {
          if (rules[i].id === id) {
            index = i;
            break;
          }
        }
        setUserState({
          type: 'setAndDeleteRule',
          payload: {
            subType: 'deleteRule',
            data: index,
          },
        });
        toast.success(response.data.message);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }, [userState.rules],
  );

  const handleOnCancel = useCallback(
    (event) => {
      event.preventDefault();
      setUserState({
        type: 'setdefault',
      });
    },
    [userState.rule, userState.output, userState.marks],
  );

  const finishDriveCreation = useCallback(() => {
    history.push(ROUTES.ADMIN + ADMIN_ROUTES.HOME);
  });

  const handleAddMoreClick = () => {
    setIsAddMore(!isAddMode);
  };

  return (
    <RuleComponent
      handleRuleChange={handleRuleChange}
      handleOnRuleAdd={handleOnAdd}
      handleOnRuleDelete={handleOnRuleDelete}
      handleOnRuleEdit={handleOnRuleEdit}
      handleOnRuleUpdate={handleOnRuleUpdate}
      handleOnCancel={handleOnCancel}
      message={message}
      ruleErrTxt={userState.ruleErr}
      isProblemSuccess={isSuccess}
      isRuleEdit={userState.isRuleEdit}
      rules={userState.rules}
      isLoading={isLoading}
      finishDriveCreation={finishDriveCreation}
      rule={userState.rule}
      handleAddMoreClick={handleAddMoreClick}
      isAddMode={isAddMode}
      handleDeleteClick={handleDeleteClick}
      targetId={targetId}
      ruleDescription={ruleDescription}
      toggleConfirmation={toggleConfirmation}
      confirmationModal={confirmationModal}
    />
  );
};

RuleContainer.defaultProps = {
  isCopyDrive: false,
};

RuleContainer.propTypes = {
  driveId: PropTypes.number.isRequired,
  isCopyDrive: PropTypes.bool,
};

export default React.memo(RuleContainer);
