import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  candidateReportFailureAction,
  candidateReportSuccessAction,
} from 'redux/admin/candidateReport/action';
import { candidateReportGetApi } from 'redux/admin/candidateReport/api';

import { CANDIDATE_REPORT } from 'redux/admin/candidateReport/actionConstants';

export function* candidateReportResponseSaga(action) {
  try {
    const response = yield call(candidateReportGetApi, action.token);
    yield put(candidateReportSuccessAction(response.data.data));
  } catch (error) {
    const { data } = error.response;
    yield put(candidateReportFailureAction(data));
    toast.error(data.message);
  }
}

export default function* candidateReportSaga() {
  yield takeLatest(CANDIDATE_REPORT.CANDIDATE_REPORT_REQUEST_ACTION, candidateReportResponseSaga);
}
