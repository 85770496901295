import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import SearchModalComponent from 'modules/admin/searchModal/SearchModalComponent';

import { setSearchModal } from 'redux/admin/modalStates/action';
import { driveDeleteRequest } from 'redux/admin/driveList/action';
import { setDriveSearchOffset, driveSearchRequest, clearSearchDrivesAction } from 'redux/admin/driveSearch/action';

import { DRIVES_LIMIT } from 'constants/appConstants';

const SearchModalContainer = () => {
  const dispatch = useDispatch();
  const { searchModal } = useSelector((state) => state.modalStatusReducer);
  const [searchText, setSearchText] = useState('');
  const toggle = () => {
    dispatch(clearSearchDrivesAction());
    dispatch(setSearchModal(false));
    setSearchText('');
  };
  const searchObserver = useRef();

  const history = useHistory();
  const {
    drives,
    drivesLoading,
    totalSearchedDrives,
    driveSearchFailureMessage,
    driveSearchOffSet,
  } = useSelector((item) => item.driveSearchReducer);

  const { totalUpcomingDrives } = useSelector(
    (item) => item.driveListReducer,
  );
  const [deletedDriveType, setDeletedDriveType] = useState('');
  const [driveName, setDriveName] = useState('');
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [targetId, setTargetId] = useState();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const toggleConfirmation = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleDeleteClick = (id, name, type) => {
    setTargetId(id);
    setDriveName(name);
    setDeletedDriveType('upcomingDrive');
  };

  const toggleDeleteConfirmation = () => {
    setShowDeleteConfirmationModal(!showDeleteConfirmationModal);
  };

  const onClickEdit = (driveId) => {
    dispatch(setSearchModal(false));
    history.push(`/admin/drive/${driveId}/edit`);
  };
  const onClickCandidates = (drive) => {
    history.push(`/admin/drive/${drive.id}/candidates`);
  };

  const onClickResult = (drive) => {
    history.push(`/admin/drive/${drive.id}/result`);
  };

  const onClickDelete = (driveId) => {
    dispatch(driveDeleteRequest({ id: driveId, type: deletedDriveType }));
    setDeletedDriveType('');
  };
  const driveSearchRef = useCallback((node) => {
    if (drivesLoading) return;
    if (searchObserver.current) searchObserver.current.disconnect();
    searchObserver.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (drives.length >= DRIVES_LIMIT) {
          dispatch(setDriveSearchOffset(driveSearchOffSet + DRIVES_LIMIT));
        }
      }
    });
    if (node) searchObserver.current.observe(node);
  }, [drivesLoading]);

  const handleOnChangeInput = (event) => {
    const search = event.target.value;
    setSearchText(search);
  };

  const handleOnClickSearch = useCallback(() => {
    if (searchText !== '') {
      dispatch(clearSearchDrivesAction());
      dispatch(driveSearchRequest({ driveSearchOffSet: 0, searchText: searchText.trim() }));
    } else {
      dispatch(driveSearchRequest({
        searchText: '',
        driveSearchOffSet: 0,
      }));
    }
  }, [searchText]);

  useEffect(() => {
    const allowLoading =
      searchModal && !drivesLoading && driveSearchOffSet <= totalSearchedDrives;
    if (allowLoading) {
      dispatch(driveSearchRequest({
        drives,
        driveSearchOffSet,
        searchText: searchText.trim(),
        totalSearchedDrives,
      }));
    }
  }, [searchModal, driveSearchOffSet, totalUpcomingDrives]);
  useEffect(() => {
    return () => {
      dispatch(clearSearchDrivesAction());
    };
  }, []);

  const handleResetSearch = () => {
    setSearchText('');
    dispatch(driveSearchRequest({
      searchText: '',
      driveSearchOffSet: 0,
    }));
  };

  return (
    <SearchModalComponent
      toggle={toggle}
      showModal={searchModal}
      drives={drives}
      totalSearchedDrives={totalSearchedDrives}
      drivesLoading={drivesLoading}
      onClickEdit={onClickEdit}
      onClickCandidates={onClickCandidates}
      onClickResult={onClickResult}
      driveSearchRef={driveSearchRef}
      driveSearchFailureMessage={driveSearchFailureMessage}
      toggleConfirmation={toggleConfirmation}
      targetId={targetId}
      handleDeleteClick={handleDeleteClick}
      confirmationModal={confirmationModal}
      onClickDelete={onClickDelete}
      handleOnClickSearch={handleOnClickSearch}
      handleOnChangeInput={handleOnChangeInput}
      searchText={searchText}
      driveName={driveName}
      showDeleteConfirmationModal={showDeleteConfirmationModal}
      toggleDeleteConfirmation={toggleDeleteConfirmation}
      handleResetSearch={handleResetSearch}
    />
  );
};

export default React.memo(SearchModalContainer);
