import moment from 'moment';

import { TIME_FORMAT } from 'constants/appConstants';

export const getTestStartDate = (testStarted) => (testStarted ? new Date(testStarted) : null);

export const getTestEndDate = (testFinished) => (testFinished ? new Date(testFinished) : null);

export const getTestStartDateString = (testStartDate) => (testStartDate ? testStartDate.toDateString() : '-');

export const getTestStartTime = (testStartDate) => (testStartDate ? moment(testStartDate).format(TIME_FORMAT) : '-');

export const getTestEndTime = (testEndDate) => (testEndDate ? moment(testEndDate).format(TIME_FORMAT) : '-');
