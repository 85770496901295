import produce from 'immer';

const setterForCrud = (payload, state) => {
  const { subType } = payload;
  if (subType === 'template') {
    state.template = payload.data;
    state.templateErr = '';
  } else if (subType === 'id') {
    state.id = payload.data;
  }
};

const setError = (payload, state) => {
  if (payload.subType === 'templateError') {
    state.templateError = payload.data;
  }
};

const setAndDeleteTemplate = (payload, state, [...templates]) => {
  const { subType } = payload;
  if (subType === 'setTemplates') {
    state.templates = [...payload.data];
    state.isRuleLoaded = true;
  } else if (subType === 'deleteTemplate') {
    state.templates.splice(payload.data, 1);
  }
};

export const reducer = produce((state, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case 'set template/id':
      setterForCrud(payload, state);
      break;
    case 'setError':
      setError(payload, state);
      break;
    case 'addTemplate':
      state.templates.push(payload);
      state.template = '';
      state.templateErr = '';
      break;
    case 'editTemplate':
      state.template = payload.code;
      state.id = payload.id;
      state.isTemplateEdit = true;
      break;
    case 'updateTemplate':
      state.templates[payload.index].code = payload.templateObj.code;
      state.templates[payload.index].language = payload.templateObj.language;
      state.template = '';
      state.templateErr = '';
      state.isTemplateEdit = false;
      break;
    case 'setAndDeleteTemplate':
      setAndDeleteTemplate(payload, state, state.templates);
      break;
    default:
      state.template = '';
      state.templateErr = '';
      state.isTemplateEdit = false;
      break;
  }
});
