import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  driveResultSuccessAction,
  driveResultFailureAction,
  selectedCandidateSuccessAction,
  selecteCandidateFailureAction,
  selectedCandidateEmailSuccessAction,
  selectedCandidateEmailFailureAction,
} from 'redux/admin/driveResult/action';
import { driveResultGetApi, selectedCandidatePostApi, shortlistedCandidatesResultGetApi } from 'redux/admin/driveResult/api';

import { DRIVE_RESULT, SELECTED_CANDIDATE, SELECTED_CANDIDATE_EMAIL } from 'redux/admin/driveResult/actionConstants';

const { DRIVE_RESULT_REQUEST_ACTION } = DRIVE_RESULT;
const { SELECTED_CANDIDATE_REQUEST_ACTION } = SELECTED_CANDIDATE;
const { SELECTED_CANDIDATE_EMAIL_REQUEST_ACTION } = SELECTED_CANDIDATE_EMAIL;

export function* driveResultSaga(action) {
  try {
    const response = yield call(driveResultGetApi, action.payload);
    if (!response.data.data.drive_total_marks) {
      response.data.data.drive_total_marks = '-';
    }
    yield put(driveResultSuccessAction(response.data.data));
  } catch (error) {
    const { data } = error.response;
    yield put(driveResultFailureAction(data));
    toast.error(data.message);
  }
}

export function* selectedCandidateSaga(action) {
  const { is_selected } = action.payload;

  try {
    const response = yield call(selectedCandidatePostApi,
      action.payload);
    yield put(selectedCandidateSuccessAction({ is_selected }));
  } catch (error) {
    const { data } = error.response;
    yield put(selecteCandidateFailureAction(data));
    toast.error(data.message);
  }
}

export function* selectedCandidateEmailSaga(action) {
  try {
    const response = yield call(shortlistedCandidatesResultGetApi, action.payload);
    toast.success(response.data.message);
    yield put(selectedCandidateEmailSuccessAction(response.data.data));
  } catch (error) {
    yield put(selectedCandidateEmailFailureAction(error.message));
  }
}

// watcherSaga
export default function* driveResultWatcherSaga() {
  yield takeLatest(DRIVE_RESULT_REQUEST_ACTION, driveResultSaga);
  yield takeLatest(SELECTED_CANDIDATE_REQUEST_ACTION, selectedCandidateSaga);
  yield takeLatest(SELECTED_CANDIDATE_EMAIL_REQUEST_ACTION,
    selectedCandidateEmailSaga);
}
