import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import CreateAssessmentComponent from 'modules/admin/createAssessment/CreateAssessmentComponent';

import { validateData } from 'modules/admin/createAssessment/dataValidation';
import { reducer } from 'modules/admin/createAssessment/CreateAssessmentCotainer/reducer';
import {
  editAssessmentRequestAction,
  editAssessmentClearAction,
  getAssessmentRequestAction,
} from 'redux/admin/editAssessment/action';
import { contactsListRequestActioon, contactsListClear } from 'redux/admin/getContacts/action';
import { getMcqCountRequestAction, clearMcqCount } from 'redux/admin/getMcqCount/action';
import { tagsRequestAction } from 'redux/admin/tags/action';

import arrayToKeyValueHash from 'utils/arrayToKeyValueHash';
import { getFullNameWithContact, getUserDetails } from 'utils';

import { ADMIN_ROUTES, ROUTES } from 'constants/routeConstants';

const EditAssessmentCotainer = () => {
  const defaultCnt = 0;
  const initialState = {
    data: {
      Assessment: {
        name: '',
        description: '',
        easy_problem_count: defaultCnt,
        medium_problem_count: defaultCnt,
        hard_problem_count: defaultCnt,
        easy_mcq_count: defaultCnt,
        medium_mcq_count: defaultCnt,
        hard_mcq_count: defaultCnt,
        contacts: [],
        mcq_tags: [],
        isParamAssessment: false,
      },
    },
    nameErrTxt: '',
    descriptionErrTxt: '',
    problemCountErrTxt: '',
    contactsErrTxt: '',
    message: '',
    easyProblemErrTxt: '',
    mediumProblemErrTxt: '',
    hardProblemErrTxt: '',
    easyMcqErrTxt: '',
    mediumMcqErrTxt: '',
    hardMcqErrTxt: '',
  };

  const {
    message,
    isSuccess,
    isLoading,
    assessment,
    isGetAssessmentSuccess,
  } = useSelector((state) => state.editAssessmentReducer);

  const {
    tag_list: selectedTagsList,
    assessments_contacts: assessmentsContacts,
    admin_users: adminUsers,
    is_param: assessmentIsParam,
  } = assessment;

  const [editAssessment, setEditAssessment] = useReducer(reducer, initialState);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isParam, setIsParam] = useState(false);
  const { tagList } = useSelector((state) => state.tagsReducer);
  const [tagsOptions, setTagsOptions] = useState([]);
  const [defaultContacts, setDefaultContacts] = useState([]);

  const { mcqCountData, isMcqCountLoading }
    = useSelector((state) => state.getMcqCountReducer);
  const { assessmentId } = useParams();

  const schema = yup.object().shape({
    name: yup
      .string()
      .min(5, 'Title must be atleast 5 characters')
      .required('Title is a required field'),
    description: yup
      .string()
      .min(10)
      .required(),
    problem_count: yup
      .number()
      .integer()
      .min(1, 'Please add atleast 1 count'),
    contacts: yup
      .array()
      .min(1, 'Please select contact(s)'),
    easy_problem_count: yup
      .number()
      .integer()
      .typeError('')
      .max(mcqCountData.easy_problem_count, 'Count should be less than total'),
    medium_problem_count: yup
      .number()
      .integer()
      .typeError('')
      .max(mcqCountData.medium_problem_count, 'Count should be less than total'),
    hard_problem_count: yup
      .number()
      .integer()
      .typeError('')
      .max(mcqCountData.hard_problem_count, 'Count should be less than total'),
    easy_mcq_count: yup
      .number()
      .integer()
      .typeError('')
      .max(mcqCountData.easy_mcq_count, 'Count should be less than total'),
    medium_mcq_count: yup
      .number()
      .integer()
      .typeError('')
      .max(mcqCountData.medium_mcq_count, 'Count should be less than total'),
    hard_mcq_count: yup
      .number()
      .integer()
      .typeError('')
      .max(mcqCountData.hard_mcq_count, 'Count should be less than total'),
  });

  const {
    contactsList,
    isContactsLoading,
  } = useSelector((item) => item.getContactsReducer);
  const profileDetails = getUserDetails();
  const allowCoding = profileDetails.allow_coding;
  const allowMcq = profileDetails.allow_mcq;

  useEffect(() => {
    if (!isContactsLoading) {
      dispatch(contactsListRequestActioon({
        contactsList,
      }));
    }
    dispatch(tagsRequestAction());
  }, []);

  useEffect(() => {
    setTagsOptions(arrayToKeyValueHash(tagList));
  }, [tagList]);

  useEffect(() => {
    if (!isLoading) {
      dispatch(getAssessmentRequestAction(assessmentId));
    }
  }, []);

  useEffect(() => {
    setEditAssessment({
      type: 'Assessment',
      payload: assessment,
    });
    const selected = [];
    assessmentsContacts?.forEach((element) => {
      selected.push(parseInt(element.user_id, 10));
    });
    setEditAssessment({
      type: 'defaultContacts',
      payload: selected,
    });

    if (adminUsers) {
      // set default contacts
      const contactsArray = adminUsers?.map(({
        id,
        first_name: firstName,
        last_name: lastName,
        mobile_number: mobileNumber,
        is_active: isActive,
      }) => ({
        value: id,
        label: getFullNameWithContact(firstName, lastName, mobileNumber),
        status: isActive,
      }));
      const filterArray = contactsArray?.filter((v) => !v.status)
        .concat(contactsArray?.filter((v) => v.status));
      setDefaultContacts([...filterArray]);
    }

    const selectedContacts = [];
    if (assessmentsContacts) {
      assessmentsContacts.map((e) => {
        return selectedContacts.push(e.user_id);
      });
    }
    setEditAssessment({
      type: 'contacts',
      payload: selectedContacts,
    });
    setIsParam(assessmentIsParam);
  }, [assessment]);

  useEffect(() => {
    return () => {
      dispatch(editAssessmentClearAction());
      setEditAssessment({ type: 'clear' });
      dispatch(contactsListClear());
      dispatch(clearMcqCount());
    };
  }, []);

  const defaultTags = [];
  if (selectedTagsList) {
    selectedTagsList.map((tag) => defaultTags.push({ value: tag, label: tag }));
  }

  const selectedTagsArray = defaultTags.map((tag) => {
    return tag.value;
  });

  useEffect(() => {
    if (isGetAssessmentSuccess) {
      if (!isMcqCountLoading) {
        dispatch(getMcqCountRequestAction(selectedTagsArray));
      }
    }
  }, [isGetAssessmentSuccess, selectedTagsList]);

  const handleAssessmentNameChange = useCallback(
    (event) => {
      const name = event.target.value;
      setEditAssessment({
        type: 'nameErrTxt',
        payload: '',
      });
      setEditAssessment({
        type: 'name',
        payload: name,
      });
    },
    [editAssessment.data.Assessment.name],
  );

  const handleAssessmentDescriptionChange = useCallback(
    (event) => {
      const description = event.target.value;
      setEditAssessment({
        type: 'descriptionErrTxt',
        payload: '',
      });
      setEditAssessment({
        type: 'description',
        payload: description,
      });
    },
    [editAssessment.data.Assessment.description],
  );

  const handleEasyProblemCountChange = useCallback(
    (event) => {
      const count = event.target.value;
      setEditAssessment({
        type: 'easy_problem_count',
        payload: count,
      });
    },
    [editAssessment.data.Assessment.easy_problem_count],
  );

  const handleMediumProblemCountChange = useCallback(
    (event) => {
      const count = event.target.value;
      setEditAssessment({
        type: 'medium_problem_count',
        payload: count,
      });
    },
    [editAssessment.data.Assessment.medium_problem_count],
  );

  const handleHardProblemCountChange = useCallback(
    (event) => {
      const count = event.target.value;
      setEditAssessment({
        type: 'hard_problem_count',
        payload: count,
      });
    },
    [editAssessment.data.Assessment.hard_problem_count],
  );

  const handleEasyMcqCountChange = useCallback(
    (event) => {
      const count = event.target.value;
      setEditAssessment({
        type: 'easy_mcq_count',
        payload: count,
      });
    },
    [editAssessment.data.Assessment.easy_mcq_count],
  );

  const handleMediumMcqCountChange = useCallback(
    (event) => {
      const count = event.target.value;
      setEditAssessment({
        type: 'medium_mcq_count',
        payload: count,
      });
    },
    [editAssessment.data.Assessment.medium_mcq_count],
  );

  const handleHardMcqCountChange = useCallback(
    (event) => {
      const count = event.target.value;
      setEditAssessment({
        type: 'hard_mcq_count',
        payload: count,
      });
    },
    [editAssessment.data.Assessment.hard_mcq_count],
  );

  const handleSelectedTagsChange = useCallback(
    (event) => {
      const tags = [];
      event.map((tag) => tags.push(tag.value));
      setEditAssessment({
        type: 'mcq_tags',
        payload: tags,
      });
      dispatch(getMcqCountRequestAction(tags));
    }, [editAssessment.data.Assessment.mcq_tags],
  );

  const onEditAssessmentSubmit = () => {
    const {
      data: {
        Assessment: { name, description, contacts,
          easy_problem_count, medium_problem_count, hard_problem_count,
          easy_mcq_count, medium_mcq_count, hard_mcq_count, mcq_tags, isParamAssessment,
        },
      },
    } = editAssessment;

    const problem_cnt = allowCoding ? (easy_problem_count || defaultCnt) +
      (medium_problem_count || defaultCnt) + (hard_problem_count || defaultCnt) : defaultCnt;
    const mcq_cnt = allowMcq ? (easy_mcq_count || defaultCnt) + (medium_mcq_count || defaultCnt) +
      (hard_mcq_count || defaultCnt) : defaultCnt;
    const problem_count = problem_cnt + mcq_cnt;

    const assessments_contacts_attributes = [];
    const newContacts = [];

    for (let i = 0; i < contacts.length; i += 1) {
      let flg = 1;
      for (let j = 0; j < assessmentsContacts.length; j += 1) {
        if (assessmentsContacts[j].user_id === contacts[i]) {
          flg = 0;
          break;
        }
      }
      if (flg) newContacts.push(contacts[i]);
    }

    const oldcontacts =
      assessmentsContacts.filter((x) => !contacts.includes(x.user_id));

    for (let i = 0; i < oldcontacts.length; i += 1) {
      assessments_contacts_attributes.push(
        {
          id: oldcontacts[i].id,
          user_id: oldcontacts[i].user_id,
          _destroy: true,
        },
      );
    }
    for (let i = 0; i < newContacts.length; i += 1) {
      assessments_contacts_attributes.push(
        {
          user_id: newContacts[i],
          _destroy: false,
        },
      );
    }

    setEditAssessment({
      type: 'resetCountErrs',
    });

    const validData = {
      name: name.trim(),
      description: description.trim(),
      problem_count,
      contacts,
      easy_problem_count,
      medium_problem_count,
      hard_problem_count,
      easy_mcq_count,
      medium_mcq_count,
      hard_mcq_count,
    };
    const putData = {
      name: name.trim(),
      description: description.trim(),
      problem_count_hash: {
        easy: easy_problem_count,
        medium: medium_problem_count,
        hard: hard_problem_count,
      },
      mcq_count_hash: {
        easy: easy_mcq_count,
        medium: medium_mcq_count,
        hard: hard_mcq_count,
      },
      assessments_contacts_attributes,
      tag_list: mcq_tags,
      is_param: isParam,
    };

    const activeUsers = defaultContacts.filter((contact) => contact.status);
    schema.isValid(validData).then(async (valid) => {
      if (!valid) {
        validateData(schema, validData, setEditAssessment);
      } else if (activeUsers.length === 0) {
        setEditAssessment({
          type: 'contactsErrTxt',
          payload: 'Please select atleast 1 active user',
        });
      } else {
        dispatch(editAssessmentRequestAction({ putData, assessmentId }));
      }
    });
  };

  const handleSelectedContactsChange = useCallback(
    (event) => {
      const contacts = event;
      setEditAssessment({
        type: 'contactsErrTxt',
        payload: '',
      });
      setEditAssessment({
        type: 'resetCurrentContacts',
      });
      const selected = [];
      for (let i = 0; i < event.length; i += 1) {
        selected.push(parseInt(event[i].value, 10));
      }
      setEditAssessment({
        type: 'contacts',
        payload: selected,
      });
      setDefaultContacts([...event]);
    },
    [editAssessment.data.Assessment.contacts],
  );

  const finishAssessmentEdit = useCallback(() => {
    history.push(ROUTES.ADMIN + ADMIN_ROUTES.ASSESSMENTS);
  });

  const contactOptions = useMemo(() => (contactsList?.map(({
    id,
    first_name: firstName,
    last_name: lastName,
    mobile_number: mobileNumber,
    is_active: isActive,
  }) => ({
    value: id,
    label: getFullNameWithContact(firstName, lastName, mobileNumber),
    status: isActive,
  }))));

  const handleIsParamChange = () => {
    setIsParam(!isParam);
  };

  return (
    <CreateAssessmentComponent
      handleAssessmentNameChange={handleAssessmentNameChange}
      handleAssessmentDescriptionChange={handleAssessmentDescriptionChange}
      handleEasyProblemCountChange={handleEasyProblemCountChange}
      handleMediumProblemCountChange={handleMediumProblemCountChange}
      handleHardProblemCountChange={handleHardProblemCountChange}
      handleEasyMcqCountChange={handleEasyMcqCountChange}
      handleMediumMcqCountChange={handleMediumMcqCountChange}
      handleHardMcqCountChange={handleHardMcqCountChange}
      onCreateAssessmentSubmit={onEditAssessmentSubmit}
      message={message}
      isSuccess={isSuccess}
      defaultAssessment={editAssessment}
      nameErrTxt={editAssessment.nameErrTxt}
      descriptionErrTxt={editAssessment.descriptionErrTxt}
      problemCountErrTxt={editAssessment.problemCountErrTxt}
      finishAssessmentCreation={finishAssessmentEdit}
      action='Update'
      contactOptions={contactOptions}
      selectedContacts={defaultContacts}
      handleSelectedContactsChange={handleSelectedContactsChange}
      isLoading={isLoading}
      contactsErrTxt={editAssessment.contactsErrTxt}
      defaultTags={defaultTags}
      handleSelectedTagsChange={handleSelectedTagsChange}
      mcqCountData={mcqCountData}
      allowCoding={allowCoding}
      allowMcq={allowMcq}
      isParam={isParam}
      handleIsParamChange={handleIsParamChange}
      tagsOptions={tagsOptions}
      isEditAssessment
    />
  );
};

export default React.memo(EditAssessmentCotainer);
