import produce from 'immer';

export const reducer = produce((state, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case 'password':
      return ({
        ...state,
        password: payload,
        passwordError: '',
      });
    case 'passwordConfirmation':
      return ({
        ...state,
        passwordConfirmation: payload,
        passwordConfirmationError: '',
      });
    case 'passwordConfirmationError':
      return ({ ...state, passwordConfirmationError: payload });
    case 'passwordError':
      return ({ ...state, passwordError: payload });
    default:
      return state;
  }
});
