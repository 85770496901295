import { QUESTIONS_LIST } from 'redux/admin/questionsList/actionContants';

const {
  QUESTIONS_LIST_REQUEST_ACTION,
  QUESTIONS_LIST_SUCCESS_ACTION,
  QUESTIONS_LIST_FAILURE_ACTION,
  CLEAR_QUESTIONS_LIST,
} = QUESTIONS_LIST;

export const questionsRequestAction = (payload) => ({
  type: QUESTIONS_LIST_REQUEST_ACTION,
  payload,
});

export const questionsSuccessAction = (payload) => ({
  type: QUESTIONS_LIST_SUCCESS_ACTION,
  payload,
});

export const questionsFailureAction = (payload) => ({
  type: QUESTIONS_LIST_FAILURE_ACTION,
  payload,
});

export const questionsClear = () => ({
  type: CLEAR_QUESTIONS_LIST,
});
