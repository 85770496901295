/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes, { shape } from 'prop-types';
import React from 'react';
import {
  Spinner,
} from 'core-components';
import {
  CardComponent,
  FeedbackTile,
} from 'shared-components';
import './feedbackComponent.scss';

const FeedbackComponent = ({
  isFeedbacksLoading,
  feedbacks,
  lastBookElementRef,
  totalFeedbacks,
  onClickAddFilter,
  showFilter,
  handleDeleteClick,
}) => {
  return (
    <>
      <div className='feedback-bank-section d-flex flex-column'>
        <div className={`feedback-bank-section-head d-flex align-items-center px-3 ${showFilter ? 'mb-3' : 'mb-5'}`}>
          <h2 className='section-heading font-weight-bold mb-0'>
            Candidates Feedbacks ({totalFeedbacks})
          </h2>
        </div>
        {
          feedbacks.length === 0 ? 'Feedbacks Not Found' : null
        }
        <div className='feedback-list-wrap scroll-y'>
          <ul className='feedback-list list-unstyled mb-0 pl-0 mx-3'>
            {feedbacks.map((item, index) => {
              if (feedbacks.length === index + 1) {
                return (
                  <div ref={lastBookElementRef} key={item.id}>
                    <CardComponent>
                      <FeedbackTile
                        feedbackDetails={item}
                        handleDeleteClick={handleDeleteClick}
                      />
                    </CardComponent>
                  </div>
                );
              }
              return (
                <div key={item.id}>
                  <CardComponent>
                    <FeedbackTile
                      feedbackDetails={item}
                      handleDeleteClick={handleDeleteClick}
                    />
                  </CardComponent>
                </div>
              );
            })}
            {isFeedbacksLoading && <Spinner className='loader' />}
          </ul>
        </div>
      </div>
    </>
  );
};

FeedbackComponent.defaultProps = {
  showFilter: false,
};

FeedbackComponent.propTypes = {
  isFeedbacksLoading: PropTypes.bool.isRequired,
  feedbacks: PropTypes.arrayOf(shape()).isRequired,
  lastBookElementRef: PropTypes.func.isRequired,
  totalFeedbacks: PropTypes.number.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  onClickAddFilter: PropTypes.func.isRequired,
  showFilter: PropTypes.bool,
};

export default React.memo(FeedbackComponent);
