import { get, destroy, assessmentGet } from 'redux/admin/apiHelper';
import { SERVER_URL } from 'constants/appConstants';
import queryStringHelper from 'utils/queryStringHelper';

export const AssessmentCandidatesGetApi = ({ id, page, searchQuery, absentCandidates }) => {
  const url = queryStringHelper({
    id,
    page,
    search: searchQuery,
    absent_candidates: absentCandidates,
  });
  return assessmentGet(`${SERVER_URL}admin/assessments/candidate_list?${url}`);
};

export const AssessmentReportsGetApi = ({ id, page, searchQuery }) => {
  const url = queryStringHelper({ id, page, search: searchQuery });
  return assessmentGet(`${SERVER_URL}admin/assessments/result?${url}`);
};

export const assessmentDeleteApi = ({ id }) => {
  return get(`${SERVER_URL}admin/assessments/candidate_list/${id}`);
};
