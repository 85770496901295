import { call, put, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  questionsListFailureAction, questionsListSuccessAction,
  deleteQuestionFailureAction, deleteQuestionSuccessAction,
} from 'redux/admin/questions/action';
import { getQuestions, deleteQuestionApi } from 'redux/admin/questions/api';
import { QUESTIONS, DELETE_QUESTION } from 'redux/admin/questions/actionContants';

import queryStringHelper from 'utils/queryStringHelper';

import { QUESTIONS_LIMIT } from 'constants/appConstants';

export function* getQuestionsList(action) {
  const {
    offset,
    searchedQuestionText,
    searchedQuestionDifficulty,
    searchedQuestionTag,
  } = action.payload;

  const getQueryString = queryStringHelper({
    offset,
    search: searchedQuestionText,
    difficulty_list: searchedQuestionDifficulty
      ? searchedQuestionDifficulty.value : '',
    tag_list: searchedQuestionTag
      ? searchedQuestionTag.map((item) => (item.value))?.toString() : '',
    limit: QUESTIONS_LIMIT,
  });

  try {
    const response = yield call(getQuestions, getQueryString);
    yield put(questionsListSuccessAction(response.data.data));
  } catch (error) {
    yield put(questionsListFailureAction(error));
  }
}

export function* deleteQuestion(action) {
  try {
    const response = yield call(deleteQuestionApi, action.payload);
    yield put(deleteQuestionSuccessAction({ id: action.payload }));
    toast.success(response.data.message);
  } catch (error) {
    yield put(deleteQuestionFailureAction(error));
  }
}

export default function* questionsListSaga() {
  yield takeEvery(QUESTIONS.QUESTIONS_REQUEST_ACTION, getQuestionsList);
  yield takeEvery(DELETE_QUESTION.DELETE_QUESTION_REQUEST_ACTION, deleteQuestion);
}
