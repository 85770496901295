/* eslint-disable indent */
import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  ADMIN_ROUTES,
  ROUTES,
  SUPER_ADMIN_ROUTES,
} from 'constants/routeConstants';
import { Icon } from 'core-components';
import AdminProfileContainer from 'modules/superAdmin/adminProfile/AdminProfileContainer';
import SearchModalContainer from 'modules/admin/searchModal/SearchModalContainer';
import './sideNavComponent.scss';

import siteLogo from '../../../../assets/images/spark-code-logo.png';
import joshLogo from '../../../../assets/images/josh-logo@2x.png';

import './sideNavStyle.css';

const navLinks = [
  {
    navIcon: 'organization',
    navLabel: 'Organizations',
    navPath: `${ROUTES.SUPER_ADMIN}${SUPER_ADMIN_ROUTES.ORGANIZATIONS}`,
  },
];

const SideNavComponent = ({
  handleProfileClick,
  handleSearchClick,
  handleClearState,
}) => {
  return (
    <>
      <aside className='sp-sidebar px-2 d-flex flex-column position-relative scroll-y'>
        <div className='p-3'>
          <a href='/administrant/organizations' className='sp-logo d-block'>
            <img src={siteLogo} alt='site logo' />
          </a>
        </div>
        <div className='sp-superadmin-sidenav p-3'>
          <div className='sp-sidenav-action-box sidenav-icon'>
            <Icon name='profile' className='profile-icon' title='Profile' onClick={handleProfileClick} />
          </div>
        </div>
        {/* {isSuperAdim()} */}
        <div className='sp-aside-nav d-flex flex-1 flex-column scroll-y'>
          <ul className='sp-aside-nav-list list-unstyled mb-0'>
            {navLinks.map((navItems) => (
              <li className='sp-aside-nav-item p-3'>
                <NavLink
                  to={navItems.navPath}
                  className='sp-aside-nav-link d-flex align-items-center text-decoration-none'
                  onClick={handleClearState}
                >
                  <Icon name={navItems.navIcon} />
                  <span className='ml-4 font-weight-bold'>
                    {navItems.navLabel}
                    {/* Organization */}
                  </span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
        <div className='bottom-link p-3'>
          <img src={joshLogo} alt='josh logo' className='h-100' />
        </div>
      </aside>
      <AdminProfileContainer />
      <SearchModalContainer />
    </>
  );
};

SideNavComponent.propTypes = {
  handleProfileClick: PropTypes.func.isRequired,
  handleSearchClick: PropTypes.func.isRequired,
  handleClearState: PropTypes.func.isRequired,
};

export default React.memo(SideNavComponent);
