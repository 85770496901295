import React from 'react';

import TestEndPageComponent from 'components/TestEndPageComponent';

const TestEndPageContainer = () => {
  return (
    <TestEndPageComponent />
  );
};

export default React.memo(TestEndPageContainer);
