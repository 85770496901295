// converting question data coming from backend to initiails values which will be used for formik

import arrayToKeyValueHash, { getType, stringToKeyValue } from 'utils/arrayToKeyValueHash';

export const convertResponseToInitialValues = (isQuestionLoading, question) => {
  if (!isQuestionLoading) {
    const {
      id,
      description,
      image_url: questionDiagram,
      marks,
      options,
      time_in_minutes: timeInMinutes,
    } = question;

    // looping through options array and putting each field in mcqOptions array of objects
    const mcqOptions = options?.map((option) => ({
      id: option.id,
      description: option.description,
      isAnswer: option.is_answer,
      optionDiagram: option.image_url,
    }));

    // as react select accepts key-value pair format
    // (1) converting tags array of strings into array of key-value format
    // (2) convertin dificulty string into key-value
    // (3) getting type key-value pair as per is_multi_select boolean value
    const tags = arrayToKeyValueHash(question.tags);
    const difficulty = stringToKeyValue(question.difficulty);
    const type = getType(question.is_multi_select);

    // setting values into formik.values
    const formikQuestionInitialValues = {
      id,
      description,
      difficulty,
      questionDiagram,
      type,
      marks,
      mcqOptions,
      tags,
      timeInMinutes,
    };

    return formikQuestionInitialValues;
  }
};
