import { get, put } from 'redux/admin/apiHelper';
import queryStringHelper from 'utils/queryStringHelper';

import { SERVER_URL } from 'constants/appConstants';

export const driveResultGetApi = (payload) => {
  const { page, searchQuery, driveId } = payload;
  const queryString = queryStringHelper({ page, search: searchQuery });

  return get(`${SERVER_URL}admin/drives/${driveId}/results?${queryString}`);
};

export const selectedCandidatePostApi = ({ candidateToken, is_selected, driveId }) => {
  return put(`${SERVER_URL}admin/drives/${driveId}/shortlist_candidate`, { is_selected, token: candidateToken });
};

export const shortlistedCandidatesResultGetApi = (payload) => {
  const { driveId, usersIds } = payload;
  return get(`${SERVER_URL}admin/drives/${driveId}/shortlisted_candidates_email?user_ids=${usersIds}`);
};
