export const DRIVE = {
  DRIVE_DETAIL_REQUEST: 'DRIVE_DETAIL_REQUEST',
  SET_DETAILS: 'SET_DRIVE_DETAILS',
  SET_ERROR_MESSAGE: 'SET_DRIVE_ERROR_MESSAGE',
  SET_LOADING: 'SET_DRIVE_LOADING',
};

export const USER = {
  SET_DETAILS: 'SET_USER_DETAILS',
};

export const RULES = {
  SET_DETAILS: 'SET_RULES_DETAILS',
  DETAIL_REQUEST: 'DETAIL_RULES_REQUEST',
  SET_ERROR_MESSAGE: 'SET_RULES_ERROR_MESSAGE',
};

export const CANDIDATE_FORM_ACTIONS = {
  REQUEST_ACTION: 'REQUEST_ACTION',
  SUCCESS_ACTION: 'SUCCESS_ACTION',
  FAILURE_ACTION: 'FAILURE_ACTION',
  RESET_TOKEN: 'RESET_TOKEN',
};

export const TIMER = {
  SET_TIMER: 'SET_TIMER',
  TIMER_REQUEST: 'TIMER_REQUEST',
  SET_TIMER_ERROR: 'SET_TIMER_ERROR',
  TEST_TIMER: 'TEST_TIMER',
  UPDATE_TIMER: 'UPDATE_TIMER',
};

export const LANGUAGE = {
  FETCH_ACTION: 'LANGUAGES_DETAIL_REQUEST',
  RESET_ACTION: 'SET_LANGUAGES_DETAILS',
  FAILURE_ACTION: 'SET_LANGUAGES_ERROR_MESSAGE',
  SET_LOADING: 'SET_LANGUAGES_LOADING',
  SET_LANGUAGE_SELECTED: 'SET_LANGUAGE_SELECTED',
  SET_CODE: 'SET_CODE',
  LANGUAGE_CODE: 'LANGUAGE_CODE',
  SET_CHANGED: 'SET_CHANGED',
  SET_FIRST: 'SET_FIRST',
};

export const PROBLEM_STATEMENT = {
  SET_SELECTED_PROBLEMS: 'SET_SELECTED_PROBLEMS',
  DETAILS_REQUEST: 'STATEMENT_DETAIL_REQUEST',
  UPDATE_SUBMISSION_COUNT: 'UPDATE_SUBMISSION_COUNT',
  SET_ERROR_MESSAGE: 'SET_STATEMENT_ERROR_MESSAGE',
  SET_ACTIVE_INDEX: 'SET_ACTIVE_INDEX',
};

export const DRIVE_TIMER = {
  SET_DRIVE_TIMER: 'SET_DRIVE_TIMER',
  DRIVE_TIMER_REQUEST: 'DRIVE_TIMER_REQUEST',
  SET_DRIVE_TIMER_ERROR: 'SET_DRIVE_TIMER_ERROR',
  UPDATE_DRIVE_TIMER: 'UPDATE_DRIVE_TIMER',
};

export const CODE_SUBMISSION = {
  SET_DETAILS:'SET_DETAILS',
  CODE_SUBMISSION_REQUEST: 'CODE_SUBMISSION_REQUEST',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_SUBMISSION_ALLOWED: 'SET_SUBMISSION_ALLOWED',
};

export const CODE_BACKUP = {
  SET_BACKUP_CODE:'SET_BACKUP_CODE',
  GET_BACKUP_CODE: 'GET_BACKUP_CODE',
  FETCH_CODE_REQUEST: 'FETCH_BACKUP_CODE_REQUEST',
  FETCH_CODE_ACTION: 'FETCH_BACKUP_CODE_ACTION',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
};

export const FINISH_TEST = {
  SET_FINISH_DETAILS:'SET_FINISH_DETAILS',
  FINISH_TEST_REQUEST: 'FINISH_TEST_REQUEST',
  SET_FINISH_ERROR_MESSAGE: 'SET_FINISH_ERROR_MESSAGE',
};

export const INPUT_OUTPUT = {
  SET_INPUT:'SET_INPUT',
  SET_OUTPUT: 'SET_OUTPUT',
  CLEAR: 'CLEAR',
  SET_OUTPUT_REQUEST: 'SET_OUTPUT_REQUEST',
  SET_TOKEN: 'SET_TOKEN',
};

export const CANDIDATE_FEEFBACK_ACTIONS = {
  REQUEST_ACTION: ' CANDIDATE_FEEFBACK_REQUEST_ACTION',
  SUCCESS_ACTION: ' CANDIDATE_FEEFBACK_SUCCESS_ACTION',
};

export const CANDIDATE_SECTION_CHANGE_ACTION = {
  CANDIDATE_SECTION_CHANGE_ACTION: 'CANDIDATE_SECTION_CHANGE_ACTION',
};

export const MCQ_QUESTION = {
  MCQ_QUESTION_REQUEST_ACTION: 'MCQ_QUESTION_REQUEST_ACTION',
  MCQ_QUESTION_SUCCESS_ACTION: 'MCQ_QUESTION_SUCCESS_ACTION',
  MCQ_QUESTION_FAILURE_ACTION: 'MCQ_QUESTION_FAILURE_ACTION',
  SELECT_MCQ: 'SELECT_MCQ',
  SAVE_QUESTION_REQUEST_ACTION: 'SAVE_QUESTION_REQUEST_ACTION',
  SAVE_QUESTION_SUCCESS_ACTION: 'SAVE_QUESTION_SUCCESS_ACTION',
  SELECT_ANSWER_REQUEST_ACTION: 'SELECT_ANSWER_REQUEST_ACTION',
  FINAL_MCQ_SUBMISSION: 'FINAL_MCQ_SUBMISSION',
  CLEAR_SAVE_MESSAGE: 'CLEAR_SAVE_MESSAGE',
};
