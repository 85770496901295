import produce from 'immer';

export const reducer = produce((state, action) => {
  const { type, payload } = action;
  switch (action.type) {
    case 'title':
      state.title = payload;
      state.validTitle = true;
      break;
    case 'description':
      state.description = payload;
      state.validDescription = true;
      break;
    case 'submissionCount':
      state.submissionCount = payload;
      state.validSubmissionCount = true;
      break;
    case 'timeInMinutes':
      state.timeInMinutes = payload;
      state.validTime = true;
      break;
    case 'difficulty':
      state.difficulty = payload;
      state.difficultyError = '';
      break;
    case 'tags':
      state.tags = payload;
      state.tagsError = '';
      break;
    case 'invalidTitle':
      state.validTitle = false;
      state.titleError = payload;
      break;
    case 'invalidDescription':
      state.validDescription = false;
      state.descriptionError = payload;
      break;
    case 'invalidSubmissionCount':
      state.validSubmissionCount = false;
      state.submissionCountError = payload;
      break;
    case 'invalidTime':
      state.validTime = false;
      state.timeInMinutesError = payload;
      break;
    case 'invalidDifficulty':
      state.difficultyError = payload;
      break;
    case 'invalidTags':
      state.tagsError = payload;
      break;
    case 'problemDiagram':
      state.problemDiagram = payload;
      break;
    default: return state;
  }
});
