import React from 'react';
import PropTypes from 'prop-types';

import ProblemsListContainer from 'modules/admin/problemsList/ProblemsListContainer';
import QuestionContainer from 'modules/admin/question/QuestionContainer';
import TemplateContainer from 'modules/admin/template/TemplateContainer';
import QuestionHeaderComponent from 'modules/admin/question/QuestionComponent/QuestionHeaderComponent';
import ProblemHeaderComponent from 'modules/admin/problemsList/ProblemsListComponent/ProblemHeaderComponent';

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'core-components';

import { CODING_TAB, MCQ_TAB } from 'constants/appConstants';

import './QuestionTabsComponent.scss';

const QuestionTabsComponent = ({
  activeTab,
  setActiveTabMethod,
  totalQuestions,
  handleAddQuestionClick,
  handleAddProblemClick,
  handleOnChange,
  handleProblemSearchClick,
  handleProblemSearchClearClick,
  totalProblems,
  onClickAddFilter,
  showFilter,
  handleQuestionSearchClick,
  handleQuestionSearchClearClick,
  questionSearchText,
  onClickQuestionsFilter,
  searchedQuestionText,
  searchedProblemText,
  showQuestionsFilter,
  onProblemSearchChange,
  problemSearchText,
  handleDownloadMcq,
}) => {
  return (
    <div className='bg-card d-flex flex-column flex-1 scroll-y'>
      <div className='px-2 pb-3 pt-5 d-flex align-items-center justify-content-between'>
        {activeTab !== 'template' && (
          <Nav className='navitem-style'>
            <NavItem>
              <NavLink
                active={activeTab === CODING_TAB}
                onClick={() => setActiveTabMethod(CODING_TAB)}
              >
                Coding
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                active={activeTab === MCQ_TAB}
                onClick={() => setActiveTabMethod(MCQ_TAB)}
              >
                MCQ
              </NavLink>
            </NavItem>
          </Nav>
        )}
        <div>
          {activeTab === MCQ_TAB && (
            <QuestionHeaderComponent
              totalQuestions={totalQuestions}
              handleAddQuestionClick={handleAddQuestionClick}
              handleOnChange={handleOnChange}
              handleQuestionSearchClick={handleQuestionSearchClick}
              handleQuestionSearchClearClick={handleQuestionSearchClearClick}
              questionSearchText={questionSearchText}
              onClickQuestionsFilter={onClickQuestionsFilter}
              searchedQuestionText={searchedQuestionText}
              showQuestionsFilter={showQuestionsFilter}
              handleDownloadMcq={handleDownloadMcq}
            />
          )}
          {activeTab === CODING_TAB && (
            <ProblemHeaderComponent
              handleAddProblemClick={handleAddProblemClick}
              handleProblemSearchClick={handleProblemSearchClick}
              handleProblemSearchClearClick={handleProblemSearchClearClick}
              totalProblems={totalProblems}
              onClickAddFilter={onClickAddFilter}
              showFilter={showFilter}
              onProblemSearchChange={onProblemSearchChange}
              problemSearchText={problemSearchText}
              setActiveTabMethod={setActiveTabMethod}
              searchedProblemText={searchedProblemText}
            />
          )}
        </div>
      </div>
      <div className='d-flex flex-column scroll-y flex-1'>
        <TabContent
          activeTab={activeTab}
          className='d-flex flex-column flex-1 scroll-y'
        >
          {activeTab === CODING_TAB && (
            <TabPane
              tabId='Coding'
              className='d-flex flex-column flex-1 scroll-y'
            >
              <ProblemsListContainer />
            </TabPane>
          )}
          {activeTab === MCQ_TAB && (
            <TabPane
              tabId='MCQ'
              className='d-flex flex-column flex-1 scroll-y'
            >
              <QuestionContainer />
            </TabPane>
          )}
          {activeTab === 'template' && (
          <TabPane
            tabId='template'
            className='d-flex flex-column flex-1 scroll-y'
          >
            <TemplateContainer />
          </TabPane>
          )}
        </TabContent>
      </div>
    </div>
  );
};

QuestionTabsComponent.propTypes = {
  totalQuestions: PropTypes.number.isRequired,
  totalProblems: PropTypes.number.isRequired,
  onClickAddFilter: PropTypes.func.isRequired,
  showFilter: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTabMethod: PropTypes.func.isRequired,
  handleAddQuestionClick: PropTypes.func.isRequired,
  handleAddProblemClick: PropTypes.func.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  handleProblemSearchClick: PropTypes.func.isRequired,
  handleProblemSearchClearClick: PropTypes.func.isRequired,
  handleQuestionSearchClick: PropTypes.func.isRequired,
  handleQuestionSearchClearClick: PropTypes.func.isRequired,
  questionSearchText: PropTypes.string.isRequired,
  onClickQuestionsFilter: PropTypes.func.isRequired,
  searchedQuestionText: PropTypes.string.isRequired,
  searchedProblemText: PropTypes.string.isRequired,
  showQuestionsFilter: PropTypes.bool.isRequired,
  onProblemSearchChange: PropTypes.func.isRequired,
  problemSearchText: PropTypes.string.isRequired,
  handleDownloadMcq: PropTypes.func.isRequired,
};

export default QuestionTabsComponent;
