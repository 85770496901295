import React from 'react';
import PropTypes from 'prop-types';

import '../DeleteConfirmModal/deleteConfirmModal.scss';

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'core-components';

import session from 'utils/session';

const OpenFullScreenModal = ({
  confirmationModal,
  setConfirmationModal,
  setIsFullScreen,
}) => {
  const fullScreenAttempts = session.getItem('fullScreenAttempts');

  const handleEnterFullScreen = () => {
    document.body.requestFullscreen().then(() => {
      console.log('handleEnterFullScreen in setIsFullScreen set true');
      setIsFullScreen(true);
      setConfirmationModal(false);
    }).then((error) => console.error(error));
  };

  return (
    <Modal
      className='confirmation-modal'
      isOpen={confirmationModal}
      backdrop='static'
    >
      <ModalHeader
        className='modal-header'
      >
        Enter Full Screen
      </ModalHeader>
      <ModalBody className='modal-body'>
        <p>
          Please check the internet connection. <br />
          Click &apos;Enter Full Screen&apos; button below to Continue to Test !
        </p>
        <p className='text-danger'>
          {fullScreenAttempts} full screen attempts remaining
        </p>
      </ModalBody>
      <ModalFooter className='border-0 modal-footer'>
        <Button color='primary' onClick={handleEnterFullScreen}>
          Enter Full Screen
        </Button>
      </ModalFooter>
    </Modal>
  );
};

OpenFullScreenModal.propTypes = {
  confirmationModal: PropTypes.bool.isRequired,
  setConfirmationModal: PropTypes.func.isRequired,
  setIsFullScreen: PropTypes.func.isRequired,
};

export default React.memo(OpenFullScreenModal);
