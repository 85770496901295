import React from 'react';
import PropTypes from 'prop-types';

import { FormGroup, Button, Input, FormFeedback, Icon } from 'core-components/index';

import './inputWithButton.scss';

const InputWithButton = ({
  className,
  errorMessage,
  color,
  buttonText,
  onClick,
  onChange,
  placeHolder,
  value,
  onClickInput,
  disabled,
  successMessage,
  isSearchModal,
  handleResetSearch,
}) => {
  const onEnterPress = (event) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  return (
    <>
      <FormGroup
        className={`button-wrapper d-flex mb-0 pr-3 py-2 ${className}`}
      >
        <Input
          type='text'
          placeholder={placeHolder}
          onChange={onChange}
          onClick={onClickInput}
          invalid={errorMessage !== ''}
          value={value}
          onKeyPress={onEnterPress}
        />
        {isSearchModal && value !== '' && (
        <div className='d-flex align-items-center pr-2'>
          <Icon
            name='close icon-curson'
            onClick={handleResetSearch}
          />
        </div>
        )}
        <Button color={color} onClick={onClick} disabled={disabled} className='px-4 py-2'>
          {buttonText}
        </Button>
      </FormGroup>
      {errorMessage && <div className='text-danger text-break'>{errorMessage}</div>}
    </>
  );
};

InputWithButton.defaultProps = {
  className: '',
  onClick: () => {},
  onChange: () => {},
  value: '',
  onClickInput: () => { },
  successMessage: '',
  errorMessage: '',
  isSearchModal: false,
  handleResetSearch: () => {},
};

InputWithButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  color: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  placeHolder: PropTypes.string.isRequired,
  value: PropTypes.string,
  onClickInput: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
  successMessage: PropTypes.bool,
  isSearchModal: PropTypes.bool,
  handleResetSearch: PropTypes.func,
};

export default InputWithButton;
