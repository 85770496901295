import request from 'apis/apiHelper';
import local from 'utils/local';

export const fetchMcqs = () => {
  const token = local.getItem('authToken');
  return request.get(`/questions?token=${token}`);
};

export const mcqSubmissionPostApi = (data) => request.post('questions_submissions', data);

export const savedQuestions = () => {
  const token = local.getItem('authToken');
  return request.get(`/questions_submissions?token=${token}`);
};

export const finalSubmissionMCQTest = (data) => request.post('submit_mcqs', data);
