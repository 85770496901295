import React from 'react';
import PropTypes, { shape } from 'prop-types';

import { Container, Input } from 'core-components';
import Loading from 'shared-components/Loading';

import './rulesList.scss';
import './ruleStyle.css';

const RulesComponent = ({
  isError,
  errorMessage,
  isLoading,
  rulesList,
  rulesChecked,
  setRulesChecked,
}) => {
  const loading = () => {
    if (isLoading) {
      return (
        <Loading />
      );
    }
  };

  if (isError) {
    return (
      <Container className='green p-5 overflow-auto'>
        <h4 className='text-center text-white font-weight-bold mb-3'>Rules</h4>
        <h6 className='text-white font-weight-light'>{errorMessage}</h6>
      </Container>
    );
  }

  const renderRulesData = () => {
    loading();
    return rulesList.map((val) => {
      return (
        <li className='candidate-rules-item p-3' key={val.id}>
          <span>
            {val.description}
          </span>
        </li>
      );
    });
  };

  return (
    <div className='rule-list-content scroll-y pt-3'>
      <ul className='candidate-rules-list list-unstyled mb-0 pl-0'>
        {renderRulesData()}
      </ul>
      <div className='d-flex'>
        <Input
          type='checkbox'
          name='check'
          id='exampleCheck'
          className='position-relative ml-3 mt-4'
          checked={rulesChecked}
          onChange={() => setRulesChecked(!rulesChecked)}
        />
        <span className='ml-3 mt-4 color-secondary'>
          I hereby read all the Rules for the Test.
        </span>
      </div>
    </div>
  );
};

RulesComponent.propTypes = {
  isError: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  rulesList: PropTypes.arrayOf(shape()).isRequired,
  rulesChecked: PropTypes.bool.isRequired,
  setRulesChecked: PropTypes.func.isRequired,
};

export default React.memo(RulesComponent);
