import { CREATE_ASSESSMENT } from 'redux/admin/createAssessment/actionConstants';

// request
export const createAssessmentRequestAction = (payload) => ({
  type: CREATE_ASSESSMENT.CREATE_ASSESSMENT_REQUEST_ACTION,
  payload,
});

// success
export const createAssessmentSuccessAction = (payload) => ({
  type: CREATE_ASSESSMENT.CREATE_ASSESSMENT_SUCCESS_ACTION,
  payload,
});

// failure
export const createAssessmentFailureAction = (payload) => ({
  type: CREATE_ASSESSMENT.CREATE_ASSESSMENT_FAILURE_ACTION,
  payload,
});

export const createAssessmentClearState = () => ({
  type: CREATE_ASSESSMENT.CLEAR_STATE,
});
