import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Container } from 'core-components';
import Loading from 'shared-components/Loading';

import './invalidTokenComponent.scss';

const InvalidTokenComponent = () => {
  const { isLoading } = useSelector((state) => state.signUpUser);

  if (isLoading) {
    return (
      <Loading />
    );
  }

  return (
    <Container fluid className='invalid-token-font-style align-items-center d-flex justify-content-center'>
      <div>
        <h3 className='pb-4'>
          Invitation has been already accepted or the link you are trying to access is Expired.
        </h3>
        <h3 className='text-view font-weight-bold text-center'>
          Please contact Administrator !
        </h3>
      </div>
    </Container>
  );
};

export default InvalidTokenComponent;
