import produce from 'immer';

import date from 'utils/date';

import { DOWNLOAD_RESULT, ABSENT_CANDIDATES } from 'redux/admin/downloadResult/actionConstants';

const {
  DOWNLOAD_ASSESSMENT_RESULT_FAILURE_ACTION,
  DOWNLOAD_ASSESSMENT_RESULT_REQUEST_ACTION,
  DOWNLOAD_ASSESSMENT_RESULT_SUCCESS_ACTION,
  DOWNLOAD_RESULT_FAILURE_ACTION,
  DOWNLOAD_RESULT_REQUEST_ACTION,
  DOWNLOAD_RESULT_SUCCESS_ACTION,
  SET_DOWNLOAD_DRIVE_NAME_ACTION,
} = DOWNLOAD_RESULT;

const {
  ABSENT_CANDIDATES_FAILURE_ACTION,
  ABSENT_CANDIDATES_REQUEST_ACTION,
  ABSENT_CANDIDATES_SUCCESS_ACTION,
  SET_DOWNLOAD_ASSESSMENT_NAME_ACTION,
} = ABSENT_CANDIDATES;

const fileSaver = require('file-saver');

export const initialState = {
  downloadResultMessage: '',
  downloadResultIsLoading: true,
  downloadedDriveName: '',
  downloadedAssessmentName: '',
};

const downloadResultReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case DOWNLOAD_RESULT_SUCCESS_ACTION:
      state.downloadResultMessage = payload;
      state.downloadResultIsLoading = false;
      fileSaver.saveAs(
        new Blob([payload],
          {
            type: 'application/octet-stream',
          }),
        `Report_${state.downloadedDriveName}_${date.getTimeStampForFileName()}.csv`,
      );
      break;
    case DOWNLOAD_RESULT_FAILURE_ACTION:
      state.downloadResultMessage = payload.message;
      state.downloadResultIsLoading = false;
      break;
    case DOWNLOAD_RESULT_REQUEST_ACTION:
      state.downloadResultIsLoading = true;
      break;
    case SET_DOWNLOAD_DRIVE_NAME_ACTION:
      state.downloadedDriveName = payload;
      break;
    case SET_DOWNLOAD_ASSESSMENT_NAME_ACTION:
      state.downloadedAssessmentName = payload;
      break;
    case DOWNLOAD_ASSESSMENT_RESULT_SUCCESS_ACTION:
      state.downloadResultMessage = payload;
      state.downloadResultIsLoading = false;
      fileSaver.saveAs(new Blob([payload],
        {
          type: 'application/octet-stream',
        }),
      `Report_${state.downloadedAssessmentName}_${date.getTimeStampForFileName()}.csv`);
      break;
    case DOWNLOAD_ASSESSMENT_RESULT_FAILURE_ACTION:
      state.downloadResultMessage = payload.message;
      state.downloadResultIsLoading = false;
      break;
    case DOWNLOAD_ASSESSMENT_RESULT_REQUEST_ACTION:
      state.downloadResultIsLoading = true;
      break;
    case ABSENT_CANDIDATES_REQUEST_ACTION:
      return {
        ...state,
        downloadResultIsLoading: true,
      };
    case ABSENT_CANDIDATES_SUCCESS_ACTION:
      fileSaver.saveAs(
        new Blob([payload],
          {
            type: 'application/octet-stream',
          }),
        `Absent-candidates_${state.downloadedDriveName}_${date.getTimeStampForFileName()}.csv`,
      );
      return {
        ...state,
        downloadResultIsLoading: false,
        downloadResultMessage: payload,
      };
    case ABSENT_CANDIDATES_FAILURE_ACTION:
      return {
        ...state,
        downloadResultIsLoading: false,
        downloadResultMessage: payload.message,
      };
    default:
      return state;
  }
});

export default downloadResultReducer;
