import { GET_MCQ_COUNT } from 'redux/admin/getMcqCount/actionContants';

const { GET_MCQ_COUNT_REQUEST_ACTION, GET_MCQ_COUNT_SUCCESS_ACTION,
  GET_MCQ_COUNT_FAILURE_ACTION, CLEAR_MCQ_COUNT } = GET_MCQ_COUNT;

export const getMcqCountRequestAction = (payload) => ({
  type: GET_MCQ_COUNT_REQUEST_ACTION,
  payload,
});

export const getMcqCountSuccessAction = (payload) => ({
  type: GET_MCQ_COUNT_SUCCESS_ACTION,
  payload,
});

export const getMcqCountFailureAction = (payload) => ({
  type: GET_MCQ_COUNT_FAILURE_ACTION,
  payload,
});

export const clearMcqCount = () => ({
  type: CLEAR_MCQ_COUNT,
});
