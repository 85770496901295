import { PROBLEM_STATEMENT } from 'constants/actionConstants';

const {
  SET_SELECTED_PROBLEMS,
  UPDATE_SUBMISSION_COUNT,
  SET_ERROR_MESSAGE,
  DETAILS_REQUEST,
  SET_ACTIVE_INDEX,
} = PROBLEM_STATEMENT;

export const statementRequest = (driveId) => ({
  type: DETAILS_REQUEST,
  payload: { driveId },
});

export const setSelectedProblems = (statement) => ({
  type: SET_SELECTED_PROBLEMS,
  payload: [...statement],
});

export const updateSubmissionCount = (updatedVal) => ({
  type: UPDATE_SUBMISSION_COUNT,
  payload: updatedVal,
});

export const statementActionFailed = (requestError) => ({
  type: SET_ERROR_MESSAGE,
  payload: requestError,
});

export const statementActiveIndex = (index) => ({
  type: SET_ACTIVE_INDEX,
  payload: index,
});
