import { useRef, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProblemsListComponent from 'modules/admin/problemsList/ProblemsListComponent';
import {
  problemsListRequest,
  problemsListClear,
  problemDeleteRequest,
  setProblemsOffset,
} from 'redux/admin/problemsList/action';

import { PROBLEMS_LIMIT } from 'constants/appConstants';
import { openNewWindow } from 'utils/window';

const ProblemsListContainer = () => {
  const dispatch = useDispatch();
  const observer = useRef();
  const [searchText, setSearchText] = useState();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [targetId, setTargetId] = useState();
  const [problemTitle, setProblemTitle] = useState();

  const toggleConfirmation = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleDeleteClick = (id, title) => {
    setTargetId(id);
    setProblemTitle(title);
  };

  const {
    problems,
    totalProblems,
    isProblemsLoading,
    offset: currentPageProblems,
    deletionCount,
    problemSearchText,
    problemDifficulty,
    problemTag,
  } = useSelector((item) => item.problemsListReducer);

  const { isRefetchProblemList } = useSelector((state) => state.problemBulkUploadReducer);

  const lastBookElementRef = useCallback((node) => {
    if (isProblemsLoading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (problems.length >= PROBLEMS_LIMIT || deletionCount !== 0) {
          dispatch(setProblemsOffset(currentPageProblems + PROBLEMS_LIMIT - deletionCount));
        }
      }
    });
    if (node) observer.current.observe(node);
  }, [isProblemsLoading, deletionCount, problems]);

  useEffect(() => {
    if (!isProblemsLoading && currentPageProblems <= totalProblems) {
      dispatch(problemsListRequest({
        offset: currentPageProblems,
        searchText,
        problems,
        searchedProblemText: problemSearchText,
        searchedProblemDifficulty: problemDifficulty,
        searchedProblemTag: problemTag,
      }));
    }
  }, [currentPageProblems, isRefetchProblemList]);

  useEffect(() => {
    if (isRefetchProblemList) {
      dispatch(problemsListClear());
    }
  }, [isRefetchProblemList]);

  useEffect(() => {
    return () => {
      dispatch(problemsListClear());
    };
  }, []);

  const onClickDetails = (problemId) => {
    openNewWindow(`/admin/problem/${problemId}/details`);
  };

  const onClickEdit = (problemId, e) => {
    e.stopPropagation();
    openNewWindow(`/admin/problem/${problemId}/edit`);
  };

  const onClickDelete = (problemId) => {
    dispatch(problemDeleteRequest({ id: problemId }));
  };

  return (
    <ProblemsListComponent
      allProblems={problems}
      problemIsLoading={isProblemsLoading}
      onClickEdit={onClickEdit}
      onClickDetails={onClickDetails}
      onClickDelete={onClickDelete}
      lastBookElementRef={lastBookElementRef}
      totalProblems={totalProblems}
      toggleConfirmation={toggleConfirmation}
      targetId={targetId}
      problemTitle={problemTitle}
      handleDeleteClick={handleDeleteClick}
      confirmationModal={confirmationModal}
    />
  );
};

export default ProblemsListContainer;
