import { toast } from 'react-toastify';
import { put } from 'redux/admin/apiHelper';
import { SERVER_URL } from 'constants/appConstants';

// TODO
// api without saga 12
export const putEditProfileApi = async (data) => {
  const result = await put(`${SERVER_URL}admin/users/${data.id}`, data)
    .then((response) => {
      return response;
    }).catch((error) => {
      toast.error(error.response.data.message);
      return error;
    });

  const apiResponse = {
    result,
  };

  return apiResponse;
};
