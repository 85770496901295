export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const SERVER_URL = `${BASE_URL}api/v1/`;
export const CANDIDATE_INFO_API = `${SERVER_URL}candidates`;
export const DATE_TIME_FORMAT = 'DD-MM-YYYY, h:mm A';
export const TIME_FORMAT = 'hh:mm:ss A';
export const EXPORT_FILE_NAME_DATE_FORMAT = 'YYYYMMDD-Hmmss';
export const TIME_ALERT = 2;
export const PROBLEMS_LIMIT = 15;
export const DRIVES_LIMIT = 15;
export const ASSESSMENT_LIMIT = 15;
export const ORGANIZATION_LIMIT = 15;
export const FEEDBACKS_LIMIT = 15;
export const QUESTIONS_LIMIT = 15;
export const CONTACTS_LIMIT = 30;
export const WEB_SCOKET_CABLE_URL = process.env.REACT_APP_CABLE_URL;
export const MCQ_TAB = 'MCQ';
export const CODING_TAB = 'Coding';
