import * as yup from 'yup';

import { csvFormatErrorMessage } from 'constants/messageConstants';

const supportedformats = ['csv'];

export const schema = yup.object().shape({
  fileName: yup
    .string()
    .test('required', 'Please choose a File', (value) => {
      if (value) {
        return true;
      }
      return false;
    })
    .test('extension', csvFormatErrorMessage, (value) => {
      if (value) {
        const ext = value.substr(value.lastIndexOf('.') + 1);
        return supportedformats.includes(ext);
      }
      return false;
    }),
});
