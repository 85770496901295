const initialUserState = {
  email: '',
  emailError: '',
};

export const reducer = (state = initialUserState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case 'email':
      return ({ ...state, email: payload, emailError:'' });
    case 'emailError':
      return ({ ...state, emailError: payload });
    default:
      return state;
  }
};

export default reducer;
