import React, { useCallback, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import VerifyForgotPasswordComponent from 'modules/admin/verifyForgotPassword/VerifyForgotPasswordComponent';
import { reducer } from 'modules/admin/verifyForgotPassword/VerifyForgotPasswordContainer/reducer';
import { schema } from 'modules/admin/verifyForgotPassword/VerifyForgotPasswordContainer/schema';
import { verifyPasswordRequestAction } from 'redux/admin/verifyForgotPassword/action';
import { capitalize } from 'utils/capitalize';

const VerifyForgotPasswordContainer = () => {
  const dispatch = useDispatch();

  const { successMessage, isLoading, isError, errorMessage }
    = useSelector((state) => state.verifyPasswordReducer);

  const { token } = useParams();

  const initialUserState = {
    password: '',
    passwordConfirmation: '',
    passwordError: '',
    passwordConfirmationError: '',
  };
  const [resetPasswordState, setResetPasswordState] = useReducer(reducer, initialUserState);

  const handlePasswordChange = useCallback(
    (event) => {
      const password = event.target.value;
      setResetPasswordState({
        type: 'password',
        payload: password,
      });
    }, [],
  );

  const handlePasswordConfirmationChange = useCallback(
    (event) => {
      const passwordConfirmation = event.target.value;
      setResetPasswordState({
        type: 'passwordConfirmation',
        payload: passwordConfirmation,
      });
    }, [],
  );

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const { password, passwordConfirmation } = resetPasswordState;
      schema.validate(
        {
          password,
          passwordConfirmation,
        },
        { abortEarly: false },
      )
        .then((response) => {
          const data = {
            password,
            password_confirmation: passwordConfirmation,
            reset_password_token: token,
          };
          dispatch(verifyPasswordRequestAction(data));
        })
        .catch((error) => {
          error.inner.forEach((e) => {
            switch (e.path) {
              case 'password':
                setResetPasswordState({
                  type: 'passwordError',
                  payload: capitalize(e.message),
                });
                break;
              case 'passwordConfirmation':
                setResetPasswordState({
                  type: 'passwordConfirmationError',
                  payload: e.message,
                });
                break;
              default:
                break;
            }
          });
        });
    }, [resetPasswordState],
  );

  const handleCopy = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handlePaste = useCallback((event) => {
    event.preventDefault();
  }, []);

  return (
    <VerifyForgotPasswordComponent
      handlePasswordChange={handlePasswordChange}
      handlePasswordConfirmationChange={handlePasswordConfirmationChange}
      handleSubmit={handleSubmit}
      handleCopy={handleCopy}
      handlePaste={handlePaste}
      resetPasswordState={resetPasswordState}
      successMessage={successMessage}
      isLoading={isLoading}
      isError={isError}
      errorMessage={errorMessage}
    />
  );
};

export default React.memo(VerifyForgotPasswordContainer);
