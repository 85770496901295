import { CODE_BACKUP } from 'constants/actionConstants';

const { SET_BACKUP_CODE, FETCH_CODE_REQUEST, FETCH_CODE_ACTION, SET_ERROR_MESSAGE } = CODE_BACKUP;

export const saveCode = (data) => ({
  type: SET_BACKUP_CODE,
  payload: { ...data },
});

export const backupCodeRequest = (payload) => ({
  type: FETCH_CODE_REQUEST,
  payload: { ...payload },
});

export const backupCodeAction = (payload) => ({
  type: FETCH_CODE_ACTION,
  payload: { ...payload },
});

export const backupCodeFailed = (requestError) => ({
  type: SET_ERROR_MESSAGE,
  payload: requestError,
});
