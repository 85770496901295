import produce from 'immer';

import { EDIT_ORGANIZATION, ADD_CREDITS } from 'redux/superAdmin/editOrganization/actionConstants';

const { EDIT_ORGANIZATION_REQUEST_ACTION, EDIT_ORGANIZATION_SUCCESS_ACTION,
  EDIT_ORGANIZATION_FAILURE_ACTION, GET_ORGANIZATION_REQUEST_ACTION,
  GET_ORGANIZATION_SUCCESS_ACTION,
  GET_ORGANIZATION_FAILURE_ACTION, CLEAR_STATE } = EDIT_ORGANIZATION;

export const initialState = {
  organization: {
    name: '',
    description: '',
    contacts: '',
    problem_count: '',
    is_param_ai_allowed: false,
    is_mcq: false,
    is_coding: false,
    addCreditsLoading: false,
  },
  isLoading: false,
  isSuccess: false,
  message: '',
  failureMessage: '',
};

const editOrganizationReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case EDIT_ORGANIZATION_REQUEST_ACTION:
      return { ...state, isLoading: true };
    case EDIT_ORGANIZATION_SUCCESS_ACTION:
      return {
        ...state,
        organization: payload,
        isLoading: false,
        isSuccess: true,
        message: payload.message,
      };
    case EDIT_ORGANIZATION_FAILURE_ACTION:
      return {
        ...state,
        failureMessage: payload.message,
        isLoading: false,
      };

    case GET_ORGANIZATION_REQUEST_ACTION:
      return { ...state, isLoading: false };
    case GET_ORGANIZATION_SUCCESS_ACTION:
      return {
        ...state,
        organization: payload.organization,
        isLoading: false,
      };

    case GET_ORGANIZATION_FAILURE_ACTION:
      return {
        ...state,
        failureMessage: payload,
        isLoading: false,
      };

    case CLEAR_STATE:
      return { ...initialState };
    default:
      return state;
  }
});

export default editOrganizationReducer;
