import { get } from 'redux/admin/apiHelper';

import queryStringHelper from 'utils/queryStringHelper';
import { SERVER_URL } from 'constants/appConstants';

export const getCandidates = ({ currentPageNumber, driveId, searchQuery, absentCandidates }) => {
  const queryString = queryStringHelper({
    page: currentPageNumber,
    search: searchQuery,
    absent_candidates: absentCandidates,
  });
  return get(`${SERVER_URL}admin/drives/${driveId}/candidate_list?${queryString}`);
};
