/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React from 'react';

import {
  Spinner,
} from 'core-components';
import {
  DeleteConfirmModal,
} from 'shared-components';
import ProblemsComponent from 'modules/admin/problemsList/ProblemsListComponent/ProblemComponent';

import './problemListComponent.scss';

const ProblemsListComponent = ({
  problemIsLoading,
  allProblems,
  onClickEdit,
  onClickDetails,
  lastBookElementRef,
  confirmationModal,
  toggleConfirmation,
  targetId,
  problemTitle,
  handleDeleteClick,
  onClickDelete,
}) => {
  return (
    <>
      <DeleteConfirmModal
        confirmationModal={confirmationModal}
        toggleConfirmation={toggleConfirmation}
        handleDelete={onClickDelete}
        targetId={targetId}
        type='problem'
        name={problemTitle}
      />
      <div className='problem-bank-section d-flex flex-column flex-1 scroll-y'>
        {
          allProblems.length === 0 ? 'Problems Not Found' : null
        }
        <div className='problem-list-wrap scroll-y py-3'>
          <ul className='problem-list list-unstyled mb-0 px-3'>
            {allProblems.map((item, index) => {
              const key = `${item.id}-${index}`;
              if (allProblems.length === index + 1) {
                return (
                  <li className='problem-list-item d-flex flex-column'>
                    <div
                      key={key}
                      ref={lastBookElementRef}
                      className='d-flex flex-column flex-1'
                    >
                      <ProblemsComponent
                        onClickEdit={onClickEdit}
                        onClickDetails={onClickDetails}
                        problem={item}
                        confirmationModal={confirmationModal}
                        toggleConfirmation={toggleConfirmation}
                        targetId={targetId}
                        handleDeleteClick={handleDeleteClick}
                        onClickDelete={onClickDelete}
                      />
                    </div>
                  </li>
                );
              }
              return (
                <li className='problem-list-item d-flex flex-column'>
                  <div
                    key={key}
                    className='d-flex flex-column flex-1'
                  >
                    <ProblemsComponent
                      onClickEdit={onClickEdit}
                      onClickDetails={onClickDetails}
                      problem={item}
                      confirmationModal={confirmationModal}
                      toggleConfirmation={toggleConfirmation}
                      targetId={targetId}
                      handleDeleteClick={handleDeleteClick}
                      onClickDelete={onClickDelete}
                    />
                  </div>
                </li>
              );
            })}
            {problemIsLoading && <Spinner className='loader' />}
          </ul>
        </div>
      </div>
    </>
  );
};

ProblemsListComponent.defaultProps = {
  targetId: 0,
};

ProblemsListComponent.propTypes = {
  problemIsLoading: PropTypes.bool.isRequired,
  allProblems: PropTypes.arrayOf(PropTypes.any).isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDetails: PropTypes.func.isRequired,
  lastBookElementRef: PropTypes.func.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  targetId: PropTypes.number,
  problemTitle: PropTypes.string.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

export default React.memo(ProblemsListComponent);
