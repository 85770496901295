import React from 'react';

import { Container, Icon, Row, Col } from 'core-components';
import './supportPage.scss';
import siteLogo from 'assets/images/spark-code-logo.png';

const SupportComponent = () => {
  return (
    <>
      <Container fluid className='dark-l'>
        <div className='custom-padding text-center text-white'>
          <img
            className='logo-size'
            src={siteLogo}
            alt='Spark Code Logo'
          />
          <div className='title-color'> Welcome to Support Center</div>
          <div className='mt-5'>
            Need More Credits? Do Contact Us
          </div>
          <ul className='listing-items'>
            <li className='contact-details-wrapper'>
              <p className='contact-details'>
                Support Team
              </p>
            </li>
            <li className='contact-details-wrapper'>
              <p className='contact-details'>
                <Row>
                  <Col className='text-secondary'>
                    <Icon name='call' />
                  </Col>
                  <Col>
                    <a href='tel:+917887889902' className='supportDetails'>
                      +917887889902
                    </a>
                  </Col>
                </Row>
              </p>
            </li>
            <li className='contact-details-wrapper'>
              <p className='contact-details'>
                <Row>
                  <Col className='text-secondary'>
                    <Icon name='email' />
                  </Col>
                  <Col>
                    <a href='mailto:support@sparkode.online' className='supportDetails'>
                      support@sparkode.online
                    </a>
                  </Col>
                </Row>
              </p>
            </li>
          </ul>
        </div>
      </Container>
    </>
  );
};

export default React.memo(SupportComponent);
