import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import { Input, Icon } from 'core-components/index';

import './searchBox.scss';

const SearchBox = ({
  placeholder,
  className,
  handleOnChange,
  handleSearchClick,
  handleCloseClick,
  value,
}) => {
  return (
    <>
      <InputGroup
        className={`${className} d-flex align-items-center search-input-box`}
      >
        <Input
          placeholder={placeholder}
          className='px-3 py-2'
          onChange={handleOnChange}
          value={value}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleSearchClick();
            }
          }}
        />
        <InputGroupAddon addonType='append' className='d-flex'>
          <InputGroupText className={`d-flex align-items-center pl-3 ${value === '' && 'pr-3'}`}>
            <Icon name='search icon-curson' onClick={handleSearchClick} />
          </InputGroupText>
          {value !== '' && (
            <InputGroupText className='d-flex align-items-center pr-2'>
              <Icon name='close icon-curson' onClick={handleCloseClick} />
            </InputGroupText>
          )}
        </InputGroupAddon>
      </InputGroup>
    </>
  );
};

SearchBox.defaultProps = {
  handleOnChange: () => { },
  handleCloseClick: () => { },
  className: '',
  value: '',
};

SearchBox.propTypes = {
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  handleOnChange: PropTypes.func,
  handleSearchClick: PropTypes.func.isRequired,
  handleCloseClick: PropTypes.func,
  value: PropTypes.string,
};

export default SearchBox;
