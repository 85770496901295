// Icon Component

import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ className, name, ...other }) => {
  return <i className={`icon-${name} ${className}`} {...other} />;
};

Icon.propTypes = {
  className: PropTypes.string,

  // eslint-disable-next-line react/require-default-props
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

Icon.defaultProps = {
  className: '',
};

export default Icon;
