import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'core-components';

const RenderCandidateListTable = ({
  allCandidates,
  activeTab,
  isCheck,
  handleClick,
  driveStatus,
}) => {
  if (allCandidates.length === 0) {
    return (
      <tr key={0}>
        <td>Invite candidate(s) to view data!</td>
      </tr>
    );
  }

  return allCandidates.map((val) => {
    const {
      id,
      first_name: firstName,
      last_name: lastName,
      email,
      mobile_number: mobileNumber,
    } = val;

    return (
      <tr key={id}>
        {/* commented on 17th january 2023 in ShowCandidateListContainer */}
        {/* commenting for now can be used later */}
        {/* <td>{id}</td> */}
        <td>{firstName}</td>
        <td>{lastName}</td>
        <td>{email}</td>
        <td>{mobileNumber}</td>
        {activeTab === 'absentCandidate' &&
          driveStatus === 'ongoing' && (
          <>
            <td>
              <Input
                type='checkbox'
                name='check'
                className='select-all-checkbox'
                checked={isCheck[`${id}`]}
                onClick={(e) => handleClick(e, id)}
              />
            </td>
          </>
        )}
      </tr>
    );
  });
};

RenderCandidateListTable.defaultProps = {
  driveStatus: '',
};

RenderCandidateListTable.propTypes = {
  allCandidates: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      mobile_number: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  activeTab: PropTypes.string.isRequired,
  isCheck: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  driveStatus: PropTypes.string,
};

export default React.memo(RenderCandidateListTable);
