import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import moment from 'moment';

import RemainingTimeComponent from 'components/RemainingTimeComponent';

import { resetToken } from 'actions/candidateFormActions';
import { finishTestRequest } from 'actions/finishTestActions';
import { mcqQuestionSubmission } from 'actions/mcqQuestionsActions';
import {
  getRemainingTime,
  getRemainingTimeInFormat,
} from 'utils/helpers/HeaderIdeHelper';
import session from 'utils/session';

import { ROUTES, CANDIDATE_ROUTES } from 'constants/routeConstants';
import { TIME_ALERT } from 'constants/appConstants';

const CountDown = ({ handleSaveDraft }) => {
  const Ref = useRef(null);
  const dispatch = useDispatch();
  const { testTimer } = useSelector((state) => state.TimerReducer);
  const history = useHistory();
  const allowCoding = session.getItem('allowCoding');
  const allowMcq = session.getItem('allowMcq');

  const [countTimer, setCountTimer] = useState('00 hr: 00 min: 00 secs Remain');

  const [remainTime, setRemainTime] = useState('00:00:00');
  const [isSufficient, setIssufficient] = useState(false);
  const [milliseconds, setMilliseconds] = useState(testTimer * 1000);

  // this function will start the timer and set remaning time
  const startTimer = (time) => {
    const { total, hours, minutes, seconds } = getRemainingTime(time);
    const remainingTime = moment({ h: hours, m: minutes, s: seconds }).format('HH:mm:ss');
    setRemainTime(remainingTime);
    setMilliseconds(total);

    // if the pending timer reached to 0 then it will redirect to feedback page
    if (total === 0) {
      if (allowCoding) {
        handleSaveDraft();
      }
      if (allowMcq) {
        dispatch(mcqQuestionSubmission());
      }
      dispatch(resetToken());
      dispatch(finishTestRequest());
      history.replace(ROUTES.CANDIDATE + CANDIDATE_ROUTES.FEEDBACK);
    }
    // if timer is greater then 0 then it will set the timer
    if (total >= 0) {
      setCountTimer(getRemainingTimeInFormat(hours, minutes, seconds));
    }
  };

  // this will changes the timer on every seconds
  const clearTimer = (time) => {
    const { hours, minutes, seconds } = getRemainingTime(time);
    const trunTimer = getRemainingTimeInFormat(hours, minutes, seconds);
    setCountTimer(trunTimer);
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(time);
    }, 1000);
    Ref.current = id;
  };

  // this will calculates the timer by current time
  const getDeadTime = () => {
    const deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + Math.trunc(testTimer));
    return deadline;
  };

  // this useEffect will start the timer
  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  // this is changes the timer into red color for remaing 10 min
  useEffect(() => {
    const finalMin = Math.floor(milliseconds / 60000);
    if (finalMin < 10) {
      setIssufficient(true);
    } else {
      setIssufficient(false);
    }
  }, [milliseconds]);

  // Show alert when test time is remaining for 2 minutes
  useEffect(() => {
    const remainingTime = moment({ h: 0, m: TIME_ALERT, s: 0 })
      .format('HH:mm:ss');
    if (remainTime === remainingTime) {
      toast.warning(`Your test will end in ${TIME_ALERT} minutes,
         please click on Submit button to submit the test.`);
    }
  }, [remainTime]);

  return (
    <>
      <h6 className='time-color text-truncate'>
        <span className={isSufficient ? 'text-danger' : ''}>
          {countTimer}
        </span>
      </h6>
    </>
  );
};

const RemainingTimeContainer = ({ handleSaveDraft }) => {
  const {
    testTimer,
  } = useSelector((state) => state.TimerReducer);

  return (
    <>
      {testTimer && <CountDown handleSaveDraft={handleSaveDraft} />}
    </>
  );
};

CountDown.propTypes = {
  handleSaveDraft: PropTypes.func.isRequired,
};
RemainingTimeContainer.propTypes = {
  handleSaveDraft: PropTypes.func.isRequired,
};

export default React.memo(RemainingTimeContainer);
