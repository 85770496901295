import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import TestCaseListComponent from 'modules/admin/testCaseList/testCaseListComponent';

const TestCaseListContainer = (props) => {
  const {
    scrollRef,
    testCaseList,
    isTestCaseCreate,
    setTestCaseList,
  } = props;
  const dispatch = useDispatch();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [targetId, setTargetId] = useState();
  const testCaseState = useSelector((state) => state.testCaseReducer);
  const result = useSelector((state) => state.testCaseListReducer);
  const { problemId: createProblemId } = useSelector((state) => state.createProblemReducer);
  const { problemId: editProblemId } = useSelector((state) => state.editProblemReducer);

  const problemId = createProblemId || editProblemId;

  const toggleConfirmation = () => {
    setConfirmationModal(!confirmationModal);
  };
  const handleDeleteClick = (id) => {
    setTargetId(id);
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: 'resetUserState',
      });
    };
  }, []);

  const handleOnTestCaseEdit = useCallback(
    (tid) => {
      const obj = testCaseList.find((testCase, testCaseIndex) => (
        testCase.id ? testCase.id === tid : testCaseIndex === tid
      ));
      if (!obj.id) {
        obj.index = tid;
      }
      dispatch({
        type: 'editTestCase',
        payload: obj,
      });
    }, [testCaseList],
  );

  const handleOnTestCaseDelete = useCallback(
    async (id) => {
      if (testCaseList.length === 1) {
        toast.warning('Atleast 1 test case is required');
      } else {
        const deleteArray = testCaseList.filter((_, index) => index !== id);
        setTestCaseList([...deleteArray]);
      }
    }, [testCaseList],
  );

  return (
    <TestCaseListComponent
      testCases={testCaseList}
      handleOnTestCaseDelete={handleOnTestCaseDelete}
      handleOnTestCaseEdit={handleOnTestCaseEdit}
      toggleConfirmation={toggleConfirmation}
      targetId={targetId}
      handleDeleteClick={handleDeleteClick}
      confirmationModal={confirmationModal}
      scrollRef={scrollRef}
      isTestCaseCreate={isTestCaseCreate}
    />
  );
};

TestCaseListContainer.defaultProps = {
  isTestCaseCreate: false,
  scrollRef: {},
};

TestCaseListContainer.propTypes = {
  scrollRef: PropTypes.object,
  testCaseList: PropTypes.array.isRequired,
  setTestCaseList: PropTypes.func.isRequired,
  isTestCaseCreate: PropTypes.bool,
};

export default React.memo(TestCaseListContainer);
