import produce from 'immer';

import { DRIVE_SEARCH } from 'redux/admin/driveSearch/actionConstants';

export const initialState = {
  drives: [],
  drivesLoading: false,
  totalSearchedDrives: 0,
  driveSearchFailureMessage: '',
  driveSearchOffSet: 0,
};

const {
  DRIVE_SEARCH_REQUEST_ACTION,
  DRIVE_SEARCH_SUCCESS_ACTION,
  DRIVE_SEARCH_FAILURE_ACTION,
  SET_DRIVE_SEARCH_OFFSET,
  CLEAR_SEARCH_DRIVES,
} = DRIVE_SEARCH;

const driveSearchReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case DRIVE_SEARCH_REQUEST_ACTION:
      return {
        ...state,
        drivesLoading: true,
        drives: payload.drives ? payload.drives : [],
        driveSearchOffSet: payload.driveSearchOffSet ? payload.driveSearchOffSet : 0,
        totalSearchedDrives: payload.totalSearchedDrives ? payload.totalSearchedDrives : 0,
      };
    case DRIVE_SEARCH_SUCCESS_ACTION:
      return {
        ...state,
        drives: [...state.drives, ...payload.drives],
        totalSearchedDrives: payload.total_drives,
        drivesLoading: false,
        driveSearchFailureMessage: '',
      };
    case DRIVE_SEARCH_FAILURE_ACTION:
      return {
        ...state,
        driveSearchFailureMessage: payload,
        drivesLoading: false,
      };
    case SET_DRIVE_SEARCH_OFFSET:
      return { ...state, driveSearchOffSet: payload };
    case CLEAR_SEARCH_DRIVES:
      return { ...initialState };
    default:
      return state;
  }
});

export default driveSearchReducer;
