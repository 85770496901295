/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React from 'react';

import {
  Spinner,
  Row,
  Button,
  Modal,
  ModalBody,
  Icon,
  ModalFooter,
  ModalHeader,
} from 'core-components';
import './adminProfileComponent.scss';
import { CloseButton } from 'shared-components';
import userImage from 'assets/images/user.svg';

const AdminProfileComponent = (props) => {
  const {
    profileDetails,
    changePassVisible,
    setChangePassVisible,
    editProfileVisible,
    setEditProfileVisible,
    toggle,
    showModal,
    handleLogout,
    confirmationModal,
    toggleConfirmation,
  } = props;
  const {
    first_name,
    last_name,
    email,
    id,
    mobile_number,
    role,
    credits,
  } = profileDetails;

  return (
    <div>
      <Modal
        className='confirmation-modal'
        isOpen={confirmationModal}
        toggle={toggleConfirmation}
      >
        <ModalHeader
          className='modal-header'
        >
          Logout
        </ModalHeader>
        <ModalBody className=' modal-body'>
          <p>Do you want to logout?</p>
        </ModalBody>
        <ModalFooter className='border-0 modal-footer'>
          <Button
            color='secondary'
            outline
            onClick={toggleConfirmation}
          >
            Cancel
          </Button>
          <Button
            color='primary'
            onClick={() => {
              handleLogout();
              toggleConfirmation();
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showModal}
        toggle={toggle}
        className='profile-modal'
        modalClassName='left_modal search-modal'
      >
        <div className='modal-header-wrap'>
          <Row>
            <h3 className='modal-heading ml-3'>Profile</h3>
            <CloseButton onClick={toggle} />
          </Row>
        </div>
        <ModalBody className='px-5 pb-5 pt-0 profile-modal-body scroll-y'>
          <img src={userImage} className='profile-icon ml-2 mb-4' alt='Admin profile' />
          {!editProfileVisible ?
            (
              <ul className='profile-detail-list-wrap list-unstyled mb-0 pl-0'>
                <li className='profile-detail-list p-3 d-flex align-items-center justify-content-between'>
                  <label className='mb-0 candidate-name font-weight-bold'>
                    {`${first_name || 'Super'} ${last_name || 'Admin'}`}
                  </label>
                  {/* <Button
                    color='link'
                    className='profile-edit-btn text-decoration-none'
                    onClick={() => {
                      setEditProfileVisible(true);
                      setChangePassVisible(false);
                    }}
                    title='Edit Profile'
                  >
                    Edit <Icon name='pen' className='ml-1' />
                  </Button> */}
                </li>
                <li className='profile-detail-list p-3 d-flex align-items-center justify-content-between'>
                  <label className='mb-0'>Email</label>
                  <span>{email}</span>
                </li>

                <li className='profile-detail-list p-3 d-flex align-items-center justify-content-between'>
                  <label className='mb-0'>Mobile Number</label>
                  <span>{mobile_number}</span>
                </li>
              </ul>
            )
            :
            {/* (
              <EditProfileContainer
                editProfileVisible={editProfileVisible}
                setEditProfileVisible={setEditProfileVisible}
              />
            ) */ }}
        </ModalBody>
        <ModalFooter className='justify-content-between pb-5 px-5 border-0'>
          <Button
            color='link'
            className='logout-btn p-3 m-0 text-decoration-none'
            onClick={() => { toggleConfirmation(); }}
          >
            Logout
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
AdminProfileComponent.propTypes = {
  profileDetails: PropTypes.objectOf(PropTypes.object).isRequired,
  changePassVisible: PropTypes.bool.isRequired,
  setChangePassVisible: PropTypes.func.isRequired,
  editProfileVisible: PropTypes.bool.isRequired,
  setEditProfileVisible: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  confirmationModal: PropTypes.bool.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
};

export default React.memo(AdminProfileComponent);
