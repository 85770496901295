import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'core-components/index';
import './cardComponent.scss';

const CardComponent = ({ children }) => {
  return (
    <>
      <Card className='sp-card p-0'>
        <CardBody className='p-0 d-flex flex-column'>{children}</CardBody>
      </Card>
    </>
  );
};

CardComponent.propTypes = {
  children: PropTypes.node.isRequired,
};
export default CardComponent;
