import { CANDIDATE_REPORT } from 'redux/admin/candidateReport/actionConstants';

// request
export const candidateReportRequestAction = (token) => ({
  type: CANDIDATE_REPORT.CANDIDATE_REPORT_REQUEST_ACTION,
  token,
});

// success
export const candidateReportSuccessAction = (payload) => ({
  type: CANDIDATE_REPORT.CANDIDATE_REPORT_SUCCESS_ACTION,
  payload,
});

// failure
export const candidateReportFailureAction = (payload) => ({
  type: CANDIDATE_REPORT.CANDIDATE_REPORT_FAILURE_ACTION,
  payload,
});
