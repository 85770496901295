import produce from 'immer';

import { DRIVE } from 'constants/actionConstants';
import local from 'utils/local';
import session from 'utils/session';

export const initialState = {
  data: {
    id: local.getItem('driveID') || '',
    name: local.getItem('name') || '',
    startTime: '',
    endTime: '',
    allowCoding: false,
    allowMcq: false,
  },
  candidateId: local.getItem('candidateId'),
  isError: false,
  errorMessage: '',
  isLoading: false,
  isFinished: false,
};

const userDriveReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case DRIVE.SET_DETAILS:
      session.setItem('allowCoding', payload.data.allowCoding);
      session.setItem('allowMcq', payload.data.allowMcq);
      return {
        ...state,
        data: payload.data,
        candidateId: payload.candidateId,
        isLoading: false,
        isFinished: payload.isFinished,
      };
    case DRIVE.SET_ERROR_MESSAGE:
      return {
        ...state,
        isError: true,
        errorMessage: payload.message,
        isLoading: false,
      };
    case DRIVE.DRIVE_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
});

export default userDriveReducer;
