import * as yup from 'yup';

export const schema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  mobile_number: yup.string()
    .required()
    .matches(/^[0-9]+$/, 'Invalid Mobile Number')
    .min(10, 'Invalid Mobile Number')
    .max(10, 'Invalid Mobile Number'),
  password: yup.string().min(6).required(),
  password_confirmation: yup
    .string()
    .required('Password Confirmation is required')
    .min(6, 'Password Confirmation must be at least 6 characters')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  show_name: yup
    .string()
    .min(5, 'Organization Name must be at least 5 characters')
    .max(50, 'Organization Name must be at most 50 characters')
    .required('Organization Name is required'),
});
