import { call, put, takeEvery } from 'redux-saga/effects';
import {
  questionsSuccessAction, questionsFailureAction,
} from 'redux/admin/questionsList/action';
import { getQuestionsList } from 'redux/admin/questionsList/api';
import { QUESTIONS_LIST } from 'redux/admin/questionsList/actionContants';
import queryStringHelper from 'utils/queryStringHelper';
import { toast } from 'react-toastify';

export function* getQuestions(action) {
  const { searchText, filterDifficulty, filterTags } = action.payload;
  const getQueryString = queryStringHelper({
    search: searchText,
    difficulty_list: filterDifficulty,
    tag_list: filterTags?.toString(),
  });

  try {
    const response = yield call(getQuestionsList, getQueryString);
    yield put(questionsSuccessAction(response.data.data));
  } catch (error) {
    yield put(questionsFailureAction(error));
  }
}

export default function* getquestionsSaga() {
  yield takeEvery(QUESTIONS_LIST.QUESTIONS_LIST_REQUEST_ACTION, getQuestions);
}
