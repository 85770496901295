import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { editOrganizationPutApi, getOrganizationApi } from 'redux/superAdmin/editOrganization/api';
import { EDIT_ORGANIZATION } from 'redux/superAdmin/editOrganization/actionConstants';
import {
  editOrganizationFailureAction,
  editOrganizationSuccessAction,
  getOrganizationFailureAction,
  getOrganizationSuccessAction,
} from 'redux/superAdmin/editOrganization/action';

export function* editOrganizationSaga(action) {
  const { putData, organizationId } = action.payload;
  try {
    const response = yield call(editOrganizationPutApi, putData, organizationId);
    toast.success(response.data.message);
    yield put(editOrganizationSuccessAction(response.data));
  } catch (error) {
    const { data } = error.response;

    toast.error(data.message);
    yield put(editOrganizationFailureAction(data));
  }
}

export function* getOrganizationSaga(action) {
  try {
    const response = yield call(getOrganizationApi, action.payload);
    yield put(getOrganizationSuccessAction(response.data.data));
  } catch (error) {
    const { data } = error.response;

    toast.error(data.message);
    yield put(getOrganizationFailureAction(data));
  }
}

export default function* editOrganizationWatcherSaga() {
  yield takeLatest(EDIT_ORGANIZATION.EDIT_ORGANIZATION_REQUEST_ACTION, editOrganizationSaga);
  yield takeLatest(EDIT_ORGANIZATION.GET_ORGANIZATION_REQUEST_ACTION, getOrganizationSaga);
}
