import produce from 'immer';

import { ADMIN_LOGIN } from 'redux/admin/login/actionConstants';
import local from 'utils/local';

export const initialState = {
  emailId: '',
  firstName: '',
  lastName: '',
  errorMessage: '',
  isError: false,
  isAuth: false,
  isLoading: false,
  role: '',
  accessToken: local.getItem('accessToken') || 'initial_token',
  allowCoding: false,
  allowMcq: false,
};

const adminLoginReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_LOGIN.ADMIN_SUCCESS_ACTION:
      state.emailId = payload.email;
      state.firstName = payload.first_name;
      state.lastName = payload.last_name;
      state.role = payload.role;
      state.isAuth = true;
      state.isLoading = false;
      state.allowCoding = payload.allow_coding;
      state.allowMcq = payload.allow_mcq;
      localStorage.setItem('userDetails', JSON.stringify(payload));
      break;
    case ADMIN_LOGIN.ADMIN_FAILURE_ACTION:
      state.isAuth = false;
      state.isError = true;
      state.errorMessage = payload;
      state.isLoading = false;
      state.role = '';
      break;
    case ADMIN_LOGIN.ADMIN_REQUEST_ACTION:
      state.isLoading = true;
      state.role = '';
      break;
    case ADMIN_LOGIN.ADMIN_RESET_ACTION:
      return { ...initialState };
    default:
      return state;
  }
});

export default adminLoginReducer;
