import { EDIT_ASSESSMENT } from 'redux/admin/editAssessment/actionConstants';

const { EDIT_ASSESSMENT_REQUEST_ACTION, EDIT_ASSESSMENT_SUCCESS_ACTION,
  EDIT_ASSESSMENT_FAILURE_ACTION, GET_ASSESSMENT_REQUEST_ACTION,
  GET_ASSESSMENT_SUCCESS_ACTION, GET_ASSESSMENT_FAILURE_ACTION, CLEAR_STATE } = EDIT_ASSESSMENT;

export const editAssessmentRequestAction = (payload) => ({
  type: EDIT_ASSESSMENT_REQUEST_ACTION,
  payload,
});
export const editAssessmentSuccessAction = (payload) => ({
  type: EDIT_ASSESSMENT_SUCCESS_ACTION,
  payload,
});
export const editAssessmentFailureAction = (payload) => ({
  type: EDIT_ASSESSMENT_FAILURE_ACTION,
  payload,
});

export const getAssessmentRequestAction = (payload) => ({
  type: GET_ASSESSMENT_REQUEST_ACTION,
  payload,
});
export const getAssessmentSuccessAction = (payload) => ({
  type: GET_ASSESSMENT_SUCCESS_ACTION,
  payload,
});
export const getAssessmentFailureAction = (payload) => ({
  type: GET_ASSESSMENT_FAILURE_ACTION,
  payload,
});

export const editAssessmentClearAction = () => ({
  type: CLEAR_STATE,
});
