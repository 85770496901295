import { SERVER_URL } from 'constants/appConstants';
import { get, post, put } from 'redux/admin/apiHelper';

export const sendEmailsApi = (data) => post(`${SERVER_URL}admin/invite_user`, data);

export const getUsersApi = (queryParams) => get(`${SERVER_URL}admin/users?${queryParams}`);

export const getUserRole = () => get(`${SERVER_URL}admin/roles`);

export const putUserStatusApi = (data) => {
  const { id, is_active } = data;
  return put(`${SERVER_URL}/admin/users/${id}`, { is_active });
};
