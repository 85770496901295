import { Container, Row, Table } from 'core-components';
import PropTypes from 'prop-types';
import React from 'react';

const ProblemDetailsComponent = (props) => {
  const { renderProblemDetails } = props;
  return (
    <>{renderProblemDetails()}</>
  );
};
ProblemDetailsComponent.propTypes = {
  renderProblemDetails: PropTypes.func.isRequired,
};

export default React.memo(ProblemDetailsComponent);
