import { all } from 'redux-saga/effects';
import headerSaga from 'sagas/timerSaga';
import driveRulesSaga from 'sagas/rulesSaga';
import formSaga from 'sagas/formSaga';
import driveSagas from 'sagas/userDriveSagas';
import languageSagas from 'sagas/languageSagas';
import statementRequestSaga from 'sagas/problemsSaga';
import driveTimerSaga from 'sagas/driveTimerSaga';
import submitSaga from 'sagas/codeSubmissionSaga';
import saveCandidateFeedback from 'sagas/candidateFeedbackSaga';
import mcqQuestionSaga from 'sagas/mcqQuestionSaga';
import adminSaga from 'redux/admin/login/saga';
import inputOutputSaga from 'sagas/inputOutputSaga';
import createDriveWatcherSaga from 'redux/admin/createDrive/saga';
import editDriveWatcherSaga from 'redux/admin/editDrive/saga';
import problemWatcherSaga from 'redux/admin/createProblem/saga';
import editProblemWatcherSaga from 'redux/admin/editProblem/saga';
import testCaseWatcherSaga from 'redux/admin/testCase/saga';
import driveResultSaga from 'redux/admin/driveResult/saga';
import downloadResultSaga from 'redux/admin/downloadResult/saga';
import testCaseListSaga from 'redux/admin/testCaseList/saga';
import problemsListSaga from 'redux/admin/problemsList/saga';
import saveCodeSaga from 'sagas/codeBackupSaga';
import candidateReportSaga from 'redux/admin/candidateReport/saga';
import driveListSaga from 'redux/admin/driveList/saga';
import assessmentsListSaga from 'redux/admin/assessmentList/saga';
import createAssessmentWatcherSaga from 'redux/admin/createAssessment/saga';
import editAssessmentWatcherSaga from 'redux/admin/editAssessment/saga';
import assessmentsCandidatesSaga from 'redux/admin/assessmentCandidates/saga';
import adminSignupSaga from 'redux/admin/signup/saga';
import forgotPasswordSaga from 'redux/admin/forgotPassword/saga';
import verifyForgotPasswordSaga from 'redux/admin/verifyForgotPassword/saga';
import organizationListSaga from 'redux/superAdmin/organizationList/saga';
import remainingCreditsSaga from 'redux/admin/remainingCredits/saga';
import driveSearchWatcherSaga from 'redux/admin/driveSearch/saga';
import feedbacksListSaga from 'redux/admin/feedbacks/saga';
import questionsListSaga from 'redux/admin/questions/saga';
import createQuestionWatcherSaga from 'redux/admin/createQuestion/saga';
import editQuestionWatcherSaga from 'redux/admin/editQuestion/saga';
import getProblemsSaga from 'redux/admin/problems/saga';
import getContactsSaga from 'redux/admin/getContacts/saga';
import getEditDriveSaga from 'redux/admin/getEditDrive/saga';
import getquestionsSaga from 'redux/admin/questionsList/saga';
import getMcqCountSaga from 'redux/admin/getMcqCount/saga';
import editOrganizationWatcherSaga from 'redux/superAdmin/editOrganization/saga';
import getTagsSaga from 'redux/admin/tags/saga';
import getDriveContactsSaga from 'redux/admin/getDriveContacts/saga';
import { mcqBulkUploadWatcherSaga } from 'redux/admin/mcqUpload/saga';
import getTemplateSaga from 'redux/admin/template/saga';
import getCandidateTemplateSaga from 'redux/admin/template/candidateTemplateSaga';
import candidateListSaga from 'redux/admin/candidateList/saga';
import downloadMcqSaga from 'redux/admin/downloadMcq/saga';
import signUpCompanySaga from 'redux/admin/signUpCompany/saga';
import ruleDriveWatcherSaga from 'redux/admin/driveRules/saga';
import problemBulkUploadWatcherSaga from 'redux/admin/problemUpload/saga';
import finishTestSaga from './finishtestSaga';

export default function* rootSagas() {
  yield all([
    formSaga(),
    driveSagas(),
    driveRulesSaga(),
    headerSaga(),
    languageSagas(),
    statementRequestSaga(),
    signUpCompanySaga(),
    driveTimerSaga(),
    submitSaga(),
    adminSaga(),
    createDriveWatcherSaga(),
    editDriveWatcherSaga(),
    finishTestSaga(),
    problemWatcherSaga(),
    editProblemWatcherSaga(),
    testCaseWatcherSaga(),
    driveResultSaga(),
    downloadResultSaga(),
    saveCodeSaga(),
    testCaseListSaga(),
    problemsListSaga(),
    candidateReportSaga(),
    inputOutputSaga(),
    driveListSaga(),
    assessmentsListSaga(),
    createAssessmentWatcherSaga(),
    editAssessmentWatcherSaga(),
    assessmentsCandidatesSaga(),
    adminSignupSaga(),
    forgotPasswordSaga(),
    verifyForgotPasswordSaga(),
    organizationListSaga(),
    saveCandidateFeedback(),
    remainingCreditsSaga(),
    driveSearchWatcherSaga(),
    feedbacksListSaga(),
    questionsListSaga(),
    createQuestionWatcherSaga(),
    editQuestionWatcherSaga(),
    getProblemsSaga(),
    getContactsSaga(),
    getEditDriveSaga(),
    mcqQuestionSaga(),
    getquestionsSaga(),
    getMcqCountSaga(),
    editOrganizationWatcherSaga(),
    getDriveContactsSaga(),
    getTagsSaga(),
    mcqBulkUploadWatcherSaga(),
    getTemplateSaga(),
    getCandidateTemplateSaga(),
    candidateListSaga(),
    downloadMcqSaga(),
    ruleDriveWatcherSaga(),
    problemBulkUploadWatcherSaga(),
  ]);
}
