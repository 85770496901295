/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable indent */
import PropTypes, { shape, string } from 'prop-types';
import React, { useMemo } from 'react';
import Select from 'react-select';

import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  FormFeedback,
  Icon,
} from 'core-components';
import CustomDatePicker from 'core-components/CustomDatePicker';
import RuleContainer from 'modules/admin/rule/RuleContainer';
import AddQuestionComponent from 'modules/admin/createDrive/CreateDriveComponent/addQuestionCmponent';
import AddProblemComponent from 'modules/admin/createDrive/CreateDriveComponent/addProblemComponent';

import { getFullNameWithContact } from 'utils';

import { customStyle } from 'constants/stylesConstants';
import { CustomThemeMultiSelect } from 'constants/themesConstants';
import { DRIVE_SECTION_CONSTANTS } from '../CreateDriveContainer/constants';

import './createDriveComponent.scss';

const { DRIVE_DETAILS_SECTION, ADD_QUESTIONS_SECTION, ADD_RULES_SECTION } =
  DRIVE_SECTION_CONSTANTS;

const CreateDriveComponent = (props) => {
  const {
    handleDriveNameChange,
    handleDriveDescriptionChange,
    handleDriveStartChange,
    handleDriveEndChange,
    handleSelectedProblemChange,
    data,
    problemIsLoading,
    onDriveDetailsNext,
    onAddQuestionsBack,
    onCreateDriveSubmit,
    onAddNewRulesBack,
    isSuccess,
    nameErrTxt,
    descriptionErrTxt,
    problemErrTxt,
    createDrive,
    finishDriveCreation,
    contactsData,
    handleSelectedContactsChange,
    contactsErrTxt,
    questions,
    isQuestionsLoading,
    handleSelectedQuestionChange,
    isContactsLoading,
    defaultContacts,
    handleSearchInputChange,
    handleFilterTagsChange,
    handleFilterDifficultyChange,
    searchButtonClick,
    resetButtonClick,
    searchText,
    filterTags,
    filterDifficulty,
    handleFilterQuestionBox,
    showQuestionFilters,
    selectedQuestionsDetails,
    handleSearchProbInputChange,
    handleFilterProbTagsChange,
    handleFilterProbDifficultyChange,
    searchProbButtonClick,
    resetProbButtonClick,
    searchProbText,
    filterProbTags,
    filterProbDifficulty,
    handleFilterProbBox,
    showProbFilters,
    selectedProbDetails,
    allowCoding,
    allowMcq,
    tagsOptions,
    difficultyOptions,
    section,
    startTimeValue,
    endTimeValue,
    isCopyDrive,
    endTimeErrTxt,
    startTimeErrTxt,
  } = props;

  const {
    name,
    description,
  } = createDrive.data.drive;

  if (isContactsLoading) {
    return (
      <Container className='d-flex justify-content-center align-content'>
        <Spinner />
      </Container>
    );
  }

  const contactOptions = useMemo(() => (contactsData?.map(({
    id,
    first_name: firstName,
    last_name: lastName,
    mobile_number: mobileNumber,
    is_active: isActive,
  }) => ({
    value: id,
    label: getFullNameWithContact(firstName, lastName, mobileNumber),
    status: isActive,
  }))));

  const driveSuccess = () => {
    if (isSuccess) {
      return (
        <>
          <RuleContainer isCopyDrive={isCopyDrive} />
        </>
      );
    }
    return (
      <div className='px-3 w-100'>
        <div className='rule-info-box d-flex pl-3 py-3'>
          <p className='mb-0'>Please create drive for adding rules</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className='new-drive-section d-flex flex-column scroll-y'>

        <div className='d-flex align-items-center mt-3 mb-5'>
          <div className='d-flex align-items-center mr-3'>
            <h2 className='section-heading font-weight-bold mb-0'>
              Add New Drive
            </h2>
          </div>
          <div className='drive-title px-3'>
            <span className='font-weight-bold text-capitalize'>
              {name}
            </span>
          </div>
        </div>

        <Form className='new-drive-form d-flex flex-column scroll-y'>
          {section === DRIVE_DETAILS_SECTION && (
            <>
              <h5 className='section-sub-heading mb-4'>Drive Details</h5>
              <div className='d-flex flex-column'>
                <div className='drive-details-wrap d-flex justify-content-between'>
                  <div className='drive-details'>
                    <FormGroup>
                      <Label className=''>Title</Label>
                      <Input
                        type='text'
                        placeholder='Enter Drive Title'
                        onChange={handleDriveNameChange}
                        invalid={nameErrTxt !== ''}
                        value={name}
                      />
                      <FormFeedback>{nameErrTxt}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label className=''>Description</Label>
                      <Input
                        type='textarea'
                        rows='5'
                        placeholder='Enter Drive Description'
                        onChange={handleDriveDescriptionChange}
                        invalid={descriptionErrTxt !== ''}
                        value={description}
                      />
                      <FormFeedback>{descriptionErrTxt}</FormFeedback>
                    </FormGroup>
                  </div>

                  <div className='drive-details'>
                    <FormGroup>
                      <Label className=''>Start Date</Label>
                      <CustomDatePicker
                        value={startTimeValue}
                        onChange={handleDriveStartChange}
                        errorMessage={startTimeErrTxt}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className=''>End Date</Label>
                      <CustomDatePicker
                        value={endTimeValue}
                        onChange={handleDriveEndChange}
                        errorMessage={endTimeErrTxt}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className=''>Contacts</Label>
                      <Select
                        className='text-light react-select-container'
                        classNamePrefix='react-select'
                        id='role'
                        value={defaultContacts}
                        onChange={handleSelectedContactsChange}
                        options={contactOptions}
                        placeholder='Select Contacts to Send in Email'
                        theme={CustomThemeMultiSelect}
                        isMulti
                        styles={customStyle}
                      />
                      {contactsErrTxt && (
                        <div className='text-danger errFontSize'>{contactsErrTxt}</div>
                      )}
                      <div className='d-flex justify-content-end'>
                        <Button
                          color='primary'
                          onClick={() => {
                            onDriveDetailsNext();
                          }}
                          className='py-2 px-4 my-5'
                        >
                          Next
                        </Button>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </>
          )}
          {section === ADD_QUESTIONS_SECTION && (
            <>
              <h5 className='section-sub-heading mb-4'>Add Questions</h5>
              {problemErrTxt && (
                <div className='text-danger mb-1 font-weight-bold'>{problemErrTxt}</div>
              )}
              <div className='drive-details-wrap d-flex flex-1 scroll-y'>
                <div className='drive-details d-flex flex-column scroll-y'>
                  {allowCoding && (
                    <AddProblemComponent
                      handleSelectedProblemChange={handleSelectedProblemChange}
                      data={data}
                      problemIsLoading={problemIsLoading}
                      handleSearchProbInputChange={handleSearchProbInputChange}
                      handleFilterProbTagsChange={handleFilterProbTagsChange}
                      handleFilterProbDifficultyChange={
                        handleFilterProbDifficultyChange
                      }
                      searchProbButtonClick={searchProbButtonClick}
                      resetProbButtonClick={resetProbButtonClick}
                      searchProbText={searchProbText}
                      filterProbTags={filterProbTags}
                      filterProbDifficulty={filterProbDifficulty}
                      handleFilterProbBox={handleFilterProbBox}
                      showProbFilters={showProbFilters}
                      selectedProbDetails={selectedProbDetails}
                      tagsOptions={tagsOptions}
                      difficultyOptions={difficultyOptions}
                    />
                  )}
                </div>
                <div className='drive-details d-flex flex-column scroll-y'>
                  {allowMcq && (
                    <AddQuestionComponent
                      questions={questions}
                      isQuestionsLoading={isQuestionsLoading}
                      handleSelectedQuestionChange={
                        handleSelectedQuestionChange
                      }
                      handleSearchInputChange={handleSearchInputChange}
                      handleFilterTagsChange={handleFilterTagsChange}
                      handleFilterDifficultyChange={
                        handleFilterDifficultyChange
                      }
                      searchButtonClick={searchButtonClick}
                      resetButtonClick={resetButtonClick}
                      searchText={searchText}
                      filterTags={filterTags}
                      filterDifficulty={filterDifficulty}
                      handleFilterQuestionBox={handleFilterQuestionBox}
                      showQuestionFilters={showQuestionFilters}
                      selectedQuestionsDetails={selectedQuestionsDetails}
                      tagsOptions={tagsOptions}
                      difficultyOptions={difficultyOptions}
                    />
                  )}
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between'>
                <Button
                  onClick={() => {
                    onAddQuestionsBack();
                  }}
                  color='secondary'
                  outline
                  className='back-button py-2 px-4'
                >
                  <Icon
                    name='left'
                    className='back-page-arrow font-weight-bold align-middle'
                  />
                  Back
                </Button>
                <Button
                  color='primary'
                  onClick={() => {
                    onCreateDriveSubmit();
                  }}
                  className='py-2 px-4'
                >
                  Create Drive
                </Button>
              </div>
            </>
          )}
          {section === ADD_RULES_SECTION && (
            <>
              <div className='new-drive-col scroll-y d-flex flex-column'>
                <div className='new-drive-content-head'>
                  <label className='label-color mb-0'>
                    Add New Rules to Drive
                  </label>
                </div>
                <div className='new-drive-content-body d-flex flex-column my-3 scroll-y'>
                  {driveSuccess()}
                </div>
              </div>
              <div>
                {isSuccess ? (
                  <div className='d-flex'>
                    <Button
                      onClick={() => {
                        onAddNewRulesBack();
                      }}
                      color='secondary'
                      outline
                      className='back-button py-2 px-4 mr-3'
                    >
                      <Icon
                        name='left'
                        className='back-page-arrow font-weight-bold align-middle'
                      />
                      Back
                    </Button>
                    <Button
                      color='primary'
                      disabled={!isSuccess}
                      onClick={finishDriveCreation}
                      className='finish-button py-2 px-4 ml-auto'
                    >
                      Finish
                    </Button>
                  </div>
                ) : null}{' '}
              </div>
            </>
          )}
        </Form>
      </div>
    </>
  );
};

CreateDriveComponent.defaultProps = {
  isSuccess: false,
  contactsErrTxt: '',
  isCopyDrive: false,
  startTimeErrTxt: '',
  endTimeErrTxt: '',
};

CreateDriveComponent.propTypes = {
  handleDriveNameChange: PropTypes.func.isRequired,
  handleDriveDescriptionChange: PropTypes.func.isRequired,
  handleDriveStartChange: PropTypes.func.isRequired,
  handleDriveEndChange: PropTypes.func.isRequired,
  handleSelectedProblemChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  problemIsLoading: PropTypes.bool.isRequired,
  onDriveDetailsNext: PropTypes.func.isRequired,
  onAddQuestionsBack: PropTypes.func.isRequired,
  onCreateDriveSubmit: PropTypes.func.isRequired,
  onAddNewRulesBack: PropTypes.func.isRequired,
  isSuccess: PropTypes.bool,
  nameErrTxt: PropTypes.string.isRequired,
  descriptionErrTxt: PropTypes.string.isRequired,
  problemErrTxt: PropTypes.string.isRequired,
  createDrive: PropTypes.objectOf(PropTypes.any).isRequired,
  finishDriveCreation: PropTypes.func.isRequired,
  contactsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSelectedContactsChange: PropTypes.func.isRequired,
  defaultContacts: PropTypes.objectOf(PropTypes.object).isRequired,
  contactsErrTxt: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  isQuestionsLoading: PropTypes.bool.isRequired,
  handleSelectedQuestionChange: PropTypes.func.isRequired,
  isContactsLoading: PropTypes.bool.isRequired,
  handleSearchInputChange: PropTypes.func.isRequired,
  handleFilterTagsChange: PropTypes.func.isRequired,
  handleFilterDifficultyChange: PropTypes.func.isRequired,
  searchButtonClick: PropTypes.func.isRequired,
  resetButtonClick: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  filterTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterDifficulty: PropTypes.string.isRequired,
  handleFilterQuestionBox: PropTypes.func.isRequired,
  showQuestionFilters: PropTypes.bool.isRequired,
  selectedQuestionsDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSearchProbInputChange: PropTypes.func.isRequired,
  handleFilterProbTagsChange: PropTypes.func.isRequired,
  handleFilterProbDifficultyChange: PropTypes.func.isRequired,
  searchProbButtonClick: PropTypes.func.isRequired,
  resetProbButtonClick: PropTypes.func.isRequired,
  searchProbText: PropTypes.string.isRequired,
  filterProbTags: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterProbDifficulty: PropTypes.string.isRequired,
  handleFilterProbBox: PropTypes.func.isRequired,
  showProbFilters: PropTypes.bool.isRequired,
  selectedProbDetails: PropTypes.arrayOf(PropTypes.object).isRequired,
  allowCoding: PropTypes.bool.isRequired,
  allowMcq: PropTypes.bool.isRequired,
  tagsOptions: PropTypes.arrayOf(string).isRequired,
  difficultyOptions: PropTypes.arrayOf(shape).isRequired,
  section: PropTypes.number.isRequired,
  startTimeValue: PropTypes.string.isRequired,
  endTimeValue: PropTypes.string.isRequired,
  isCopyDrive: PropTypes.bool,
  startTimeErrTxt: PropTypes.string,
  endTimeErrTxt: PropTypes.string,
};

export default React.memo(CreateDriveComponent);
