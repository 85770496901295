import produce from 'immer';
import { TEMPLATE } from 'redux/admin/template/actionContants';

const { TEMPLATE_FAILURE_ACTION, TEMPLATE_LIST_REQUEST_ACTION,
  TEMPLATE_SUCCESS_ACTION, ADD_NEW_TEMPLATE, RESET_TEMPLATE,
  CANDIDATE_TEMPLATE_LIST_REQUEST_ACTION } = TEMPLATE;

export const initialState = {
  templateList: [],
  isTemplateSuccess: false,
  isTemplateLoading: false,
  isTemplateError: false,
};

const templateReducer = produce((state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case TEMPLATE_LIST_REQUEST_ACTION:
      return { ...state, isTemplateLoading: true };
    case CANDIDATE_TEMPLATE_LIST_REQUEST_ACTION:
      return { ...state, isTemplateLoading: true };
    case TEMPLATE_SUCCESS_ACTION:
      return { ...state, isTemplateLoading: false, isTemplateSuccess: true, templateList: payload };
    case TEMPLATE_FAILURE_ACTION:
      return { ...state, isTemplateError: true, isTemplateLoading: false };
    case ADD_NEW_TEMPLATE:
      return { ...state, templateList:  [...state.templateList, payload] };
    case RESET_TEMPLATE:
      return { ...initialState };
    default:
      return state;
  }
});
export default templateReducer;
