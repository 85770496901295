export const DRIVE_RESULT = {
  DRIVE_RESULT_REQUEST_ACTION: 'DRIVE_RESULT_REQUEST_ACTION',
  DRIVE_RESULT_SUCCESS_ACTION: 'DRIVE_RESULT_SUCCESS_ACTION',
  DRIVE_RESULT_FAILURE_ACTION: 'DRIVE_RESULT_FAILURE_ACTION',
};

export const SELECTED_CANDIDATE = {
  SELECTED_CANDIDATE_REQUEST_ACTION: 'SELECTED_CANDIDATE_REQUEST_ACTION',
  SELECTED_CANDIDATE_SUCCESS_ACTION: 'SELECTED_CANDIDATE_SUCCESS_ACTION',
  SELECTED_CANDIDATE_FAILURE_ACTION: 'SELECTED_CANDIDATE_FAILURE_ACTION',
};

export const SELECTED_CANDIDATE_EMAIL = {
  SELECTED_CANDIDATE_EMAIL_REQUEST_ACTION: 'SELECTED_CANDIDATE_EMAIL_REQUEST_ACTION',
  SELECTED_CANDIDATE_EMAIL_SUCCESS_ACTION: 'SELECTED_CANDIDATE_EMAIL_SUCCESS_ACTION',
  SELECTED_CANDIDATE_EMAIL_FAILURE_ACTION: 'SELECTED_CANDIDATE_EMAIL_FAILURE_ACTION',
};
