import { call, put, takeLatest } from 'redux-saga/effects';

import {
  assessementListSuccess,
  assessementListFailure,
  assessementDeleteSuccess,
  assessementDeleteFailure,
} from 'redux/admin/assessmentList/action';

import { AssessmentGetApi, assessmentDeleteApi } from 'redux/admin/assessmentList/api';
import { ASSESSMENT_LIST } from 'redux/admin/assessmentList/actionConstants';
import { toast } from 'react-toastify';

export function* assessementSaga(action) {
  try {
    const { assessmentOffset, searchText, assessments } = action.payload;
    const response = yield call(AssessmentGetApi,
      { offset: assessmentOffset, search: searchText, assessments });
    yield put(assessementListSuccess(response.data.data));
  } catch (error) {
    const { data } = error.response;
    yield put(assessementListFailure(data));
    toast.error(data.message);
  }
}

export function* assessementDeleteSaga(action) {
  try {
    const { id } = action.payload;
    const response = yield call(assessmentDeleteApi, { id });
    if (response.data.message === 'Assessment deleted successfully.') {
      toast.success(response.data.message);
      yield put(assessementDeleteSuccess(id));
    } else {
      toast.error(response.data.message);
      yield put(assessementDeleteFailure(response.data.message));
    }
  } catch (error) {
    yield put(assessementDeleteFailure('error while deleting drive'));
  }
}

export default function* assessmentsListSaga() {
  yield takeLatest(ASSESSMENT_LIST.ASSESSMENT_LIST_REQUEST, assessementSaga);
  yield takeLatest(ASSESSMENT_LIST.ASSESSMENT_DELETE_REQUEST, assessementDeleteSaga);
}
