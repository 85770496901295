const backgroundColor = '#2345ff';

export const tourSteps = [
  {
    selector: '#search',
    content: 'Here you can search for all the drives',
    style: {
      backgroundColor,
    },
  },
  {
    selector: '#profile',
    content: 'Here you can access your profile details',
    style: {
      backgroundColor,
    },
  },
  {
    selector: '#home',
    content: 'This is the home component and here, you will get to see all the drive related details',
    style: {
      backgroundColor,
    },
  },
  {
    selector: '#drive',
    content: 'This is the new drive component and here, you can add a new drive.',
    style: {
      backgroundColor,
    },
  },
  {
    selector: '#rules',
    content: 'Here you can see, add and change rules for a drive',
    style: {
      backgroundColor,
    },
  },
  {
    selector: '#problems-bank',
    content: 'Here you will get to see and create new problems',
    style: {
      backgroundColor,
    },
  },
  {
    selector: '#users',
    content: 'Here you can see the list of users in your company using this application',
    style: {
      backgroundColor,
    },
  },
  {
    selector: '#assessment',
    content: 'Here you will get to see a list of assessments available',
    style: {
      backgroundColor,
    },
  },
  {
    selector: '#feedback',
    content: 'Here you will get to see feedback from the candidates',
    style: {
      backgroundColor,
    },
  },
];
